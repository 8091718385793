import Vue from 'vue'
import Vuex from 'vuex'
import sciStore from './modules/sciStore'
import journal from "./modules/journal";
import subject from "./modules/subject";
import pubmedzh from "./modules/pubmedzh";
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    // isFirstOpen:true,
    journalNameAll: [

    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    sciStore,journal,subject,pubmedzh
  }
})
