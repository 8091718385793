import md5 from 'js-md5';
import $ from 'jquery'
import config from './config'

import commonUtils from "../../../common/commonUtils";
import storage from 'sweet-storage'


// function showAlert(alertFn,title,msg,btnName,callback){
//   let confirmBtnName=btnName;
//   if(!confirmBtnName){
//     confirmBtnName="好的"
//   }
//   alertFn(msg, title, {
//     confirmButtonText:confirmBtnName,
//     dangerouslyUseHTMLString: true,
//     // cancelButtonText: '',
//     type: 'warning'
//   }).then(() => {
//     if(callback){
//       callback()
//     }
//   }).catch(() => {
//   });
// }
// function showWarningMsg(alertFn,msg){
//   showAlert(alertFn,"消息提示",msg)
// }
// function showSuccMsg(alertFn,msg){
//   showAlert(alertFn,"消息提示",msg)
// }

function shortShow(text,len){
  return text&&text.length>len?text.substring(0,len)+"..." : text;
}

function getNowDate(){
  let nowDate=new Date()
  return nowDate.getFullYear()+"-"+(nowDate.getMonth()+1)+"-"+nowDate.getDate()
}

//通过term条件转化成条件列表
function convertTerm2ConList(term){
  let conList=[];
  let cons=term.split(this.RL_AND);
  for (const consKey in cons) {
    let value=cons[consKey].match(/ ?\(?(.+?)\[(.+?)\]\)? ?/)[1];
    let value_type=cons[consKey].match(/ ?\(?(.+?)\[(.+?)\]\)? ?/)[2];
    let value_rl=this.RL_AND;
    let intKey=parseInt(consKey)
    if(intKey>0){
      conList.push({
        value_rl: value_rl,
        value: value,
        value_type: value_type
      });
    }else{
      conList.push( {
        value_rl: '',
        value: value,
        value_type: value_type
      });
    }
  }
  return conList;
}


function getCookie(c_name){

  if (document.cookie.length>0){

    let c_start=document.cookie.indexOf(c_name + "=")
    if (c_start!=-1){
      c_start=c_start + c_name.length+1
      let c_end=document.cookie.indexOf(";",c_start)
      if (c_end==-1) c_end=document.cookie.length
      return unescape(document.cookie.substring(c_start,c_end))
    }
  }
  return ""
}



function deleteCookie(name) {

    let exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let cval = getCookie(name);

    if (cval != null)
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();

}

function HTMLDecode(str)
{
  var  s = "";
  if (str.length == 0) return "";
  s = str.replace(/&gt;/g, "&");
  s = s.replace(/&lt;/g, " <");
  s  = s.replace(/&gt;/g, ">");
  s = s.replace(/&nbsp;/g, " ");
  s = s.replace(/'/g, "\'");
  s = s.replace(/&quot;/g, "\"");
  s = s.replace(/ <br>/g, "\n");
  return s;
}

const md5Count=1

function md5PassWd(pass) {
  for(let i=0;i<md5Count;i++){
    pass=md5(pass)
  }
  return pass;
}

function md5Str(str) {
  try {
    if(typeof(str) != "string"){
      str=JSON.stringify(str);
    }
    str=md5(str)
  }catch (e) {
    return ""
  }

  return str;
}
function getSelfHeight(){

  let hs=[]
   let addh=0

  if(varNotNull(  $(".footer"))  && varNotNull($(".page-warp"))){
    let footerh=$(".footer").height();
    addh+=footerh+ $('.page-warp').height();

  }
  hs.push(
    $(window).height(),
    $('body').height(),
    $('.page-warp').height(),
    $('.main').height(),
    $('.page').height(),
    $('.paper-main').height(),
    addh
  )

  hs=hs.filter( h=> !isNaN(h) )

  let max = Math.max.apply(null,hs);

  // console.log(hs.length+"个高度数据中，最大值"+max+"==="+ ($('.paper-main').height()))

  return max

}
function getSelfWidth(){

  let hs=[]
  hs.push(
    $(window).width(),
    $('body').width(),
    $('.page-warp').width(),
    $('.main').width(),
    $('.page').width(),
  )

  hs=hs.filter( h=> !isNaN(h) )
  let max = Math.max.apply(null,hs);

  // console.log(hs.length+"个宽度数据中，最大值"+max)

  return max

}
function hasChinese(str) {
  if(/.*[\u4e00-\u9fa5]+.*$/.test(str))
  {
    return true;
  }
  return false;
}
function hasEnglish(str) {
  if(/.*[a-zA-Z]+.*$/.test(str))
  {
    return true;
  }
  return false;
}

function onlyChinese(str) {

  if(hasEnglish(str)){
    return false
  }
  return hasChinese(str)
}

function autoRemFontSize() {
  if(config.autoRemFontSize){

    let whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
    // eslint-disable-next-line no-unused-vars
    let wH = window.innerHeight;// 当前窗口的高度
    let wW = window.innerWidth;// 当前窗口的宽度
    // console.log("wW: "+wW)
    let rem = wW * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    // console.log("rem: "+rem)
    /*
    *         if(fontSize>=58.3333 || width>1120 ){
            utils.autoRemFontSize()
        }**/

    // let w=getSelfWidth();
    //
    // if(varNotNull(wW)){
    //   wW=0;
    // }
    // if(varNotNull(w)){
    //   w=0;
    // }
    // if(w>wW){
    //   wW=w
    // }

    if(wW<=1210){
      $('html').css('font-size', '63.8542' + "px");
      $('html').css('min-width', '1210' + "px");
    }else{
      $('html').css('font-size', rem + "px");
    }


  }
}


function listUniq(array){
  let temp = [];
  let index = [];
  let l = array.length;
  for(let i = 0; i < l; i++) {
    for(let j = i + 1; j < l; j++){
      if (array[i] === array[j]){
        i++;
        j = i;
      }
    }
    temp.push(array[i]);
    index.push(i);
  }
  return temp;
}

function getWindowWidth() {
  return $(window).width();
}

function varIsNull(v) {
  return !varNotNull(v);
}

function varNotNull(v) {

  if(typeof(v) === "undefined"){
    return false
  }
  if(v===null){
    return false
  }
  if(v===""){
    return false
  }
  return true;
}

function objNotNull(obj) {
  let res=varNotNull(obj)
  if(res){
    if(JSON.stringify(obj) == "{}"){
      return false
    }
    return true
  }
  return false;
}

function scrollTop() {
  scrollTo(0,0);
}

function getChinese(str){

  let reg=/[^\u4e00-\u9fa5]/g;
  if(varNotNull(str)){
    return  str.replace(reg,"")
  }
  return ''
}

function cpObj(origin) {
  let _obj = JSON.stringify(origin);
  let  objClone = JSON.parse(_obj);
  return objClone;
}


function setLocalStorage(key,data,expire) {
  // if(!varNotNull(data) || !varNotNull(key)){
  //   return
  // }
  if(typeof(data) != "string"){
    data=JSON.stringify(data);
  }
  if(expire){
    localStorage.setItem(key,data);
    // storage.save(key, data, expire)
  }else{
    localStorage.setItem(key,data);
  }

}

function getLocalStorage(key,isExpireStorage) {
  if( !varNotNull(key)){
    return null;
  }
  let data=""
  if(isExpireStorage){
    data=localStorage.getItem(key);
    // data=storage.getKey(key);
  }else{
    data=localStorage.getItem(key);
  }

  if(!varNotNull(data)){
    return null;
  }

  if( (data.startsWith("{") && data.endsWith("}"))   || (data.startsWith("[") && data.endsWith("]")) ){
    data=JSON.parse(data);
    return  data;
  }
  return data;
}

function deleteLocalStorage(key) {
  if( !varNotNull(key)){
    return null;
  }
  localStorage.removeItem(key);
}
function moreThanBytesLimitAndTtim(val,limit) {
  let len = 0;
  let res=''

  for (let i = 0; i < val.length; i++) {
    let a = val.charAt(i);
    // eslint-disable-next-line no-control-regex
    if (a.match(/[^\x00-\xff]/ig) != null) {
      len += 2;
    }
    else {
      len += 1;
    }
    if(len<=limit){
      res+=a
    }else{
      break
    }
  }


  return res;
}


function getUrlParam(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  let r = window.location.search.substr(1).match(reg);  //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}

// function debounce(fn, delay, scope) {
//   let timer = null;
//   // 返回函数对debounce作用域形成闭包
//   return function () {
//     // setTimeout()中用到函数环境总是window,故需要当前环境的副本；
//     let context = scope || this, args = arguments;
//     // 如果事件被触发，清除timer并重新开始计时
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       fn.apply(context, args);
//     }, delay);
//   }
// }
/**
 * 节流器
 * @param [function] fn 事件触发后要调用的函数，也就是要节流的函数
 * @param [object]context fn的执行上下文对象，没有执行对象设为null即可
 * @param [number] delay 延缓执行的时间间隔 毫秒
 * @param param fn需要的参数
 * @return 无
 */
function throttle(fn, context, delay, param) {
  clearTimeout(fn['timeoutId']);

  fn['timeoutId'] = setTimeout(function () {
    fn.call(context, param);
  }, delay);
}
function debounce(handle, wait) {
  let lasttime = 0;
  // eslint-disable-next-line no-unused-vars
  return function(e) {
    let nowtime = new Date().getTime();
    if(nowtime - lasttime > wait) {
      handle.apply(this, arguments);
      lasttime = nowtime;
    }
  }
}

function getBrowser() {
  let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  let isOpera = userAgent.indexOf("Opera") > -1;
  if (isOpera) { //判断是否Opera浏览器
    return "Opera"
  }
  if (userAgent.indexOf("Firefox") > -1) { //判断是否Firefox浏览器
    return "FF";
  }

  // if (userAgent.indexOf("Safari") > -1) { //判断是否Safari浏览器
  //   return "Safari";
  // }

  if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) { //判断是否IE浏览器
    return "IE";
  }
  if (userAgent.indexOf("Edge") > -1) { //判断是否Safari浏览器
    return "Edge";
  }
//
  if (userAgent.indexOf("Chrome") > -1) {
    return "Chrome";
  }
}
function isGoogle() {
  if(getBrowser()==='Chrome'){
    return true;
  }
  return  false;
}

function checkNoticeCanShow(key,data) {


    let canShowUpdateNotice=false;
    data=data.trim();
    let md5=md5Str(data);

    let oldNotice=getLocalStorage(key);


    if(!varNotNull(oldNotice)){
      //说明首次存储通知应该显示的
      setLocalStorage(key,data);
      canShowUpdateNotice=true;
    }else{
      try {
        oldNotice=oldNotice.trim();
        // eslint-disable-next-line no-empty
      }catch (e) {

      }

      let oldMd5=md5Str(oldNotice);

      if(oldMd5!==md5){
        //当MD5不一致说明 数据有更新 应该要显示 且保存最新内容
        canShowUpdateNotice=true;
        setLocalStorage(key,data);
      }else{
        //否则说明是 没有更新 就不显示了
        canShowUpdateNotice=false;
      }
    }


    if(!varNotNull(data)){
      canShowUpdateNotice=false;
    }



  // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      if(canShowUpdateNotice){
        //真正的显示更新广告
        resolve(data)
      }
    })
}


function checkFirstDonwload(){


  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject)=>{


    let first=commonUtils.getLocalStorage("first.download.tip")




    if(commonUtils.varIsNull(first)){
      commonUtils.setLocalStorage("first.download.tip",1)


      let msg="多个图下载会下载多个pdf，如果你只下载到一个，点击右上角 <img src=\"/static/img/alterwindowstip.png\" alt=\"\">开放本网站的浏览器拦截即可。"

      window.globalMethods.swichTip(true,'ee',msg,()=>{},
        ()=>{

          resolve()

        },null)

      return true
    }else{

      commonUtils.setLocalStorage("first.download.tip",1)
      resolve()

    }

    return false
  });

}

function isMobile() {
  var userAgentInfo = navigator.userAgent;
  var mobileAgents = [ "Android", "iPhone", "SymbianOS", "Windows Phone","iPod"];
  var mobile_flag = false;
  //根据userAgent判断是否是手机
  // console.log(userAgentInfo)
  for (var v = 0; v < mobileAgents.length; v++) {
    if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
      mobile_flag = true;
      break;
    }
  }
  var screen_width = window.screen.width;
  var screen_height = window.screen.height;
  //根据屏幕分辨率判断是否是手机
    if(screen_width < 500){
    mobile_flag = true;
  }
  return mobile_flag;
}

export default {
  deleteCookie,getCookie,md5PassWd,getSelfHeight,listUniq,hasChinese,hasEnglish,onlyChinese,getWindowWidth,autoRemFontSize
  ,varNotNull,objNotNull,scrollTop,getChinese,cpObj,getSelfWidth,getLocalStorage,deleteLocalStorage,setLocalStorage,moreThanBytesLimitAndTtim,
  getUrlParam,throttle,debounce,isGoogle,md5Str,checkNoticeCanShow,varIsNull,checkFirstDonwload,HTMLDecode,getNowDate,shortShow,isMobile
}
