import commonUtils from "../../../common/commonUtils";

const state = {
  bbs_doi_key:"medreading_bbs",
  bbs_rebot_req_token:"742048932c24a4732f8cc46bf6f5b1e8",
  bold_head_keys:["背景","目标","目的",'研究目的',"方法","结果","结论","病例报告",
    "综述目的","最近的发现","摘要","前言","导言","材料和方法","解释","经费","材料和方法",
    "病例描述","背景和目的","简介"]
}

// getters
const getters = {

}

// actions
const actions = {



}

// mutations
const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
