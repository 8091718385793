import commonUtils from "../../../common/commonUtils";

const state = {
     dataTypeMap:{
         "gene_name_keyword":"基因",
         "sigle_path_name_keyword":"信号通路",
         "disease_name_keyword":"疾病",
         "drug_name_keyword":"药物",
         "biological_function_name_keyword":"生物功能",

         "gene_name_keyword_summary":"基因",
         "sigle_path_name_keyword_summary":"信号通路",
         "disease_name_keyword_summary":"疾病",
         "drug_name_keyword_summary":"药物",
         "biological_function_name_keyword_summary":"生物功能"
     },
     dataTypeDefault:"gene",
     aggregation:[]
}

// getters
const getters = {

}

// actions
const actions = {



}

// mutations
const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
