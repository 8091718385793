import commonUtils from "../../../common/commonUtils";

const state = {
 journals:[
     {"name":"ADVANCED BIOSYSTEMS"},
     {"name":"PEDIATRIC ENDOCRINOLOGY REVIEWS PER"},
     {"name":"ANNUAL REVIEW OF EARTH AND PLANETARY SCIENCES"},
     {"name":"ANNUAL REVIEW OF PHYSIOLOGY"},
     {"name":"MEDICAL JOURNAL OF AUSTRALIA"},
     {"name":"ASTRONOMY AND ASTROPHYSICS REVIEW"},
     {"name":"JOURNAL OF TRAVEL MEDICINE"},
     {"name":"JOURNAL OF EXPERIMENTAL MEDICINE"},
     {"name":"ENVIRONMENT INTERNATIONAL"},
     {"name":"NANO ENERGY"},
     {"name":"NPJ PRECISION ONCOLOGY"},
     {"name":"NEOPLASIA"},
     {"name":"INTERNATIONAL JOURNAL OF BEHAVIORAL NUTRITION AND PHYSICAL ACTIVITY"},
     {"name":"MOLECULAR PSYCHIATRY"},
     {"name":"CHEMICAL REVIEWS"},
     {"name":"JOURNAL OF POWER SOURCES"},
     {"name":"INTERNET AND HIGHER EDUCATION"},
     {"name":"CLINICAL AND TRANSLATIONAL MEDICINE"},
     {"name":"INTERNATIONAL JOURNAL OF INTELLIGENT SYSTEMS"},
     {"name":"ISPRS JOURNAL OF PHOTOGRAMMETRY AND REMOTE SENSING"},
     {"name":"IEEE TRANSACTIONS ON INDUSTRIAL INFORMATICS"},
     {"name":"JOURNAL OF CATALYSIS"},
     {"name":"AMERICAN JOURNAL OF OBSTETRICS AND GYNECOLOGY"},
     {"name":"SOLAR RRL"},
     {"name":"TRENDS IN BIOCHEMICAL SCIENCES"},
     {"name":"SIGNAL TRANSDUCTION AND TARGETED THERAPY"},
     {"name":"JOURNAL OF HIGH ENERGY PHYSICS"},
     {"name":"JOURNAL OF BIOMEDICAL SCIENCE"},
     {"name":"NATURE NANOTECHNOLOGY"},
     {"name":"MSYSTEMS"},
     {"name":"NPJ REGENERATIVE MEDICINE"},
     {"name":"POLYMER REVIEWS"},
     {"name":"PROGRESS IN CRYSTAL GROWTH AND CHARACTERIZATION OF MATERIALS"},
     {"name":"ANNUAL REVIEW OF CHEMICAL AND BIOMOLECULAR ENGINEERING"},
     {"name":"AMERICAN JOURNAL OF PSYCHIATRY"},
     {"name":"GENOMICS"},
     {"name":"CONSERVATION BIOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF CONTEMPORARY HOSPITALITY MANAGEMENT"},
     {"name":"BRAIN STIMULATION"},
     {"name":"OPHTHALMOLOGY"},
     {"name":"CLINICAL NUTRITION"},
     {"name":"TRANSPORT REVIEWS"},
     {"name":"VIRULENCE"},
     {"name":"ACADEMY OF MANAGEMENT ANNALS"},
     {"name":"EPIDEMIOLOGY AND PSYCHIATRIC SCIENCES"},
     {"name":"ANNALS OF ONCOLOGY"},
     {"name":"OCULAR SURFACE"},
     {"name":"JOURNAL OF ENVIRONMENTAL MANAGEMENT"},
     {"name":"NEUROSCIENCE AND BIOBEHAVIORAL REVIEWS"},
     {"name":"PHYSIOLOGY"},
     {"name":"FREE RADICAL BIOLOGY AND MEDICINE"},
     {"name":"CANCER AND METASTASIS REVIEWS"},
     {"name":"PSYCHOLOGICAL REVIEW"},
     {"name":"ENDOSCOPY"},
     {"name":"JOURNAL OF INTERNAL MEDICINE"},
     {"name":"JOURNAL OF CHILD PSYCHOLOGY AND PSYCHIATRY"},
     {"name":"LANCET INFECTIOUS DISEASES"},
     {"name":"NPJ COMPUTATIONAL MATERIALS"},
     {"name":"NPJ PARKINSONS DISEASE"},
     {"name":"ADVANCES IN CARBOHYDRATE CHEMISTRY AND BIOCHEMISTRY"},
     {"name":"NEW PHYTOLOGIST"},
     {"name":"ANNUAL REVIEW OF ENVIRONMENT AND RESOURCES"},
     {"name":"IEEE TRANSACTIONS ON SOFTWARE ENGINEERING"},
     {"name":"ONCOIMMUNOLOGY"},
     {"name":"TRENDS IN CANCER"},
     {"name":"CRITICAL REVIEWS IN BIOCHEMISTRY AND MOLECULAR BIOLOGY"},
     {"name":"IEEE COMPUTATIONAL INTELLIGENCE MAGAZINE"},
     {"name":"ACADEMY OF MANAGEMENT JOURNAL"},
     {"name":"MOLECULAR PLANT"},
     {"name":"GENETICS IN MEDICINE"},
     {"name":"MBIO"},
     {"name":"CRITICAL REVIEWS IN MICROBIOLOGY"},
     {"name":"INFORMATION SCIENCES"},
     {"name":"BIOFABRICATION"},
     {"name":"ANNUAL REVIEW OF MATERIALS RESEARCH"},
     {"name":"LEUKEMIA"},
     {"name":"ARTIFICIAL INTELLIGENCE REVIEW"},
     {"name":"LANCET NEUROLOGY"},
     {"name":"HUMAN REPRODUCTION UPDATE"},
     {"name":"JOURNAL OF CLINICAL INVESTIGATION"},
     {"name":"EXPERT SYSTEMS WITH APPLICATIONS"},
     {"name":"QUARTERLY REVIEWS OF BIOPHYSICS"},
     {"name":"DIABETOLOGIA"},
     {"name":"IEEE TRANSACTIONS ON CYBERNETICS"},
     {"name":"DIALOGUES IN HUMAN GEOGRAPHY"},
     {"name":"IEEE INDUSTRIAL ELECTRONICS MAGAZINE"},
     {"name":"COLD SPRING HARBOR PERSPECTIVES IN MEDICINE"},
     {"name":"NUTRITION REVIEWS"},
     {"name":"METHODS IN ECOLOGY AND EVOLUTION"},
     {"name":"HEPATOLOGY"},
     {"name":"NATURE SUSTAINABILITY"},
     {"name":"CANCER"},
     {"name":"NATURE GEOSCIENCE"},
     {"name":"ANNUAL REVIEW OF NUCLEAR AND PARTICLE SCIENCE"},
     {"name":"SCIENCE ROBOTICS"},
     {"name":"LAB ANIMAL"},
     {"name":"ANNUAL REVIEW OF MARINE SCIENCE"},
     {"name":"CELL METABOLISM"},
     {"name":"NEUROPATHOLOGY AND APPLIED NEUROBIOLOGY"},
     {"name":"NATURE CELL BIOLOGY"},
     {"name":"AMERICAN JOURNAL OF GASTROENTEROLOGY"},
     {"name":"SPORTS MEDICINE"},
     {"name":"BASIC RESEARCH IN CARDIOLOGY"},
     {"name":"PHOTONICS RESEARCH"},
     {"name":"AUTOIMMUNITY REVIEWS"},
     {"name":"COMPREHENSIVE PHYSIOLOGY"},
     {"name":"MATERIALS RESEARCH LETTERS"},
     {"name":"CANCER TREATMENT REVIEWS"},
     {"name":"JOURNAL OF HAZARDOUS MATERIALS"},
     {"name":"CURRENT BIOLOGY"},
     {"name":"CLINICAL MICROBIOLOGY REVIEWS"},
     {"name":"REVIEW OF EDUCATIONAL RESEARCH"},
     {"name":"CLINICAL JOURNAL OF THE AMERICAN SOCIETY OF NEPHROLOGY"},
     {"name":"INTERNATIONAL MATERIALS REVIEWS"},
     {"name":"PSYCHOTHERAPY AND PSYCHOSOMATICS"},
     {"name":"INTERNATIONAL JOURNAL OF NEURAL SYSTEMS"},
     {"name":"IMMUNOLOGICAL REVIEWS"},
     {"name":"ANNUAL REVIEW OF CONDENSED MATTER PHYSICS"},
     {"name":"BEHAVIORAL AND BRAIN SCIENCES"},
     {"name":"ANNUAL REVIEW OF PLANT BIOLOGY"},
     {"name":"MOLECULAR CELL"},
     {"name":"ENVIRONMENTAL POLLUTION"},
     {"name":"RHEUMATOLOGY"},
     {"name":"NATURE REVIEWS NEPHROLOGY"},
     {"name":"EUROPEAN JOURNAL OF CANCER"},
     {"name":"LANCET"},
     {"name":"CELL REPORTS"},
     {"name":"GLOBAL ECOLOGY AND BIOGEOGRAPHY"},
     {"name":"NPJ 2D MATERIALS AND APPLICATIONS"},
     {"name":"SCIENCE"},
     {"name":"DIABETES CARE"},
     {"name":"ANNUAL REVIEW OF ANIMAL BIOSCIENCES"},
     {"name":"IEEE JOURNAL ON SELECTED AREAS IN COMMUNICATIONS"},
     {"name":"NATURE PROTOCOLS"},
     {"name":"PLOS BIOLOGY"},
     {"name":"JOURNAL OF RETAILING"},
     {"name":"COORDINATION CHEMISTRY REVIEWS"},
     {"name":"JOURNAL OF APPLIED ECOLOGY"},
     {"name":"ACTA NUMERICA"},
     {"name":"KIDNEY INTERNATIONAL SUPPLEMENTS"},
     {"name":"JOURNAL OF OCCUPATIONAL HEALTH PSYCHOLOGY"},
     {"name":"MACROMOLECULES"},
     {"name":"JOURNAL OF THE AMERICAN MATHEMATICAL SOCIETY"},
     {"name":"ANNALS OF SURGERY"},
     {"name":"JOURNAL OF NEUROINFLAMMATION"},
     {"name":"NATURE REVIEWS DISEASE PRIMERS"},
     {"name":"BMC BIOLOGY"},
     {"name":"TRENDS IN MOLECULAR MEDICINE"},
     {"name":"ANNUAL REVIEW OF PHARMACOLOGY AND TOXICOLOGY"},
     {"name":"MUCOSAL IMMUNOLOGY"},
     {"name":"JOURNAL OF NETWORK AND COMPUTER APPLICATIONS"},
     {"name":"AMERICAN JOURNAL OF HEMATOLOGY"},
     {"name":"CELL STEM CELL"},
     {"name":"ARTERIOSCLEROSIS THROMBOSIS AND VASCULAR BIOLOGY"},
     {"name":"BIOMATERIALS"},
     {"name":"JAMA INTERNAL MEDICINE"},
     {"name":"TRANSLATIONAL RESEARCH"},
     {"name":"METABOLIC ENGINEERING"},
     {"name":"JOURNAL OF CLINICAL ONCOLOGY"},
     {"name":"NATURE"},
     {"name":"EMBO MOLECULAR MEDICINE"},
     {"name":"HUMAN GENETICS"},
     {"name":"MOLECULAR THERAPY"},
     {"name":"ENERGY STORAGE MATERIALS"},
     {"name":"FRONTIERS IN ECOLOGY AND THE ENVIRONMENT"},
     {"name":"NATURE CHEMISTRY"},
     {"name":"NATURE MATERIALS"},
     {"name":"HAEMATOLOGICA"},
     {"name":"CHEMOSPHERE"},
     {"name":"IEEE TRANSACTIONS ON SUSTAINABLE ENERGY"},
     {"name":"JOURNAL OF CONSUMER RESEARCH"},
     {"name":"NATIONAL SCIENCE REVIEW"},
     {"name":"JOURNAL OF ECOLOGY"},
     {"name":"REVIEWS OF ENVIRONMENTAL CONTAMINATION AND TOXICOLOGY"},
     {"name":"JOURNAL OF CLINICAL MICROBIOLOGY"},
     {"name":"AMERICAN PSYCHOLOGIST"},
     {"name":"ENTREPRENEURSHIP THEORY AND PRACTICE"},
     {"name":"STROKE"},
     {"name":"CURRENT OPINION IN PLANT BIOLOGY"},
     {"name":"PLOS PATHOGENS"},
     {"name":"JOURNAL OF ABNORMAL PSYCHOLOGY"},
     {"name":"JOURNAL OF THE AMERICAN COLLEGE OF CARDIOLOGY"},
     {"name":"JOURNAL OF NUCLEAR MEDICINE"},
     {"name":"PERSOONIA"},
     {"name":"EUROPEAN JOURNAL OF EPIDEMIOLOGY"},
     {"name":"SEMINARS IN IMMUNOPATHOLOGY"},
     {"name":"NATURE REVIEWS CHEMISTRY"},
     {"name":"SCIENCE OF THE TOTAL ENVIRONMENT"},
     {"name":"COMPUTER METHODS IN APPLIED MECHANICS AND ENGINEERING"},
     {"name":"NATURE REVIEWS CLINICAL ONCOLOGY"},
     {"name":"NPG ASIA MATERIALS"},
     {"name":"ADVANCED MATERIALS"},
     {"name":"FERTILITY AND STERILITY"},
     {"name":"JOURNAL OF ADVANCED RESEARCH"},
     {"name":"AMERICAN JOURNAL OF HUMAN GENETICS"},
     {"name":"CRITICAL CARE MEDICINE"},
     {"name":"PSYCHOLOGICAL BULLETIN"},
     {"name":"PROGRESS IN PHOTOVOLTAICS"},
     {"name":"PROGRESS IN SOLID STATE CHEMISTRY"},
     {"name":"GENOME BIOLOGY"},
     {"name":"ENVIRONMENTAL INNOVATION AND SOCIETAL TRANSITIONS"},
     {"name":"NATURE REVIEWS MICROBIOLOGY"},
     {"name":"ANNUAL REVIEW OF IMMUNOLOGY"},
     {"name":"JOURNAL OF FINANCE"},
     {"name":"TRENDS IN ENVIRONMENTAL ANALYTICAL CHEMISTRY"},
     {"name":"IEEE TRANSACTIONS ON POWER ELECTRONICS"},
     {"name":"QUANTUM"},
     {"name":"COMPUTATIONAL AND STRUCTURAL BIOTECHNOLOGY JOURNAL"},
     {"name":"JOURNAL OF UROLOGY"},
     {"name":"NANO TODAY"},
     {"name":"IEEE TRANSACTIONS ON INTELLIGENT TRANSPORTATION SYSTEMS"},
     {"name":"CIRCULATION"},
     {"name":"NATURE HUMAN BEHAVIOUR"},
     {"name":"TRANSLATIONAL STROKE RESEARCH"},
     {"name":"ANNUAL REVIEW OF ENTOMOLOGY"},
     {"name":"IEEE TRANSACTIONS ON NEURAL NETWORKS AND LEARNING SYSTEMS"},
     {"name":"ASTROPHYSICAL JOURNAL SUPPLEMENT SERIES"},
     {"name":"MECHANICAL SYSTEMS AND SIGNAL PROCESSING"},
     {"name":"BIOINFORMATICS"},
     {"name":"JOURNAL OF THORACIC ONCOLOGY"},
     {"name":"ANNUAL REVIEW OF ORGANIZATIONAL PSYCHOLOGY AND ORGANIZATIONAL BEHAVIOR"},
     {"name":"BRAIN PATHOLOGY"},
     {"name":"MOLECULAR CANCER"},
     {"name":"PLANT PHYSIOLOGY"},
     {"name":"CURRENT OPINION IN STRUCTURAL BIOLOGY"},
     {"name":"AMERICAN JOURNAL OF PUBLIC HEALTH"},
     {"name":"ADVANCES IN CATALYSIS"},
     {"name":"BIORESOURCE TECHNOLOGY"},
     {"name":"COMMUNICATIONS OF THE ACM"},
     {"name":"CURRENT OPINION IN BIOTECHNOLOGY"},
     {"name":"CARDIOVASCULAR DIABETOLOGY"},
     {"name":"REPORTS ON PROGRESS IN PHYSICS"},
     {"name":"NATURE REVIEWS DRUG DISCOVERY"},
     {"name":"ACCOUNTS OF CHEMICAL RESEARCH"},
     {"name":"ADVANCES IN ECOLOGICAL RESEARCH"},
     {"name":"EUROPEAN JOURNAL OF NUCLEAR MEDICINE AND MOLECULAR IMAGING"},
     {"name":"MOLECULAR SYSTEMS BIOLOGY"},
     {"name":"ANAESTHESIA"},
     {"name":"SWARM AND EVOLUTIONARY COMPUTATION"},
     {"name":"IEEE TRANSACTIONS ON SMART GRID"},
     {"name":"CARDIOVASCULAR RESEARCH"},
     {"name":"ANNUAL REVIEW OF VIROLOGY"},
     {"name":"TRENDS IN PHARMACOLOGICAL SCIENCES"},
     {"name":"IEEE TRANSACTIONS ON MULTIMEDIA"},
     {"name":"ANNUAL REVIEW OF VISION SCIENCE"},
     {"name":"ANNUAL REVIEW OF GENETICS"},
     {"name":"JOURNAL OF POLITICAL ECONOMY"},
     {"name":"INTERNATIONAL JOURNAL OF HOSPITALITY MANAGEMENT"},
     {"name":"BIOLOGICAL REVIEWS"},
     {"name":"CARBON"},
     {"name":"ECONOMIC GEOGRAPHY"},
     {"name":"BIPOLAR DISORDERS"},
     {"name":"IEEE COMMUNICATIONS SURVEYS AND TUTORIALS"},
     {"name":"JOURNAL OF CLINICAL ANESTHESIA"},
     {"name":"JOURNAL OF EXPERIMENTAL BOTANY"},
     {"name":"EARTHS FUTURE"},
     {"name":"TECHNOVATION"},
     {"name":"APPLIED ENERGY"},
     {"name":"NANOSCALE HORIZONS"},
     {"name":"NATURE MEDICINE"},
     {"name":"PERSPECTIVES ON PSYCHOLOGICAL SCIENCE"},
     {"name":"TRENDS IN ENDOCRINOLOGY AND METABOLISM"},
     {"name":"JOURNAL OF CEREBRAL BLOOD FLOW AND METABOLISM"},
     {"name":"GREEN CHEMISTRY"},
     {"name":"INTERNATIONAL JOURNAL OF EPIDEMIOLOGY"},
     {"name":"SEMINARS IN IMMUNOLOGY"},
     {"name":"ADMINISTRATIVE SCIENCE QUARTERLY"},
     {"name":"JOURNAL OF THE INTERNATIONAL AIDS SOCIETY"},
     {"name":"CRITICAL REVIEWS IN FOOD SCIENCE AND NUTRITION"},
     {"name":"PROGRESS IN ENERGY AND COMBUSTION SCIENCE"},
     {"name":"SOLAR ENERGY MATERIALS AND SOLAR CELLS"},
     {"name":"IMPLEMENTATION SCIENCE"},
     {"name":"ANALYTICAL CHEMISTRY"},
     {"name":"ECOSYSTEM SERVICES"},
     {"name":"JAMA SURGERY"},
     {"name":"NPJ QUANTUM MATERIALS"},
     {"name":"REVIEWS OF MODERN PHYSICS"},
     {"name":"EUROPEAN UROLOGY"},
     {"name":"TRENDS IN NEUROSCIENCES"},
     {"name":"GENOME MEDICINE"},
     {"name":"PROGRESS IN POLYMER SCIENCE"},
     {"name":"EPIDEMIOLOGIC REVIEWS"},
     {"name":"BRITISH JOURNAL OF PHARMACOLOGY"},
     {"name":"ANNUAL REVIEW OF BIOPHYSICS"},
     {"name":"JOURNAL OF TRAVEL RESEARCH"},
     {"name":"SCIENCE SIGNALING"},
     {"name":"STEM CELLS TRANSLATIONAL MEDICINE"},
     {"name":"JOURNAL OF MATERIOMICS"},
     {"name":"NATURE REVIEWS RHEUMATOLOGY"},
     {"name":"ANNUAL REVIEW OF PHYTOPATHOLOGY"},
     {"name":"HARVARD LAW REVIEW"},
     {"name":"ANNUAL REVIEW OF MEDICINE"},
     {"name":"TRENDS IN PARASITOLOGY"},
     {"name":"MOLECULAR BIOLOGY AND EVOLUTION"},
     {"name":"CURRENT OPINION IN ELECTROCHEMISTRY"},
     {"name":"ADVANCED SCIENCE"},
     {"name":"ECOLOGY LETTERS"},
     {"name":"IEEE CONTROL SYSTEMS MAGAZINE"},
     {"name":"APPLIED MECHANICS REVIEWS"},
     {"name":"PHYSICAL REVIEW X"},
     {"name":"NEUROSCIENTIST"},
     {"name":"AMERICAN ECONOMIC REVIEW"},
     {"name":"LANCET PSYCHIATRY"},
     {"name":"ULTRASONICS SONOCHEMISTRY"},
     {"name":"TOURISM MANAGEMENT"},
     {"name":"JOURNAL OF COLLOID AND INTERFACE SCIENCE"},
     {"name":"ESSAYS IN BIOCHEMISTRY"},
     {"name":"CURRENT OPINION IN CHEMICAL BIOLOGY"},
     {"name":"JOULE"},
     {"name":"PLANT CELL AND ENVIRONMENT"},
     {"name":"APPLIED MATERIALS TODAY"},
     {"name":"ADVANCED ENERGY MATERIALS"},
     {"name":"FOOD HYDROCOLLOIDS"},
     {"name":"ADVANCED HEALTHCARE MATERIALS"},
     {"name":"CATALYSIS TODAY"},
     {"name":"QUARTERLY JOURNAL OF ECONOMICS"},
     {"name":"JOURNAL OF CELLULAR PHYSIOLOGY"},
     {"name":"ACTA BIOMATERIALIA"},
     {"name":"NATURE REVIEWS MATERIALS"},
     {"name":"STUDIES IN MYCOLOGY"},
     {"name":"SEMINARS IN CANCER BIOLOGY"},
     {"name":"JOURNAL OF THE AMERICAN SOCIETY OF NEPHROLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF ENGINEERING SCIENCE"},
     {"name":"LEADERSHIP QUARTERLY"},
     {"name":"EARTH SYSTEM SCIENCE DATA"},
     {"name":"INTERNATIONAL JOURNAL OF PLASTICITY"},
     {"name":"ANESTHESIOLOGY"},
     {"name":"PARTICLE AND FIBRE TOXICOLOGY"},
     {"name":"PROGRESS IN CARDIOVASCULAR DISEASES"},
     {"name":"INTENSIVE CARE MEDICINE"},
     {"name":"HEART RHYTHM"},
     {"name":"CHEST"},
     {"name":"CURRENT OPINION IN MICROBIOLOGY"},
     {"name":"ADVANCED OPTICAL MATERIALS"},
     {"name":"INTERNATIONAL SECURITY"},
     {"name":"IEEE TRANSACTIONS ON POWER SYSTEMS"},
     {"name":"PHYSIOLOGICAL REVIEWS"},
     {"name":"IEEE TRANSACTIONS ON INDUSTRIAL ELECTRONICS"},
     {"name":"ADVANCES IN OPTICS AND PHOTONICS"},
     {"name":"PERIODONTOLOGY 2000"},
     {"name":"JOURNAL OF PERSONALITY AND SOCIAL PSYCHOLOGY"},
     {"name":"ANGIOGENESIS"},
     {"name":"PLANT CELL"},
     {"name":"JAMA OPHTHALMOLOGY"},
     {"name":"ANNUAL REVIEW OF FOOD SCIENCE AND TECHNOLOGY"},
     {"name":"STEM CELL REPORTS"},
     {"name":"CELL RESEARCH"},
     {"name":"JAMA PEDIATRICS"},
     {"name":"FORBES"},
     {"name":"CHEMSUSCHEM"},
     {"name":"HUMAN REPRODUCTION"},
     {"name":"ACTA NEUROPATHOLOGICA COMMUNICATIONS"},
     {"name":"FUEL"},
     {"name":"JOURNAL OF MEMBRANE SCIENCE"},
     {"name":"NATURE NEUROSCIENCE"},
     {"name":"EUROSURVEILLANCE"},
     {"name":"BRITISH JOURNAL OF HAEMATOLOGY"},
     {"name":"EUROPEAN RESPIRATORY JOURNAL"},
     {"name":"ANNUAL REVIEW OF ASTRONOMY AND ASTROPHYSICS"},
     {"name":"ADVANCES IN APPLIED MECHANICS"},
     {"name":"NATURE GENETICS"},
     {"name":"MAYO CLINIC PROCEEDINGS"},
     {"name":"SCIENCE ADVANCES"},
     {"name":"CELL BIOLOGY AND TOXICOLOGY"},
     {"name":"RESOURCES CONSERVATION AND RECYCLING"},
     {"name":"WATER RESEARCH"},
     {"name":"MASS SPECTROMETRY REVIEWS"},
     {"name":"AMERICAN JOURNAL OF RESPIRATORY AND CRITICAL CARE MEDICINE"},
     {"name":"SYSTEMATIC BIOLOGY"},
     {"name":"CORROSION SCIENCE"},
     {"name":"ASTRONOMICAL JOURNAL"},
     {"name":"GLIA"},
     {"name":"CELLULAR AND MOLECULAR GASTROENTEROLOGY AND HEPATOLOGY"},
     {"name":"ANNALS OF TOURISM RESEARCH"},
     {"name":"ARTIFICIAL INTELLIGENCE"},
     {"name":"COMPREHENSIVE REVIEWS IN FOOD SCIENCE AND FOOD SAFETY"},
     {"name":"IEEE NETWORK"},
     {"name":"EUROPEAN HEART JOURNAL"},
     {"name":"WASTE MANAGEMENT"},
     {"name":"IEEE TRANSACTIONS ON COMMUNICATIONS"},
     {"name":"PROGRESS IN LIPID RESEARCH"},
     {"name":"ENDOCRINE REVIEWS"},
     {"name":"MICROBIOLOGY AND MOLECULAR BIOLOGY REVIEWS"},
     {"name":"DIALOGUES IN CLINICAL NEUROSCIENCE"},
     {"name":"PHYSICAL REVIEW LETTERS"},
     {"name":"CANCER DISCOVERY"},
     {"name":"GUT"},
     {"name":"PSYCHOLOGICAL MEDICINE"},
     {"name":"SMALL METHODS"},
     {"name":"CLINICAL PSYCHOLOGICAL SCIENCE"},
     {"name":"ANNUAL REVIEW OF PSYCHOLOGY"},
     {"name":"BROOKINGS PAPERS ON ECONOMIC ACTIVITY"},
     {"name":"JOURNAL OF SERVICE RESEARCH"},
     {"name":"CURRENT CLIMATE CHANGE REPORTS"},
     {"name":"NATURE REVIEWS NEUROLOGY"},
     {"name":"JOURNAL OF SUPPLY CHAIN MANAGEMENT"},
     {"name":"MOLECULAR ASPECTS OF MEDICINE"},
     {"name":"CHEMICAL SOCIETY REVIEWS"},
     {"name":"NATURE REVIEWS IMMUNOLOGY"},
     {"name":"IEEE WIRELESS COMMUNICATIONS"},
     {"name":"EBIOMEDICINE"},
     {"name":"ASTROPHYSICAL JOURNAL LETTERS"},
     {"name":"PSYCHOLOGICAL METHODS"},
     {"name":"FEMS MICROBIOLOGY REVIEWS"},
     {"name":"NEUROLOGY"},
     {"name":"GASTRIC CANCER"},
     {"name":"THERANOSTICS"},
     {"name":"NANOPHOTONICS"},
     {"name":"NEURON"},
     {"name":"BIOMATERIALS SCIENCE"},
     {"name":"ACS ENERGY LETTERS"},
     {"name":"EMBO REPORTS"},
     {"name":"CEMENT AND CONCRETE RESEARCH"},
     {"name":"CELLULAR AND MOLECULAR LIFE SCIENCES"},
     {"name":"ANNUAL REVIEW OF NUTRITION"},
     {"name":"TRENDS IN IMMUNOLOGY"},
     {"name":"PHARMACOLOGICAL RESEARCH"},
     {"name":"ACS CATALYSIS"},
     {"name":"ENGINEERING APPLICATIONS OF COMPUTATIONAL FLUID MECHANICS"},
     {"name":"IEEE TRANSACTIONS ON DEPENDABLE AND SECURE COMPUTING"},
     {"name":"TRENDS IN COGNITIVE SCIENCES"},
     {"name":"LANCET HAEMATOLOGY"},
     {"name":"ASTROPHYSICAL JOURNAL"},
     {"name":"MICROCHIMICA ACTA"},
     {"name":"NATURE CHEMICAL BIOLOGY"},
     {"name":"CHEMICAL COMMUNICATIONS"},
     {"name":"IEEE TRANSACTIONS ON EMERGING TOPICS IN COMPUTING"},
     {"name":"JOURNAL OF CLIMATE"},
     {"name":"CRITICAL REVIEWS IN SOLID STATE AND MATERIALS SCIENCES"},
     {"name":"PROGRESS IN NEUROBIOLOGY"},
     {"name":"JOURNAL OF CACHEXIA SARCOPENIA AND MUSCLE"},
     {"name":"BLOOD REVIEWS"},
     {"name":"LANCET ONCOLOGY"},
     {"name":"NATURE REVIEWS ENDOCRINOLOGY"},
     {"name":"NPJ GENOMIC MEDICINE"},
     {"name":"ENERGY CONVERSION AND MANAGEMENT"},
     {"name":"TOBACCO CONTROL"},
     {"name":"ADVANCES IN PHYSICS"},
     {"name":"IEEE SIGNAL PROCESSING MAGAZINE"},
     {"name":"ADVANCES IN PARASITOLOGY"},
     {"name":"JOURNAL OF THE AMERICAN CHEMICAL SOCIETY"},
     {"name":"IEEE TRANSACTIONS ON TRANSPORTATION ELECTRIFICATION"},
     {"name":"SURFACE SCIENCE REPORTS"},
     {"name":"IEEE GEOSCIENCE AND REMOTE SENSING MAGAZINE"},
     {"name":"JOURNAL OF BONE AND MINERAL RESEARCH"},
     {"name":"JOURNAL OF THE ACADEMY OF MARKETING SCIENCE"},
     {"name":"BLOOD CANCER JOURNAL"},
     {"name":"BRITISH JOURNAL OF CANCER"},
     {"name":"SPACE SCIENCE REVIEWS"},
     {"name":"PHOTOACOUSTICS"},
     {"name":"JOURNAL OF PINEAL RESEARCH"},
     {"name":"ANNUAL REVIEW OF FLUID MECHANICS"},
     {"name":"BRITISH JOURNAL OF SURGERY"},
     {"name":"INTERNATIONAL JOURNAL OF MANAGEMENT REVIEWS"},
     {"name":"AGING AND DISEASE"},
     {"name":"2D MATERIALS"},
     {"name":"ANNUAL REVIEW OF NEUROSCIENCE"},
     {"name":"CHINESE JOURNAL OF CATALYSIS"},
     {"name":"ADVANCES IN NUTRITION"},
     {"name":"CONSERVATION LETTERS"},
     {"name":"IEEE TRANSACTIONS ON MEDICAL IMAGING"},
     {"name":"JOURNAL OF STATISTICAL SOFTWARE"},
     {"name":"TRANSLATIONAL NEURODEGENERATION"},
     {"name":"GLOBAL CHANGE BIOLOGY"},
     {"name":"JOURNAL OF ADVERTISING"},
     {"name":"RENEWABLE ENERGY"},
     {"name":"CHEM"},
     {"name":"VIRUS EVOLUTION"},
     {"name":"JOURNAL OF INTERNATIONAL BUSINESS STUDIES"},
     {"name":"NUTRITION RESEARCH REVIEWS"},
     {"name":"AMERICAN JOURNAL OF TRANSPLANTATION"},
     {"name":"DESALINATION"},
     {"name":"NEURAL NETWORKS"},
     {"name":"ACS SENSORS"},
     {"name":"DRUGS"},
     {"name":"MATRIX BIOLOGY"},
     {"name":"NATURE MICROBIOLOGY"},
     {"name":"PROGRESS IN MATERIALS SCIENCE"},
     {"name":"EDUCATIONAL RESEARCH REVIEW"},
     {"name":"GONDWANA RESEARCH"},
     {"name":"IEEE TRANSACTIONS ON SERVICES COMPUTING"},
     {"name":"JOURNAL OF ALLERGY AND CLINICAL IMMUNOLOGY"},
     {"name":"GUT MICROBES"},
     {"name":"JOURNAL OF APPLIED PSYCHOLOGY"},
     {"name":"SURVEYS IN GEOPHYSICS"},
     {"name":"NATURE REVIEWS UROLOGY"},
     {"name":"SOFT ROBOTICS"},
     {"name":"STRATEGIC ENTREPRENEURSHIP JOURNAL"},
     {"name":"JOURNAL OF MATERIALS CHEMISTRY C"},
     {"name":"PAIN"},
     {"name":"SCHIZOPHRENIA BULLETIN"},
     {"name":"ENERGY"},
     {"name":"REVIEWS IN AQUACULTURE"},
     {"name":"CLINICAL CANCER RESEARCH"},
     {"name":"JOURNAL OF CLEANER PRODUCTION"},
     {"name":"ONCOGENESIS"},
     {"name":"COCHRANE DATABASE OF SYSTEMATIC REVIEWS"},
     {"name":"ACS CENTRAL SCIENCE"},
     {"name":"CANCER CELL"},
     {"name":"JOURNAL OF THE AMERICAN SOCIETY OF ECHOCARDIOGRAPHY"},
     {"name":"EXPERT OPINION ON THERAPEUTIC PATENTS"},
     {"name":"CLINICAL CHEMISTRY"},
     {"name":"MATERIALS TODAY ENERGY"},
     {"name":"CRITICAL REVIEWS IN BIOTECHNOLOGY"},
     {"name":"EMBO JOURNAL"},
     {"name":"REMOTE SENSING OF ENVIRONMENT"},
     {"name":"JOURNAL OF CONTROLLED RELEASE"},
     {"name":"TRENDS IN PLANT SCIENCE"},
     {"name":"EPILEPSIA"},
     {"name":"PROCEEDINGS OF THE NUTRITION SOCIETY"},
     {"name":"NEW ENGLAND JOURNAL OF MEDICINE"},
     {"name":"ECOGRAPHY"},
     {"name":"IEEE TRANSACTIONS ON ROBOTICS"},
     {"name":"JAMA DERMATOLOGY"},
     {"name":"RADIOLOGY"},
     {"name":"NATURE CLIMATE CHANGE"},
     {"name":"ADDITIVE MANUFACTURING"},
     {"name":"ENVIRONMENTAL HEALTH PERSPECTIVES"},
     {"name":"SMALL"},
     {"name":"TRENDS IN GENETICS"},
     {"name":"PROCEEDINGS OF THE NATIONAL ACADEMY OF SCIENCES OF THE UNITED STATES OF AMERICA"},
     {"name":"IEEE TRANSACTIONS ON INFORMATION FORENSICS AND SECURITY"},
     {"name":"NATURE PHYSICS"},
     {"name":"DIABETES"},
     {"name":"ACTA NEUROPATHOLOGICA"},
     {"name":"PLANT BIOTECHNOLOGY JOURNAL"},
     {"name":"PROCEEDINGS OF THE COMBUSTION INSTITUTE"},
     {"name":"REDOX BIOLOGY"},
     {"name":"NUCLEAR DATA SHEETS"},
     {"name":"JOURNAL OF ENERGY CHEMISTRY"},
     {"name":"MICROBIOME"},
     {"name":"PLOS MEDICINE"},
     {"name":"NATURE METHODS"},
     {"name":"APPLIED PHYSICS REVIEWS"},
     {"name":"IEEE COMMUNICATIONS MAGAZINE"},
     {"name":"BRAIN"},
     {"name":"ENVIRONMENTAL RESEARCH LETTERS"},
     {"name":"ECOLOGICAL MONOGRAPHS"},
     {"name":"MEDICINAL RESEARCH REVIEWS"},
     {"name":"ANNUAL REVIEW OF ANALYTICAL CHEMISTRY"},
     {"name":"BULLETIN OF THE WORLD HEALTH ORGANIZATION"},
     {"name":"CIRCULATION RESEARCH"},
     {"name":"CHEMICAL RECORD"},
     {"name":"DRUG RESISTANCE UPDATES"},
     {"name":"JOURNAL OF GINSENG RESEARCH"},
     {"name":"BIOTECHNOLOGY ADVANCES"},
     {"name":"SOCIAL ISSUES AND POLICY REVIEW"},
     {"name":"MOLECULAR NEURODEGENERATION"},
     {"name":"PROGRESS IN PARTICLE AND NUCLEAR PHYSICS"},
     {"name":"CLINICAL GASTROENTEROLOGY AND HEPATOLOGY"},
     {"name":"ADDICTION"},
     {"name":"IEEE TRANSACTIONS ON AUTOMATIC CONTROL"},
     {"name":"INTERNATIONAL JOURNAL OF PROJECT MANAGEMENT"},
     {"name":"ELIFE"},
     {"name":"TRENDS IN BIOTECHNOLOGY"},
     {"name":"ANNALS OF NEUROLOGY"},
     {"name":"NATURE REVIEWS GENETICS"},
     {"name":"JOURNAL OF AUTOIMMUNITY"},
     {"name":"AMERICAN JOURNAL OF BIOETHICS"},
     {"name":"CLINICAL MICROBIOLOGY AND INFECTION"},
     {"name":"WHO TECHNICAL REPORT SERIES"},
     {"name":"YALE LAW JOURNAL"},
     {"name":"ATMOSPHERIC CHEMISTRY AND PHYSICS"},
     {"name":"PLANT JOURNAL"},
     {"name":"ENVIRONMENTAL SCIENCES EUROPE"},
     {"name":"THEORETICAL LINGUISTICS"},
     {"name":"NATURE REVIEWS CANCER"},
     {"name":"AUTOMATION IN CONSTRUCTION"},
     {"name":"ACTA MATERIALIA"},
     {"name":"APPLIED SOFT COMPUTING"},
     {"name":"SIAM REVIEW"},
     {"name":"PROGRESS IN NUCLEAR MAGNETIC RESONANCE SPECTROSCOPY"},
     {"name":"EXPERIMENTAL AND MOLECULAR MEDICINE"},
     {"name":"NANOSCALE"},
     {"name":"COMPOSITES SCIENCE AND TECHNOLOGY"},
     {"name":"ENGINEERING"},
     {"name":"ARCHIVES OF COMPUTATIONAL METHODS IN ENGINEERING"},
     {"name":"LANCET PUBLIC HEALTH"},
     {"name":"LAB ON A CHIP"},
     {"name":"JOURNAL OF HEPATOLOGY"},
     {"name":"IEEE TRANSACTIONS ON GEOSCIENCE AND REMOTE SENSING"},
     {"name":"MALACOLOGIA"},
     {"name":"MOVEMENT DISORDERS"},
     {"name":"JOURNAL OF MEDICINAL CHEMISTRY"},
     {"name":"JCI INSIGHT"},
     {"name":"JOURNAL OF PHYSICAL CHEMISTRY LETTERS"},
     {"name":"JOURNAL OF ANTIMICROBIAL CHEMOTHERAPY"},
     {"name":"PSYCHOLOGICAL INQUIRY"},
     {"name":"ACM COMPUTING SURVEYS"},
     {"name":"ANNUAL REVIEW OF PUBLIC HEALTH"},
     {"name":"ENVIRONMENTAL CHEMISTRY LETTERS"},
     {"name":"NATURE CATALYSIS"},
     {"name":"ACADEMY OF MANAGEMENT REVIEW"},
     {"name":"NEUROBIOLOGY OF STRESS"},
     {"name":"CURRENT OPINION IN CELL BIOLOGY"},
     {"name":"MOLECULAR AUTISM"},
     {"name":"CRITICAL REVIEWS IN ENVIRONMENTAL SCIENCE AND TECHNOLOGY"},
     {"name":"BIOACTIVE MATERIALS"},
     {"name":"CANCERS"},
     {"name":"BIOLOGY AND FERTILITY OF SOILS"},
     {"name":"ANNUAL REVIEW OF SOCIOLOGY"},
     {"name":"SCIENTIFIC DATA"},
     {"name":"THERAPEUTIC ADVANCES IN MEDICAL ONCOLOGY"},
     {"name":"BRITISH JOURNAL OF ANAESTHESIA"},
     {"name":"POLITICAL COMMUNICATION"},
     {"name":"ACS PHOTONICS"},
     {"name":"AUTOMATICA"},
     {"name":"JOURNAL OF HEART AND LUNG TRANSPLANTATION"},
     {"name":"CANCER RESEARCH"},
     {"name":"ORGANIZATIONAL RESEARCH METHODS"},
     {"name":"CURRENT OPINION IN NEUROBIOLOGY"},
     {"name":"STRATEGIC MANAGEMENT JOURNAL"},
     {"name":"JOURNAL OF ECONOMIC PERSPECTIVES"},
     {"name":"BMC MEDICINE"},
     {"name":"INTERNATIONAL JOURNAL OF RADIATION ONCOLOGY BIOLOGY PHYSICS"},
     {"name":"LIVING REVIEWS IN SOLAR PHYSICS"},
     {"name":"CELL DEATH AND DIFFERENTIATION"},
     {"name":"MICROBIOLOGY SPECTRUM"},
     {"name":"IUCRJ"},
     {"name":"OBESITY REVIEWS"},
     {"name":"NUCLEIC ACIDS RESEARCH"},
     {"name":"NATURE ELECTRONICS"},
     {"name":"BRITISH JOURNAL OF DERMATOLOGY"},
     {"name":"JAMA NEUROLOGY"},
     {"name":"NATURAL PRODUCT REPORTS"},
     {"name":"JOURNAL OF CO2 UTILIZATION"},
     {"name":"SLEEP MEDICINE REVIEWS"},
     {"name":"JOURNAL OF NANOBIOTECHNOLOGY"},
     {"name":"NATURE PLANTS"},
     {"name":"ANNUAL REVIEW OF MICROBIOLOGY"},
     {"name":"CANCER IMMUNOLOGY IMMUNOTHERAPY"},
     {"name":"CRITICAL REVIEWS IN PLANT SCIENCES"},
     {"name":"BIOSCIENCE"},
     {"name":"HYPERTENSION"},
     {"name":"BUSINESS STRATEGY AND THE ENVIRONMENT"},
     {"name":"ANNALS OF THE RHEUMATIC DISEASES"},
     {"name":"EUROPEAN RESPIRATORY REVIEW"},
     {"name":"MODERN PATHOLOGY"},
     {"name":"CHEMISTRY OF MATERIALS"},
     {"name":"CURRENT OPINION IN ENVIRONMENTAL SUSTAINABILITY"},
     {"name":"GIGASCIENCE"},
     {"name":"IEEE TRANSACTIONS ON PATTERN ANALYSIS AND MACHINE INTELLIGENCE"},
     {"name":"STEM CELLS"},
     {"name":"CANCER COMMUNICATIONS"},
     {"name":"ADVANCES IN EXPERIMENTAL SOCIAL PSYCHOLOGY"},
     {"name":"AMERICAN JOURNAL OF SPORTS MEDICINE"},
     {"name":"KIDNEY INTERNATIONAL"},
     {"name":"CURRENT OPINION IN IMMUNOLOGY"},
     {"name":"CRITICAL REVIEWS IN ONCOLOGY HEMATOLOGY"},
     {"name":"CANADIAN MEDICAL ASSOCIATION JOURNAL"},
     {"name":"DEVELOPMENTAL CELL"},
     {"name":"PROGRESS IN AEROSPACE SCIENCES"},
     {"name":"PERSONALITY AND SOCIAL PSYCHOLOGY REVIEW"},
     {"name":"CHEMICAL SCIENCE"},
     {"name":"JOURNAL OF INDUSTRIAL ECOLOGY"},
     {"name":"ANNUAL REVIEW OF PHYSICAL CHEMISTRY"},
     {"name":"JOURNAL OF INVESTIGATIVE DERMATOLOGY"},
     {"name":"JOURNAL OF MATERIALS CHEMISTRY A"},
     {"name":"ORGANIC LETTERS"},
     {"name":"CANCER IMMUNOLOGY RESEARCH"},
     {"name":"OBSTETRICS AND GYNECOLOGY"},
     {"name":"LANDSCAPE AND URBAN PLANNING"},
     {"name":"JOURNAL OF MANAGEMENT"},
     {"name":"AUTOPHAGY"},
     {"name":"NATURE BIOMEDICAL ENGINEERING"},
     {"name":"ACTA PHYSIOLOGICA"},
     {"name":"IMMUNITY"},
     {"name":"NATURE REVIEWS NEUROSCIENCE"},
     {"name":"CELL CHEMICAL BIOLOGY"},
     {"name":"ANNUAL REVIEW OF BIOMEDICAL ENGINEERING"},
     {"name":"EUROPEAN JOURNAL OF PREVENTIVE CARDIOLOGY"},
     {"name":"JOURNAL OF FINANCIAL ECONOMICS"},
     {"name":"JOURNAL OF STROKE"},
     {"name":"JOURNAL OF BUSINESS VENTURING"},
     {"name":"INORGANIC CHEMISTRY FRONTIERS"},
     {"name":"LANCET GLOBAL HEALTH"},
     {"name":"NPJ BIOFILMS AND MICROBIOMES"},
     {"name":"JAMA PSYCHIATRY"},
     {"name":"ANNUAL REVIEW OF CRIMINOLOGY"},
     {"name":"HORTICULTURE RESEARCH"},
     {"name":"AGEING RESEARCH REVIEWS"},
     {"name":"RIVISTA DEL NUOVO CIMENTO"},
     {"name":"TECHNOLOGICAL FORECASTING AND SOCIAL CHANGE"},
     {"name":"NATURE PHOTONICS"},
     {"name":"NEUROPSYCHOPHARMACOLOGY"},
     {"name":"ISME JOURNAL"},
     {"name":"MATERIALS TODAY PHYSICS"},
     {"name":"SCIENCE BULLETIN"},
     {"name":"CLINICAL NUCLEAR MEDICINE"},
     {"name":"GASTROENTEROLOGY"},
     {"name":"PROGRESS IN QUANTUM ELECTRONICS"},
     {"name":"PSYCHOLOGICAL SCIENCE IN THE PUBLIC INTEREST"},
     {"name":"FOOD CHEMISTRY"},
     {"name":"EUROPEAN JOURNAL OF HEART FAILURE"},
     {"name":"DRUG DISCOVERY TODAY"},
     {"name":"GENOME RESEARCH"},
     {"name":"CHEMICAL ENGINEERING JOURNAL"},
     {"name":"ACTA PHARMACEUTICA SINICA B"},
     {"name":"LANCET RESPIRATORY MEDICINE"},
     {"name":"JAMA ONCOLOGY"},
     {"name":"NANO RESEARCH"},
     {"name":"ELECTROCHIMICA ACTA"},
     {"name":"PATTERN RECOGNITION"},
     {"name":"NPJ VACCINES"},
     {"name":"LIVING REVIEWS IN RELATIVITY"},
     {"name":"MULTIPLE SCLEROSIS JOURNAL"},
     {"name":"ANNALS OF INTERNAL MEDICINE"},
     {"name":"PROCEEDINGS OF THE IEEE"},
     {"name":"JOURNAL OF MAGNESIUM AND ALLOYS"},
     {"name":"JOURNAL OF CLINICAL IMMUNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF INFORMATION MANAGEMENT"},
     {"name":"INFORMATION FUSION"},
     {"name":"BULLETIN OF THE AMERICAN METEOROLOGICAL SOCIETY"},
     {"name":"ACS MACRO LETTERS"},
     {"name":"REVIEW OF ENVIRONMENTAL ECONOMICS AND POLICY"},
     {"name":"NATURE ENERGY"},
     {"name":"JOURNAL OF PATHOLOGY"},
     {"name":"IEEE TRANSACTIONS ON EVOLUTIONARY COMPUTATION"},
     {"name":"ANNUAL REVIEW OF ECOLOGY EVOLUTION AND SYSTEMATICS"},
     {"name":"CLINICAL PSYCHOLOGY REVIEW"},
     {"name":"WORLD PSYCHIATRY"},
     {"name":"NATURE REVIEWS MOLECULAR CELL BIOLOGY"},
     {"name":"AMERICAN JOURNAL OF KIDNEY DISEASES"},
     {"name":"REGIONAL ANESTHESIA AND PAIN MEDICINE"},
     {"name":"BRITISH JOURNAL OF SPORTS MEDICINE"},
     {"name":"JAMA CARDIOLOGY"},
     {"name":"JOURNAL OF MOLECULAR DIAGNOSTICS"},
     {"name":"CELL"},
     {"name":"ANNUAL REVIEW OF CELL AND DEVELOPMENTAL BIOLOGY"},
     {"name":"BIOMACROMOLECULES"},
     {"name":"IEEE VEHICULAR TECHNOLOGY MAGAZINE"},
     {"name":"LIVER CANCER"},
     {"name":"GASTROINTESTINAL ENDOSCOPY"},
     {"name":"APPLIED SURFACE SCIENCE"},
     {"name":"PROGRESS IN HUMAN GEOGRAPHY"},
     {"name":"MATERIALS TODAY"},
     {"name":"NATURE BIOTECHNOLOGY"},
     {"name":"HARVARD BUSINESS REVIEW"},
     {"name":"ENVIRONMENTAL RESEARCH"},
     {"name":"NPJ BREAST CANCER"},
     {"name":"SCIENCE IMMUNOLOGY"},
     {"name":"PROGRESS IN SURFACE SCIENCE"},
     {"name":"IEEE TRANSACTIONS ON FUZZY SYSTEMS"},
     {"name":"BONE RESEARCH"},
     {"name":"AMERICAN JOURNAL OF CLINICAL NUTRITION"},
     {"name":"CARBOHYDRATE POLYMERS"},
     {"name":"THORAX"},
     {"name":"ADVANCED MATERIALS TECHNOLOGIES"},
     {"name":"JOURNAL OF INDUSTRIAL INFORMATION INTEGRATION"},
     {"name":"ADVANCED DRUG DELIVERY REVIEWS"},
     {"name":"EUROPEAN JOURNAL OF MEDICINAL CHEMISTRY"},
     {"name":"SCIENCE TRANSLATIONAL MEDICINE"},
     {"name":"JOURNAL FOR IMMUNOTHERAPY OF CANCER"},
     {"name":"TRENDS IN CELL BIOLOGY"},
     {"name":"JOURNAL OF THE AMERICAN ACADEMY OF DERMATOLOGY"},
     {"name":"PERSONNEL PSYCHOLOGY"},
     {"name":"JOURNAL OF NEUROSCIENCE"},
     {"name":"JOURNAL OF CELL BIOLOGY"},
     {"name":"FISH AND FISHERIES"},
     {"name":"NATURE REVIEWS CARDIOLOGY"},
     {"name":"IEEE INTERNET OF THINGS JOURNAL"},
     {"name":"ANALYTICA CHIMICA ACTA"},
     {"name":"BRAIN BEHAVIOR AND IMMUNITY"},
     {"name":"CELL DISCOVERY"},
     {"name":"VIRTUAL AND PHYSICAL PROTOTYPING"},
     {"name":"ANALYTIC METHODS IN ACCIDENT RESEARCH"},
     {"name":"COLD SPRING HARBOR PERSPECTIVES IN BIOLOGY"},
     {"name":"AGING CELL"},
     {"name":"REVIEWS OF GEOPHYSICS"},
     {"name":"CRITICAL CARE"},
     {"name":"DIGITAL COMMUNICATIONS AND NETWORKS"},
     {"name":"CELL SYSTEMS"},
     {"name":"INTERNATIONAL JOURNAL OF COAL GEOLOGY"},
     {"name":"ONCOGENE"},
     {"name":"PAIN AND THERAPY"},
     {"name":"OPTICA"},
     {"name":"NATURE IMMUNOLOGY"},
     {"name":"IEEE TRANSACTIONS ON IMAGE PROCESSING"},
     {"name":"NATURE COMMUNICATIONS"},
     {"name":"INTERNATIONAL REVIEW OF SPORT AND EXERCISE PSYCHOLOGY"},
     {"name":"JOURNAL OF NEUROLOGY NEUROSURGERY AND PSYCHIATRY"},
     {"name":"MATERIALS CHEMISTRY FRONTIERS"},
     {"name":"ADVANCED ELECTRONIC MATERIALS"},
     {"name":"NATURE ASTRONOMY"},
     {"name":"CELL PROLIFERATION"},
     {"name":"BIOLOGICAL PSYCHIATRY"},
     {"name":"FRONTIERS IN NEUROENDOCRINOLOGY"},
     {"name":"CLINICAL INFECTIOUS DISEASES"},
     {"name":"JOURNAL OF PHYSIOTHERAPY"},
     {"name":"EXPERT OPINION ON THERAPEUTIC TARGETS"},
     {"name":"BRIEFINGS IN BIOINFORMATICS"},
     {"name":"CANCER LETTERS"},
     {"name":"INTERNATIONAL JOURNAL OF COMPUTER VISION"},
     {"name":"PHYSICS OF LIFE REVIEWS"},
     {"name":"MOLECULAR CANCER THERAPEUTICS"},
     {"name":"ADVANCES IN COLLOID AND INTERFACE SCIENCE"},
     {"name":"ACS NANO"},
     {"name":"MOLECULAR ONCOLOGY"},
     {"name":"MOLECULAR METABOLISM"},
     {"name":"JOURNAL OF THE AMERICAN ACADEMY OF CHILD AND ADOLESCENT PSYCHIATRY"},
     {"name":"MEDICAL IMAGE ANALYSIS"},
     {"name":"AMERICAN SOCIOLOGICAL REVIEW"},
     {"name":"MATERIALS HORIZONS"},
     {"name":"NANO LETTERS"},
     {"name":"LANCET HIV"},
     {"name":"NANOIMPACT"},
     {"name":"ANNUAL REVIEW OF CLINICAL PSYCHOLOGY"},
     {"name":"IEEE TRANSACTIONS ON WIRELESS COMMUNICATIONS"},
     {"name":"IEEE TRANSACTIONS ON AFFECTIVE COMPUTING"},
     {"name":"BRITISH JOURNAL OF PSYCHIATRY"},
     {"name":"JOURNAL OF THE NATIONAL COMPREHENSIVE CANCER NETWORK"},
     {"name":"NEUROTHERAPEUTICS"},
     {"name":"BLOOD"},
     {"name":"JOURNAL OF EXTRACELLULAR VESICLES"},
     {"name":"MOLECULAR ECOLOGY RESOURCES"},
     {"name":"NEUROIMAGE"},
     {"name":"JOURNAL OF GASTROENTEROLOGY"},
     {"name":"SCIENCE AND TECHNOLOGY OF ADVANCED MATERIALS"},
     {"name":"ANNALS OF EMERGENCY MEDICINE"},
     {"name":"EMERGING INFECTIOUS DISEASES"},
     {"name":"COMPUTER SCIENCE REVIEW"},
     {"name":"PHARMACOLOGICAL REVIEWS"},
     {"name":"PROGRESS IN RETINAL AND EYE RESEARCH"},
     {"name":"TOPICS IN CURRENT CHEMISTRY"},
     {"name":"ALLERGY"},
     {"name":"ANNUAL REVIEW OF GENOMICS AND HUMAN GENETICS"},
     {"name":"SEPARATION AND PURIFICATION TECHNOLOGY"},
     {"name":"FUNGAL DIVERSITY"},
     {"name":"TRENDS IN MICROBIOLOGY"},
     {"name":"DEVELOPMENT"},
     {"name":"JOURNAL OF ECONOMIC LITERATURE"},
     {"name":"ADVANCED FUNCTIONAL MATERIALS"},
     {"name":"ANNUAL REVIEW OF BIOCHEMISTRY"},
     {"name":"NPJ QUANTUM INFORMATION"},
     {"name":"JOURNAL OF NUTRITION"},
     {"name":"BONE"},
     {"name":"CURRENT OPINION IN VIROLOGY"},
     {"name":"RESOURCES POLICY"},
     {"name":"EUROPEAN UROLOGY FOCUS"},
     {"name":"COMPUTERS IN HUMAN BEHAVIOR"},
     {"name":"FASEB JOURNAL"},
     {"name":"JOURNAL OF MEDICAL INTERNET RESEARCH"},
     {"name":"ECOLOGICAL APPLICATIONS"},
     {"name":"JOURNAL OF CLINICAL PSYCHIATRY"},
     {"name":"JOURNAL OF INTELLIGENT MANUFACTURING"},
     {"name":"STEEL AND COMPOSITE STRUCTURES"},
     {"name":"JOURNAL OF MARKETING"},
     {"name":"MARINE DRUGS"},
     {"name":"FRONTIERS IN MICROBIOLOGY"},
     {"name":"TRANSLATIONAL PSYCHIATRY"},
     {"name":"EUROPACE"},
     {"name":"JOURNAL OF MANAGEMENT INFORMATION SYSTEMS"},
     {"name":"VASCULAR PHARMACOLOGY"},
     {"name":"SOCIAL STUDIES OF SCIENCE"},
     {"name":"TELEMATICS AND INFORMATICS"},
     {"name":"NEUROCOMPUTING"},
     {"name":"HEPATOBILIARY SURGERY AND NUTRITION"},
     {"name":"TRIBOLOGY INTERNATIONAL"},
     {"name":"INTERNATIONAL JOURNAL OF HEAT AND MASS TRANSFER"},
     {"name":"FUTURE OF CHILDREN"},
     {"name":"ENDOSCOPIC ULTRASOUND"},
     {"name":"COMMUNICATIONS CHEMISTRY"},
     {"name":"PLOS COMPUTATIONAL BIOLOGY"},
     {"name":"JOURNAL OF CYSTIC FIBROSIS"},
     {"name":"JOURNAL OF INTELLECTUAL CAPITAL"},
     {"name":"CURRENT OPINION IN LIPIDOLOGY"},
     {"name":"DENTAL MATERIALS"},
     {"name":"APL PHOTONICS"},
     {"name":"CYTOTHERAPY"},
     {"name":"MRS BULLETIN"},
     {"name":"JOURNAL OF CLINICAL EPIDEMIOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF LIFE CYCLE ASSESSMENT"},
     {"name":"ATHEROSCLEROSIS"},
     {"name":"BIOTECHNOLOGY FOR BIOFUELS"},
     {"name":"ADVANCED MATERIALS INTERFACES"},
     {"name":"VACCINES"},
     {"name":"ACS SYNTHETIC BIOLOGY"},
     {"name":"ADVANCES IN HETEROCYCLIC CHEMISTRY"},
     {"name":"JOURNAL OF ENDOCRINOLOGY"},
     {"name":"JOURNAL OF NEUROTRAUMA"},
     {"name":"JOURNAL OF IMMUNOTHERAPY"},
     {"name":"CORTEX"},
     {"name":"GLOBAL AND PLANETARY CHANGE"},
     {"name":"EDUCATIONAL PSYCHOLOGY REVIEW"},
     {"name":"ANNUAL REVIEW OF POLITICAL SCIENCE"},
     {"name":"JOURNAL OF NEUROLOGY"},
     {"name":"IEEE SYSTEMS JOURNAL"},
     {"name":"REVIEWS IN CHEMICAL ENGINEERING"},
     {"name":"NONLINEAR DYNAMICS"},
     {"name":"STEM CELL RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF BIOLOGICAL SCIENCES"},
     {"name":"CURRENT PSYCHIATRY REPORTS"},
     {"name":"JOURNAL OF HYDROLOGY"},
     {"name":"PRESLIA"},
     {"name":"HYDROLOGY AND EARTH SYSTEM SCIENCES"},
     {"name":"ANNALS OF MATHEMATICS"},
     {"name":"ANNALS OF THE AMERICAN THORACIC SOCIETY"},
     {"name":"POSTHARVEST BIOLOGY AND TECHNOLOGY"},
     {"name":"JOURNAL OF MANUFACTURING SYSTEMS"},
     {"name":"MILBANK QUARTERLY"},
     {"name":"JMIR MHEALTH AND UHEALTH"},
     {"name":"INTERNATIONAL JOURNAL OF FOOD MICROBIOLOGY"},
     {"name":"HUMAN MUTATION"},
     {"name":"CURRENT OPINION IN PHARMACOLOGY"},
     {"name":"IEEE CONSUMER ELECTRONICS MAGAZINE"},
     {"name":"ARCHIVOS DE BRONCONEUMOLOGIA"},
     {"name":"GEOBIOLOGY"},
     {"name":"CLINICAL SCIENCE"},
     {"name":"INTERNATIONAL JOURNAL OF PRODUCTION RESEARCH"},
     {"name":"ATMOSPHERIC ENVIRONMENT"},
     {"name":"JOURNAL OF AGRICULTURAL AND FOOD CHEMISTRY"},
     {"name":"INTERNATIONAL DATA PRIVACY LAW"},
     {"name":"NEUROCHEMISTRY INTERNATIONAL"},
     {"name":"COMMUNICATIONS PHYSICS"},
     {"name":"BIOCONJUGATE CHEMISTRY"},
     {"name":"JOURNAL OF THE AMERICAN GERIATRICS SOCIETY"},
     {"name":"GLOBAL CHANGE BIOLOGY BIOENERGY"},
     {"name":"MOLECULAR CANCER RESEARCH"},
     {"name":"INTERNATIONAL REVIEW OF CELL AND MOLECULAR BIOLOGY"},
     {"name":"JOURNAL OF ALZHEIMERS DISEASE"},
     {"name":"ANNUAL REVIEWS IN CONTROL"},
     {"name":"NEUROPSYCHOLOGY REVIEW"},
     {"name":"ENVIRONMENTAL REVIEWS"},
     {"name":"DIABETES RESEARCH AND CLINICAL PRACTICE"},
     {"name":"WORLD JOURNAL OF BIOLOGICAL PSYCHIATRY"},
     {"name":"JOURNAL OF CHEMICAL THEORY AND COMPUTATION"},
     {"name":"JOURNAL OF DENTAL RESEARCH"},
     {"name":"FIELD CROPS RESEARCH"},
     {"name":"JOURNAL OF MAGNETIC RESONANCE IMAGING"},
     {"name":"CURRENT TOPICS IN DEVELOPMENTAL BIOLOGY"},
     {"name":"JOURNAL OF FUNGI"},
     {"name":"CARDIOVASCULAR DRUGS AND THERAPY"},
     {"name":"PHYTOCHEMISTRY REVIEWS"},
     {"name":"GLOBAL CHALLENGES"},
     {"name":"SMALL BUSINESS ECONOMICS"},
     {"name":"ENVIRONMENTAL POLITICS"},
     {"name":"BIOTECHNOLOGY AND BIOENGINEERING"},
     {"name":"ACCOUNTING REVIEW"},
     {"name":"TRANSLATIONAL LUNG CANCER RESEARCH"},
     {"name":"INTERNET RESEARCH"},
     {"name":"RUSSIAN CHEMICAL REVIEWS"},
     {"name":"APOPTOSIS"},
     {"name":"JOURNAL OF HEADACHE AND PAIN"},
     {"name":"INTERNATIONAL BUSINESS REVIEW"},
     {"name":"FOOD CONTROL"},
     {"name":"JOURNAL OF THE TAIWAN INSTITUTE OF CHEMICAL ENGINEERS"},
     {"name":"CURRENT MEDICINAL CHEMISTRY"},
     {"name":"BIOCHEMICAL SOCIETY TRANSACTIONS"},
     {"name":"ADVANCES IN WATER RESOURCES"},
     {"name":"MONTHLY NOTICES OF THE ROYAL ASTRONOMICAL SOCIETY"},
     {"name":"LONG RANGE PLANNING"},
     {"name":"JOURNAL OF HIGH ENERGY ASTROPHYSICS"},
     {"name":"JOURNAL OF PEST SCIENCE"},
     {"name":"ARABIAN JOURNAL OF CHEMISTRY"},
     {"name":"INTERNATIONAL JOURNAL OF HYDROGEN ENERGY"},
     {"name":"JOURNAL OF LIGHTWAVE TECHNOLOGY"},
     {"name":"JOURNAL OF FOOD ENGINEERING"},
     {"name":"JOURNAL OF KNOWLEDGE MANAGEMENT"},
     {"name":"HELICOBACTER"},
     {"name":"PERSPECTIVES IN PUBLIC HEALTH"},
     {"name":"IEEE TRANSACTIONS ON VEHICULAR TECHNOLOGY"},
     {"name":"BUILDING RESEARCH AND INFORMATION"},
     {"name":"LIMNOLOGY AND OCEANOGRAPHY LETTERS"},
     {"name":"RESULTS IN PHYSICS"},
     {"name":"INTERNATIONAL JOURNAL FOR UNCERTAINTY QUANTIFICATION"},
     {"name":"JOURNAL OF TECHNOLOGY TRANSFER"},
     {"name":"WEATHER AND CLIMATE EXTREMES"},
     {"name":"COGNITIVE BEHAVIOUR THERAPY"},
     {"name":"ISCIENCE"},
     {"name":"AMERICAN JOURNAL OF MEDICINE"},
     {"name":"JOURNAL OF CHROMATOGRAPHY A"},
     {"name":"JOURNAL OF THE AMERICAN PLANNING ASSOCIATION"},
     {"name":"JOURNAL OF BEHAVIORAL ADDICTIONS"},
     {"name":"JOURNAL OF INFORMETRICS"},
     {"name":"ENERGY POLICY"},
     {"name":"GENES AND IMMUNITY"},
     {"name":"INTERNATIONAL JOURNAL OF DRUG POLICY"},
     {"name":"ANTIMICROBIAL AGENTS AND CHEMOTHERAPY"},
     {"name":"JOURNAL OF GENETICS AND GENOMICS"},
     {"name":"IMMUNOLOGY"},
     {"name":"JOURNAL OF MATERIALS PROCESSING TECHNOLOGY"},
     {"name":"PLASTIC AND RECONSTRUCTIVE SURGERY"},
     {"name":"CLIMATIC CHANGE"},
     {"name":"PEDIATRIC ALLERGY AND IMMUNOLOGY"},
     {"name":"NANOMATERIALS"},
     {"name":"CITIES"},
     {"name":"AMBIO"},
     {"name":"HUMAN BRAIN MAPPING"},
     {"name":"CURRENT OPINION IN GREEN AND SUSTAINABLE CHEMISTRY"},
     {"name":"EXPERT REVIEWS IN MOLECULAR MEDICINE"},
     {"name":"TUNNELLING AND UNDERGROUND SPACE TECHNOLOGY"},
     {"name":"AMERICAN JOURNAL OF SURGICAL PATHOLOGY"},
     {"name":"IEEE PERVASIVE COMPUTING"},
     {"name":"JOURNAL OF ALLOYS AND COMPOUNDS"},
     {"name":"INTERNATIONAL JOURNAL OF ROCK MECHANICS AND MINING SCIENCES"},
     {"name":"PHARMACEUTICS"},
     {"name":"JOURNAL OF CARDIOVASCULAR MAGNETIC RESONANCE"},
     {"name":"OPTICS AND LASERS IN ENGINEERING"},
     {"name":"CELL AND BIOSCIENCE"},
     {"name":"AGRONOMY FOR SUSTAINABLE DEVELOPMENT"},
     {"name":"ATHEROSCLEROSIS SUPPLEMENTS"},
     {"name":"AGRICULTURAL WATER MANAGEMENT"},
     {"name":"INTERNATIONAL JOURNAL OF PHARMACEUTICS"},
     {"name":"JOURNAL OF THE AMERICAN STATISTICAL ASSOCIATION"},
     {"name":"ARCHIVES OF TOXICOLOGY"},
     {"name":"EARTH AND PLANETARY SCIENCE LETTERS"},
     {"name":"ANNALS OF THE NEW YORK ACADEMY OF SCIENCES"},
     {"name":"MSPHERE"},
     {"name":"IEEE TRANSACTIONS ON COMPUTATIONAL IMAGING"},
     {"name":"CANCER GENE THERAPY"},
     {"name":"NEUROENDOCRINOLOGY"},
     {"name":"JOURNAL OF THE AMERICAN MEDICAL INFORMATICS ASSOCIATION"},
     {"name":"DNA RESEARCH"},
     {"name":"CURRENT TREATMENT OPTIONS IN ONCOLOGY"},
     {"name":"EXPERT OPINION ON DRUG DISCOVERY"},
     {"name":"IEEE TRANSACTIONS ON NETWORK AND SERVICE MANAGEMENT"},
     {"name":"SEMINARS IN ONCOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF CANCER"},
     {"name":"BIODRUGS"},
     {"name":"IEEE TRANSACTIONS ON VISUALIZATION AND COMPUTER GRAPHICS"},
     {"name":"ACS CHEMICAL NEUROSCIENCE"},
     {"name":"JOURNAL OF ANALYTICAL AND APPLIED PYROLYSIS"},
     {"name":"AGRICULTURAL SYSTEMS"},
     {"name":"LABORATORY INVESTIGATION"},
     {"name":"SCRIPTA MATERIALIA"},
     {"name":"BEHAVIOUR RESEARCH AND THERAPY"},
     {"name":"IET RENEWABLE POWER GENERATION"},
     {"name":"TRANSACTIONS OF THE INSTITUTE OF BRITISH GEOGRAPHERS"},
     {"name":"ACTA PSYCHIATRICA SCANDINAVICA"},
     {"name":"HUMAN GENE THERAPY"},
     {"name":"COASTAL ENGINEERING"},
     {"name":"FRONTIERS IN AGING NEUROSCIENCE"},
     {"name":"CHINESE CHEMICAL LETTERS"},
     {"name":"JOURNAL OF THE ASSOCIATION OF ENVIRONMENTAL AND RESOURCE ECONOMISTS"},
     {"name":"APPLIED CLAY SCIENCE"},
     {"name":"JOURNAL OF CLINICAL PERIODONTOLOGY"},
     {"name":"AMERICAN JOURNAL OF CANCER RESEARCH"},
     {"name":"METABOLITES"},
     {"name":"INTERNATIONAL JOURNAL OF NANOMEDICINE"},
     {"name":"CURRENT OBESITY REPORTS"},
     {"name":"JOURNAL OF INDUSTRIAL AND ENGINEERING CHEMISTRY"},
     {"name":"INTERNATIONAL JOURNAL OF MINING SCIENCE AND TECHNOLOGY"},
     {"name":"ENERGY ECONOMICS"},
     {"name":"JOURNAL OF GENERAL INTERNAL MEDICINE"},
     {"name":"JOURNAL OF NEUROCHEMISTRY"},
     {"name":"CHEMCATCHEM"},
     {"name":"ADVANCES IN GEOPHYSICS"},
     {"name":"APPLIED SPECTROSCOPY REVIEWS"},
     {"name":"JOURNAL OF INNATE IMMUNITY"},
     {"name":"JOURNAL OF THE MECHANICS AND PHYSICS OF SOLIDS"},
     {"name":"CRITICAL REVIEWS IN ANALYTICAL CHEMISTRY"},
     {"name":"EUROINTERVENTION"},
     {"name":"COGNITIVE COMPUTATION"},
     {"name":"CANCER CELL INTERNATIONAL"},
     {"name":"JAMA NETWORK OPEN"},
     {"name":"GENES AND NUTRITION"},
     {"name":"ECOLOGICAL INDICATORS"},
     {"name":"JOURNAL OF ORTHOPAEDIC TRANSLATION"},
     {"name":"COMPUTERS IN INDUSTRY"},
     {"name":"MICROBIAL BIOTECHNOLOGY"},
     {"name":"JOURNAL OF HYDROMETEOROLOGY"},
     {"name":"GENE THERAPY"},
     {"name":"GENETICS SELECTION EVOLUTION"},
     {"name":"FRONTIERS IN CELL AND DEVELOPMENTAL BIOLOGY"},
     {"name":"SEMINARS IN RADIATION ONCOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF HYGIENE AND ENVIRONMENTAL HEALTH"},
     {"name":"JOURNAL OF NEUROSURGERY"},
     {"name":"CLIMATE POLICY"},
     {"name":"IEEE TRANSACTIONS ON NETWORK SCIENCE AND ENGINEERING"},
     {"name":"EUROPEAN JOURNAL OF IMMUNOLOGY"},
     {"name":"COMBUSTION AND FLAME"},
     {"name":"PROGRESS IN MOLECULAR BIOLOGY AND TRANSLATIONAL SCIENCE"},
     {"name":"MOLECULAR BRAIN"},
     {"name":"EUROPEAN JOURNAL OF PERSONALITY"},
     {"name":"JOURNAL OF MOLECULAR BIOLOGY"},
     {"name":"EDUCATIONAL PSYCHOLOGIST"},
     {"name":"POLYMER DEGRADATION AND STABILITY"},
     {"name":"BIOMOLECULES"},
     {"name":"ACS MEDICINAL CHEMISTRY LETTERS"},
     {"name":"CURRENT OPINION IN BEHAVIORAL SCIENCES"},
     {"name":"JOURNAL OF INTERACTIVE MARKETING"},
     {"name":"OPEN BIOLOGY"},
     {"name":"NBER MACROECONOMICS ANNUAL"},
     {"name":"CELLULOSE"},
     {"name":"IEEE MULTIMEDIA"},
     {"name":"ENERGY STRATEGY REVIEWS"},
     {"name":"ADVANCES IN CLIMATE CHANGE RESEARCH"},
     {"name":"THERAPEUTIC ADVANCES IN CHRONIC DISEASE"},
     {"name":"COMMUNICATIONS IN NONLINEAR SCIENCE AND NUMERICAL SIMULATION"},
     {"name":"CRIMINOLOGY"},
     {"name":"JOURNAL OF PHYSICAL CHEMISTRY C"},
     {"name":"MIS QUARTERLY"},
     {"name":"BIOGEOCHEMISTRY"},
     {"name":"POLYMER"},
     {"name":"CEPHALALGIA"},
     {"name":"CELLS"},
     {"name":"JOURNAL OF LIPID RESEARCH"},
     {"name":"BJU INTERNATIONAL"},
     {"name":"INDUSTRIAL CROPS AND PRODUCTS"},
     {"name":"FRONTIERS IN IMMUNOLOGY"},
     {"name":"ECONOMIC GEOLOGY"},
     {"name":"MIS QUARTERLY EXECUTIVE"},
     {"name":"JOURNAL OF HEPATOCELLULAR CARCINOMA"},
     {"name":"GLOBAL STRATEGY JOURNAL"},
     {"name":"JOURNAL OF CELL SCIENCE"},
     {"name":"INTERNATIONAL JOURNAL OF APPLIED EARTH OBSERVATION AND GEOINFORMATION"},
     {"name":"SURVEY OF OPHTHALMOLOGY"},
     {"name":"HUMAN RESOURCE MANAGEMENT REVIEW"},
     {"name":"IEEE TRANSACTIONS ON CLOUD COMPUTING"},
     {"name":"AMERICAN EDUCATIONAL RESEARCH JOURNAL"},
     {"name":"CLINICAL ORTHOPAEDICS AND RELATED RESEARCH"},
     {"name":"GEOSCIENTIFIC MODEL DEVELOPMENT"},
     {"name":"GLOBAL BIOGEOCHEMICAL CYCLES"},
     {"name":"ECONOMIC POLICY"},
     {"name":"CELLULAR SIGNALLING"},
     {"name":"SEMINARS IN LIVER DISEASE"},
     {"name":"HELLENIC JOURNAL OF CARDIOLOGY"},
     {"name":"CURRENT OPINION IN FOOD SCIENCE"},
     {"name":"ANNALS OF BEHAVIORAL MEDICINE"},
     {"name":"BIOMEDICAL OPTICS EXPRESS"},
     {"name":"JOURNAL OF TRANSLATIONAL MEDICINE"},
     {"name":"ENVIRONMENTAL IMPACT ASSESSMENT REVIEW"},
     {"name":"MECHANISMS OF AGEING AND DEVELOPMENT"},
     {"name":"NANOTOXICOLOGY"},
     {"name":"EJSO"},
     {"name":"REMOTE SENSING"},
     {"name":"FOOD POLICY"},
     {"name":"REVIEW OF RESEARCH IN EDUCATION"},
     {"name":"CELLULAR IMMUNOLOGY"},
     {"name":"PHYSICS LETTERS B"},
     {"name":"JOURNAL OF NEUROIMMUNE PHARMACOLOGY"},
     {"name":"FUEL PROCESSING TECHNOLOGY"},
     {"name":"ATMOSPHERIC RESEARCH"},
     {"name":"INFORMATION SYSTEMS JOURNAL"},
     {"name":"CURRENT OPINION IN RHEUMATOLOGY"},
     {"name":"ISA TRANSACTIONS"},
     {"name":"PROTEIN SCIENCE"},
     {"name":"CANCER SCIENCE"},
     {"name":"ADVANCED ENGINEERING INFORMATICS"},
     {"name":"POWDER TECHNOLOGY"},
     {"name":"CLINICAL PHARMACOKINETICS"},
     {"name":"NEW BIOTECHNOLOGY"},
     {"name":"ACADEMY OF MANAGEMENT PERSPECTIVES"},
     {"name":"COMPUTERS ENVIRONMENT AND URBAN SYSTEMS"},
     {"name":"RESPIROLOGY"},
     {"name":"NANOMEDICINE"},
     {"name":"JOURNAL OF CONSULTING AND CLINICAL PSYCHOLOGY"},
     {"name":"NEUROBIOLOGY OF DISEASE"},
     {"name":"JOURNAL OF THORACIC AND CARDIOVASCULAR SURGERY"},
     {"name":"DRUG AND ALCOHOL DEPENDENCE"},
     {"name":"JOURNAL OF PAIN"},
     {"name":"RADIOGRAPHICS"},
     {"name":"ECOTOXICOLOGY AND ENVIRONMENTAL SAFETY"},
     {"name":"INTERNATIONAL JOURNAL OF STROKE"},
     {"name":"CHILD DEVELOPMENT PERSPECTIVES"},
     {"name":"THERAPEUTIC ADVANCES IN MUSCULOSKELETAL DISEASE"},
     {"name":"NEUROSCIENCE BULLETIN"},
     {"name":"HEART"},
     {"name":"PUBLIC ADMINISTRATION REVIEW"},
     {"name":"INDOOR AIR"},
     {"name":"JOURNAL OF SPORT AND HEALTH SCIENCE"},
     {"name":"JOURNAL OF VIROLOGY"},
     {"name":"EPIGENOMICS"},
     {"name":"JOURNAL OF COSMOLOGY AND ASTROPARTICLE PHYSICS"},
     {"name":"ESMO OPEN"},
     {"name":"JOURNAL OF BIOMEDICAL NANOTECHNOLOGY"},
     {"name":"CLADISTICS"},
     {"name":"CELL DEATH DISCOVERY"},
     {"name":"CLIMATE RISK MANAGEMENT"},
     {"name":"BIOCHEMICAL JOURNAL"},
     {"name":"ECONOMETRICA"},
     {"name":"QUANTUM SCIENCE AND TECHNOLOGY"},
     {"name":"STRUCTURE"},
     {"name":"JOURNAL OF STRATEGIC INFORMATION SYSTEMS"},
     {"name":"FRONTIERS IN MOLECULAR NEUROSCIENCE"},
     {"name":"JOURNAL OF ANIMAL SCIENCE AND BIOTECHNOLOGY"},
     {"name":"JOURNAL OF MOLECULAR CELL BIOLOGY"},
     {"name":"JOURNAL OF ECONOMIC GROWTH"},
     {"name":"FRONTIERS IN CELLULAR AND INFECTION MICROBIOLOGY"},
     {"name":"ANNALS OF SURGICAL ONCOLOGY"},
     {"name":"IEEE TRANSACTIONS ON ANTENNAS AND PROPAGATION"},
     {"name":"JOURNAL OF GEODESY"},
     {"name":"JOURNAL OF PARKINSONS DISEASE"},
     {"name":"DRUG DELIVERY"},
     {"name":"PRECISION AGRICULTURE"},
     {"name":"BIOORGANIC CHEMISTRY"},
     {"name":"ACM TRANSACTIONS ON GRAPHICS"},
     {"name":"STROKE AND VASCULAR NEUROLOGY"},
     {"name":"TRAFFIC"},
     {"name":"CAMBRIDGE JOURNAL OF REGIONS ECONOMY AND SOCIETY"},
     {"name":"REVIEW OF ECONOMICS AND STATISTICS"},
     {"name":"EUROPEAN JOURNAL OF ENDOCRINOLOGY"},
     {"name":"PHYSICS TODAY"},
     {"name":"MOLECULAR PLANT PATHOLOGY"},
     {"name":"MACROMOLECULAR RAPID COMMUNICATIONS"},
     {"name":"EVOLUTIONARY APPLICATIONS"},
     {"name":"CONSTRUCTION AND BUILDING MATERIALS"},
     {"name":"IEEE TRANSACTIONS ON MOBILE COMPUTING"},
     {"name":"JOURNAL OF BUSINESS RESEARCH"},
     {"name":"EUROPEAN JOURNAL OF PSYCHOLOGY APPLIED TO LEGAL CONTEXT"},
     {"name":"PRECAMBRIAN RESEARCH"},
     {"name":"STRUCTURAL SAFETY"},
     {"name":"BIOESSAYS"},
     {"name":"FRONTIERS IN PHARMACOLOGY"},
     {"name":"NATURE AND SCIENCE OF SLEEP"},
     {"name":"PROSTATE CANCER AND PROSTATIC DISEASES"},
     {"name":"CLINICAL AND TRANSLATIONAL ALLERGY"},
     {"name":"JOURNAL OF SUSTAINABLE TOURISM"},
     {"name":"ENTOMOLOGIA GENERALIS"},
     {"name":"CRISPR JOURNAL"},
     {"name":"IEEE JOURNAL OF BIOMEDICAL AND HEALTH INFORMATICS"},
     {"name":"ENVIRONMENTAL MICROBIOLOGY"},
     {"name":"ORAL ONCOLOGY"},
     {"name":"FLUIDS AND BARRIERS OF THE CNS"},
     {"name":"MICROORGANISMS"},
     {"name":"LANDSLIDES"},
     {"name":"JOURNAL OF MOLECULAR AND CELLULAR CARDIOLOGY"},
     {"name":"JOURNAL OF MOLECULAR LIQUIDS"},
     {"name":"ECOSYSTEMS"},
     {"name":"ACTA PHARMACOLOGICA SINICA"},
     {"name":"DIVERSITY AND DISTRIBUTIONS"},
     {"name":"MICROBIOLOGICAL RESEARCH"},
     {"name":"MOLECULAR GENETICS AND METABOLISM"},
     {"name":"INDUSTRIAL MARKETING MANAGEMENT"},
     {"name":"DYES AND PIGMENTS"},
     {"name":"BULLETIN OF THE CHEMICAL SOCIETY OF JAPAN"},
     {"name":"MABS"},
     {"name":"NEUROSURGERY"},
     {"name":"EUROPEAN RADIOLOGY"},
     {"name":"RNA"},
     {"name":"MOLECULAR ECOLOGY"},
     {"name":"FOOD AND CHEMICAL TOXICOLOGY"},
     {"name":"WATER RESOURCES RESEARCH"},
     {"name":"ECOLOGICAL ECONOMICS"},
     {"name":"JOURNAL OF THE ENERGY INSTITUTE"},
     {"name":"JOURNAL OF PRODUCT INNOVATION MANAGEMENT"},
     {"name":"JOURNAL OF INFECTIOUS DISEASES"},
     {"name":"BIOFACTORS"},
     {"name":"POLYMER CHEMISTRY"},
     {"name":"GEOLOGY"},
     {"name":"NPJ CLIMATE AND ATMOSPHERIC SCIENCE"},
     {"name":"ADVANCES IN AGRONOMY"},
     {"name":"ENVIRONMENTAL HEALTH"},
     {"name":"ARTIFICIAL INTELLIGENCE IN MEDICINE"},
     {"name":"SOLID STATE PHYSICS"},
     {"name":"SCIENTIFIC REPORTS"},
     {"name":"CONTACT DERMATITIS"},
     {"name":"BIOMARKER RESEARCH"},
     {"name":"JOURNAL OF METAMORPHIC GEOLOGY"},
     {"name":"CARCINOGENESIS"},
     {"name":"APPLIED LINGUISTICS"},
     {"name":"LIQUID CRYSTALS REVIEWS"},
     {"name":"EUROPEAN JOURNAL OF NEUROLOGY"},
     {"name":"CURRENT OPINION IN INFECTIOUS DISEASES"},
     {"name":"JOURNAL OF INHERITED METABOLIC DISEASE"},
     {"name":"INORGANIC CHEMISTRY"},
     {"name":"JOURNAL OF INVESTIGATIONAL ALLERGOLOGY AND CLINICAL IMMUNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF ONCOLOGY"},
     {"name":"ADVANCES IN CANCER RESEARCH"},
     {"name":"STEM CELL REVIEWS AND REPORTS"},
     {"name":"ACS CHEMICAL BIOLOGY"},
     {"name":"JOURNAL OF ATHEROSCLEROSIS AND THROMBOSIS"},
     {"name":"JOURNAL OF MANAGEMENT STUDIES"},
     {"name":"PLOS GENETICS"},
     {"name":"EVOLUTION MEDICINE AND PUBLIC HEALTH"},
     {"name":"CARBON BALANCE AND MANAGEMENT"},
     {"name":"ANNALS OF INTENSIVE CARE"},
     {"name":"PHARMACEUTICALS"},
     {"name":"MANAGEMENT SCIENCE"},
     {"name":"ENGINEERING GEOLOGY"},
     {"name":"COMPOSITE STRUCTURES"},
     {"name":"CANADIAN JOURNAL OF CARDIOLOGY"},
     {"name":"JOURNAL OF ANIMAL ECOLOGY"},
     {"name":"JOURNAL OF ENVIRONMENTAL INFORMATICS"},
     {"name":"CASE STUDIES IN THERMAL ENGINEERING"},
     {"name":"JOURNAL OF THE EUROPEAN CERAMIC SOCIETY"},
     {"name":"ADVANCES IN MICROBIAL PHYSIOLOGY"},
     {"name":"AMERICAN JOURNAL OF POLITICAL SCIENCE"},
     {"name":"JOURNAL OF ADOLESCENT HEALTH"},
     {"name":"DEVELOPMENTAL MEDICINE AND CHILD NEUROLOGY"},
     {"name":"BRITISH JOURNAL OF GENERAL PRACTICE"},
     {"name":"SUSTAINABLE DEVELOPMENT"},
     {"name":"SOCIAL PSYCHOLOGICAL AND PERSONALITY SCIENCE"},
     {"name":"PITUITARY"},
     {"name":"JOURNAL OF NANOSTRUCTURE IN CHEMISTRY"},
     {"name":"MOLECULAR PHARMACEUTICS"},
     {"name":"DECISION SUPPORT SYSTEMS"},
     {"name":"INTERNATIONAL JOURNAL OF ANTIMICROBIAL AGENTS"},
     {"name":"INTERNATIONAL JOURNAL OF PRODUCTION ECONOMICS"},
     {"name":"CLIMATE DYNAMICS"},
     {"name":"BRAIN CONNECTIVITY"},
     {"name":"ADVANCES IN ENGINEERING SOFTWARE"},
     {"name":"ADVANCES IN NANO RESEARCH"},
     {"name":"AGRICULTURAL AND FOREST METEOROLOGY"},
     {"name":"MEDICAL EDUCATION"},
     {"name":"CLINICAL RESEARCH IN CARDIOLOGY"},
     {"name":"CRYOSPHERE"},
     {"name":"INTERNATIONAL JOURNAL OF CLIMATOLOGY"},
     {"name":"CHEMELECTROCHEM"},
     {"name":"IEEE INTERNET COMPUTING"},
     {"name":"AGE AND AGEING"},
     {"name":"TRAVEL MEDICINE AND INFECTIOUS DISEASE"},
     {"name":"FOOD QUALITY AND PREFERENCE"},
     {"name":"CATENA"},
     {"name":"ENVIRONMENT AND BEHAVIOR"},
     {"name":"GEOPHYSICAL RESEARCH LETTERS"},
     {"name":"CRITICAL REVIEWS IN TOXICOLOGY"},
     {"name":"OXIDATIVE MEDICINE AND CELLULAR LONGEVITY"},
     {"name":"PLOS NEGLECTED TROPICAL DISEASES"},
     {"name":"FOOD RESEARCH INTERNATIONAL"},
     {"name":"CELLULAR ONCOLOGY"},
     {"name":"ASN NEURO"},
     {"name":"HEALTH AFFAIRS"},
     {"name":"RESPIRATORY RESEARCH"},
     {"name":"GEODERMA"},
     {"name":"COMMUNICATION METHODS AND MEASURES"},
     {"name":"JOURNAL OF PROTEOME RESEARCH"},
     {"name":"CLINICAL EPIGENETICS"},
     {"name":"SOLAR ENERGY"},
     {"name":"FUNGAL BIOLOGY REVIEWS"},
     {"name":"REVIEWS IN FISH BIOLOGY AND FISHERIES"},
     {"name":"DEPRESSION AND ANXIETY"},
     {"name":"TRENDS IN CARDIOVASCULAR MEDICINE"},
     {"name":"IEEE TRANSACTIONS ON BIOMEDICAL CIRCUITS AND SYSTEMS"},
     {"name":"JOURNAL OF ORGANIZATIONAL BEHAVIOR"},
     {"name":"IEEE TRANSACTIONS ON ENERGY CONVERSION"},
     {"name":"CNS DRUGS"},
     {"name":"JOURNAL OF MANUFACTURING PROCESSES"},
     {"name":"JOURNAL OF MEMORY AND LANGUAGE"},
     {"name":"JOURNAL OF POLICY ANALYSIS AND MANAGEMENT"},
     {"name":"UNIVERSITY OF PENNSYLVANIA LAW REVIEW"},
     {"name":"ECOLOGY"},
     {"name":"ORGANIZATION STUDIES"},
     {"name":"BEHAVIOR RESEARCH METHODS"},
     {"name":"SUSTAINABLE CITIES AND SOCIETY"},
     {"name":"BUILDING AND ENVIRONMENT"},
     {"name":"JOURNAL OF THE EUROPEAN ACADEMY OF DERMATOLOGY AND VENEREOLOGY"},
     {"name":"NUTRIENTS"},
     {"name":"EUROPEAN PHYSICAL JOURNAL C"},
     {"name":"CURRENT OSTEOPOROSIS REPORTS"},
     {"name":"INTERNATIONAL ORGANIZATION"},
     {"name":"ENGINEERING APPLICATIONS OF ARTIFICIAL INTELLIGENCE"},
     {"name":"DIGITAL JOURNALISM"},
     {"name":"FOOD AND ENERGY SECURITY"},
     {"name":"JOURNAL OF PEASANT STUDIES"},
     {"name":"INTERNATIONAL REVIEWS IN PHYSICAL CHEMISTRY"},
     {"name":"EXPERT REVIEW OF MOLECULAR DIAGNOSTICS"},
     {"name":"CURRENT NEUROLOGY AND NEUROSCIENCE REPORTS"},
     {"name":"RESEARCH SYNTHESIS METHODS"},
     {"name":"JOURNAL OF CHEMINFORMATICS"},
     {"name":"FRONTIERS IN CELLULAR NEUROSCIENCE"},
     {"name":"ADVANCES IN IMMUNOLOGY"},
     {"name":"PHYSIOLOGIA PLANTARUM"},
     {"name":"ADVANCES IN THE STUDY OF BEHAVIOR"},
     {"name":"JOURNAL OF ORGANIC CHEMISTRY"},
     {"name":"FAMILY BUSINESS REVIEW"},
     {"name":"PHARMACOPSYCHIATRY"},
     {"name":"JOURNAL OF ENVIRONMENTAL CHEMICAL ENGINEERING"},
     {"name":"JOURNAL OF NUTRITIONAL BIOCHEMISTRY"},
     {"name":"EMOTION REVIEW"},
     {"name":"MICROPOROUS AND MESOPOROUS MATERIALS"},
     {"name":"ENERGY AND BUILDINGS"},
     {"name":"BLOOD ADVANCES"},
     {"name":"REMOTE SENSING IN ECOLOGY AND CONSERVATION"},
     {"name":"TRANSBOUNDARY AND EMERGING DISEASES"},
     {"name":"FUZZY OPTIMIZATION AND DECISION MAKING"},
     {"name":"JOURNAL OF EDUCATIONAL PSYCHOLOGY"},
     {"name":"ACADEMIC MEDICINE"},
     {"name":"GYNECOLOGIC ONCOLOGY"},
     {"name":"JOURNAL OF NEURAL ENGINEERING"},
     {"name":"NUTRITIONAL NEUROSCIENCE"},
     {"name":"BIOTECHNOLOGY JOURNAL"},
     {"name":"HEADACHE"},
     {"name":"THERAPEUTIC ADVANCES IN NEUROLOGICAL DISORDERS"},
     {"name":"INFECTIOUS DISEASES AND THERAPY"},
     {"name":"ADVANCED POWDER TECHNOLOGY"},
     {"name":"NEUROPHARMACOLOGY"},
     {"name":"JOURNAL OF NEUROINTERVENTIONAL SURGERY"},
     {"name":"GENETICS"},
     {"name":"EXPOSURE AND HEALTH"},
     {"name":"BIOCHEMICAL PHARMACOLOGY"},
     {"name":"JOURNAL OF OPERATIONS MANAGEMENT"},
     {"name":"JOURNAL OF ENVIRONMENTAL SCIENCES"},
     {"name":"ANTIOXIDANTS"},
     {"name":"EXPERT OPINION ON DRUG DELIVERY"},
     {"name":"ASTROBIOLOGY"},
     {"name":"SEMINARS IN ARTHRITIS AND RHEUMATISM"},
     {"name":"THYROID"},
     {"name":"CLINICAL AND TRANSLATIONAL GASTROENTEROLOGY"},
     {"name":"HEALTH PSYCHOLOGY REVIEW"},
     {"name":"NEUROBIOLOGY OF AGING"},
     {"name":"IEEE ELECTRON DEVICE LETTERS"},
     {"name":"COMMUNICATIONS BIOLOGY"},
     {"name":"JOURNAL OF CHEMICAL INFORMATION AND MODELING"},
     {"name":"ANALYST"},
     {"name":"JOURNAL OF SERVICE MANAGEMENT"},
     {"name":"CURRENT ISSUES IN TOURISM"},
     {"name":"ONCOLOGY RESEARCH"},
     {"name":"JOURNAL OF CELLULAR AND MOLECULAR MEDICINE"},
     {"name":"INTERNATIONAL JOURNAL OF ROBOTICS RESEARCH"},
     {"name":"JOURNAL OF TISSUE ENGINEERING"},
     {"name":"PSYCHOLOGICAL SCIENCE"},
     {"name":"ANTIVIRAL RESEARCH"},
     {"name":"IEEE WIRELESS COMMUNICATIONS LETTERS"},
     {"name":"ONCOLOGIST"},
     {"name":"IEEE JOURNAL OF EMERGING AND SELECTED TOPICS IN POWER ELECTRONICS"},
     {"name":"JOURNAL OF CELLULAR BIOCHEMISTRY"},
     {"name":"BIOLOGICAL CONSERVATION"},
     {"name":"DEVELOPMENTAL NEUROBIOLOGY"},
     {"name":"AIDS"},
     {"name":"CURRENT DIRECTIONS IN PSYCHOLOGICAL SCIENCE"},
     {"name":"RADIOTHERAPY AND ONCOLOGY"},
     {"name":"AMERICAN JOURNAL OF EPIDEMIOLOGY"},
     {"name":"SLEEP"},
     {"name":"EPMA JOURNAL"},
     {"name":"PHYSICS OF THE DARK UNIVERSE"},
     {"name":"ADVANCES IN ATOMIC MOLECULAR AND OPTICAL PHYSICS"},
     {"name":"INTERNATIONAL JOURNAL OF SPORT NUTRITION AND EXERCISE METABOLISM"},
     {"name":"PROGRESS IN OPTICS"},
     {"name":"IEEE TRANSACTIONS ON BIOMEDICAL ENGINEERING"},
     {"name":"JOURNAL OF WORLD BUSINESS"},
     {"name":"JOURNAL OF PERSONALIZED MEDICINE"},
     {"name":"JOURNAL OF THE ACADEMY OF NUTRITION AND DIETETICS"},
     {"name":"CANCER NANOTECHNOLOGY"},
     {"name":"COMPOSITES COMMUNICATIONS"},
     {"name":"CELL CALCIUM"},
     {"name":"REGENERATIVE BIOMATERIALS"},
     {"name":"GEOCHEMICAL PERSPECTIVES LETTERS"},
     {"name":"JOURNAL OF AMBIENT INTELLIGENCE AND HUMANIZED COMPUTING"},
     {"name":"CURRENT OPINION IN PSYCHIATRY"},
     {"name":"VEHICULAR COMMUNICATIONS"},
     {"name":"HEPATOLOGY INTERNATIONAL"},
     {"name":"JOURNAL OF THE INTERNATIONAL SOCIETY OF SPORTS NUTRITION"},
     {"name":"VALUE IN HEALTH"},
     {"name":"PROGRESS IN ORGANIC COATINGS"},
     {"name":"CHILD DEVELOPMENT"},
     {"name":"GOVERNMENT INFORMATION QUARTERLY"},
     {"name":"JOURNAL OF THE ROYAL SOCIETY OF MEDICINE"},
     {"name":"PLANT AND CELL PHYSIOLOGY"},
     {"name":"APPLIED THERMAL ENGINEERING"},
     {"name":"HABITAT INTERNATIONAL"},
     {"name":"AMERICAN JOURNAL OF CLINICAL DERMATOLOGY"},
     {"name":"CURRENT OPINION IN NEUROLOGY"},
     {"name":"FRONTIERS IN MEDICINE"},
     {"name":"CHINESE JOURNAL OF CANCER RESEARCH"},
     {"name":"JOURNAL OF RETAILING AND CONSUMER SERVICES"},
     {"name":"WEAR"},
     {"name":"NPJ CLEAN WATER"},
     {"name":"EUROPEAN JOURNAL OF VASCULAR AND ENDOVASCULAR SURGERY"},
     {"name":"EARTH SURFACE DYNAMICS"},
     {"name":"IEEE TRANSACTIONS ON KNOWLEDGE AND DATA ENGINEERING"},
     {"name":"DRUG METABOLISM REVIEWS"},
     {"name":"CRITICAL REVIEWS IN CLINICAL LABORATORY SCIENCES"},
     {"name":"ECOLOGY AND SOCIETY"},
     {"name":"REVIEWS IN MEDICAL VIROLOGY"},
     {"name":"REVIEW OF FINANCIAL STUDIES"},
     {"name":"CLINICAL AND EXPERIMENTAL ALLERGY"},
     {"name":"PEDIATRICS"},
     {"name":"INTERNATIONAL JOURNAL OF CLINICAL AND HEALTH PSYCHOLOGY"},
     {"name":"BIOMEDICINES"},
     {"name":"JOURNAL OF MARKETING RESEARCH"},
     {"name":"EXERCISE AND SPORT SCIENCES REVIEWS"},
     {"name":"SUSTAINABILITY SCIENCE"},
     {"name":"EVOLUTIONARY COMPUTATION"},
     {"name":"MITOCHONDRION"},
     {"name":"RESUSCITATION"},
     {"name":"POLITICAL ANALYSIS"},
     {"name":"INTERNATIONAL JOURNAL OF MOLECULAR SCIENCES"},
     {"name":"ACS APPLIED ENERGY MATERIALS"},
     {"name":"DEVELOPMENTAL COGNITIVE NEUROSCIENCE"},
     {"name":"JOURNAL OF ENZYME INHIBITION AND MEDICINAL CHEMISTRY"},
     {"name":"SEMINARS IN HEMATOLOGY"},
     {"name":"AMERICAN POLITICAL SCIENCE REVIEW"},
     {"name":"CLINICAL AND MOLECULAR HEPATOLOGY"},
     {"name":"ULTRASCHALL IN DER MEDIZIN"},
     {"name":"EXPERT REVIEW OF VACCINES"},
     {"name":"DIGESTIVE ENDOSCOPY"},
     {"name":"EUROPEAN JOURNAL OF PHARMACEUTICS AND BIOPHARMACEUTICS"},
     {"name":"DERMATITIS"},
     {"name":"AUSTRALIAN AND NEW ZEALAND JOURNAL OF PSYCHIATRY"},
     {"name":"REVIEWS OF PHYSIOLOGY BIOCHEMISTRY AND PHARMACOLOGY"},
     {"name":"CLINICS IN LIVER DISEASE"},
     {"name":"JOURNAL OF INTERNATIONAL MARKETING"},
     {"name":"EXTREME MECHANICS LETTERS"},
     {"name":"RICE"},
     {"name":"PSYCHOSOCIAL INTERVENTION"},
     {"name":"NPJ SCHIZOPHRENIA"},
     {"name":"FRONTIERS IN PLANT SCIENCE"},
     {"name":"ANNALS OF FAMILY MEDICINE"},
     {"name":"TOXICOLOGY"},
     {"name":"THEORETICAL AND APPLIED GENETICS"},
     {"name":"JOURNAL OF COMMUNICATION"},
     {"name":"INVESTIGATIVE RADIOLOGY"},
     {"name":"FRICTION"},
     {"name":"ENVIRONMENTAL AND EXPERIMENTAL BOTANY"},
     {"name":"TRANSPORTATION"},
     {"name":"CURRENT NEUROPHARMACOLOGY"},
     {"name":"PHYSICAL REVIEW D"},
     {"name":"THROMBOSIS AND HAEMOSTASIS"},
     {"name":"ADVANCED SUSTAINABLE SYSTEMS"},
     {"name":"COMPUTER"},
     {"name":"INTERNATIONAL JOURNAL OF OBESITY"},
     {"name":"GASTROENTEROLOGY CLINICS OF NORTH AMERICA"},
     {"name":"EUROPEAN JOURNAL OF NUTRITION"},
     {"name":"JOURNAL OF BUSINESS ETHICS"},
     {"name":"PROGRESS IN OCEANOGRAPHY"},
     {"name":"ANIMAL NUTRITION"},
     {"name":"MEDICINE AND SCIENCE IN SPORTS AND EXERCISE"},
     {"name":"FUNCTIONAL ECOLOGY"},
     {"name":"ACS INFECTIOUS DISEASES"},
     {"name":"IEEE TRANSACTIONS ON AUTOMATION SCIENCE AND ENGINEERING"},
     {"name":"CEREBRAL CORTEX"},
     {"name":"JOURNAL OF VOCATIONAL BEHAVIOR"},
     {"name":"TARGETED ONCOLOGY"},
     {"name":"IEEE TRANSACTIONS ON SIGNAL PROCESSING"},
     {"name":"CELL COMMUNICATION AND SIGNALING"},
     {"name":"EUROPEAN JOURNAL OF INTERNAL MEDICINE"},
     {"name":"OSTEOARTHRITIS AND CARTILAGE"},
     {"name":"EPIGENETICS"},
     {"name":"MATERIALS RESEARCH BULLETIN"},
     {"name":"EXERCISE IMMUNOLOGY REVIEW"},
     {"name":"JOURNAL OF FAMILY BUSINESS STRATEGY"},
     {"name":"DALTON TRANSACTIONS"},
     {"name":"FOOD PACKAGING AND SHELF LIFE"},
     {"name":"FRONTIERS IN MOLECULAR BIOSCIENCES"},
     {"name":"QUARTERLY REVIEW OF BIOLOGY"},
     {"name":"JOURNAL OF MATERIALS CHEMISTRY B"},
     {"name":"INTERNATIONAL REVIEWS OF IMMUNOLOGY"},
     {"name":"IEEE TRANSACTIONS ON CONTROL SYSTEMS TECHNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF BIOLOGICAL MACROMOLECULES"},
     {"name":"JOURNAL OF AFFECTIVE DISORDERS"},
     {"name":"ENGINEERING WITH COMPUTERS"},
     {"name":"COGNITIVE NEURODYNAMICS"},
     {"name":"JOURNAL OF THE AMERICAN COLLEGE OF SURGEONS"},
     {"name":"JOURNAL OF ADVANCES IN MODELING EARTH SYSTEMS"},
     {"name":"PHYSICAL REVIEW APPLIED"},
     {"name":"ONE HEALTH"},
     {"name":"NEUROREHABILITATION AND NEURAL REPAIR"},
     {"name":"INTERNATIONAL JOURNAL OF FATIGUE"},
     {"name":"JOURNAL OF ORAL MICROBIOLOGY"},
     {"name":"FRONTIERS IN ONCOLOGY"},
     {"name":"FOODS"},
     {"name":"ENDOCRINOLOGY"},
     {"name":"LIVER INTERNATIONAL"},
     {"name":"FRONTIERS IN CARDIOVASCULAR MEDICINE"},
     {"name":"ACCOUNTING ORGANIZATIONS AND SOCIETY"},
     {"name":"JOURNAL OF PURCHASING AND SUPPLY MANAGEMENT"},
     {"name":"AUTISM"},
     {"name":"TALANTA"},
     {"name":"JOURNAL OF OPERATIONAL OCEANOGRAPHY"},
     {"name":"INTERNATIONAL JOURNAL OF NEUROPSYCHOPHARMACOLOGY"},
     {"name":"AMERICAN JOURNAL OF PREVENTIVE MEDICINE"},
     {"name":"IEEE JOURNAL OF SELECTED TOPICS IN SIGNAL PROCESSING"},
     {"name":"SIGNAL PROCESSING"},
     {"name":"WORLD JOURNAL OF EMERGENCY SURGERY"},
     {"name":"SAFETY SCIENCE"},
     {"name":"JOURNAL OF MEDICAL GENETICS"},
     {"name":"GLYCOBIOLOGY"},
     {"name":"MICROBIAL GENOMICS"},
     {"name":"JOURNAL OF THE AMERICAN HEART ASSOCIATION"},
     {"name":"IEEE JOURNAL OF SELECTED TOPICS IN QUANTUM ELECTRONICS"},
     {"name":"JOURNAL OF INTEGRATIVE PLANT BIOLOGY"},
     {"name":"PLANT REPRODUCTION"},
     {"name":"JOURNAL OF THE AMERICAN COLLEGE OF RADIOLOGY"},
     {"name":"APPLIED AND ENVIRONMENTAL MICROBIOLOGY"},
     {"name":"GEOSCIENCE FRONTIERS"},
     {"name":"JOURNAL OF REPRODUCTIVE IMMUNOLOGY"},
     {"name":"JOURNAL OF BIOLOGICAL CHEMISTRY"},
     {"name":"JOURNAL OF THE AMERICAN MEDICAL DIRECTORS ASSOCIATION"},
     {"name":"ANNALS OF BOTANY"},
     {"name":"RETROVIROLOGY"},
     {"name":"CURRENT OPINION IN INSECT SCIENCE"},
     {"name":"EVOLUTION LETTERS"},
     {"name":"GEOCHIMICA ET COSMOCHIMICA ACTA"},
     {"name":"EUROPEAN JOURNAL OF ANAESTHESIOLOGY"},
     {"name":"ANESTHESIA AND ANALGESIA"},
     {"name":"BMJ GLOBAL HEALTH"},
     {"name":"BIOELECTROCHEMISTRY"},
     {"name":"JOURNAL OF IMMUNOLOGY"},
     {"name":"REVISTA ESPANOLA DE CARDIOLOGIA"},
     {"name":"ADDICTION BIOLOGY"},
     {"name":"INTERNATIONAL COMMUNICATIONS IN HEAT AND MASS TRANSFER"},
     {"name":"ESC HEART FAILURE"},
     {"name":"MOLECULAR MEDICINE"},
     {"name":"SCIPOST PHYSICS"},
     {"name":"RESEARCH POLICY"},
     {"name":"DEUTSCHES ARZTEBLATT INTERNATIONAL"},
     {"name":"MARINE POLLUTION BULLETIN"},
     {"name":"INTERNATIONAL JOURNAL OF FUZZY SYSTEMS"},
     {"name":"AEROSPACE SCIENCE AND TECHNOLOGY"},
     {"name":"REVIEWS IN COMPUTATIONAL CHEMISTRY"},
     {"name":"SUSTAINABLE MATERIALS AND TECHNOLOGIES"},
     {"name":"CURRENT OPINION IN PSYCHOLOGY"},
     {"name":"ASIAN JOURNAL OF PHARMACEUTICAL SCIENCES"},
     {"name":"PHYTOTHERAPY RESEARCH"},
     {"name":"ELECTROCHEMISTRY COMMUNICATIONS"},
     {"name":"NEW ASTRONOMY REVIEWS"},
     {"name":"ROCK MECHANICS AND ROCK ENGINEERING"},
     {"name":"MOLECULAR NEUROBIOLOGY"},
     {"name":"CURRENT ATHEROSCLEROSIS REPORTS"},
     {"name":"FOUNDATIONS AND TRENDS IN INFORMATION RETRIEVAL"},
     {"name":"MOLECULES AND CELLS"},
     {"name":"FOOD REVIEWS INTERNATIONAL"},
     {"name":"PROCESS SAFETY AND ENVIRONMENTAL PROTECTION"},
     {"name":"EUROPEAN JOURNAL OF OPERATIONAL RESEARCH"},
     {"name":"EXPERT OPINION ON INVESTIGATIONAL DRUGS"},
     {"name":"CALIFORNIA MANAGEMENT REVIEW"},
     {"name":"NUCLEIC ACID THERAPEUTICS"},
     {"name":"IEEE TRANSACTIONS ON CIRCUITS AND SYSTEMS FOR VIDEO TECHNOLOGY"},
     {"name":"JOURNAL OF INFLAMMATION RESEARCH"},
     {"name":"ALLERGOLOGY INTERNATIONAL"},
     {"name":"CURRENT FORESTRY REPORTS"},
     {"name":"NEPHROLOGY DIALYSIS TRANSPLANTATION"},
     {"name":"PHYTOMEDICINE"},
     {"name":"NEUROMODULATION"},
     {"name":"FOOD MICROBIOLOGY"},
     {"name":"PSYCHONEUROENDOCRINOLOGY"},
     {"name":"JOURNAL OF HYPERTENSION"},
     {"name":"REVIEW OF ECONOMIC STUDIES"},
     {"name":"CORPORATE SOCIAL RESPONSIBILITY AND ENVIRONMENTAL MANAGEMENT"},
     {"name":"AMERICAN HEART JOURNAL"},
     {"name":"JOURNAL OF INFECTION"},
     {"name":"LIVER TRANSPLANTATION"},
     {"name":"IEEE TRANSACTIONS ON COGNITIVE COMMUNICATIONS AND NETWORKING"},
     {"name":"ACTA GEOTECHNICA"},
     {"name":"EXPERIMENTAL NEUROLOGY"},
     {"name":"SEPARATION AND PURIFICATION REVIEWS"},
     {"name":"ADVANCES IN VIRUS RESEARCH"},
     {"name":"RNA BIOLOGY"},
     {"name":"BREAST CANCER RESEARCH"},
     {"name":"AMERICAN JOURNAL OF RESPIRATORY CELL AND MOLECULAR BIOLOGY"},
     {"name":"JOURNAL OF NEUROSCIENCE RESEARCH"},
     {"name":"JOURNAL OF FOOD AND DRUG ANALYSIS"},
     {"name":"INFLAMMATORY BOWEL DISEASES"},
     {"name":"ORGANIC CHEMISTRY FRONTIERS"},
     {"name":"MICROBIAL CELL FACTORIES"},
     {"name":"JOURNAL OF THROMBOSIS AND HAEMOSTASIS"},
     {"name":"BIOLOGY OF THE CELL"},
     {"name":"INTERNATIONAL IMMUNOPHARMACOLOGY"},
     {"name":"MINERALIUM DEPOSITA"},
     {"name":"CURRENT OPINION IN CHEMICAL ENGINEERING"},
     {"name":"SYSTEMATIC ENTOMOLOGY"},
     {"name":"EPIDEMIOLOGY"},
     {"name":"ANNUAL REVIEW OF STATISTICS AND ITS APPLICATION"},
     {"name":"LUNG CANCER"},
     {"name":"FEBS JOURNAL"},
     {"name":"JOURNAL OF EUROPEAN PUBLIC POLICY"},
     {"name":"TRANSPLANTATION"},
     {"name":"INTERNATIONAL JOURNAL OF MECHANICAL SCIENCES"},
     {"name":"AMERICAN JOURNAL OF OPHTHALMOLOGY"},
     {"name":"BONE MARROW TRANSPLANTATION"},
     {"name":"AQUATIC TOXICOLOGY"},
     {"name":"CELL AND TISSUE RESEARCH"},
     {"name":"JOURNAL OF BUSINESS LOGISTICS"},
     {"name":"EUROPEAN PSYCHIATRY"},
     {"name":"HUMAN MOLECULAR GENETICS"},
     {"name":"ANATOMICAL SCIENCES EDUCATION"},
     {"name":"IEEE ROBOTICS AND AUTOMATION LETTERS"},
     {"name":"AIDS AND BEHAVIOR"},
     {"name":"PEDOSPHERE"},
     {"name":"SKIN PHARMACOLOGY AND PHYSIOLOGY"},
     {"name":"JOURNAL OF NATURAL PRODUCTS"},
     {"name":"PREVENTIVE MEDICINE"},
     {"name":"MOLECULAR HUMAN REPRODUCTION"},
     {"name":"ADVANCES IN ANATOMIC PATHOLOGY"},
     {"name":"EUROPEAN JOURNAL OF AGRONOMY"},
     {"name":"IEEE TRANSACTIONS ON INDUSTRY APPLICATIONS"},
     {"name":"CANCER RESEARCH AND TREATMENT"},
     {"name":"GUT PATHOGENS"},
     {"name":"BRITISH JOURNAL OF NUTRITION"},
     {"name":"WORLD JOURNAL OF GASTROENTEROLOGY"},
     {"name":"JOURNAL OF EXPOSURE SCIENCE AND ENVIRONMENTAL EPIDEMIOLOGY"},
     {"name":"APPLIED MATHEMATICS AND COMPUTATION"},
     {"name":"ANTIMICROBIAL RESISTANCE AND INFECTION CONTROL"},
     {"name":"EXPERT OPINION ON BIOLOGICAL THERAPY"},
     {"name":"JOURNAL OF PEDIATRICS"},
     {"name":"JOURNAL OF EXPERIMENTAL SOCIAL PSYCHOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF LOGISTICS MANAGEMENT"},
     {"name":"THERAPEUTIC ADVANCES IN GASTROENTEROLOGY"},
     {"name":"OBESITY"},
     {"name":"IEEE ANTENNAS AND WIRELESS PROPAGATION LETTERS"},
     {"name":"MOLECULAR AND CELLULAR NEUROSCIENCE"},
     {"name":"CRYSTALLOGRAPHY REVIEWS"},
     {"name":"INTERNATIONAL JOURNAL OF HEALTH POLICY AND MANAGEMENT"},
     {"name":"INTERNATIONAL JOURNAL OF SOLIDS AND STRUCTURES"},
     {"name":"PHYSICAL REVIEW B"},
     {"name":"RESEARCH IN MICROBIOLOGY"},
     {"name":"SOCIAL PSYCHIATRY AND PSYCHIATRIC EPIDEMIOLOGY"},
     {"name":"JOURNAL OF SAUDI CHEMICAL SOCIETY"},
     {"name":"FRONTIERS IN SYSTEMS NEUROSCIENCE"},
     {"name":"CLINICAL IMMUNOLOGY"},
     {"name":"REGIONAL ENVIRONMENTAL CHANGE"},
     {"name":"FRONTIERS IN NEUROSCIENCE"},
     {"name":"JOURNAL OF CANCER RESEARCH AND CLINICAL ONCOLOGY"},
     {"name":"PATTERN RECOGNITION LETTERS"},
     {"name":"JOURNAL OF URBAN TECHNOLOGY"},
     {"name":"SPINE JOURNAL"},
     {"name":"MOLECULAR BIOLOGY OF THE CELL"},
     {"name":"QUATERNARY SCIENCE REVIEWS"},
     {"name":"JOURNAL OF TRACE ELEMENTS IN MEDICINE AND BIOLOGY"},
     {"name":"FRONTIERS IN NUTRITION"},
     {"name":"HIPPOCAMPUS"},
     {"name":"ARTIFICIAL CELLS NANOMEDICINE AND BIOTECHNOLOGY"},
     {"name":"CANCER MEDICINE"},
     {"name":"INFECTIOUS DISEASE CLINICS OF NORTH AMERICA"},
     {"name":"DRUG SAFETY"},
     {"name":"LANGMUIR"},
     {"name":"EXPERT REVIEW OF PROTEOMICS"},
     {"name":"IEEE INTELLIGENT SYSTEMS"},
     {"name":"JOURNAL OF SUPERCRITICAL FLUIDS"},
     {"name":"ENDOCRINE"},
     {"name":"CLINICAL KIDNEY JOURNAL"},
     {"name":"JOURNAL OF EPIDEMIOLOGY AND COMMUNITY HEALTH"},
     {"name":"TRANSPLANTATION REVIEWS"},
     {"name":"ENVIRONMENTAL AND MOLECULAR MUTAGENESIS"},
     {"name":"CELL TRANSPLANTATION"},
     {"name":"JOURNAL OF MAMMARY GLAND BIOLOGY AND NEOPLASIA"},
     {"name":"TOXICS"},
     {"name":"OBESITY SURGERY"},
     {"name":"AMYOTROPHIC LATERAL SCLEROSIS AND FRONTOTEMPORAL DEGENERATION"},
     {"name":"INTERNATIONAL JOURNAL OF WATER RESOURCES DEVELOPMENT"},
     {"name":"AICHE JOURNAL"},
     {"name":"JOURNAL OF INORGANIC BIOCHEMISTRY"},
     {"name":"ENDOCRINE PRACTICE"},
     {"name":"ACS COMBINATORIAL SCIENCE"},
     {"name":"RICE SCIENCE"},
     {"name":"CURRENT OPINION IN ALLERGY AND CLINICAL IMMUNOLOGY"},
     {"name":"NPJ MICROGRAVITY"},
     {"name":"AMERICAN JOURNAL OF SCIENCE"},
     {"name":"EUROPEAN EATING DISORDERS REVIEW"},
     {"name":"ATMOSPHERIC POLLUTION RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF FOOD SCIENCES AND NUTRITION"},
     {"name":"BUSINESS HORIZONS"},
     {"name":"DERMATOLOGIC CLINICS"},
     {"name":"JOURNAL OF RESEARCH IN SCIENCE TEACHING"},
     {"name":"CERAMICS INTERNATIONAL"},
     {"name":"EARTH SYSTEM DYNAMICS"},
     {"name":"YALE JOURNAL OF BIOLOGY AND MEDICINE"},
     {"name":"JOURNAL OF PATHOLOGY CLINICAL RESEARCH"},
     {"name":"AMERICAN JOURNAL OF TRANSLATIONAL RESEARCH"},
     {"name":"FEMS YEAST RESEARCH"},
     {"name":"JOURNAL OF NUMERICAL MATHEMATICS"},
     {"name":"DNA REPAIR"},
     {"name":"INFLAMMATION RESEARCH"},
     {"name":"OPEN FORUM INFECTIOUS DISEASES"},
     {"name":"JOURNAL OF FIELD ROBOTICS"},
     {"name":"FRONTIERS OF MEDICINE"},
     {"name":"MARINE AND PETROLEUM GEOLOGY"},
     {"name":"TRANSLATIONAL ONCOLOGY"},
     {"name":"ENVIRONMENT AND URBANIZATION"},
     {"name":"EUROPEAN THYROID JOURNAL"},
     {"name":"JAMA FACIAL PLASTIC SURGERY"},
     {"name":"CURRENT MOLECULAR PHARMACOLOGY"},
     {"name":"PERSPECTIVES IN ECOLOGY AND CONSERVATION"},
     {"name":"BREAST"},
     {"name":"ICES JOURNAL OF MARINE SCIENCE"},
     {"name":"ARCHIVUM IMMUNOLOGIAE ET THERAPIAE EXPERIMENTALIS"},
     {"name":"IEEE COMMUNICATIONS LETTERS"},
     {"name":"ANNALS OF MEDICINE"},
     {"name":"JOURNAL OF SERVICES MARKETING"},
     {"name":"JOURNAL OF LABOR ECONOMICS"},
     {"name":"ELEMENTS"},
     {"name":"JOURNAL OF BIOMEDICAL INFORMATICS"},
     {"name":"GERONTOLOGY"},
     {"name":"INTERNATIONAL AFFAIRS"},
     {"name":"OCEANOGRAPHY"},
     {"name":"APPLIED ORGANOMETALLIC CHEMISTRY"},
     {"name":"COMMUNICATION RESEARCH"},
     {"name":"JOURNAL OF HUMAN NUTRITION AND DIETETICS"},
     {"name":"INTERNATIONAL JOURNAL OF TRANSGENDERISM"},
     {"name":"FOOD AND BIOPROCESS TECHNOLOGY"},
     {"name":"SEMANTIC WEB"},
     {"name":"CHINESE JOURNAL OF POLYMER SCIENCE"},
     {"name":"ANTHROPOCENE"},
     {"name":"EUROPEAN JOURNAL OF PHARMACEUTICAL SCIENCES"},
     {"name":"JOURNAL OF PROCESS CONTROL"},
     {"name":"VETERINARY RESEARCH"},
     {"name":"FINANCE RESEARCH LETTERS"},
     {"name":"SYSTEMATIC AND APPLIED MICROBIOLOGY"},
     {"name":"STUDIES IN SCIENCE EDUCATION"},
     {"name":"COMPUTER METHODS AND PROGRAMS IN BIOMEDICINE"},
     {"name":"AQUACULTURE"},
     {"name":"HPB"},
     {"name":"JOURNAL OF ELECTROANALYTICAL CHEMISTRY"},
     {"name":"JOURNAL OF THE AMERICAN SOCIETY FOR MASS SPECTROMETRY"},
     {"name":"ENZYME AND MICROBIAL TECHNOLOGY"},
     {"name":"ENERGY TECHNOLOGY"},
     {"name":"AUTONOMOUS ROBOTS"},
     {"name":"JOURNAL OF VASCULAR SURGERY"},
     {"name":"NEW POLITICAL ECONOMY"},
     {"name":"LIMNOLOGY AND OCEANOGRAPHY"},
     {"name":"IMMUNOLOGY AND CELL BIOLOGY"},
     {"name":"AMERICAN JOURNAL OF CHINESE MEDICINE"},
     {"name":"PERSPECTIVES ON SEXUAL AND REPRODUCTIVE HEALTH"},
     {"name":"JOURNAL OF INFORMATION TECHNOLOGY"},
     {"name":"GLOBAL HEART"},
     {"name":"PAIN PHYSICIAN"},
     {"name":"INTERNATIONAL JOURNAL OF DAMAGE MECHANICS"},
     {"name":"JOURNAL OF ENGINEERING TECHNOLOGY"},
     {"name":"INTERFACE FOCUS"},
     {"name":"INTERNATIONAL JOURNAL OF MACHINE LEARNING AND CYBERNETICS"},
     {"name":"EUROPEAN REVIEW OF SOCIAL PSYCHOLOGY"},
     {"name":"GENES"},
     {"name":"JOURNAL OF PHYSICS AND CHEMISTRY OF SOLIDS"},
     {"name":"PLANT METHODS"},
     {"name":"JOURNAL OF COUNSELING PSYCHOLOGY"},
     {"name":"READING RESEARCH QUARTERLY"},
     {"name":"JOURNAL OF ENVIRONMENTAL ECONOMICS AND MANAGEMENT"},
     {"name":"JOURNAL OF CARDIAC FAILURE"},
     {"name":"INFLUENZA AND OTHER RESPIRATORY VIRUSES"},
     {"name":"MATURITAS"},
     {"name":"MICROBIAL ECOLOGY"},
     {"name":"URBAN CLIMATE"},
     {"name":"COMPUTERS IN BIOLOGY AND MEDICINE"},
     {"name":"JOURNAL OF VIRAL HEPATITIS"},
     {"name":"CHEMICAL RESEARCH IN TOXICOLOGY"},
     {"name":"CLINICAL AND EXPERIMENTAL RHEUMATOLOGY"},
     {"name":"JOURNAL OF TRANSPORT GEOGRAPHY"},
     {"name":"MATERIALS CHEMISTRY AND PHYSICS"},
     {"name":"IEEE TRANSACTIONS ON BROADCASTING"},
     {"name":"INFECTION AND IMMUNITY"},
     {"name":"CHEMBIOENG REVIEWS"},
     {"name":"PROSTATE"},
     {"name":"OCEAN MODELLING"},
     {"name":"RESPIRATION"},
     {"name":"EUROPEAN JOURNAL OF PHARMACOLOGY"},
     {"name":"ACTA OPHTHALMOLOGICA"},
     {"name":"EUROPEAN JOURNAL OF CLINICAL INVESTIGATION"},
     {"name":"SEXUALLY TRANSMITTED INFECTIONS"},
     {"name":"JOURNAL OF HOSPITAL INFECTION"},
     {"name":"CLINICAL JOURNAL OF SPORT MEDICINE"},
     {"name":"PLANTA"},
     {"name":"SCIENCE EDUCATION"},
     {"name":"FRONTIERS IN GENETICS"},
     {"name":"WORK AND STRESS"},
     {"name":"CURRENT HYPERTENSION REPORTS"},
     {"name":"STRATEGIC ORGANIZATION"},
     {"name":"ORPHANET JOURNAL OF RARE DISEASES"},
     {"name":"COMPUTERS AND GEOTECHNICS"},
     {"name":"JOURNAL OF DENTISTRY"},
     {"name":"PALLIATIVE MEDICINE"},
     {"name":"MICHIGAN LAW REVIEW"},
     {"name":"TREE PHYSIOLOGY"},
     {"name":"EXCEPTIONAL CHILDREN"},
     {"name":"WORLD JOURNAL OF STEM CELLS"},
     {"name":"IEEE TRANSACTIONS ON INSTRUMENTATION AND MEASUREMENT"},
     {"name":"INTERNATIONAL JOURNAL OF MECHANICS AND MATERIALS IN DESIGN"},
     {"name":"CROP JOURNAL"},
     {"name":"SENSORS"},
     {"name":"PHYSICS OF FLUIDS"},
     {"name":"CURRENT ANTHROPOLOGY"},
     {"name":"ARCHIVES OF SEXUAL BEHAVIOR"},
     {"name":"INDUSTRY AND INNOVATION"},
     {"name":"ASSESSMENT"},
     {"name":"SUSTAINABLE CHEMISTRY AND PHARMACY"},
     {"name":"EXPERT REVIEW OF CLINICAL PHARMACOLOGY"},
     {"name":"ANIMAL HEALTH RESEARCH REVIEWS"},
     {"name":"JOURNAL OF MICROBIOLOGY IMMUNOLOGY AND INFECTION"},
     {"name":"SMART MATERIALS AND STRUCTURES"},
     {"name":"INTERNATIONAL JOURNAL OF SPORTS PHYSIOLOGY AND PERFORMANCE"},
     {"name":"MEAT SCIENCE"},
     {"name":"STEM CELLS AND DEVELOPMENT"},
     {"name":"ADDICTIVE BEHAVIORS"},
     {"name":"JOURNAL OF THE AMERICAN CERAMIC SOCIETY"},
     {"name":"BMB REPORTS"},
     {"name":"SURGERY"},
     {"name":"JOURNAL OF COMPETITIVENESS"},
     {"name":"QUANTITATIVE IMAGING IN MEDICINE AND SURGERY"},
     {"name":"MONTHLY WEATHER REVIEW"},
     {"name":"LANGUAGE TEACHING"},
     {"name":"REVIEW OF INTERNATIONAL ORGANIZATIONS"},
     {"name":"JOURNAL OF RURAL STUDIES"},
     {"name":"AMERICAN JOURNAL OF NEURORADIOLOGY"},
     {"name":"EXPERIMENTAL CELL RESEARCH"},
     {"name":"CURRENT OPINION IN ONCOLOGY"},
     {"name":"JOURNAL OF RESEARCH ON EDUCATIONAL EFFECTIVENESS"},
     {"name":"BIOGERONTOLOGY"},
     {"name":"CALCIFIED TISSUE INTERNATIONAL"},
     {"name":"JOURNAL OF LEUKOCYTE BIOLOGY"},
     {"name":"SOFT MATTER"},
     {"name":"JOURNAL OF BIOTECHNOLOGY"},
     {"name":"JOURNAL OF THE EUROPEAN ECONOMIC ASSOCIATION"},
     {"name":"FOREIGN AFFAIRS"},
     {"name":"EMERGENCIAS"},
     {"name":"PLACENTA"},
     {"name":"MEDICAL CARE"},
     {"name":"TRANSFUSION MEDICINE REVIEWS"},
     {"name":"MACROMOLECULAR BIOSCIENCE"},
     {"name":"CLINICAL ENDOCRINOLOGY"},
     {"name":"GEOHEALTH"},
     {"name":"ANNALS OF TRANSLATIONAL MEDICINE"},
     {"name":"JOURNAL OF CLINICAL CHILD AND ADOLESCENT PSYCHOLOGY"},
     {"name":"CONTRIBUTIONS TO MINERALOGY AND PETROLOGY"},
     {"name":"JOURNAL OF BIOMEDICAL MATERIALS RESEARCH PART A"},
     {"name":"JOURNAL OF QUANTITATIVE CRIMINOLOGY"},
     {"name":"DEVELOPMENTAL SCIENCE"},
     {"name":"JOURNAL OF IMMUNOLOGY RESEARCH"},
     {"name":"TRANSPORTATION SCIENCE"},
     {"name":"LAND USE POLICY"},
     {"name":"BEHAVIOR THERAPY"},
     {"name":"IEEE JOURNAL OF SELECTED TOPICS IN APPLIED EARTH OBSERVATIONS AND REMOTE SENSING"},
     {"name":"CURRENT PROBLEMS IN CANCER"},
     {"name":"JOURNAL OF SEX RESEARCH"},
     {"name":"EXPERT OPINION ON DRUG SAFETY"},
     {"name":"ATMOSPHERIC MEASUREMENT TECHNIQUES"},
     {"name":"JOURNAL OF ETHNOPHARMACOLOGY"},
     {"name":"EUROPEAN JOURNAL OF HUMAN GENETICS"},
     {"name":"KOREAN JOURNAL OF RADIOLOGY"},
     {"name":"BIOMEDICAL JOURNAL"},
     {"name":"ANNALS OF THE AMERICAN ASSOCIATION OF GEOGRAPHERS"},
     {"name":"DEVELOPMENTAL REVIEW"},
     {"name":"JOURNAL OF PETROLEUM SCIENCE AND ENGINEERING"},
     {"name":"MODERN LANGUAGE JOURNAL"},
     {"name":"CULTURAL ANTHROPOLOGY"},
     {"name":"CURRENT TOPICS IN MEMBRANES"},
     {"name":"AMERICAN NATURALIST"},
     {"name":"EXPERIMENTAL BIOLOGY AND MEDICINE"},
     {"name":"CHEMPHYSCHEM"},
     {"name":"TOURISM GEOGRAPHIES"},
     {"name":"JOURNAL OF ANALYTICAL ATOMIC SPECTROMETRY"},
     {"name":"CURRENT TOPICS IN MEDICINAL CHEMISTRY"},
     {"name":"JOURNAL OF PSYCHIATRIC RESEARCH"},
     {"name":"PLANT CELL REPORTS"},
     {"name":"WORLD JOURNAL OF UROLOGY"},
     {"name":"PHYSICAL REVIEW MATERIALS"},
     {"name":"ONCOLOGY REPORTS"},
     {"name":"EVOLUTIONARY ANTHROPOLOGY"},
     {"name":"SOIL"},
     {"name":"DEVELOPMENT AND PSYCHOPATHOLOGY"},
     {"name":"INFORMATICA"},
     {"name":"PANCREATOLOGY"},
     {"name":"CELLULAR AND MOLECULAR NEUROBIOLOGY"},
     {"name":"CURRENT OPINION IN CLINICAL NUTRITION AND METABOLIC CARE"},
     {"name":"SEMINARS IN NUCLEAR MEDICINE"},
     {"name":"JOURNAL OF GENERAL VIROLOGY"},
     {"name":"COMMON MARKET LAW REVIEW"},
     {"name":"WORLD JOURNAL OF DIABETES"},
     {"name":"GEOMORPHOLOGY"},
     {"name":"POLICY SCIENCES"},
     {"name":"MYCOTOXIN RESEARCH"},
     {"name":"JOURNAL OF COMPUTATIONAL DESIGN AND ENGINEERING"},
     {"name":"OSTEOPOROSIS INTERNATIONAL"},
     {"name":"JOURNAL OF EPIDEMIOLOGY"},
     {"name":"HUMAN RESOURCE DEVELOPMENT QUARTERLY"},
     {"name":"LEARNING AND INSTRUCTION"},
     {"name":"HUMAN GENE THERAPY CLINICAL DEVELOPMENT"},
     {"name":"MATERIALS CHARACTERIZATION"},
     {"name":"JOURNAL OF ECONOMIC SURVEYS"},
     {"name":"IEEE ANTENNAS AND PROPAGATION MAGAZINE"},
     {"name":"POLYMERS"},
     {"name":"PLANT AND SOIL"},
     {"name":"JOURNAL OF PERSONALITY"},
     {"name":"CLINICAL IMPLANT DENTISTRY AND RELATED RESEARCH"},
     {"name":"EXPERIMENTAL THERMAL AND FLUID SCIENCE"},
     {"name":"CARTILAGE"},
     {"name":"EUROPEAN JOURNAL OF INTERNATIONAL RELATIONS"},
     {"name":"PHYSICAL CHEMISTRY CHEMICAL PHYSICS"},
     {"name":"MINERVA UROLOGICA E NEFROLOGICA"},
     {"name":"PLATELETS"},
     {"name":"IMMUNOLOGY LETTERS"},
     {"name":"STANFORD LAW REVIEW"},
     {"name":"ANNUAL REVIEW OF ECONOMICS"},
     {"name":"REPRODUCTION"},
     {"name":"FUTURE MEDICINAL CHEMISTRY"},
     {"name":"MOLECULAR AND CELLULAR ENDOCRINOLOGY"},
     {"name":"CLINICAL GENETICS"},
     {"name":"JOURNAL OF APPLIED RESEARCH IN MEMORY AND COGNITION"},
     {"name":"JOURNAL FOR RESEARCH IN MATHEMATICS EDUCATION"},
     {"name":"RHEUMATIC DISEASE CLINICS OF NORTH AMERICA"},
     {"name":"NUTRITION"},
     {"name":"GEOTHERMICS"},
     {"name":"COMMENTS ON INORGANIC CHEMISTRY"},
     {"name":"TRAVEL BEHAVIOUR AND SOCIETY"},
     {"name":"THERAPEUTIC ADVANCES IN HEMATOLOGY"},
     {"name":"NEURAL REGENERATION RESEARCH"},
     {"name":"EUROPEAN JOURNAL OF SOIL SCIENCE"},
     {"name":"MEDIATORS OF INFLAMMATION"},
     {"name":"FORUM OF MATHEMATICS PI"},
     {"name":"ORGANIC ELECTRONICS"},
     {"name":"PATHOLOGY"},
     {"name":"INFORMATION AND ORGANIZATION"},
     {"name":"JOURNAL OF HUMAN EVOLUTION"},
     {"name":"IT PROFESSIONAL"},
     {"name":"FRACTIONAL CALCULUS AND APPLIED ANALYSIS"},
     {"name":"BRAIN IMAGING AND BEHAVIOR"},
     {"name":"INFLAMMOPHARMACOLOGY"},
     {"name":"POLITICAL PSYCHOLOGY"},
     {"name":"JOURNAL OF GEOCHEMICAL EXPLORATION"},
     {"name":"JOURNAL OF ACCOUNTING RESEARCH"},
     {"name":"NEW JOURNAL OF PHYSICS"},
     {"name":"JOURNAL OF THE ELECTROCHEMICAL SOCIETY"},
     {"name":"HUMAN GENOMICS"},
     {"name":"PLANT GENOME"},
     {"name":"ONCOTARGETS AND THERAPY"},
     {"name":"ACTA DIABETOLOGICA"},
     {"name":"FRONTIERS IN NEUROANATOMY"},
     {"name":"FRONTIERS IN BIOENGINEERING AND BIOTECHNOLOGY"},
     {"name":"EMOTION"},
     {"name":"IEEE TRANSACTIONS ON RELIABILITY"},
     {"name":"CELL CYCLE"},
     {"name":"SEDIMENTOLOGY"},
     {"name":"HEALTH REPORTS"},
     {"name":"JOURNAL OF PHYSICAL OCEANOGRAPHY"},
     {"name":"STRUCTURAL AND MULTIDISCIPLINARY OPTIMIZATION"},
     {"name":"ORGANIC GEOCHEMISTRY"},
     {"name":"COMUNICAR"},
     {"name":"DEVELOPMENTAL DYNAMICS"},
     {"name":"TRANSPORT POLICY"},
     {"name":"BRITISH JOURNAL OF POLITICAL SCIENCE"},
     {"name":"AMERICAN JOURNAL OF NEPHROLOGY"},
     {"name":"EXPERIMENTAL GERONTOLOGY"},
     {"name":"JOURNAL OF DIABETES INVESTIGATION"},
     {"name":"BRITISH JOURNAL OF CLINICAL PHARMACOLOGY"},
     {"name":"CRITICAL CARE CLINICS"},
     {"name":"JOURNAL OF FOOD COMPOSITION AND ANALYSIS"},
     {"name":"HYDROLOGICAL PROCESSES"},
     {"name":"APL MATERIALS"},
     {"name":"CURRENT OPINION IN GASTROENTEROLOGY"},
     {"name":"OCCUPATIONAL AND ENVIRONMENTAL MEDICINE"},
     {"name":"MOLECULAR IMMUNOLOGY"},
     {"name":"JOURNAL OF HOSPITALITY AND TOURISM MANAGEMENT"},
     {"name":"JOURNAL OF NEURODEVELOPMENTAL DISORDERS"},
     {"name":"EUROPEAN JOURNAL OF PAIN"},
     {"name":"EDUCATIONAL EVALUATION AND POLICY ANALYSIS"},
     {"name":"APPLIED GEOGRAPHY"},
     {"name":"LGBT HEALTH"},
     {"name":"PHARMACOECONOMICS"},
     {"name":"EAR AND HEARING"},
     {"name":"ASIAN JOURNAL OF ORGANIC CHEMISTRY"},
     {"name":"EUROPEAN UROLOGY SUPPLEMENTS"},
     {"name":"INTERNATIONAL JOURNAL OF RESEARCH IN MARKETING"},
     {"name":"WORLD BANK RESEARCH OBSERVER"},
     {"name":"PSYCHOPHYSIOLOGY"},
     {"name":"NATURAL RESOURCES RESEARCH"},
     {"name":"WORLD DEVELOPMENT"},
     {"name":"JOURNAL OF SLEEP RESEARCH"},
     {"name":"JOURNAL OF INSECTS AS FOOD AND FEED"},
     {"name":"TOURISM MANAGEMENT PERSPECTIVES"},
     {"name":"ENDOCRINOLOGY AND METABOLISM"},
     {"name":"EUROPEAN JOURNAL OF PSYCHOTRAUMATOLOGY"},
     {"name":"JOURNAL OF INTENSIVE CARE MEDICINE"},
     {"name":"CELL DIVISION"},
     {"name":"CURRENT OPINION IN ENDOCRINOLOGY DIABETES AND OBESITY"},
     {"name":"JOURNAL OF ECONOMIC GEOGRAPHY"},
     {"name":"JOURNAL OF SERVICE THEORY AND PRACTICE"},
     {"name":"CURRENT OPINION IN HIV AND AIDS"},
     {"name":"TOXINS"},
     {"name":"JOURNAL OF BIOGEOGRAPHY"},
     {"name":"TRANSPLANT INTERNATIONAL"},
     {"name":"CHROMOSOME RESEARCH"},
     {"name":"HIV MEDICINE"},
     {"name":"PLANT PHYSIOLOGY AND BIOCHEMISTRY"},
     {"name":"GEOLOGICAL SOCIETY OF AMERICA BULLETIN"},
     {"name":"IEEE TRANSACTIONS ON POWER DELIVERY"},
     {"name":"JOURNAL OF PHARMACEUTICAL AND BIOMEDICAL ANALYSIS"},
     {"name":"TECTONICS"},
     {"name":"DEVELOPMENTAL AND COMPARATIVE IMMUNOLOGY"},
     {"name":"ENVIRONMENTAL TOXICOLOGY AND PHARMACOLOGY"},
     {"name":"JOURNAL OF WATER PROCESS ENGINEERING"},
     {"name":"BREAST CANCER RESEARCH AND TREATMENT"},
     {"name":"APPLIED PHYSICS LETTERS"},
     {"name":"REPRODUCTIVE BIOMEDICINE ONLINE"},
     {"name":"INTERNATIONAL JOURNAL OF HEALTH GEOGRAPHICS"},
     {"name":"IMA FUNGUS"},
     {"name":"AD HOC NETWORKS"},
     {"name":"JOURNAL DER DEUTSCHEN DERMATOLOGISCHEN GESELLSCHAFT"},
     {"name":"EUROPEAN NEUROPSYCHOPHARMACOLOGY"},
     {"name":"BIOMEDICAL SIGNAL PROCESSING AND CONTROL"},
     {"name":"JOURNAL OF NEUROENGINEERING AND REHABILITATION"},
     {"name":"JOURNAL OF BUILDING PERFORMANCE SIMULATION"},
     {"name":"FRONTIERS IN CHEMISTRY"},
     {"name":"CLINICAL CHILD AND FAMILY PSYCHOLOGY REVIEW"},
     {"name":"MOLECULAR PHYLOGENETICS AND EVOLUTION"},
     {"name":"MEMETIC COMPUTING"},
     {"name":"JOURNAL OF MOLECULAR ENDOCRINOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF ROBUST AND NONLINEAR CONTROL"},
     {"name":"MYCOSES"},
     {"name":"ACTA ONCOLOGICA"},
     {"name":"AUSTRALIAN JOURNAL OF GRAPE AND WINE RESEARCH"},
     {"name":"JOURNAL OF THE LEARNING SCIENCES"},
     {"name":"CURRENT TREATMENT OPTIONS IN NEUROLOGY"},
     {"name":"JOURNAL OF MACHINE LEARNING RESEARCH"},
     {"name":"DIABETES THERAPY"},
     {"name":"FRONTIERS OF CHEMICAL SCIENCE AND ENGINEERING"},
     {"name":"GEOCARTO INTERNATIONAL"},
     {"name":"IEEE ACCESS"},
     {"name":"JOURNAL OF TRAUMA AND ACUTE CARE SURGERY"},
     {"name":"INTEGRATING MATERIALS AND MANUFACTURING INNOVATION"},
     {"name":"SURGERY FOR OBESITY AND RELATED DISEASES"},
     {"name":"JOURNAL OF HUMAN RESOURCES"},
     {"name":"MOLECULES"},
     {"name":"OXFORD REVIEW OF ECONOMIC POLICY"},
     {"name":"CORAL REEFS"},
     {"name":"INTERNATIONAL ENDODONTIC JOURNAL"},
     {"name":"SYNTHETIC METALS"},
     {"name":"JOURNAL OF CLINICAL LIPIDOLOGY"},
     {"name":"JOURNAL OF CANCER"},
     {"name":"NEW JOURNAL OF CHEMISTRY"},
     {"name":"JOURNAL OF ASTHMA AND ALLERGY"},
     {"name":"ELECTRONIC COMMERCE RESEARCH AND APPLICATIONS"},
     {"name":"EVOLUTION"},
     {"name":"PROTEOMICS"},
     {"name":"HISTOPATHOLOGY"},
     {"name":"SEISMOLOGICAL RESEARCH LETTERS"},
     {"name":"EUROPEAN POLYMER JOURNAL"},
     {"name":"PSYCHOSOMATIC MEDICINE"},
     {"name":"CLINICAL TOXICOLOGY"},
     {"name":"JOURNAL OF NUCLEAR CARDIOLOGY"},
     {"name":"INFLAMMATION"},
     {"name":"QUALITATIVE INQUIRY"},
     {"name":"BMC GENOMICS"},
     {"name":"CHROMOSOMA"},
     {"name":"FRESHWATER BIOLOGY"},
     {"name":"CLINICAL AND EXPERIMENTAL IMMUNOLOGY"},
     {"name":"NEW TECHNOLOGY WORK AND EMPLOYMENT"},
     {"name":"PHYSICAL THERAPY"},
     {"name":"NANOTECHNOLOGY REVIEWS"},
     {"name":"JOURNAL OF PHARMACOLOGY AND EXPERIMENTAL THERAPEUTICS"},
     {"name":"HEPATOLOGY RESEARCH"},
     {"name":"INVESTIGATIONAL NEW DRUGS"},
     {"name":"R JOURNAL"},
     {"name":"REGIONAL STUDIES"},
     {"name":"ANNALS OF CLINICAL AND TRANSLATIONAL NEUROLOGY"},
     {"name":"JOURNAL OF LUMINESCENCE"},
     {"name":"JOURNAL OF THE ATMOSPHERIC SCIENCES"},
     {"name":"ENVIRONMENTAL EVIDENCE"},
     {"name":"SEMINARS IN PERINATOLOGY"},
     {"name":"BMC BIOINFORMATICS"},
     {"name":"APPLIED INTELLIGENCE"},
     {"name":"JOURNAL OF SCIENCE AND MEDICINE IN SPORT"},
     {"name":"ORE GEOLOGY REVIEWS"},
     {"name":"MOLECULAR CATALYSIS"},
     {"name":"JOURNAL OF GERIATRIC PHYSICAL THERAPY"},
     {"name":"JOURNAL OF CLINICAL SLEEP MEDICINE"},
     {"name":"HUMAN COMMUNICATION RESEARCH"},
     {"name":"NUTRITION JOURNAL"},
     {"name":"PLANT MOLECULAR BIOLOGY"},
     {"name":"INTERNATIONAL SOIL AND WATER CONSERVATION RESEARCH"},
     {"name":"PEDIATRIC OBESITY"},
     {"name":"HEARING RESEARCH"},
     {"name":"JOURNAL OF PROTEOMICS"},
     {"name":"DIGESTIVE AND LIVER DISEASE"},
     {"name":"MOLECULAR AND CELLULAR BIOLOGY"},
     {"name":"BRITISH ACCOUNTING REVIEW"},
     {"name":"NEUROSURGICAL FOCUS"},
     {"name":"COMMUNICATION MONOGRAPHS"},
     {"name":"IEEE TRANSACTIONS ON CONTROL OF NETWORK SYSTEMS"},
     {"name":"BIG DATA"},
     {"name":"HISTOCHEMISTRY AND CELL BIOLOGY"},
     {"name":"SPORT MANAGEMENT REVIEW"},
     {"name":"LIFE SCIENCES"},
     {"name":"VIRTUAL REALITY"},
     {"name":"IEEE TRANSACTIONS ON SIGNAL AND INFORMATION PROCESSING OVER NETWORKS"},
     {"name":"JOURNAL OF NATURAL GAS SCIENCE AND ENGINEERING"},
     {"name":"INTERNATIONAL JOURNAL OF SURGERY"},
     {"name":"HYDROMETALLURGY"},
     {"name":"HEALTH TECHNOLOGY ASSESSMENT"},
     {"name":"COMPUTERS AND ELECTRONICS IN AGRICULTURE"},
     {"name":"CLINICAL NEUROPHYSIOLOGY"},
     {"name":"FRONTIERS IN NEURAL CIRCUITS"},
     {"name":"BIOMEDICAL MATERIALS"},
     {"name":"FOOD AND BIOPRODUCTS PROCESSING"},
     {"name":"JOURNALISM"},
     {"name":"JOURNAL OF POSITIVE PSYCHOLOGY"},
     {"name":"PHARMACOTHERAPY"},
     {"name":"JOINT BONE SPINE"},
     {"name":"EXPERIMENTAL DERMATOLOGY"},
     {"name":"LANDSCAPE ECOLOGY"},
     {"name":"INFORMATION SYSTEMS RESEARCH"},
     {"name":"TOXICOLOGY LETTERS"},
     {"name":"INTERNATIONAL JOURNAL OF INFECTIOUS DISEASES"},
     {"name":"ANNALS OF PHYSICAL AND REHABILITATION MEDICINE"},
     {"name":"APPLIED MICROBIOLOGY AND BIOTECHNOLOGY"},
     {"name":"KIDNEY INTERNATIONAL REPORTS"},
     {"name":"NPJ PRIMARY CARE RESPIRATORY MEDICINE"},
     {"name":"ACM TRANSACTIONS ON MULTIMEDIA COMPUTING COMMUNICATIONS AND APPLICATIONS"},
     {"name":"EXPERT REVIEW OF ANTICANCER THERAPY"},
     {"name":"CLINICAL COLORECTAL CANCER"},
     {"name":"ICARUS"},
     {"name":"MOLECULAR MICROBIOLOGY"},
     {"name":"INTERNATIONAL ENTREPRENEURSHIP AND MANAGEMENT JOURNAL"},
     {"name":"NUCLEAR FUSION"},
     {"name":"JOURNAL OF ENDOCRINOLOGICAL INVESTIGATION"},
     {"name":"CANCER PREVENTION RESEARCH"},
     {"name":"FOOD ENGINEERING REVIEWS"},
     {"name":"REPRODUCTIVE BIOLOGY AND ENDOCRINOLOGY"},
     {"name":"JOURNAL OF ARTHROPLASTY"},
     {"name":"AMERICAN JOURNAL OF GERIATRIC PSYCHIATRY"},
     {"name":"TISSUE ENGINEERING PART A"},
     {"name":"IEEE INTELLIGENT TRANSPORTATION SYSTEMS MAGAZINE"},
     {"name":"ARCHIVES OF CIVIL AND MECHANICAL ENGINEERING"},
     {"name":"ENDOCRINE PATHOLOGY"},
     {"name":"IEEE TRANSACTIONS ON MICROWAVE THEORY AND TECHNIQUES"},
     {"name":"INTERNATIONAL JOURNAL OF EATING DISORDERS"},
     {"name":"RISK ANALYSIS"},
     {"name":"BENEFICIAL MICROBES"},
     {"name":"MICROCHEMICAL JOURNAL"},
     {"name":"MUTAGENESIS"},
     {"name":"JOURNAL OF PUBLIC ADMINISTRATION RESEARCH AND THEORY"},
     {"name":"BASIC AND APPLIED ECOLOGY"},
     {"name":"FRONTIERS IN PHYSIOLOGY"},
     {"name":"CLIMATE OF THE PAST"},
     {"name":"METHODS"},
     {"name":"GREEN CHEMISTRY LETTERS AND REVIEWS"},
     {"name":"DRUG METABOLISM AND DISPOSITION"},
     {"name":"ANNALS OF THORACIC SURGERY"},
     {"name":"UNITED EUROPEAN GASTROENTEROLOGY JOURNAL"},
     {"name":"ACCIDENT ANALYSIS AND PREVENTION"},
     {"name":"JMIR SERIOUS GAMES"},
     {"name":"TOXICOLOGY AND APPLIED PHARMACOLOGY"},
     {"name":"SPE JOURNAL"},
     {"name":"OCEANOGRAPHY AND MARINE BIOLOGY"},
     {"name":"MAGNETIC RESONANCE IN MEDICINE"},
     {"name":"DRUG DESIGN DEVELOPMENT AND THERAPY"},
     {"name":"JOURNAL OF MANUFACTURING TECHNOLOGY MANAGEMENT"},
     {"name":"JOURNAL OF CLINICAL MEDICINE"},
     {"name":"JOURNAL OF SEXUAL MEDICINE"},
     {"name":"OPTICS AND LASER TECHNOLOGY"},
     {"name":"NANOSCALE RESEARCH LETTERS"},
     {"name":"HUMAN FACTORS"},
     {"name":"MARINE POLICY"},
     {"name":"JOURNAL OF INTERNATIONAL MANAGEMENT"},
     {"name":"JOURNAL OF DERMATOLOGICAL SCIENCE"},
     {"name":"OBESITY FACTS"},
     {"name":"KNEE SURGERY SPORTS TRAUMATOLOGY ARTHROSCOPY"},
     {"name":"JOURNAL OF ONCOLOGY PRACTICE"},
     {"name":"INSECT BIOCHEMISTRY AND MOLECULAR BIOLOGY"},
     {"name":"BMC CANCER"},
     {"name":"CELLULAR MICROBIOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF CARDIOLOGY"},
     {"name":"ANALYTICAL AND BIOANALYTICAL CHEMISTRY"},
     {"name":"BRITISH JOURNAL OF PSYCHOLOGY"},
     {"name":"BIOCHEMICAL ENGINEERING JOURNAL"},
     {"name":"JOURNAL OF BIOCHEMICAL AND MOLECULAR TOXICOLOGY"},
     {"name":"IEEE TRANSACTIONS ON AEROSPACE AND ELECTRONIC SYSTEMS"},
     {"name":"CNS SPECTRUMS"},
     {"name":"IEEE MICRO"},
     {"name":"FOREST POLICY AND ECONOMICS"},
     {"name":"BIOLOGY OF REPRODUCTION"},
     {"name":"DERMATOLOGY"},
     {"name":"JOURNAL OF PERIODONTOLOGY"},
     {"name":"JOURNAL OF NEPHROLOGY"},
     {"name":"ENGINEERING STRUCTURES"},
     {"name":"BIOLOGICAL CHEMISTRY"},
     {"name":"MINERALS ENGINEERING"},
     {"name":"JOURNAL OF FLUID MECHANICS"},
     {"name":"MICROBIOLOGYOPEN"},
     {"name":"AAPS JOURNAL"},
     {"name":"JOURNAL OF RHEOLOGY"},
     {"name":"LITHOSPHERE"},
     {"name":"JMIR MENTAL HEALTH"},
     {"name":"NEUROINFORMATICS"},
     {"name":"SOCIAL COGNITIVE AND AFFECTIVE NEUROSCIENCE"},
     {"name":"POLICY STUDIES JOURNAL"},
     {"name":"POPULATION HEALTH METRICS"},
     {"name":"METALLOMICS"},
     {"name":"TOXICOLOGICAL SCIENCES"},
     {"name":"NEUROTOXICOLOGY AND TERATOLOGY"},
     {"name":"JOURNAL OF TEACHER EDUCATION"},
     {"name":"EMPIRICAL SOFTWARE ENGINEERING"},
     {"name":"INTERNATIONAL GEOLOGY REVIEW"},
     {"name":"CURRENT INFECTIOUS DISEASE REPORTS"},
     {"name":"FOREST ECOLOGY AND MANAGEMENT"},
     {"name":"OPTICS EXPRESS"},
     {"name":"RHEUMATOLOGY AND THERAPY"},
     {"name":"CLINICAL NEURORADIOLOGY"},
     {"name":"MATERIALS LETTERS"},
     {"name":"CATALYSIS COMMUNICATIONS"},
     {"name":"NANOTECHNOLOGY"},
     {"name":"JOURNAL OF OPTICAL COMMUNICATIONS AND NETWORKING"},
     {"name":"SOCIOLOGY OF EDUCATION"},
     {"name":"JOURNAL OF FUNCTIONAL FOODS"},
     {"name":"STEM CELLS INTERNATIONAL"},
     {"name":"CEREBELLUM"},
     {"name":"JOURNAL OF NEURAL TRANSMISSION"},
     {"name":"FRONTIERS IN ENDOCRINOLOGY"},
     {"name":"JOURNAL OF SOUND AND VIBRATION"},
     {"name":"CURRENT ALLERGY AND ASTHMA REPORTS"},
     {"name":"PHYTOPATHOLOGY"},
     {"name":"HUMAN CELL"},
     {"name":"AUTISM RESEARCH"},
     {"name":"EUROPEAN RESEARCH ON MANAGEMENT AND BUSINESS ECONOMICS"},
     {"name":"INTERNATIONAL IMMUNOLOGY"},
     {"name":"GENOME BIOLOGY AND EVOLUTION"},
     {"name":"REVIEWS IN THE NEUROSCIENCES"},
     {"name":"EXPERT REVIEW OF CLINICAL IMMUNOLOGY"},
     {"name":"POLYMER TESTING"},
     {"name":"MOVEMENT ECOLOGY"},
     {"name":"BIOINORGANIC CHEMISTRY AND APPLICATIONS"},
     {"name":"SMART STRUCTURES AND SYSTEMS"},
     {"name":"APPLIED SOIL ECOLOGY"},
     {"name":"IEEE TRANSACTIONS ON NEURAL SYSTEMS AND REHABILITATION ENGINEERING"},
     {"name":"CONTROL ENGINEERING PRACTICE"},
     {"name":"IEEE GEOSCIENCE AND REMOTE SENSING LETTERS"},
     {"name":"CATALYSTS"},
     {"name":"OIKOS"},
     {"name":"LITHOS"},
     {"name":"CLINICAL ORAL IMPLANTS RESEARCH"},
     {"name":"MITIGATION AND ADAPTATION STRATEGIES FOR GLOBAL CHANGE"},
     {"name":"PROBIOTICS AND ANTIMICROBIAL PROTEINS"},
     {"name":"PLANT DISEASE"},
     {"name":"JOURNAL OF MATERIALS SCIENCE"},
     {"name":"HARMFUL ALGAE"},
     {"name":"DNA AND CELL BIOLOGY"},
     {"name":"COMPUTATIONAL MECHANICS"},
     {"name":"ELECTRIC POWER SYSTEMS RESEARCH"},
     {"name":"JOURNAL OF THE ROYAL SOCIETY INTERFACE"},
     {"name":"GENES BRAIN AND BEHAVIOR"},
     {"name":"JOURNAL OF ENVIRONMENTAL PSYCHOLOGY"},
     {"name":"PHOTOSYNTHESIS RESEARCH"},
     {"name":"MEDICAL CLINICS OF NORTH AMERICA"},
     {"name":"INFORMATION SYSTEMS FRONTIERS"},
     {"name":"CANCER JOURNAL"},
     {"name":"COMPARATIVE POLITICAL STUDIES"},
     {"name":"BIOCHIMIE"},
     {"name":"PEST MANAGEMENT SCIENCE"},
     {"name":"MECHANISM AND MACHINE THEORY"},
     {"name":"BIOIMPACTS"},
     {"name":"MOBILE DNA"},
     {"name":"ENERGY REPORTS"},
     {"name":"GEOTECHNIQUE"},
     {"name":"FUZZY SETS AND SYSTEMS"},
     {"name":"INTERNATIONAL JOURNAL OF HYPERTHERMIA"},
     {"name":"PLANT SCIENCE"},
     {"name":"MOLECULAR CARCINOGENESIS"},
     {"name":"CHINESE JOURNAL OF CHEMISTRY"},
     {"name":"JOURNAL OF BIOLOGICAL ENGINEERING"},
     {"name":"INTERNATIONAL JOURNAL OF QUALITATIVE METHODS"},
     {"name":"EXPERT REVIEW OF NEUROTHERAPEUTICS"},
     {"name":"GERONTOLOGIST"},
     {"name":"PSYCHIATRY AND CLINICAL NEUROSCIENCES"},
     {"name":"JOURNAL OF GENERAL PHYSIOLOGY"},
     {"name":"JOURNAL OF INTELLIGENT TRANSPORTATION SYSTEMS"},
     {"name":"MECHANICS OF ADVANCED MATERIALS AND STRUCTURES"},
     {"name":"INTERNATIONAL JOURNAL OF GREENHOUSE GAS CONTROL"},
     {"name":"BLOOD TRANSFUSION"},
     {"name":"HUMAN RELATIONS"},
     {"name":"ANNUAL REVIEW OF ANTHROPOLOGY"},
     {"name":"APPLIED MATHEMATICAL MODELLING"},
     {"name":"COGNITION"},
     {"name":"ARCHIVES OF BIOCHEMISTRY AND BIOPHYSICS"},
     {"name":"UNIVERSITY OF CHICAGO LAW REVIEW"},
     {"name":"INTERMETALLICS"},
     {"name":"MINE WATER AND THE ENVIRONMENT"},
     {"name":"BIOPHYSICAL JOURNAL"},
     {"name":"PSYCHOTHERAPY"},
     {"name":"NMR IN BIOMEDICINE"},
     {"name":"STATISTICAL SCIENCE"},
     {"name":"BIOLOGY OF BLOOD AND MARROW TRANSPLANTATION"},
     {"name":"ENDOCRINOLOGY AND METABOLISM CLINICS OF NORTH AMERICA"},
     {"name":"JOURNAL OF BUSINESS AND PSYCHOLOGY"},
     {"name":"FRONTIERS IN MARINE SCIENCE"},
     {"name":"IET CONTROL THEORY AND APPLICATIONS"},
     {"name":"NPJ SYSTEMS BIOLOGY AND APPLICATIONS"},
     {"name":"JOURNAL OF BIOLOGICAL INORGANIC CHEMISTRY"},
     {"name":"JOURNAL OF BUILDING ENGINEERING"},
     {"name":"WORLD JOURNAL OF PSYCHIATRY"},
     {"name":"CHEMNANOMAT"},
     {"name":"JOURNAL OF STEROID BIOCHEMISTRY AND MOLECULAR BIOLOGY"},
     {"name":"JOURNAL OF CELL COMMUNICATION AND SIGNALING"},
     {"name":"ADVANCES IN APPLIED MICROBIOLOGY"},
     {"name":"BRITISH JOURNAL OF OPHTHALMOLOGY"},
     {"name":"VIROLOGICA SINICA"},
     {"name":"ADIPOCYTE"},
     {"name":"LANGUAGE LEARNING"},
     {"name":"SUSTAINABLE ENERGY TECHNOLOGIES AND ASSESSMENTS"},
     {"name":"APPETITE"},
     {"name":"3D PRINTING AND ADDITIVE MANUFACTURING"},
     {"name":"SURGICAL ENDOSCOPY AND OTHER INTERVENTIONAL TECHNIQUES"},
     {"name":"CURRENT RHEUMATOLOGY REPORTS"},
     {"name":"JOURNAL OF GYNECOLOGIC ONCOLOGY"},
     {"name":"BIOLOGY OF SEX DIFFERENCES"},
     {"name":"JOURNAL OF ANALYTICAL TOXICOLOGY"},
     {"name":"HARM REDUCTION JOURNAL"},
     {"name":"WORLD ALLERGY ORGANIZATION JOURNAL"},
     {"name":"NUTRITION METABOLISM AND CARDIOVASCULAR DISEASES"},
     {"name":"JOURNAL OF MIXED METHODS RESEARCH"},
     {"name":"AESTHETIC SURGERY JOURNAL"},
     {"name":"BIOSYSTEMS ENGINEERING"},
     {"name":"MACROMOLECULAR MATERIALS AND ENGINEERING"},
     {"name":"ADVANCES IN THERAPY"},
     {"name":"MINDFULNESS"},
     {"name":"SCIENCE AND TECHNOLOGY OF WELDING AND JOINING"},
     {"name":"VACCINE"},
     {"name":"INTERNATIONAL JOURNAL OF GEOGRAPHICAL INFORMATION SCIENCE"},
     {"name":"ANNALS OF BIOMEDICAL ENGINEERING"},
     {"name":"ECOLOGICAL ENGINEERING"},
     {"name":"JOURNAL OF GENE MEDICINE"},
     {"name":"SKELETAL MUSCLE"},
     {"name":"INTERNATIONAL JOURNAL OF THERMAL SCIENCES"},
     {"name":"EUROPEAN JOURNAL OF CLINICAL NUTRITION"},
     {"name":"MEASUREMENT"},
     {"name":"EARTH SURFACE PROCESSES AND LANDFORMS"},
     {"name":"APPLIED ERGONOMICS"},
     {"name":"INTERNATIONAL JOURNAL OF NURSING STUDIES"},
     {"name":"INTERNATIONAL JOURNAL OF ENERGY RESEARCH"},
     {"name":"GUT AND LIVER"},
     {"name":"AMERICAN JOURNAL OF SOCIOLOGY"},
     {"name":"JOURNAL OF THE MECHANICAL BEHAVIOR OF BIOMEDICAL MATERIALS"},
     {"name":"SUSTAINABLE PRODUCTION AND CONSUMPTION"},
     {"name":"MOLECULAR PHARMACOLOGY"},
     {"name":"JOURNAL OF FLOW CHEMISTRY"},
     {"name":"EPILEPSY CURRENTS"},
     {"name":"IUBMB LIFE"},
     {"name":"COMPUTERIZED MEDICAL IMAGING AND GRAPHICS"},
     {"name":"ADVANCES IN CLINICAL CHEMISTRY"},
     {"name":"ADVANCED ENGINEERING MATERIALS"},
     {"name":"POLITICAL BEHAVIOR"},
     {"name":"CLINICS IN PERINATOLOGY"},
     {"name":"SEMINARS IN NEPHROLOGY"},
     {"name":"CURRENT ONCOLOGY REPORTS"},
     {"name":"GEORGETOWN LAW JOURNAL"},
     {"name":"INTERNATIONAL JOURNAL FOR PARASITOLOGY"},
     {"name":"BRAIN RESEARCH BULLETIN"},
     {"name":"ENVIRONMENTAL GEOCHEMISTRY AND HEALTH"},
     {"name":"ENEURO"},
     {"name":"JOURNAL OF ANTHROPOLOGICAL SCIENCES"},
     {"name":"IMMUNE NETWORK"},
     {"name":"ENVIRONMENTAL TOXICOLOGY AND CHEMISTRY"},
     {"name":"PHARMACEUTICAL RESEARCH"},
     {"name":"THERAPEUTIC ADVANCES IN DRUG SAFETY"},
     {"name":"ANIMAL CONSERVATION"},
     {"name":"EUROPEAN ARCHIVES OF PSYCHIATRY AND CLINICAL NEUROSCIENCE"},
     {"name":"PSYCHOPHARMACOLOGY"},
     {"name":"CURRENT DIABETES REPORTS"},
     {"name":"MICROSCOPY AND MICROANALYSIS"},
     {"name":"SURFACES AND INTERFACES"},
     {"name":"INTEGRATED ENVIRONMENTAL ASSESSMENT AND MANAGEMENT"},
     {"name":"BASIN RESEARCH"},
     {"name":"AMERICAN JOURNAL OF PATHOLOGY"},
     {"name":"ENERGY FOR SUSTAINABLE DEVELOPMENT"},
     {"name":"HORMONES AND BEHAVIOR"},
     {"name":"JOURNAL OF DRUG TARGETING"},
     {"name":"CHEMICAL ENGINEERING SCIENCE"},
     {"name":"BMC PLANT BIOLOGY"},
     {"name":"ESOPHAGUS"},
     {"name":"ENGINEERING FRACTURE MECHANICS"},
     {"name":"CLINICAL CHEMISTRY AND LABORATORY MEDICINE"},
     {"name":"HUMAN RESOURCE MANAGEMENT JOURNAL"},
     {"name":"GEOCHEMISTRY GEOPHYSICS GEOSYSTEMS"},
     {"name":"HEART FAILURE REVIEWS"},
     {"name":"JOURNAL OF CARDIOVASCULAR TRANSLATIONAL RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF IMPACT ENGINEERING"},
     {"name":"JOURNAL OF DAIRY SCIENCE"},
     {"name":"JOURNAL OF ENERGY STORAGE"},
     {"name":"GEOCHEMICAL PERSPECTIVES"},
     {"name":"APPLIED MATHEMATICS LETTERS"},
     {"name":"BIOGEOSCIENCES"},
     {"name":"INTERNATIONAL JOURNAL OF ADVERTISING"},
     {"name":"HEREDITY"},
     {"name":"GPS SOLUTIONS"},
     {"name":"WORK EMPLOYMENT AND SOCIETY"},
     {"name":"PANMINERVA MEDICA"},
     {"name":"ANNALEN DER PHYSIK"},
     {"name":"JOURNAL OF KING SAUD UNIVERSITY SCIENCE"},
     {"name":"JOURNAL OF PETROLOGY"},
     {"name":"FARADAY DISCUSSIONS"},
     {"name":"CANCER BIOMARKERS"},
     {"name":"CHEMICAL GEOLOGY"},
     {"name":"QUARTERLY JOURNAL OF THE ROYAL METEOROLOGICAL SOCIETY"},
     {"name":"EUROPEAN PHYSICAL JOURNAL PLUS"},
     {"name":"JOURNAL OF INTERPERSONAL VIOLENCE"},
     {"name":"GEOTEXTILES AND GEOMEMBRANES"},
     {"name":"ADVANCES IN INSECT PHYSIOLOGY"},
     {"name":"AIDS PATIENT CARE AND STDS"},
     {"name":"JOURNAL OF RHEUMATOLOGY"},
     {"name":"JOURNAL OF SMALL BUSINESS MANAGEMENT"},
     {"name":"ORGANOMETALLICS"},
     {"name":"MEDICAL CARE RESEARCH AND REVIEW"},
     {"name":"ACS EARTH AND SPACE CHEMISTRY"},
     {"name":"INSIGHTS INTO IMAGING"},
     {"name":"YEAST"},
     {"name":"FEMS MICROBIOLOGY ECOLOGY"},
     {"name":"CURRENT POLLUTION REPORTS"},
     {"name":"ADVANCES IN CHRONIC KIDNEY DISEASE"},
     {"name":"COMPUTER PHYSICS COMMUNICATIONS"},
     {"name":"MEDICAL PHYSICS"},
     {"name":"EDUCATIONAL RESEARCHER"},
     {"name":"JOURNAL OF CANCER SURVIVORSHIP"},
     {"name":"OPTICS LETTERS"},
     {"name":"JOURNAL OF DIABETES"},
     {"name":"CANCER CYTOPATHOLOGY"},
     {"name":"SCHIZOPHRENIA RESEARCH"},
     {"name":"JOURNAL OF DIGITAL IMAGING"},
     {"name":"WAVES IN RANDOM AND COMPLEX MEDIA"},
     {"name":"CLINICAL LUNG CANCER"},
     {"name":"JOURNAL OF GASTROENTEROLOGY AND HEPATOLOGY"},
     {"name":"BRAIN SCIENCES"},
     {"name":"JOURNAL OF NEUROSURGICAL ANESTHESIOLOGY"},
     {"name":"CRITICAL STUDIES IN EDUCATION"},
     {"name":"MULTIVARIATE BEHAVIORAL RESEARCH"},
     {"name":"NUCLEAR PHYSICS B"},
     {"name":"SCIENCE AND ENGINEERING ETHICS"},
     {"name":"MARINE ENVIRONMENTAL RESEARCH"},
     {"name":"MARINE RESOURCE ECONOMICS"},
     {"name":"NURSING OUTLOOK"},
     {"name":"CSEE JOURNAL OF POWER AND ENERGY SYSTEMS"},
     {"name":"BIODIVERSITY AND CONSERVATION"},
     {"name":"JOURNAL OF HEALTH ECONOMICS"},
     {"name":"MINERVA MEDICA"},
     {"name":"JOURNAL OF GUIDANCE CONTROL AND DYNAMICS"},
     {"name":"LASERS IN SURGERY AND MEDICINE"},
     {"name":"FORENSIC TOXICOLOGY"},
     {"name":"JOURNAL OF COMPOSITES FOR CONSTRUCTION"},
     {"name":"CHEMPHOTOCHEM"},
     {"name":"ARCHIVES OF PHARMACAL RESEARCH"},
     {"name":"APPLIED OCEAN RESEARCH"},
     {"name":"AEOLIAN RESEARCH"},
     {"name":"FRONTIERS IN MATERIALS"},
     {"name":"MARINE STRUCTURES"},
     {"name":"FOREST ECOSYSTEMS"},
     {"name":"TOPICS IN ORGANOMETALLIC CHEMISTRY"},
     {"name":"MANAGEMENT DECISION"},
     {"name":"METABOLIC BRAIN DISEASE"},
     {"name":"JOURNAL OF SENSORY STUDIES"},
     {"name":"IEEE ELECTRICAL INSULATION MAGAZINE"},
     {"name":"JOURNAL OF FINANCIAL INTERMEDIATION"},
     {"name":"MACHINE LEARNING"},
     {"name":"MOLECULAR IMAGING"},
     {"name":"DEMOGRAPHY"},
     {"name":"EXPRESS POLYMER LETTERS"},
     {"name":"ACM TRANSACTIONS ON INTELLIGENT SYSTEMS AND TECHNOLOGY"},
     {"name":"JOURNAL OF COMPUTATIONAL CHEMISTRY"},
     {"name":"ASIA PACIFIC JOURNAL OF MANAGEMENT"},
     {"name":"HIGH VOLTAGE"},
     {"name":"JOURNAL OF PLANT INTERACTIONS"},
     {"name":"ANTHROPOCENE REVIEW"},
     {"name":"IEEE PHOTONICS JOURNAL"},
     {"name":"CURRENT TOPICS IN MICROBIOLOGY AND IMMUNOLOGY"},
     {"name":"COMPUTER NETWORKS"},
     {"name":"NEUROGASTROENTEROLOGY AND MOTILITY"},
     {"name":"MEDICAL MYCOLOGY"},
     {"name":"JOURNAL OF ENGINEERING EDUCATION"},
     {"name":"MARKETING THEORY"},
     {"name":"SUBSTANCE ABUSE"},
     {"name":"BRITISH JOURNAL OF CRIMINOLOGY"},
     {"name":"DIGITAL SIGNAL PROCESSING"},
     {"name":"JOURNAL OF SEPARATION SCIENCE"},
     {"name":"METABOLOMICS"},
     {"name":"BIOLOGICAL TRACE ELEMENT RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF MULTIPHASE FLOW"},
     {"name":"MEMOIRS OF THE AMERICAN MATHEMATICAL SOCIETY"},
     {"name":"FUNGAL GENETICS AND BIOLOGY"},
     {"name":"ANIMAL GENETICS"},
     {"name":"MECHATRONICS"},
     {"name":"JOURNAL OF ENDOVASCULAR THERAPY"},
     {"name":"JOURNAL OF SUBSTANCE ABUSE TREATMENT"},
     {"name":"SAUDI JOURNAL OF BIOLOGICAL SCIENCES"},
     {"name":"WORLD JOURNAL OF GASTROINTESTINAL ONCOLOGY"},
     {"name":"JOURNAL OF MECHANICAL DESIGN"},
     {"name":"COMMUNICATIONS ON PURE AND APPLIED MATHEMATICS"},
     {"name":"ECOHYDROLOGY"},
     {"name":"ANALYTICAL BIOCHEMISTRY"},
     {"name":"SLEEP MEDICINE"},
     {"name":"BRIEFINGS IN FUNCTIONAL GENOMICS"},
     {"name":"ADVANCES IN BOTANICAL RESEARCH"},
     {"name":"BIOSTATISTICS"},
     {"name":"EXPERT OPINION ON EMERGING DRUGS"},
     {"name":"JOURNAL OF ENVIRONMENTAL ENGINEERING AND LANDSCAPE MANAGEMENT"},
     {"name":"IEEE SIGNAL PROCESSING LETTERS"},
     {"name":"JOURNAL OF VOLCANOLOGY AND GEOTHERMAL RESEARCH"},
     {"name":"JOURNAL OF APPLIED TOXICOLOGY"},
     {"name":"CLINICS AND RESEARCH IN HEPATOLOGY AND GASTROENTEROLOGY"},
     {"name":"JOURNAL OF LOSS PREVENTION IN THE PROCESS INDUSTRIES"},
     {"name":"HEALTH PSYCHOLOGY"},
     {"name":"JOURNAL OF DRUG DELIVERY SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF BIOPHOTONICS"},
     {"name":"VIRUS RESEARCH"},
     {"name":"MOLECULAR PAIN"},
     {"name":"COGNITIVE PSYCHOLOGY"},
     {"name":"CHEMICAL AND BIOLOGICAL TECHNOLOGIES IN AGRICULTURE"},
     {"name":"KIDNEY DISEASES"},
     {"name":"ANTIPODE"},
     {"name":"COMPUTER VISION AND IMAGE UNDERSTANDING"},
     {"name":"ARCHIVES OF DISEASE IN CHILDHOOD"},
     {"name":"JOURNAL OF BEHAVIORAL MEDICINE"},
     {"name":"EGYPTIAN INFORMATICS JOURNAL"},
     {"name":"JOURNAL OF STRUCTURAL GEOLOGY"},
     {"name":"PLASMID"},
     {"name":"JOURNAL OF POSITIVE BEHAVIOR INTERVENTIONS"},
     {"name":"FRONTIERS IN PSYCHIATRY"},
     {"name":"JOURNAL OF FLOOD RISK MANAGEMENT"},
     {"name":"JOURNAL OF PHYSICAL AND CHEMICAL REFERENCE DATA"},
     {"name":"JOURNAL OF ARCHAEOLOGICAL RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF URBAN AND REGIONAL RESEARCH"},
     {"name":"JUSTICE QUARTERLY"},
     {"name":"GENDER WORK AND ORGANIZATION"},
     {"name":"CANCER MANAGEMENT AND RESEARCH"},
     {"name":"JOURNAL OF CRITICAL CARE"},
     {"name":"JOURNAL OF AIR TRANSPORT MANAGEMENT"},
     {"name":"JOURNAL OF PROSTHODONTIC RESEARCH"},
     {"name":"NEUROGENETICS"},
     {"name":"JOURNAL OF WIND ENGINEERING AND INDUSTRIAL AERODYNAMICS"},
     {"name":"MALARIA JOURNAL"},
     {"name":"INTERNATIONAL JOURNAL OF GERIATRIC PSYCHIATRY"},
     {"name":"MATERNAL AND CHILD NUTRITION"},
     {"name":"HEALTH INFORMATICS JOURNAL"},
     {"name":"JOURNAL OF EARTHQUAKE ENGINEERING"},
     {"name":"SOLID STATE IONICS"},
     {"name":"GENE"},
     {"name":"PAEDIATRIC AND PERINATAL EPIDEMIOLOGY"},
     {"name":"EVOLUTION AND HUMAN BEHAVIOR"},
     {"name":"ANIMAL COGNITION"},
     {"name":"INFECTIOUS DISEASES OF POVERTY"},
     {"name":"URBAN GEOGRAPHY"},
     {"name":"INFECTION"},
     {"name":"JOURNAL OF GLOBAL ANTIMICROBIAL RESISTANCE"},
     {"name":"NEONATOLOGY"},
     {"name":"MOLECULAR GENETICS AND GENOMICS"},
     {"name":"ANNALS OF CARDIOTHORACIC SURGERY"},
     {"name":"ENVIRONMENTAL SCIENCE AND POLLUTION RESEARCH"},
     {"name":"COLD REGIONS SCIENCE AND TECHNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF SUSTAINABLE DEVELOPMENT AND WORLD ECOLOGY"},
     {"name":"NANO CONVERGENCE"},
     {"name":"RESPIRATORY MEDICINE"},
     {"name":"CHEMOSENSORS"},
     {"name":"JOURNAL OF PAIN AND SYMPTOM MANAGEMENT"},
     {"name":"IEEE TRANSACTIONS ON COMPUTERS"},
     {"name":"BIODATA MINING"},
     {"name":"PHYSICAL REVIEW A"},
     {"name":"NEUROSCIENCE"},
     {"name":"NANOSCALE AND MICROSCALE THERMOPHYSICAL ENGINEERING"},
     {"name":"PERMAFROST AND PERIGLACIAL PROCESSES"},
     {"name":"JOURNAL OF AGRONOMY AND CROP SCIENCE"},
     {"name":"HUMAN RESOURCES FOR HEALTH"},
     {"name":"ACTA ORTHOPAEDICA"},
     {"name":"ALGAE"},
     {"name":"EPJ QUANTUM TECHNOLOGY"},
     {"name":"NEOBIOTA"},
     {"name":"NATURAL HAZARDS AND EARTH SYSTEM SCIENCES"},
     {"name":"INTERNATIONAL JOURNAL FOR NUMERICAL AND ANALYTICAL METHODS IN GEOMECHANICS"},
     {"name":"BMC NEUROSCIENCE"},
     {"name":"EXPERIMENTAL EYE RESEARCH"},
     {"name":"ANNALS OF CLINICAL MICROBIOLOGY AND ANTIMICROBIALS"},
     {"name":"NEUROIMAGING CLINICS OF NORTH AMERICA"},
     {"name":"INTERNATIONAL JOURNAL OF WEB AND GRID SERVICES"},
     {"name":"JOURNAL OF NURSING SCHOLARSHIP"},
     {"name":"AMERICAN JOURNAL OF HYPERTENSION"},
     {"name":"PROCESSES"},
     {"name":"JOURNAL OF GEOTECHNICAL AND GEOENVIRONMENTAL ENGINEERING"},
     {"name":"IEEE JOURNAL OF OCEANIC ENGINEERING"},
     {"name":"GEOSTANDARDS AND GEOANALYTICAL RESEARCH"},
     {"name":"DIGESTIVE DISEASES AND SCIENCES"},
     {"name":"JOURNAL OF PLANNING EDUCATION AND RESEARCH"},
     {"name":"BIOCHEMICAL AND BIOPHYSICAL RESEARCH COMMUNICATIONS"},
     {"name":"MOLECULAR INFORMATICS"},
     {"name":"COLLOID AND INTERFACE SCIENCE COMMUNICATIONS"},
     {"name":"ADVANCES IN ORGANOMETALLIC CHEMISTRY"},
     {"name":"JOURNAL OF MODERN POWER SYSTEMS AND CLEAN ENERGY"},
     {"name":"LANGUAGE TEACHING RESEARCH"},
     {"name":"CANADIAN FAMILY PHYSICIAN"},
     {"name":"ADVANCES IN WOUND CARE"},
     {"name":"JOURNAL OF SOILS AND SEDIMENTS"},
     {"name":"CURRENT PROBLEMS IN CARDIOLOGY"},
     {"name":"STUDIES IN APPLIED MATHEMATICS"},
     {"name":"JOURNAL OF COMPARATIVE NEUROLOGY"},
     {"name":"IEEE TRANSACTIONS ON ENGINEERING MANAGEMENT"},
     {"name":"TEACHING AND TEACHER EDUCATION"},
     {"name":"IET ELECTRIC POWER APPLICATIONS"},
     {"name":"ELECTRONIC JOURNAL OF BIOTECHNOLOGY"},
     {"name":"METROLOGIA"},
     {"name":"REDOX REPORT"},
     {"name":"AAPG BULLETIN"},
     {"name":"ANIMAL BEHAVIOUR"},
     {"name":"ACADEMIC EMERGENCY MEDICINE"},
     {"name":"CRITICAL PERSPECTIVES ON ACCOUNTING"},
     {"name":"MARINE GEOLOGY"},
     {"name":"MATERIALS TODAY COMMUNICATIONS"},
     {"name":"ORGANIZATIONAL PSYCHOLOGY REVIEW"},
     {"name":"PLOS ONE"},
     {"name":"NEUROMUSCULAR DISORDERS"},
     {"name":"INTERNATIONAL JOURNAL OF BANK MARKETING"},
     {"name":"INFORMATION TECHNOLOGY FOR DEVELOPMENT"},
     {"name":"JOURNAL OF FLUIDS AND STRUCTURES"},
     {"name":"PERSONALITY AND SOCIAL PSYCHOLOGY BULLETIN"},
     {"name":"ANNALS OF LABORATORY MEDICINE"},
     {"name":"BRAIN TOPOGRAPHY"},
     {"name":"BEHAVIOURAL BRAIN RESEARCH"},
     {"name":"PROCEEDINGS OF THE VLDB ENDOWMENT"},
     {"name":"PARASITOLOGY"},
     {"name":"POULTRY SCIENCE"},
     {"name":"JOURNAL OF DEMOCRACY"},
     {"name":"SPORT EDUCATION AND SOCIETY"},
     {"name":"JOURNAL OF SECOND LANGUAGE WRITING"},
     {"name":"MEDICAL TEACHER"},
     {"name":"RAPID PROTOTYPING JOURNAL"},
     {"name":"NEUROBIOLOGY OF LEARNING AND MEMORY"},
     {"name":"STATISTICS AND COMPUTING"},
     {"name":"JOURNAL OF ADVANCED CERAMICS"},
     {"name":"INTERNATIONAL JOURNAL OF CONTROL"},
     {"name":"JOURNAL OF URBAN ECONOMICS"},
     {"name":"ADVANCES IN NONLINEAR ANALYSIS"},
     {"name":"NUCLEUS"},
     {"name":"PPAR RESEARCH"},
     {"name":"RADIATION RESEARCH"},
     {"name":"PEDIATRIC RESEARCH"},
     {"name":"JOURNAL OF PSYCHOSOMATIC RESEARCH"},
     {"name":"CRITICAL SOCIAL POLICY"},
     {"name":"JOURNAL OF THE AMERICAN BOARD OF FAMILY MEDICINE"},
     {"name":"SOCIOLOGY OF SPORT JOURNAL"},
     {"name":"JOURNAL OF MEDICAL SYSTEMS"},
     {"name":"EUROPEAN JOURNAL OF POLITICAL RESEARCH"},
     {"name":"JOURNAL OF TISSUE ENGINEERING AND REGENERATIVE MEDICINE"},
     {"name":"SOIL DYNAMICS AND EARTHQUAKE ENGINEERING"},
     {"name":"VLDB JOURNAL"},
     {"name":"CLINICS IN CHEST MEDICINE"},
     {"name":"INFECTIOUS AGENTS AND CANCER"},
     {"name":"PATHOGENS"},
     {"name":"URBAN STUDIES"},
     {"name":"ADVANCES IN PROTEIN CHEMISTRY AND STRUCTURAL BIOLOGY"},
     {"name":"PLANT FOODS FOR HUMAN NUTRITION"},
     {"name":"CANADIAN GEOTECHNICAL JOURNAL"},
     {"name":"ARCHIVES OF PHYSICAL MEDICINE AND REHABILITATION"},
     {"name":"PERIOPERATIVE MEDICINE"},
     {"name":"OECOLOGIA"},
     {"name":"OPTICAL MATERIALS"},
     {"name":"JOURNAL OF ASSISTED REPRODUCTION AND GENETICS"},
     {"name":"CHILDHOOD OBESITY"},
     {"name":"CANCER CHEMOTHERAPY AND PHARMACOLOGY"},
     {"name":"PEDIATRIC NEUROLOGY"},
     {"name":"FRONTIERS IN ENERGY"},
     {"name":"INTERNATIONAL JOURNAL OF CHRONIC OBSTRUCTIVE PULMONARY DISEASE"},
     {"name":"JOURNAL OF CONFLICT RESOLUTION"},
     {"name":"METACOGNITION AND LEARNING"},
     {"name":"APPLIED ECONOMIC PERSPECTIVES AND POLICY"},
     {"name":"JOURNAL OF CONSTRUCTIONAL STEEL RESEARCH"},
     {"name":"IMAGE AND VISION COMPUTING"},
     {"name":"INFECTION GENETICS AND EVOLUTION"},
     {"name":"INFECTION AND DRUG RESISTANCE"},
     {"name":"THROMBOSIS RESEARCH"},
     {"name":"JOURNAL OF ORTHOPAEDIC RESEARCH"},
     {"name":"ULTRASONOGRAPHY"},
     {"name":"JOURNAL OF BACTERIOLOGY"},
     {"name":"BIOCELL"},
     {"name":"JOURNAL OF VEGETATION SCIENCE"},
     {"name":"TOXICOLOGY IN VITRO"},
     {"name":"INTERNATIONAL STATISTICAL REVIEW"},
     {"name":"INTERNATIONAL TRANSACTIONS IN OPERATIONAL RESEARCH"},
     {"name":"NEUROPSYCHOLOGIA"},
     {"name":"MELANOMA RESEARCH"},
     {"name":"JOURNAL OF CLINICAL PSYCHOPHARMACOLOGY"},
     {"name":"NANO FUTURES"},
     {"name":"CURRENT OPINION IN HEMATOLOGY"},
     {"name":"SOFT COMPUTING"},
     {"name":"CURRENT VASCULAR PHARMACOLOGY"},
     {"name":"PREVENTION SCIENCE"},
     {"name":"INTERNATIONAL WOUND JOURNAL"},
     {"name":"WIND ENERGY"},
     {"name":"DESIGN STUDIES"},
     {"name":"IRRIGATION SCIENCE"},
     {"name":"JOURNAL OF SOLID STATE ELECTROCHEMISTRY"},
     {"name":"EUROPEAN JOURNAL OF DERMATOLOGY"},
     {"name":"INSECT CONSERVATION AND DIVERSITY"},
     {"name":"INTERNATIONAL JOURNAL OF PSYCHOPHYSIOLOGY"},
     {"name":"SEMINARS IN THROMBOSIS AND HEMOSTASIS"},
     {"name":"DIGESTION"},
     {"name":"EPJ DATA SCIENCE"},
     {"name":"ELECTRONIC MARKETS"},
     {"name":"REVIEW OF MANAGERIAL SCIENCE"},
     {"name":"JOURNAL OF SYSTEMATICS AND EVOLUTION"},
     {"name":"CURRENT OPINION IN OPHTHALMOLOGY"},
     {"name":"ANNUAL REVIEW OF RESOURCE ECONOMICS"},
     {"name":"INTERNATIONAL JOURNAL OF CONTROL AUTOMATION AND SYSTEMS"},
     {"name":"ADVANCES IN INORGANIC CHEMISTRY"},
     {"name":"CHINA JOURNAL"},
     {"name":"JOURNAL OF COMPUTING IN CIVIL ENGINEERING"},
     {"name":"SCIENTIFIC STUDIES OF READING"},
     {"name":"CLINICAL INTERVENTIONS IN AGING"},
     {"name":"ACADEMIC PEDIATRICS"},
     {"name":"EUROPEAN REVIEW FOR MEDICAL AND PHARMACOLOGICAL SCIENCES"},
     {"name":"JOURNAL OF SHOULDER AND ELBOW SURGERY"},
     {"name":"THERAPEUTIC ADVANCES IN PSYCHOPHARMACOLOGY"},
     {"name":"JOURNAL OF PEDIATRIC GASTROENTEROLOGY AND NUTRITION"},
     {"name":"HUMAN RESOURCE DEVELOPMENT REVIEW"},
     {"name":"BIODEGRADATION"},
     {"name":"APPLIED DEVELOPMENTAL SCIENCE"},
     {"name":"JOURNAL OF STRENGTH AND CONDITIONING RESEARCH"},
     {"name":"CIRP JOURNAL OF MANUFACTURING SCIENCE AND TECHNOLOGY"},
     {"name":"BMC EVOLUTIONARY BIOLOGY"},
     {"name":"NEUROLOGIC CLINICS"},
     {"name":"EXPERT REVIEW OF RESPIRATORY MEDICINE"},
     {"name":"PHYTOCHEMICAL ANALYSIS"},
     {"name":"JOURNAL OF ATTENTION DISORDERS"},
     {"name":"INTERNATIONAL JOURNAL OF HUMAN RESOURCE MANAGEMENT"},
     {"name":"NEWSLETTERS ON STRATIGRAPHY"},
     {"name":"ADVANCED THEORY AND SIMULATIONS"},
     {"name":"INTERNATIONAL JOURNAL OF DISASTER RISK REDUCTION"},
     {"name":"ECONOMIC JOURNAL"},
     {"name":"NEUROSCIENCE RESEARCH"},
     {"name":"CHINESE MEDICINE"},
     {"name":"CURRENT DRUG METABOLISM"},
     {"name":"PHYSIOLOGICAL GENOMICS"},
     {"name":"MATHEMATICAL PROGRAMMING"},
     {"name":"ACTA ASTRONAUTICA"},
     {"name":"SAUDI PHARMACEUTICAL JOURNAL"},
     {"name":"STUDIES IN FAMILY PLANNING"},
     {"name":"IEEE CIRCUITS AND SYSTEMS MAGAZINE"},
     {"name":"JOURNAL OF ANTIBIOTICS"},
     {"name":"IEEE JOURNAL ON EMERGING AND SELECTED TOPICS IN CIRCUITS AND SYSTEMS"},
     {"name":"HERNIA"},
     {"name":"CLINICAL AND EXPERIMENTAL OPHTHALMOLOGY"},
     {"name":"ORGANIZATION SCIENCE"},
     {"name":"CLINICAL GENITOURINARY CANCER"},
     {"name":"BIOLOGICAL RESEARCH"},
     {"name":"EUROPEAN URBAN AND REGIONAL STUDIES"},
     {"name":"CLINICAL THERAPEUTICS"},
     {"name":"NEUROTOXICITY RESEARCH"},
     {"name":"PEERJ COMPUTER SCIENCE"},
     {"name":"VASCULAR MEDICINE"},
     {"name":"EUROPEAN JOURNAL OF CELL BIOLOGY"},
     {"name":"CHILD MALTREATMENT"},
     {"name":"OPTICAL MATERIALS EXPRESS"},
     {"name":"HEALTH POLICY AND PLANNING"},
     {"name":"JOURNAL OF PHARMACOLOGICAL SCIENCES"},
     {"name":"JOURNAL OF COGNITIVE NEUROSCIENCE"},
     {"name":"RHINOLOGY"},
     {"name":"SOCIAL SCIENCE COMPUTER REVIEW"},
     {"name":"JOURNAL OF CLINICAL VIROLOGY"},
     {"name":"ANDROLOGY"},
     {"name":"EARTH AND ENVIRONMENTAL SCIENCE TRANSACTIONS OF THE ROYAL SOCIETY OF EDINBURGH"},
     {"name":"FRONTIERS IN EARTH SCIENCE"},
     {"name":"REPRODUCTIVE TOXICOLOGY"},
     {"name":"INFECTION CONTROL AND HOSPITAL EPIDEMIOLOGY"},
     {"name":"JOURNAL OF DEVELOPMENT ECONOMICS"},
     {"name":"JOURNAL OF NEUROGASTROENTEROLOGY AND MOTILITY"},
     {"name":"POLICY AND INTERNET"},
     {"name":"GEOSYNTHETICS INTERNATIONAL"},
     {"name":"BMC PULMONARY MEDICINE"},
     {"name":"CURRENT ALZHEIMER RESEARCH"},
     {"name":"ENVIRONMENTAL TOXICOLOGY"},
     {"name":"HARVARD REVIEW OF PSYCHIATRY"},
     {"name":"GEODERMA REGIONAL"},
     {"name":"ASAIO JOURNAL"},
     {"name":"JOURNAL OF ENTERPRISE INFORMATION MANAGEMENT"},
     {"name":"REVISTA MEXICANA DE ASTRONOMIA Y ASTROFISICA"},
     {"name":"JOURNAL OF THE FORMOSAN MEDICAL ASSOCIATION"},
     {"name":"JOURNAL OF RENAL NUTRITION"},
     {"name":"CLINICS IN GERIATRIC MEDICINE"},
     {"name":"REVISTA DE PSICODIDACTICA"},
     {"name":"DUKE LAW JOURNAL"},
     {"name":"ACTA NEUROPSYCHIATRICA"},
     {"name":"JOURNAL OF INTENSIVE CARE"},
     {"name":"INTERNATIONAL JOURNAL OF EDUCATIONAL TECHNOLOGY IN HIGHER EDUCATION"},
     {"name":"TECHNIQUES IN COLOPROCTOLOGY"},
     {"name":"POLYMER JOURNAL"},
     {"name":"STRUCTURAL ENGINEERING AND MECHANICS"},
     {"name":"SHOCK"},
     {"name":"JOURNAL OF CRIMINAL JUSTICE"},
     {"name":"JOURNAL OF POLITICS"},
     {"name":"PLANTA MEDICA"},
     {"name":"JOURNAL OF DIABETES AND ITS COMPLICATIONS"},
     {"name":"DEVELOPMENTAL PSYCHOLOGY"},
     {"name":"MATERIALS SCIENCE IN SEMICONDUCTOR PROCESSING"},
     {"name":"PHOTOCHEMISTRY AND PHOTOBIOLOGY"},
     {"name":"DIABETIC MEDICINE"},
     {"name":"BIOMEDICAL AND ENVIRONMENTAL SCIENCES"},
     {"name":"ROYAL SOCIETY OPEN SCIENCE"},
     {"name":"ONCOLOGY"},
     {"name":"FRONTIERS IN HUMAN NEUROSCIENCE"},
     {"name":"APPLIED NANOSCIENCE"},
     {"name":"WASTE AND BIOMASS VALORIZATION"},
     {"name":"BRITISH JOURNAL OF BIOMEDICAL SCIENCE"},
     {"name":"JOURNAL OF STRUCTURAL BIOLOGY"},
     {"name":"DEVELOPMENTAL BIOLOGY"},
     {"name":"PROTEOME SCIENCE"},
     {"name":"SEXUALLY TRANSMITTED DISEASES"},
     {"name":"MULTIPLE SCLEROSIS AND RELATED DISORDERS"},
     {"name":"INTERNATIONAL JOURNAL OF BIPOLAR DISORDERS"},
     {"name":"EUROPEAN JOURNAL OF ORGANIC CHEMISTRY"},
     {"name":"SCANDINAVIAN JOURNAL OF HOSPITALITY AND TOURISM"},
     {"name":"IEEE JOURNAL OF PHOTOVOLTAICS"},
     {"name":"JOURNAL OF PEACE RESEARCH"},
     {"name":"BMC COMPLEMENTARY AND ALTERNATIVE MEDICINE"},
     {"name":"INTERNATIONAL JOURNAL OF CLINICAL ONCOLOGY"},
     {"name":"JOURNAL OF AUTISM AND DEVELOPMENTAL DISORDERS"},
     {"name":"INTERNATIONAL JOURNAL OF DIGITAL EARTH"},
     {"name":"AMINO ACIDS"},
     {"name":"KOREAN JOURNAL OF INTERNAL MEDICINE"},
     {"name":"FOOD AND ENVIRONMENTAL VIROLOGY"},
     {"name":"TEXAS LAW REVIEW"},
     {"name":"NEUROCRITICAL CARE"},
     {"name":"ENGINEERING FAILURE ANALYSIS"},
     {"name":"FRONTIERS IN ENERGY RESEARCH"},
     {"name":"JOURNAL OF PHARMACEUTICAL SCIENCES"},
     {"name":"IEEE TRANSACTIONS ON CONSUMER ELECTRONICS"},
     {"name":"PEDIATRIC CRITICAL CARE MEDICINE"},
     {"name":"PERVASIVE AND MOBILE COMPUTING"},
     {"name":"GEOPOLITICS"},
     {"name":"NEUROCHEMICAL RESEARCH"},
     {"name":"PARTICUOLOGY"},
     {"name":"STUDIES IN HIGHER EDUCATION"},
     {"name":"TAXON"},
     {"name":"OENO ONE"},
     {"name":"CANADIAN JOURNAL OF FISHERIES AND AQUATIC SCIENCES"},
     {"name":"COMPTES RENDUS PHYSIQUE"},
     {"name":"DISEASE MARKERS"},
     {"name":"CONTRACEPTION"},
     {"name":"CHAOS"},
     {"name":"FEBS LETTERS"},
     {"name":"LEUKOS"},
     {"name":"JOURNAL OF HEREDITY"},
     {"name":"MOLECULAR REPRODUCTION AND DEVELOPMENT"},
     {"name":"BRITISH JOURNAL OF EDUCATIONAL TECHNOLOGY"},
     {"name":"JOURNAL OF CARDIOVASCULAR COMPUTED TOMOGRAPHY"},
     {"name":"ACTA NEUROLOGICA SCANDINAVICA"},
     {"name":"PEDIATRIC DIABETES"},
     {"name":"BIOLOGICAL PSYCHOLOGY"},
     {"name":"JOURNAL OF ASIAN CERAMIC SOCIETIES"},
     {"name":"ADVANCES IN MARINE BIOLOGY"},
     {"name":"AGING CLINICAL AND EXPERIMENTAL RESEARCH"},
     {"name":"JOURNAL OF BONE ONCOLOGY"},
     {"name":"JOURNAL OF RESPONSIBLE INNOVATION"},
     {"name":"DESIGN AUTOMATION FOR EMBEDDED SYSTEMS"},
     {"name":"CONTEMPORARY EDUCATIONAL PSYCHOLOGY"},
     {"name":"ILR REVIEW"},
     {"name":"JOURNAL OF AEROSOL SCIENCE"},
     {"name":"JOURNAL OF SAFETY RESEARCH"},
     {"name":"EUROPEAN JOURNAL OF NEUROSCIENCE"},
     {"name":"JOURNAL OF SURGICAL ONCOLOGY"},
     {"name":"MATERIALS"},
     {"name":"OCEAN ENGINEERING"},
     {"name":"GEOGRAPHICAL JOURNAL"},
     {"name":"JOURNAL OF HOSPITALITY AND TOURISM TECHNOLOGY"},
     {"name":"INVENTIONES MATHEMATICAE"},
     {"name":"CLINICAL AUTONOMIC RESEARCH"},
     {"name":"DRUG DELIVERY AND TRANSLATIONAL RESEARCH"},
     {"name":"EXCLI JOURNAL"},
     {"name":"EUROPEAN PSYCHOLOGIST"},
     {"name":"PHYSICS IN MEDICINE AND BIOLOGY"},
     {"name":"MATERIALS AND MANUFACTURING PROCESSES"},
     {"name":"DRYING TECHNOLOGY"},
     {"name":"HEALTH CARE MANAGEMENT REVIEW"},
     {"name":"INTERNATIONAL JOURNAL OF ADHESION AND ADHESIVES"},
     {"name":"APPLIED GEOCHEMISTRY"},
     {"name":"INTERNATIONAL JOURNAL FOR NUMERICAL METHODS IN ENGINEERING"},
     {"name":"CRYSTENGCOMM"},
     {"name":"QUEST"},
     {"name":"JOURNAL OF THE NEUROLOGICAL SCIENCES"},
     {"name":"JOURNAL OF MOLECULAR NEUROSCIENCE"},
     {"name":"FUTURES"},
     {"name":"TRANSLATIONAL BEHAVIORAL MEDICINE"},
     {"name":"PLASMA PROCESSES AND POLYMERS"},
     {"name":"JOURNAL OF APPLIED MICROBIOLOGY"},
     {"name":"FOOD ANALYTICAL METHODS"},
     {"name":"GLOBAL ENVIRONMENTAL POLITICS"},
     {"name":"AMERICAN JOURNAL OF REPRODUCTIVE IMMUNOLOGY"},
     {"name":"QUATERNARY GEOCHRONOLOGY"},
     {"name":"FRONTIERS IN NEUROLOGY"},
     {"name":"SUPPORTIVE CARE IN CANCER"},
     {"name":"SEMINARS IN PEDIATRIC SURGERY"},
     {"name":"JOURNAL OF PARENTERAL AND ENTERAL NUTRITION"},
     {"name":"BEHAVIORAL ECOLOGY"},
     {"name":"CURRENT DRUG TARGETS"},
     {"name":"INTEGRATIVE AND COMPARATIVE BIOLOGY"},
     {"name":"JOURNAL OF APPLIED PHYCOLOGY"},
     {"name":"SOLID EARTH"},
     {"name":"FRONTIERS IN NEUROINFORMATICS"},
     {"name":"JOURNAL OF ROCK MECHANICS AND GEOTECHNICAL ENGINEERING"},
     {"name":"NEUROTOXICOLOGY"},
     {"name":"PALAEOGEOGRAPHY PALAEOCLIMATOLOGY PALAEOECOLOGY"},
     {"name":"EXPERIMENTAL HEMATOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF FRACTURE"},
     {"name":"CLINICAL EPIDEMIOLOGY"},
     {"name":"AQUACULTURAL ENGINEERING"},
     {"name":"MAMMAL REVIEW"},
     {"name":"PSICOTHEMA"},
     {"name":"SPINE"},
     {"name":"JOURNAL OF PHARMACEUTICAL ANALYSIS"},
     {"name":"CHINESE JOURNAL OF PHYSICS"},
     {"name":"ALDRICHIMICA ACTA"},
     {"name":"COLUMBIA LAW REVIEW"},
     {"name":"PEPTIDES"},
     {"name":"ZOOLOGICAL RESEARCH"},
     {"name":"AMERICAN JOURNAL OF DRUG AND ALCOHOL ABUSE"},
     {"name":"AMERICAN JOURNAL OF AGRICULTURAL ECONOMICS"},
     {"name":"SPORT EXERCISE AND PERFORMANCE PSYCHOLOGY"},
     {"name":"ANNALS OF HEMATOLOGY"},
     {"name":"PHARMACEUTICAL BIOLOGY"},
     {"name":"ENTREPRENEURSHIP AND REGIONAL DEVELOPMENT"},
     {"name":"CANCER CONTROL"},
     {"name":"PESTICIDE BIOCHEMISTRY AND PHYSIOLOGY"},
     {"name":"SEDIMENTARY GEOLOGY"},
     {"name":"BRITISH MEDICAL BULLETIN"},
     {"name":"JOURNAL OF GLACIOLOGY"},
     {"name":"AMERICAN JOURNAL OF ROENTGENOLOGY"},
     {"name":"JOURNAL OF GAMBLING STUDIES"},
     {"name":"JOURNAL OF ARCHAEOLOGICAL SCIENCE"},
     {"name":"DEVELOPMENTAL NEUROSCIENCE"},
     {"name":"VIRCHOWS ARCHIV"},
     {"name":"JOURNAL OF OCCUPATIONAL AND ORGANIZATIONAL PSYCHOLOGY"},
     {"name":"CONTEMPORARY PHYSICS"},
     {"name":"ENERGIES"},
     {"name":"INFORMATION AND SOFTWARE TECHNOLOGY"},
     {"name":"TOURISM REVIEW"},
     {"name":"PHYSICAL REVIEW C"},
     {"name":"JOURNAL OF BIOMOLECULAR NMR"},
     {"name":"IET POWER ELECTRONICS"},
     {"name":"SOCIOLOGICAL THEORY"},
     {"name":"VACUUM"},
     {"name":"JOURNAL OF MAGNETISM AND MAGNETIC MATERIALS"},
     {"name":"VISUAL NEUROSCIENCE"},
     {"name":"PSYCHOTHERAPY RESEARCH"},
     {"name":"IEEE TRANSACTIONS ON NANOBIOSCIENCE"},
     {"name":"REMEDIAL AND SPECIAL EDUCATION"},
     {"name":"IEEE TRANSACTIONS ON HAPTICS"},
     {"name":"JOURNAL OF THE ASSOCIATION FOR INFORMATION SYSTEMS"},
     {"name":"INTERNATIONAL JOURNAL OF APPROXIMATE REASONING"},
     {"name":"COMPUTER COMMUNICATIONS"},
     {"name":"CANCER GENETICS"},
     {"name":"MATERIALS AND STRUCTURES"},
     {"name":"CLINICAL JOURNAL OF PAIN"},
     {"name":"MOLECULAR ORAL MICROBIOLOGY"},
     {"name":"JOURNAL OF ENDODONTICS"},
     {"name":"VIROLOGY"},
     {"name":"PEDIATRIC NEPHROLOGY"},
     {"name":"INSECT SCIENCE"},
     {"name":"JOURNAL OF DERMATOLOGY"},
     {"name":"RESTORATION ECOLOGY"},
     {"name":"GEOHERITAGE"},
     {"name":"REVIEW OF FINANCE"},
     {"name":"JOURNAL OF AEROSOL MEDICINE AND PULMONARY DRUG DELIVERY"},
     {"name":"SURFACE INNOVATIONS"},
     {"name":"DRUG METABOLISM AND PHARMACOKINETICS"},
     {"name":"EUROPEAN JOURNAL OF CLINICAL PHARMACOLOGY"},
     {"name":"AMERICAN FAMILY PHYSICIAN"},
     {"name":"ACM TRANSACTIONS ON INFORMATION SYSTEMS"},
     {"name":"JOURNAL OF RURAL HEALTH"},
     {"name":"QUALITY OF LIFE RESEARCH"},
     {"name":"CLINICAL ORAL INVESTIGATIONS"},
     {"name":"JOURNAL OF PHYSIOLOGICAL SCIENCES"},
     {"name":"THEORETICAL AND APPLIED FRACTURE MECHANICS"},
     {"name":"SIAM JOURNAL ON NUMERICAL ANALYSIS"},
     {"name":"JOURNAL OF SOLID STATE CHEMISTRY"},
     {"name":"TRANSNATIONAL ENVIRONMENTAL LAW"},
     {"name":"PHARMACOGENETICS AND GENOMICS"},
     {"name":"FISHERIES"},
     {"name":"COMPUTATIONAL MATERIALS SCIENCE"},
     {"name":"PROTOPLASMA"},
     {"name":"ARCHIVES OF MEDICAL SCIENCE"},
     {"name":"ACS OMEGA"},
     {"name":"COMPUTER ASSISTED LANGUAGE LEARNING"},
     {"name":"CHEMMEDCHEM"},
     {"name":"INTERNATIONAL JOURNAL OF REMOTE SENSING"},
     {"name":"JOURNAL OF EUROPEAN SOCIAL POLICY"},
     {"name":"INTERNATIONAL JOURNAL OF ENVIRONMENTAL RESEARCH AND PUBLIC HEALTH"},
     {"name":"JOURNAL OF CLINICAL GASTROENTEROLOGY"},
     {"name":"JOURNAL OF OVARIAN RESEARCH"},
     {"name":"IEEE TRANSACTIONS ON ULTRASONICS FERROELECTRICS AND FREQUENCY CONTROL"},
     {"name":"FUNGAL ECOLOGY"},
     {"name":"MICROVASCULAR RESEARCH"},
     {"name":"MARINE CHEMISTRY"},
     {"name":"JOURNAL OF ETHNIC AND MIGRATION STUDIES"},
     {"name":"JOURNAL OF ORTHOPAEDICS AND TRAUMATOLOGY"},
     {"name":"IEEE SENSORS JOURNAL"},
     {"name":"JOURNAL OF NEUROIMMUNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF SUSTAINABLE TRANSPORTATION"},
     {"name":"JOURNAL OF GLOBAL HEALTH"},
     {"name":"ACTA CHIMICA SINICA"},
     {"name":"CHINA ECONOMIC REVIEW"},
     {"name":"RSC ADVANCES"},
     {"name":"POLITICAL GEOGRAPHY"},
     {"name":"AMERICAN JOURNAL OF CARDIOVASCULAR DRUGS"},
     {"name":"INTERNATIONAL PSYCHOGERIATRICS"},
     {"name":"ASIAN JOURNAL OF CONTROL"},
     {"name":"BRITISH JOURNAL OF MANAGEMENT"},
     {"name":"PROCESS BIOCHEMISTRY"},
     {"name":"JOURNAL OF COMPUTATIONAL SCIENCE"},
     {"name":"IEEE TRANSACTIONS ON INFORMATION THEORY"},
     {"name":"TERRA NOVA"},
     {"name":"JOURNAL OF RARE EARTHS"},
     {"name":"ANAEROBE"},
     {"name":"HUMAN PATHOLOGY"},
     {"name":"BMC MICROBIOLOGY"},
     {"name":"JOURNAL OF PLANT GROWTH REGULATION"},
     {"name":"COLORECTAL DISEASE"},
     {"name":"JOURNAL OF THE AMERICAN DENTAL ASSOCIATION"},
     {"name":"JOURNAL OF HEAD TRAUMA REHABILITATION"},
     {"name":"JOURNAL OF DIABETES RESEARCH"},
     {"name":"TRANSFUSION"},
     {"name":"PAEDIATRIC RESPIRATORY REVIEWS"},
     {"name":"JOURNAL OF BIOMEDICAL OPTICS"},
     {"name":"JOURNAL OF FINANCIAL AND QUANTITATIVE ANALYSIS"},
     {"name":"VETERINARY MICROBIOLOGY"},
     {"name":"MYCORRHIZA"},
     {"name":"MEMBRANES"},
     {"name":"PHARMACOEPIDEMIOLOGY AND DRUG SAFETY"},
     {"name":"AGGRESSION AND VIOLENT BEHAVIOR"},
     {"name":"SCIENTOMETRICS"},
     {"name":"BIOLOGICAL CONTROL"},
     {"name":"AMERICAN ETHNOLOGIST"},
     {"name":"ZOOLOGICAL JOURNAL OF THE LINNEAN SOCIETY"},
     {"name":"HEALTH EXPECTATIONS"},
     {"name":"FRONTIERS IN ENVIRONMENTAL SCIENCE"},
     {"name":"ANTONIE VAN LEEUWENHOEK INTERNATIONAL JOURNAL OF GENERAL AND MOLECULAR MICROBIOLOGY"},
     {"name":"ENGINEERING ANALYSIS WITH BOUNDARY ELEMENTS"},
     {"name":"DEFENCE TECHNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF ADVANCED MANUFACTURING TECHNOLOGY"},
     {"name":"CEREBROVASCULAR DISEASES"},
     {"name":"INTERNATIONAL JOURNAL OF FORECASTING"},
     {"name":"IMMUNOBIOLOGY"},
     {"name":"JOURNAL OF MATHEMATICAL PSYCHOLOGY"},
     {"name":"JOURNAL OF PHYSICAL CHEMISTRY B"},
     {"name":"PHARMACOGENOMICS JOURNAL"},
     {"name":"DIAGNOSTICS"},
     {"name":"NEUROSURGICAL REVIEW"},
     {"name":"JOURNAL OF PATIENT SAFETY"},
     {"name":"BRITISH JOURNAL OF SOCIAL PSYCHOLOGY"},
     {"name":"HIGHER EDUCATION"},
     {"name":"BREAST CANCER"},
     {"name":"PHOTODIAGNOSIS AND PHOTODYNAMIC THERAPY"},
     {"name":"MEDICAL ONCOLOGY"},
     {"name":"PARTY POLITICS"},
     {"name":"JOURNAL OF YOUTH AND ADOLESCENCE"},
     {"name":"JOURNAL OF ARCHAEOLOGICAL METHOD AND THEORY"},
     {"name":"FOOD BIOSCIENCE"},
     {"name":"CELL BIOCHEMISTRY AND FUNCTION"},
     {"name":"KNOWLEDGE AND INFORMATION SYSTEMS"},
     {"name":"MANAGEMENT ACCOUNTING RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF MEDICAL MICROBIOLOGY"},
     {"name":"FINITE ELEMENTS IN ANALYSIS AND DESIGN"},
     {"name":"JOURNAL OF PLANT PHYSIOLOGY"},
     {"name":"CLINICAL ONCOLOGY"},
     {"name":"IEEE TRANSACTIONS ON LEARNING TECHNOLOGIES"},
     {"name":"NEURAL PLASTICITY"},
     {"name":"GEOFORUM"},
     {"name":"FUNGAL BIOLOGY"},
     {"name":"CURRENT PAIN AND HEADACHE REPORTS"},
     {"name":"MOLECULAR AND CELLULAR BIOCHEMISTRY"},
     {"name":"PRACTICAL RADIATION ONCOLOGY"},
     {"name":"EJNMMI RESEARCH"},
     {"name":"CURRENT CANCER DRUG TARGETS"},
     {"name":"LIQUID CRYSTALS"},
     {"name":"JOURNAL OF GERIATRIC ONCOLOGY"},
     {"name":"TECTONOPHYSICS"},
     {"name":"FUTURE MICROBIOLOGY"},
     {"name":"JOURNAL OF MANAGEMENT IN ENGINEERING"},
     {"name":"JOURNAL OF SYSTEMATIC PALAEONTOLOGY"},
     {"name":"IMMUNOTHERAPY"},
     {"name":"JOURNAL OF THE AMERICAN PHARMACISTS ASSOCIATION"},
     {"name":"FLUID PHASE EQUILIBRIA"},
     {"name":"PLASMA PHYSICS AND CONTROLLED FUSION"},
     {"name":"WATER RESOURCES MANAGEMENT"},
     {"name":"AMERICAN MINERALOGIST"},
     {"name":"JOURNAL OF THE ASTRONAUTICAL SCIENCES"},
     {"name":"JOURNAL OF PHYSIOLOGY AND PHARMACOLOGY"},
     {"name":"CHEMOMETRICS AND INTELLIGENT LABORATORY SYSTEMS"},
     {"name":"PSYCHOLOGICAL ASSESSMENT"},
     {"name":"JOURNAL OF EDUCATION POLICY"},
     {"name":"INTERNATIONAL JOURNAL OF BIOMETEOROLOGY"},
     {"name":"AMERICAN JOURNAL OF BOTANY"},
     {"name":"ELECTROPHORESIS"},
     {"name":"JOURNAL OF ASIAN EARTH SCIENCES"},
     {"name":"INTERNATIONAL JOURNAL OF MOLECULAR MEDICINE"},
     {"name":"JOURNAL OF VIRUS ERADICATION"},
     {"name":"BRAIN RESEARCH"},
     {"name":"PHYTOCHEMISTRY"},
     {"name":"MOLECULAR IMAGING AND BIOLOGY"},
     {"name":"THERMOCHIMICA ACTA"},
     {"name":"HYPERTENSION RESEARCH"},
     {"name":"LIPIDS IN HEALTH AND DISEASE"},
     {"name":"JOURNAL OF NEUROLOGIC PHYSICAL THERAPY"},
     {"name":"METHODS AND APPLICATIONS IN FLUORESCENCE"},
     {"name":"MEDCHEMCOMM"},
     {"name":"JOURNAL OF INTEGRATIVE ENVIRONMENTAL SCIENCES"},
     {"name":"JOURNAL OF CHEMICAL PHYSICS"},
     {"name":"EFSA JOURNAL"},
     {"name":"MECHANICS OF MATERIALS"},
     {"name":"BULLETIN OF ENGINEERING GEOLOGY AND THE ENVIRONMENT"},
     {"name":"EPIDEMICS"},
     {"name":"PSYCHOLOGY OF ADDICTIVE BEHAVIORS"},
     {"name":"EUROPEAN JOURNAL OF PHYCOLOGY"},
     {"name":"JOURNAL OF SCHOOL PSYCHOLOGY"},
     {"name":"JOURNAL OF CHEMICAL TECHNOLOGY AND BIOTECHNOLOGY"},
     {"name":"JOURNAL OF VASCULAR AND INTERVENTIONAL RADIOLOGY"},
     {"name":"AIR QUALITY ATMOSPHERE AND HEALTH"},
     {"name":"BIOLOGY LETTERS"},
     {"name":"EUROPEAN JOURNAL OF SPORT SCIENCE"},
     {"name":"ACM TRANSACTIONS ON DATABASE SYSTEMS"},
     {"name":"AEROBIOLOGIA"},
     {"name":"BLADDER CANCER"},
     {"name":"EXPERT OPINION ON PHARMACOTHERAPY"},
     {"name":"RADIATION ONCOLOGY"},
     {"name":"CLASSICAL AND QUANTUM GRAVITY"},
     {"name":"INTERNATIONAL MARKETING REVIEW"},
     {"name":"JOURNAL OF EXPERIMENTAL BIOLOGY"},
     {"name":"NEURAL PROCESSING LETTERS"},
     {"name":"JOURNAL OF CEREAL SCIENCE"},
     {"name":"HAEMOPHILIA"},
     {"name":"BMC INFECTIOUS DISEASES"},
     {"name":"CYTOMETRY PART A"},
     {"name":"POLICY AND SOCIETY"},
     {"name":"JOURNAL OF APPLIED PHYSIOLOGY"},
     {"name":"BIOLOGICAL PROCEDURES ONLINE"},
     {"name":"BRITISH JOURNAL OF SOCIOLOGY"},
     {"name":"BIOCHEMISTRY"},
     {"name":"CURRENT ISSUES IN MOLECULAR BIOLOGY"},
     {"name":"MATTER AND RADIATION AT EXTREMES"},
     {"name":"ENVIRONMENTAL MICROBIOLOGY REPORTS"},
     {"name":"JOURNAL OF ANXIETY DISORDERS"},
     {"name":"INTERNATIONAL JOURNAL OF FOOD SCIENCE AND TECHNOLOGY"},
     {"name":"ANNALS OF NUTRITION AND METABOLISM"},
     {"name":"INTERNATIONAL JOURNAL OF INTEGRATED CARE"},
     {"name":"REGULATORY TOXICOLOGY AND PHARMACOLOGY"},
     {"name":"IEEE SPECTRUM"},
     {"name":"JOURNAL OF REFRACTIVE SURGERY"},
     {"name":"PALAEONTOLOGY"},
     {"name":"BIG DATA RESEARCH"},
     {"name":"JOURNAL OF ADDICTION MEDICINE"},
     {"name":"HEMATOLOGICAL ONCOLOGY"},
     {"name":"APPLIED PHYSICS EXPRESS"},
     {"name":"STUDIES IN SECOND LANGUAGE ACQUISITION"},
     {"name":"ULTRASONICS"},
     {"name":"BRITISH JOURNAL OF HEALTH PSYCHOLOGY"},
     {"name":"INTERNATIONAL ARCHIVES OF ALLERGY AND IMMUNOLOGY"},
     {"name":"PANCREAS"},
     {"name":"BAYESIAN ANALYSIS"},
     {"name":"WIRELESS NETWORKS"},
     {"name":"FUTURE ONCOLOGY"},
     {"name":"JOURNAL OF APPLIED CRYSTALLOGRAPHY"},
     {"name":"BMC PSYCHIATRY"},
     {"name":"TOXIN REVIEWS"},
     {"name":"PHARMACOLOGICAL REPORTS"},
     {"name":"IEEE MICROWAVE MAGAZINE"},
     {"name":"BIOSCIENCE REPORTS"},
     {"name":"JOURNAL OF CREATIVE BEHAVIOR"},
     {"name":"JOURNAL OF MONETARY ECONOMICS"},
     {"name":"CORNELL HOSPITALITY QUARTERLY"},
     {"name":"POPULATION AND DEVELOPMENT REVIEW"},
     {"name":"FINANCIAL INNOVATION"},
     {"name":"ECOSPHERE"},
     {"name":"JOURNAL OF PSYCHOPHARMACOLOGY"},
     {"name":"JOURNAL OF RESEARCH IN PERSONALITY"},
     {"name":"COGNITIVE NEUROSCIENCE"},
     {"name":"JOURNAL OF BIOLOGICAL RHYTHMS"},
     {"name":"JOURNAL OF MENTAL HEALTH"},
     {"name":"PALEOCEANOGRAPHY AND PALEOCLIMATOLOGY"},
     {"name":"EUROPEAN JOURNAL OF RADIOLOGY"},
     {"name":"HLA"},
     {"name":"IMMUNOLOGY AND ALLERGY CLINICS OF NORTH AMERICA"},
     {"name":"JOURNAL OF COMPUTATIONAL PHYSICS"},
     {"name":"SCANDINAVIAN JOURNAL OF IMMUNOLOGY"},
     {"name":"SCIENTIA HORTICULTURAE"},
     {"name":"CLINICAL AND EXPERIMENTAL MEDICINE"},
     {"name":"DRUG TESTING AND ANALYSIS"},
     {"name":"PSYCHOLOGY OF SPORT AND EXERCISE"},
     {"name":"THEORETICAL AND APPLIED CLIMATOLOGY"},
     {"name":"HARVARD ENVIRONMENTAL LAW REVIEW"},
     {"name":"GASTROENTEROLOGY REPORT"},
     {"name":"FREE RADICAL RESEARCH"},
     {"name":"STRAHLENTHERAPIE UND ONKOLOGIE"},
     {"name":"JOURNAL OF THERMAL ANALYSIS AND CALORIMETRY"},
     {"name":"JOURNAL OF GEOGRAPHICAL SCIENCES"},
     {"name":"THERAPEUTIC ADVANCES IN RESPIRATORY DISEASE"},
     {"name":"BMC MEDICAL RESEARCH METHODOLOGY"},
     {"name":"JOURNAL OF EVOLUTIONARY BIOLOGY"},
     {"name":"POLICY AND POLITICS"},
     {"name":"INTERNATIONAL JOURNAL OF PAVEMENT ENGINEERING"},
     {"name":"NUTRITION RESEARCH"},
     {"name":"JOURNAL OF MINIMALLY INVASIVE GYNECOLOGY"},
     {"name":"MARKETING SCIENCE"},
     {"name":"IEEE TRANSACTIONS ON COGNITIVE AND DEVELOPMENTAL SYSTEMS"},
     {"name":"JOURNAL OF SPACE WEATHER AND SPACE CLIMATE"},
     {"name":"JOURNAL OF THE GEOLOGICAL SOCIETY"},
     {"name":"MICROBIAL PATHOGENESIS"},
     {"name":"JOURNAL OF IMMUNOTOXICOLOGY"},
     {"name":"MIT SLOAN MANAGEMENT REVIEW"},
     {"name":"JOURNAL OF NEUROPATHOLOGY AND EXPERIMENTAL NEUROLOGY"},
     {"name":"JOURNAL OF PHYSIOLOGY AND BIOCHEMISTRY"},
     {"name":"JOURNAL OF ABNORMAL CHILD PSYCHOLOGY"},
     {"name":"PROSTAGLANDINS LEUKOTRIENES AND ESSENTIAL FATTY ACIDS"},
     {"name":"CYTOKINE"},
     {"name":"INTERNATIONAL JOURNAL OF ORAL SCIENCE"},
     {"name":"CONNECTIVE TISSUE RESEARCH"},
     {"name":"CLINICAL BREAST CANCER"},
     {"name":"ACTIVE LEARNING IN HIGHER EDUCATION"},
     {"name":"GEOSCIENCE DATA JOURNAL"},
     {"name":"JOURNAL OF CLINICAL HYPERTENSION"},
     {"name":"DERMATOLOGY AND THERAPY"},
     {"name":"EMERGING MARKETS REVIEW"},
     {"name":"PETROLEUM EXPLORATION AND DEVELOPMENT"},
     {"name":"THEORETICAL CRIMINOLOGY"},
     {"name":"OCEAN SCIENCE"},
     {"name":"CHEMPLUSCHEM"},
     {"name":"GENERAL HOSPITAL PSYCHIATRY"},
     {"name":"SCANDINAVIAN JOURNAL OF RHEUMATOLOGY"},
     {"name":"BIOCONTROL"},
     {"name":"JOURNAL OF BREATH RESEARCH"},
     {"name":"BMC GERIATRICS"},
     {"name":"EUROPEAN JOURNAL OF REMOTE SENSING"},
     {"name":"JOURNAL OF EATING DISORDERS"},
     {"name":"CURRENT GENOMICS"},
     {"name":"MINERAL PROCESSING AND EXTRACTIVE METALLURGY REVIEW"},
     {"name":"FRONTIERS IN PHYSICS"},
     {"name":"JOURNAL OF CHEMICAL THERMODYNAMICS"},
     {"name":"CANADIAN JOURNAL OF DIABETES"},
     {"name":"CURRENT OPINION IN CRITICAL CARE"},
     {"name":"REVIEW OF PUBLIC PERSONNEL ADMINISTRATION"},
     {"name":"WORK AND OCCUPATIONS"},
     {"name":"INTERNATIONAL JOURNAL OF COMPUTER INTEGRATED MANUFACTURING"},
     {"name":"GLOBAL SPINE JOURNAL"},
     {"name":"BIOLOGICAL INVASIONS"},
     {"name":"IEEE TRANSACTIONS ON ELECTRON DEVICES"},
     {"name":"JOURNAL OF HUMAN GENETICS"},
     {"name":"JOURNAL OF CONSUMER PSYCHOLOGY"},
     {"name":"ANNALS OF STATISTICS"},
     {"name":"WEATHER AND FORECASTING"},
     {"name":"ENVIRONMENTAL HEALTH AND PREVENTIVE MEDICINE"},
     {"name":"JOURNAL OF NEUROENDOCRINOLOGY"},
     {"name":"JOURNAL OF PERIODONTAL RESEARCH"},
     {"name":"KOREAN JOURNAL OF CHEMICAL ENGINEERING"},
     {"name":"FRONTIERS IN PEDIATRICS"},
     {"name":"EUROPEAN JOURNAL OF WORK AND ORGANIZATIONAL PSYCHOLOGY"},
     {"name":"ACTA BIOCHIMICA ET BIOPHYSICA SINICA"},
     {"name":"INTERNATIONAL JOURNAL OF MEDICAL INFORMATICS"},
     {"name":"DIAMOND AND RELATED MATERIALS"},
     {"name":"HYDROGEOLOGY JOURNAL"},
     {"name":"JOURNAL OF CATARACT AND REFRACTIVE SURGERY"},
     {"name":"JOURNAL OF MICROBIOLOGY"},
     {"name":"INTERNATIONAL ORTHOPAEDICS"},
     {"name":"ROBOTICS AND AUTONOMOUS SYSTEMS"},
     {"name":"ACTA OBSTETRICIA ET GYNECOLOGICA SCANDINAVICA"},
     {"name":"PRODUCTION AND OPERATIONS MANAGEMENT"},
     {"name":"QUANTUM INFORMATION PROCESSING"},
     {"name":"ENGLISH FOR SPECIFIC PURPOSES"},
     {"name":"AMERICAN JOURNAL OF CARDIOLOGY"},
     {"name":"REVISTA DE PSIQUIATRIA Y SALUD MENTAL"},
     {"name":"PSYCHIATRIC SERVICES"},
     {"name":"JOURNAL OF THE SCIENCE OF FOOD AND AGRICULTURE"},
     {"name":"ORGANOGENESIS"},
     {"name":"PLANT GROWTH REGULATION"},
     {"name":"ESTUARIES AND COASTS"},
     {"name":"DATA MINING AND KNOWLEDGE DISCOVERY"},
     {"name":"PREHOSPITAL EMERGENCY CARE"},
     {"name":"JOURNAL OF CLINICAL NEUROLOGY"},
     {"name":"EXTREMOPHILES"},
     {"name":"BRAIN TUMOR PATHOLOGY"},
     {"name":"FRONTIERS IN ZOOLOGY"},
     {"name":"HEREDITAS"},
     {"name":"MANAGEMENT AND ORGANIZATION REVIEW"},
     {"name":"FLOW TURBULENCE AND COMBUSTION"},
     {"name":"CHEMBIOCHEM"},
     {"name":"OPTICAL SWITCHING AND NETWORKING"},
     {"name":"INTERNATIONAL JOURNAL OF GEOMECHANICS"},
     {"name":"JOURNAL OF SYSTEMS AND SOFTWARE"},
     {"name":"IMMUNITY INFLAMMATION AND DISEASE"},
     {"name":"GEOCHEMICAL TRANSACTIONS"},
     {"name":"GEBURTSHILFE UND FRAUENHEILKUNDE"},
     {"name":"JOURNAL OF APPLIED METEOROLOGY AND CLIMATOLOGY"},
     {"name":"AMERICAN JOURNAL ON ADDICTIONS"},
     {"name":"GEOGRAPHY COMPASS"},
     {"name":"ECONOMIC SYSTEMS RESEARCH"},
     {"name":"JOURNAL OF AGRICULTURAL ECONOMICS"},
     {"name":"JOURNAL OF CARDIOVASCULAR ELECTROPHYSIOLOGY"},
     {"name":"BMC GENETICS"},
     {"name":"IEEE TRANSACTIONS ON DIELECTRICS AND ELECTRICAL INSULATION"},
     {"name":"BEILSTEIN JOURNAL OF NANOTECHNOLOGY"},
     {"name":"VOX SANGUINIS"},
     {"name":"INTERNATIONAL POLITICAL SOCIOLOGY"},
     {"name":"EUROPEAN JOURNAL OF COMMUNICATION"},
     {"name":"JOURNAL OF NEUROVIROLOGY"},
     {"name":"ALEXANDRIA ENGINEERING JOURNAL"},
     {"name":"REGENERATIVE MEDICINE"},
     {"name":"MOLECULAR OMICS"},
     {"name":"MACROMOLECULAR CHEMISTRY AND PHYSICS"},
     {"name":"JOURNAL OF NEUROIMAGING"},
     {"name":"EXPERT REVIEW OF HEMATOLOGY"},
     {"name":"CARDIOVASCULAR THERAPEUTICS"},
     {"name":"INTERNATIONAL JOURNAL OF APPLIED MECHANICS"},
     {"name":"HUMAN RESOURCE MANAGEMENT"},
     {"name":"DRUG DEVELOPMENT AND INDUSTRIAL PHARMACY"},
     {"name":"JOURNAL OF CONTEMPORARY CHINA"},
     {"name":"GEOMECHANICS AND ENGINEERING"},
     {"name":"HOLOCENE"},
     {"name":"JOURNAL OF NEUROPSYCHOLOGY"},
     {"name":"HYDROBIOLOGIA"},
     {"name":"VEHICLE SYSTEM DYNAMICS"},
     {"name":"CHEMICAL SENSES"},
     {"name":"POLYHEDRON"},
     {"name":"CHINESE JOURNAL OF CHEMICAL ENGINEERING"},
     {"name":"INTERNATIONAL JOURNAL OF NAVAL ARCHITECTURE AND OCEAN ENGINEERING"},
     {"name":"JOHNSON MATTHEY TECHNOLOGY REVIEW"},
     {"name":"SOCIOLOGICAL SCIENCE"},
     {"name":"BIOANALYSIS"},
     {"name":"ATOMIC DATA AND NUCLEAR DATA TABLES"},
     {"name":"ADVANCES IN EXPERIMENTAL MEDICINE AND BIOLOGY"},
     {"name":"AMERICAN MUSEUM NOVITATES"},
     {"name":"BRAIN AND LANGUAGE"},
     {"name":"QUALITATIVE RESEARCH"},
     {"name":"INTERNATIONAL REVIEW OF SOCIAL PSYCHOLOGY"},
     {"name":"CURRENT CARDIOLOGY REPORTS"},
     {"name":"TRANSLATIONAL ANDROLOGY AND UROLOGY"},
     {"name":"FIRE SAFETY JOURNAL"},
     {"name":"ECOTOXICOLOGY"},
     {"name":"ENDANGERED SPECIES RESEARCH"},
     {"name":"PERSPECTIVES IN PLANT ECOLOGY EVOLUTION AND SYSTEMATICS"},
     {"name":"CLINICAL RHEUMATOLOGY"},
     {"name":"PAIN PRACTICE"},
     {"name":"BMC BIOTECHNOLOGY"},
     {"name":"ELECTRONICS"},
     {"name":"GEOPHYSICAL JOURNAL INTERNATIONAL"},
     {"name":"JOURNAL OF TISSUE VIABILITY"},
     {"name":"PLANNING THEORY"},
     {"name":"EUROPEAN JOURNAL OF SOIL BIOLOGY"},
     {"name":"JOURNAL OF GEOLOGY"},
     {"name":"EARTH AND SPACE SCIENCE"},
     {"name":"JOURNAL OF CLINICAL DENSITOMETRY"},
     {"name":"JOURNAL OF GASTROINTESTINAL AND LIVER DISEASES"},
     {"name":"TOPICS IN COGNITIVE SCIENCE"},
     {"name":"PRENATAL DIAGNOSIS"},
     {"name":"TISSUE ENGINEERING AND REGENERATIVE MEDICINE"},
     {"name":"SWARM INTELLIGENCE"},
     {"name":"PATHOGENS AND GLOBAL HEALTH"},
     {"name":"CLINICAL AND EXPERIMENTAL PHARMACOLOGY AND PHYSIOLOGY"},
     {"name":"TRANSACTIONS OF NONFERROUS METALS SOCIETY OF CHINA"},
     {"name":"JOURNAL OF BIOMEDICAL SEMANTICS"},
     {"name":"CHEMISTRYOPEN"},
     {"name":"SECURITY DIALOGUE"},
     {"name":"CURRENT HEMATOLOGIC MALIGNANCY REPORTS"},
     {"name":"JOURNAL OF FOOD SCIENCE"},
     {"name":"INTERNATIONAL BREASTFEEDING JOURNAL"},
     {"name":"CHANNELS"},
     {"name":"JOURNAL OF THE AMERICAN ACADEMY OF ORTHOPAEDIC SURGEONS"},
     {"name":"JOURNAL OF SOCIAL AND PERSONAL RELATIONSHIPS"},
     {"name":"ASSESSING WRITING"},
     {"name":"MARINE ECOLOGY PROGRESS SERIES"},
     {"name":"ASIAN JOURNAL OF ANDROLOGY"},
     {"name":"JOURNAL OF THERMAL BIOLOGY"},
     {"name":"FOTTEA"},
     {"name":"LUPUS"},
     {"name":"AIDS REVIEWS"},
     {"name":"GEOSCIENCE LETTERS"},
     {"name":"CURRENT OPINION IN ANESTHESIOLOGY"},
     {"name":"FIELD METHODS"},
     {"name":"WORK AGING AND RETIREMENT"},
     {"name":"DERMATOLOGIC THERAPY"},
     {"name":"RESEARCH IN SCIENCE EDUCATION"},
     {"name":"JOURNAL OF ELASTICITY"},
     {"name":"FISH PHYSIOLOGY AND BIOCHEMISTRY"},
     {"name":"JOURNAL OF OCCUPATIONAL MEDICINE AND TOXICOLOGY"},
     {"name":"POPULATION AND ENVIRONMENT"},
     {"name":"EXPERIMENTS IN FLUIDS"},
     {"name":"PHARMACOGENOMICS"},
     {"name":"EUROPEAN JOURNAL OF AGEING"},
     {"name":"HUMAN GENE THERAPY METHODS"},
     {"name":"ISLETS"},
     {"name":"INTERNATIONAL GAMBLING STUDIES"},
     {"name":"TUBERCULOSIS"},
     {"name":"MIT TECHNOLOGY REVIEW"},
     {"name":"TECHNOLOGY IN SOCIETY"},
     {"name":"MONOGRAPHS OF THE SOCIETY FOR RESEARCH IN CHILD DEVELOPMENT"},
     {"name":"MICROFLUIDICS AND NANOFLUIDICS"},
     {"name":"LIFE SCIENCE ALLIANCE"},
     {"name":"ESTUARINE COASTAL AND SHELF SCIENCE"},
     {"name":"EUROPEAN ADDICTION RESEARCH"},
     {"name":"MICROBIAL DRUG RESISTANCE"},
     {"name":"EUROPEAN JOURNAL OF SOCIAL THEORY"},
     {"name":"GRANULAR MATTER"},
     {"name":"ECOLOGY AND EVOLUTION"},
     {"name":"JOURNAL OF INFORMATION SECURITY AND APPLICATIONS"},
     {"name":"PEDIATRIC ANESTHESIA"},
     {"name":"DRUG AND ALCOHOL REVIEW"},
     {"name":"REGENERATIVE THERAPY"},
     {"name":"APMIS"},
     {"name":"VIROLOGY JOURNAL"},
     {"name":"WATER"},
     {"name":"NUTRIENT CYCLING IN AGROECOSYSTEMS"},
     {"name":"JOURNAL OF EXPERIMENTAL MARINE BIOLOGY AND ECOLOGY"},
     {"name":"JOURNAL OF GERIATRIC CARDIOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF SOCIAL ROBOTICS"},
     {"name":"ENVIRONMENTAL POLICY AND GOVERNANCE"},
     {"name":"ACTA MATHEMATICA"},
     {"name":"INFORMATION SYSTEMS"},
     {"name":"ADVANCES IN HEALTH SCIENCES EDUCATION"},
     {"name":"MILITARY MEDICAL RESEARCH"},
     {"name":"JOURNAL OF SPORT MANAGEMENT"},
     {"name":"CLINICAL TRIALS"},
     {"name":"SOLID STATE SCIENCES"},
     {"name":"PHYSICS LETTERS A"},
     {"name":"MYCOKEYS"},
     {"name":"JOURNAL FOR NATURE CONSERVATION"},
     {"name":"ZOONOSES AND PUBLIC HEALTH"},
     {"name":"AQUATIC SCIENCES"},
     {"name":"INFORMATION SOCIETY"},
     {"name":"BRAZILIAN JOURNAL OF MICROBIOLOGY"},
     {"name":"WEED SCIENCE"},
     {"name":"JOURNAL OF CONSTRUCTION ENGINEERING AND MANAGEMENT"},
     {"name":"BRAIN AND COGNITION"},
     {"name":"TOPICS IN CATALYSIS"},
     {"name":"EUROPEAN UNION POLITICS"},
     {"name":"REMOTE SENSING LETTERS"},
     {"name":"FRONTIERS IN COMPUTATIONAL NEUROSCIENCE"},
     {"name":"JOURNAL OF NATURAL FIBERS"},
     {"name":"GEOPHYSICS"},
     {"name":"BMC IMMUNOLOGY"},
     {"name":"TOXICOLOGY MECHANISMS AND METHODS"},
     {"name":"JOURNAL OF CONTAMINANT HYDROLOGY"},
     {"name":"JOURNAL OF INTELLIGENT MATERIAL SYSTEMS AND STRUCTURES"},
     {"name":"JOURNAL OF EXPERIMENTAL CRIMINOLOGY"},
     {"name":"ORTHOPAEDIC JOURNAL OF SPORTS MEDICINE"},
     {"name":"PEDIATRIC DRUGS"},
     {"name":"JOURNAL OF BONE AND MINERAL METABOLISM"},
     {"name":"INTERNATIONAL JOURNAL OF HIGH PERFORMANCE COMPUTING APPLICATIONS"},
     {"name":"INGEGNERIA SISMICA"},
     {"name":"BIOPROCESS AND BIOSYSTEMS ENGINEERING"},
     {"name":"INTEGRATIVE CANCER THERAPIES"},
     {"name":"JOURNAL OF THE ASSOCIATION FOR INFORMATION SCIENCE AND TECHNOLOGY"},
     {"name":"EUROPEAN PHYSICAL EDUCATION REVIEW"},
     {"name":"JOURNAL OF SEDIMENTARY RESEARCH"},
     {"name":"PEDIATRIC RHEUMATOLOGY"},
     {"name":"DENDROCHRONOLOGIA"},
     {"name":"BOLETIN DE LA SOCIEDAD ESPANOLA DE CERAMICA Y VIDRIO"},
     {"name":"INTERNATIONAL REVIEW OF FINANCIAL ANALYSIS"},
     {"name":"IET INTELLIGENT TRANSPORT SYSTEMS"},
     {"name":"JOURNAL OF NUCLEAR MATERIALS"},
     {"name":"PHYSICAL REVIEW FLUIDS"},
     {"name":"INSECT MOLECULAR BIOLOGY"},
     {"name":"EXPERIMENTAL NEUROBIOLOGY"},
     {"name":"WORLD MYCOTOXIN JOURNAL"},
     {"name":"LEARNED PUBLISHING"},
     {"name":"SURGICAL CLINICS OF NORTH AMERICA"},
     {"name":"NURSING ETHICS"},
     {"name":"INTERNATIONAL JOURNAL OF ENDOCRINOLOGY"},
     {"name":"JOURNAL OF THE AMERICAN WATER RESOURCES ASSOCIATION"},
     {"name":"IMMUNOGENETICS"},
     {"name":"JOURNAL OF HAPPINESS STUDIES"},
     {"name":"TRANSPORT IN POROUS MEDIA"},
     {"name":"DYSPHAGIA"},
     {"name":"QUALITATIVE HEALTH RESEARCH"},
     {"name":"IEEE JOURNAL OF THE ELECTRON DEVICES SOCIETY"},
     {"name":"PAPERS IN PALAEONTOLOGY"},
     {"name":"HUMAN IMMUNOLOGY"},
     {"name":"VETERINARY QUARTERLY"},
     {"name":"CRYSTALS"},
     {"name":"JOURNAL OF POLYMERS AND THE ENVIRONMENT"},
     {"name":"HEALTH SERVICES RESEARCH"},
     {"name":"CLINICAL PSYCHOPHARMACOLOGY AND NEUROSCIENCE"},
     {"name":"JOURNAL OF STRUCTURAL ENGINEERING"},
     {"name":"INTERNAL AND EMERGENCY MEDICINE"},
     {"name":"ENVIRONMENTAL DEVELOPMENT"},
     {"name":"IONICS"},
     {"name":"LAND"},
     {"name":"ALCOHOL"},
     {"name":"RHEOLOGICA ACTA"},
     {"name":"ALPINE BOTANY"},
     {"name":"JOURNAL OF INFORMATION SCIENCE"},
     {"name":"JOURNAL OF NEURORADIOLOGY"},
     {"name":"FRONTIERS OF MECHANICAL ENGINEERING"},
     {"name":"ALLERGY AND ASTHMA PROCEEDINGS"},
     {"name":"JOURNAL OF ALTERNATIVE AND COMPLEMENTARY MEDICINE"},
     {"name":"FOODBORNE PATHOGENS AND DISEASE"},
     {"name":"APPLIED MATHEMATICS AND OPTIMIZATION"},
     {"name":"IMMUNOPHARMACOLOGY AND IMMUNOTOXICOLOGY"},
     {"name":"GEOSPHERE"},
     {"name":"APPLIED AND COMPUTATIONAL HARMONIC ANALYSIS"},
     {"name":"EXPERIMENTAL MECHANICS"},
     {"name":"CELL STRUCTURE AND FUNCTION"},
     {"name":"LEARNING MEDIA AND TECHNOLOGY"},
     {"name":"JOURNAL OF INTERNATIONAL ECONOMICS"},
     {"name":"JOURNAL OF FORESTRY"},
     {"name":"NEUROLOGIA"},
     {"name":"ARCHIVES OF PHYSIOLOGY AND BIOCHEMISTRY"},
     {"name":"HOUSING STUDIES"},
     {"name":"EUROPEAN JOURNAL OF INNOVATION MANAGEMENT"},
     {"name":"NEUROPSYCHOLOGY"},
     {"name":"CARTOGRAPHY AND GEOGRAPHIC INFORMATION SCIENCE"},
     {"name":"REPRODUCTIVE SCIENCES"},
     {"name":"PSYCHOLOGY OF WOMEN QUARTERLY"},
     {"name":"JOURNAL OF NUTRITION EDUCATION AND BEHAVIOR"},
     {"name":"JOURNAL OF ARTIFICIAL INTELLIGENCE RESEARCH"},
     {"name":"BOREAS"},
     {"name":"JOURNAL OF HYDRODYNAMICS"},
     {"name":"MORAVIAN GEOGRAPHICAL REPORTS"},
     {"name":"INTERNATIONAL JOURNAL OF HEMATOLOGY"},
     {"name":"SOCIAL PROBLEMS"},
     {"name":"PROBABILISTIC ENGINEERING MECHANICS"},
     {"name":"JOURNAL OF THE PERIPHERAL NERVOUS SYSTEM"},
     {"name":"BMC PUBLIC HEALTH"},
     {"name":"EUROPEAN JOURNAL OF HEALTH ECONOMICS"},
     {"name":"SUSTAINABILITY"},
     {"name":"APPLIED VEGETATION SCIENCE"},
     {"name":"TRENDS IN HEARING"},
     {"name":"COMMUNICATIONS IN COMPUTATIONAL PHYSICS"},
     {"name":"INTERNATIONAL JOURNAL OF UROLOGY"},
     {"name":"ADVANCES IN CONCRETE CONSTRUCTION"},
     {"name":"JOURNAL OF THEORETICAL BIOLOGY"},
     {"name":"ENVIRONMENTAL CONSERVATION"},
     {"name":"ARCHIVES OF VIROLOGY"},
     {"name":"SOUTHERN AFRICAN JOURNAL OF HIV MEDICINE"},
     {"name":"JOURNAL OF ENDOUROLOGY"},
     {"name":"MEDIA PSYCHOLOGY"},
     {"name":"FOUNDATIONS OF COMPUTATIONAL MATHEMATICS"},
     {"name":"REVIEW OF ACCOUNTING STUDIES"},
     {"name":"WORLD POLITICS"},
     {"name":"IEEE TRANSACTIONS ON PARALLEL AND DISTRIBUTED SYSTEMS"},
     {"name":"DEMENTIA AND GERIATRIC COGNITIVE DISORDERS"},
     {"name":"EUROPEAN TRANSPORT RESEARCH REVIEW"},
     {"name":"PSYCHOLOGY OF VIOLENCE"},
     {"name":"SCIENCE COMMUNICATION"},
     {"name":"SIAM JOURNAL ON IMAGING SCIENCES"},
     {"name":"JOURNAL OF HUMAN HYPERTENSION"},
     {"name":"ANIMAL"},
     {"name":"FLEXIBLE SERVICES AND MANUFACTURING JOURNAL"},
     {"name":"EUROPEAN FOOD RESEARCH AND TECHNOLOGY"},
     {"name":"RISK MANAGEMENT AND HEALTHCARE POLICY"},
     {"name":"TOXICOLOGY RESEARCH"},
     {"name":"ANNALS OF PHARMACOTHERAPY"},
     {"name":"INTERNATIONAL DAIRY JOURNAL"},
     {"name":"NUCLEAR MEDICINE AND BIOLOGY"},
     {"name":"JOURNAL OF EXPERIMENTAL CHILD PSYCHOLOGY"},
     {"name":"ATMOSPHERE"},
     {"name":"ACADEMIC RADIOLOGY"},
     {"name":"GLOBAL ECOLOGY AND CONSERVATION"},
     {"name":"JOURNAL OF APPLIED PHYSICS"},
     {"name":"REVIEW OF GENERAL PSYCHOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF SOCIAL RESEARCH METHODOLOGY"},
     {"name":"JOURNAL OF BIOCHEMISTRY"},
     {"name":"ADVANCES IN MEDICAL SCIENCES"},
     {"name":"POLYMER INTERNATIONAL"},
     {"name":"UROLITHIASIS"},
     {"name":"EUROPEAN JOURNAL OF PEDIATRICS"},
     {"name":"JOURNAL OF COMPUTING IN HIGHER EDUCATION"},
     {"name":"EXPERIMENTAL AND MOLECULAR PATHOLOGY"},
     {"name":"JOURNAL OF RESEARCH IN CRIME AND DELINQUENCY"},
     {"name":"DIFFERENTIATION"},
     {"name":"JMIR MEDICAL INFORMATICS"},
     {"name":"CONSERVATION PHYSIOLOGY"},
     {"name":"BEHAVIORAL SLEEP MEDICINE"},
     {"name":"INTERNATIONAL REVIEW OF RESEARCH IN OPEN AND DISTRIBUTED LEARNING"},
     {"name":"JOURNAL OF PHONETICS"},
     {"name":"JOURNAL OF ADHESIVE DENTISTRY"},
     {"name":"INTERNATIONAL JOURNAL OF LEAN SIX SIGMA"},
     {"name":"JOURNAL OF CLINICAL BIOCHEMISTRY AND NUTRITION"},
     {"name":"MYCOPATHOLOGIA"},
     {"name":"INTERNATIONAL REVIEW OF NEUROBIOLOGY"},
     {"name":"FOOD BIOPHYSICS"},
     {"name":"DIAGNOSTIC PATHOLOGY"},
     {"name":"AREA"},
     {"name":"JOURNAL OF CIVIL ENGINEERING AND MANAGEMENT"},
     {"name":"EUROPEAN JOURNAL OF FOREST RESEARCH"},
     {"name":"ANNALS OF EPIDEMIOLOGY"},
     {"name":"BULLETIN OF EARTHQUAKE ENGINEERING"},
     {"name":"POLYMER COMPOSITES"},
     {"name":"MAMMALIAN GENOME"},
     {"name":"HORMONE AND METABOLIC RESEARCH"},
     {"name":"IEEE PHOTONICS TECHNOLOGY LETTERS"},
     {"name":"APPLIED BIOCHEMISTRY AND BIOTECHNOLOGY"},
     {"name":"CORPUS LINGUISTICS AND LINGUISTIC THEORY"},
     {"name":"CRYOBIOLOGY"},
     {"name":"EUROPEAN JOURNAL OF MEDICAL GENETICS"},
     {"name":"CLINICA CHIMICA ACTA"},
     {"name":"HEART FAILURE CLINICS"},
     {"name":"REVIEW OF HIGHER EDUCATION"},
     {"name":"JOURNAL OF SUPERCOMPUTING"},
     {"name":"JOURNAL OF ORAL REHABILITATION"},
     {"name":"JOURNAL OF FLUORINE CHEMISTRY"},
     {"name":"BRITISH JOURNAL FOR THE PHILOSOPHY OF SCIENCE"},
     {"name":"MOLECULAR MEMBRANE BIOLOGY"},
     {"name":"POPULATION SPACE AND PLACE"},
     {"name":"NORTHWESTERN UNIVERSITY LAW REVIEW"},
     {"name":"JOURNAL OF MARINE SYSTEMS"},
     {"name":"JOURNAL OF INVESTIGATIVE MEDICINE"},
     {"name":"CLINICAL PROTEOMICS"},
     {"name":"SOCIOLOGY OF RELIGION"},
     {"name":"ARCHIVES OF GYNECOLOGY AND OBSTETRICS"},
     {"name":"APPLIED AND COMPUTATIONAL MATHEMATICS"},
     {"name":"JOURNAL OF ADOLESCENCE"},
     {"name":"THROMBOSIS JOURNAL"},
     {"name":"JOURNAL OF TELEMEDICINE AND TELECARE"},
     {"name":"JOURNAL OF BIOMECHANICS"},
     {"name":"ADVANCES IN ATMOSPHERIC SCIENCES"},
     {"name":"CYBERPSYCHOLOGY BEHAVIOR AND SOCIAL NETWORKING"},
     {"name":"CURRENT UROLOGY REPORTS"},
     {"name":"JOURNAL OF THERMAL STRESSES"},
     {"name":"JOURNAL OF FINANCIAL STABILITY"},
     {"name":"JOURNAL OF GASTROINTESTINAL ONCOLOGY"},
     {"name":"CLINICAL AND TRANSLATIONAL IMAGING"},
     {"name":"NEUROMOLECULAR MEDICINE"},
     {"name":"NEUROPHOTONICS"},
     {"name":"JOURNAL OF CARDIOLOGY"},
     {"name":"ULTRASOUND IN MEDICINE AND BIOLOGY"},
     {"name":"JOURNAL OF APPLIED POLYMER SCIENCE"},
     {"name":"PERSONALITY AND INDIVIDUAL DIFFERENCES"},
     {"name":"JOURNAL OF RESEARCH IN INTERACTIVE MARKETING"},
     {"name":"BIOTECHNOLOGY PROGRESS"},
     {"name":"CLINICS IN DERMATOLOGY"},
     {"name":"ORAL DISEASES"},
     {"name":"ADVANCES IN DIFFERENCE EQUATIONS"},
     {"name":"HIGH POWER LASER SCIENCE AND ENGINEERING"},
     {"name":"NEUROPEPTIDES"},
     {"name":"UPDATES IN SURGERY"},
     {"name":"JOURNAL OF PHARMACOKINETICS AND PHARMACODYNAMICS"},
     {"name":"INTERNATIONAL JOURNAL OF ENGINE RESEARCH"},
     {"name":"CURRENT ZOOLOGY"},
     {"name":"SEX ROLES"},
     {"name":"JOURNAL OF PARALLEL AND DISTRIBUTED COMPUTING"},
     {"name":"CLINICS IN LABORATORY MEDICINE"},
     {"name":"QUATERNARY RESEARCH"},
     {"name":"EYE AND VISION"},
     {"name":"NEUROPSYCHOLOGICAL REHABILITATION"},
     {"name":"BMC MEDICAL ETHICS"},
     {"name":"SIAM JOURNAL ON OPTIMIZATION"},
     {"name":"JOURNAL OF INFECTION AND PUBLIC HEALTH"},
     {"name":"PSYCHOLOGY OF AESTHETICS CREATIVITY AND THE ARTS"},
     {"name":"ANNALS OF OPERATIONS RESEARCH"},
     {"name":"JOURNAL OF CHILD AND ADOLESCENT PSYCHOPHARMACOLOGY"},
     {"name":"NEW YORK UNIVERSITY LAW REVIEW"},
     {"name":"THEORY AND RESEARCH IN SOCIAL EDUCATION"},
     {"name":"JOURNAL OF ETHNOBIOLOGY AND ETHNOMEDICINE"},
     {"name":"JOURNAL OF SYNCHROTRON RADIATION"},
     {"name":"RESEARCH IN SPORTS MEDICINE"},
     {"name":"EDUCACION XX1"},
     {"name":"CLIMACTERIC"},
     {"name":"JOURNAL OF ANTHROPOLOGICAL ARCHAEOLOGY"},
     {"name":"JOURNAL OF PAIN RESEARCH"},
     {"name":"SOLAR PHYSICS"},
     {"name":"EUROPEAN JOURNAL OF PUBLIC HEALTH"},
     {"name":"NETWORKS"},
     {"name":"CHINESE PHYSICS C"},
     {"name":"SOIL SCIENCE SOCIETY OF AMERICA JOURNAL"},
     {"name":"DIGESTIVE DISEASES"},
     {"name":"ANXIETY STRESS AND COPING"},
     {"name":"JOURNAL OF MAPS"},
     {"name":"ENTROPY"},
     {"name":"RURAL SOCIOLOGY"},
     {"name":"JOURNAL OF SOCIAL POLICY"},
     {"name":"SEMICONDUCTOR SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF LITERACY RESEARCH"},
     {"name":"ANIMALS"},
     {"name":"BIOCHIP JOURNAL"},
     {"name":"COGNITION AND INSTRUCTION"},
     {"name":"ENVIRONMENTAL MANAGEMENT"},
     {"name":"JOURNAL OF BIOSCIENCE AND BIOENGINEERING"},
     {"name":"CHINESE SOCIOLOGICAL REVIEW"},
     {"name":"ECONOMIC AND INDUSTRIAL DEMOCRACY"},
     {"name":"VIRGINIA LAW REVIEW"},
     {"name":"NURSE EDUCATION TODAY"},
     {"name":"FORESTRY"},
     {"name":"MYRMECOLOGICAL NEWS"},
     {"name":"ISRAEL JOURNAL OF CHEMISTRY"},
     {"name":"BMC MEDICAL INFORMATICS AND DECISION MAKING"},
     {"name":"MATHEMATICAL GEOSCIENCES"},
     {"name":"TRANSPORTATION GEOTECHNICS"},
     {"name":"BUILDING SIMULATION"},
     {"name":"RESEARCH EVALUATION"},
     {"name":"CATALYSIS LETTERS"},
     {"name":"JOURNAL OF CAREER ASSESSMENT"},
     {"name":"BLOOD CELLS MOLECULES AND DISEASES"},
     {"name":"BIOFOULING"},
     {"name":"JOURNAL OF STUDIES ON ALCOHOL AND DRUGS"},
     {"name":"JOURNAL OF MARKETING FOR HIGHER EDUCATION"},
     {"name":"HUMAN AND ECOLOGICAL RISK ASSESSMENT"},
     {"name":"INTERNATIONAL JOURNAL OF COMPUTER ASSISTED RADIOLOGY AND SURGERY"},
     {"name":"ENVIRONMENT"},
     {"name":"INFECTIOUS DISEASES"},
     {"name":"ECOLOGICAL INFORMATICS"},
     {"name":"COATINGS"},
     {"name":"OPERATIONS RESEARCH"},
     {"name":"PROJECT MANAGEMENT JOURNAL"},
     {"name":"IMA JOURNAL OF NUMERICAL ANALYSIS"},
     {"name":"ENVIRONMENTAL EDUCATION RESEARCH"},
     {"name":"CROATIAN JOURNAL OF FOREST ENGINEERING"},
     {"name":"INTERNATIONAL JOURNAL OF TOURISM RESEARCH"},
     {"name":"NANO COMMUNICATION NETWORKS"},
     {"name":"MICROMACHINES"},
     {"name":"CURRENT OPINION IN ORGAN TRANSPLANTATION"},
     {"name":"ARCHIVES OF ENVIRONMENTAL CONTAMINATION AND TOXICOLOGY"},
     {"name":"NUTRITION IN CLINICAL PRACTICE"},
     {"name":"JOURNAL OF LIPOSOME RESEARCH"},
     {"name":"PAIN MEDICINE"},
     {"name":"COMMUNICATIONS IN NUMBER THEORY AND PHYSICS"},
     {"name":"SOCIOLOGIA RURALIS"},
     {"name":"RESTORATIVE NEUROLOGY AND NEUROSCIENCE"},
     {"name":"AMERICAN JOURNAL OF INTERNATIONAL LAW"},
     {"name":"ARCHIVES OF DERMATOLOGICAL RESEARCH"},
     {"name":"EXPERIMENTAL PHYSIOLOGY"},
     {"name":"AGRICULTURE AND HUMAN VALUES"},
     {"name":"PATIENT EDUCATION AND COUNSELING"},
     {"name":"INTERNATIONAL JOURNAL OF SEDIMENT RESEARCH"},
     {"name":"NEURODEGENERATIVE DISEASES"},
     {"name":"URBAN ECOSYSTEMS"},
     {"name":"FOOD SCIENCE AND HUMAN WELLNESS"},
     {"name":"BIOMED RESEARCH INTERNATIONAL"},
     {"name":"ADVANCES IN ASTRONOMY"},
     {"name":"JOURNALISM STUDIES"},
     {"name":"ARCHIVES OF CARDIOVASCULAR DISEASES"},
     {"name":"KONA POWDER AND PARTICLE JOURNAL"},
     {"name":"EUROPEAN REVIEW OF AGRICULTURAL ECONOMICS"},
     {"name":"ECOSYSTEM HEALTH AND SUSTAINABILITY"},
     {"name":"EUROPEAN JOURNAL OF GENERAL PRACTICE"},
     {"name":"SERVICE INDUSTRIES JOURNAL"},
     {"name":"FRONTIERS IN NEUROROBOTICS"},
     {"name":"BIOMECHANICS AND MODELING IN MECHANOBIOLOGY"},
     {"name":"MECHANICS BASED DESIGN OF STRUCTURES AND MACHINES"},
     {"name":"JOURNAL OF GASTROINTESTINAL SURGERY"},
     {"name":"JOURNAL OF METEOROLOGICAL RESEARCH"},
     {"name":"COMPREHENSIVE PSYCHIATRY"},
     {"name":"BIOMETALS"},
     {"name":"RESEARCH ON CHEMICAL INTERMEDIATES"},
     {"name":"GEOLOGICAL MAGAZINE"},
     {"name":"EUROPEAN JOURNAL OF CARDIOVASCULAR NURSING"},
     {"name":"FRONTIERS IN ECOLOGY AND EVOLUTION"},
     {"name":"PHYSICAL REVIEW E"},
     {"name":"MEDICAL DECISION MAKING"},
     {"name":"TRIBOLOGY LETTERS"},
     {"name":"BMC GASTROENTEROLOGY"},
     {"name":"GENERAL AND COMPARATIVE ENDOCRINOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF ENVIRONMENTAL SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF THE AMERICAN COLLEGE OF NUTRITION"},
     {"name":"IMMUNOLOGIC RESEARCH"},
     {"name":"BRITISH JOURNAL OF EDUCATIONAL PSYCHOLOGY"},
     {"name":"BIOMASS CONVERSION AND BIOREFINERY"},
     {"name":"JOURNAL OF SPORTS SCIENCES"},
     {"name":"JOURNAL OF MICROENCAPSULATION"},
     {"name":"JOURNAL OF MOLECULAR STRUCTURE"},
     {"name":"AMB EXPRESS"},
     {"name":"AMERICAN JOURNAL OF PHYSICAL ANTHROPOLOGY"},
     {"name":"MATHEMATICAL FINANCE"},
     {"name":"APPLIED ACOUSTICS"},
     {"name":"PHYSICS AND CHEMISTRY OF THE EARTH"},
     {"name":"JOURNAL OF PLANNING LITERATURE"},
     {"name":"ROAD MATERIALS AND PAVEMENT DESIGN"},
     {"name":"GRAEFES ARCHIVE FOR CLINICAL AND EXPERIMENTAL OPHTHALMOLOGY"},
     {"name":"EUROPEAN POLITICAL SCIENCE REVIEW"},
     {"name":"INTERNATIONAL JOURNAL OF SIMULATION MODELLING"},
     {"name":"JOURNAL OF SYSTEMS ARCHITECTURE"},
     {"name":"CAREER DEVELOPMENT INTERNATIONAL"},
     {"name":"JOURNAL OF COMMODITY MARKETS"},
     {"name":"CRITICAL PUBLIC HEALTH"},
     {"name":"VADOSE ZONE JOURNAL"},
     {"name":"PHYCOLOGIA"},
     {"name":"ACTA TROPICA"},
     {"name":"JOURNAL OF BREAST CANCER"},
     {"name":"JOURNAL OF PHARMACY AND PHARMACOLOGY"},
     {"name":"PEERJ"},
     {"name":"TEACHERS AND TEACHING"},
     {"name":"JOURNAL OF MAGNETIC RESONANCE"},
     {"name":"EUROPEAN JOURNAL OF SOCIAL PSYCHOLOGY"},
     {"name":"DRUG AND CHEMICAL TOXICOLOGY"},
     {"name":"JOURNAL OF MOLECULAR HISTOLOGY"},
     {"name":"JOURNAL OF SOCIAL ISSUES"},
     {"name":"JOURNAL OF ATHLETIC TRAINING"},
     {"name":"CANCER MICROENVIRONMENT"},
     {"name":"JOURNAL OF APPLIED ELECTROCHEMISTRY"},
     {"name":"INTERNATIONAL JOURNAL OF SPORTS MEDICINE"},
     {"name":"BMC NEUROLOGY"},
     {"name":"JOURNAL OF PLASTIC RECONSTRUCTIVE AND AESTHETIC SURGERY"},
     {"name":"SEXUALITY RESEARCH AND SOCIAL POLICY"},
     {"name":"INTERNATIONAL JOURNAL OF MANAGEMENT EDUCATION"},
     {"name":"AQUACULTURE REPORTS"},
     {"name":"THORACIC CANCER"},
     {"name":"PERSONALIZED MEDICINE"},
     {"name":"ULTRAMICROSCOPY"},
     {"name":"BIOCYBERNETICS AND BIOMEDICAL ENGINEERING"},
     {"name":"JOURNAL OF ADHESION"},
     {"name":"VITAMINS AND HORMONES"},
     {"name":"EUROPEAN JOURNAL OF CANCER PREVENTION"},
     {"name":"WOMEN AND BIRTH"},
     {"name":"LIFE SCIENCES IN SPACE RESEARCH"},
     {"name":"COUNSELING PSYCHOLOGIST"},
     {"name":"JOURNAL OF CARDIOTHORACIC AND VASCULAR ANESTHESIA"},
     {"name":"POSTGRADUATE MEDICINE"},
     {"name":"JOURNAL OF INSECT PHYSIOLOGY"},
     {"name":"INORGANICA CHIMICA ACTA"},
     {"name":"REVIEW OF INTERNATIONAL POLITICAL ECONOMY"},
     {"name":"ARCHIVES OF SUICIDE RESEARCH"},
     {"name":"CLINICAL DRUG INVESTIGATION"},
     {"name":"DIAGNOSTIC AND INTERVENTIONAL IMAGING"},
     {"name":"CARDIOVASCULAR DIAGNOSIS AND THERAPY"},
     {"name":"JOURNAL OF OPTICS"},
     {"name":"EUROPEAN JOURNAL OF PHYSICAL AND REHABILITATION MEDICINE"},
     {"name":"AGRICULTURAL ECONOMICS"},
     {"name":"AVIAN CONSERVATION AND ECOLOGY"},
     {"name":"NEUROLOGICAL RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL FOR EQUITY IN HEALTH"},
     {"name":"EUROPEAN JOURNAL OF PROTISTOLOGY"},
     {"name":"PHOTOSYNTHETICA"},
     {"name":"PROGRESS IN PLANNING"},
     {"name":"INTERNATIONAL JOURNAL OF INTERACTIVE MULTIMEDIA AND ARTIFICIAL INTELLIGENCE"},
     {"name":"ORGANIZATIONAL BEHAVIOR AND HUMAN DECISION PROCESSES"},
     {"name":"WOUND REPAIR AND REGENERATION"},
     {"name":"FRONTIERS IN VETERINARY SCIENCE"},
     {"name":"EQUINE VETERINARY JOURNAL"},
     {"name":"FUNCTIONAL PLANT BIOLOGY"},
     {"name":"STRUCTURE AND INFRASTRUCTURE ENGINEERING"},
     {"name":"INTERNATIONAL JOURNAL OF MENTAL HEALTH NURSING"},
     {"name":"CURRENT RESEARCH IN TRANSLATIONAL MEDICINE"},
     {"name":"SYNAPSE"},
     {"name":"JOURNAL OF PERSONALITY DISORDERS"},
     {"name":"CULTURAL GEOGRAPHIES"},
     {"name":"JOURNAL OF MATERIALS RESEARCH"},
     {"name":"JOURNAL OF PROSTHETIC DENTISTRY"},
     {"name":"ASTROPARTICLE PHYSICS"},
     {"name":"STATISTICAL METHODS IN MEDICAL RESEARCH"},
     {"name":"WOMENS HEALTH ISSUES"},
     {"name":"CRYPTOGAMIE MYCOLOGIE"},
     {"name":"JOURNAL OF FISH DISEASES"},
     {"name":"JOURNAL OF WORLD PREHISTORY"},
     {"name":"ANIMAL FEED SCIENCE AND TECHNOLOGY"},
     {"name":"FITOTERAPIA"},
     {"name":"ANTITRUST LAW JOURNAL"},
     {"name":"BIOCHEMISTRY AND CELL BIOLOGY"},
     {"name":"GLOBALIZATION AND HEALTH"},
     {"name":"AVIAN PATHOLOGY"},
     {"name":"NEUROLOGICAL SCIENCES"},
     {"name":"JOURNAL OF ADVANCED NURSING"},
     {"name":"UCLA LAW REVIEW"},
     {"name":"ABDOMINAL RADIOLOGY"},
     {"name":"MARINE GEOPHYSICAL RESEARCH"},
     {"name":"ARTIFICIAL ORGANS"},
     {"name":"CELL BIOLOGY INTERNATIONAL"},
     {"name":"AMERICAN JOURNAL OF INFECTION CONTROL"},
     {"name":"PEDIATRIC PULMONOLOGY"},
     {"name":"BIOMARKERS IN MEDICINE"},
     {"name":"BIOELECTROMAGNETICS"},
     {"name":"EXPERIMENTAL HEAT TRANSFER"},
     {"name":"EUROPEAN JOURNAL OF TEACHER EDUCATION"},
     {"name":"JOURNAL OF PHYSICAL CHEMISTRY A"},
     {"name":"ADMINISTRATION AND POLICY IN MENTAL HEALTH AND MENTAL HEALTH SERVICES RESEARCH"},
     {"name":"KOREAN CIRCULATION JOURNAL"},
     {"name":"CARDIOVASCULAR TOXICOLOGY"},
     {"name":"OPHTHALMIC AND PHYSIOLOGICAL OPTICS"},
     {"name":"CURRENT ONCOLOGY"},
     {"name":"CURRENT OPINION IN PULMONARY MEDICINE"},
     {"name":"GOVERNMENT AND OPPOSITION"},
     {"name":"EDUCATION FINANCE AND POLICY"},
     {"name":"JOURNAL OF BIOENERGETICS AND BIOMEMBRANES"},
     {"name":"JOURNAL OF CARDIOVASCULAR PHARMACOLOGY AND THERAPEUTICS"},
     {"name":"ANNALS OF NUCLEAR MEDICINE"},
     {"name":"JOURNAL OF ECT"},
     {"name":"NEURORADIOLOGY"},
     {"name":"SEMINARS IN DIAGNOSTIC PATHOLOGY"},
     {"name":"TETRAHEDRON LETTERS"},
     {"name":"HEALTH ECONOMICS"},
     {"name":"ANGIOLOGY"},
     {"name":"EARLY CHILDHOOD RESEARCH QUARTERLY"},
     {"name":"JOURNAL OF CORPORATE FINANCE"},
     {"name":"ZOOLOGICA SCRIPTA"},
     {"name":"INNATE IMMUNITY"},
     {"name":"ANNUAL REVIEW OF LAW AND SOCIAL SCIENCE"},
     {"name":"INTERNATIONAL JOURNAL OF BIFURCATION AND CHAOS"},
     {"name":"INTERNATIONAL JOURNAL OF AGRICULTURAL SUSTAINABILITY"},
     {"name":"ECOLOGICAL MODELLING"},
     {"name":"CLIMATE AND DEVELOPMENT"},
     {"name":"HELVETICA CHIMICA ACTA"},
     {"name":"AMERICAN JOURNAL OF PHARMACEUTICAL EDUCATION"},
     {"name":"ACM TRANSACTIONS ON SENSOR NETWORKS"},
     {"name":"CURRENT APPLIED PHYSICS"},
     {"name":"NEUROSCIENCE LETTERS"},
     {"name":"BULLETIN OF THE SEISMOLOGICAL SOCIETY OF AMERICA"},
     {"name":"DIAGNOSTIC MICROBIOLOGY AND INFECTIOUS DISEASE"},
     {"name":"ADVANCES IN THEORETICAL AND MATHEMATICAL PHYSICS"},
     {"name":"STRESS AND HEALTH"},
     {"name":"IEEE SOFTWARE"},
     {"name":"ASIAN JOURNAL OF PSYCHIATRY"},
     {"name":"ENERGY JOURNAL"},
     {"name":"CURRENT GENE THERAPY"},
     {"name":"CLINICAL CARDIOLOGY"},
     {"name":"EMERGENCY MEDICINE JOURNAL"},
     {"name":"ORTHOPEDIC CLINICS OF NORTH AMERICA"},
     {"name":"JOURNAL OF THERMAL SPRAY TECHNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF PUBLIC HEALTH"},
     {"name":"STOCHASTIC ENVIRONMENTAL RESEARCH AND RISK ASSESSMENT"},
     {"name":"BMC PREGNANCY AND CHILDBIRTH"},
     {"name":"JOURNAL OF NURSING MANAGEMENT"},
     {"name":"JOURNAL OF QUATERNARY SCIENCE"},
     {"name":"BULLETIN OF THE AMERICAN MUSEUM OF NATURAL HISTORY"},
     {"name":"VEGETATION HISTORY AND ARCHAEOBOTANY"},
     {"name":"IEEE MICROWAVE AND WIRELESS COMPONENTS LETTERS"},
     {"name":"ENDOCRINE CONNECTIONS"},
     {"name":"MECHANICS RESEARCH COMMUNICATIONS"},
     {"name":"JOURNAL OF PEDIATRIC PSYCHOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF ELECTRONIC COMMERCE"},
     {"name":"EUROPEAN JOURNAL OF APPLIED PHYSIOLOGY"},
     {"name":"JOURNAL OF CLINICAL PATHOLOGY"},
     {"name":"JOURNAL OF DEVELOPMENTAL ORIGINS OF HEALTH AND DISEASE"},
     {"name":"INTELLIGENCE"},
     {"name":"SOCIAL NEUROSCIENCE"},
     {"name":"JOURNAL OF CHEMICAL AND ENGINEERING DATA"},
     {"name":"COMPLEXITY"},
     {"name":"COMPARATIVE EDUCATION REVIEW"},
     {"name":"JOURNAL OF HEALTH AND SOCIAL BEHAVIOR"},
     {"name":"JOURNAL OF VENOMOUS ANIMALS AND TOXINS INCLUDING TROPICAL DISEASES"},
     {"name":"ANNALS OF GLACIOLOGY"},
     {"name":"PROTEOMICS CLINICAL APPLICATIONS"},
     {"name":"INTERNATIONAL JOURNAL OF GENOMICS"},
     {"name":"BASIC AND CLINICAL ANDROLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF METHODS IN PSYCHIATRIC RESEARCH"},
     {"name":"EUROPEAN SURGICAL RESEARCH"},
     {"name":"HEALTH RESEARCH POLICY AND SYSTEMS"},
     {"name":"TRANSLATIONAL PEDIATRICS"},
     {"name":"INTERNATIONAL JOURNAL OF RADIATION BIOLOGY"},
     {"name":"CURRENT OPINION IN NEPHROLOGY AND HYPERTENSION"},
     {"name":"CLINICAL REHABILITATION"},
     {"name":"COMPUTATIONAL INTELLIGENCE AND NEUROSCIENCE"},
     {"name":"AEROSOL SCIENCE AND TECHNOLOGY"},
     {"name":"BMJ OPEN"},
     {"name":"SOUTH EUROPEAN SOCIETY AND POLITICS"},
     {"name":"ARCHIVES OF WOMENS MENTAL HEALTH"},
     {"name":"BOSTON UNIVERSITY LAW REVIEW"},
     {"name":"INTERNATIONAL REVIEW OF PSYCHIATRY"},
     {"name":"HEALTH AND QUALITY OF LIFE OUTCOMES"},
     {"name":"LASERS IN MEDICAL SCIENCE"},
     {"name":"DISEASES OF THE ESOPHAGUS"},
     {"name":"WORLD JOURNAL OF MENS HEALTH"},
     {"name":"JOURNAL OF MAMMALIAN EVOLUTION"},
     {"name":"ANNALS OF PROBABILITY"},
     {"name":"JOURNAL OF WATER RESOURCES PLANNING AND MANAGEMENT"},
     {"name":"MULTIMEDIA TOOLS AND APPLICATIONS"},
     {"name":"IRANIAN JOURNAL OF FUZZY SYSTEMS"},
     {"name":"NEURAL COMPUTATION"},
     {"name":"JOURNAL OF HEALTH PSYCHOLOGY"},
     {"name":"REVIEWS ON ENVIRONMENTAL HEALTH"},
     {"name":"JOURNAL OF CULTURAL HERITAGE"},
     {"name":"APPLIED HEALTH ECONOMICS AND HEALTH POLICY"},
     {"name":"MINERVA ANESTESIOLOGICA"},
     {"name":"CHILD NEUROPSYCHOLOGY"},
     {"name":"BRITISH JOURNAL OF CLINICAL PSYCHOLOGY"},
     {"name":"JOURNAL OF VISUAL COMMUNICATION AND IMAGE REPRESENTATION"},
     {"name":"MEDICINA INTENSIVA"},
     {"name":"JOURNAL OF PHARMACOLOGICAL AND TOXICOLOGICAL METHODS"},
     {"name":"JOURNAL OF POLYMER RESEARCH"},
     {"name":"EARLY INTERVENTION IN PSYCHIATRY"},
     {"name":"SOCIAL NETWORKS"},
     {"name":"JOURNAL OF PALLIATIVE MEDICINE"},
     {"name":"COMPUTATIONAL LINGUISTICS"},
     {"name":"CHRONOBIOLOGY INTERNATIONAL"},
     {"name":"BULLETIN OF MATHEMATICAL SCIENCES"},
     {"name":"JOURNAL OF NETWORK AND SYSTEMS MANAGEMENT"},
     {"name":"MEDICINAL CHEMISTRY"},
     {"name":"JOURNAL OF ACCOUNTING AND PUBLIC POLICY"},
     {"name":"JOURNAL OF THE INTERNATIONAL NEUROPSYCHOLOGICAL SOCIETY"},
     {"name":"COGNITIVE THERAPY AND RESEARCH"},
     {"name":"COMPOSITE INTERFACES"},
     {"name":"BEHAVIORAL ECOLOGY AND SOCIOBIOLOGY"},
     {"name":"VETERINARY RECORD"},
     {"name":"INTERNATIONAL JOURNAL OF MEDICAL SCIENCES"},
     {"name":"DERMATOLOGIC SURGERY"},
     {"name":"INTEGRATIVE BIOLOGY"},
     {"name":"EUROPEAN JOURNAL OF INFORMATION SYSTEMS"},
     {"name":"ANALYTICAL METHODS"},
     {"name":"ARCHIVE FOR RATIONAL MECHANICS AND ANALYSIS"},
     {"name":"FRONTIERS IN BEHAVIORAL NEUROSCIENCE"},
     {"name":"MEDICAL MOLECULAR MORPHOLOGY"},
     {"name":"INTEGRATIVE ZOOLOGY"},
     {"name":"IEEE TRANSACTIONS ON TERAHERTZ SCIENCE AND TECHNOLOGY"},
     {"name":"EUROPEAN JOURNAL OF PAEDIATRIC NEUROLOGY"},
     {"name":"MINERALS"},
     {"name":"MICROBES AND INFECTION"},
     {"name":"EUROPEAN MANAGEMENT JOURNAL"},
     {"name":"PSYCHOLOGY OF RELIGION AND SPIRITUALITY"},
     {"name":"PHYSICAL EDUCATION AND SPORT PEDAGOGY"},
     {"name":"JOURNAL OF COMPUTATIONAL AND GRAPHICAL STATISTICS"},
     {"name":"SURFACE ENGINEERING"},
     {"name":"JOURNAL OF CONSUMER CULTURE"},
     {"name":"CRITICAL CARE AND RESUSCITATION"},
     {"name":"CURRENT MEDICAL RESEARCH AND OPINION"},
     {"name":"VANDERBILT LAW REVIEW"},
     {"name":"JOURNAL OF ELECTROCERAMICS"},
     {"name":"DEVELOPMENT AND CHANGE"},
     {"name":"BIOMICROFLUIDICS"},
     {"name":"INTERNATIONAL JOURNAL OF SYSTEMATIC AND EVOLUTIONARY MICROBIOLOGY"},
     {"name":"SCHOOL PSYCHOLOGY REVIEW"},
     {"name":"JOURNAL OF WEB SEMANTICS"},
     {"name":"INTERNATIONAL JOURNAL OF CLINICAL PRACTICE"},
     {"name":"FRONTIERS OF PHYSICS"},
     {"name":"BUSINESS ETHICS QUARTERLY"},
     {"name":"APPLIED PHYSIOLOGY NUTRITION AND METABOLISM"},
     {"name":"LARYNGOSCOPE"},
     {"name":"VISION RESEARCH"},
     {"name":"BMC MEDICAL GENOMICS"},
     {"name":"MICROELECTRONIC ENGINEERING"},
     {"name":"PHYSIOLOGICAL MEASUREMENT"},
     {"name":"INTERNATIONAL JOURNAL OF EARTH SCIENCES"},
     {"name":"JOURNAL OF CLINICAL PHARMACOLOGY"},
     {"name":"CIRCULATION JOURNAL"},
     {"name":"PREVENTIVE VETERINARY MEDICINE"},
     {"name":"EYE"},
     {"name":"ARCHIV DER PHARMAZIE"},
     {"name":"CLEAN TECHNOLOGIES AND ENVIRONMENTAL POLICY"},
     {"name":"CLINICAL BIOCHEMISTRY"},
     {"name":"BJPSYCH OPEN"},
     {"name":"EFORT OPEN REVIEWS"},
     {"name":"IEEE JOURNAL OF QUANTUM ELECTRONICS"},
     {"name":"INTERNATIONAL JOURNAL OF PHYTOREMEDIATION"},
     {"name":"PHYSIOTHERAPY"},
     {"name":"POLYMERS FOR ADVANCED TECHNOLOGIES"},
     {"name":"FRONTIERS IN PUBLIC HEALTH"},
     {"name":"EUROPEAN SPINE JOURNAL"},
     {"name":"JOURNAL OF CHEMICAL NEUROANATOMY"},
     {"name":"CANCER BIOTHERAPY AND RADIOPHARMACEUTICALS"},
     {"name":"PHARMACOLOGY BIOCHEMISTRY AND BEHAVIOR"},
     {"name":"EUROPEAN REVIEW OF AGING AND PHYSICAL ACTIVITY"},
     {"name":"JOURNAL OF LAW AND THE BIOSCIENCES"},
     {"name":"ONCOLOGY LETTERS"},
     {"name":"PLASMONICS"},
     {"name":"AAPS PHARMSCITECH"},
     {"name":"JOURNAL OF PHYCOLOGY"},
     {"name":"ELECTRONIC COMMERCE RESEARCH"},
     {"name":"CANCER INVESTIGATION"},
     {"name":"PHYSIOLOGICAL AND BIOCHEMICAL ZOOLOGY"},
     {"name":"ORGANIZATION"},
     {"name":"PROGRESS IN EARTH AND PLANETARY SCIENCE"},
     {"name":"IMMUNOLOGICAL INVESTIGATIONS"},
     {"name":"NEURAL DEVELOPMENT"},
     {"name":"ILAR JOURNAL"},
     {"name":"JOURNAL OF CARDIOVASCULAR PHARMACOLOGY"},
     {"name":"CONDOR"},
     {"name":"ADVANCES IN MANUFACTURING"},
     {"name":"JOURNAL OF MICROELECTROMECHANICAL SYSTEMS"},
     {"name":"ELECTROANALYSIS"},
     {"name":"ELECTROCATALYSIS"},
     {"name":"PERSPECTIVES ON POLITICS"},
     {"name":"JOURNAL OF OCCUPATIONAL HEALTH"},
     {"name":"CROP PROTECTION"},
     {"name":"NEW FORESTS"},
     {"name":"JOURNAL OF PLASMA PHYSICS"},
     {"name":"NATURAL HAZARDS"},
     {"name":"EUROPEAN JOURNAL OF INORGANIC CHEMISTRY"},
     {"name":"COGNITIVE NEUROPSYCHOLOGY"},
     {"name":"EJNMMI PHYSICS"},
     {"name":"SYSTEMATIC REVIEWS"},
     {"name":"JOURNAL OF PETROLEUM GEOLOGY"},
     {"name":"JOURNAL OF ORGANOMETALLIC CHEMISTRY"},
     {"name":"INTERNATIONAL JOURNAL OF TUBERCULOSIS AND LUNG DISEASE"},
     {"name":"CONTINENTAL SHELF RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF WILDLAND FIRE"},
     {"name":"ECONOMIC AND LABOUR RELATIONS REVIEW"},
     {"name":"BEILSTEIN JOURNAL OF ORGANIC CHEMISTRY"},
     {"name":"ASIA PACIFIC JOURNAL OF MARKETING AND LOGISTICS"},
     {"name":"METEOROLOGICAL APPLICATIONS"},
     {"name":"JOURNAL OF VACATION MARKETING"},
     {"name":"ANNALS OF CLINICAL BIOCHEMISTRY"},
     {"name":"EARTHQUAKE SPECTRA"},
     {"name":"ANNALS OF HEPATOLOGY"},
     {"name":"PATIENT PREFERENCE AND ADHERENCE"},
     {"name":"JOURNAL OF TOXICOLOGICAL SCIENCES"},
     {"name":"MATERIALS SCIENCE AND TECHNOLOGY"},
     {"name":"INTERNATIONAL EMERGENCY NURSING"},
     {"name":"FOOD TECHNOLOGY AND BIOTECHNOLOGY"},
     {"name":"ICHTHYOLOGICAL EXPLORATION OF FRESHWATERS"},
     {"name":"PARASITE IMMUNOLOGY"},
     {"name":"AGROFORESTRY SYSTEMS"},
     {"name":"BEHAVIOR MODIFICATION"},
     {"name":"COLLEGIAN"},
     {"name":"NEOPLASMA"},
     {"name":"JOURNAL OF LOW FREQUENCY NOISE VIBRATION AND ACTIVE CONTROL"},
     {"name":"COMPUTING"},
     {"name":"EUROPEAN JOURNAL OF TRAUMA AND EMERGENCY SURGERY"},
     {"name":"PATHOGENS AND DISEASE"},
     {"name":"JOURNAL OF THE CHINESE MEDICAL ASSOCIATION"},
     {"name":"OCEAN DYNAMICS"},
     {"name":"JOURNAL OF GREY SYSTEM"},
     {"name":"METALS AND MATERIALS INTERNATIONAL"},
     {"name":"WETLANDS"},
     {"name":"MEDICAL MICROBIOLOGY AND IMMUNOLOGY"},
     {"name":"NEW MICROBIOLOGICA"},
     {"name":"INTERNATIONAL JOURNAL OF GYNECOLOGICAL PATHOLOGY"},
     {"name":"AUSTRALIAN CRITICAL CARE"},
     {"name":"FRONTIERS IN SURGERY"},
     {"name":"BIOMEDICAL CHROMATOGRAPHY"},
     {"name":"JOURNAL OF APPLIED SPORT PSYCHOLOGY"},
     {"name":"TURKISH JOURNAL OF AGRICULTURE AND FORESTRY"},
     {"name":"AGING NEUROPSYCHOLOGY AND COGNITION"},
     {"name":"JOURNAL OF ENVIRONMENTAL EDUCATION"},
     {"name":"JOURNAL OF MEDICAL ETHICS"},
     {"name":"JOURNAL OF BERRY RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF PAEDIATRIC DENTISTRY"},
     {"name":"JOURNAL OF NAVIGATION"},
     {"name":"ACTA NEUROCHIRURGICA"},
     {"name":"UNIVERSE"},
     {"name":"CRITICAL POLICY STUDIES"},
     {"name":"WATER INTERNATIONAL"},
     {"name":"CANADIAN JOURNAL OF GASTROENTEROLOGY AND HEPATOLOGY"},
     {"name":"MEMORIAS DO INSTITUTO OSWALDO CRUZ"},
     {"name":"JOURNAL OF MARKETING MANAGEMENT"},
     {"name":"VETERINARY IMMUNOLOGY AND IMMUNOPATHOLOGY"},
     {"name":"SOCIAL PSYCHOLOGY"},
     {"name":"ELECTRONIC MATERIALS LETTERS"},
     {"name":"NUMERICAL ALGORITHMS"},
     {"name":"HEALTH COMMUNICATION"},
     {"name":"PHYSIOLOGICAL ENTOMOLOGY"},
     {"name":"ARCHIVES OF AGRONOMY AND SOIL SCIENCE"},
     {"name":"ACTA MECHANICA SINICA"},
     {"name":"CLINICAL TRANSPLANTATION"},
     {"name":"THIRD WORLD QUARTERLY"},
     {"name":"NURSING RESEARCH"},
     {"name":"UROLOGIC CLINICS OF NORTH AMERICA"},
     {"name":"PSYCHIATRIC CLINICS OF NORTH AMERICA"},
     {"name":"CORROSION ENGINEERING SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF WOOD CHEMISTRY AND TECHNOLOGY"},
     {"name":"GLOBAL PUBLIC HEALTH"},
     {"name":"WORLD JOURNAL OF GASTROINTESTINAL SURGERY"},
     {"name":"HEALTH INFORMATION MANAGEMENT JOURNAL"},
     {"name":"FUEL CELLS"},
     {"name":"NORDIC JOURNAL OF PSYCHIATRY"},
     {"name":"INTERNATIONAL JOURNAL OF COMPUTATIONAL INTELLIGENCE SYSTEMS"},
     {"name":"UROLOGIA INTERNATIONALIS"},
     {"name":"PHOTONIC SENSORS"},
     {"name":"MANAGERIAL AUDITING JOURNAL"},
     {"name":"ENVIRONMENT DEVELOPMENT AND SUSTAINABILITY"},
     {"name":"INTERNATIONAL JOURNAL OF GENERAL SYSTEMS"},
     {"name":"HOMEOPATHY"},
     {"name":"JOM"},
     {"name":"JOURNAL OF NEUROLINGUISTICS"},
     {"name":"EUROPEAN JOURNAL OF DEVELOPMENTAL PSYCHOLOGY"},
     {"name":"AERA OPEN"},
     {"name":"BIOCHEMIA MEDICA"},
     {"name":"ANALYTICAL CELLULAR PATHOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF STRESS MANAGEMENT"},
     {"name":"MULTIBODY SYSTEM DYNAMICS"},
     {"name":"SAFETY AND HEALTH AT WORK"},
     {"name":"CONTEMPORARY ACCOUNTING RESEARCH"},
     {"name":"CHEMICAL PHYSICS"},
     {"name":"CHINESE JOURNAL OF MECHANICAL ENGINEERING"},
     {"name":"PEDIATRIC CLINICS OF NORTH AMERICA"},
     {"name":"ETHNOS"},
     {"name":"NEUROSURGERY CLINICS OF NORTH AMERICA"},
     {"name":"INTERNATIONAL JOURNAL OF SPELEOLOGY"},
     {"name":"JOURNAL OF CHEMOTHERAPY"},
     {"name":"DISTANCE EDUCATION"},
     {"name":"INTERNATIONAL FORESTRY REVIEW"},
     {"name":"INTERNATIONAL JOURNAL OF BIOLOGICAL MARKERS"},
     {"name":"PSYCHOLOGICAL SERVICES"},
     {"name":"COMPARATIVE EDUCATION"},
     {"name":"JOURNAL OF ORTHOPAEDIC TRAUMA"},
     {"name":"ACTUATORS"},
     {"name":"LANGUAGE IN SOCIETY"},
     {"name":"CRYOGENICS"},
     {"name":"PATHOLOGY INTERNATIONAL"},
     {"name":"NEUROPSYCHOBIOLOGY"},
     {"name":"ENVIRONMENTAL TECHNOLOGY"},
     {"name":"JOURNAL OF THE PEDIATRIC INFECTIOUS DISEASES SOCIETY"},
     {"name":"JOURNAL OF PLANKTON RESEARCH"},
     {"name":"SPATIAL COGNITION AND COMPUTATION"},
     {"name":"AMERICAN JOURNAL OF THE MEDICAL SCIENCES"},
     {"name":"TRIALS"},
     {"name":"ARCHIVES OF MICROBIOLOGY"},
     {"name":"ADVANCES IN POLYMER SCIENCE"},
     {"name":"ACTA ANAESTHESIOLOGICA SCANDINAVICA"},
     {"name":"EUROPEAN JOURNAL OF ORAL SCIENCES"},
     {"name":"AMERICAN JOURNAL OF MEDICAL GENETICS PART A"},
     {"name":"NEUROIMMUNOMODULATION"},
     {"name":"AQUACULTURE RESEARCH"},
     {"name":"BRITISH JOURNAL OF INDUSTRIAL RELATIONS"},
     {"name":"CLINICAL COSMETIC AND INVESTIGATIONAL DERMATOLOGY"},
     {"name":"PLANT PATHOLOGY"},
     {"name":"REPRODUCTIVE BIOLOGY"},
     {"name":"INTERNATIONAL MIGRATION REVIEW"},
     {"name":"IEEE TRANSACTIONS ON EDUCATION"},
     {"name":"CLINICAL AND EXPERIMENTAL DERMATOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF BILINGUAL EDUCATION AND BILINGUALISM"},
     {"name":"COMPTES RENDUS GEOSCIENCE"},
     {"name":"JOURNAL DE MATHEMATIQUES PURES ET APPLIQUEES"},
     {"name":"AIAA JOURNAL"},
     {"name":"JOURNAL OF VISCERAL SURGERY"},
     {"name":"SCANDINAVIAN JOURNAL OF PUBLIC HEALTH"},
     {"name":"ITALIAN JOURNAL OF ANIMAL SCIENCE"},
     {"name":"EUROPEAN JOURNAL OF HISTOCHEMISTRY"},
     {"name":"MEASUREMENT AND EVALUATION IN COUNSELING AND DEVELOPMENT"},
     {"name":"JOURNAL OF BASIC MICROBIOLOGY"},
     {"name":"HERITAGE SCIENCE"},
     {"name":"JOURNAL OF DEVELOPMENT EFFECTIVENESS"},
     {"name":"EXPERIMENTAL AND CLINICAL PSYCHOPHARMACOLOGY"},
     {"name":"PLANT CELL TISSUE AND ORGAN CULTURE"},
     {"name":"HEALTH SOCIOLOGY REVIEW"},
     {"name":"ENDOCRINE JOURNAL"},
     {"name":"BMC MOLECULAR BIOLOGY"},
     {"name":"JOURNAL OF PUBLIC ECONOMICS"},
     {"name":"JOURNAL OF HIGHER EDUCATION"},
     {"name":"PAPERS IN REGIONAL SCIENCE"},
     {"name":"JOURNAL OF CONTEMPORARY ASIA"},
     {"name":"MOLECULAR VISION"},
     {"name":"JOURNAL OF MEMBRANE BIOLOGY"},
     {"name":"BMC NURSING"},
     {"name":"JOURNAL OF INDUSTRIAL RELATIONS"},
     {"name":"JOURNAL OF AGING AND HEALTH"},
     {"name":"ADVANCES IN APPLIED MATHEMATICS AND MECHANICS"},
     {"name":"WEST EUROPEAN POLITICS"},
     {"name":"AGGRESSIVE BEHAVIOR"},
     {"name":"INTERNATIONAL JOURNAL OF GASTRONOMY AND FOOD SCIENCE"},
     {"name":"INTERNATIONAL PUBLIC MANAGEMENT JOURNAL"},
     {"name":"HISTOLOGY AND HISTOPATHOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF DISASTER RISK SCIENCE"},
     {"name":"JOURNAL OF VERTEBRATE PALEONTOLOGY"},
     {"name":"GLOBAL HEALTH ACTION"},
     {"name":"JOURNAL OF MICROMECHANICS AND MICROENGINEERING"},
     {"name":"INTERNATIONAL THEORY"},
     {"name":"BMC INTERNATIONAL HEALTH AND HUMAN RIGHTS"},
     {"name":"JOURNAL OF CLINICAL PSYCHOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF ARCHITECTURAL HERITAGE"},
     {"name":"INTERNATIONAL UROGYNECOLOGY JOURNAL"},
     {"name":"NETHERLANDS HEART JOURNAL"},
     {"name":"JOURNAL OF HUMANISTIC PSYCHOLOGY"},
     {"name":"JOURNAL OF INFECTION AND CHEMOTHERAPY"},
     {"name":"SEMINARS IN REPRODUCTIVE MEDICINE"},
     {"name":"CLINICAL EEG AND NEUROSCIENCE"},
     {"name":"JOURNAL OF THE ECONOMICS OF AGEING"},
     {"name":"EARTH PLANETS AND SPACE"},
     {"name":"SOCIOLOGICAL REVIEW"},
     {"name":"SOCIOLOGICAL FORUM"},
     {"name":"WESTERN JOURNAL OF EMERGENCY MEDICINE"},
     {"name":"OPTICAL AND QUANTUM ELECTRONICS"},
     {"name":"CELL JOURNAL"},
     {"name":"ANTIVIRAL THERAPY"},
     {"name":"RHEUMATOLOGY INTERNATIONAL"},
     {"name":"BIOENGINEERED"},
     {"name":"JOURNAL OF WATER REUSE AND DESALINATION"},
     {"name":"JOURNAL OF SUSTAINABLE METALLURGY"},
     {"name":"CURRENT PSYCHOLOGY"},
     {"name":"HEAT TRANSFER ENGINEERING"},
     {"name":"JOURNAL OF RESEARCH ON ADOLESCENCE"},
     {"name":"BIOLOGY OF SPORT"},
     {"name":"ACTA HISTOCHEMICA"},
     {"name":"CHILD AND ADOLESCENT MENTAL HEALTH"},
     {"name":"INTERNATIONAL JOURNAL OF LEGAL MEDICINE"},
     {"name":"ONCOLOGY NURSING FORUM"},
     {"name":"MEMORY STUDIES"},
     {"name":"STRUCTURAL DESIGN OF TALL AND SPECIAL BUILDINGS"},
     {"name":"WOOD SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF MAMMALOGY"},
     {"name":"STUDIES IN INFORMATICS AND CONTROL"},
     {"name":"CARBON LETTERS"},
     {"name":"JOURNAL OF THE EUROPEAN MATHEMATICAL SOCIETY"},
     {"name":"BURNS"},
     {"name":"JOURNAL OF CHEMISTRY"},
     {"name":"CURRENT OPINION IN CARDIOLOGY"},
     {"name":"SLEEP AND BREATHING"},
     {"name":"REVIEWS IN ANALYTICAL CHEMISTRY"},
     {"name":"REVIEW OF INTERNATIONAL STUDIES"},
     {"name":"FEBS OPEN BIO"},
     {"name":"AQUATIC INVASIONS"},
     {"name":"JOURNAL OF APPLIED GENETICS"},
     {"name":"AMERICAN JOURNAL OF THERAPEUTICS"},
     {"name":"JOURNAL OF APPLIED RESEARCH ON MEDICINAL AND AROMATIC PLANTS"},
     {"name":"JOURNAL OF ORGANIZATIONAL AND END USER COMPUTING"},
     {"name":"CONSCIOUSNESS AND COGNITION"},
     {"name":"SEED SCIENCE RESEARCH"},
     {"name":"JOURNAL OF INVESTIGATIVE SURGERY"},
     {"name":"INTERNATIONAL STUDIES REVIEW"},
     {"name":"EUROPEAN JOURNAL OF DEVELOPMENT RESEARCH"},
     {"name":"JOURNAL OF DIFFERENTIAL EQUATIONS"},
     {"name":"WILDLIFE RESEARCH"},
     {"name":"INTERNATIONAL ANGIOLOGY"},
     {"name":"BMC SURGERY"},
     {"name":"AMERICAN JOURNAL OF HEALTH ECONOMICS"},
     {"name":"ELECTROMAGNETIC BIOLOGY AND MEDICINE"},
     {"name":"EUROPEAN JOURNAL OF ORTHODONTICS"},
     {"name":"JOURNAL OF THE BRAZILIAN SOCIETY OF MECHANICAL SCIENCES AND ENGINEERING"},
     {"name":"INTERNATIONAL HEART JOURNAL"},
     {"name":"LAW AND HUMAN BEHAVIOR"},
     {"name":"AIDS RESEARCH AND THERAPY"},
     {"name":"MACHINING SCIENCE AND TECHNOLOGY"},
     {"name":"ALLERGY ASTHMA AND CLINICAL IMMUNOLOGY"},
     {"name":"CRITICAL REVIEWS IN EUKARYOTIC GENE EXPRESSION"},
     {"name":"JOURNAL OF DISPERSION SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF INTEGRATIVE AGRICULTURE"},
     {"name":"HORMONE RESEARCH IN PAEDIATRICS"},
     {"name":"CLINICAL RADIOLOGY"},
     {"name":"SURFACE AND INTERFACE ANALYSIS"},
     {"name":"FOLIA MICROBIOLOGICA"},
     {"name":"NURSING INQUIRY"},
     {"name":"FOCUS ON AUTISM AND OTHER DEVELOPMENTAL DISABILITIES"},
     {"name":"EDUCATIONAL ADMINISTRATION QUARTERLY"},
     {"name":"AMERICAN JOURNAL OF SURGERY"},
     {"name":"BRITISH FOOD JOURNAL"},
     {"name":"MAGNETOCHEMISTRY"},
     {"name":"STATISTICS IN MEDICINE"},
     {"name":"JOURNAL OF APPLIED RESEARCH IN INTELLECTUAL DISABILITIES"},
     {"name":"BIOETHICS"},
     {"name":"AMERICAN ANTIQUITY"},
     {"name":"THINKING SKILLS AND CREATIVITY"},
     {"name":"INJURY PREVENTION"},
     {"name":"ANNUAL REVIEW OF FINANCIAL ECONOMICS"},
     {"name":"JOURNAL OF CONSUMER BEHAVIOUR"},
     {"name":"ACCOUNTING FORUM"},
     {"name":"INTERNATIONAL JOURNAL OF GYNECOLOGICAL CANCER"},
     {"name":"FIRE ECOLOGY"},
     {"name":"GENETIC PROGRAMMING AND EVOLVABLE MACHINES"},
     {"name":"MAGNETIC RESONANCE IN CHEMISTRY"},
     {"name":"MICROBIAL RISK ANALYSIS"},
     {"name":"WORLD BANK ECONOMIC REVIEW"},
     {"name":"IET BIOMETRICS"},
     {"name":"KNEE"},
     {"name":"CLINICS IN SPORTS MEDICINE"},
     {"name":"ISRAEL JOURNAL OF HEALTH POLICY RESEARCH"},
     {"name":"SYNLETT"},
     {"name":"CRYPTOGAMIE ALGOLOGIE"},
     {"name":"SOUTH AFRICAN JOURNAL OF SCIENCE"},
     {"name":"CONSERVATION GENETICS"},
     {"name":"JOURNAL OF FINANCIAL MARKETS"},
     {"name":"TRANSACTIONS IN GIS"},
     {"name":"TERRORISM AND POLITICAL VIOLENCE"},
     {"name":"JOURNAL OF HYDROINFORMATICS"},
     {"name":"ARCHIVES OF OSTEOPOROSIS"},
     {"name":"ECONOMIC ANALYSIS AND POLICY"},
     {"name":"ONLINE INFORMATION REVIEW"},
     {"name":"JOURNAL OF OCULAR PHARMACOLOGY AND THERAPEUTICS"},
     {"name":"GENES AND ENVIRONMENT"},
     {"name":"ENGINEERING CONSTRUCTION AND ARCHITECTURAL MANAGEMENT"},
     {"name":"INTERNATIONAL JOURNAL OF COMPUTERIZED DENTISTRY"},
     {"name":"NEPHROLOGY"},
     {"name":"JOURNAL OF APPLIED GERONTOLOGY"},
     {"name":"JOURNAL OF ELECTROMYOGRAPHY AND KINESIOLOGY"},
     {"name":"JOURNAL OF SCHEDULING"},
     {"name":"CANADIAN JOURNAL OF FOREST RESEARCH"},
     {"name":"COMMUNITY DENTISTRY AND ORAL EPIDEMIOLOGY"},
     {"name":"BLOOD PRESSURE"},
     {"name":"CHIRALITY"},
     {"name":"JOURNAL OF INFRASTRUCTURE SYSTEMS"},
     {"name":"JOURNAL OF PUBLIC HEALTH DENTISTRY"},
     {"name":"CONGENITAL HEART DISEASE"},
     {"name":"JOURNAL OF WILDLIFE MANAGEMENT"},
     {"name":"RESEARCH IN TRANSPORTATION ECONOMICS"},
     {"name":"CALIFORNIA LAW REVIEW"},
     {"name":"FRESHWATER SCIENCE"},
     {"name":"JOURNAL OF MACROMARKETING"},
     {"name":"SOIL RESEARCH"},
     {"name":"AQUACULTURE NUTRITION"},
     {"name":"MYCOLOGIA"},
     {"name":"JOURNAL OF RESEARCH IN MEDICAL SCIENCES"},
     {"name":"AMERICAN JOURNAL OF EMERGENCY MEDICINE"},
     {"name":"THERAPEUTICS AND CLINICAL RISK MANAGEMENT"},
     {"name":"AOB PLANTS"},
     {"name":"JOURNAL OF TEXTURE STUDIES"},
     {"name":"EARLY HUMAN DEVELOPMENT"},
     {"name":"SURGICAL ONCOLOGY CLINICS OF NORTH AMERICA"},
     {"name":"CORROSION REVIEWS"},
     {"name":"JOURNAL OF ENVIRONMENTAL PLANNING AND MANAGEMENT"},
     {"name":"JOURNAL OF HEALTH POLITICS POLICY AND LAW"},
     {"name":"EUROPEAN JOURNAL OF WOMENS STUDIES"},
     {"name":"SMALL GROUP RESEARCH"},
     {"name":"JOURNAL OF EARTH SCIENCE"},
     {"name":"JOURNAL OF SPORTS SCIENCE AND MEDICINE"},
     {"name":"INTERNATIONAL JOURNAL OF LABORATORY HEMATOLOGY"},
     {"name":"JOURNAL OF FOOD PROCESS ENGINEERING"},
     {"name":"LEUKEMIA RESEARCH"},
     {"name":"EXPERIMENTAL AND THERAPEUTIC MEDICINE"},
     {"name":"JOURNAL OF NATURAL MEDICINES"},
     {"name":"JOURNAL OF ECONOMIC ENTOMOLOGY"},
     {"name":"CATHETERIZATION AND CARDIOVASCULAR INTERVENTIONS"},
     {"name":"CURRENT OPINION IN UROLOGY"},
     {"name":"PSYCHIATRY INVESTIGATION"},
     {"name":"PEDIATRIC RADIOLOGY"},
     {"name":"ANNALS OF THE AMERICAN ACADEMY OF POLITICAL AND SOCIAL SCIENCE"},
     {"name":"BIOSCIENCE OF MICROBIOTA FOOD AND HEALTH"},
     {"name":"INFORMATION RETRIEVAL JOURNAL"},
     {"name":"PSYCHOSOMATICS"},
     {"name":"VIOLENCE AGAINST WOMEN"},
     {"name":"JOURNAL OF SEISMIC EXPLORATION"},
     {"name":"SCIENCE PROGRESS"},
     {"name":"ORIGINS OF LIFE AND EVOLUTION OF BIOSPHERES"},
     {"name":"CRITICAL REVIEWS IN THERAPEUTIC DRUG CARRIER SYSTEMS"},
     {"name":"OCEANOLOGIA"},
     {"name":"EATING BEHAVIORS"},
     {"name":"BMC ZOOLOGY"},
     {"name":"EDUCATIONAL AND PSYCHOLOGICAL MEASUREMENT"},
     {"name":"JOURNAL OF MATHEMATICAL BIOLOGY"},
     {"name":"AMERICAN JOURNAL OF HEALTH PROMOTION"},
     {"name":"HEMODIALYSIS INTERNATIONAL"},
     {"name":"NEUROPSYCHIATRIC DISEASE AND TREATMENT"},
     {"name":"ACCOUNTING AND FINANCE"},
     {"name":"CHEMOSENSORY PERCEPTION"},
     {"name":"ENGINEERING IN LIFE SCIENCES"},
     {"name":"INTERNATIONAL JOURNAL OF COLORECTAL DISEASE"},
     {"name":"FRONTIERS OF STRUCTURAL AND CIVIL ENGINEERING"},
     {"name":"CHINA QUARTERLY"},
     {"name":"PRION"},
     {"name":"BEHAVIOURAL PROCESSES"},
     {"name":"INDIAN JOURNAL OF MICROBIOLOGY"},
     {"name":"AMERICAN ANTHROPOLOGIST"},
     {"name":"ARCHIVES OF ENVIRONMENTAL PROTECTION"},
     {"name":"MARINE MICROPALEONTOLOGY"},
     {"name":"HUMAN ECOLOGY"},
     {"name":"PRIMARY CARE DIABETES"},
     {"name":"MEDICINE HEALTH CARE AND PHILOSOPHY"},
     {"name":"KYBERNETES"},
     {"name":"ODONTOLOGY"},
     {"name":"INORGANIC CHEMISTRY COMMUNICATIONS"},
     {"name":"MEDICAL SCIENCE MONITOR"},
     {"name":"AUTOIMMUNITY"},
     {"name":"OBSTETRICS AND GYNECOLOGY CLINICS OF NORTH AMERICA"},
     {"name":"REVISTA DE CHIMIE"},
     {"name":"CHEMISTRYSELECT"},
     {"name":"CLINICAL ANATOMY"},
     {"name":"WORLD JOURNAL OF EMERGENCY MEDICINE"},
     {"name":"JOURNAL OF GREAT LAKES RESEARCH"},
     {"name":"STATA JOURNAL"},
     {"name":"JOURNAL OF NONVERBAL BEHAVIOR"},
     {"name":"REPRODUCTION FERTILITY AND DEVELOPMENT"},
     {"name":"CONFLICT AND HEALTH"},
     {"name":"OPTICAL FIBER TECHNOLOGY"},
     {"name":"JOURNAL OF INNOVATIVE OPTICAL HEALTH SCIENCES"},
     {"name":"SEMINARS IN THORACIC AND CARDIOVASCULAR SURGERY"},
     {"name":"AMERICAN STATISTICIAN"},
     {"name":"JOURNAL OF FIXED POINT THEORY AND APPLICATIONS"},
     {"name":"INTERNATIONAL JOURNAL OF NEUROSCIENCE"},
     {"name":"INTERNATIONAL JOURNAL OF URBAN SCIENCES"},
     {"name":"SCIENCE AND PUBLIC POLICY"},
     {"name":"MENDELEEV COMMUNICATIONS"},
     {"name":"JAPANESE JOURNAL OF CLINICAL ONCOLOGY"},
     {"name":"THIN SOLID FILMS"},
     {"name":"JOURNAL OF MUSCLE RESEARCH AND CELL MOTILITY"},
     {"name":"CLINICAL AND EXPERIMENTAL OTORHINOLARYNGOLOGY"},
     {"name":"FIRE AND MATERIALS"},
     {"name":"GERIATRIC NURSING"},
     {"name":"LINGUISTICS AND PHILOSOPHY"},
     {"name":"THERAPIE"},
     {"name":"JOURNAL OF ELECTRONIC PACKAGING"},
     {"name":"ACM TRANSACTIONS ON KNOWLEDGE DISCOVERY FROM DATA"},
     {"name":"MEDICAL EDUCATION ONLINE"},
     {"name":"JOURNAL OF RACIAL AND ETHNIC HEALTH DISPARITIES"},
     {"name":"PROGRESS IN ORTHODONTICS"},
     {"name":"DIGESTIVE SURGERY"},
     {"name":"FASHION AND TEXTILES"},
     {"name":"BOTANICAL REVIEW"},
     {"name":"INTERNATIONAL JOURNAL OF BEHAVIORAL MEDICINE"},
     {"name":"LIVESTOCK SCIENCE"},
     {"name":"CURRENT PHARMACEUTICAL BIOTECHNOLOGY"},
     {"name":"MATHEMATICS OF OPERATIONS RESEARCH"},
     {"name":"GREEN MATERIALS"},
     {"name":"CIRCUITS SYSTEMS AND SIGNAL PROCESSING"},
     {"name":"NEOTROPICAL ICHTHYOLOGY"},
     {"name":"CORROSION"},
     {"name":"JOURNAL OF IMMUNOLOGICAL METHODS"},
     {"name":"QUARTERLY JOURNAL OF EXPERIMENTAL PSYCHOLOGY"},
     {"name":"THEORETICAL BIOLOGY AND MEDICAL MODELLING"},
     {"name":"TESOL QUARTERLY"},
     {"name":"SYSTEM DYNAMICS REVIEW"},
     {"name":"CHINA AGRICULTURAL ECONOMIC REVIEW"},
     {"name":"FOREIGN LANGUAGE ANNALS"},
     {"name":"JOURNAL OF INTELLECTUAL DISABILITY RESEARCH"},
     {"name":"OPERATIVE NEUROSURGERY"},
     {"name":"EUROPEAN JOURNAL OF PEDIATRIC SURGERY"},
     {"name":"DESIGNED MONOMERS AND POLYMERS"},
     {"name":"CARDIOLOGY IN REVIEW"},
     {"name":"JOURNAL OF FOOD BIOCHEMISTRY"},
     {"name":"ENGINEERING OPTIMIZATION"},
     {"name":"JOURNAL OF PERINATOLOGY"},
     {"name":"EUROPEAN JOURNAL OF ONCOLOGY NURSING"},
     {"name":"CARIES RESEARCH"},
     {"name":"CENTRAL EUROPEAN JOURNAL OF OPERATIONS RESEARCH"},
     {"name":"INTERNATIONAL TRANSACTIONS ON ELECTRICAL ENERGY SYSTEMS"},
     {"name":"SURVEY REVIEW"},
     {"name":"JOURNAL OF CLINICAL MONITORING AND COMPUTING"},
     {"name":"FISHERIES RESEARCH"},
     {"name":"INSECTS"},
     {"name":"UNIVERSAL ACCESS IN THE INFORMATION SOCIETY"},
     {"name":"BRACHYTHERAPY"},
     {"name":"INTERNATIONAL JOURNAL ON SEMANTIC WEB AND INFORMATION SYSTEMS"},
     {"name":"JOURNAL OF PHARMACEUTICAL INNOVATION"},
     {"name":"AUSTRALASIAN JOURNAL OF DERMATOLOGY"},
     {"name":"JOURNAL OF APPLIED ENTOMOLOGY"},
     {"name":"JOURNAL OF PRIMARY PREVENTION"},
     {"name":"CONTEMPORARY SECURITY POLICY"},
     {"name":"COGNITIVE SYSTEMS RESEARCH"},
     {"name":"HUMAN DEVELOPMENT"},
     {"name":"URBAN POLICY AND RESEARCH"},
     {"name":"POLISH JOURNAL OF FOOD AND NUTRITION SCIENCES"},
     {"name":"LANGUAGE TESTING"},
     {"name":"JOURNAL OF APPLIED ORAL SCIENCE"},
     {"name":"PSYCHIATRIC REHABILITATION JOURNAL"},
     {"name":"INVERTEBRATE SYSTEMATICS"},
     {"name":"MATERIALS RESEARCH EXPRESS"},
     {"name":"JOURNAL OF SCIENTIFIC COMPUTING"},
     {"name":"SYMBIOSIS"},
     {"name":"CURRENT PROBLEMS IN PEDIATRIC AND ADOLESCENT HEALTH CARE"},
     {"name":"ECS JOURNAL OF SOLID STATE SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF NANOMATERIALS"},
     {"name":"LANGUAGE SPEECH AND HEARING SERVICES IN SCHOOLS"},
     {"name":"HISTORICAL BIOLOGY"},
     {"name":"RIVER RESEARCH AND APPLICATIONS"},
     {"name":"JOURNAL OF SURGICAL EDUCATION"},
     {"name":"INTERNATIONAL JOURNAL OF BEHAVIORAL DEVELOPMENT"},
     {"name":"PALEOBIOLOGY"},
     {"name":"SPINAL CORD"},
     {"name":"JOURNAL OF CAUSAL INFERENCE"},
     {"name":"BMC CARDIOVASCULAR DISORDERS"},
     {"name":"JOURNAL OF ULTRASOUND IN MEDICINE"},
     {"name":"JOURNAL OF HEALTHCARE ENGINEERING"},
     {"name":"ARCHIVES OF CLINICAL NEUROPSYCHOLOGY"},
     {"name":"ECONOMIC DEVELOPMENT AND CULTURAL CHANGE"},
     {"name":"FOOD SECURITY"},
     {"name":"INTERNATIONAL JOURNAL OF QUANTUM CHEMISTRY"},
     {"name":"ZOOLOGICAL LETTERS"},
     {"name":"SYSTEMS BIOLOGY IN REPRODUCTIVE MEDICINE"},
     {"name":"PSYCHOLOGY PUBLIC POLICY AND LAW"},
     {"name":"JOURNAL OF EPIDEMIOLOGY AND GLOBAL HEALTH"},
     {"name":"MEDICINAL CHEMISTRY RESEARCH"},
     {"name":"SCANDINAVIAN JOURNAL OF PRIMARY HEALTH CARE"},
     {"name":"ANNALS OF DIAGNOSTIC PATHOLOGY"},
     {"name":"SEPARATION SCIENCE AND TECHNOLOGY"},
     {"name":"ORYX"},
     {"name":"MODELLING AND SIMULATION IN MATERIALS SCIENCE AND ENGINEERING"},
     {"name":"JOURNAL OF APPLIED BIOMEDICINE"},
     {"name":"SCANDINAVIAN JOURNAL OF GASTROENTEROLOGY"},
     {"name":"EUROPEAN PHYSICAL JOURNAL A"},
     {"name":"CHILDRENS GEOGRAPHIES"},
     {"name":"EURASIAN BUSINESS REVIEW"},
     {"name":"IRANIAN POLYMER JOURNAL"},
     {"name":"JOURNAL OF THE MEDICAL LIBRARY ASSOCIATION"},
     {"name":"INTENSIVE AND CRITICAL CARE NURSING"},
     {"name":"INTERNATIONAL JOURNAL OF SUSTAINABILITY IN HIGHER EDUCATION"},
     {"name":"JOURNAL OF SCHOOL HEALTH"},
     {"name":"INTERNATIONAL NEUROUROLOGY JOURNAL"},
     {"name":"CARDIOVASCULAR ENGINEERING AND TECHNOLOGY"},
     {"name":"EUROPEAN SOCIOLOGICAL REVIEW"},
     {"name":"JOURNAL OF BIONIC ENGINEERING"},
     {"name":"ASTERISQUE"},
     {"name":"JOURNAL OF RISK RESEARCH"},
     {"name":"COMBUSTION SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF COMPUTATIONAL NEUROSCIENCE"},
     {"name":"MAGNETIC RESONANCE IMAGING"},
     {"name":"PARASITOLOGY INTERNATIONAL"},
     {"name":"JOURNAL OF PAEDIATRICS AND CHILD HEALTH"},
     {"name":"ATOMIZATION AND SPRAYS"},
     {"name":"JOURNAL OF PLANT ECOLOGY"},
     {"name":"MYCOLOGICAL PROGRESS"},
     {"name":"JORNAL DE PEDIATRIA"},
     {"name":"ADVANCES IN COMPUTERS"},
     {"name":"CONTEMPORARY CLINICAL TRIALS"},
     {"name":"METEOROLOGISCHE ZEITSCHRIFT"},
     {"name":"GAMES FOR HEALTH JOURNAL"},
     {"name":"TUMORI J"},
     {"name":"ANNALS OF PHYSICS"},
     {"name":"DISASTERS"},
     {"name":"JOURNAL OF CELLULAR PLASTICS"},
     {"name":"JOURNAL OF GRID COMPUTING"},
     {"name":"EUROPEAN SPORT MANAGEMENT QUARTERLY"},
     {"name":"EDUCATIONAL REVIEW"},
     {"name":"LINGUISTIC APPROACHES TO BILINGUALISM"},
     {"name":"INTERNATIONAL JOURNAL OF MINING RECLAMATION AND ENVIRONMENT"},
     {"name":"CHRONIC RESPIRATORY DISEASE"},
     {"name":"JOURNAL OF MATERIAL CYCLES AND WASTE MANAGEMENT"},
     {"name":"COGNITIVE DEVELOPMENT"},
     {"name":"MEDICAL LETTER ON DRUGS AND THERAPEUTICS"},
     {"name":"DEVELOPMENTAL PSYCHOBIOLOGY"},
     {"name":"JOURNAL OF HYDROLOGY AND HYDROMECHANICS"},
     {"name":"INTERNATIONAL JOURNAL OF RHEUMATIC DISEASES"},
     {"name":"EPL"},
     {"name":"RESEARCH IN HIGHER EDUCATION"},
     {"name":"ASIAN PACIFIC JOURNAL OF TROPICAL MEDICINE"},
     {"name":"INTERNATIONAL JOURNAL OF VEHICLE DESIGN"},
     {"name":"TEXTILE RESEARCH JOURNAL"},
     {"name":"CLINICAL HEMORHEOLOGY AND MICROCIRCULATION"},
     {"name":"TOPICS IN STROKE REHABILITATION"},
     {"name":"JOURNAL OF EXPERIMENTAL EDUCATION"},
     {"name":"JOURNAL OF THE OPERATIONAL RESEARCH SOCIETY"},
     {"name":"EUROPEAN PLANNING STUDIES"},
     {"name":"JOURNAL OF COMPUTATIONAL AND APPLIED MATHEMATICS"},
     {"name":"BMC DEVELOPMENTAL BIOLOGY"},
     {"name":"GLYCOCONJUGATE JOURNAL"},
     {"name":"GENETICS AND MOLECULAR BIOLOGY"},
     {"name":"JOURNAL OF CLINICAL PHARMACY AND THERAPEUTICS"},
     {"name":"JOURNAL OF VIROLOGICAL METHODS"},
     {"name":"INTERNATIONAL JOURNAL OF SPORT AND EXERCISE PSYCHOLOGY"},
     {"name":"CHINESE GEOGRAPHICAL SCIENCE"},
     {"name":"BOTHALIA"},
     {"name":"JOURNAL OF AEROSPACE ENGINEERING"},
     {"name":"SCHOOL MENTAL HEALTH"},
     {"name":"JOURNAL OF CLINICAL AND EXPERIMENTAL NEUROPSYCHOLOGY"},
     {"name":"JOURNAL OF PSYCHOACTIVE DRUGS"},
     {"name":"LETTERS IN APPLIED MICROBIOLOGY"},
     {"name":"WEATHER CLIMATE AND SOCIETY"},
     {"name":"EPIDEMIOLOGY AND INFECTION"},
     {"name":"HUMAN FERTILITY"},
     {"name":"WEED RESEARCH"},
     {"name":"PERSONALITY AND MENTAL HEALTH"},
     {"name":"JOURNAL OF GLAUCOMA"},
     {"name":"HEART LUNG AND CIRCULATION"},
     {"name":"INTERNATIONAL CLINICAL PSYCHOPHARMACOLOGY"},
     {"name":"SYSTEMATICS AND BIODIVERSITY"},
     {"name":"JOURNAL OF VASCULAR RESEARCH"},
     {"name":"INTERNATIONAL HEALTH"},
     {"name":"LANGUAGE AND EDUCATION"},
     {"name":"JOURNAL OF MEDICAL ENTOMOLOGY"},
     {"name":"NEUROLOGY INDIA"},
     {"name":"GASTROENTEROLOGY RESEARCH AND PRACTICE"},
     {"name":"CLINICAL PHARMACOLOGY IN DRUG DEVELOPMENT"},
     {"name":"MAGNETIC RESONANCE IN MEDICAL SCIENCES"},
     {"name":"JOURNAL OF ZOOLOGY"},
     {"name":"CANCER IMAGING"},
     {"name":"JOURNAL OF FASHION MARKETING AND MANAGEMENT"},
     {"name":"BIOSOCIETIES"},
     {"name":"CANADIAN JOURNAL OF PHYSIOLOGY AND PHARMACOLOGY"},
     {"name":"CURRENT BIOINFORMATICS"},
     {"name":"INTERACTIVE CARDIOVASCULAR AND THORACIC SURGERY"},
     {"name":"ACTA VETERINARIA SCANDINAVICA"},
     {"name":"JOURNAL OF PUBLIC POLICY"},
     {"name":"JOURNAL OF COMPUTATIONAL AND NONLINEAR DYNAMICS"},
     {"name":"HEALTH CARE MANAGEMENT SCIENCE"},
     {"name":"MAGAZINE OF CONCRETE RESEARCH"},
     {"name":"DISABILITY AND HEALTH JOURNAL"},
     {"name":"ARCHAEOLOGICAL AND ANTHROPOLOGICAL SCIENCES"},
     {"name":"ANNALES D ENDOCRINOLOGIE"},
     {"name":"INTERNATIONAL JOURNAL OF SPRAY AND COMBUSTION DYNAMICS"},
     {"name":"ECONOMIC MODELLING"},
     {"name":"JOURNAL OF SPINAL CORD MEDICINE"},
     {"name":"APPLIED COMPOSITE MATERIALS"},
     {"name":"FRONTIERS OF MATERIALS SCIENCE"},
     {"name":"JOURNAL OF PSYCHIATRIC AND MENTAL HEALTH NURSING"},
     {"name":"BRITISH JOURNAL OF RADIOLOGY"},
     {"name":"JOURNAL OF LEARNING DISABILITIES"},
     {"name":"ANALYTICAL SCIENCES"},
     {"name":"EUROPEAN JOURNAL OF DRUG METABOLISM AND PHARMACOKINETICS"},
     {"name":"JOURNAL OF FLUENCY DISORDERS"},
     {"name":"THERIOGENOLOGY"},
     {"name":"ENTOMOLOGIA EXPERIMENTALIS ET APPLICATA"},
     {"name":"JOURNAL OF ENVIRONMENTAL HEALTH SCIENCE AND ENGINEERING"},
     {"name":"JOURNAL OF LEGAL ANALYSIS"},
     {"name":"BMC PEDIATRICS"},
     {"name":"NEPHRON"},
     {"name":"JOURNAL OF MARRIAGE AND FAMILY"},
     {"name":"JOURNAL OF HUMAN KINETICS"},
     {"name":"JOURNAL OF ONCOLOGY PHARMACY PRACTICE"},
     {"name":"MOLECULAR DIVERSITY"},
     {"name":"ANTICANCER RESEARCH"},
     {"name":"ANNUAL REPORTS IN MEDICINAL CHEMISTRY"},
     {"name":"STRUCTURES"},
     {"name":"AMERICAN JOURNAL OF TROPICAL MEDICINE AND HYGIENE"},
     {"name":"FRONTIERS IN INTEGRATIVE NEUROSCIENCE"},
     {"name":"VETERINARY PATHOLOGY"},
     {"name":"BRAZILIAN JOURNAL OF INFECTIOUS DISEASES"},
     {"name":"JOURNAL OF REINFORCED PLASTICS AND COMPOSITES"},
     {"name":"IEEE TRANSACTIONS ON ELECTROMAGNETIC COMPATIBILITY"},
     {"name":"FRONTIERS IN PSYCHOLOGY"},
     {"name":"ASIAN JOURNAL OF SURGERY"},
     {"name":"BEHAVIOURAL AND COGNITIVE PSYCHOTHERAPY"},
     {"name":"AMERICAN JOURNAL OF ORTHODONTICS AND DENTOFACIAL ORTHOPEDICS"},
     {"name":"JOURNAL OF WOUND OSTOMY AND CONTINENCE NURSING"},
     {"name":"CHEMOECOLOGY"},
     {"name":"URBAN AFFAIRS REVIEW"},
     {"name":"JOURNAL OF SPECIAL EDUCATION"},
     {"name":"JOURNAL OF INVERTEBRATE PATHOLOGY"},
     {"name":"VENTURE CAPITAL"},
     {"name":"ETHICS"},
     {"name":"JOURNAL OF PLANT NUTRITION AND SOIL SCIENCE"},
     {"name":"JOURNAL OF SEA RESEARCH"},
     {"name":"VISCERAL MEDICINE"},
     {"name":"JOURNAL OF THERMAL SCIENCE"},
     {"name":"JOURNAL OF FOREST ECONOMICS"},
     {"name":"EUROPEAN ECONOMIC REVIEW"},
     {"name":"INTERNATIONAL DENTAL JOURNAL"},
     {"name":"INTERNATIONAL JOURNAL OF PUBLIC OPINION RESEARCH"},
     {"name":"ARCHIVES OF PUBLIC HEALTH"},
     {"name":"APPLIED OPTICS"},
     {"name":"INTERNATIONAL JOURNAL OF FOOD PROPERTIES"},
     {"name":"INTERNATIONAL JOURNAL OF DESIGN"},
     {"name":"ASIA PACIFIC JOURNAL OF HUMAN RESOURCES"},
     {"name":"PSYCHOLOGY RESEARCH AND BEHAVIOR MANAGEMENT"},
     {"name":"FINANCIAL ANALYSTS JOURNAL"},
     {"name":"JOURNAL OF MICROBIOLOGICAL METHODS"},
     {"name":"JOURNAL OF THE ACOUSTICAL SOCIETY OF AMERICA"},
     {"name":"CLINICAL SARCOMA RESEARCH"},
     {"name":"JOURNAL OF MULTINATIONAL FINANCIAL MANAGEMENT"},
     {"name":"SOUTH AFRICAN JOURNAL OF ENOLOGY AND VITICULTURE"},
     {"name":"JOURNAL OF CONTINGENCIES AND CRISIS MANAGEMENT"},
     {"name":"CLIMATE RESEARCH"},
     {"name":"COOPERATION AND CONFLICT"},
     {"name":"SIAM JOURNAL ON APPLIED DYNAMICAL SYSTEMS"},
     {"name":"RADIOCARBON"},
     {"name":"MEASUREMENT SCIENCE AND TECHNOLOGY"},
     {"name":"AMERICAN JOURNAL OF CRITICAL CARE"},
     {"name":"LIPIDS"},
     {"name":"BIOREMEDIATION JOURNAL"},
     {"name":"JOURNAL OF ELECTRONIC COMMERCE RESEARCH"},
     {"name":"OPHTHALMOLOGICA"},
     {"name":"INTERNATIONAL STUDIES QUARTERLY"},
     {"name":"JOURNAL OF SCHOOL NURSING"},
     {"name":"EVOLUTIONARY ECOLOGY"},
     {"name":"GIFTED CHILD QUARTERLY"},
     {"name":"VETERINARY DERMATOLOGY"},
     {"name":"BIOINTERPHASES"},
     {"name":"BRAIN INJURY"},
     {"name":"AIN SHAMS ENGINEERING JOURNAL"},
     {"name":"POLYMER BULLETIN"},
     {"name":"LEISURE SCIENCES"},
     {"name":"CARDIORENAL MEDICINE"},
     {"name":"LOCAL GOVERNMENT STUDIES"},
     {"name":"NONPROFIT AND VOLUNTARY SECTOR QUARTERLY"},
     {"name":"RHIZOSPHERE"},
     {"name":"DIAGNOSTIC AND INTERVENTIONAL RADIOLOGY"},
     {"name":"IET OPTOELECTRONICS"},
     {"name":"ZEITSCHRIFT FUR MEDIZINISCHE PHYSIK"},
     {"name":"FOOT AND ANKLE SURGERY"},
     {"name":"TRANSGENIC RESEARCH"},
     {"name":"JOURNAL OF RADIATION RESEARCH AND APPLIED SCIENCES"},
     {"name":"SOIL USE AND MANAGEMENT"},
     {"name":"IRISH VETERINARY JOURNAL"},
     {"name":"CANCER NURSING"},
     {"name":"IEEE TRANSACTIONS ON COMPONENTS PACKAGING AND MANUFACTURING TECHNOLOGY"},
     {"name":"SOLVENT EXTRACTION AND ION EXCHANGE"},
     {"name":"INTERNATIONAL JOURNAL OF ASTROBIOLOGY"},
     {"name":"NUCLEAR ENGINEERING AND TECHNOLOGY"},
     {"name":"EPILEPSY RESEARCH"},
     {"name":"MATERNAL AND CHILD HEALTH JOURNAL"},
     {"name":"EDUCATIONAL POLICY"},
     {"name":"RESEARCH QUARTERLY FOR EXERCISE AND SPORT"},
     {"name":"COMPTES RENDUS CHIMIE"},
     {"name":"SOLID STATE NUCLEAR MAGNETIC RESONANCE"},
     {"name":"METEOROLOGY AND ATMOSPHERIC PHYSICS"},
     {"name":"INTERNATIONAL JOURNAL OF COSMETIC SCIENCE"},
     {"name":"ADVANCES IN COMPUTATIONAL MATHEMATICS"},
     {"name":"IET NANOBIOTECHNOLOGY"},
     {"name":"CARDIOLOGY JOURNAL"},
     {"name":"ANNALS OF THE MISSOURI BOTANICAL GARDEN"},
     {"name":"BIOMARKERS"},
     {"name":"BIOLOGY DIRECT"},
     {"name":"BIOTROPICA"},
     {"name":"ARCHIVES OF ENDOCRINOLOGY METABOLISM"},
     {"name":"ECONOMETRICS JOURNAL"},
     {"name":"JOURNAL OF STUDIES IN INTERNATIONAL EDUCATION"},
     {"name":"JOURNAL OF SOCIAL ARCHAEOLOGY"},
     {"name":"LANGUAGE COGNITION AND NEUROSCIENCE"},
     {"name":"COMPLEMENTARY THERAPIES IN MEDICINE"},
     {"name":"JOURNAL OF SPEECH LANGUAGE AND HEARING RESEARCH"},
     {"name":"RADIATION PHYSICS AND CHEMISTRY"},
     {"name":"CAMBRIDGE JOURNAL OF ECONOMICS"},
     {"name":"JOURNAL OF CHEMICAL ECOLOGY"},
     {"name":"JOURNAL OF CUTANEOUS MEDICINE AND SURGERY"},
     {"name":"HARVARD EDUCATIONAL REVIEW"},
     {"name":"EVODEVO"},
     {"name":"POLYMER ENGINEERING AND SCIENCE"},
     {"name":"JOURNAL OF RISK AND UNCERTAINTY"},
     {"name":"JOURNAL OF DEVELOPMENTAL AND BEHAVIORAL PEDIATRICS"},
     {"name":"MEMORY"},
     {"name":"SECURITY STUDIES"},
     {"name":"SCANDINAVIAN JOURNAL OF MANAGEMENT"},
     {"name":"BOTANICAL JOURNAL OF THE LINNEAN SOCIETY"},
     {"name":"BOSNIAN JOURNAL OF BASIC MEDICAL SCIENCES"},
     {"name":"JOURNAL OF STRATEGIC STUDIES"},
     {"name":"INTERNATIONAL JOURNAL FOR NUMERICAL METHODS IN BIOMEDICAL ENGINEERING"},
     {"name":"SIAM JOURNAL ON SCIENTIFIC COMPUTING"},
     {"name":"LANGENBECKS ARCHIVES OF SURGERY"},
     {"name":"RESOURCE AND ENERGY ECONOMICS"},
     {"name":"AUSTRALIAN AND NEW ZEALAND JOURNAL OF PUBLIC HEALTH"},
     {"name":"WORLD NEUROSURGERY"},
     {"name":"JOURNAL OF ENVIRONMENTAL QUALITY"},
     {"name":"INTEGRATIVE MEDICINE RESEARCH"},
     {"name":"APPLIED SPATIAL ANALYSIS AND POLICY"},
     {"name":"JOURNAL OF COGNITION AND DEVELOPMENT"},
     {"name":"JOURNAL OF MEDICINAL FOOD"},
     {"name":"MECHANISMS OF DEVELOPMENT"},
     {"name":"JOURNAL OF GASTRIC CANCER"},
     {"name":"JOURNAL OF COMPOSITE MATERIALS"},
     {"name":"BIOTECHNOLOGY LETTERS"},
     {"name":"FISHERIES MANAGEMENT AND ECOLOGY"},
     {"name":"JOURNAL OF INTERFERON AND CYTOKINE RESEARCH"},
     {"name":"JOURNAL OF CHILD NEUROLOGY"},
     {"name":"STEEL RESEARCH INTERNATIONAL"},
     {"name":"ACTAS ESPANOLAS DE PSIQUIATRIA"},
     {"name":"JOURNAL OF MATERIALS IN CIVIL ENGINEERING"},
     {"name":"PERITONEAL DIALYSIS INTERNATIONAL"},
     {"name":"TECHNOMETRICS"},
     {"name":"POLITICAL SCIENCE RESEARCH AND METHODS"},
     {"name":"INTERNATIONAL JOURNAL OF ACCOUNTING INFORMATION SYSTEMS"},
     {"name":"BULLETIN OF ENVIRONMENTAL CONTAMINATION AND TOXICOLOGY"},
     {"name":"JOURNAL OF EXPERIMENTAL NANOSCIENCE"},
     {"name":"BIOSYSTEMS"},
     {"name":"JOURNAL OF KNEE SURGERY"},
     {"name":"BOTANICAL STUDIES"},
     {"name":"SIAM JOURNAL ON CONTROL AND OPTIMIZATION"},
     {"name":"JOURNAL OF YOUTH STUDIES"},
     {"name":"PHARMACEUTICAL DEVELOPMENT AND TECHNOLOGY"},
     {"name":"XENOBIOTICA"},
     {"name":"EUROPEAN JOURNAL OF MARKETING"},
     {"name":"KAOHSIUNG JOURNAL OF MEDICAL SCIENCES"},
     {"name":"COMPUTATIONAL BIOLOGY AND CHEMISTRY"},
     {"name":"MOLECULAR AND CELLULAR PROBES"},
     {"name":"BMC MUSCULOSKELETAL DISORDERS"},
     {"name":"BIRTH DEFECTS RESEARCH"},
     {"name":"AQUATIC BOTANY"},
     {"name":"CARDIOLOGY"},
     {"name":"JOURNAL OF NEUROSCIENCE METHODS"},
     {"name":"MICROGRAVITY SCIENCE AND TECHNOLOGY"},
     {"name":"TEACHING AND LEARNING IN MEDICINE"},
     {"name":"JOURNAL OF SOIL SCIENCE AND PLANT NUTRITION"},
     {"name":"FINANCIAL MANAGEMENT"},
     {"name":"CANADIAN JOURNAL OF CHEMICAL ENGINEERING"},
     {"name":"CHINESE JOURNAL OF AERONAUTICS"},
     {"name":"JOURNAL OF AMERICAN COLLEGE HEALTH"},
     {"name":"JOURNAL OF THE METEOROLOGICAL SOCIETY OF JAPAN"},
     {"name":"FEMS MICROBIOLOGY LETTERS"},
     {"name":"ACM TRANSACTIONS ON SOFTWARE ENGINEERING AND METHODOLOGY"},
     {"name":"EXPERT REVIEW OF MEDICAL DEVICES"},
     {"name":"POSTGRADUATE MEDICAL JOURNAL"},
     {"name":"INTERACTIVE LEARNING ENVIRONMENTS"},
     {"name":"APHASIOLOGY"},
     {"name":"TOXICOLOGY AND INDUSTRIAL HEALTH"},
     {"name":"BLOOD PURIFICATION"},
     {"name":"AGRICULTURAL AND FOREST ENTOMOLOGY"},
     {"name":"BULLETIN OF VOLCANOLOGY"},
     {"name":"ENERGY EFFICIENCY"},
     {"name":"ACM TRANSACTIONS ON AUTONOMOUS AND ADAPTIVE SYSTEMS"},
     {"name":"JOURNAL OF THE AMERICAN ACADEMY OF AUDIOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF MENTAL HEALTH SYSTEMS"},
     {"name":"RECALL"},
     {"name":"INTERNATIONAL COMMUNICATION GAZETTE"},
     {"name":"RESEARCH IN ENGINEERING DESIGN"},
     {"name":"SOCIAL POLICY AND SOCIETY"},
     {"name":"WIND AND STRUCTURES"},
     {"name":"JOURNAL OF TERRAMECHANICS"},
     {"name":"BIRD CONSERVATION INTERNATIONAL"},
     {"name":"HERPETOLOGICAL MONOGRAPHS"},
     {"name":"JOURNAL OF ZOOLOGICAL SYSTEMATICS AND EVOLUTIONARY RESEARCH"},
     {"name":"JOURNAL OF HEALTH POPULATION AND NUTRITION"},
     {"name":"BIOLOGY OPEN"},
     {"name":"JOURNAL OF CLINICAL NEUROSCIENCE"},
     {"name":"BMC ECOLOGY"},
     {"name":"ARCHIVES OF GERONTOLOGY AND GERIATRICS"},
     {"name":"JOURNAL OF WINE ECONOMICS"},
     {"name":"SLAS TECHNOLOGY"},
     {"name":"HEAT AND MASS TRANSFER"},
     {"name":"JOURNAL OF PLANT RESEARCH"},
     {"name":"OPERATIVE DENTISTRY"},
     {"name":"BMC SPORTS SCIENCE MEDICINE AND REHABILITATION"},
     {"name":"JOURNAL OF GLOBAL HISTORY"},
     {"name":"SEMINARS IN RESPIRATORY AND CRITICAL CARE MEDICINE"},
     {"name":"BIOLOGICAL RESEARCH FOR NURSING"},
     {"name":"COMPUTATIONAL AND MATHEMATICAL METHODS IN MEDICINE"},
     {"name":"WATER RESOURCES AND ECONOMICS"},
     {"name":"CHINESE OPTICS LETTERS"},
     {"name":"INTERNATIONAL REVIEW OF ADMINISTRATIVE SCIENCES"},
     {"name":"CELLULAR REPROGRAMMING"},
     {"name":"YALE JOURNAL ON REGULATION"},
     {"name":"JOURNAL OF HUMAN LACTATION"},
     {"name":"CARDIOLOGY CLINICS"},
     {"name":"CURRENT EYE RESEARCH"},
     {"name":"LASER PHYSICS LETTERS"},
     {"name":"JOURNAL OF COMMUNICATIONS AND NETWORKS"},
     {"name":"ORGANIZATIONAL DYNAMICS"},
     {"name":"JOURNAL OF MOLECULAR EVOLUTION"},
     {"name":"JOURNAL OF ENGINEERING DESIGN"},
     {"name":"COASTAL ENGINEERING JOURNAL"},
     {"name":"JOURNAL OF AVIAN BIOLOGY"},
     {"name":"PROTIST"},
     {"name":"WILDLIFE MONOGRAPHS"},
     {"name":"EUROPEAN JOURNAL OF LIPID SCIENCE AND TECHNOLOGY"},
     {"name":"REVISTA DE SAUDE PUBLICA"},
     {"name":"JOURNAL OF INTERNATIONAL ECONOMIC LAW"},
     {"name":"JOURNAL OF ARID LAND"},
     {"name":"FIBERS AND POLYMERS"},
     {"name":"INTERNATIONAL JOURNAL OF POLYMER ANALYSIS AND CHARACTERIZATION"},
     {"name":"THERAPEUTIC DRUG MONITORING"},
     {"name":"MATHEMATICS AND MECHANICS OF SOLIDS"},
     {"name":"JOURNAL OF INTERNATIONAL RELATIONS AND DEVELOPMENT"},
     {"name":"BMC ORAL HEALTH"},
     {"name":"SCIENCE AS CULTURE"},
     {"name":"TEACHER EDUCATION AND SPECIAL EDUCATION"},
     {"name":"IET SIGNAL PROCESSING"},
     {"name":"PALAIOS"},
     {"name":"CHILD AND ADOLESCENT PSYCHIATRY AND MENTAL HEALTH"},
     {"name":"ANNALS OF APPLIED BIOLOGY"},
     {"name":"BIOCATALYSIS AND BIOTRANSFORMATION"},
     {"name":"BREAST CARE"},
     {"name":"JOURNAL OF STORED PRODUCTS RESEARCH"},
     {"name":"EATING DISORDERS"},
     {"name":"JOURNAL OF NONLINEAR SCIENCE"},
     {"name":"ORTHOPAEDIC SURGERY"},
     {"name":"MODERN RHEUMATOLOGY"},
     {"name":"INVERSE PROBLEMS"},
     {"name":"CLINICS IN PLASTIC SURGERY"},
     {"name":"MEASUREMENT IN PHYSICAL EDUCATION AND EXERCISE SCIENCE"},
     {"name":"JOURNAL OF FLUORESCENCE"},
     {"name":"JOURNAL OF MEDICAL VIROLOGY"},
     {"name":"JOURNAL OF CONSUMER AFFAIRS"},
     {"name":"AMERICAN JOURNAL OF MATHEMATICS"},
     {"name":"MARINE AND COASTAL FISHERIES"},
     {"name":"PLANT DIVERSITY"},
     {"name":"JOURNAL OF TRAUMATIC STRESS"},
     {"name":"JOURNAL OF GEODYNAMICS"},
     {"name":"PREVENTING CHRONIC DISEASE"},
     {"name":"CYTOTECHNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF OBSTETRIC ANESTHESIA"},
     {"name":"CLINICAL AND EXPERIMENTAL OPTOMETRY"},
     {"name":"VETERINARY JOURNAL"},
     {"name":"JOURNAL OF EARLY CHILDHOOD LITERACY"},
     {"name":"MICRON"},
     {"name":"VETERINARY AND COMPARATIVE ONCOLOGY"},
     {"name":"MASS COMMUNICATION AND SOCIETY"},
     {"name":"INTERNATIONAL REGIONAL SCIENCE REVIEW"},
     {"name":"MACROMOLECULAR RESEARCH"},
     {"name":"ECOLOGICAL PSYCHOLOGY"},
     {"name":"FOLIA HORTICULTURAE"},
     {"name":"JOURNAL OF CARDIOVASCULAR NURSING"},
     {"name":"MANAGEMENT INTERNATIONAL REVIEW"},
     {"name":"JOURNAL OF PEDIATRIC ORTHOPAEDICS"},
     {"name":"QUALITATIVE RESEARCH IN PSYCHOLOGY"},
     {"name":"FISHERIES OCEANOGRAPHY"},
     {"name":"JOURNAL OF VISION"},
     {"name":"INTERNATIONAL JOURNAL OF MASS SPECTROMETRY"},
     {"name":"GEOINFORMATICA"},
     {"name":"CARDIOVASCULAR PATHOLOGY"},
     {"name":"RESEARCH IN TRANSPORTATION BUSINESS AND MANAGEMENT"},
     {"name":"EUROPEAN JOURNAL OF HAEMATOLOGY"},
     {"name":"MEDICAL AND VETERINARY ENTOMOLOGY"},
     {"name":"ETHNIC AND RACIAL STUDIES"},
     {"name":"JOURNAL OF GLOBAL OPTIMIZATION"},
     {"name":"PRIMARY CARE"},
     {"name":"CYTOSKELETON"},
     {"name":"COMPUTATIONAL GEOSCIENCES"},
     {"name":"CORNELL LAW REVIEW"},
     {"name":"INTERNATIONAL JOURNAL OF CRITICAL INFRASTRUCTURE PROTECTION"},
     {"name":"ECONOMICS LETTERS"},
     {"name":"BIOPHYSICAL CHEMISTRY"},
     {"name":"NANOETHICS"},
     {"name":"BIOMETRICS"},
     {"name":"SOCIETY AND MENTAL HEALTH"},
     {"name":"JOURNAL OF BIOLOGICAL DYNAMICS"},
     {"name":"TRANSACTIONS OF THE ROYAL SOCIETY OF TROPICAL MEDICINE AND HYGIENE"},
     {"name":"RAPID COMMUNICATIONS IN MASS SPECTROMETRY"},
     {"name":"GROUNDWATER"},
     {"name":"CLINICAL AND EXPERIMENTAL NEPHROLOGY"},
     {"name":"ADVANCES IN GENETICS"},
     {"name":"BRAZILIAN JOURNAL OF PHYSICAL THERAPY"},
     {"name":"COMBUSTION THEORY AND MODELLING"},
     {"name":"FORESTS"},
     {"name":"PHYTOPATHOLOGIA MEDITERRANEA"},
     {"name":"BREEDING SCIENCE"},
     {"name":"PHYSICIAN AND SPORTSMEDICINE"},
     {"name":"FRONTIERS OF HORMONE RESEARCH"},
     {"name":"CELLS TISSUES ORGANS"},
     {"name":"LANDSCAPE RESEARCH"},
     {"name":"BEHAVIORAL MEDICINE"},
     {"name":"JOURNAL OF THORACIC IMAGING"},
     {"name":"FEMINIST LEGAL STUDIES"},
     {"name":"REVISTA ESPANOLA DE ENFERMEDADES DIGESTIVAS"},
     {"name":"INTERNATIONAL JOURNAL OF IMPLANT DENTISTRY"},
     {"name":"APPLIED BIOLOGICAL CHEMISTRY"},
     {"name":"JOURNAL OF THORACIC DISEASE"},
     {"name":"URBAN WATER JOURNAL"},
     {"name":"JORNAL BRASILEIRO DE PNEUMOLOGIA"},
     {"name":"JOURNAL OF PROFESSIONAL NURSING"},
     {"name":"TETRAHEDRON"},
     {"name":"VETERINARY PARASITOLOGY"},
     {"name":"ANNALS OF FOREST SCIENCE"},
     {"name":"AUGMENTATIVE AND ALTERNATIVE COMMUNICATION"},
     {"name":"INTERNATIONAL JOURNAL OF ENVIRONMENTAL HEALTH RESEARCH"},
     {"name":"SEXUAL MEDICINE"},
     {"name":"DENTOMAXILLOFACIAL RADIOLOGY"},
     {"name":"EVOLUTIONARY BIOLOGY"},
     {"name":"COMPUTATIONAL OPTIMIZATION AND APPLICATIONS"},
     {"name":"ANNALS OF APPLIED STATISTICS"},
     {"name":"BMC MEDICAL EDUCATION"},
     {"name":"ELECTRONIC JOURNAL OF QUALITATIVE THEORY OF DIFFERENTIAL EQUATIONS"},
     {"name":"JOURNAL OF PORPHYRINS AND PHTHALOCYANINES"},
     {"name":"MICROSURGERY"},
     {"name":"CURRENT MICROBIOLOGY"},
     {"name":"BEHAVIOURAL NEUROLOGY"},
     {"name":"CELL BIOCHEMISTRY AND BIOPHYSICS"},
     {"name":"JOURNAL OF ECONOMIC PSYCHOLOGY"},
     {"name":"COMPUTATIONAL PARTICLE MECHANICS"},
     {"name":"JOURNAL OF APPLIED GEOPHYSICS"},
     {"name":"JOURNAL OF ONCOLOGY"},
     {"name":"RADIOLOGIC CLINICS OF NORTH AMERICA"},
     {"name":"JOURNAL OF PUBLIC HEALTH"},
     {"name":"AMERICAN JOURNAL OF NURSING"},
     {"name":"AMERICAN HEALTH AND DRUG BENEFITS"},
     {"name":"CLINICAL AND EXPERIMENTAL HYPERTENSION"},
     {"name":"ANALYSIS AND MATHEMATICAL PHYSICS"},
     {"name":"BEHAVIOR GENETICS"},
     {"name":"IEEE TRANSACTIONS ON SEMICONDUCTOR MANUFACTURING"},
     {"name":"JOURNAL OF BRIDGE ENGINEERING"},
     {"name":"ITALIAN JOURNAL OF PEDIATRICS"},
     {"name":"MOTIVATION AND EMOTION"},
     {"name":"JOURNAL OF GENETIC COUNSELING"},
     {"name":"ACTA PALAEONTOLOGICA POLONICA"},
     {"name":"PLANT BIOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF MODERN PHYSICS D"},
     {"name":"STUDIES IN EDUCATIONAL EVALUATION"},
     {"name":"SCANDINAVIAN JOURNAL OF SURGERY"},
     {"name":"DEMOCRATIZATION"},
     {"name":"JOURNAL OF PEPTIDE SCIENCE"},
     {"name":"PROGRESS OF THEORETICAL AND EXPERIMENTAL PHYSICS"},
     {"name":"INTERNATIONAL JOURNAL OF ANALYTICAL CHEMISTRY"},
     {"name":"CLINICAL SIMULATION IN NURSING"},
     {"name":"BMC PALLIATIVE CARE"},
     {"name":"SIGNAL IMAGE AND VIDEO PROCESSING"},
     {"name":"THEORETICAL AND COMPUTATIONAL FLUID DYNAMICS"},
     {"name":"JOURNAL OF POROUS MATERIALS"},
     {"name":"FAMILY PRACTICE"},
     {"name":"ANNALS OF WORK EXPOSURES AND HEALTH"},
     {"name":"JOURNAL OF APICULTURAL RESEARCH"},
     {"name":"JOURNAL OF REPRODUCTION AND DEVELOPMENT"},
     {"name":"MULTIDIMENSIONAL SYSTEMS AND SIGNAL PROCESSING"},
     {"name":"ANIMAL REPRODUCTION SCIENCE"},
     {"name":"DISPLAYS"},
     {"name":"INTERNATIONAL JOURNAL OF CONFLICT MANAGEMENT"},
     {"name":"PHYSICS OF THE EARTH AND PLANETARY INTERIORS"},
     {"name":"NUCLEAR PHYSICS A"},
     {"name":"JOURNAL OF ENGINEERING MECHANICS"},
     {"name":"APPLIED NUMERICAL MATHEMATICS"},
     {"name":"JOURNAL OF EDUCATIONAL AND BEHAVIORAL STATISTICS"},
     {"name":"OPTICS COMMUNICATIONS"},
     {"name":"METALS"},
     {"name":"PROBABILITY THEORY AND RELATED FIELDS"},
     {"name":"CHINA PERSPECTIVES"},
     {"name":"JOURNAL OF HIP PRESERVATION SURGERY"},
     {"name":"PHOTONIC NETWORK COMMUNICATIONS"},
     {"name":"FUSION ENGINEERING AND DESIGN"},
     {"name":"AQUACULTURE ENVIRONMENT INTERACTIONS"},
     {"name":"JOURNAL OF INFRARED MILLIMETER AND TERAHERTZ WAVES"},
     {"name":"RESEARCH IN VETERINARY SCIENCE"},
     {"name":"JOURNAL OF FAMILY NURSING"},
     {"name":"PATHOLOGY RESEARCH AND PRACTICE"},
     {"name":"PSYCHOGERIATRICS"},
     {"name":"ALCOHOL AND ALCOHOLISM"},
     {"name":"JOURNAL OF INTELLIGENT INFORMATION SYSTEMS"},
     {"name":"INTERNATIONAL JOURNAL OF STEM EDUCATION"},
     {"name":"MATHEMATICS"},
     {"name":"INTERNATIONAL JOURNAL OF MANAGING PROJECTS IN BUSINESS"},
     {"name":"AMERICAN JOURNAL OF MANAGED CARE"},
     {"name":"MEDITERRANEAN MARINE SCIENCE"},
     {"name":"PSYCHOPATHOLOGY"},
     {"name":"DUKE MATHEMATICAL JOURNAL"},
     {"name":"PSYCHOLOGY AND AGING"},
     {"name":"OPTIK"},
     {"name":"MECCANICA"},
     {"name":"GEOGRAPHICAL REVIEW"},
     {"name":"BIOCHEMICAL GENETICS"},
     {"name":"AMERICAN JOURNAL OF CULTURAL SOCIOLOGY"},
     {"name":"KARDIOLOGIA POLSKA"},
     {"name":"FETAL DIAGNOSIS AND THERAPY"},
     {"name":"JOURNAL OF BEHAVIORAL DECISION MAKING"},
     {"name":"MOLECULAR BREEDING"},
     {"name":"JOURNAL OF APPLIED CLINICAL MEDICAL PHYSICS"},
     {"name":"PEDIATRICS AND NEONATOLOGY"},
     {"name":"STEROIDS"},
     {"name":"REQUIREMENTS ENGINEERING"},
     {"name":"WORLDS POULTRY SCIENCE JOURNAL"},
     {"name":"PHYSICS AND CHEMISTRY OF LIQUIDS"},
     {"name":"ENVIRONMENTAL EARTH SCIENCES"},
     {"name":"PROGRESS IN BRAIN RESEARCH"},
     {"name":"MENTAL HEALTH AND PHYSICAL ACTIVITY"},
     {"name":"CHILD CARE HEALTH AND DEVELOPMENT"},
     {"name":"JOURNAL OF FORESTRY RESEARCH"},
     {"name":"ARABIAN JOURNAL FOR SCIENCE AND ENGINEERING"},
     {"name":"ADMINISTRATIVE LAW REVIEW"},
     {"name":"RESEARCH IN DEVELOPMENTAL DISABILITIES"},
     {"name":"JOURNAL OF EARLY ADOLESCENCE"},
     {"name":"MOLECULAR BIOTECHNOLOGY"},
     {"name":"BMC FAMILY PRACTICE"},
     {"name":"JOURNAL OF PERSONALITY ASSESSMENT"},
     {"name":"JOURNAL OF VOICE"},
     {"name":"HYPERTENSION IN PREGNANCY"},
     {"name":"VIRAL IMMUNOLOGY"},
     {"name":"IEEE EMBEDDED SYSTEMS LETTERS"},
     {"name":"BMC NEPHROLOGY"},
     {"name":"ACTA PAEDIATRICA"},
     {"name":"JOURNAL OF ADVANCED TRANSPORTATION"},
     {"name":"CURRENT NANOSCIENCE"},
     {"name":"EUROPEAN BIOPHYSICS JOURNAL WITH BIOPHYSICS LETTERS"},
     {"name":"PARKINSONS DISEASE"},
     {"name":"APPLIED CLINICAL INFORMATICS"},
     {"name":"GEOGRAPHICAL ANALYSIS"},
     {"name":"REPRODUCTIVE HEALTH"},
     {"name":"BIOMEDICAL ENGINEERING ONLINE"},
     {"name":"INTERNATIONAL JOURNAL OF ADAPTIVE CONTROL AND SIGNAL PROCESSING"},
     {"name":"AMERICAN JOURNAL OF CLINICAL PATHOLOGY"},
     {"name":"THERAPEUTIC ADVANCES IN UROLOGY"},
     {"name":"RESEARCH ON AGING"},
     {"name":"JOURNAL OF SOCIAL MARKETING"},
     {"name":"POLAR BIOLOGY"},
     {"name":"CARBOHYDRATE RESEARCH"},
     {"name":"NEUROUROLOGY AND URODYNAMICS"},
     {"name":"AMERICAN JOURNAL OF PRIMATOLOGY"},
     {"name":"JOURNAL OF TAIBAH UNIVERSITY FOR SCIENCE"},
     {"name":"JOURNAL OF COMPARATIVE ECONOMICS"},
     {"name":"CRIME MEDIA CULTURE"},
     {"name":"JOURNAL OF SOUTH AMERICAN EARTH SCIENCES"},
     {"name":"AMERICAN REVIEW OF PUBLIC ADMINISTRATION"},
     {"name":"POPULATION HEALTH MANAGEMENT"},
     {"name":"CANADIAN JOURNAL OF MICROBIOLOGY"},
     {"name":"AUTOMATED SOFTWARE ENGINEERING"},
     {"name":"SOUTH AFRICAN JOURNAL OF BOTANY"},
     {"name":"SUPERLATTICES AND MICROSTRUCTURES"},
     {"name":"ATMOSPHERIC SCIENCE LETTERS"},
     {"name":"CANCER EPIDEMIOLOGY"},
     {"name":"EUROPEAN JOURNAL OF MEDICAL RESEARCH"},
     {"name":"JOURNAL OF REGIONAL SCIENCE"},
     {"name":"PATHOBIOLOGY"},
     {"name":"CONGENITAL ANOMALIES"},
     {"name":"RESEARCH IN INTERNATIONAL BUSINESS AND FINANCE"},
     {"name":"JOURNAL OF MEDICAL ECONOMICS"},
     {"name":"BMC VETERINARY RESEARCH"},
     {"name":"JOURNAL OF ELECTRONIC MATERIALS"},
     {"name":"EUROPEAN JOURNAL OF ENVIRONMENTAL AND CIVIL ENGINEERING"},
     {"name":"ACUPUNCTURE IN MEDICINE"},
     {"name":"ENVIRONMENTAL ENGINEERING SCIENCE"},
     {"name":"FORENSIC SCIENCE INTERNATIONAL"},
     {"name":"INTERNATIONAL JOURNAL OF DEVELOPMENTAL NEUROSCIENCE"},
     {"name":"AMERICAN JOURNAL OF ORTHOPSYCHIATRY"},
     {"name":"INVESTIGATIVE AND CLINICAL UROLOGY"},
     {"name":"QUARTERLY JOURNAL OF ENGINEERING GEOLOGY AND HYDROGEOLOGY"},
     {"name":"GENOME"},
     {"name":"FINANCE AND STOCHASTICS"},
     {"name":"EUROPEAN JOURNAL OF EMERGENCY MEDICINE"},
     {"name":"INTERNATIONAL JOURNAL OF ORAL AND MAXILLOFACIAL SURGERY"},
     {"name":"OPERATIONS MANAGEMENT RESEARCH"},
     {"name":"PLANT SPECIES BIOLOGY"},
     {"name":"DISSERTATIONES MATHEMATICAE"},
     {"name":"JOURNAL OF ATMOSPHERIC AND OCEANIC TECHNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF PRIMATOLOGY"},
     {"name":"ECOHEALTH"},
     {"name":"RESEARCH IN AUTISM SPECTRUM DISORDERS"},
     {"name":"ACTA PHYSIOLOGIAE PLANTARUM"},
     {"name":"JOURNAL OF INTERVENTIONAL CARDIOLOGY"},
     {"name":"EAST ASIAN JOURNAL ON APPLIED MATHEMATICS"},
     {"name":"JOURNAL OF ADVERTISING RESEARCH"},
     {"name":"SOCIOLOGY OF RACE AND ETHNICITY"},
     {"name":"IEEE TRANSACTIONS ON NANOTECHNOLOGY"},
     {"name":"JOURNAL OF THE AMERICAN OIL CHEMISTS SOCIETY"},
     {"name":"MIDWIFERY"},
     {"name":"APPLIED ANIMAL BEHAVIOUR SCIENCE"},
     {"name":"BEHAVIORAL AND BRAIN FUNCTIONS"},
     {"name":"JOURNAL OF ANATOMY"},
     {"name":"CROP SCIENCE"},
     {"name":"ARCHIVES OF ORAL BIOLOGY"},
     {"name":"LIMNOLOGICA"},
     {"name":"CATALYSIS SURVEYS FROM ASIA"},
     {"name":"COGNITIVE SCIENCE"},
     {"name":"NUMERICAL METHODS FOR PARTIAL DIFFERENTIAL EQUATIONS"},
     {"name":"MOLECULAR PHYSICS"},
     {"name":"RENAL FAILURE"},
     {"name":"BIOLOGICALS"},
     {"name":"DIABETES EDUCATOR"},
     {"name":"IBIS"},
     {"name":"JOURNAL OF POPULATION ECONOMICS"},
     {"name":"INTERNATIONAL JOURNAL OF AGRICULTURAL AND BIOLOGICAL ENGINEERING"},
     {"name":"NUCLEAR MATERIALS AND ENERGY"},
     {"name":"CHINA COMMUNICATIONS"},
     {"name":"BRITISH JOURNAL OF SOCIOLOGY OF EDUCATION"},
     {"name":"GLAND SURGERY"},
     {"name":"JOURNAL OF MICROBIOLOGY AND BIOTECHNOLOGY"},
     {"name":"JOURNAL OF DIFFERENTIAL GEOMETRY"},
     {"name":"SOFTWARE AND SYSTEMS MODELING"},
     {"name":"JOURNAL OF INTERPROFESSIONAL CARE"},
     {"name":"INTERNATIONAL JOURNAL OF PHOTOENERGY"},
     {"name":"INTERNATIONAL UROLOGY AND NEPHROLOGY"},
     {"name":"JOURNAL OF SOIL AND WATER CONSERVATION"},
     {"name":"INTERNATIONAL JOURNAL OF POLYMERIC MATERIALS AND POLYMERIC BIOMATERIALS"},
     {"name":"INTERNATIONAL JOURNAL OF SYSTEMS SCIENCE"},
     {"name":"PHLEBOLOGY"},
     {"name":"RESEARCH IN THE TEACHING OF ENGLISH"},
     {"name":"PHYSICAL BIOLOGY"},
     {"name":"FAMILY PROCESS"},
     {"name":"STRUCTURAL CHANGE AND ECONOMIC DYNAMICS"},
     {"name":"CLINICAL MEDICINE"},
     {"name":"PHOTONICS"},
     {"name":"ARCHIVES OF MEDICAL RESEARCH"},
     {"name":"TURKISH JOURNAL OF HEMATOLOGY"},
     {"name":"JOURNAL OF ANALYTICAL SCIENCE AND TECHNOLOGY"},
     {"name":"LETHAIA"},
     {"name":"TEACHING IN HIGHER EDUCATION"},
     {"name":"JOURNAL OF MATHEMATICAL CHEMISTRY"},
     {"name":"SEMINARS IN VASCULAR SURGERY"},
     {"name":"CHINESE JOURNAL OF INTERNATIONAL POLITICS"},
     {"name":"COMPUTER SPEECH AND LANGUAGE"},
     {"name":"JOURNAL OF ENGLISH FOR ACADEMIC PURPOSES"},
     {"name":"ENTERPRISE INFORMATION SYSTEMS"},
     {"name":"ARCHIVES OF CONTROL SCIENCES"},
     {"name":"EUROPEAN JOURNAL OF INTERNATIONAL MANAGEMENT"},
     {"name":"INTERNATIONAL JOURNAL OF INTERCULTURAL RELATIONS"},
     {"name":"SEPARATIONS"},
     {"name":"CHEMISTRY EDUCATION RESEARCH AND PRACTICE"},
     {"name":"RADIOLOGIA MEDICA"},
     {"name":"INTERNATIONAL JOURNAL OF STRUCTURAL STABILITY AND DYNAMICS"},
     {"name":"MUSICAE SCIENTIAE"},
     {"name":"NATURAL PRODUCT RESEARCH"},
     {"name":"JOURNAL OF ASIAN ECONOMICS"},
     {"name":"INTERNATIONAL JOURNAL OF ENVIRONMENTAL RESEARCH"},
     {"name":"ANNALS OF PALLIATIVE MEDICINE"},
     {"name":"ANNALS OF REGIONAL SCIENCE"},
     {"name":"JOURNAL OF HYDRAULIC ENGINEERING"},
     {"name":"IEEE TRANSACTIONS ON GAMES"},
     {"name":"AMERICAN JOURNAL OF INDUSTRIAL MEDICINE"},
     {"name":"IET RADAR SONAR AND NAVIGATION"},
     {"name":"MEDITERRANEAN POLITICS"},
     {"name":"OPTIMIZATION AND ENGINEERING"},
     {"name":"ONCOLOGY RESEARCH AND TREATMENT"},
     {"name":"JOURNAL OF MASS SPECTROMETRY"},
     {"name":"BREASTFEEDING MEDICINE"},
     {"name":"AESTHETIC PLASTIC SURGERY"},
     {"name":"TELECOMMUNICATION SYSTEMS"},
     {"name":"ZEBRAFISH"},
     {"name":"BULLETIN OF THE PEABODY MUSEUM OF NATURAL HISTORY"},
     {"name":"ACTA NEUROLOGICA BELGICA"},
     {"name":"INDUSTRIAL AND CORPORATE CHANGE"},
     {"name":"EUROPEAN ACCOUNTING REVIEW"},
     {"name":"ERGONOMICS"},
     {"name":"JOURNAL OF SULFUR CHEMISTRY"},
     {"name":"EUROPEAN JOURNAL OF MINERALOGY"},
     {"name":"INTERNATIONAL JOURNAL OF MULTILINGUALISM"},
     {"name":"TERRITORY POLITICS GOVERNANCE"},
     {"name":"JOURNAL OF VETERINARY INTERNAL MEDICINE"},
     {"name":"INTERNATIONAL JOURNAL OF CHEMICAL ENGINEERING"},
     {"name":"INTERNATIONAL JOURNAL OF IMMUNOPATHOLOGY AND PHARMACOLOGY"},
     {"name":"CREATIVITY AND INNOVATION MANAGEMENT"},
     {"name":"GOLD BULLETIN"},
     {"name":"IET IMAGE PROCESSING"},
     {"name":"GENETIC EPIDEMIOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF IMAGING SYSTEMS AND TECHNOLOGY"},
     {"name":"MINERALOGICAL MAGAZINE"},
     {"name":"INTERNATIONAL JOURNAL OF AUDIOLOGY"},
     {"name":"INDOOR AND BUILT ENVIRONMENT"},
     {"name":"JOURNAL OF RADIATION RESEARCH"},
     {"name":"DISCOVERY MEDICINE"},
     {"name":"JOURNAL OF MANAGEMENT INQUIRY"},
     {"name":"JOURNAL OF ANIMAL SCIENCE AND TECHNOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF HEAT AND FLUID FLOW"},
     {"name":"OECONOMIA COPERNICANA"},
     {"name":"SLAS DISCOVERY"},
     {"name":"HYDROLOGY RESEARCH"},
     {"name":"ACTA MECHANICA"},
     {"name":"SEXUAL DEVELOPMENT"},
     {"name":"SELF AND IDENTITY"},
     {"name":"CANADIAN WATER RESOURCES JOURNAL"},
     {"name":"TRANSFUSION MEDICINE"},
     {"name":"MARINE GENOMICS"},
     {"name":"INTERNATIONAL JOURNAL OF APPLIED GLASS SCIENCE"},
     {"name":"PERSONNEL REVIEW"},
     {"name":"CHEMICAL PHYSICS LETTERS"},
     {"name":"INHALATION TOXICOLOGY"},
     {"name":"JOURNAL OF HYDRAULIC RESEARCH"},
     {"name":"BRITISH EDUCATIONAL RESEARCH JOURNAL"},
     {"name":"JOURNAL OF NANOPARTICLE RESEARCH"},
     {"name":"STRUCTURAL CONCRETE"},
     {"name":"JOURNAL OF RAMAN SPECTROSCOPY"},
     {"name":"JOURNAL OF THE ACM"},
     {"name":"CULTURAL TRENDS"},
     {"name":"GEOMICROBIOLOGY JOURNAL"},
     {"name":"AIDS RESEARCH AND HUMAN RETROVIRUSES"},
     {"name":"INTERNAL MEDICINE JOURNAL"},
     {"name":"EDUCATION AND TRAINING"},
     {"name":"COMMUNICATIONS IN MATHEMATICAL PHYSICS"},
     {"name":"JOURNAL OF ANIMAL SCIENCE"},
     {"name":"INTERNATIONAL JOURNAL OF COMPUTATIONAL METHODS"},
     {"name":"JOURNAL OF HOMOSEXUALITY"},
     {"name":"CLINICAL PHYSIOLOGY AND FUNCTIONAL IMAGING"},
     {"name":"JAPANESE JOURNAL OF OPHTHALMOLOGY"},
     {"name":"ACTA MECHANICA SOLIDA SINICA"},
     {"name":"NATURAL HAZARDS REVIEW"},
     {"name":"RIVISTA ITALIANA DI PALEONTOLOGIA E STRATIGRAFIA"},
     {"name":"JOURNAL OF GERIATRIC PSYCHIATRY AND NEUROLOGY"},
     {"name":"JOURNAL OF MEDICAL SCREENING"},
     {"name":"SCIENCE OF NATURE"},
     {"name":"MATERIALS TECHNOLOGY"},
     {"name":"EXPERIMENTAL ECONOMICS"},
     {"name":"SERVICE BUSINESS"},
     {"name":"CHEMICAL ENGINEERING COMMUNICATIONS"},
     {"name":"JOURNAL OF HOSPITAL MEDICINE"},
     {"name":"PHYSICA SCRIPTA"},
     {"name":"HEALTH PROMOTION INTERNATIONAL"},
     {"name":"INTERNATIONAL JOURNAL OF STEM CELLS"},
     {"name":"JOURNAL OF BUON"},
     {"name":"MATHEMATICS OF COMPUTATION"},
     {"name":"JOURNAL OF ELECTROCHEMICAL ENERGY CONVERSION AND STORAGE"},
     {"name":"MINDS AND MACHINES"},
     {"name":"JOURNAL OF AGRARIAN CHANGE"},
     {"name":"PORCINE HEALTH MANAGEMENT"},
     {"name":"MOLECULAR GENETICS AND METABOLISM REPORTS"},
     {"name":"EUROPEAN SECURITY"},
     {"name":"CLINICAL NEUROPSYCHOLOGIST"},
     {"name":"INTERNATIONAL JOURNAL OF PSYCHIATRY IN CLINICAL PRACTICE"},
     {"name":"ASIAN PACIFIC JOURNAL OF TROPICAL BIOMEDICINE"},
     {"name":"FACIES"},
     {"name":"DEVELOPMENTAL NEUROREHABILITATION"},
     {"name":"BMC MEDICAL IMAGING"},
     {"name":"JOURNAL OF RECONSTRUCTIVE MICROSURGERY"},
     {"name":"OPHTHALMIC RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF GENERAL MEDICINE"},
     {"name":"PSYCHIATRY RESEARCH"},
     {"name":"JOURNAL OF EMOTIONAL AND BEHAVIORAL DISORDERS"},
     {"name":"INTERNATIONAL JOURNAL FOR QUALITY IN HEALTH CARE"},
     {"name":"EUROPEAN JOURNAL OF CRIMINOLOGY"},
     {"name":"JOURNAL OF ASTHMA"},
     {"name":"JOURNAL OF HERBAL MEDICINE"},
     {"name":"BORSA ISTANBUL REVIEW"},
     {"name":"BULLETIN OF MATHEMATICAL BIOLOGY"},
     {"name":"JOURNAL OF CHOICE MODELLING"},
     {"name":"SOCIAL SCIENCE RESEARCH"},
     {"name":"ECONOMIC BOTANY"},
     {"name":"SIMULATION MODELLING PRACTICE AND THEORY"},
     {"name":"AMERICAN JOURNAL OF OCCUPATIONAL THERAPY"},
     {"name":"JOURNAL OF THROMBOSIS AND THROMBOLYSIS"},
     {"name":"IETE TECHNICAL REVIEW"},
     {"name":"BUSINESS PROCESS MANAGEMENT JOURNAL"},
     {"name":"INSTRUCTIONAL SCIENCE"},
     {"name":"WATER AIR AND SOIL POLLUTION"},
     {"name":"INTERNATIONAL JOURNAL OF COOPERATIVE INFORMATION SYSTEMS"},
     {"name":"MICROCIRCULATION"},
     {"name":"JOURNAL OF KOREAN MEDICAL SCIENCE"},
     {"name":"ACM TRANSACTIONS ON PRIVACY AND SECURITY"},
     {"name":"EUROPEAN CYTOKINE NETWORK"},
     {"name":"COMPTES RENDUS BIOLOGIES"},
     {"name":"EARTHQUAKES AND STRUCTURES"},
     {"name":"QUALITY AND RELIABILITY ENGINEERING INTERNATIONAL"},
     {"name":"JOURNAL OF EDUCATIONAL COMPUTING RESEARCH"},
     {"name":"VIBRATIONAL SPECTROSCOPY"},
     {"name":"JOURNAL OF PHYSIOLOGICAL ANTHROPOLOGY"},
     {"name":"FLOW MEASUREMENT AND INSTRUMENTATION"},
     {"name":"LEARNING AND INDIVIDUAL DIFFERENCES"},
     {"name":"ADVANCES IN PHYSICAL ORGANIC CHEMISTRY"},
     {"name":"PETROLEUM SCIENCE"},
     {"name":"ADVANCES IN APPLIED CERAMICS"},
     {"name":"JOURNAL OF INDUSTRIAL TEXTILES"},
     {"name":"JOURNAL OF ENGINEERING AND TECHNOLOGY MANAGEMENT"},
     {"name":"CARBON MANAGEMENT"},
     {"name":"NURSING IN CRITICAL CARE"},
     {"name":"MANAGEMENT LEARNING"},
     {"name":"NUMERISCHE MATHEMATIK"},
     {"name":"DISABILITY AND REHABILITATION"},
     {"name":"TELECOMMUNICATIONS POLICY"},
     {"name":"JOURNAL OF APPLIED ECONOMETRICS"},
     {"name":"JOURNAL OF REHABILITATION MEDICINE"},
     {"name":"MRS COMMUNICATIONS"},
     {"name":"JOURNAL OF PROPULSION AND POWER"},
     {"name":"MICROSCOPY RESEARCH AND TECHNIQUE"},
     {"name":"APIDOLOGIE"},
     {"name":"CEREAL CHEMISTRY"},
     {"name":"BALTIC JOURNAL OF MANAGEMENT"},
     {"name":"INTERNATIONAL JOURNAL OF EXPERIMENTAL PATHOLOGY"},
     {"name":"JOURNAL OF EUKARYOTIC MICROBIOLOGY"},
     {"name":"ACM TRANSACTIONS ON COMPUTING EDUCATION"},
     {"name":"CHEMISTRY AND PHYSICS OF LIPIDS"},
     {"name":"SUBSTANCE ABUSE TREATMENT PREVENTION AND POLICY"},
     {"name":"EUROPEAN JOURNAL OF PSYCHOLOGICAL ASSESSMENT"},
     {"name":"DRUG DEVELOPMENT RESEARCH"},
     {"name":"MAGNETIC RESONANCE MATERIALS IN PHYSICS BIOLOGY AND MEDICINE"},
     {"name":"PERSONAL AND UBIQUITOUS COMPUTING"},
     {"name":"CHINESE JOURNAL OF NATURAL MEDICINES"},
     {"name":"DIGITAL INVESTIGATION"},
     {"name":"LOCAL ENVIRONMENT"},
     {"name":"PSYCHIATRISCHE PRAXIS"},
     {"name":"INTERNATIONAL JOURNAL OF CARDIOVASCULAR IMAGING"},
     {"name":"SURGERY TODAY"},
     {"name":"GENERAL RELATIVITY AND GRAVITATION"},
     {"name":"PLANT DIRECT"},
     {"name":"METABOLIC SYNDROME AND RELATED DISORDERS"},
     {"name":"ENERGY SUSTAINABILITY AND SOCIETY"},
     {"name":"SOCIAL WORK"},
     {"name":"IRANIAN JOURNAL OF BASIC MEDICAL SCIENCES"},
     {"name":"HOLZFORSCHUNG"},
     {"name":"TECHNOLOGICAL AND ECONOMIC DEVELOPMENT OF ECONOMY"},
     {"name":"PHYSICS OF PLASMAS"},
     {"name":"POWDER METALLURGY"},
     {"name":"PEDIATRIC INFECTIOUS DISEASE JOURNAL"},
     {"name":"INTERNATIONAL JOURNAL OF CLIMATE CHANGE STRATEGIES AND MANAGEMENT"},
     {"name":"ANNUAL REVIEW OF APPLIED LINGUISTICS"},
     {"name":"AFRICAN AFFAIRS"},
     {"name":"JOURNAL OF MEDICAL MICROBIOLOGY"},
     {"name":"JOURNAL OF COATINGS TECHNOLOGY AND RESEARCH"},
     {"name":"WORLD JOURNAL OF SURGERY"},
     {"name":"JOURNAL OF NEUROPHYSIOLOGY"},
     {"name":"CLINICS IN COLON AND RECTAL SURGERY"},
     {"name":"INTERNATIONAL JOURNAL OF EDUCATIONAL RESEARCH"},
     {"name":"PLANT PRODUCTION SCIENCE"},
     {"name":"ACM SIGCOMM COMPUTER COMMUNICATION REVIEW"},
     {"name":"JOURNAL OF PUBLIC RELATIONS RESEARCH"},
     {"name":"PHYSICAL MEDICINE AND REHABILITATION CLINICS OF NORTH AMERICA"},
     {"name":"BMC BIOCHEMISTRY"},
     {"name":"COMPUTER GRAPHICS FORUM"},
     {"name":"REVUE NEUROLOGIQUE"},
     {"name":"UROLOGY"},
     {"name":"SWISS POLITICAL SCIENCE REVIEW"},
     {"name":"CHILD AND ADOLESCENT PSYCHIATRIC CLINICS OF NORTH AMERICA"},
     {"name":"JOURNAL OF NEUROPSYCHIATRY AND CLINICAL NEUROSCIENCES"},
     {"name":"ROMANIAN REPORTS IN PHYSICS"},
     {"name":"PARASITE"},
     {"name":"QUARTERLY JOURNAL OF NUCLEAR MEDICINE AND MOLECULAR IMAGING"},
     {"name":"MOLECULAR MEDICINE REPORTS"},
     {"name":"RACE ETHNICITY AND EDUCATION"},
     {"name":"CARDIOVASCULAR AND INTERVENTIONAL RADIOLOGY"},
     {"name":"INTERNATIONAL ARCHIVES OF OCCUPATIONAL AND ENVIRONMENTAL HEALTH"},
     {"name":"REGIONAL SCIENCE AND URBAN ECONOMICS"},
     {"name":"JOURNAL OF DIGESTIVE DISEASES"},
     {"name":"LUMINESCENCE"},
     {"name":"BMC HEALTH SERVICES RESEARCH"},
     {"name":"BIOPOLYMERS"},
     {"name":"BEHAVIORAL NEUROSCIENCE"},
     {"name":"SCANDINAVIAN JOURNAL OF FOREST RESEARCH"},
     {"name":"HUMAN MOVEMENT SCIENCE"},
     {"name":"AUK"},
     {"name":"FAMILIAL CANCER"},
     {"name":"RARE METALS"},
     {"name":"AGRONOMY JOURNAL"},
     {"name":"JOURNAL OF PHARMACY AND PHARMACEUTICAL SCIENCES"},
     {"name":"PEDOBIOLOGIA"},
     {"name":"JOURNAL OF AGING AND PHYSICAL ACTIVITY"},
     {"name":"OTOLARYNGOLOGIC CLINICS OF NORTH AMERICA"},
     {"name":"APPLIED RESEARCH IN QUALITY OF LIFE"},
     {"name":"JOURNAL OF NONDESTRUCTIVE EVALUATION"},
     {"name":"TRANSCULTURAL PSYCHIATRY"},
     {"name":"CANADIAN JOURNAL OF NEUROLOGICAL SCIENCES"},
     {"name":"HEREDITARY CANCER IN CLINICAL PRACTICE"},
     {"name":"IMF ECONOMIC REVIEW"},
     {"name":"VIRUS GENES"},
     {"name":"SKIN RESEARCH AND TECHNOLOGY"},
     {"name":"ARCTIC ANTARCTIC AND ALPINE RESEARCH"},
     {"name":"JOURNAL OF FAMILY PSYCHOLOGY"},
     {"name":"JOURNAL OF COMPUTER ASSISTED LEARNING"},
     {"name":"COGNITIVE AND BEHAVIORAL PRACTICE"},
     {"name":"JOURNAL OF MARINE SCIENCE AND ENGINEERING"},
     {"name":"GLOBAL MENTAL HEALTH"},
     {"name":"INTERNATIONAL JOURNAL OF MINERALS METALLURGY AND MATERIALS"},
     {"name":"PUBLIC ADMINISTRATION"},
     {"name":"CARDIOVASCULAR ULTRASOUND"},
     {"name":"CURRENT PHARMACEUTICAL DESIGN"},
     {"name":"BMC BIOPHYSICS"},
     {"name":"PLANT BREEDING"},
     {"name":"ANNALES SCIENTIFIQUES DE L ECOLE NORMALE SUPERIEURE"},
     {"name":"INTERNATIONAL REVIEW FOR THE SOCIOLOGY OF SPORT"},
     {"name":"3 BIOTECH"},
     {"name":"CONTINUUM MECHANICS AND THERMODYNAMICS"},
     {"name":"WRITTEN COMMUNICATION"},
     {"name":"INTERNATIONAL JOURNAL FOR NUMERICAL METHODS IN FLUIDS"},
     {"name":"ENVIRONMENTAL MONITORING AND ASSESSMENT"},
     {"name":"LEGAL AND CRIMINOLOGICAL PSYCHOLOGY"},
     {"name":"JOURNAL OF QUALITY TECHNOLOGY"},
     {"name":"BRAIN AND BEHAVIOR"},
     {"name":"JOURNAL OF INTEGRATED PEST MANAGEMENT"},
     {"name":"JOURNAL OF WOUND CARE"},
     {"name":"RADIOLOGY AND ONCOLOGY"},
     {"name":"MARINE BIOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF DERMATOLOGY"},
     {"name":"POLYCYCLIC AROMATIC COMPOUNDS"},
     {"name":"CURRENT ORGANIC SYNTHESIS"},
     {"name":"HEALTH POLICY"},
     {"name":"JOURNAL OF FOOD QUALITY"},
     {"name":"BOUNDARY VALUE PROBLEMS"},
     {"name":"CONFLICT MANAGEMENT AND PEACE SCIENCE"},
     {"name":"SEMINARS IN NEUROLOGY"},
     {"name":"FIRE TECHNOLOGY"},
     {"name":"AMERICAN HISTORICAL REVIEW"},
     {"name":"TRANSFUSION MEDICINE AND HEMOTHERAPY"},
     {"name":"UPSALA JOURNAL OF MEDICAL SCIENCES"},
     {"name":"APPLIED SPECTROSCOPY"},
     {"name":"OCULAR IMMUNOLOGY AND INFLAMMATION"},
     {"name":"BIOTECHNOLOGY AND BIOPROCESS ENGINEERING"},
     {"name":"MUSCULOSKELETAL SCIENCE AND PRACTICE"},
     {"name":"SURGICAL INFECTIONS"},
     {"name":"JOURNAL OF ARID ENVIRONMENTS"},
     {"name":"EUROPEAN PHYSICAL JOURNAL E"},
     {"name":"DOMESTIC ANIMAL ENDOCRINOLOGY"},
     {"name":"STRUCTURAL CHEMISTRY"},
     {"name":"JOURNAL OF CIVIL STRUCTURAL HEALTH MONITORING"},
     {"name":"ECOLOGICAL ENTOMOLOGY"},
     {"name":"GREEN PROCESSING AND SYNTHESIS"},
     {"name":"PHOTOGRAMMETRIC RECORD"},
     {"name":"INTERNATIONAL JOURNAL OF MEDICAL ROBOTICS AND COMPUTER ASSISTED SURGERY"},
     {"name":"SOILS AND FOUNDATIONS"},
     {"name":"PHYSICAL REVIEW PHYSICS EDUCATION RESEARCH"},
     {"name":"LUNG"},
     {"name":"SAUDI JOURNAL OF GASTROENTEROLOGY"},
     {"name":"CHEMICAL PAPERS"},
     {"name":"TOURISM ECONOMICS"},
     {"name":"PSYCHOLOGY OF MUSIC"},
     {"name":"JOURNAL OF PEDIATRIC AND ADOLESCENT GYNECOLOGY"},
     {"name":"LUBRICATION SCIENCE"},
     {"name":"ARCHIVES OF ANIMAL NUTRITION"},
     {"name":"THEORY AND SOCIETY"},
     {"name":"JOURNAL OF BIOMATERIALS APPLICATIONS"},
     {"name":"JOURNAL OF VIBRATION AND CONTROL"},
     {"name":"OPERATIONAL RESEARCH"},
     {"name":"RENEWABLE AGRICULTURE AND FOOD SYSTEMS"},
     {"name":"JOURNAL OF ANALYTICAL METHODS IN CHEMISTRY"},
     {"name":"URBAN EDUCATION"},
     {"name":"JOURNAL OF MOLECULAR RECOGNITION"},
     {"name":"BIOLOGICAL JOURNAL OF THE LINNEAN SOCIETY"},
     {"name":"SUSTAINABILITY ACCOUNTING MANAGEMENT AND POLICY JOURNAL"},
     {"name":"PLANT BIOSYSTEMS"},
     {"name":"JOURNAL OF CULTURAL ECONOMICS"},
     {"name":"SAR AND QSAR IN ENVIRONMENTAL RESEARCH"},
     {"name":"ZOOLOGY"},
     {"name":"PLANETARY AND SPACE SCIENCE"},
     {"name":"CLINICAL OTOLARYNGOLOGY"},
     {"name":"ADULT EDUCATION QUARTERLY"},
     {"name":"PROCEEDINGS OF THE GEOLOGISTS ASSOCIATION"},
     {"name":"INTERNATIONAL JOURNAL OF PRESSURE VESSELS AND PIPING"},
     {"name":"BIOPRESERVATION AND BIOBANKING"},
     {"name":"EXPERIMENTAL PARASITOLOGY"},
     {"name":"PHYSIOLOGY AND MOLECULAR BIOLOGY OF PLANTS"},
     {"name":"INTERNATIONAL JOURNAL OF APPLIED CERAMIC TECHNOLOGY"},
     {"name":"NEUROPATHOLOGY"},
     {"name":"CRETACEOUS RESEARCH"},
     {"name":"ADVANCES IN SPACE RESEARCH"},
     {"name":"JOURNAL OF EVALUATION IN CLINICAL PRACTICE"},
     {"name":"ACM TRANSACTIONS ON MATHEMATICAL SOFTWARE"},
     {"name":"JOURNAL OF ANIMAL BREEDING AND GENETICS"},
     {"name":"JOURNAL OF BRAND MANAGEMENT"},
     {"name":"ENVIRONMENTAL CHEMISTRY"},
     {"name":"JOURNAL OF CLUSTER SCIENCE"},
     {"name":"BEHAVIOURAL PHARMACOLOGY"},
     {"name":"SEMINARS IN DIALYSIS"},
     {"name":"BMC ENDOCRINE DISORDERS"},
     {"name":"INTERNATIONAL JOURNAL OF COAL PREPARATION AND UTILIZATION"},
     {"name":"LYMPHATIC RESEARCH AND BIOLOGY"},
     {"name":"YONSEI MEDICAL JOURNAL"},
     {"name":"MEDECINE ET MALADIES INFECTIEUSES"},
     {"name":"JOURNAL OF FUNCTION SPACES"},
     {"name":"BORDERLINE PERSONALITY DISORDER AND EMOTION DYSREGULATION"},
     {"name":"RESEARCH PAPERS IN EDUCATION"},
     {"name":"GEOTHERMAL ENERGY"},
     {"name":"CORNEA"},
     {"name":"JUDGMENT AND DECISION MAKING"},
     {"name":"COMMUNICATION THEORY"},
     {"name":"PEPTIDE SCIENCE"},
     {"name":"JOURNAL OF EDUCATIONAL CHANGE"},
     {"name":"JOURNAL OF PRODUCT AND BRAND MANAGEMENT"},
     {"name":"EXPERIMENTAL LUNG RESEARCH"},
     {"name":"JOURNAL OF BEHAVIOR THERAPY AND EXPERIMENTAL PSYCHIATRY"},
     {"name":"JOURNAL OF ORTHOPAEDIC SURGERY AND RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF INDUSTRIAL ERGONOMICS"},
     {"name":"JOURNAL OF APPLIED DEVELOPMENTAL PSYCHOLOGY"},
     {"name":"BRAZILIAN JOURNAL OF MEDICAL AND BIOLOGICAL RESEARCH"},
     {"name":"ANNUAL REPORTS ON NMR SPECTROSCOPY"},
     {"name":"JOURNAL OF OCCUPATIONAL REHABILITATION"},
     {"name":"ANDROLOGIA"},
     {"name":"JOURNAL OF ESTHETIC AND RESTORATIVE DENTISTRY"},
     {"name":"JOURNAL OF TEACHING IN PHYSICAL EDUCATION"},
     {"name":"JOURNAL OF CLINICAL NURSING"},
     {"name":"AQUATIC MICROBIAL ECOLOGY"},
     {"name":"PEDIATRIC SURGERY INTERNATIONAL"},
     {"name":"COMPLEMENTARY THERAPIES IN CLINICAL PRACTICE"},
     {"name":"JOURNAL OF INSTITUTIONAL ECONOMICS"},
     {"name":"TRANSPLANT INFECTIOUS DISEASE"},
     {"name":"INFANCY"},
     {"name":"NANOMATERIALS AND NANOTECHNOLOGY"},
     {"name":"MOLECULAR SIMULATION"},
     {"name":"AUSTRALASIAN JOURNAL OF EDUCATIONAL TECHNOLOGY"},
     {"name":"ACM JOURNAL ON COMPUTING AND CULTURAL HERITAGE"},
     {"name":"RESPIRATORY CARE"},
     {"name":"FOSSIL RECORD"},
     {"name":"YOUTH VIOLENCE AND JUVENILE JUSTICE"},
     {"name":"NEW CARBON MATERIALS"},
     {"name":"ACADEMIC PSYCHIATRY"},
     {"name":"ACCOUNTING AND BUSINESS RESEARCH"},
     {"name":"MINERVA"},
     {"name":"HONG KONG MEDICAL JOURNAL"},
     {"name":"SOCIAL INDICATORS RESEARCH"},
     {"name":"KYKLOS"},
     {"name":"ETHICS AND INFORMATION TECHNOLOGY"},
     {"name":"JOURNAL OF WOMENS HEALTH"},
     {"name":"SPORTS BIOMECHANICS"},
     {"name":"MULTINATIONAL BUSINESS REVIEW"},
     {"name":"CELLULAR POLYMERS"},
     {"name":"JOURNAL OF DERMATOLOGICAL TREATMENT"},
     {"name":"FOREST SCIENCE"},
     {"name":"HUMAN DIMENSIONS OF WILDLIFE"},
     {"name":"HEALTHCARE"},
     {"name":"ANIMAL WELFARE"},
     {"name":"BMC ANESTHESIOLOGY"},
     {"name":"SWISS MEDICAL WEEKLY"},
     {"name":"JOURNAL OF THEORETICAL AND APPLIED ELECTRONIC COMMERCE RESEARCH"},
     {"name":"JOURNAL OF DOCUMENTATION"},
     {"name":"CURRENT ORGANIC CHEMISTRY"},
     {"name":"MYCOSPHERE"},
     {"name":"JOURNAL OF SURGICAL RESEARCH"},
     {"name":"ARCHIVES OF ORTHOPAEDIC AND TRAUMA SURGERY"},
     {"name":"QUATERNARY INTERNATIONAL"},
     {"name":"PHYSICAL THERAPY IN SPORT"},
     {"name":"INTERNATIONAL JOURNAL OF CONCRETE STRUCTURES AND MATERIALS"},
     {"name":"JOURNAL OF PERIODONTAL AND IMPLANT SCIENCE"},
     {"name":"ECOLOGY OF FRESHWATER FISH"},
     {"name":"BRAIN BEHAVIOR AND EVOLUTION"},
     {"name":"JOURNAL OF POROUS MEDIA"},
     {"name":"SCANDINAVIAN ACTUARIAL JOURNAL"},
     {"name":"ACTA POLYMERICA SINICA"},
     {"name":"DECISION SCIENCES"},
     {"name":"JOURNAL OF PEDIATRIC SURGERY"},
     {"name":"INTERNATIONAL NURSING REVIEW"},
     {"name":"JOURNAL OF INTERNATIONAL MONEY AND FINANCE"},
     {"name":"MANAGEMENT OF BIOLOGICAL INVASIONS"},
     {"name":"NEUROEPIDEMIOLOGY"},
     {"name":"EARTH INTERACTIONS"},
     {"name":"GEOMECHANICS FOR ENERGY AND THE ENVIRONMENT"},
     {"name":"INTERNATIONAL MICROBIOLOGY"},
     {"name":"SOCIAL FORCES"},
     {"name":"NUTRITION RESEARCH AND PRACTICE"},
     {"name":"ACTA ASTRONOMICA"},
     {"name":"SYSTEM"},
     {"name":"PLASMA CHEMISTRY AND PLASMA PROCESSING"},
     {"name":"WORLD JOURNAL OF SURGICAL ONCOLOGY"},
     {"name":"SOCIAL MOVEMENT STUDIES"},
     {"name":"TOXICON"},
     {"name":"JOURNAL OF SURVEYING ENGINEERING"},
     {"name":"BULLETIN OF ENTOMOLOGICAL RESEARCH"},
     {"name":"FUNCTIONAL MATERIALS LETTERS"},
     {"name":"BIOENERGY RESEARCH"},
     {"name":"CELLULAR AND MOLECULAR BIOENGINEERING"},
     {"name":"QUALITY TECHNOLOGY AND QUANTITATIVE MANAGEMENT"},
     {"name":"ANNALS OF GENERAL PSYCHIATRY"},
     {"name":"PHILOSOPHICAL MAGAZINE"},
     {"name":"IET COMMUNICATIONS"},
     {"name":"EUROPEAN JOURNAL OF CANCER CARE"},
     {"name":"JOURNAL OF CLINICAL RESEARCH IN PEDIATRIC ENDOCRINOLOGY"},
     {"name":"GENESIS"},
     {"name":"ENVIRONMENTAL VALUES"},
     {"name":"POLITICAL STUDIES"},
     {"name":"AMERICAN JOURNAL OF ENOLOGY AND VITICULTURE"},
     {"name":"ASTRONOMY AND COMPUTING"},
     {"name":"ASLIB JOURNAL OF INFORMATION MANAGEMENT"},
     {"name":"ASIA PACIFIC JOURNAL OF TOURISM RESEARCH"},
     {"name":"JOURNAL OF PSYCHOPATHOLOGY AND BEHAVIORAL ASSESSMENT"},
     {"name":"BIOMEDICAL MICRODEVICES"},
     {"name":"BREAST JOURNAL"},
     {"name":"ADVANCED COMPOSITE MATERIALS"},
     {"name":"JOURNAL OF INORGANIC AND ORGANOMETALLIC POLYMERS AND MATERIALS"},
     {"name":"ANNUAL REVIEW OF LINGUISTICS"},
     {"name":"JOURNAL OF MULTIDISCIPLINARY HEALTHCARE"},
     {"name":"PSYCHOMETRIKA"},
     {"name":"UTILITIES POLICY"},
     {"name":"JOURNAL OF ENVIRONMENTAL RADIOACTIVITY"},
     {"name":"SYNTHETIC COMMUNICATIONS"},
     {"name":"CURRENT OPINION IN PEDIATRICS"},
     {"name":"CANADIAN JOURNAL OF REMOTE SENSING"},
     {"name":"THERAPEUTIC ADVANCES IN ENDOCRINOLOGY AND METABOLISM"},
     {"name":"JOURNAL OF CAREER DEVELOPMENT"},
     {"name":"JOURNAL OF BUSINESS ECONOMICS AND MANAGEMENT"},
     {"name":"PATTERN ANALYSIS AND APPLICATIONS"},
     {"name":"ANZ JOURNAL OF SURGERY"},
     {"name":"NEAR SURFACE GEOPHYSICS"},
     {"name":"CARDIOVASCULAR JOURNAL OF AFRICA"},
     {"name":"PHYSIOTHERAPY CANADA"},
     {"name":"ADVANCES IN MECHANICAL ENGINEERING"},
     {"name":"AMERICAS"},
     {"name":"JOURNAL OF DENTAL EDUCATION"},
     {"name":"PRESSE MEDICALE"},
     {"name":"BIODEMOGRAPHY AND SOCIAL BIOLOGY"},
     {"name":"JOURNAL OF COUNSELING AND DEVELOPMENT"},
     {"name":"RECENT PATENTS ON NANOTECHNOLOGY"},
     {"name":"QUANTUM TOPOLOGY"},
     {"name":"INFANT AND CHILD DEVELOPMENT"},
     {"name":"ARTIFICIAL LIFE"},
     {"name":"MATHEMATICAL PHYSICS ANALYSIS AND GEOMETRY"},
     {"name":"JOURNAL OF THE HISTORY OF SEXUALITY"},
     {"name":"JOURNAL OF LABOR RESEARCH"},
     {"name":"SCANNING"},
     {"name":"AFRICAN STUDIES REVIEW"},
     {"name":"INTERNATIONAL TAX AND PUBLIC FINANCE"},
     {"name":"EDUCATIONAL RESEARCH"},
     {"name":"URBAN DESIGN INTERNATIONAL"},
     {"name":"SPIN"},
     {"name":"ECOLOGICAL RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF SPORT PSYCHOLOGY"},
     {"name":"REVIEW OF INCOME AND WEALTH"},
     {"name":"JOURNAL OF PUBLIC CHILD WELFARE"},
     {"name":"MODELING IDENTIFICATION AND CONTROL"},
     {"name":"ISRAEL MEDICAL ASSOCIATION JOURNAL"},
     {"name":"ARAB JOURNAL OF GASTROENTEROLOGY"},
     {"name":"MATHEMATISCHE ZEITSCHRIFT"},
     {"name":"KNOWLEDGE AND MANAGEMENT OF AQUATIC ECOSYSTEMS"},
     {"name":"INTERNATIONAL JOURNAL OF NURSING KNOWLEDGE"},
     {"name":"COMPARATIVE MEDICINE"},
     {"name":"ACTA ORTHOPAEDICA ET TRAUMATOLOGICA TURCICA"},
     {"name":"BRAZILIAN JOURNAL OF OTORHINOLARYNGOLOGY"},
     {"name":"ACTA INFORMATICA"},
     {"name":"HASTINGS LAW JOURNAL"},
     {"name":"JOURNAL OF LAND USE SCIENCE"},
     {"name":"DERMATOLOGICA SINICA"},
     {"name":"INVASIVE PLANT SCIENCE AND MANAGEMENT"},
     {"name":"CLAY MINERALS"},
     {"name":"AUSTRALIAN JOURNAL OF SOCIAL ISSUES"},
     {"name":"MOBILITIES"},
     {"name":"BIOINSPIRED BIOMIMETIC AND NANOBIOMATERIALS"},
     {"name":"TEST"},
     {"name":"ZENTRALBLATT FUR CHIRURGIE"},
     {"name":"COMMUNICATIONS ON PURE AND APPLIED ANALYSIS"},
     {"name":"JOURNAL OF PROFESSIONAL ISSUES IN ENGINEERING EDUCATION AND PRACTICE"},
     {"name":"RACE AND JUSTICE"},
     {"name":"ELT JOURNAL"},
     {"name":"PHYSICS AND CHEMISTRY OF MINERALS"},
     {"name":"BOTANICAL SCIENCES"},
     {"name":"JOURNAL OF HUMAN CAPITAL"},
     {"name":"ISSUES IN SCIENCE AND TECHNOLOGY"},
     {"name":"CONSERVATION GENETICS RESOURCES"},
     {"name":"HISTORY OF PSYCHOLOGY"},
     {"name":"JOURNAL OF EARLY INTERVENTION"},
     {"name":"NOVA HEDWIGIA"},
     {"name":"REHABILITATION COUNSELING BULLETIN"},
     {"name":"HAGUE JOURNAL ON THE RULE OF LAW"},
     {"name":"AUSTRALIAN JOURNAL OF PSYCHOLOGY"},
     {"name":"JOURNAL OF NONLINEAR MATHEMATICAL PHYSICS"},
     {"name":"HEALTH POLICY AND TECHNOLOGY"},
     {"name":"SOCIAL COGNITION"},
     {"name":"JOURNAL OF COMPUTATIONAL FINANCE"},
     {"name":"ECOLOGICAL COMPLEXITY"},
     {"name":"JOURNAL OF AGRICULTURAL METEOROLOGY"},
     {"name":"JOURNAL OF EMPIRICAL FINANCE"},
     {"name":"GEOTECTONICS"},
     {"name":"MOLECULAR BIOLOGY"},
     {"name":"TRANSPORTATION JOURNAL"},
     {"name":"ARDEA"},
     {"name":"NURSE EDUCATOR"},
     {"name":"SOUTHERN MEDICAL JOURNAL"},
     {"name":"JOURNAL OF THE JAPAN PETROLEUM INSTITUTE"},
     {"name":"INTERNATIONAL JOURNAL OF SHIPPING AND TRANSPORT LOGISTICS"},
     {"name":"JOURNAL OF THEORETICAL AND APPLIED MECHANICS"},
     {"name":"JOURNAL OF MEDICAL AND BIOLOGICAL ENGINEERING"},
     {"name":"ADVANCES IN ELECTRICAL AND COMPUTER ENGINEERING"},
     {"name":"ANALYSIS AND APPLICATIONS"},
     {"name":"JOURNAL OF THE JAPANESE AND INTERNATIONAL ECONOMIES"},
     {"name":"CONTEMPORARY ECONOMIC POLICY"},
     {"name":"JOURNAL OF COMPUTATIONAL ELECTRONICS"},
     {"name":"INTERNATIONAL JOURNAL ON ARTIFICIAL INTELLIGENCE TOOLS"},
     {"name":"JOURNAL OF PROFESSIONAL CAPITAL AND COMMUNITY"},
     {"name":"ZOOLOGICAL SCIENCE"},
     {"name":"JOURNAL OF THE AMERICAN ASSOCIATION FOR LABORATORY ANIMAL SCIENCE"},
     {"name":"JOURNAL OF POULTRY SCIENCE"},
     {"name":"JOURNALISM PRACTICE"},
     {"name":"GEOFIZIKA"},
     {"name":"INDIAN JOURNAL OF DERMATOLOGY"},
     {"name":"HIGH PERFORMANCE POLYMERS"},
     {"name":"COMPLEX VARIABLES AND ELLIPTIC EQUATIONS"},
     {"name":"MYCOBIOLOGY"},
     {"name":"CONNECTION SCIENCE"},
     {"name":"EXPERIMENTAL AGRICULTURE"},
     {"name":"MACROHETEROCYCLES"},
     {"name":"JOURNAL OF WATERWAY PORT COASTAL AND OCEAN ENGINEERING"},
     {"name":"EVOLUTIONARY ECOLOGY RESEARCH"},
     {"name":"EARTH SCIENCE INFORMATICS"},
     {"name":"POSTEPY DERMATOLOGII I ALERGOLOGII"},
     {"name":"JOURNAL OF GENETICS"},
     {"name":"RUSSIAN METEOROLOGY AND HYDROLOGY"},
     {"name":"JOURNAL OF COASTAL CONSERVATION"},
     {"name":"NATIONS AND NATIONALISM"},
     {"name":"JOURNAL OF ATMOSPHERIC CHEMISTRY"},
     {"name":"COMPARATIVE POLITICS"},
     {"name":"EPISODES"},
     {"name":"MARINE MAMMAL SCIENCE"},
     {"name":"JOURNAL OF MINIMAL ACCESS SURGERY"},
     {"name":"CHEMISTRY OF HETEROCYCLIC COMPOUNDS"},
     {"name":"HIP INTERNATIONAL"},
     {"name":"CHILD AND ADOLESCENT SOCIAL WORK JOURNAL"},
     {"name":"DYNA"},
     {"name":"SOCIOBIOLOGY"},
     {"name":"STRATIGRAPHY AND GEOLOGICAL CORRELATION"},
     {"name":"GRASAS Y ACEITES"},
     {"name":"STEREOTACTIC AND FUNCTIONAL NEUROSURGERY"},
     {"name":"NATURAL RESOURCES JOURNAL"},
     {"name":"LINGUISTIC TYPOLOGY"},
     {"name":"EXPERIMENTAL ANIMALS"},
     {"name":"INTERNATIONAL JOURNAL OF ACOUSTICS AND VIBRATION"},
     {"name":"CONSTRUCTIVE APPROXIMATION"},
     {"name":"ANNALES ZOOLOGICI"},
     {"name":"HEALTH PHYSICS"},
     {"name":"INDUSTRIAL LUBRICATION AND TRIBOLOGY"},
     {"name":"ANNALES HENRI POINCARE"},
     {"name":"JOURNAL OF CARBOHYDRATE CHEMISTRY"},
     {"name":"ENDOKRYNOLOGIA POLSKA"},
     {"name":"APPLIED ENTOMOLOGY AND ZOOLOGY"},
     {"name":"INTERNATIONAL JOURNAL OF LAW CRIME AND JUSTICE"},
     {"name":"INTERNATIONAL JOURNAL OF EMERGING MARKETS"},
     {"name":"JOURNAL OF REGULATORY ECONOMICS"},
     {"name":"ACTA HERPETOLOGICA"},
     {"name":"ASIA PACIFIC JOURNAL OF EDUCATION"},
     {"name":"REVISTA DE LA FACULTAD DE AGRONOMIA DE LA UNIVERSIDAD DEL ZULIA"},
     {"name":"CLINICAL JOURNAL OF ONCOLOGY NURSING"},
     {"name":"JOURNAL OF ECONOMIC EDUCATION"},
     {"name":"SCIENTIST"},
     {"name":"JOURNAL OF APPLIED BEHAVIORAL SCIENCE"},
     {"name":"JOURNAL OF INVESTIGATIVE PSYCHOLOGY AND OFFENDER PROFILING"},
     {"name":"JOURNAL OF MATHEMATICAL IMAGING AND VISION"},
     {"name":"LITHIC TECHNOLOGY"},
     {"name":"JOURNAL OF BEHAVIORAL EDUCATION"},
     {"name":"FLUCTUATION AND NOISE LETTERS"},
     {"name":"HISTORICAL JOURNAL"},
     {"name":"AQUACULTURE INTERNATIONAL"},
     {"name":"ASIAN HERPETOLOGICAL RESEARCH"},
     {"name":"JOURNAL OF ORTHOPAEDIC SCIENCE"},
     {"name":"JOURNAL OF MULTIVARIATE ANALYSIS"},
     {"name":"POLISH JOURNAL OF PATHOLOGY"},
     {"name":"JOURNAL OF GLOBAL INFORMATION MANAGEMENT"},
     {"name":"DEVELOPMENTAL NEUROPSYCHOLOGY"},
     {"name":"THORACIC SURGERY CLINICS"},
     {"name":"PROGRESS IN RUBBER PLASTICS AND RECYCLING TECHNOLOGY"},
     {"name":"ANNALS ACADEMY OF MEDICINE SINGAPORE"},
     {"name":"NEUROLOGIST"},
     {"name":"AORN JOURNAL"},
     {"name":"COMPTES RENDUS MECANIQUE"},
     {"name":"STRUCTURE AND BONDING"},
     {"name":"PHYSIOTHERAPY THEORY AND PRACTICE"},
     {"name":"REVISTA LATINOAMERICANA DE PSICOLOGIA"},
     {"name":"IET COMPUTERS AND DIGITAL TECHNIQUES"},
     {"name":"DISCRETE APPLIED MATHEMATICS"},
     {"name":"TENSIDE SURFACTANTS DETERGENTS"},
     {"name":"CANADIAN JOURNAL OF ANIMAL SCIENCE"},
     {"name":"JOURNAL OF COMBINATORIAL DESIGNS"},
     {"name":"LEISURE STUDIES"},
     {"name":"FOLIA BIOLOGICA"},
     {"name":"JOURNAL OF LANGUAGE AND SOCIAL PSYCHOLOGY"},
     {"name":"HASTINGS CENTER REPORT"},
     {"name":"CRITICAL REVIEWS IN IMMUNOLOGY"},
     {"name":"PERSPECTIVES ON BEHAVIOR SCIENCE"},
     {"name":"ASIA PACIFIC EDUCATION REVIEW"},
     {"name":"DISCRETE OPTIMIZATION"},
     {"name":"INTERNATIONAL JOURNAL OF OCCUPATIONAL MEDICINE AND ENVIRONMENTAL HEALTH"},
     {"name":"FEMINIST CRIMINOLOGY"},
     {"name":"PROGRESS IN DEVELOPMENT STUDIES"},
     {"name":"AMERICAN LAW AND ECONOMICS REVIEW"},
     {"name":"SIAM JOURNAL ON APPLIED MATHEMATICS"},
     {"name":"TRANSACTIONS OF THE ASABE"},
     {"name":"MINERVA BIOTECNOLOGICA"},
     {"name":"BRAZILIAN JOURNAL OF PHYSICS"},
     {"name":"JOURNAL OF ORNITHOLOGY"},
     {"name":"MATERIALES DE CONSTRUCCION"},
     {"name":"JOURNAL OF GEOSCIENCES"},
     {"name":"JOURNAL OF TROPICAL ECOLOGY"},
     {"name":"JOURNAL OF RENEWABLE MATERIALS"},
     {"name":"PSYCHOLOGICAL RECORD"},
     {"name":"REVISTA ARGENTINA DE MICROBIOLOGIA"},
     {"name":"ANTIQUITY"},
     {"name":"BIOCHEMICAL SYSTEMATICS AND ECOLOGY"},
     {"name":"JOURNAL OF REPRODUCTIVE AND INFANT PSYCHOLOGY"},
     {"name":"PHYSIOLOGICAL RESEARCH"},
     {"name":"AMERICAN JOURNAL OF FORENSIC MEDICINE AND PATHOLOGY"},
     {"name":"BRAGANTIA"},
     {"name":"BRITISH POLITICS"},
     {"name":"INTERNATIONAL JOURNAL OF TECHNOLOGY MANAGEMENT"},
     {"name":"INTERNATIONAL JOURNAL OF AUDITING"},
     {"name":"INTERNATIONAL JOURNAL OF OCCUPATIONAL SAFETY AND ERGONOMICS"},
     {"name":"COMPARATIVE STUDIES IN SOCIETY AND HISTORY"},
     {"name":"OPEN MATHEMATICS"},
     {"name":"JOURNAL OF NUCLEAR SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF LASER APPLICATIONS"},
     {"name":"EDUCATIONAL PHILOSOPHY AND THEORY"},
     {"name":"JOURNAL OF SOCIOLINGUISTICS"},
     {"name":"FLAVOUR AND FRAGRANCE JOURNAL"},
     {"name":"JOURNAL OF COASTAL RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL OF NURSING PRACTICE"},
     {"name":"PLANT BIOTECHNOLOGY"},
     {"name":"CALIFORNIA AGRICULTURE"},
     {"name":"HORTICULTURE JOURNAL"},
     {"name":"JOURNAL OF GENERAL AND APPLIED MICROBIOLOGY"},
     {"name":"HISTORY AND PHILOSOPHY OF THE LIFE SCIENCES"},
     {"name":"NUMERICAL FUNCTIONAL ANALYSIS AND OPTIMIZATION"},
     {"name":"MOTOR CONTROL"},
     {"name":"SURVIVAL"},
     {"name":"JOURNAL OF NEW MUSIC RESEARCH"},
     {"name":"PROTECTION OF METALS AND PHYSICAL CHEMISTRY OF SURFACES"},
     {"name":"POLITICAL QUARTERLY"},
     {"name":"EARLY YEARS"},
     {"name":"JOURNAL OF CHEMOMETRICS"},
     {"name":"NANO"},
     {"name":"GROUND WATER MONITORING AND REMEDIATION"},
     {"name":"INTERNATIONAL JOURNAL OF CAST METALS RESEARCH"},
     {"name":"ADVANCES IN METEOROLOGY"},
     {"name":"AIDS EDUCATION AND PREVENTION"},
     {"name":"COGNITIVE LINGUISTICS"},
     {"name":"SCIENCE OF COMPUTER PROGRAMMING"},
     {"name":"HAMOSTASEOLOGIE"},
     {"name":"ANIMAL BIOTECHNOLOGY"},
     {"name":"VETERINARY OPHTHALMOLOGY"},
     {"name":"ANNALS OF APPLIED PROBABILITY"},
     {"name":"JOURNAL OF NONLINEAR AND CONVEX ANALYSIS"},
     {"name":"CONTRIBUTIONS TO ZOOLOGY"},
     {"name":"ARCHIV FUR MOLLUSKENKUNDE"},
     {"name":"ACTA ODONTOLOGICA SCANDINAVICA"},
     {"name":"JOURNAL OF COMBINATORIAL OPTIMIZATION"},
     {"name":"CANADIAN JOURNAL OF CIVIL ENGINEERING"},
     {"name":"GLOBAL POLICY"},
     {"name":"SURGICAL INNOVATION"},
     {"name":"INTERNATIONAL JOURNAL OF AEROACOUSTICS"},
     {"name":"JOURNAL OF SUBSTANCE USE"},
     {"name":"JOURNAL OF VETERINARY RESEARCH"},
     {"name":"MUSIC PERCEPTION"},
     {"name":"JOURNAL OF PRODUCTIVITY ANALYSIS"},
     {"name":"ACM TRANSACTIONS ON APPLIED PERCEPTION"},
     {"name":"NEW MEDIT"},
     {"name":"TSINGHUA SCIENCE AND TECHNOLOGY"},
     {"name":"JOURNAL OF SCHOLARLY PUBLISHING"},
     {"name":"INTERNATIONAL JOURNAL OF BILINGUALISM"},
     {"name":"GENDER PLACE AND CULTURE"},
     {"name":"GRANA"},
     {"name":"BOLLETTINO DELLA SOCIETA PALEONTOLOGICA ITALIANA"},
     {"name":"SOCIAL WORK RESEARCH"},
     {"name":"JOURNAL OF BIOLOGICAL REGULATORS AND HOMEOSTATIC AGENTS"},
     {"name":"JOURNAL OF OVONIC RESEARCH"},
     {"name":"JOURNAL OF URBAN AFFAIRS"},
     {"name":"JOURNAL OF NONCOMMUTATIVE GEOMETRY"},
     {"name":"STOCHASTIC ANALYSIS AND APPLICATIONS"},
     {"name":"JOURNAL OF TURBULENCE"},
     {"name":"POLITICAL SCIENCE QUARTERLY"},
     {"name":"SYSTEMATIC AND APPLIED ACAROLOGY"},
     {"name":"JOURNAL OF MENTAL HEALTH RESEARCH IN INTELLECTUAL DISABILITIES"},
     {"name":"CADERNOS DE SAUDE PUBLICA"},
     {"name":"SYSTEMATIC BOTANY"},
     {"name":"SIGMOD RECORD"},
     {"name":"BRODOGRADNJA"},
     {"name":"MATHEMATICAL MODELLING AND ANALYSIS"},
     {"name":"REVUE D EPIDEMIOLOGIE ET DE SANTE PUBLIQUE"},
     {"name":"NUMERICAL LINEAR ALGEBRA WITH APPLICATIONS"},
     {"name":"JOURNAL OF MATHEMATICAL LOGIC"},
     {"name":"NEMATOLOGY"},
     {"name":"METRIKA"},
     {"name":"INFANT MENTAL HEALTH JOURNAL"},
     {"name":"INRA PRODUCTIONS ANIMALES"},
     {"name":"COMPUTER METHODS IN BIOMECHANICS AND BIOMEDICAL ENGINEERING"},
     {"name":"ARCHIVES OF MECHANICS"},
     {"name":"JOURNAL OF SEMANTICS"},
     {"name":"JOURNAL OF STATISTICAL PLANNING AND INFERENCE"},
     {"name":"NARRATIVE INQUIRY"},
     {"name":"JOURNAL OF APPLIED STATISTICS"},
     {"name":"TRANSACTIONS OF THE INDIAN INSTITUTE OF METALS"},
     {"name":"ESTUDIOS ATACAMENOS"},
     {"name":"ZEITSCHRIFT FUR ANORGANISCHE UND ALLGEMEINE CHEMIE"},
     {"name":"BOLLETTINO DI GEOFISICA TEORICA ED APPLICATA"},
     {"name":"JOURNAL OF COMPUTER ASSISTED TOMOGRAPHY"},
     {"name":"REVISTA DE EDUCACION"},
     {"name":"JOURNAL OF SPORTS MEDICINE AND PHYSICAL FITNESS"},
     {"name":"BMC EMERGENCY MEDICINE"},
     {"name":"REVISTA DE CIENCIA POLITICA"},
     {"name":"SCANDINAVIAN JOURNAL OF STATISTICS"},
     {"name":"ETRI JOURNAL"},
     {"name":"INTERNATIONAL JOURNAL OF DISABILITY DEVELOPMENT AND EDUCATION"},
     {"name":"AMERICAN POLITICS RESEARCH"},
     {"name":"INTERNATIONAL JOURNAL ON DOCUMENT ANALYSIS AND RECOGNITION"},
     {"name":"SCIENTIFIC PROGRAMMING"},
     {"name":"ECOLOGICAL PROCESSES"},
     {"name":"AVIAN DISEASES"},
      {"name":"ALGEBRAIC AND GEOMETRIC TOPOLOGY"},
      {"name":"WATER POLICY"},
      {"name":"RATIONALITY AND SOCIETY"},
      {"name":"JOURNAL OF TROPICAL PEDIATRICS"},
      {"name":"AUSTRALIAN ECONOMIC PAPERS"},
      {"name":"PSYCHIATRY AND CLINICAL PSYCHOPHARMACOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF PSYCHOLOGY"},
      {"name":"IEEE TRANSACTIONS ON PLASMA SCIENCE"},
      {"name":"LAKE AND RESERVOIR MANAGEMENT"},
      {"name":"METHODS IN ENZYMOLOGY"},
      {"name":"JOURNAL OF ELECTRON SPECTROSCOPY AND RELATED PHENOMENA"},
      {"name":"BIOLOGICAL CYBERNETICS"},
      {"name":"INTERVENTIONAL NEURORADIOLOGY"},
      {"name":"TRANSPLANTATION PROCEEDINGS"},
      {"name":"JOURNAL OF CERAMIC SCIENCE AND TECHNOLOGY"},
      {"name":"FRONTIERS IN LIFE SCIENCE"},
      {"name":"FLUORIDE"},
      {"name":"MINERVA PEDIATRICA"},
      {"name":"NONLINEAR PROCESSES IN GEOPHYSICS"},
      {"name":"SILVA FENNICA"},
      {"name":"SUPREME COURT REVIEW"},
      {"name":"GESTURE"},
      {"name":"BRAZILIAN JOURNAL OF CHEMICAL ENGINEERING"},
      {"name":"KINETIC AND RELATED MODELS"},
      {"name":"JOURNAL OF BEHAVIORAL AND EXPERIMENTAL ECONOMICS"},
      {"name":"JOURNAL OF NURSING RESEARCH"},
      {"name":"JOURNAL OF THE AMERICAN SOCIETY FOR HORTICULTURAL SCIENCE"},
      {"name":"NAVAL RESEARCH LOGISTICS"},
      {"name":"JOURNAL OF AGRICULTURAL SCIENCE"},
      {"name":"JOURNAL OF ENERGY IN SOUTHERN AFRICA"},
      {"name":"JOURNAL ON MULTIMODAL USER INTERFACES"},
      {"name":"JOURNAL OF THE AMERICAN PSYCHIATRIC NURSES ASSOCIATION"},
      {"name":"REVISTA ESPANOLA DE DERECHO CONSTITUCIONAL"},
      {"name":"JOURNAL OF PEDIATRIC HEMATOLOGY ONCOLOGY"},
      {"name":"FOUNDATIONS OF PHYSICS"},
      {"name":"ACOUSTICAL PHYSICS"},
      {"name":"MEDICAL PRINCIPLES AND PRACTICE"},
      {"name":"LEGISLATIVE STUDIES QUARTERLY"},
      {"name":"ACTA MATHEMATICA SCIENTIA"},
      {"name":"JOURNAL OF LANGUAGE IDENTITY AND EDUCATION"},
      {"name":"PROGRESS IN NUCLEAR ENERGY"},
      {"name":"ACTA OCEANOLOGICA SINICA"},
      {"name":"MATHEMATICS AND COMPUTERS IN SIMULATION"},
      {"name":"ADVANCES IN LIFE COURSE RESEARCH"},
      {"name":"JOURNAL OF HAND THERAPY"},
      {"name":"ARID LAND RESEARCH AND MANAGEMENT"},
      {"name":"PHYSIOLOGICAL AND MOLECULAR PLANT PATHOLOGY"},
      {"name":"QUANTITATIVE ECONOMICS"},
      {"name":"TRANSPLANT IMMUNOLOGY"},
      {"name":"SOUTH ATLANTIC QUARTERLY"},
      {"name":"JOURNAL OF LARYNGOLOGY AND OTOLOGY"},
      {"name":"WATER AND ENVIRONMENT JOURNAL"},
      {"name":"JOURNAL OF LANGUAGE AND POLITICS"},
      {"name":"INTERNATIONAL JOURNAL OF OFFENDER THERAPY AND COMPARATIVE CRIMINOLOGY"},
      {"name":"MEDICAL ULTRASONOGRAPHY"},
      {"name":"CROP BREEDING AND APPLIED BIOTECHNOLOGY"},
      {"name":"ASIAN JOURNAL OF CRIMINOLOGY"},
      {"name":"JOURNAL OF ORGANIZATIONAL BEHAVIOR MANAGEMENT"},
      {"name":"JOURNAL OF SOCIAL WORK EDUCATION"},
      {"name":"HELLENIC JOURNAL OF NUCLEAR MEDICINE"},
      {"name":"JOURNAL OF APPLIED SPECTROSCOPY"},
      {"name":"JOURNAL OF LOW TEMPERATURE PHYSICS"},
      {"name":"PUBLIC CHOICE"},
      {"name":"ANNALS OF MICROBIOLOGY"},
      {"name":"ECOLOGICAL RESTORATION"},
      {"name":"EUROPEAN REVIEW OF ECONOMIC HISTORY"},
      {"name":"JOURNAL OF PRAGMATICS"},
      {"name":"POLICE QUARTERLY"},
      {"name":"MINERVA CARDIOANGIOLOGICA"},
      {"name":"INTERNATIONAL JOURNAL OF MENTAL HEALTH AND ADDICTION"},
      {"name":"INFORMATION TECHNOLOGY AND CONTROL"},
      {"name":"AFRICAN ZOOLOGY"},
      {"name":"APPLIED LINGUISTICS REVIEW"},
      {"name":"AMERICAN JOURNAL OF CRIMINAL JUSTICE"},
      {"name":"JOURNAL OF ARTIFICIAL ORGANS"},
      {"name":"EGYPTIAN JOURNAL OF BIOLOGICAL PEST CONTROL"},
      {"name":"DEVIANT BEHAVIOR"},
      {"name":"ASIAN AND PACIFIC MIGRATION JOURNAL"},
      {"name":"INTERNATIONAL JOURNAL OF LOWER EXTREMITY WOUNDS"},
      {"name":"JOURNAL OF SPECIALISED TRANSLATION"},
      {"name":"JOURNAL OF MONEY CREDIT AND BANKING"},
      {"name":"WOMENS STUDIES INTERNATIONAL FORUM"},
      {"name":"MATERIALS TRANSACTIONS"},
      {"name":"ASIAN PACIFIC JOURNAL OF ALLERGY AND IMMUNOLOGY"},
      {"name":"ANNALES DE L INSTITUT FOURIER"},
      {"name":"JOURNAL OF ORAL IMPLANTOLOGY"},
      {"name":"CHINESE JOURNAL OF INTERNATIONAL LAW"},
      {"name":"INTERNATIONAL JOURNAL OF COMPARATIVE SOCIOLOGY"},
      {"name":"JOURNAL OF SYSTEMS SCIENCE AND SYSTEMS ENGINEERING"},
      {"name":"ASIAN NURSING RESEARCH"},
      {"name":"JOURNAL OF EDUCATIONAL RESEARCH"},
      {"name":"ACTA CHIMICA SLOVENICA"},
      {"name":"JOURNAL OF AGRICULTURAL BIOLOGICAL AND ENVIRONMENTAL STATISTICS"},
      {"name":"AUSTRALIAN EDUCATIONAL RESEARCHER"},
      {"name":"KYOTO JOURNAL OF MATHEMATICS"},
      {"name":"DYSLEXIA"},
      {"name":"MOUNTAIN RESEARCH AND DEVELOPMENT"},
      {"name":"EUROPEAN JOURNAL OF HORTICULTURAL SCIENCE"},
      {"name":"IEICE ELECTRONICS EXPRESS"},
      {"name":"HISTORY OF SCIENCE"},
      {"name":"RADIO SCIENCE"},
      {"name":"CURRENT OPINION IN SUPPORTIVE AND PALLIATIVE CARE"},
      {"name":"QUALITY MANAGEMENT IN HEALTH CARE"},
      {"name":"INTELLIGENT SERVICE ROBOTICS"},
      {"name":"MILITARY PSYCHOLOGY"},
      {"name":"JOURNAL OF FIRE SCIENCES"},
      {"name":"PHILOSOPHIA MATHEMATICA"},
      {"name":"ASIAN STUDIES REVIEW"},
      {"name":"INTEGRAL TRANSFORMS AND SPECIAL FUNCTIONS"},
      {"name":"NUCLEAR MEDICINE COMMUNICATIONS"},
      {"name":"HACETTEPE JOURNAL OF MATHEMATICS AND STATISTICS"},
      {"name":"SPECTROSCOPY"},
      {"name":"JOURNAL OF CHROMATOGRAPHIC SCIENCE"},
      {"name":"JOURNAL OF FAMILY VIOLENCE"},
      {"name":"BRAZILIAN JOURNAL OF BOTANY"},
      {"name":"JOURNAL OF EDUCATIONAL MEASUREMENT"},
      {"name":"GEOTECHNIQUE LETTERS"},
      {"name":"FOOD SCIENCE AND TECHNOLOGY"},
      {"name":"SOUTH AFRICAN JOURNAL OF GEOLOGY"},
      {"name":"JOURNAL OF LOGICAL AND ALGEBRAIC METHODS IN PROGRAMMING"},
      {"name":"CLIMATE CHANGE ECONOMICS"},
      {"name":"OPTICA APPLICATA"},
      {"name":"IMA JOURNAL OF APPLIED MATHEMATICS"},
      {"name":"JOURNAL OF COMPUTING AND INFORMATION SCIENCE IN ENGINEERING"},
      {"name":"SIBERIAN MATHEMATICAL JOURNAL"},
      {"name":"JOURNAL OF CUTANEOUS PATHOLOGY"},
      {"name":"TECHNOLOGY AND HEALTH CARE"},
      {"name":"NURSING PHILOSOPHY"},
      {"name":"RUSSIAN CHEMICAL BULLETIN"},
      {"name":"APPLIED ARTIFICIAL INTELLIGENCE"},
      {"name":"AMERICAN CRIMINAL LAW REVIEW"},
      {"name":"SOCIAL WORK IN HEALTH CARE"},
      {"name":"JOURNAL OF TESTING AND EVALUATION"},
      {"name":"JOURNAL OF SPORT REHABILITATION"},
      {"name":"JOURNAL OF DAIRY RESEARCH"},
      {"name":"ANNALS OF CLINICAL AND LABORATORY SCIENCE"},
      {"name":"MAGNESIUM RESEARCH"},
      {"name":"IEEE TECHNOLOGY AND SOCIETY MAGAZINE"},
      {"name":"JOURNAL OF PIPELINE SYSTEMS ENGINEERING AND PRACTICE"},
      {"name":"ORAL AND MAXILLOFACIAL SURGERY CLINICS OF NORTH AMERICA"},
      {"name":"SENSOR REVIEW"},
      {"name":"NEUROETHICS"},
      {"name":"CHELONIAN CONSERVATION AND BIOLOGY"},
      {"name":"BOTANICA MARINA"},
      {"name":"NEW ZEALAND JOURNAL OF MARINE AND FRESHWATER RESEARCH"},
      {"name":"RESEARCH IN NONDESTRUCTIVE EVALUATION"},
      {"name":"INTERNATIONAL JOURNAL OF SEXUAL HEALTH"},
      {"name":"OCEANOLOGY"},
      {"name":"ENGINEERING COMPUTATIONS"},
      {"name":"JOURNAL OF THE INDIAN INSTITUTE OF SCIENCE"},
      {"name":"EMERGENCY MEDICINE AUSTRALASIA"},
      {"name":"SPORT MARKETING QUARTERLY"},
      {"name":"HOUSING POLICY DEBATE"},
      {"name":"ECONOMIC HISTORY REVIEW"},
      {"name":"JOURNAL OF PESTICIDE SCIENCE"},
      {"name":"AUSTRALIAN VETERINARY JOURNAL"},
      {"name":"GEOGRAFIA FISICA E DINAMICA QUATERNARIA"},
      {"name":"AFRICAN HEALTH SCIENCES"},
      {"name":"PROCEEDINGS OF THE LONDON MATHEMATICAL SOCIETY"},
      {"name":"LEIDEN JOURNAL OF INTERNATIONAL LAW"},
      {"name":"COMPUTATIONAL METHODS IN APPLIED MATHEMATICS"},
      {"name":"REVIEWS IN INORGANIC CHEMISTRY"},
      {"name":"MEDICINA CLINICA"},
      {"name":"ELECTROCHEMISTRY"},
      {"name":"PLANT MOLECULAR BIOLOGY REPORTER"},
      {"name":"JOURNAL OF NANOSCIENCE AND NANOTECHNOLOGY"},
      {"name":"BULLETIN OF THE CHEMICAL SOCIETY OF ETHIOPIA"},
      {"name":"EUROPEAN SOCIETIES"},
      {"name":"FISHERY BULLETIN"},
      {"name":"JOURNAL OF LOWER GENITAL TRACT DISEASE"},
      {"name":"PHARMACOLOGY"},
      {"name":"FORUM MATHEMATICUM"},
      {"name":"JOURNAL OF CLINICAL ULTRASOUND"},
      {"name":"NEW IDEAS IN PSYCHOLOGY"},
      {"name":"CANADIAN JOURNAL OF SOIL SCIENCE"},
      {"name":"ECONOMIC RECORD"},
      {"name":"SIAM JOURNAL ON MATRIX ANALYSIS AND APPLICATIONS"},
      {"name":"JOURNAL OF MATHEMATICAL PHYSICS"},
      {"name":"INTERNATIONAL JOURNAL OF AUTOMOTIVE TECHNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF NONLINEAR SCIENCES AND NUMERICAL SIMULATION"},
      {"name":"SURFACE REVIEW AND LETTERS"},
      {"name":"STUDIA MATHEMATICA"},
      {"name":"PACIFIC REVIEW"},
      {"name":"HEALTH EDUCATION JOURNAL"},
      {"name":"STUDIES IN HISTORY AND PHILOSOPHY OF SCIENCE"},
      {"name":"DEATH STUDIES"},
      {"name":"TEXAS HEART INSTITUTE JOURNAL"},
      {"name":"GENETICA"},
      {"name":"ANAESTHESIST"},
      {"name":"GESUNDE PFLANZEN"},
      {"name":"JOURNAL OF ENVIRONMENTAL PROTECTION AND ECOLOGY"},
      {"name":"CULTURAL SOCIOLOGY"},
      {"name":"JOURNAL OF SURFACTANTS AND DETERGENTS"},
      {"name":"JOURNAL OF HEALTH COMMUNICATION"},
      {"name":"ENVIRONMENTAL GEOTECHNICS"},
      {"name":"INTERNATIONAL JOURNAL OF AGRICULTURE AND BIOLOGY"},
      {"name":"PROCESSING AND APPLICATION OF CERAMICS"},
      {"name":"FISCAL STUDIES"},
      {"name":"MATHEMATICS AND FINANCIAL ECONOMICS"},
      {"name":"JOURNAL OF INSECT BEHAVIOR"},
      {"name":"MIGRATION STUDIES"},
      {"name":"JOURNAL OF MOLECULAR SPECTROSCOPY"},
      {"name":"SCANDINAVIAN JOURNAL OF OCCUPATIONAL THERAPY"},
      {"name":"JOURNAL OF COSMETIC AND LASER THERAPY"},
      {"name":"CRIME LAW AND SOCIAL CHANGE"},
      {"name":"SERVICE SCIENCE"},
      {"name":"HUMAN RIGHTS LAW REVIEW"},
      {"name":"JOURNAL OF MATHEMATICS"},
      {"name":"ZEITSCHRIFT FUR ANGEWANDTE MATHEMATIK UND PHYSIK"},
      {"name":"LASER AND PARTICLE BEAMS"},
      {"name":"ARCHIVOS LATINOAMERICANOS DE NUTRICION"},
      {"name":"ARCHIVE OF APPLIED MECHANICS"},
      {"name":"JOURNAL OF MOTOR BEHAVIOR"},
      {"name":"DIAGNOSTIC CYTOPATHOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF COMPUTER MATHEMATICS"},
      {"name":"JOURNAL OF ADOLESCENT RESEARCH"},
      {"name":"LIFESTYLE GENOMICS"},
      {"name":"AMERICAN MIDLAND NATURALIST"},
      {"name":"CHEMICAL RESEARCH IN CHINESE UNIVERSITIES"},
      {"name":"MEMBRANE AND WATER TREATMENT"},
      {"name":"ARCHAEOLOGICAL PROSPECTION"},
      {"name":"IEEE TRANSACTIONS ON MAGNETICS"},
      {"name":"QUINTESSENCE INTERNATIONAL"},
      {"name":"IMPLANT DENTISTRY"},
      {"name":"JOURNAL OF MICROPALAEONTOLOGY"},
      {"name":"CHINESE PHYSICS LETTERS"},
      {"name":"CHEMIE INGENIEUR TECHNIK"},
      {"name":"CLINICAL GERONTOLOGIST"},
      {"name":"WISCONSIN LAW REVIEW"},
      {"name":"JOURNAL OF LIBRARIANSHIP AND INFORMATION SCIENCE"},
      {"name":"ZOOSYSTEMATICS AND EVOLUTION"},
      {"name":"CZECH JOURNAL OF GENETICS AND PLANT BREEDING"},
      {"name":"GRASS AND FORAGE SCIENCE"},
      {"name":"COMMUNICATION DISORDERS QUARTERLY"},
      {"name":"POPULATION"},
      {"name":"RUSSIAN JOURNAL OF COORDINATION CHEMISTRY"},
      {"name":"STATISTICAL ANALYSIS AND DATA MINING"},
      {"name":"JOURNAL OF CONTEXTUAL BEHAVIORAL SCIENCE"},
      {"name":"DOCUMENTA OPHTHALMOLOGICA"},
      {"name":"CHINESE MANAGEMENT STUDIES"},
      {"name":"INTERNATIONAL ECONOMIC REVIEW"},
      {"name":"THEORY IN BIOSCIENCES"},
      {"name":"GAYANA BOTANICA"},
      {"name":"OIL SHALE"},
      {"name":"INTERVIROLOGY"},
      {"name":"PEDIATRIC EMERGENCY CARE"},
      {"name":"JOURNAL OF CARDIAC SURGERY"},
      {"name":"MODERN CHINA"},
      {"name":"SCOTTISH JOURNAL OF GEOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF LAW AND PSYCHIATRY"},
      {"name":"JOURNAL OF ELECTROMAGNETIC WAVES AND APPLICATIONS"},
      {"name":"SOFTWARE QUALITY JOURNAL"},
      {"name":"COMMUNITY MENTAL HEALTH JOURNAL"},
      {"name":"ELECTRONICS LETTERS"},
      {"name":"INTERNATIONAL REVIEW OF FINANCE"},
      {"name":"ACM TRANSACTIONS ON COMPUTER SYSTEMS"},
      {"name":"COMMUNICATIONS IN MATHEMATICAL SCIENCES"},
      {"name":"INTERNATIONAL JOURNAL OF GEOMETRIC METHODS IN MODERN PHYSICS"},
      {"name":"INTERNATIONAL JOURNAL OF OPTOMECHATRONICS"},
      {"name":"JOURNAL OF OCEAN UNIVERSITY OF CHINA"},
      {"name":"JOURNAL OF VASCULAR ACCESS"},
      {"name":"MATERIALS EXPRESS"},
      {"name":"ENVIRONMENTAL BIOLOGY OF FISHES"},
      {"name":"GEOLOGICAL SURVEY OF DENMARK AND GREENLAND BULLETIN"},
      {"name":"JOURNAL OF NURSING ADMINISTRATION"},
      {"name":"JOURNAL OF CORPORATE LAW STUDIES"},
      {"name":"LANGUAGE ACQUISITION"},
      {"name":"POPULATION RESEARCH AND POLICY REVIEW"},
      {"name":"JOURNAL OF MANAGERIAL PSYCHOLOGY"},
      {"name":"VISUAL COMPUTER"},
      {"name":"JOURNAL OF PUBLIC ECONOMIC THEORY"},
      {"name":"EUROPEAN JOURNAL FOR PHILOSOPHY OF SCIENCE"},
      {"name":"JOURNAL OF BACK AND MUSCULOSKELETAL REHABILITATION"},
      {"name":"JOURNAL OF DRUG ISSUES"},
      {"name":"JOURNAL OF AFRICAN EARTH SCIENCES"},
      {"name":"PHYTOKEYS"},
      {"name":"ORAL HISTORY REVIEW"},
      {"name":"ANNALS OF CARNEGIE MUSEUM"},
      {"name":"FORENSIC SCIENCE MEDICINE AND PATHOLOGY"},
      {"name":"EUROPEAN JOURNAL OF INDUSTRIAL RELATIONS"},
      {"name":"REVISTA MEXICANA DE INGENIERIA QUIMICA"},
      {"name":"RUSSIAN MATHEMATICAL SURVEYS"},
      {"name":"JOURNAL OF EMERGENCY MEDICINE"},
      {"name":"INLAND WATERS"},
      {"name":"ARCHAEOLOGY IN OCEANIA"},
      {"name":"UNIVERSITY OF ILLINOIS LAW REVIEW"},
      {"name":"ACM TRANSACTIONS ON RECONFIGURABLE TECHNOLOGY AND SYSTEMS"},
      {"name":"NATURAL RESOURCES FORUM"},
      {"name":"FINITE FIELDS AND THEIR APPLICATIONS"},
      {"name":"POLAR SCIENCE"},
      {"name":"HANDCHIRURGIE MIKROCHIRURGIE PLASTISCHE CHIRURGIE"},
      {"name":"DOKLADY BIOCHEMISTRY AND BIOPHYSICS"},
      {"name":"WATER ENVIRONMENT RESEARCH"},
      {"name":"JOURNAL OF ANIMAL AND FEED SCIENCES"},
      {"name":"WORLD ECONOMY"},
      {"name":"ANNALES DE PALEONTOLOGIE"},
      {"name":"VETERINARY RESEARCH COMMUNICATIONS"},
      {"name":"INTERNATIONAL JOURNAL OF APPLIED MATHEMATICS AND COMPUTER SCIENCE"},
      {"name":"IAWA JOURNAL"},
      {"name":"INTERNATIONAL JOURNAL OF BIOSTATISTICS"},
      {"name":"EUROPEAN JOURNAL OF INDUSTRIAL ENGINEERING"},
      {"name":"JOURNAL OF INSTRUMENTATION"},
      {"name":"JOURNAL OF SEISMOLOGY"},
      {"name":"ARCHIVES OF IRANIAN MEDICINE"},
      {"name":"HISTORY OF THE HUMAN SCIENCES"},
      {"name":"EASTERN MEDITERRANEAN HEALTH JOURNAL"},
      {"name":"GASTROENTEROLOGIA Y HEPATOLOGIA"},
      {"name":"PCI JOURNAL"},
      {"name":"SOLA"},
      {"name":"MINERVA ENDOCRINOLOGICA"},
      {"name":"HERPETOLOGICA"},
      {"name":"CHILDREN AND YOUTH SERVICES REVIEW"},
      {"name":"JOURNAL OF ANALYTICAL CHEMISTRY"},
      {"name":"IEEE TRANSACTIONS ON PROFESSIONAL COMMUNICATION"},
      {"name":"PETROPHYSICS"},
      {"name":"COMMUNICATIONS IN CONTEMPORARY MATHEMATICS"},
      {"name":"SEMICONDUCTORS AND SEMIMETALS"},
      {"name":"JOURNAL OF ACADEMIC LIBRARIANSHIP"},
      {"name":"CARPATHIAN JOURNAL OF EARTH AND ENVIRONMENTAL SCIENCES"},
      {"name":"SOCIOLOGIA"},
      {"name":"CHINA FOUNDRY"},
      {"name":"BASIC AND APPLIED SOCIAL PSYCHOLOGY"},
      {"name":"ASIAN AMERICAN JOURNAL OF PSYCHOLOGY"},
      {"name":"SEMINARS IN PEDIATRIC NEUROLOGY"},
      {"name":"JOURNAL OF NERVOUS AND MENTAL DISEASE"},
      {"name":"MOLECULAR AND BIOCHEMICAL PARASITOLOGY"},
      {"name":"INTERNATIONAL FINANCE"},
      {"name":"EXPOSITIONES MATHEMATICAE"},
      {"name":"FOREST PATHOLOGY"},
      {"name":"SEMINARS IN INTERVENTIONAL RADIOLOGY"},
      {"name":"GERODONTOLOGY"},
      {"name":"MATHEMATICAL CONTROL AND RELATED FIELDS"},
      {"name":"WILDLIFE SOCIETY BULLETIN"},
      {"name":"JOURNAL OF TRAUMA NURSING"},
      {"name":"JOURNAL OF ANESTHESIA"},
      {"name":"JOURNAL OF BUSINESS AND TECHNICAL COMMUNICATION"},
      {"name":"MARINE BIOLOGY RESEARCH"},
      {"name":"OPEN ECONOMIES REVIEW"},
      {"name":"ACTA OTORHINOLARYNGOLOGICA ITALICA"},
      {"name":"REVISTA CIENCIA AGRONOMICA"},
      {"name":"KOEDOE"},
      {"name":"HERZ"},
      {"name":"NUCLEAR SCIENCE AND ENGINEERING"},
      {"name":"REVISTA DE CERCETARE SI INTERVENTIE SOCIALA"},
      {"name":"SURVEY METHODOLOGY"},
      {"name":"DANISH MEDICAL JOURNAL"},
      {"name":"JOURNAL AMERICAN WATER WORKS ASSOCIATION"},
      {"name":"LIMNOLOGY"},
      {"name":"THEORETICAL POPULATION BIOLOGY"},
      {"name":"JOURNAL OF CENTRAL SOUTH UNIVERSITY"},
      {"name":"ASTRONOMY REPORTS"},
      {"name":"MODERN LAW REVIEW"},
      {"name":"JOURNAL OF THE AMERICAN ACADEMY OF PSYCHIATRY AND THE LAW"},
      {"name":"STOCHASTIC PROCESSES AND THEIR APPLICATIONS"},
      {"name":"INTERNATIONAL SOCIOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF ELECTROCHEMICAL SCIENCE"},
      {"name":"INTERNATIONAL JOURNAL OF CHEMICAL KINETICS"},
      {"name":"ACTA MICROSCOPICA"},
      {"name":"FLY"},
      {"name":"INFORMATION PROCESSING LETTERS"},
      {"name":"INTERNATIONAL JOURNAL OF OIL GAS AND COAL TECHNOLOGY"},
      {"name":"JOURNAL OF EVOLUTION EQUATIONS"},
      {"name":"JOURNAL OF FAMILY ISSUES"},
      {"name":"COGNITIVE NEUROPSYCHIATRY"},
      {"name":"CAMBRIDGE JOURNAL OF MATHEMATICS"},
      {"name":"EUROPEAN PHYSICAL JOURNAL D"},
      {"name":"JOURNAL OF SOCIAL WORK"},
      {"name":"JOURNAL OF NEMATOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF MODERN PHYSICS E"},
      {"name":"SECURITY JOURNAL"},
      {"name":"MARINE BIODIVERSITY"},
      {"name":"INTERNATIONAL RELATIONS"},
      {"name":"CANADIAN JOURNAL OF EARTH SCIENCES"},
      {"name":"JOURNAL OF APPLIED FLUID MECHANICS"},
      {"name":"JOURNAL OF BIOLOGICAL PHYSICS"},
      {"name":"SHOCK AND VIBRATION"},
      {"name":"CLINICAL CHILD PSYCHOLOGY AND PSYCHIATRY"},
      {"name":"AUSTRALIAN JOURNAL OF GENERAL PRACTICE"},
      {"name":"NEW GENETICS AND SOCIETY"},
      {"name":"ASSISTIVE TECHNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF COMMUNICATION SYSTEMS"},
      {"name":"MECHANICAL SCIENCES"},
      {"name":"PROGRESS IN TRANSPLANTATION"},
      {"name":"CLINICAL NEUROPHARMACOLOGY"},
      {"name":"JOURNAL OF THE AMERICAN HELICOPTER SOCIETY"},
      {"name":"ANNALS OF DYSLEXIA"},
      {"name":"RESEARCH IN HUMAN DEVELOPMENT"},
      {"name":"APPLIED RHEOLOGY"},
      {"name":"CLINICAL BIOMECHANICS"},
      {"name":"INTERNATIONAL JOURNAL OF OSTEOARCHAEOLOGY"},
      {"name":"JOURNAL OF PSYCHOSOCIAL NURSING AND MENTAL HEALTH SERVICES"},
      {"name":"HIGH ABILITY STUDIES"},
      {"name":"SOCIAL ANTHROPOLOGY"},
      {"name":"JOURNAL OF SYMBOLIC COMPUTATION"},
      {"name":"EUROPEAN POULTRY SCIENCE"},
      {"name":"TURKISH JOURNAL OF BOTANY"},
      {"name":"VETERINARY SURGERY"},
      {"name":"OPTOMETRY AND VISION SCIENCE"},
      {"name":"INTERNATIONAL STUDIES PERSPECTIVES"},
      {"name":"INTERNATIONAL JOURNAL OF CORPUS LINGUISTICS"},
      {"name":"JOURNAL OF INDIVIDUAL DIFFERENCES"},
      {"name":"COMPUTATIONAL COMPLEXITY"},
      {"name":"JOURNAL OF THE GEOLOGICAL SOCIETY OF INDIA"},
      {"name":"AUSTRALIAN JOURNAL OF MANAGEMENT"},
      {"name":"JOURNAL OF COMPARATIVE EFFECTIVENESS RESEARCH"},
      {"name":"SOCIAL CHOICE AND WELFARE"},
      {"name":"JOURNAL OF TOXICOLOGIC PATHOLOGY"},
      {"name":"JOURNAL OF CARDIOTHORACIC SURGERY"},
      {"name":"AVIAN RESEARCH"},
      {"name":"JOURNAL OF GENERAL PLANT PATHOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF PEPTIDE RESEARCH AND THERAPEUTICS"},
      {"name":"CUTIS"},
      {"name":"JOURNAL OF STRAIN ANALYSIS FOR ENGINEERING DESIGN"},
      {"name":"FAMILY RELATIONS"},
      {"name":"REAL ESTATE ECONOMICS"},
      {"name":"ADVANCES IN STRUCTURAL ENGINEERING"},
      {"name":"BIOSEMIOTICS"},
      {"name":"BULLETIN OF THE LONDON MATHEMATICAL SOCIETY"},
      {"name":"AFRICAN INVERTEBRATES"},
      {"name":"JOURNAL OF TIME SERIES ANALYSIS"},
      {"name":"EUROPEAN JOURNAL OF LAW AND ECONOMICS"},
      {"name":"LIBRARY QUARTERLY"},
      {"name":"ACTA OF BIOENGINEERING AND BIOMECHANICS"},
      {"name":"DEUTSCHE ENTOMOLOGISCHE ZEITSCHRIFT"},
      {"name":"SCIENTIFIC AMERICAN"},
      {"name":"INTERNATIONAL JOURNAL OF METALCASTING"},
      {"name":"AMERICAN JOURNAL OF PERINATOLOGY"},
      {"name":"INDIAN JOURNAL OF TRADITIONAL KNOWLEDGE"},
      {"name":"CLINICAL AND INVESTIGATIVE MEDICINE"},
      {"name":"WEED BIOLOGY AND MANAGEMENT"},
      {"name":"ACM TRANSACTIONS ON INTERACTIVE INTELLIGENT SYSTEMS"},
      {"name":"APPLICABLE ANALYSIS AND DISCRETE MATHEMATICS"},
      {"name":"SIAM JOURNAL ON COMPUTING"},
      {"name":"JOURNAL OF THE INDIAN SOCIETY OF REMOTE SENSING"},
      {"name":"DISCRETE DYNAMICS IN NATURE AND SOCIETY"},
      {"name":"PHARMAZIE"},
      {"name":"ELECTRONIC JOURNAL OF DIFFERENTIAL EQUATIONS"},
      {"name":"HETEROATOM CHEMISTRY"},
      {"name":"CAMBRIDGE QUARTERLY OF HEALTHCARE ETHICS"},
      {"name":"VETERINARY MEDICINE AND SCIENCE"},
      {"name":"SCIENCE AND TECHNOLOGY FOR THE BUILT ENVIRONMENT"},
      {"name":"BULLETIN OF MATERIALS SCIENCE"},
      {"name":"SCIENTIA MARINA"},
      {"name":"ADVANCED ROBOTICS"},
      {"name":"GRAPHICAL MODELS"},
      {"name":"ASYMPTOTIC ANALYSIS"},
      {"name":"JOURNAL OF TRADITIONAL CHINESE MEDICINE"},
      {"name":"INTERNATIONAL JOURNAL OF ISLAMIC AND MIDDLE EASTERN FINANCE AND MANAGEMENT"},
      {"name":"IET SOFTWARE"},
      {"name":"PADDY AND WATER ENVIRONMENT"},
      {"name":"ECONOMICA"},
      {"name":"MEDIZINISCHE GENETIK"},
      {"name":"JOURNAL OF MULTICULTURAL COUNSELING AND DEVELOPMENT"},
      {"name":"NATURAL LANGUAGE ENGINEERING"},
      {"name":"JOURNAL OF APPLIED BIOMECHANICS"},
      {"name":"JOURNAL OF EAST ASIAN STUDIES"},
      {"name":"EXPERIMENTAL AGING RESEARCH"},
      {"name":"JOURNAL OF CONSUMER PROTECTION AND FOOD SAFETY"},
      {"name":"LINGUISTIC INQUIRY"},
      {"name":"MATHEMATICAL PROCEEDINGS OF THE CAMBRIDGE PHILOSOPHICAL SOCIETY"},
      {"name":"EUROPEAN EDUCATIONAL RESEARCH JOURNAL"},
      {"name":"JOURNAL OF APPLIED POULTRY RESEARCH"},
      {"name":"GROWTH AND CHANGE"},
      {"name":"JOURNAL OF SOCIOLOGY"},
      {"name":"CROATIAN MEDICAL JOURNAL"},
      {"name":"INFORMATION VISUALIZATION"},
      {"name":"TRANSFUSION CLINIQUE ET BIOLOGIQUE"},
      {"name":"AMERICAN JOURNAL OF EDUCATION"},
      {"name":"RAFFLES BULLETIN OF ZOOLOGY"},
      {"name":"JOURNAL OF PLANT BIOCHEMISTRY AND BIOTECHNOLOGY"},
      {"name":"JOURNAL OF PLANT NUTRITION"},
      {"name":"REVIEW OF COGNITIVE LINGUISTICS"},
      {"name":"INTERNATIONAL JOURNAL OF FOOD ENGINEERING"},
      {"name":"JOURNAL OF EMPIRICAL LEGAL STUDIES"},
      {"name":"STRENGTH AND CONDITIONING JOURNAL"},
      {"name":"AEROSPACE MEDICINE AND HUMAN PERFORMANCE"},
      {"name":"QUARTERLY JOURNAL OF SPEECH"},
      {"name":"APPLIED NURSING RESEARCH"},
      {"name":"SPORT PSYCHOLOGIST"},
      {"name":"NEBRASKA SYMPOSIUM ON MOTIVATION"},
      {"name":"OXFORD REVIEW OF EDUCATION"},
      {"name":"JOURNAL OF THE HISTORY OF MEDICINE AND ALLIED SCIENCES"},
      {"name":"GERMAN ECONOMIC REVIEW"},
      {"name":"INTERNATIONAL JOURNAL OF GERONTOLOGY"},
      {"name":"ANNALS OF INDIAN ACADEMY OF NEUROLOGY"},
      {"name":"CURRENT PHARMACEUTICAL ANALYSIS"},
      {"name":"SCANDINAVIAN POLITICAL STUDIES"},
      {"name":"INTERNATIONAL JOURNAL OF ANTENNAS AND PROPAGATION"},
      {"name":"GEOLOGICAL JOURNAL"},
      {"name":"DYNAMIC GAMES AND APPLICATIONS"},
      {"name":"INTERNATIONAL JOURNAL OF CLINICAL PHARMACY"},
      {"name":"ELECTRONIC TRANSACTIONS ON NUMERICAL ANALYSIS"},
      {"name":"REVISTA BRASILEIRA DE PARASITOLOGIA VETERINARIA"},
      {"name":"JOURNAL OF MUSIC THERAPY"},
      {"name":"JOURNAL OF ORTHOPAEDIC SURGERY"},
      {"name":"SOCIAL ANALYSIS"},
      {"name":"KINESIOLOGY"},
      {"name":"PSYCHIATRIA POLSKA"},
      {"name":"HEALTH AND HUMAN RIGHTS"},
      {"name":"ACTA CARSOLOGICA"},
      {"name":"INTERNATIONAL JOURNAL OF ELECTRICAL ENGINEERING EDUCATION"},
      {"name":"JOURNAL OF ALGEBRAIC GEOMETRY"},
      {"name":"ACTA BOTANICA BRASILICA"},
      {"name":"SCANDINAVIAN JOURNAL OF EDUCATIONAL RESEARCH"},
      {"name":"RESEARCH IN GERONTOLOGICAL NURSING"},
      {"name":"HISTORY AND THEORY"},
      {"name":"BOREAL ENVIRONMENT RESEARCH"},
      {"name":"MISKOLC MATHEMATICAL NOTES"},
      {"name":"NURSE EDUCATION IN PRACTICE"},
      {"name":"ROBOTICA"},
      {"name":"VOLUNTAS"},
      {"name":"INTERNATIONAL JOURNAL OF MOBILE COMMUNICATIONS"},
      {"name":"JOURNAL FOR SPECIALISTS IN PEDIATRIC NURSING"},
      {"name":"ACM TRANSACTIONS ON COMPUTATIONAL LOGIC"},
      {"name":"FAMILIES RELATIONSHIPS AND SOCIETIES"},
      {"name":"ACTA SOCIETATIS BOTANICORUM POLONIAE"},
      {"name":"AMERICAN JOURNAL OF POTATO RESEARCH"},
      {"name":"WORLD RABBIT SCIENCE"},
      {"name":"REVIEW OF POLICY RESEARCH"},
      {"name":"CLINICAL LABORATORY"},
      {"name":"PACKAGING TECHNOLOGY AND SCIENCE"},
      {"name":"JOURNAL OF FOREST RESEARCH"},
      {"name":"MEDICINE SCIENCE AND THE LAW"},
      {"name":"INTERNATIONAL JOURNAL OF MANPOWER"},
      {"name":"JOURNAL OF THERMOPHYSICS AND HEAT TRANSFER"},
      {"name":"EVALUATION"},
      {"name":"CANADIAN JOURNAL OF ZOOLOGY"},
      {"name":"LAW AND PHILOSOPHY"},
      {"name":"POLICY STUDIES"},
      {"name":"POSTCOLONIAL STUDIES"},
      {"name":"GEOLOGICA ACTA"},
      {"name":"CELL AND TISSUE BANKING"},
      {"name":"CALIFORNIA COOPERATIVE OCEANIC FISHERIES INVESTIGATIONS REPORTS"},
      {"name":"SOUTH AFRICAN JOURNAL OF EDUCATION"},
      {"name":"RESOURCE GEOLOGY"},
      {"name":"INTERNATIONAL MULTILINGUAL RESEARCH JOURNAL"},
      {"name":"BULLETIN OF THE HISTORY OF MEDICINE"},
      {"name":"GROUP DECISION AND NEGOTIATION"},
      {"name":"CARNETS DE GEOLOGIE"},
      {"name":"JOURNAL OF GENETIC PSYCHOLOGY"},
      {"name":"PEDIATRIC EXERCISE SCIENCE"},
      {"name":"AMERICAN JOURNAL OF MENS HEALTH"},
      {"name":"MATHEMATICAL METHODS OF OPERATIONS RESEARCH"},
      {"name":"LANGUAGE LEARNING AND DEVELOPMENT"},
      {"name":"RUSSIAN GEOLOGY AND GEOPHYSICS"},
      {"name":"EXPERIMENTAL AND CLINICAL TRANSPLANTATION"},
      {"name":"IEEE COMPUTER ARCHITECTURE LETTERS"},
      {"name":"EURASIP JOURNAL ON AUDIO SPEECH AND MUSIC PROCESSING"},
      {"name":"MILITARY MEDICINE"},
      {"name":"MICROWAVE AND OPTICAL TECHNOLOGY LETTERS"},
      {"name":"LAND ECONOMICS"},
      {"name":"NEW REPUBLIC"},
      {"name":"TRANSLATION STUDIES"},
      {"name":"ANNALS OF TRANSPLANTATION"},
      {"name":"JOURNAL OF MICROSCOPY"},
      {"name":"SKELETAL RADIOLOGY"},
      {"name":"FOOD SCIENCE AND TECHNOLOGY INTERNATIONAL"},
      {"name":"JOURNAL OF APPROXIMATION THEORY"},
      {"name":"HISTORY OF THE FAMILY"},
      {"name":"ATOMIC SPECTROSCOPY"},
      {"name":"JOURNAL OF JAPANESE STUDIES"},
      {"name":"INTERNATIONAL JOURNAL OF MODERN PHYSICS B"},
      {"name":"PALAEOWORLD"},
      {"name":"JOURNAL OF PLANT BIOLOGY"},
      {"name":"STATISTICAL APPLICATIONS IN GENETICS AND MOLECULAR BIOLOGY"},
      {"name":"KOREAN JOURNAL OF PAIN"},
      {"name":"INTERNATIONAL JOURNAL OF CLINICAL PHARMACOLOGY AND THERAPEUTICS"},
      {"name":"REVIEW OF ECONOMIC DYNAMICS"},
      {"name":"INTERNATIONAL JOURNAL OF DISTRIBUTED SENSOR NETWORKS"},
      {"name":"MATHEMATICAL METHODS IN THE APPLIED SCIENCES"},
      {"name":"MOLECULAR CYTOGENETICS"},
      {"name":"EUROPEAN HEART JOURNAL SUPPLEMENTS"},
      {"name":"IRANIAN JOURNAL OF FISHERIES SCIENCES"},
      {"name":"OPHTHALMIC GENETICS"},
      {"name":"OXFORD BULLETIN OF ECONOMICS AND STATISTICS"},
      {"name":"EUROPEAN PHYSICAL JOURNAL H"},
      {"name":"PALYNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF SURGICAL PATHOLOGY"},
      {"name":"ADVANCES IN HIGH ENERGY PHYSICS"},
      {"name":"INFORMATION TECHNOLOGY AND LIBRARIES"},
      {"name":"JOURNAL OF FUTURES MARKETS"},
      {"name":"CARDIOLOGY IN THE YOUNG"},
      {"name":"EUROPEAN JOURNAL OF COMBINATORICS"},
      {"name":"COGNITIVE PROCESSING"},
      {"name":"JOURNAL OF IBERIAN GEOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF ARTS MANAGEMENT"},
      {"name":"KNOWLEDGE ORGANIZATION"},
      {"name":"JOURNAL OF ESSENTIAL OIL RESEARCH"},
      {"name":"JOURNAL OF AUTOMATED REASONING"},
      {"name":"PERFORMANCE EVALUATION"},
      {"name":"CZECH JOURNAL OF ANIMAL SCIENCE"},
      {"name":"DENDROBIOLOGY"},
      {"name":"TOBACCO INDUCED DISEASES"},
      {"name":"MALAYSIAN JOURNAL OF COMPUTER SCIENCE"},
      {"name":"PEDIATRIC NEUROSURGERY"},
      {"name":"JOURNAL OF SHELLFISH RESEARCH"},
      {"name":"POLYMER SCIENCE SERIES A"},
      {"name":"JOURNAL OF GEOPHYSICS AND ENGINEERING"},
      {"name":"ANNALS OF MATHEMATICS AND ARTIFICIAL INTELLIGENCE"},
      {"name":"JOURNAL OF ASTROPHYSICS AND ASTRONOMY"},
      {"name":"BALTICA"},
      {"name":"EURASIP JOURNAL ON ADVANCES IN SIGNAL PROCESSING"},
      {"name":"PROSTATE INTERNATIONAL"},
      {"name":"JOURNAL OF ETHNOBIOLOGY"},
      {"name":"CLINICAL PSYCHOLOGIST"},
      {"name":"PAKISTAN JOURNAL OF MEDICAL SCIENCES"},
      {"name":"JOURNAL OF THE MATHEMATICAL SOCIETY OF JAPAN"},
      {"name":"CHEMISTRY LETTERS"},
      {"name":"CLINICAL NEPHROLOGY"},
      {"name":"PSYCHIATRIC GENETICS"},
      {"name":"SIAM JOURNAL ON DISCRETE MATHEMATICS"},
      {"name":"JOURNAL OF THE FACULTY OF ENGINEERING AND ARCHITECTURE OF GAZI UNIVERSITY"},
      {"name":"JOURNAL OF FISH BIOLOGY"},
      {"name":"PERSPECTIVES IN BIOLOGY AND MEDICINE"},
      {"name":"PEDIATRIC CARDIOLOGY"},
      {"name":"ANTHROPOLOGY SOUTHERN AFRICA"},
      {"name":"MARINE AND FRESHWATER BEHAVIOUR AND PHYSIOLOGY"},
      {"name":"GLOBAL GOVERNANCE"},
      {"name":"REVISTA CAATINGA"},
      {"name":"WAVE MOTION"},
      {"name":"MEDICAL DOSIMETRY"},
      {"name":"JOURNAL OF EXPERIMENTAL PSYCHOPATHOLOGY"},
      {"name":"MATERIALS AT HIGH TEMPERATURES"},
      {"name":"ANNALS OF THE INSTITUTE OF STATISTICAL MATHEMATICS"},
      {"name":"TOXICOLOGICAL AND ENVIRONMENTAL CHEMISTRY"},
      {"name":"MATHEMATICAL SCIENCES"},
      {"name":"JOURNAL OF GEOMETRY AND PHYSICS"},
      {"name":"KSCE JOURNAL OF CIVIL ENGINEERING"},
      {"name":"JOURNAL OF THE ROYAL ARMY MEDICAL CORPS"},
      {"name":"SCIENCE OF SINTERING"},
      {"name":"CEREAL RESEARCH COMMUNICATIONS"},
      {"name":"PHONOLOGY"},
      {"name":"NONLINEAR DYNAMICS PSYCHOLOGY AND LIFE SCIENCES"},
      {"name":"BRAIN IMPAIRMENT"},
      {"name":"PHYTOCHEMISTRY LETTERS"},
      {"name":"BIOMETRIKA"},
      {"name":"CLOTHING AND TEXTILES RESEARCH JOURNAL"},
      {"name":"ZEITSCHRIFT FUR GEOMORPHOLOGIE"},
      {"name":"JOURNAL OF OLEO SCIENCE"},
      {"name":"AFRICAN JOURNAL OF REPRODUCTIVE HEALTH"},
      {"name":"INDIAN JOURNAL OF PHARMACOLOGY"},
      {"name":"DISCOURSE PROCESSES"},
      {"name":"PROGRESS IN CHEMISTRY"},
      {"name":"PARALLEL COMPUTING"},
      {"name":"CURRENT ISSUES IN LANGUAGE PLANNING"},
      {"name":"TRANSACTIONS OF THE ROYAL SOCIETY OF SOUTH AUSTRALIA"},
      {"name":"PAKISTAN VETERINARY JOURNAL"},
      {"name":"NEW ZEALAND JOURNAL OF ECOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF MODERN PHYSICS A"},
      {"name":"SOIL SCIENCE"},
      {"name":"ALGORITHMS FOR MOLECULAR BIOLOGY"},
      {"name":"RAMANUJAN JOURNAL"},
      {"name":"DYNAMICS OF ATMOSPHERES AND OCEANS"},
      {"name":"ACM TRANSACTIONS ON THE WEB"},
      {"name":"INTERNATIONAL JOURNAL OF THEORETICAL PHYSICS"},
      {"name":"SUPRAMOLECULAR CHEMISTRY"},
      {"name":"ADVANCES IN APPLIED MATHEMATICS"},
      {"name":"SOCIAL POLITICS"},
      {"name":"JOURNAL OF MULTILINGUAL AND MULTICULTURAL DEVELOPMENT"},
      {"name":"TRANSACTIONS OF THE INSTITUTE OF METAL FINISHING"},
      {"name":"QUALITY ENGINEERING"},
      {"name":"JOURNAL OF ELECTRONIC IMAGING"},
      {"name":"NEUROPEDIATRICS"},
      {"name":"INTERNATIONAL JOURNAL OF SCIENCE EDUCATION"},
      {"name":"MITOCHONDRIAL DNA PART A"},
      {"name":"CALCULUS OF VARIATIONS AND PARTIAL DIFFERENTIAL EQUATIONS"},
      {"name":"JOURNAL OF HEALTH ORGANIZATION AND MANAGEMENT"},
      {"name":"ONDERSTEPOORT JOURNAL OF VETERINARY RESEARCH"},
      {"name":"JOURNAL OF NEAR INFRARED SPECTROSCOPY"},
      {"name":"NEW PERSPECTIVES ON TURKEY"},
      {"name":"ZOOLOGIA"},
      {"name":"ACTA ETHOLOGICA"},
      {"name":"INTERNATIONAL JOURNAL OF MARKET RESEARCH"},
      {"name":"CROATICA CHEMICA ACTA"},
      {"name":"ANNALS OF PURE AND APPLIED LOGIC"},
      {"name":"JOURNAL OF RESEARCH ON TECHNOLOGY IN EDUCATION"},
      {"name":"AQUATIC BIOLOGY"},
      {"name":"MIND CULTURE AND ACTIVITY"},
      {"name":"PSYCH JOURNAL"},
      {"name":"TECHNICAL PHYSICS LETTERS"},
      {"name":"UKRAINIAN JOURNAL OF PHYSICAL OPTICS"},
      {"name":"CURRENT MEDICAL SCIENCE"},
      {"name":"SOUTH AFRICAN JOURNAL OF PSYCHIATRY"},
      {"name":"CONTEMPORARY NURSE"},
      {"name":"WIRELESS PERSONAL COMMUNICATIONS"},
      {"name":"SEXUALITY AND DISABILITY"},
      {"name":"EMPLOYEE RELATIONS"},
      {"name":"LATIN AMERICAN JOURNAL OF SOLIDS AND STRUCTURES"},
      {"name":"JOURNAL OF THE SOCIETY FOR INFORMATION DISPLAY"},
      {"name":"WIENER KLINISCHE WOCHENSCHRIFT"},
      {"name":"INTERNATIONAL JOURNAL OF AD HOC AND UBIQUITOUS COMPUTING"},
      {"name":"ANGLE ORTHODONTIST"},
      {"name":"ZOOTAXA"},
      {"name":"RADICAL PHILOSOPHY"},
      {"name":"JOURNAL OF TRANSCULTURAL NURSING"},
      {"name":"ANAIS DA ACADEMIA BRASILEIRA DE CIENCIAS"},
      {"name":"DEMOGRAPHIC RESEARCH"},
      {"name":"PEACEBUILDING"},
      {"name":"COMPUTER SCIENCE AND INFORMATION SYSTEMS"},
      {"name":"INTERNATIONAL JOURNAL OF DAIRY TECHNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF CHEMICAL REACTOR ENGINEERING"},
      {"name":"GEOGRAPHICAL RESEARCH"},
      {"name":"APPLIED PSYCHOLINGUISTICS"},
      {"name":"ADAPTED PHYSICAL ACTIVITY QUARTERLY"},
      {"name":"JOURNAL OF FIELD ORNITHOLOGY"},
      {"name":"JOURNAL OF RISK AND INSURANCE"},
      {"name":"MINNESOTA LAW REVIEW"},
      {"name":"REVIEW OF PALAEOBOTANY AND PALYNOLOGY"},
      {"name":"ANNALS OF FUNCTIONAL ANALYSIS"},
      {"name":"INTERNATIONAL JOURNAL OF CIVIL ENGINEERING"},
      {"name":"JOURNAL OF SYSTEMS ENGINEERING AND ELECTRONICS"},
      {"name":"JOURNAL OF HYPERBOLIC DIFFERENTIAL EQUATIONS"},
      {"name":"BEHAVIORAL DISORDERS"},
      {"name":"READING AND WRITING"},
      {"name":"VERTEBRATE ZOOLOGY"},
      {"name":"AMERICAN JOURNAL OF ALZHEIMERS DISEASE AND OTHER DEMENTIAS"},
      {"name":"EUROPEAN JOURNAL OF EDUCATION"},
      {"name":"JOURNAL OF COMMUNICATION DISORDERS"},
      {"name":"INTERFACES"},
      {"name":"AUSTRALIAN JOURNAL OF LINGUISTICS"},
      {"name":"JOURNAL OF DYNAMICAL AND CONTROL SYSTEMS"},
      {"name":"WESTERN JOURNAL OF NURSING RESEARCH"},
      {"name":"LIMNETICA"},
      {"name":"EUROPEAN POLITICAL SCIENCE"},
      {"name":"STUDIES IN CONTINUING EDUCATION"},
      {"name":"HIGHER EDUCATION POLICY"},
      {"name":"DEFENCE AND PEACE ECONOMICS"},
      {"name":"INTERFACES AND FREE BOUNDARIES"},
      {"name":"HIGH PRESSURE RESEARCH"},
      {"name":"CHALCOGENIDE LETTERS"},
      {"name":"PHYTOCOENOLOGIA"},
      {"name":"EDUCATIONAL STUDIES"},
      {"name":"JOURNAL OF ALGEBRAIC COMBINATORICS"},
      {"name":"INTERNATIONAL JOURNAL OF POLYMER SCIENCE"},
      {"name":"JAPANESE JOURNAL OF RADIOLOGY"},
      {"name":"TURKISH JOURNAL OF FIELD CROPS"},
      {"name":"OPEN GEOSCIENCES"},
      {"name":"HEAT TRANSFER RESEARCH"},
      {"name":"ENDOCRINOLOGIA DIABETES Y NUTRICION"},
      {"name":"WEED TECHNOLOGY"},
      {"name":"CLEVELAND CLINIC JOURNAL OF MEDICINE"},
      {"name":"JOURNAL OF GEMMOLOGY"},
      {"name":"ASIAN JOURNAL OF COMMUNICATION"},
      {"name":"EMPIRICA"},
      {"name":"ANNALS OF NUCLEAR ENERGY"},
      {"name":"MACROMOLECULAR REACTION ENGINEERING"},
      {"name":"INTERNATIONAL MIGRATION"},
      {"name":"POLAR RESEARCH"},
      {"name":"IRANIAN JOURNAL OF BIOTECHNOLOGY"},
      {"name":"NEOTROPICAL ENTOMOLOGY"},
      {"name":"EPILEPTIC DISORDERS"},
      {"name":"JOURNAL OF COORDINATION CHEMISTRY"},
      {"name":"GACETA SANITARIA"},
      {"name":"NEW ZEALAND JOURNAL OF CROP AND HORTICULTURAL SCIENCE"},
      {"name":"DEVELOPMENT POLICY REVIEW"},
      {"name":"CLINICS"},
      {"name":"ECONOMIC CHANGE AND RESTRUCTURING"},
      {"name":"JOURNAL OF COSMETIC DERMATOLOGY"},
      {"name":"LANGUAGE RESOURCES AND EVALUATION"},
      {"name":"JOURNAL OF THE HISTORY OF ECONOMIC THOUGHT"},
      {"name":"EARLY CHILDHOOD EDUCATION JOURNAL"},
      {"name":"NEUROLOGIA I NEUROCHIRURGIA POLSKA"},
      {"name":"MELBOURNE UNIVERSITY LAW REVIEW"},
      {"name":"INTERNATIONAL JOURNAL OF PATTERN RECOGNITION AND ARTIFICIAL INTELLIGENCE"},
      {"name":"CONSTRAINTS"},
      {"name":"JOURNAL OF REAL ESTATE FINANCE AND ECONOMICS"},
      {"name":"ANNALES DE CHIRURGIE PLASTIQUE ESTHETIQUE"},
      {"name":"GAMES AND ECONOMIC BEHAVIOR"},
      {"name":"JOURNAL OF ADDICTIVE DISEASES"},
      {"name":"RAND JOURNAL OF ECONOMICS"},
      {"name":"DISASTER MEDICINE AND PUBLIC HEALTH PREPAREDNESS"},
      {"name":"JOURNAL OF MEDICINE AND PHILOSOPHY"},
      {"name":"JOURNAL OF SENSORS"},
      {"name":"NONLINEARITY"},
      {"name":"BIOLOGIA"},
      {"name":"JOURNAL OF COMPUTER INFORMATION SYSTEMS"},
      {"name":"EVOLUTION EQUATIONS AND CONTROL THEORY"},
      {"name":"CHINESE JOURNAL OF CHEMICAL PHYSICS"},
      {"name":"JOURNAL OF BLACK PSYCHOLOGY"},
      {"name":"EUROPEAN JOURNAL OF PHYSICS"},
      {"name":"JOURNAL OF FORENSIC SCIENCES"},
      {"name":"MIDDLE EAST POLICY"},
      {"name":"PRIMARY HEALTH CARE RESEARCH AND DEVELOPMENT"},
      {"name":"BRITISH JOURNAL OF DEVELOPMENTAL PSYCHOLOGY"},
      {"name":"GEOTECHNICAL TESTING JOURNAL"},
      {"name":"ADVANCES IN GEOMETRY"},
      {"name":"PHOSPHORUS SULFUR AND SILICON AND THE RELATED ELEMENTS"},
      {"name":"ACM TRANSACTIONS ON ARCHITECTURE AND CODE OPTIMIZATION"},
      {"name":"RESEARCH IN SOCIAL STRATIFICATION AND MOBILITY"},
      {"name":"SMALL RUMINANT RESEARCH"},
      {"name":"AFRICAN ARCHAEOLOGICAL REVIEW"},
      {"name":"JOURNAL OF AIRCRAFT"},
      {"name":"SAMJ SOUTH AFRICAN MEDICAL JOURNAL"},
      {"name":"CZECH JOURNAL OF FOOD SCIENCES"},
      {"name":"EXPERT SYSTEMS"},
      {"name":"PHILOSOPHY ETHICS AND HUMANITIES IN MEDICINE"},
      {"name":"JOURNAL OF ENVIRONMENTAL LAW"},
      {"name":"AFRICAN JOURNAL OF MARINE SCIENCE"},
      {"name":"JOURNAL OF THE BELGIAN SOCIETY OF RADIOLOGY"},
      {"name":"JOURNAL OF ENVIRONMENTAL PATHOLOGY TOXICOLOGY AND ONCOLOGY"},
      {"name":"BRAZILIAN JOURNAL OF PHARMACEUTICAL SCIENCES"},
      {"name":"JOURNAL OF IRRIGATION AND DRAINAGE ENGINEERING"},
      {"name":"COMPUTATIONAL STATISTICS"},
      {"name":"JOURNAL OF RENAL CARE"},
      {"name":"JOURNAL OF ECONOMIC METHODOLOGY"},
      {"name":"EURASIAN SOIL SCIENCE"},
      {"name":"AMERICAN JOURNAL OF VETERINARY RESEARCH"},
      {"name":"INTERNATIONAL REVIEW OF RESEARCH IN DEVELOPMENTAL DISABILITIES"},
      {"name":"JOURNAL OF FAMILY PRACTICE"},
      {"name":"INSIGHT"},
      {"name":"ACTA MICROBIOLOGICA ET IMMUNOLOGICA HUNGARICA"},
      {"name":"BRITISH JOURNAL OF LEARNING DISABILITIES"},
      {"name":"ARCHIVES EUROPEENNES DE SOCIOLOGIE"},
      {"name":"JOURNAL OF PURE AND APPLIED ALGEBRA"},
      {"name":"CARPATHIAN JOURNAL OF MATHEMATICS"},
      {"name":"CONTRIBUTIONS TO PLASMA PHYSICS"},
      {"name":"RECORDS OF NATURAL PRODUCTS"},
      {"name":"AUSTRALIAN JOURNAL OF RURAL HEALTH"},
      {"name":"CULTURA Y EDUCACION"},
      {"name":"INDIAN JOURNAL OF PSYCHIATRY"},
      {"name":"SAO PAULO MEDICAL JOURNAL"},
      {"name":"FOREST PRODUCTS JOURNAL"},
      {"name":"JOURNAL OF INVASIVE CARDIOLOGY"},
      {"name":"ELECTORAL STUDIES"},
      {"name":"REVISTA CLINICA ESPANOLA"},
      {"name":"JOURNAL OF DISABILITY POLICY STUDIES"},
      {"name":"CERNE"},
      {"name":"WATER SA"},
      {"name":"JOURNAL OF MACROECONOMICS"},
      {"name":"SCANDINAVIAN CARDIOVASCULAR JOURNAL"},
      {"name":"AUSTRALASIAN PSYCHIATRY"},
      {"name":"CANADIAN MINERALOGIST"},
      {"name":"KENNEDY INSTITUTE OF ETHICS JOURNAL"},
      {"name":"IRISH POLITICAL STUDIES"},
      {"name":"ANNALS OF HUMAN BIOLOGY"},
      {"name":"PETROLEUM CHEMISTRY"},
      {"name":"ROMANIAN JOURNAL OF PHYSICS"},
      {"name":"MEDICINE"},
      {"name":"AUSTRALIAN DENTAL JOURNAL"},
      {"name":"POLITICA Y GOBIERNO"},
      {"name":"JOURNAL OF FINANCIAL RESEARCH"},
      {"name":"KOREAN JOURNAL OF PARASITOLOGY"},
      {"name":"STATISTICS IN BIOPHARMACEUTICAL RESEARCH"},
      {"name":"JOURNAL OF HEALTH CARE FOR THE POOR AND UNDERSERVED"},
      {"name":"MATHEMATICAL PROBLEMS IN ENGINEERING"},
      {"name":"AEQUATIONES MATHEMATICAE"},
      {"name":"NEW REVIEW OF HYPERMEDIA AND MULTIMEDIA"},
      {"name":"PARLIAMENTARY AFFAIRS"},
      {"name":"PLASTIC SURGERY"},
      {"name":"INTERNATIONAL JOURNAL OF MIDDLE EAST STUDIES"},
      {"name":"EUROPEAN ZOOLOGICAL JOURNAL"},
      {"name":"SCOTTISH GEOGRAPHICAL JOURNAL"},
      {"name":"INTERNATIONAL PEACEKEEPING"},
      {"name":"AMERICAN JOURNAL OF CLINICAL HYPNOSIS"},
      {"name":"JAPANESE JOURNAL OF APPLIED PHYSICS"},
      {"name":"SOFT MATERIALS"},
      {"name":"GENETICS RESEARCH"},
      {"name":"MATHEMATICAL BIOSCIENCES"},
      {"name":"COLLECTANEA MATHEMATICA"},
      {"name":"JOURNAL OF PALEOLIMNOLOGY"},
      {"name":"ASSAY AND DRUG DEVELOPMENT TECHNOLOGIES"},
      {"name":"DISTRIBUTED COMPUTING"},
      {"name":"RUSSIAN JOURNAL OF GENERAL CHEMISTRY"},
      {"name":"LANGUAGE AND LITERATURE"},
      {"name":"EUROPEAN JOURNAL ON CRIMINAL POLICY AND RESEARCH"},
      {"name":"JOURNAL OF CANCER EDUCATION"},
      {"name":"ACTA APPLICANDAE MATHEMATICAE"},
      {"name":"ACTA PSYCHOLOGICA"},
      {"name":"INTERNATIONAL JOURNAL OF PEST MANAGEMENT"},
      {"name":"JOURNAL OF FOOD MEASUREMENT AND CHARACTERIZATION"},
      {"name":"GEOSPATIAL HEALTH"},
      {"name":"ACAROLOGIA"},
      {"name":"BRITISH POULTRY SCIENCE"},
      {"name":"JOURNAL OF OPHTHALMOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF STRATEGIC PROPERTY MANAGEMENT"},
      {"name":"ENVIRONMENTAL AND ECOLOGICAL STATISTICS"},
      {"name":"TOPOLOGICAL METHODS IN NONLINEAR ANALYSIS"},
      {"name":"PSYCHOLOGY IN THE SCHOOLS"},
      {"name":"JOURNAL OF FOURIER ANALYSIS AND APPLICATIONS"},
      {"name":"IET SYSTEMS BIOLOGY"},
      {"name":"JOURNAL OF AQUATIC ANIMAL HEALTH"},
      {"name":"LANGUAGE"},
      {"name":"JOURNAL OF CURRICULUM STUDIES"},
      {"name":"SCIENTIA IRANICA"},
      {"name":"TEACHING OF PSYCHOLOGY"},
      {"name":"BRAZILIAN JOURNAL OF CARDIOVASCULAR SURGERY"},
      {"name":"ACTA DERMATOVENEROLOGICA CROATICA"},
      {"name":"ASIAN ECONOMIC POLICY REVIEW"},
      {"name":"JOURNAL OF MEDICAL ULTRASONICS"},
      {"name":"ANNALS OF GLOBAL HEALTH"},
      {"name":"INDIAN JOURNAL OF PEDIATRICS"},
      {"name":"DRUGS OF TODAY"},
      {"name":"SPECTROSCOPY LETTERS"},
      {"name":"ST PETERSBURG MATHEMATICAL JOURNAL"},
      {"name":"JOURNAL OF BEHAVIORAL FINANCE"},
      {"name":"LANDSCAPE AND ECOLOGICAL ENGINEERING"},
      {"name":"IEEE LATIN AMERICA TRANSACTIONS"},
      {"name":"REPORTS ON MATHEMATICAL PHYSICS"},
      {"name":"GEOCHEMISTRY INTERNATIONAL"},
      {"name":"PRAGUE ECONOMIC PAPERS"},
      {"name":"GLOBAL HEALTH PROMOTION"},
      {"name":"SCIENCE AND TECHNOLOGY OF NUCLEAR INSTALLATIONS"},
      {"name":"REVIEW OF ECONOMICS OF THE HOUSEHOLD"},
      {"name":"CURRENT MOLECULAR MEDICINE"},
      {"name":"INTERNATIONAL JOURNAL OF TECHNOLOGY ASSESSMENT IN HEALTH CARE"},
      {"name":"INTERNATIONAL JOURNAL OF PSYCHOLOGICAL RESEARCH"},
      {"name":"INFORMS JOURNAL ON COMPUTING"},
      {"name":"OPERATIVE ORTHOPADIE UND TRAUMATOLOGIE"},
      {"name":"ZOOKEYS"},
      {"name":"JOURNAL OF HYDROLOGIC ENGINEERING"},
      {"name":"APPLIED BIONICS AND BIOMECHANICS"},
      {"name":"CUTANEOUS AND OCULAR TOXICOLOGY"},
      {"name":"JOURNAL OF MICROWAVE POWER AND ELECTROMAGNETIC ENERGY"},
      {"name":"OUTLOOK ON AGRICULTURE"},
      {"name":"QUALITATIVE THEORY OF DYNAMICAL SYSTEMS"},
      {"name":"REVIEW OF KEYNESIAN ECONOMICS"},
      {"name":"SOCIOLOGICAL PERSPECTIVES"},
      {"name":"DIGEST JOURNAL OF NANOMATERIALS AND BIOSTRUCTURES"},
      {"name":"PEDIATRIC PHYSICAL THERAPY"},
      {"name":"JOURNAL OF MARITAL AND FAMILY THERAPY"},
      {"name":"ELECTRICAL ENGINEERING"},
      {"name":"AUSTRALIAN JOURNAL OF CHEMISTRY"},
      {"name":"SPANISH JOURNAL OF PSYCHOLOGY"},
      {"name":"FOOT AND ANKLE CLINICS"},
      {"name":"BIOTA NEOTROPICA"},
      {"name":"JOURNAL OF MORAL EDUCATION"},
      {"name":"HIGH TEMPERATURE"},
      {"name":"JOURNAL OF SOUTHERN HEMISPHERE EARTH SYSTEMS SCIENCE"},
      {"name":"JOURNAL OF POWER ELECTRONICS"},
      {"name":"SOCIOLOGICAL QUARTERLY"},
      {"name":"QUARTERLY JOURNAL OF MATHEMATICS"},
      {"name":"ANATOLIAN JOURNAL OF CARDIOLOGY"},
      {"name":"ENSENANZA DE LAS CIENCIAS"},
      {"name":"ARGUMENTATION"},
      {"name":"ALLELOPATHY JOURNAL"},
      {"name":"JOURNAL OF ECONOMIC INEQUALITY"},
      {"name":"INTERNATIONAL JOURNAL OF TOXICOLOGY"},
      {"name":"SPATIAL STATISTICS"},
      {"name":"INTERNATIONAL MATHEMATICS RESEARCH NOTICES"},
      {"name":"PLANT PATHOLOGY JOURNAL"},
      {"name":"ANIMAL SCIENCE JOURNAL"},
      {"name":"PHONETICA"},
      {"name":"SOCIOLOGICAL INQUIRY"},
      {"name":"PROPELLANTS EXPLOSIVES PYROTECHNICS"},
      {"name":"CLINICAL NURSING RESEARCH"},
      {"name":"JOURNAL OF TROPICAL MEDICINE"},
      {"name":"JOURNAL OF NEUROGENETICS"},
      {"name":"ISSUES IN MENTAL HEALTH NURSING"},
      {"name":"CULTURE MEDICINE AND PSYCHIATRY"},
      {"name":"JOURNAL OF GLOBAL INFORMATION TECHNOLOGY MANAGEMENT"},
      {"name":"JOURNAL OF STOMATOLOGY ORAL AND MAXILLOFACIAL SURGERY"},
      {"name":"HARVARD INTERNATIONAL LAW JOURNAL"},
      {"name":"AMERICAN JOURNAL OF MEDICAL QUALITY"},
      {"name":"FLORA"},
      {"name":"NEUROREPORT"},
      {"name":"JOURNAL OF RESEARCH OF THE NATIONAL INSTITUTE OF STANDARDS AND TECHNOLOGY"},
      {"name":"CANADIAN HISTORICAL REVIEW"},
      {"name":"JOURNAL OF BIOBASED MATERIALS AND BIOENERGY"},
      {"name":"AUSTRALASIAN JOURNAL ON AGEING"},
      {"name":"SECURITY AND COMMUNICATION NETWORKS"},
      {"name":"JOURNAL OF IMMIGRANT AND MINORITY HEALTH"},
      {"name":"JOURNAL OF TRANSPORT ECONOMICS AND POLICY"},
      {"name":"JOURNAL OF ENGINEERING MATHEMATICS"},
      {"name":"SALUD MENTAL"},
      {"name":"JOURNAL OF AOAC INTERNATIONAL"},
      {"name":"DISTRIBUTED AND PARALLEL DATABASES"},
      {"name":"SURVEY RESEARCH METHODS"},
      {"name":"AMFITEATRU ECONOMIC"},
      {"name":"FEMINIST REVIEW"},
      {"name":"TIJDSCHRIFT VOOR ECONOMISCHE EN SOCIALE GEOGRAFIE"},
      {"name":"AMERICAN JOURNAL OF EVALUATION"},
      {"name":"INTERNATIONAL JOURNAL OF MODERN PHYSICS C"},
      {"name":"SPACE AND CULTURE"},
      {"name":"EUROPEAN JOURNAL OF WILDLIFE RESEARCH"},
      {"name":"AFRICAN JOURNAL OF WILDLIFE RESEARCH"},
      {"name":"AGROECOLOGY AND SUSTAINABLE FOOD SYSTEMS"},
      {"name":"AQUATIC GEOCHEMISTRY"},
      {"name":"JOURNAL OF BIOLOGICAL EDUCATION"},
      {"name":"DISSOLUTION TECHNOLOGIES"},
      {"name":"INTERNATIONAL JOURNAL OF OLDER PEOPLE NURSING"},
      {"name":"JOURNAL D ANALYSE MATHEMATIQUE"},
      {"name":"BOTANY LETTERS"},
      {"name":"TROPICAL ZOOLOGY"},
      {"name":"POLITICS AND GOVERNANCE"},
      {"name":"JOURNAL OF COMPUTER SCIENCE AND TECHNOLOGY"},
      {"name":"GEOLOGIA CROATICA"},
      {"name":"AVIAN BIOLOGY RESEARCH"},
      {"name":"MICROELECTRONICS JOURNAL"},
      {"name":"BLOOD PRESSURE MONITORING"},
      {"name":"REVISTA IBEROAMERICANA DE MICOLOGIA"},
      {"name":"COMPLEMENTARY MEDICINE RESEARCH"},
      {"name":"ZOOLOGICAL STUDIES"},
      {"name":"EUPHYTICA"},
      {"name":"ARCHIVES OF MINING SCIENCES"},
      {"name":"ANIMAL BIOLOGY"},
      {"name":"NEW ZEALAND JOURNAL OF AGRICULTURAL RESEARCH"},
      {"name":"EUROPEAN JOURNAL OF HEALTH PSYCHOLOGY"},
      {"name":"CIENCIA E TECNICA VITIVINICOLA"},
      {"name":"JOURNAL OF URBAN PLANNING AND DEVELOPMENT"},
      {"name":"JOURNAL OF MANIPULATIVE AND PHYSIOLOGICAL THERAPEUTICS"},
      {"name":"JOURNAL OF COMPUTER AND SYSTEM SCIENCES"},
      {"name":"LEADERSHIP"},
      {"name":"COMMUNICATIONS IN SOIL SCIENCE AND PLANT ANALYSIS"},
      {"name":"WELDING IN THE WORLD"},
      {"name":"BRITISH JOURNAL OF EDUCATIONAL STUDIES"},
      {"name":"EXTREMES"},
      {"name":"ZDRAVSTVENO VARSTVO"},
      {"name":"INFOR"},
      {"name":"FOUNDATIONS OF CHEMISTRY"},
      {"name":"ARKIVOC"},
      {"name":"TOP"},
      {"name":"DEVELOPING WORLD BIOETHICS"},
      {"name":"REACTION KINETICS MECHANISMS AND CATALYSIS"},
      {"name":"AUSTRALIAN SYSTEMATIC BOTANY"},
      {"name":"INTERNATIONAL DEVELOPMENT PLANNING REVIEW"},
      {"name":"THERMAL SCIENCE"},
      {"name":"CLINICAL NEUROPATHOLOGY"},
      {"name":"BALKAN MEDICAL JOURNAL"},
      {"name":"REVISTA DA ASSOCIACAO MEDICA BRASILEIRA"},
      {"name":"TEACHING SOCIOLOGY"},
      {"name":"MICROPALEONTOLOGY"},
      {"name":"JOURNAL OF NURSING EDUCATION"},
      {"name":"EUROPEAN JOURNAL OF SPECIAL NEEDS EDUCATION"},
      {"name":"JOURNAL OF POSTGRADUATE MEDICINE"},
      {"name":"SEMINARS IN SPEECH AND LANGUAGE"},
      {"name":"INTERNATIONAL LABOUR REVIEW"},
      {"name":"IET INFORMATION SECURITY"},
      {"name":"JOURNAL OF GERONTOLOGICAL SOCIAL WORK"},
      {"name":"JOURNAL OF APPLIED MATHEMATICS AND COMPUTING"},
      {"name":"PHILOSOPHICAL MAGAZINE LETTERS"},
      {"name":"ARABIAN JOURNAL OF GEOSCIENCES"},
      {"name":"METAPHOR AND SYMBOL"},
      {"name":"CURRENT PROBLEMS IN SURGERY"},
      {"name":"CLINICAL IMAGING"},
      {"name":"MICHIGAN MATHEMATICAL JOURNAL"},
      {"name":"ADVANCES IN QUANTUM CHEMISTRY"},
      {"name":"INTERNATIONAL JOURNAL OF OSTEOPATHIC MEDICINE"},
      {"name":"REVISTA ESPANOLA DE SALUD PUBLICA"},
      {"name":"CANADIAN JOURNAL OF SCHOOL PSYCHOLOGY"},
      {"name":"COMPARATIVE CYTOGENETICS"},
      {"name":"KINDHEIT UND ENTWICKLUNG"},
      {"name":"JOURNAL OF PEDIATRIC UROLOGY"},
      {"name":"ENFERMEDADES INFECCIOSAS Y MICROBIOLOGIA CLINICA"},
      {"name":"SOUTH AMERICAN JOURNAL OF HERPETOLOGY"},
      {"name":"STRAIN"},
      {"name":"ECONOMETRIC REVIEWS"},
      {"name":"ACM TRANSACTIONS ON INTERNET TECHNOLOGY"},
      {"name":"JOURNAL OF ECONOMIC POLICY REFORM"},
      {"name":"AUSTRALIAN MAMMALOGY"},
      {"name":"EUROPEAN JOURNAL OF PLANT PATHOLOGY"},
      {"name":"GEOGRAFIE"},
      {"name":"JOURNAL OF FORENSIC NURSING"},
      {"name":"EUROPEAN JOURNAL OF PAEDIATRIC DENTISTRY"},
      {"name":"INTERNATIONAL JOURNAL OF PROSTHODONTICS"},
      {"name":"OPTIMIZATION LETTERS"},
      {"name":"ACTA ORNITHOLOGICA"},
      {"name":"REVISTA ESPANOLA DE PEDAGOGIA"},
      {"name":"PAEDIATRICS AND INTERNATIONAL CHILD HEALTH"},
      {"name":"JOURNAL OF ELECTROCARDIOLOGY"},
      {"name":"EDUCATIONAL PSYCHOLOGY"},
      {"name":"BELGIAN JOURNAL OF ZOOLOGY"},
      {"name":"BLUMEA"},
      {"name":"NOTA LEPIDOPTEROLOGICA"},
      {"name":"INTERNATIONAL JOURNAL OF PSYCHIATRY IN MEDICINE"},
      {"name":"ECONOMIC SYSTEMS"},
      {"name":"COASTAL MANAGEMENT"},
      {"name":"PHILOSOPHICAL PSYCHOLOGY"},
      {"name":"CLINICAL MASS SPECTROMETRY"},
      {"name":"EQUINE VETERINARY EDUCATION"},
      {"name":"NAGOYA JOURNAL OF MEDICAL SCIENCE"},
      {"name":"APPLIED BIOCHEMISTRY AND MICROBIOLOGY"},
      {"name":"ERDKUNDE"},
      {"name":"ANALOG INTEGRATED CIRCUITS AND SIGNAL PROCESSING"},
      {"name":"WELDING JOURNAL"},
      {"name":"SOUTH AFRICAN JOURNAL OF ECONOMICS"},
      {"name":"ANALES DEL SISTEMA SANITARIO DE NAVARRA"},
      {"name":"JOURNAL OF EASTERN AFRICAN STUDIES"},
      {"name":"JOURNAL OF TROPICAL METEOROLOGY"},
      {"name":"JOURNAL OF FRESHWATER ECOLOGY"},
      {"name":"DISP"},
      {"name":"POLITICAL STUDIES REVIEW"},
      {"name":"UNIVERSITY OF TORONTO LAW JOURNAL"},
      {"name":"AMERICAN JOURNAL OF PHYSICS"},
      {"name":"TRENDS IN ORGANIZED CRIME"},
      {"name":"EVALUATION AND PROGRAM PLANNING"},
      {"name":"INTERNATIONAL JOURNAL OF PHYSICAL MODELLING IN GEOTECHNICS"},
      {"name":"JOURNAL OF COGNITIVE PSYCHOLOGY"},
      {"name":"REVISTA DE METALURGIA"},
      {"name":"JOURNAL OF CLINICAL NEUROPHYSIOLOGY"},
      {"name":"MICROELECTRONICS RELIABILITY"},
      {"name":"SCIENCE TECHNOLOGY AND SOCIETY"},
      {"name":"JOURNAL OF ETHOLOGY"},
      {"name":"JOURNAL OF THE KOREAN ASTRONOMICAL SOCIETY"},
      {"name":"CIRCUIT WORLD"},
      {"name":"IISE TRANSACTIONS"},
      {"name":"ACI MATERIALS JOURNAL"},
      {"name":"OCCUPATIONAL THERAPY INTERNATIONAL"},
      {"name":"EMPIRICAL ECONOMICS"},
      {"name":"GESUNDHEITSWESEN"},
      {"name":"PROSTHETICS AND ORTHOTICS INTERNATIONAL"},
      {"name":"JOURNAL OF THE AUSTRALIAN CERAMIC SOCIETY"},
      {"name":"HOMICIDE STUDIES"},
      {"name":"LANGUAGE VARIATION AND CHANGE"},
      {"name":"MACROECONOMIC DYNAMICS"},
      {"name":"TRANSITION METAL CHEMISTRY"},
      {"name":"MATHEMATICAL AND COMPUTER MODELLING OF DYNAMICAL SYSTEMS"},
      {"name":"REVISTA MEXICANA DE CIENCIAS PECUARIAS"},
      {"name":"GEOCHEMICAL JOURNAL"},
      {"name":"ACTA CARDIOLOGICA"},
      {"name":"AUTEX RESEARCH JOURNAL"},
      {"name":"CITIZENSHIP STUDIES"},
      {"name":"INTERNATIONAL JOURNAL OF OPTICS"},
      {"name":"EUROPEAN GERIATRIC MEDICINE"},
      {"name":"NETHERLANDS QUARTERLY OF HUMAN RIGHTS"},
      {"name":"BULLETIN OF INSECTOLOGY"},
      {"name":"INTERNATIONAL FOOD AND AGRIBUSINESS MANAGEMENT REVIEW"},
      {"name":"INTERNATIONAL JOURNAL OF PEDIATRIC OTORHINOLARYNGOLOGY"},
      {"name":"TURKISH JOURNAL OF MEDICAL SCIENCES"},
      {"name":"IRISH JOURNAL OF MEDICAL SCIENCE"},
      {"name":"TOPICS IN LANGUAGE DISORDERS"},
      {"name":"JOURNAL OF VETERINARY DIAGNOSTIC INVESTIGATION"},
      {"name":"PACIFIC AFFAIRS"},
      {"name":"ADVANCES IN MATERIALS SCIENCE AND ENGINEERING"},
      {"name":"JOURNAL OF COMBINATORIAL THEORY SERIES B"},
      {"name":"IRANIAN JOURNAL OF PUBLIC HEALTH"},
      {"name":"JOURNAL OF COMPLEXITY"},
      {"name":"ACTA POLITICA"},
      {"name":"COMPTES RENDUS MATHEMATIQUE"},
      {"name":"JOURNAL OF NANO RESEARCH"},
      {"name":"AFRICAN JOURNAL OF HERPETOLOGY"},
      {"name":"JOURNAL OF GEOGRAPHICAL SYSTEMS"},
      {"name":"COMMENTARII MATHEMATICI HELVETICI"},
      {"name":"INTERNATIONAL JOURNAL OF CIRCUMPOLAR HEALTH"},
      {"name":"JOURNAL OF PSYCHOLOGISTS AND COUNSELLORS IN SCHOOLS"},
      {"name":"JOURNAL OF THE EXPERIMENTAL ANALYSIS OF BEHAVIOR"},
      {"name":"PLASTICS RUBBER AND COMPOSITES"},
      {"name":"BOTANY"},
      {"name":"JOURNAL OF VETERINARY MEDICAL SCIENCE"},
      {"name":"JOURNAL OF HUMAN DEVELOPMENT AND CAPABILITIES"},
      {"name":"RUSSIAN JOURNAL OF PLANT PHYSIOLOGY"},
      {"name":"CLINICAL OBSTETRICS AND GYNECOLOGY"},
      {"name":"IEEE TRANSACTIONS ON DEVICE AND MATERIALS RELIABILITY"},
      {"name":"REVIEW OF SYMBOLIC LOGIC"},
      {"name":"STUDIES ON NEOTROPICAL FAUNA AND ENVIRONMENT"},
      {"name":"REVISTA ESPANOLA DE INVESTIGACIONES SOCIOLOGICAS"},
      {"name":"NURSING OPEN"},
      {"name":"PERCEPTION"},
      {"name":"INTERNATIONAL JOURNAL OF ACAROLOGY"},
      {"name":"LANGUAGE AND SPEECH"},
      {"name":"CANADIAN JOURNAL OF UROLOGY"},
      {"name":"GEOFLUIDS"},
      {"name":"COMMUNICATIONS IN PARTIAL DIFFERENTIAL EQUATIONS"},
      {"name":"CRITICAL CARE NURSE"},
      {"name":"ASTROPHYSICAL BULLETIN"},
      {"name":"LANGUAGE SCIENCES"},
      {"name":"IOWA LAW REVIEW"},
      {"name":"STAT"},
      {"name":"ADVANCES IN MATHEMATICAL PHYSICS"},
      {"name":"JOURNAL OF PLANT DISEASES AND PROTECTION"},
      {"name":"SCANDINAVIAN JOURNAL OF CARING SCIENCES"},
      {"name":"PSYCHOLOGISCHE RUNDSCHAU"},
      {"name":"COMPUTERS AND CONCRETE"},
      {"name":"AMERICAN JOURNAL OF COMMUNITY PSYCHOLOGY"},
      {"name":"MEDICAL ANTHROPOLOGY"},
      {"name":"HEALTH ECONOMICS POLICY AND LAW"},
      {"name":"SOCIAL INCLUSION"},
      {"name":"PHARMACEUTICAL STATISTICS"},
      {"name":"INTERNATIONAL JOURNAL OF INJURY CONTROL AND SAFETY PROMOTION"},
      {"name":"FAMILY MEDICINE"},
      {"name":"MULTIMEDIA SYSTEMS"},
      {"name":"JOURNAL OF GEOGRAPHY IN HIGHER EDUCATION"},
      {"name":"JOURNAL OF LOGIC AND COMPUTATION"},
      {"name":"ANALES DE PSICOLOGIA"},
      {"name":"CRITIQUE OF ANTHROPOLOGY"},
      {"name":"JOURNAL OF INTERVENTIONAL CARDIAC ELECTROPHYSIOLOGY"},
      {"name":"BULLETIN OF THE GEOLOGICAL SOCIETY OF DENMARK"},
      {"name":"ANDEAN GEOLOGY"},
      {"name":"JOURNAL OF WATER AND CLIMATE CHANGE"},
      {"name":"GEOPHYSICAL PROSPECTING"},
      {"name":"ACM TRANSACTIONS ON ALGORITHMS"},
      {"name":"POLITIKON"},
      {"name":"INDUSTRIAL RELATIONS"},
      {"name":"HEALTH ECONOMICS REVIEW"},
      {"name":"BRITISH JOURNAL OF MIDDLE EASTERN STUDIES"},
      {"name":"PUBLIC CULTURE"},
      {"name":"TURKISH JOURNAL OF BIOLOGY"},
      {"name":"EMERGING ADULTHOOD"},
      {"name":"HIGH TEMPERATURE MATERIALS AND PROCESSES"},
      {"name":"JOURNAL OF BIOSOCIAL SCIENCE"},
      {"name":"RESEARCH AND PRACTICE FOR PERSONS WITH SEVERE DISABILITIES"},
      {"name":"LAW AND HISTORY REVIEW"},
      {"name":"CHINESE JOURNAL OF INTEGRATIVE MEDICINE"},
      {"name":"SOCIOLOGY COMPASS"},
      {"name":"ADVANCES IN POLYMER TECHNOLOGY"},
      {"name":"STATISTICAL PAPERS"},
      {"name":"QUALITATIVE SOCIAL WORK"},
      {"name":"ADVANCES IN PHYSIOLOGY EDUCATION"},
      {"name":"ENVIRONMENT AND HISTORY"},
      {"name":"JOURNAL OF ECONOMETRICS"},
      {"name":"LETTERS IN MATHEMATICAL PHYSICS"},
      {"name":"GRASSLAND SCIENCE"},
      {"name":"SOCIAL SERVICE REVIEW"},
      {"name":"SILVAE GENETICA"},
      {"name":"ASTIN BULLETIN"},
      {"name":"JOURNAL OF ELECTROSTATICS"},
      {"name":"NURSING ECONOMICS"},
      {"name":"COPEIA"},
      {"name":"CRITICAL CARE NURSING CLINICS OF NORTH AMERICA"},
      {"name":"INTERVENTION IN SCHOOL AND CLINIC"},
      {"name":"PACIFIC SCIENCE"},
      {"name":"HEALTH"},
      {"name":"EUROPEAN JOURNAL OF APPLIED MATHEMATICS"},
      {"name":"MEDITERRANEAN JOURNAL OF MATHEMATICS"},
      {"name":"JOURNAL OF SOCIAL AND CLINICAL PSYCHOLOGY"},
      {"name":"EUROPEAN JOURNAL OF TAXONOMY"},
      {"name":"HORTICULTURAL PLANT JOURNAL"},
      {"name":"PUBLIC ADMINISTRATION AND DEVELOPMENT"},
      {"name":"IZVESTIYA ATMOSPHERIC AND OCEANIC PHYSICS"},
      {"name":"ACTA POLYTECHNICA HUNGARICA"},
      {"name":"ERNAHRUNGS UMSCHAU"},
      {"name":"SPEECH COMMUNICATION"},
      {"name":"ANTHROPOLOGICAL THEORY"},
      {"name":"ACTA PHYTOTAXONOMICA ET GEOBOTANICA"},
      {"name":"PSYCHOTHERAPIE PSYCHOSOMATIK MEDIZINISCHE PSYCHOLOGIE"},
      {"name":"JOURNAL OF EVOLUTIONARY ECONOMICS"},
      {"name":"BEHAVIOUR CHANGE"},
      {"name":"AMERICAN JOURNAL OF PSYCHOLOGY"},
      {"name":"JOURNAL OF ORAL AND MAXILLOFACIAL SURGERY"},
      {"name":"REHABILITATION"},
      {"name":"GLOBALIZATIONS"},
      {"name":"INTERNATIONAL JOURNAL OF TRANSITIONAL JUSTICE"},
      {"name":"JOURNAL OF PUBLIC HEALTH MANAGEMENT AND PRACTICE"},
      {"name":"TROPICAL PLANT BIOLOGY"},
      {"name":"IRANIAN JOURNAL OF KIDNEY DISEASES"},
      {"name":"GAMES AND CULTURE"},
      {"name":"PALAEOBIODIVERSITY AND PALAEOENVIRONMENTS"},
      {"name":"PHYSICOCHEMICAL PROBLEMS OF MINERAL PROCESSING"},
      {"name":"ATMOSFERA"},
      {"name":"ZEITSCHRIFT FUR SOZIOLOGIE"},
      {"name":"ANNALS OF PUBLIC AND COOPERATIVE ECONOMICS"},
      {"name":"CRYSTAL RESEARCH AND TECHNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF HEALTH PLANNING AND MANAGEMENT"},
      {"name":"INTERNATIONAL JOURNAL OF BIOMATHEMATICS"},
      {"name":"INTERNATIONAL JOURNAL OF HYPERTENSION"},
      {"name":"MOLLUSCAN RESEARCH"},
      {"name":"JOURNAL OF UNIVERSAL COMPUTER SCIENCE"},
      {"name":"RESULTS IN MATHEMATICS"},
      {"name":"ACTA SOCIOLOGICA"},
      {"name":"INTERNATIONAL JOURNAL OF LAW POLICY AND THE FAMILY"},
      {"name":"JOURNAL OF PLASTIC SURGERY AND HAND SURGERY"},
      {"name":"COLLOID JOURNAL"},
      {"name":"SILICON"},
      {"name":"INTERNATIONAL JOURNAL OF MICROWAVE AND WIRELESS TECHNOLOGIES"},
      {"name":"ANUARIO DE PSICOLOGIA JURIDICA"},
      {"name":"JOURNAL OF RESEARCH IN READING"},
      {"name":"HISTORICAL METHODS"},
      {"name":"PLANT SOIL AND ENVIRONMENT"},
      {"name":"NETWORKS AND HETEROGENEOUS MEDIA"},
      {"name":"JOURNAL OF CHILD HEALTH CARE"},
      {"name":"POLYMER SCIENCE SERIES B"},
      {"name":"AUSTRALIAN ARCHAEOLOGY"},
      {"name":"SOCIAL PSYCHOLOGY OF EDUCATION"},
      {"name":"JOURNAL OF OBSTETRICS AND GYNAECOLOGY"},
      {"name":"EDUCATION AND TREATMENT OF CHILDREN"},
      {"name":"SCIENCE OF ADVANCED MATERIALS"},
      {"name":"LITERACY"},
      {"name":"MEASUREMENT SCIENCE REVIEW"},
      {"name":"INTERNATIONAL JOURNAL FOR THE PSYCHOLOGY OF RELIGION"},
      {"name":"PSYCHOLOGICAL REPORTS"},
      {"name":"IEEE ANNALS OF THE HISTORY OF COMPUTING"},
      {"name":"ACTA PETROLOGICA SINICA"},
      {"name":"RESEARCH ON LANGUAGE AND SOCIAL INTERACTION"},
      {"name":"JOURNAL OF MODERN AFRICAN STUDIES"},
      {"name":"PERSONAL RELATIONSHIPS"},
      {"name":"COGNITIVE AND BEHAVIORAL NEUROLOGY"},
      {"name":"INTERNAL MEDICINE"},
      {"name":"OXFORD JOURNAL OF LEGAL STUDIES"},
      {"name":"JOURNAL OF TOPOLOGY"},
      {"name":"HUMAN ORGANIZATION"},
      {"name":"QUARTERLY JOURNAL OF POLITICAL SCIENCE"},
      {"name":"JOURNAL OF CRUSTACEAN BIOLOGY"},
      {"name":"FORESTRY CHRONICLE"},
      {"name":"JOURNAL OF QUANTITATIVE LINGUISTICS"},
      {"name":"SBORNIK MATHEMATICS"},
      {"name":"JOURNAL OF INTERNATIONAL MEDICAL RESEARCH"},
      {"name":"IRANIAN JOURNAL OF PHARMACEUTICAL RESEARCH"},
      {"name":"BRAZILIAN ORAL RESEARCH"},
      {"name":"ARCTIC"},
      {"name":"REVISTA PORTUGUESA DE CARDIOLOGIA"},
      {"name":"APPLIED MAGNETIC RESONANCE"},
      {"name":"DENTAL TRAUMATOLOGY"},
      {"name":"JOURNAL OF THE ASIA PACIFIC ECONOMY"},
      {"name":"JOURNAL OF CONTEMPORARY BRACHYTHERAPY"},
      {"name":"IET COMPUTER VISION"},
      {"name":"ITALIAN JOURNAL OF AGRONOMY"},
      {"name":"ZEITSCHRIFT FUR GERONTOLOGIE UND GERIATRIE"},
      {"name":"ACTA RADIOLOGICA"},
      {"name":"JOURNAL OF HELMINTHOLOGY"},
      {"name":"PLANT SYSTEMATICS AND EVOLUTION"},
      {"name":"IRANIAN JOURNAL OF PARASITOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF CENTRAL BANKING"},
      {"name":"BANACH JOURNAL OF MATHEMATICAL ANALYSIS"},
      {"name":"BMC UROLOGY"},
      {"name":"LEARNING AND MOTIVATION"},
      {"name":"PETROLEUM SCIENCE AND TECHNOLOGY"},
      {"name":"ANATOMICAL SCIENCE INTERNATIONAL"},
      {"name":"JOURNAL OF VISUALIZATION"},
      {"name":"TRANSACTIONS OF THE INSTITUTE OF MEASUREMENT AND CONTROL"},
      {"name":"REVIEW OF SCIENTIFIC INSTRUMENTS"},
      {"name":"AIRCRAFT ENGINEERING AND AEROSPACE TECHNOLOGY"},
      {"name":"INFORMATION SYSTEMS MANAGEMENT"},
      {"name":"HOLISTIC NURSING PRACTICE"},
      {"name":"CHEMOTHERAPY"},
      {"name":"SEXUALITIES"},
      {"name":"INTERNATIONAL JOURNAL OF GLOBAL WARMING"},
      {"name":"THERAPEUTIC APHERESIS AND DIALYSIS"},
      {"name":"ACTA GEODAETICA ET GEOPHYSICA"},
      {"name":"IETE JOURNAL OF RESEARCH"},
      {"name":"ENTOMOLOGICAL RESEARCH"},
      {"name":"INTERNATIONAL JOURNAL OF MATERIAL FORMING"},
      {"name":"JOURNAL OF EMPIRICAL RESEARCH ON HUMAN RESEARCH ETHICS"},
      {"name":"METROLOGY AND MEASUREMENT SYSTEMS"},
      {"name":"LIBRARY TRENDS"},
      {"name":"BULLETIN OF THE ATOMIC SCIENTISTS"},
      {"name":"INDIANA UNIVERSITY MATHEMATICS JOURNAL"},
      {"name":"RHETORIC SOCIETY QUARTERLY"},
      {"name":"INTERNATIONAL JOURNAL OF PARALLEL PROGRAMMING"},
      {"name":"TRANSACTIONS OF THE AMERICAN MATHEMATICAL SOCIETY"},
      {"name":"WORLD TRADE REVIEW"},
      {"name":"INTERNATIONAL JOURNAL OF VENTILATION"},
      {"name":"COMPARATIVE IMMUNOLOGY MICROBIOLOGY AND INFECTIOUS DISEASES"},
      {"name":"TOHOKU MATHEMATICAL JOURNAL"},
      {"name":"ACM TRANSACTIONS ON EMBEDDED COMPUTING SYSTEMS"},
      {"name":"PARTICULATE SCIENCE AND TECHNOLOGY"},
      {"name":"TOURIST STUDIES"},
      {"name":"JOURNAL OF HEURISTICS"},
      {"name":"QUAESTIONES MATHEMATICAE"},
      {"name":"MICROSCOPY"},
      {"name":"CHINESE JOURNAL OF ORGANIC CHEMISTRY"},
      {"name":"NUTRICION HOSPITALARIA"},
      {"name":"AUSTRAL ECOLOGY"},
      {"name":"JOURNAL OF OCEANOGRAPHY"},
      {"name":"CURRENT SOCIOLOGY"},
      {"name":"JOURNAL OF VETERINARY CARDIOLOGY"},
      {"name":"JOURNAL OF EUROPEAN INTEGRATION"},
      {"name":"HETEROCYCLIC COMMUNICATIONS"},
      {"name":"MIDDLE EAST JOURNAL"},
      {"name":"JOURNAL OF PALEONTOLOGY"},
      {"name":"JOURNAL OF TRANSPORT AND LAND USE"},
      {"name":"JOURNAL OF PHASE EQUILIBRIA AND DIFFUSION"},
      {"name":"PEDIATRIC HEMATOLOGY AND ONCOLOGY"},
      {"name":"ANNALS OF THORACIC MEDICINE"},
      {"name":"JOURNAL OF ETHNICITY IN SUBSTANCE ABUSE"},
      {"name":"LC GC EUROPE"},
      {"name":"AUSTRALIAN OCCUPATIONAL THERAPY JOURNAL"},
      {"name":"IRANIAN JOURNAL OF VETERINARY RESEARCH"},
      {"name":"PROTEIN AND PEPTIDE LETTERS"},
      {"name":"EXPERIMENTAL AND APPLIED ACAROLOGY"},
      {"name":"NATION"},
      {"name":"NORTH AMERICAN JOURNAL OF AQUACULTURE"},
      {"name":"AMERICAN JOURNAL OF DENTISTRY"},
      {"name":"REVIEW OF AFRICAN POLITICAL ECONOMY"},
      {"name":"FUTURE VIROLOGY"},
      {"name":"CONTEMPORARY POLITICS"},
      {"name":"ACTA PARASITOLOGICA"},
      {"name":"CAMBRIDGE REVIEW OF INTERNATIONAL AFFAIRS"},
      {"name":"JOURNAL OF CLASSIFICATION"},
      {"name":"ANIMAL FRONTIERS"},
      {"name":"INTERNATIONAL JOURNAL OF SOCIAL PSYCHIATRY"},
      {"name":"INTERNATIONAL JOURNAL OF STEEL STRUCTURES"},
      {"name":"ASIAN POPULATION STUDIES"},
      {"name":"AQUATIC LIVING RESOURCES"},
      {"name":"SINGAPORE JOURNAL OF TROPICAL GEOGRAPHY"},
      {"name":"WEATHER"},
      {"name":"GROUPS GEOMETRY AND DYNAMICS"},
      {"name":"ADVANCES IN NURSING SCIENCE"},
      {"name":"JOURNAL OF RUSSIAN LASER RESEARCH"},
      {"name":"JOURNAL OF BALKAN AND NEAR EASTERN STUDIES"},
      {"name":"VETERINARIA ITALIANA"},
      {"name":"JOURNAL OF INTERVENTION AND STATEBUILDING"},
      {"name":"ECONOMIC INQUIRY"},
      {"name":"FRONTIERS OF MATHEMATICS IN CHINA"},
      {"name":"JOURNAL OF APPLIED PHILOSOPHY"},
      {"name":"NOTRE DAME LAW REVIEW"},
      {"name":"ASIAN JOURNAL OF SOCIAL PSYCHOLOGY"},
      {"name":"JOURNAL OF AUSTRALIAN POLITICAL ECONOMY"},
      {"name":"INVERTEBRATE BIOLOGY"},
      {"name":"REVISTA BRASILEIRA DE PALEONTOLOGIA"},
      {"name":"RESEARCH IN THE MATHEMATICAL SCIENCES"},
      {"name":"ALGEBRAIC GEOMETRY"},
      {"name":"JOURNAL OF SUSTAINABLE FORESTRY"},
      {"name":"MOLECULAR SYNDROMOLOGY"},
      {"name":"JOURNAL OF NUTRITIONAL SCIENCE AND VITAMINOLOGY"},
      {"name":"METHODS IN CELL BIOLOGY"},
      {"name":"TRIBOLOGY TRANSACTIONS"},
      {"name":"ANNALS OF HUMAN GENETICS"},
      {"name":"REVISTA DE GEOGRAFIA NORTE GRANDE"},
      {"name":"COMBINATORICA"},
      {"name":"REHABILITATION PSYCHOLOGY"},
      {"name":"YOUNG"},
      {"name":"CAREER DEVELOPMENT AND TRANSITION FOR EXCEPTIONAL INDIVIDUALS"},
      {"name":"CLINICAL CASE STUDIES"},
      {"name":"VASCULAR AND ENDOVASCULAR SURGERY"},
      {"name":"STUDIA LOGICA"},
      {"name":"ACM TRANSACTIONS ON DESIGN AUTOMATION OF ELECTRONIC SYSTEMS"},
      {"name":"INTERNATIONAL JOURNAL OF INDUSTRIAL ORGANIZATION"},
      {"name":"HEALTH INFORMATION AND LIBRARIES JOURNAL"},
      {"name":"JOURNAL OF NEUROSCIENCE PSYCHOLOGY AND ECONOMICS"},
      {"name":"REFERENCE SERVICES REVIEW"},
      {"name":"HEALTH SECURITY"},
      {"name":"INFORMATION DEVELOPMENT"},
      {"name":"JOURNAL OF WATER AND HEALTH"},
      {"name":"CANADIAN JOURNAL OF EMERGENCY MEDICINE"},
      {"name":"JOURNAL OF ECONOMIC THEORY"},
      {"name":"NUCLEAR TECHNOLOGY"},
      {"name":"EXPLORATION GEOPHYSICS"},
      {"name":"INTERNATIONAL JOURNAL FOR EDUCATIONAL AND VOCATIONAL GUIDANCE"},
      {"name":"COMPUTATIONAL ECONOMICS"},
      {"name":"ADVANCES IN MATHEMATICS OF COMMUNICATIONS"},
      {"name":"ACTA ADRIATICA"},
      {"name":"AUSTRALIAN JOURNAL OF ADVANCED NURSING"},
      {"name":"HELMINTHOLOGIA"},
      {"name":"BUSINESS HISTORY"},
      {"name":"CHINESE PHYSICS B"},
      {"name":"INTERNATIONAL JOURNAL OF EDUCATIONAL DEVELOPMENT"},
      {"name":"ECONOMIC DEVELOPMENT QUARTERLY"},
      {"name":"EUROPEAN EARLY CHILDHOOD EDUCATION RESEARCH JOURNAL"},
      {"name":"JOURNAL OF NATURAL HISTORY"},
      {"name":"RESEARCH ON SOCIAL WORK PRACTICE"},
      {"name":"AMERICAN BUSINESS LAW JOURNAL"},
      {"name":"JOURNAL OF LINGUISTICS"},
      {"name":"JOURNAL OF KOREAN NEUROSURGICAL SOCIETY"},
      {"name":"BIT NUMERICAL MATHEMATICS"},
      {"name":"BIOCONTROL SCIENCE"},
      {"name":"ZEITSCHRIFT FUR GASTROENTEROLOGIE"},
      {"name":"REVIEWS ON ADVANCED MATERIALS SCIENCE"},
      {"name":"ACTA BOTANICA MEXICANA"},
      {"name":"SUGAR TECH"},
      {"name":"SOCIAL JUSTICE RESEARCH"},
      {"name":"JOURNAL OF PHOTONICS FOR ENERGY"},
      {"name":"ACTION RESEARCH"},
      {"name":"ACTA PHARMACEUTICA"},
      {"name":"JOURNAL OF GERONTOLOGICAL NURSING"},
      {"name":"COLOMBIA MEDICA"},
      {"name":"LINEAR ALGEBRA AND ITS APPLICATIONS"},
      {"name":"TRABAJOS DE PREHISTORIA"},
      {"name":"CRYOLETTERS"},
      {"name":"SEMINARS IN OPHTHALMOLOGY"},
      {"name":"JOURNAL OF DEVELOPMENTAL AND PHYSICAL DISABILITIES"},
      {"name":"JOURNAL OF PEDIATRIC HEALTH CARE"},
      {"name":"GENDER AND EDUCATION"},
      {"name":"EMERGING MARKETS FINANCE AND TRADE"},
      {"name":"NERVENARZT"},
      {"name":"INDIAN JOURNAL OF MEDICAL MICROBIOLOGY"},
      {"name":"ULTRASONIC IMAGING"},
      {"name":"JOURNAL OF CARDIOPULMONARY REHABILITATION AND PREVENTION"},
      {"name":"INVERSE PROBLEMS IN SCIENCE AND ENGINEERING"},
      {"name":"MARKETING LETTERS"},
      {"name":"AMERICAN BEHAVIORAL SCIENTIST"},
      {"name":"PROTEIN EXPRESSION AND PURIFICATION"},
      {"name":"IN VIVO"},
      {"name":"JOURNAL OF EXPERIMENTAL AND THEORETICAL PHYSICS"},
      {"name":"SOCIOLOGICAL METHODOLOGY"},
      {"name":"ERDE"},
      {"name":"COMMUNICATIONS IN APPLIED MATHEMATICS AND COMPUTATIONAL SCIENCE"},
      {"name":"ADVANCES IN ANATOMY EMBRYOLOGY AND CELL BIOLOGY"},
      {"name":"VISUAL COGNITION"},
      {"name":"JOURNAL OF RESEARCH IN MUSIC EDUCATION"},
      {"name":"ENVIRONMENTAL ENGINEERING RESEARCH"},
      {"name":"NEUROREHABILITATION"},
      {"name":"JOURNAL OF THERMAL SCIENCE AND TECHNOLOGY"},
      {"name":"SYSTEMS ENGINEERING"},
      {"name":"INTERNATIONAL REVIEW OF LAW AND ECONOMICS"},
      {"name":"JOURNAL OF FOOD AND NUTRITION RESEARCH"},
      {"name":"OPEN PHYSICS"},
      {"name":"CURRENT DRUG DELIVERY"},
      {"name":"JOURNAL OF SIMULATION"},
      {"name":"AUSTRALIAN AND NEW ZEALAND JOURNAL OF CRIMINOLOGY"},
      {"name":"OPEN LIFE SCIENCES"},
      {"name":"JOURNAL OF ORGANIZATIONAL COMPUTING AND ELECTRONIC COMMERCE"},
      {"name":"JOURNAL OF SCHOOL VIOLENCE"},
      {"name":"EXPLORATIONS IN ECONOMIC HISTORY"},
      {"name":"JOURNAL OF CRYPTOLOGY"},
      {"name":"NUCLEAR SCIENCE AND TECHNIQUES"},
      {"name":"COMMUNICATIONS IN MATHEMATICS AND STATISTICS"},
      {"name":"HORTICULTURE ENVIRONMENT AND BIOTECHNOLOGY"},
      {"name":"RADIOCHIMICA ACTA"},
      {"name":"INFORMATION AND COMPUTATION"},
      {"name":"BMC WOMENS HEALTH"},
      {"name":"NEW GENERATION COMPUTING"},
      {"name":"RUBBER CHEMISTRY AND TECHNOLOGY"},
      {"name":"JOURNAL OF ECONOMIC HISTORY"},
      {"name":"CHILD WELFARE"},
      {"name":"ANNALS OF DERMATOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF SCIENCE AND MATHEMATICS EDUCATION"},
      {"name":"NUKLEONIKA"},
      {"name":"CHINA INFORMATION"},
      {"name":"JOURNAL OF EDUCATIONAL AND PSYCHOLOGICAL CONSULTATION"},
      {"name":"UNFALLCHIRURG"},
      {"name":"OXIDATION OF METALS"},
      {"name":"JOURNAL OF CULTURAL ECONOMY"},
      {"name":"DISCOURSE STUDIES"},
      {"name":"BOLETIN DE LA SOCIEDAD GEOLOGICA MEXICANA"},
      {"name":"URSUS"},
      {"name":"FOREST SYSTEMS"},
      {"name":"DIFFERENTIAL AND INTEGRAL EQUATIONS"},
      {"name":"REVUE DE MEDECINE INTERNE"},
      {"name":"JOURNAL OF ENGINEERING THERMOPHYSICS"},
      {"name":"WOOD AND FIBER SCIENCE"},
      {"name":"BMC OPHTHALMOLOGY"},
      {"name":"WEB ECOLOGY"},
      {"name":"INTERACTION STUDIES"},
      {"name":"ECONOMY AND SOCIETY"},
      {"name":"SOCIAL SCIENCE INFORMATION SUR LES SCIENCES SOCIALES"},
      {"name":"EMIRATES JOURNAL OF FOOD AND AGRICULTURE"},
      {"name":"IEEE TRANSACTIONS ON NUCLEAR SCIENCE"},
      {"name":"ZYGOTE"},
      {"name":"NORDIC PSYCHOLOGY"},
      {"name":"JOURNAL OF AAPOS"},
      {"name":"SOIL SCIENCE AND PLANT NUTRITION"},
      {"name":"JOURNAL OF CONTEMPORARY HISTORY"},
      {"name":"JOURNAL OF THE AMERICAN LEATHER CHEMISTS ASSOCIATION"},
      {"name":"JOURNAL OF ANIMAL PHYSIOLOGY AND ANIMAL NUTRITION"},
      {"name":"JOURNAL OF MATHEMATICAL ANALYSIS AND APPLICATIONS"},
      {"name":"EARTHQUAKE ENGINEERING AND ENGINEERING VIBRATION"},
      {"name":"WORLD JOURNAL OF CLINICAL CASES"},
      {"name":"JOURNAL OF COMPARATIVE PATHOLOGY"},
      {"name":"SWISS JOURNAL OF PSYCHOLOGY"},
      {"name":"JOURNAL FOR HEALTHCARE QUALITY"},
      {"name":"INTERNATIONAL JOURNAL OF WEB SERVICES RESEARCH"},
      {"name":"IBM JOURNAL OF RESEARCH AND DEVELOPMENT"},
      {"name":"JOURNAL OF APPLIED REMOTE SENSING"},
      {"name":"REVISTA CHILENA DE HISTORIA NATURAL"},
      {"name":"SOCIAL SEMIOTICS"},
      {"name":"AUSTRALIAN JOURNAL OF POLITICAL SCIENCE"},
      {"name":"INTERNATIONAL PERSPECTIVES ON SEXUAL AND REPRODUCTIVE HEALTH"},
      {"name":"JOURNAL OF INFECTION IN DEVELOPING COUNTRIES"},
      {"name":"POTENTIAL ANALYSIS"},
      {"name":"TRAFFIC INJURY PREVENTION"},
      {"name":"ARCHIVES OF BUDO"},
      {"name":"MATERIALS TESTING"},
      {"name":"NEW ZEALAND JOURNAL OF ZOOLOGY"},
      {"name":"IRANIAN JOURNAL OF ALLERGY ASTHMA AND IMMUNOLOGY"},
      {"name":"JOURNAL OF POLITICAL IDEOLOGIES"},
      {"name":"INFANCIA Y APRENDIZAJE"},
      {"name":"MEDICAL HYPOTHESES"},
      {"name":"COLORATION TECHNOLOGY"},
      {"name":"KOREAN JOURNAL OF ORTHODONTICS"},
      {"name":"MILAN JOURNAL OF MATHEMATICS"},
      {"name":"POLISH JOURNAL OF CHEMICAL TECHNOLOGY"},
      {"name":"REVISTA DO INSTITUTO DE MEDICINA TROPICAL DE SAO PAULO"},
      {"name":"INTERNATIONAL JOURNAL OF PERFORMANCE ANALYSIS IN SPORT"},
      {"name":"OPEN CHEMISTRY"},
      {"name":"FOOD AND NUTRITION BULLETIN"},
      {"name":"JOURNAL OF MARINE RESEARCH"},
      {"name":"JAPANESE JOURNAL OF INFECTIOUS DISEASES"},
      {"name":"ISIJ INTERNATIONAL"},
      {"name":"INTERNATIONAL JOURNAL OF PRECISION ENGINEERING AND MANUFACTURING"},
      {"name":"ELECTRONIC LIBRARY"},
      {"name":"SOCIAL DEVELOPMENT"},
      {"name":"QUANTITATIVE INFRARED THERMOGRAPHY JOURNAL"},
      {"name":"NEW ASTRONOMY"},
      {"name":"ELEKTRONIKA IR ELEKTROTECHNIKA"},
      {"name":"CONTEMPORARY SOUTHEAST ASIA"},
      {"name":"JOURNAL OF THE AUSTRALIAN MATHEMATICAL SOCIETY"},
      {"name":"POLITICAL THEORY"},
      {"name":"PROFESSIONAL DEVELOPMENT IN EDUCATION"},
      {"name":"GEOLOGICAL QUARTERLY"},
      {"name":"GEOLOGICA CARPATHICA"},
      {"name":"LITHUANIAN JOURNAL OF PHYSICS"},
      {"name":"CHINESE JOURNAL OF ELECTRONICS"},
      {"name":"JOURNAL OF GYNECOLOGY OBSTETRICS AND HUMAN REPRODUCTION"},
      {"name":"ANNALS OF FOREST RESEARCH"},
      {"name":"ORGANIC PREPARATIONS AND PROCEDURES INTERNATIONAL"},
      {"name":"JOURNAL OF THE CHINESE CHEMICAL SOCIETY"},
      {"name":"INTELLECTUAL AND DEVELOPMENTAL DISABILITIES"},
      {"name":"ACTA BIOCHIMICA POLONICA"},
      {"name":"WORLD JOURNAL OF PEDIATRICS"},
      {"name":"PROCEEDINGS OF THE BIOLOGICAL SOCIETY OF WASHINGTON"},
      {"name":"LANGUAGE CULTURE AND CURRICULUM"},
      {"name":"ENGINEERING ECONOMIST"},
      {"name":"JOURNAL OF STATISTICAL COMPUTATION AND SIMULATION"},
      {"name":"INTERNATIONAL JOURNAL OF PLANT PRODUCTION"},
      {"name":"OPTICS AND SPECTROSCOPY"},
      {"name":"DIABETOLOGIE UND STOFFWECHSEL"},
      {"name":"CLINICAL RESPIRATORY JOURNAL"},
      {"name":"CRITICAL DISCOURSE STUDIES"},
      {"name":"SYNTHESE"},
      {"name":"CYBERNETICS AND SYSTEMS"},
      {"name":"JOURNAL OF THE AMERICAN ANIMAL HOSPITAL ASSOCIATION"},
      {"name":"AUSTRALIAN JOURNAL OF FORENSIC SCIENCES"},
      {"name":"GENERAL PHYSIOLOGY AND BIOPHYSICS"},
      {"name":"MATERIALWISSENSCHAFT UND WERKSTOFFTECHNIK"},
      {"name":"JOURNAL OF WILDLIFE DISEASES"},
      {"name":"POLITICS"},
      {"name":"THEORETICAL COMPUTER SCIENCE"},
      {"name":"GENE EXPRESSION PATTERNS"},
      {"name":"CRITICAL CRIMINOLOGY"},
      {"name":"ANIMAL REPRODUCTION"},
      {"name":"ULTRASOUND QUARTERLY"},
      {"name":"JOURNAL OF MINERALOGICAL AND PETROLOGICAL SCIENCES"},
      {"name":"JOURNAL OF AFRICAN ECONOMIES"},
      {"name":"JOURNAL OF ADHESION SCIENCE AND TECHNOLOGY"},
      {"name":"AUSTRALASIAN JOURNAL OF ENVIRONMENTAL MANAGEMENT"},
      {"name":"JOURNAL OF CLINICAL SPORT PSYCHOLOGY"},
      {"name":"JOURNAL OF SWINE HEALTH AND PRODUCTION"},
      {"name":"EMPIRICAL STUDIES OF THE ARTS"},
      {"name":"JOURNAL OF FUNCTIONAL ANALYSIS"},
      {"name":"PERIODICO DI MINERALOGIA"},
      {"name":"SEMINARS IN ONCOLOGY NURSING"},
      {"name":"PRIMATES"},
      {"name":"NEW ZEALAND JOURNAL OF FORESTRY SCIENCE"},
      {"name":"EDUCATION AND URBAN SOCIETY"},
      {"name":"JOURNAL OF ORAL SCIENCE"},
      {"name":"SEMINARS IN MUSCULOSKELETAL RADIOLOGY"},
      {"name":"FACIAL PLASTIC SURGERY"},
      {"name":"INTERNATIONAL JOURNAL OF NETWORK MANAGEMENT"},
      {"name":"ACTA CYTOLOGICA"},
      {"name":"JOURNAL OF THE AMERICAN PSYCHOANALYTIC ASSOCIATION"},
      {"name":"SOLAR SYSTEM RESEARCH"},
      {"name":"NEW LEFT REVIEW"},
      {"name":"TRAITEMENT DU SIGNAL"},
      {"name":"MATERIALS SCIENCE"},
      {"name":"ORTHOPADE"},
      {"name":"GENES TO CELLS"},
      {"name":"INDUSTRIAL HEALTH"},
      {"name":"ROMANIAN JOURNAL OF MORPHOLOGY AND EMBRYOLOGY"},
      {"name":"WATER SCIENCE AND TECHNOLOGY"},
      {"name":"AUSTRALIAN PSYCHOLOGIST"},
      {"name":"COMPTES RENDUS PALEVOL"},
      {"name":"BIODIVERSITY DATA JOURNAL"},
      {"name":"PALAEONTOLOGIA ELECTRONICA"},
      {"name":"JOURNAL OF IRON AND STEEL RESEARCH INTERNATIONAL"},
      {"name":"HUMAN RIGHTS QUARTERLY"},
      {"name":"INTERNATIONAL JOURNAL OF HEAVY VEHICLE SYSTEMS"},
      {"name":"SYMBOLIC INTERACTION"},
      {"name":"PETROLOGY"},
      {"name":"JOURNAL OF CRYSTAL GROWTH"},
      {"name":"SCHOOL EFFECTIVENESS AND SCHOOL IMPROVEMENT"},
      {"name":"SALUD PUBLICA DE MEXICO"},
      {"name":"PHYCOLOGICAL RESEARCH"},
      {"name":"JOURNAL OF PSYCHOLOGY"},
      {"name":"SCHOOL PSYCHOLOGY INTERNATIONAL"},
      {"name":"EUROPEAN LAW JOURNAL"},
      {"name":"ANNALS OF AGRICULTURAL AND ENVIRONMENTAL MEDICINE"},
      {"name":"ECONOMETRIC THEORY"},
      {"name":"JOURNAL OF ALGEBRA"},
      {"name":"NEW ZEALAND JOURNAL OF GEOLOGY AND GEOPHYSICS"},
      {"name":"ENVIRONMENT PROTECTION ENGINEERING"},
      {"name":"IRBM"},
      {"name":"STATISTICA NEERLANDICA"},
      {"name":"BIOINVASIONS RECORDS"},
      {"name":"JOURNAL OF HOUSING ECONOMICS"},
      {"name":"ACTA REUMATOLOGICA PORTUGUESA"},
      {"name":"INTERNATIONAL JOURNAL OF COMMUNICATION"},
      {"name":"JOURNAL OF FINANCIAL ECONOMETRICS"},
      {"name":"EMOTION SPACE AND SOCIETY"},
      {"name":"ARCHIVES ITALIENNES DE BIOLOGIE"},
      {"name":"OPEN ASTRONOMY"},
      {"name":"JOURNAL OF HEALTHCARE MANAGEMENT"},
      {"name":"IZVESTIYA MATHEMATICS"},
      {"name":"BUSINESS HISTORY REVIEW"},
      {"name":"INNOVATIONS IN EDUCATION AND TEACHING INTERNATIONAL"},
      {"name":"NEW DIRECTIONS FOR CHILD AND ADOLESCENT DEVELOPMENT"},
      {"name":"POLIMERY"},
      {"name":"SURFACE SCIENCE"},
      {"name":"HAND CLINICS"},
      {"name":"ISRAEL JOURNAL OF PLANT SCIENCES"},
      {"name":"JOURNAL OF SPACECRAFT AND ROCKETS"},
      {"name":"INDIAN JOURNAL OF OPHTHALMOLOGY"},
      {"name":"PLANT ECOLOGY AND EVOLUTION"},
      {"name":"JOURNAL OF BIOINFORMATICS AND COMPUTATIONAL BIOLOGY"},
      {"name":"CANADIAN JOURNAL OF CHEMISTRY"},
      {"name":"ENTOMOLOGICAL SCIENCE"},
      {"name":"AUSTRALIAN FORESTRY"},
      {"name":"INTERNATIONAL JOURNAL OF CONFLICT AND VIOLENCE"},
      {"name":"ACTA MONTANISTICA SLOVACA"},
      {"name":"NEUROCASE"},
      {"name":"STAHLBAU"},
      {"name":"MOLECULAR BIOLOGY REPORTS"},
      {"name":"CLINICAL SOCIAL WORK JOURNAL"},
      {"name":"JOURNAL FOR THE THEORY OF SOCIAL BEHAVIOUR"},
      {"name":"AI COMMUNICATIONS"},
      {"name":"GYNECOLOGICAL ENDOCRINOLOGY"},
      {"name":"SHOCK WAVES"},
      {"name":"RUSSIAN JOURNAL OF PHYSICAL CHEMISTRY A"},
      {"name":"INTERNATIONAL JOURNAL OF DEVELOPMENTAL DISABILITIES"},
      {"name":"INTERNATIONAL JOURNAL OF FOREST ENGINEERING"},
      {"name":"COMPUTER AIDED GEOMETRIC DESIGN"},
      {"name":"ALCHERINGA"},
      {"name":"INTERNATIONAL JOURNAL OF OPHTHALMOLOGY"},
      {"name":"ANTHROZOOS"},
      {"name":"INDIAN JOURNAL OF PHARMACEUTICAL SCIENCES"},
      {"name":"JOURNAL OF MATHEMATICAL ECONOMICS"},
      {"name":"JOURNAL OF ENERGETIC MATERIALS"},
      {"name":"INDIAN JOURNAL OF PHYSICS"},
      {"name":"ORAL SURGERY ORAL MEDICINE ORAL PATHOLOGY ORAL RADIOLOGY"},
      {"name":"CLAYS AND CLAY MINERALS"},
      {"name":"SIAM JOURNAL ON MATHEMATICAL ANALYSIS"},
      {"name":"KEW BULLETIN"},
      {"name":"ANNALS OF ANIMAL SCIENCE"},
      {"name":"AUSTRALIAN ENDODONTIC JOURNAL"},
      {"name":"FARMACIA"},
      {"name":"ANNALI DI MATEMATICA PURA ED APPLICATA"},
      {"name":"DISCRETE MATHEMATICS"},
      {"name":"EVALUATION REVIEW"},
      {"name":"CALCOLO"},
      {"name":"JOURNAL OF NANOPHOTONICS"},
      {"name":"INTERNATIONAL FEMINIST JOURNAL OF POLITICS"},
      {"name":"JOURNAL OF PERFORMANCE OF CONSTRUCTED FACILITIES"},
      {"name":"JOURNAL OF THE ROYAL SOCIETY OF NEW ZEALAND"},
      {"name":"JOURNAL OF FAMILY STUDIES"},
      {"name":"BOLETIN LATINOAMERICANO Y DEL CARIBE DE PLANTAS MEDICINALES Y AROMATICAS"},
      {"name":"ROMANIAN JOURNAL OF ECONOMIC FORECASTING"},
      {"name":"NORTH AMERICAN JOURNAL OF ECONOMICS AND FINANCE"},
      {"name":"SCHMERZ"},
      {"name":"JOURNAL OF ARACHNOLOGY"},
      {"name":"JOURNAL OF MECHANICS"},
      {"name":"REVISTA DE PSICOLOGIA DEL DEPORTE"},
      {"name":"RADIOENGINEERING"},
      {"name":"MINERALOGY AND PETROLOGY"},
      {"name":"FOLIA GEOBOTANICA"},
      {"name":"JOURNAL OF HETEROCYCLIC CHEMISTRY"},
      {"name":"QUANTUM ELECTRONICS"},
      {"name":"BRAZILIAN JOURNAL OF POULTRY SCIENCE"},
      {"name":"ANNALS OF TELECOMMUNICATIONS"},
      {"name":"GERMAN POLITICS"},
      {"name":"JOURNAL OF BALTIC SCIENCE EDUCATION"},
      {"name":"FUNDAMENTA INFORMATICAE"},
      {"name":"ANALYSES OF SOCIAL ISSUES AND PUBLIC POLICY"},
      {"name":"INFORMATION ECONOMICS AND POLICY"},
      {"name":"INTERNATIONAL JOURNAL OF PALEOPATHOLOGY"},
      {"name":"FORMAL METHODS IN SYSTEM DESIGN"},
      {"name":"SEMINARS IN ULTRASOUND CT AND MRI"},
      {"name":"PEDIATRIC AND DEVELOPMENTAL PATHOLOGY"},
      {"name":"DESIGNS CODES AND CRYPTOGRAPHY"},
      {"name":"DECISION ANALYSIS"},
      {"name":"JOURNAL OF CRANIOFACIAL SURGERY"},
      {"name":"EUROPEAN JOURNAL OF INTERNATIONAL LAW"},
      {"name":"JOURNAL OF MARINE SCIENCE AND TECHNOLOGY"},
      {"name":"APPLIED COGNITIVE PSYCHOLOGY"},
      {"name":"STOCHASTICS AND DYNAMICS"},
      {"name":"ISRAEL JOURNAL OF MATHEMATICS"},
      {"name":"REVIEW JOURNAL OF AUTISM AND DEVELOPMENTAL DISORDERS"},
      {"name":"LATIN AMERICAN ANTIQUITY"},
      {"name":"ASTROPHYSICS AND SPACE SCIENCE"},
      {"name":"VOCATIONS AND LEARNING"},
      {"name":"OPEN MEDICINE"},
      {"name":"JOURNAL OF OFFICIAL STATISTICS"},
      {"name":"METHODS OF INFORMATION IN MEDICINE"},
      {"name":"FOLIA PARASITOLOGICA"},
      {"name":"HEPATITIS MONTHLY"},
      {"name":"SPATIAL ECONOMIC ANALYSIS"},
      {"name":"FOREIGN POLICY ANALYSIS"},
      {"name":"JOURNAL OF PSYCHOLINGUISTIC RESEARCH"},
      {"name":"GEODIVERSITAS"},
      {"name":"CRITICAL STUDIES IN MEDIA COMMUNICATION"},
      {"name":"JOURNAL OF MIDDLE EAST WOMENS STUDIES"},
      {"name":"PHYSIOLOGY INTERNATIONAL"},
      {"name":"PLANT ECOLOGY"},
      {"name":"FORUM OF MATHEMATICS SIGMA"},
      {"name":"COMPARATIVE EUROPEAN POLITICS"},
      {"name":"ADVANCES IN MATHEMATICS"},
      {"name":"BRAZILIAN JOURNAL OF GEOLOGY"},
      {"name":"MLJEKARSTVO"},
      {"name":"CANADIAN JOURNAL OF DIETETIC PRACTICE AND RESEARCH"},
      {"name":"COMMUNICATIONS IN THEORETICAL PHYSICS"},
      {"name":"JOURNAL OF COMPUTATIONAL BIOLOGY"},
      {"name":"CHILDS NERVOUS SYSTEM"},
      {"name":"JOURNAL OF WORLD TRADE"},
      {"name":"LEARNING CULTURE AND SOCIAL INTERACTION"},
      {"name":"CREATIVITY RESEARCH JOURNAL"},
      {"name":"WILDLIFE BIOLOGY"},
      {"name":"EUROPEAN JOURNAL OF INTEGRATIVE MEDICINE"},
      {"name":"BIORESOURCES"},
      {"name":"POLYMER SCIENCE SERIES C"},
      {"name":"AUSTRALIAN HEALTH REVIEW"},
      {"name":"MUSIC EDUCATION RESEARCH"},
      {"name":"ADVANCES IN COGNITIVE PSYCHOLOGY"},
      {"name":"JOURNAL OF ECONOMICS"},
      {"name":"SEXUAL HEALTH"},
      {"name":"NURSING SCIENCE QUARTERLY"},
      {"name":"ASIA PACIFIC JOURNAL OF CLINICAL NUTRITION"},
      {"name":"NURSING CLINICS OF NORTH AMERICA"},
      {"name":"INTERNATIONAL JOURNAL OF FRUIT SCIENCE"},
      {"name":"APPLIED STOCHASTIC MODELS IN BUSINESS AND INDUSTRY"},
      {"name":"ESTONIAN JOURNAL OF EARTH SCIENCES"},
      {"name":"FOLIA PRIMATOLOGICA"},
      {"name":"AFRICAN JOURNAL OF ECOLOGY"},
      {"name":"JOURNAL OF VECTOR ECOLOGY"},
      {"name":"FRONTIERS OF COMPUTER SCIENCE"},
      {"name":"JOURNAL OF FORAMINIFERAL RESEARCH"},
      {"name":"JOURNAL OF PUBLIC TRANSPORTATION"},
      {"name":"JOURNAL OF HERPETOLOGY"},
      {"name":"MAMMALIA"},
      {"name":"JOURNAL OF NEUROSURGICAL SCIENCES"},
      {"name":"SURGICAL AND RADIOLOGIC ANATOMY"},
      {"name":"JOURNAL OF LINGUISTIC ANTHROPOLOGY"},
      {"name":"JOURNAL OF LIMNOLOGY"},
      {"name":"SEMINARS IN PLASTIC SURGERY"},
      {"name":"MATERIALE PLASTICE"},
      {"name":"OFIOLITI"},
      {"name":"EURASIP JOURNAL ON IMAGE AND VIDEO PROCESSING"},
      {"name":"RUSSIAN JOURNAL OF INORGANIC CHEMISTRY"},
      {"name":"PROFESIONAL DE LA INFORMACION"},
      {"name":"CHEMISTRY AND ECOLOGY"},
      {"name":"JOURNAL OF CHILDRENS ORTHOPAEDICS"},
      {"name":"ACTA VIROLOGICA"},
      {"name":"EUROPEAN NEUROLOGY"},
      {"name":"TOPICS IN COMPANION ANIMAL MEDICINE"},
      {"name":"INTERNATIONAL JOURNAL ON SOFTWARE TOOLS FOR TECHNOLOGY TRANSFER"},
      {"name":"TURKISH JOURNAL OF GASTROENTEROLOGY"},
      {"name":"ACOUSTICS AUSTRALIA"},
      {"name":"HEMATOLOGY"},
      {"name":"GENETIC RESOURCES AND CROP EVOLUTION"},
      {"name":"BEHAVIORAL INTERVENTIONS"},
      {"name":"JOURNAL OF ENGINEERED FIBERS AND FABRICS"},
      {"name":"BRAZILIAN JOURNAL OF BIOLOGY"},
      {"name":"IMAGING SCIENCE JOURNAL"},
      {"name":"ACTA BOTANICA CROATICA"},
      {"name":"PROFESSIONAL GEOGRAPHER"},
      {"name":"DEVELOPMENT GENES AND EVOLUTION"},
      {"name":"JOURNAL OF CONSTRUCTIVIST PSYCHOLOGY"},
      {"name":"CLINICAL SPINE SURGERY"},
      {"name":"BRITISH JOURNAL OF NEUROSURGERY"},
      {"name":"LATERALITY"},
      {"name":"ADVANCES IN COMPLEX SYSTEMS"},
      {"name":"ZEITSCHRIFT FUR RHEUMATOLOGIE"},
      {"name":"JOURNAL OF CHINESE POLITICAL SCIENCE"},
      {"name":"ANNALS OF THORACIC AND CARDIOVASCULAR SURGERY"},
      {"name":"AGRIBUSINESS"},
      {"name":"JOURNAL OF DEAF STUDIES AND DEAF EDUCATION"},
      {"name":"GEOSCIENCES JOURNAL"},
      {"name":"BERNOULLI"},
      {"name":"ANIMAL CELLS AND SYSTEMS"},
      {"name":"FEMINIST ECONOMICS"},
      {"name":"HEALTH EDUCATION RESEARCH"},
      {"name":"PHILOSOPHY OF SCIENCE"},
      {"name":"CURRENT HIV RESEARCH"},
      {"name":"REVISTA ESPANOLA DE DOCUMENTACION CIENTIFICA"},
      {"name":"AQUATIC ECOLOGY"},
      {"name":"ANNALS OF THE ENTOMOLOGICAL SOCIETY OF AMERICA"},
      {"name":"JOURNAL OF DUAL DIAGNOSIS"},
      {"name":"AQUATIC MAMMALS"},
      {"name":"JOURNAL OF COMMUNITY PSYCHOLOGY"},
      {"name":"ANTARCTIC SCIENCE"},
      {"name":"ADVANCES IN CLINICAL AND EXPERIMENTAL MEDICINE"},
      {"name":"GENEVA RISK AND INSURANCE REVIEW"},
      {"name":"EARLY EDUCATION AND DEVELOPMENT"},
      {"name":"POSTEPY HIGIENY I MEDYCYNY DOSWIADCZALNEJ"},
      {"name":"INTERNATIONAL POLITICAL SCIENCE REVIEW"},
      {"name":"ACTA PHYSICA SINICA"},
      {"name":"JAPAN JOURNAL OF NURSING SCIENCE"},
      {"name":"MOBILE INFORMATION SYSTEMS"},
      {"name":"CURRENT PROTEOMICS"},
      {"name":"REVISTA ROMANA DE MEDICINA DE LABORATOR"},
      {"name":"FLORIDA ENTOMOLOGIST"},
      {"name":"REVISTA BRASILEIRA DE CIENCIA DO SOLO"},
      {"name":"CAHIERS AGRICULTURES"},
      {"name":"COLLOID AND POLYMER SCIENCE"},
      {"name":"CANADIAN JOURNAL OF PLANT PATHOLOGY"},
      {"name":"JOURNAL OF PSYCHOEDUCATIONAL ASSESSMENT"},
      {"name":"INTERNATIONAL JOURNAL OF EXERGY"},
      {"name":"JOURNAL OF FAMILY AND ECONOMIC ISSUES"},
      {"name":"RIVISTA DI PSICHIATRIA"},
      {"name":"JOURNAL OF CONTINUING EDUCATION IN THE HEALTH PROFESSIONS"},
      {"name":"INTERNATIONAL JOURNAL FOR MULTISCALE COMPUTATIONAL ENGINEERING"},
      {"name":"BRYOLOGIST"},
      {"name":"BIOTECHNOLOGIE AGRONOMIE SOCIETE ET ENVIRONNEMENT"},
      {"name":"TRANSACTIONS OF THE AMERICAN FISHERIES SOCIETY"},
      {"name":"JOURNAL OF CONCHOLOGY"},
      {"name":"POLITICS AND RELIGION"},
      {"name":"PHYSICAL REVIEW ACCELERATORS AND BEAMS"},
      {"name":"FOOD BIOTECHNOLOGY"},
      {"name":"JOURNAL OF THEORETICAL PROBABILITY"},
      {"name":"AURIS NASUS LARYNX"},
      {"name":"CRIMINAL JUSTICE AND BEHAVIOR"},
      {"name":"GEOPHYSICAL AND ASTROPHYSICAL FLUID DYNAMICS"},
      {"name":"PSYCHOANALYTIC PSYCHOLOGY"},
      {"name":"ACTA HAEMATOLOGICA"},
      {"name":"JOURNAL OF ORGANIZATIONAL CHANGE MANAGEMENT"},
      {"name":"JOURNAL OF MATHEMATICS TEACHER EDUCATION"},
      {"name":"CLINICAL PEDIATRICS"},
      {"name":"ECOLOGY OF FOOD AND NUTRITION"},
      {"name":"JOURNAL OF HUMAN RIGHTS"},
      {"name":"JOURNAL OF EAST EUROPEAN MANAGEMENT STUDIES"},
      {"name":"JOURNAL OF EYE MOVEMENT RESEARCH"},
      {"name":"WILLDENOWIA"},
      {"name":"CULTURAL STUDIES"},
      {"name":"ACTA CIRURGICA BRASILEIRA"},
      {"name":"APPLICATIONS IN PLANT SCIENCES"},
      {"name":"JOURNAL OF CHEMICAL EDUCATION"},
      {"name":"INTERNATIONAL JOURNAL OF ADVANCED ROBOTIC SYSTEMS"},
      {"name":"BIOSCIENCE TRENDS"},
      {"name":"ATENCION PRIMARIA"},
      {"name":"QUARTERLY OF APPLIED MATHEMATICS"},
      {"name":"POPULATION ECOLOGY"},
      {"name":"GFF"},
      {"name":"ECONOMICS OF INNOVATION AND NEW TECHNOLOGY"},
      {"name":"EVOLUTIONARY BIOINFORMATICS"},
      {"name":"REVISTA MEXICANA DE CIENCIAS GEOLOGICAS"},
      {"name":"TECHNOLOGY PEDAGOGY AND EDUCATION"},
      {"name":"KINETICS AND CATALYSIS"},
      {"name":"INTERNATIONAL JOURNAL OF IMPOTENCE RESEARCH"},
      {"name":"LOGICAL METHODS IN COMPUTER SCIENCE"},
      {"name":"NAUPLIUS"},
      {"name":"MATHEMATICAL BIOSCIENCES AND ENGINEERING"},
      {"name":"REVISTA BRASILEIRA DE ANESTESIOLOGIA"},
      {"name":"AUSTRALIAN JOURNAL OF PUBLIC ADMINISTRATION"},
      {"name":"THEORETICAL ECONOMICS"},
      {"name":"MEDICINA ORAL PATOLOGIA ORAL Y CIRUGIA BUCAL"},
      {"name":"ZEITSCHRIFT FUR WIRTSCHAFTSGEOGRAPHIE"},
      {"name":"MINERVA CHIRURGICA"},
      {"name":"JOURNAL OF THE TEXTILE INSTITUTE"},
      {"name":"ISOTOPES IN ENVIRONMENTAL AND HEALTH STUDIES"},
      {"name":"IRANIAN JOURNAL OF IMMUNOLOGY"},
      {"name":"JOURNAL OF CLINICAL PEDIATRIC DENTISTRY"},
      {"name":"SPORT IN SOCIETY"},
      {"name":"EUROPEAN LAW REVIEW"},
      {"name":"EUROPEAN PHYSICAL JOURNAL B"},
      {"name":"ANAIS BRASILEIROS DE DERMATOLOGIA"},
      {"name":"INTERNATIONAL JOURNAL OF THE COMMONS"},
      {"name":"STATISTICA SINICA"},
      {"name":"JOURNAL OF THEORETICAL AND COMPUTATIONAL ACOUSTICS"},
      {"name":"AIP ADVANCES"},
      {"name":"RUSSIAN JOURNAL OF BIOORGANIC CHEMISTRY"},
      {"name":"GEOSCIENTIFIC INSTRUMENTATION METHODS AND DATA SYSTEMS"},
      {"name":"JOURNAL OF THE SERBIAN CHEMICAL SOCIETY"},
      {"name":"DISCRETE AND CONTINUOUS DYNAMICAL SYSTEMS"},
      {"name":"JOURNAL OF COMBINATORIAL THEORY SERIES A"},
      {"name":"LABORATORY ANIMALS"},
      {"name":"RURAL AND REMOTE HEALTH"},
      {"name":"JOURNAL OF ESSENTIAL OIL BEARING PLANTS"},
      {"name":"ARCTIC SCIENCE"},
      {"name":"GEOCHRONOMETRIA"},
      {"name":"REVISTA BRASILEIRA DE ENTOMOLOGIA"},
      {"name":"CANADIAN JOURNAL OF PLANT SCIENCE"},
      {"name":"TRENDS IN GLYCOSCIENCE AND GLYCOTECHNOLOGY"},
      {"name":"EMERGENCY MEDICINE INTERNATIONAL"},
      {"name":"JOURNAL OF TOPOLOGY AND ANALYSIS"},
      {"name":"CHINESE MEDICAL JOURNAL"},
      {"name":"MAGNETIC RESONANCE IMAGING CLINICS OF NORTH AMERICA"},
      {"name":"JOURNAL OF THERMOPLASTIC COMPOSITE MATERIALS"},
      {"name":"ELEMENTARY SCHOOL JOURNAL"},
      {"name":"DIVING AND HYPERBARIC MEDICINE"},
      {"name":"COLOR RESEARCH AND APPLICATION"},
      {"name":"GEOLOGICA BELGICA"},
      {"name":"OPHTHALMIC PLASTIC AND RECONSTRUCTIVE SURGERY"},
      {"name":"QUALITATIVE RESEARCH IN ACCOUNTING AND MANAGEMENT"},
      {"name":"IRISH JOURNAL OF AGRICULTURAL AND FOOD RESEARCH"},
      {"name":"FEMALE PELVIC MEDICINE AND RECONSTRUCTIVE SURGERY"},
      {"name":"JOURNAL OF ENVIRONMENTAL ENGINEERING"},
      {"name":"BIOLOGIA PLANTARUM"},
      {"name":"ASIA PACIFIC BUSINESS REVIEW"},
      {"name":"TERMINOLOGY"},
      {"name":"JOURNAL OF PHILOSOPHY OF EDUCATION"},
      {"name":"JOURNAL OF FORECASTING"},
      {"name":"JOURNAL OF COLLEGE STUDENT DEVELOPMENT"},
      {"name":"PLANT PROTECTION SCIENCE"},
      {"name":"CYTOPATHOLOGY"},
      {"name":"ENVIRONMENTAL ARCHAEOLOGY"},
      {"name":"CLINICAL NURSE SPECIALIST"},
      {"name":"JOURNAL OF INEQUALITIES AND APPLICATIONS"},
      {"name":"SINGAPORE MEDICAL JOURNAL"},
      {"name":"AUSTRALIAN JOURNAL OF AGRICULTURAL AND RESOURCE ECONOMICS"},
      {"name":"ELECTRONIC JOURNAL OF PROBABILITY"},
      {"name":"NEW ZEALAND GEOGRAPHER"},
      {"name":"TRANSFUSION AND APHERESIS SCIENCE"},
      {"name":"SOUTH AFRICAN GEOGRAPHICAL JOURNAL"},
      {"name":"RADIATION PROTECTION DOSIMETRY"},
      {"name":"EUROPEAN CONSTITUTIONAL LAW REVIEW"},
      {"name":"SINGAPORE ECONOMIC REVIEW"},
      {"name":"CRITICAL REVIEW"},
      {"name":"LANGUAGE POLICY"},
      {"name":"SOUTHERN ECONOMIC JOURNAL"},
      {"name":"LONGITUDINAL AND LIFE COURSE STUDIES"},
      {"name":"LIFETIME DATA ANALYSIS"},
      {"name":"FIRST LANGUAGE"},
      {"name":"INTERNATIONAL JOURNAL OF AEROSPACE ENGINEERING"},
      {"name":"HEALTH CARE ANALYSIS"},
      {"name":"JOURNAL OF APPLIED ANALYSIS AND COMPUTATION"},
      {"name":"DAEDALUS"},
      {"name":"ANNALS OF VASCULAR SURGERY"},
      {"name":"ANALES DE PEDIATRIA"},
      {"name":"INTERNATIONAL JOURNAL OF DEVELOPMENTAL BIOLOGY"},
      {"name":"SOCIAL PSYCHOLOGY QUARTERLY"},
      {"name":"OPTIMIZATION"},
      {"name":"LANGUAGE ASSESSMENT QUARTERLY"},
      {"name":"MONTHLY LABOR REVIEW"},
      {"name":"ENDOCRINE RESEARCH"},
      {"name":"GENERAL THORACIC AND CARDIOVASCULAR SURGERY"},
      {"name":"PSYCHIATRIC QUARTERLY"},
      {"name":"VASCULAR"},
      {"name":"JOURNAL OF ENVIRONMENTAL BIOLOGY"},
      {"name":"DISASTER PREVENTION AND MANAGEMENT"},
      {"name":"JOURNAL OF CREDIT RISK"},
      {"name":"ISLAND STUDIES JOURNAL"},
      {"name":"POLAR RECORD"},
      {"name":"CHINESE JOURNAL OF COMMUNICATION"},
      {"name":"SOCIAL SCIENCE QUARTERLY"},
      {"name":"MECHANICS OF COMPOSITE MATERIALS"},
      {"name":"ARCHIVES OF BIOLOGICAL SCIENCES"},
      {"name":"JOURNAL OF FINANCIAL SERVICES RESEARCH"},
      {"name":"SOUTHERN FORESTS"},
      {"name":"TROPICAL PLANT PATHOLOGY"},
      {"name":"DIATOM RESEARCH"},
      {"name":"PEDIATRIC DENTISTRY"},
      {"name":"PLANT BIOTECHNOLOGY REPORTS"},
      {"name":"EVOLUTIONARY PSYCHOLOGY"},
      {"name":"JOURNAL OF SPATIAL SCIENCE"},
      {"name":"COMMUNITY DEVELOPMENT JOURNAL"},
      {"name":"OR SPECTRUM"},
      {"name":"TOXICOLOGIC PATHOLOGY"},
      {"name":"JOURNAL OF COMPARATIVE PSYCHOLOGY"},
      {"name":"BIOPSYCHOSOCIAL MEDICINE"},
      {"name":"EXPERIMENTAL ASTRONOMY"},
      {"name":"OCEANOLOGICAL AND HYDROBIOLOGICAL STUDIES"},
      {"name":"BULLETIN DES SCIENCES MATHEMATIQUES"},
      {"name":"PORTUGUESE ECONOMIC JOURNAL"},
      {"name":"AMERICAN JOURNAL OF COMPARATIVE LAW"},
      {"name":"CRIMINAL BEHAVIOUR AND MENTAL HEALTH"},
      {"name":"JOURNAL OF MECHANICS OF MATERIALS AND STRUCTURES"},
      {"name":"STRATIGRAPHY"},
      {"name":"REVISTA DE LA FACULTAD DE CIENCIAS AGRARIAS"},
      {"name":"JOURNAL OF INTERNATIONAL ADVANCED OTOLOGY"},
      {"name":"REVISTA IBEROAMERICANA DE AUTOMATICA E INFORMATICA INDUSTRIAL"},
      {"name":"BIOLOGICAL RHYTHM RESEARCH"},
      {"name":"KOLNER ZEITSCHRIFT FUR SOZIOLOGIE UND SOZIALPSYCHOLOGIE"},
      {"name":"FEMINIST THEORY"},
      {"name":"JOURNAL DE MYCOLOGIE MEDICALE"},
      {"name":"GINEKOLOGIA POLSKA"},
      {"name":"FORDHAM LAW REVIEW"},
      {"name":"REHABILITATION NURSING"},
      {"name":"VETERINARY AND COMPARATIVE ORTHOPAEDICS AND TRAUMATOLOGY"},
      {"name":"ADVANCES IN APPLIED PROBABILITY"},
      {"name":"ETHNOGRAPHY"},
      {"name":"JOURNAL OF INTEGRATIVE NEUROSCIENCE"},
      {"name":"BALTIC FORESTRY"},
      {"name":"JOURNAL OF THEORETICAL POLITICS"},
      {"name":"AUSTRALIAN SOCIAL WORK"},
      {"name":"RUSSIAN JOURNAL OF APPLIED CHEMISTRY"},
      {"name":"SIGNS"},
      {"name":"THERAPEUTIC HYPOTHERMIA AND TEMPERATURE MANAGEMENT"},
      {"name":"DISCUSSIONES MATHEMATICAE GRAPH THEORY"},
      {"name":"AMERICAN JOURNAL OF HEALTH BEHAVIOR"},
      {"name":"OPTICAL ENGINEERING"},
      {"name":"ACTA ACUSTICA UNITED WITH ACUSTICA"},
      {"name":"JOURNAL OF SPORTS ECONOMICS"},
      {"name":"NEFROLOGIA"},
      {"name":"EURASIP JOURNAL ON WIRELESS COMMUNICATIONS AND NETWORKING"},
      {"name":"CAMBRIDGE JOURNAL OF EDUCATION"},
      {"name":"MICROELECTRONICS INTERNATIONAL"},
      {"name":"REVISTA DA ESCOLA DE ENFERMAGEM DA USP"},
      {"name":"TUEXENIA"},
      {"name":"MODERN PHYSICS LETTERS B"},
      {"name":"MACROMOLECULAR THEORY AND SIMULATIONS"},
      {"name":"JOURNAL OF CONSCIOUSNESS STUDIES"},
      {"name":"EXPERIMENTAL TECHNIQUES"},
      {"name":"JOURNAL OF PUBLIC HEALTH POLICY"},
      {"name":"POLISH MARITIME RESEARCH"},
      {"name":"ACTA GEODYNAMICA ET GEOMATERIALIA"},
      {"name":"EUROPEAN JOURNAL OF POLITICAL ECONOMY"},
      {"name":"BIOMOLECULAR NMR ASSIGNMENTS"},
      {"name":"REPRODUCTION IN DOMESTIC ANIMALS"},
      {"name":"ARQUIVOS BRASILEIROS DE CARDIOLOGIA"},
      {"name":"JOURNAL OF PERINATAL MEDICINE"},
      {"name":"JOURNAL OF AEROSPACE INFORMATION SYSTEMS"},
      {"name":"JOURNAL OF ADVANCED PROSTHODONTICS"},
      {"name":"NEW ZEALAND VETERINARY JOURNAL"},
      {"name":"JOURNAL OF FOOT AND ANKLE RESEARCH"},
      {"name":"TROPICAL ANIMAL HEALTH AND PRODUCTION"},
      {"name":"JOURNAL OF PHOTOPOLYMER SCIENCE AND TECHNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF DENTAL HYGIENE"},
      {"name":"JOURNAL OF POLICY AND PRACTICE IN INTELLECTUAL DISABILITIES"},
      {"name":"ANATOMIA HISTOLOGIA EMBRYOLOGIA"},
      {"name":"SYSTEMIC PRACTICE AND ACTION RESEARCH"},
      {"name":"JOURNAL OF STATISTICAL PHYSICS"},
      {"name":"ECONOMIC THEORY"},
      {"name":"ZOOMORPHOLOGY"},
      {"name":"JOURNAL OF FOOD SAFETY"},
      {"name":"AIMS MATHEMATICS"},
      {"name":"REVISTA ESPANOLA DE QUIMIOTERAPIA"},
      {"name":"OCEAN SCIENCE JOURNAL"},
      {"name":"JOURNAL OF RAPTOR RESEARCH"},
      {"name":"TURKISH NEUROSURGERY"},
      {"name":"IMA JOURNAL OF MATHEMATICAL CONTROL AND INFORMATION"},
      {"name":"COMPUTER JOURNAL"},
      {"name":"INSECTES SOCIAUX"},
      {"name":"TRANSACTIONS ON EMERGING TELECOMMUNICATIONS TECHNOLOGIES"},
      {"name":"ZEITSCHRIFT FUR KLINISCHE PSYCHOLOGIE UND PSYCHOTHERAPIE"},
      {"name":"FRONTIERS OF EARTH SCIENCE"},
      {"name":"CANADIAN RESPIRATORY JOURNAL"},
      {"name":"JOURNAL OF ASIAN NATURAL PRODUCTS RESEARCH"},
      {"name":"ITALIAN JOURNAL OF GEOSCIENCES"},
      {"name":"JOURNAL OF WATER SANITATION AND HYGIENE FOR DEVELOPMENT"},
      {"name":"MEDITERRANEAN JOURNAL OF HEMATOLOGY AND INFECTIOUS DISEASES"},
      {"name":"JOURNAL OF THE AMERICAN ASSOCIATION OF NURSE PRACTITIONERS"},
      {"name":"ASIAN ECONOMIC JOURNAL"},
      {"name":"JOURNAL OF SPECTRAL THEORY"},
      {"name":"PEDIATRIC ALLERGY IMMUNOLOGY AND PULMONOLOGY"},
      {"name":"ENVIRONMETRICS"},
      {"name":"ADVANCES IN CEMENT RESEARCH"},
      {"name":"VETERINARY CLINICAL PATHOLOGY"},
      {"name":"TRANSACTIONS OF THE JAPAN SOCIETY FOR AERONAUTICAL AND SPACE SCIENCES"},
      {"name":"BMC MEDICAL GENETICS"},
      {"name":"ADVANCES IN RHEUMATOLOGY"},
      {"name":"NORDIC JOURNAL OF MUSIC THERAPY"},
      {"name":"LOGIC JOURNAL OF THE IGPL"},
      {"name":"JOURNAL OF NANOELECTRONICS AND OPTOELECTRONICS"},
      {"name":"PROTEIN JOURNAL"},
      {"name":"INVESTIGACION CLINICA"},
      {"name":"ENTREPRENEURSHIP RESEARCH JOURNAL"},
      {"name":"BALTIC JOURNAL OF ECONOMICS"},
      {"name":"JOURNAL OF COMPUTATIONAL MATHEMATICS"},
      {"name":"EUROPEAN JOURNAL OF FUTURES RESEARCH"},
      {"name":"JOURNAL OF HYMENOPTERA RESEARCH"},
      {"name":"COST EFFECTIVENESS AND RESOURCE ALLOCATION"},
      {"name":"TRAINING AND EDUCATION IN PROFESSIONAL PSYCHOLOGY"},
      {"name":"EDUCATIONAL STUDIES IN MATHEMATICS"},
      {"name":"JOURNAL OF STRUCTURAL CHEMISTRY"},
      {"name":"ANNALS OF CLINICAL PSYCHIATRY"},
      {"name":"JOURNAL OF AGRICULTURAL AND RESOURCE ECONOMICS"},
      {"name":"INTERNATIONAL INSOLVENCY REVIEW"},
      {"name":"TWIN RESEARCH AND HUMAN GENETICS"},
      {"name":"REVISTA ESPANOLA DE MEDICINA NUCLEAR E IMAGEN MOLECULAR"},
      {"name":"LANGUAGE AWARENESS"},
      {"name":"PROCESS SAFETY PROGRESS"},
      {"name":"CIVIL ENGINEERING AND ENVIRONMENTAL SYSTEMS"},
      {"name":"DATA TECHNOLOGIES AND APPLICATIONS"},
      {"name":"CARBONATES AND EVAPORITES"},
      {"name":"AMERICAN SCIENTIST"},
      {"name":"INTERNATIONAL JOURNAL OF ODONATOLOGY"},
      {"name":"MARINE GEODESY"},
      {"name":"BIRD STUDY"},
      {"name":"AMERICAN JOURNAL OF AUDIOLOGY"},
      {"name":"LATIN AMERICAN JOURNAL OF AQUATIC RESEARCH"},
      {"name":"INTERNATIONAL JOURNAL OF UNCONVENTIONAL COMPUTING"},
      {"name":"ARCHIVES OF PSYCHIATRIC NURSING"},
      {"name":"INTELLIGENT AUTOMATION AND SOFT COMPUTING"},
      {"name":"INTERNATIONAL JOURNAL OF CULTURAL POLICY"},
      {"name":"AUSTRALIAN JOURNAL OF EDUCATION"},
      {"name":"JOURNAL OF AGROMEDICINE"},
      {"name":"POETICS"},
      {"name":"HISTORY OF POLITICAL ECONOMY"},
      {"name":"SECOND LANGUAGE RESEARCH"},
      {"name":"JOURNAL OF AMBIENT INTELLIGENCE AND SMART ENVIRONMENTS"},
      {"name":"JOURNAL OF BIOACTIVE AND COMPATIBLE POLYMERS"},
      {"name":"JOURNAL OF CANCER RESEARCH AND THERAPEUTICS"},
      {"name":"ECONOMICS OF GOVERNANCE"},
      {"name":"JOURNAL OF SOCIAL PSYCHOLOGY"},
      {"name":"DIACHRONICA"},
      {"name":"LEARNING DISABILITY QUARTERLY"},
      {"name":"KLINISCHE PADIATRIE"},
      {"name":"APPLIED ECONOMICS LETTERS"},
      {"name":"JOURNAL OF VECTOR BORNE DISEASES"},
      {"name":"DEFENCE SCIENCE JOURNAL"},
      {"name":"JOURNAL OF CONTEMPORARY ETHNOGRAPHY"},
      {"name":"SYDOWIA"},
      {"name":"ACTA METALLURGICA SINICA"},
      {"name":"AI MAGAZINE"},
      {"name":"NATURAL AREAS JOURNAL"},
      {"name":"ACTA NATURAE"},
      {"name":"JOURNAL OF MATHEMATICAL INEQUALITIES"},
      {"name":"ANNALI DELL ISTITUTO SUPERIORE DI SANITA"},
      {"name":"HEALTH CARE FOR WOMEN INTERNATIONAL"},
      {"name":"FUSION SCIENCE AND TECHNOLOGY"},
      {"name":"IMPACT ASSESSMENT AND PROJECT APPRAISAL"},
      {"name":"INVESTMENT ANALYSTS JOURNAL"},
      {"name":"JOURNAL OF APPLIED BEHAVIOR ANALYSIS"},
      {"name":"ACTA GEOPHYSICA"},
      {"name":"BEHAVIOUR"},
      {"name":"JOURNAL OF THE INSTITUTE OF BREWING"},
      {"name":"NEGOTIATION AND CONFLICT MANAGEMENT RESEARCH"},
      {"name":"POSTEPY W KARDIOLOGII INTERWENCYJNEJ"},
      {"name":"JOURNAL OF SIGNAL PROCESSING SYSTEMS FOR SIGNAL IMAGE AND VIDEO TECHNOLOGY"},
      {"name":"ENGINEERING STUDIES"},
      {"name":"MONATSHEFTE FUR CHEMIE"},
      {"name":"BULLETIN OF EXPERIMENTAL BIOLOGY AND MEDICINE"},
      {"name":"JOURNAL OF OPTIMIZATION THEORY AND APPLICATIONS"},
      {"name":"BIOCONTROL SCIENCE AND TECHNOLOGY"},
      {"name":"JOURNAL OF GEOMETRIC ANALYSIS"},
      {"name":"JOURNAL OF RENEWABLE AND SUSTAINABLE ENERGY"},
      {"name":"DIFFERENTIAL EQUATIONS"},
      {"name":"INTERNATIONAL JOURNAL FOR ACADEMIC DEVELOPMENT"},
      {"name":"STUDIES IN COMPARATIVE INTERNATIONAL DEVELOPMENT"},
      {"name":"INTERNATIONAL JOURNAL OF IMMUNOGENETICS"},
      {"name":"ENVIRONMENTAL FORENSICS"},
      {"name":"ACTA VETERINARIA HUNGARICA"},
      {"name":"MATERIALI IN TEHNOLOGIJE"},
      {"name":"MIND BRAIN AND EDUCATION"},
      {"name":"JOURNAL OF RECEPTORS AND SIGNAL TRANSDUCTION"},
      {"name":"JOURNAL OF APPLIED ANIMAL WELFARE SCIENCE"},
      {"name":"AUSTRALIAN JOURNAL OF EARTH SCIENCES"},
      {"name":"GEODINAMICA ACTA"},
      {"name":"CURRENT ANALYTICAL CHEMISTRY"},
      {"name":"THEORETICAL ECOLOGY"},
      {"name":"LANGUAGE AND COGNITION"},
      {"name":"JOURNAL OF NUMBER THEORY"},
      {"name":"APPLIED ONTOLOGY"},
      {"name":"CURRENT SCIENCE"},
      {"name":"PAKISTAN JOURNAL OF BOTANY"},
      {"name":"CONTROL ENGINEERING AND APPLIED INFORMATICS"},
      {"name":"LINGUISTICS"},
      {"name":"INTERNATIONAL JOURNAL OF CIRCUIT THEORY AND APPLICATIONS"},
      {"name":"ALLERGOLOGIA ET IMMUNOPATHOLOGIA"},
      {"name":"PROBABILITY IN THE ENGINEERING AND INFORMATIONAL SCIENCES"},
      {"name":"NATURAL COMPUTING"},
      {"name":"APPLIED ECOLOGY AND ENVIRONMENTAL RESEARCH"},
      {"name":"ANAESTHESIA AND INTENSIVE CARE"},
      {"name":"EXCEPTIONALITY"},
      {"name":"JOURNAL OF CIRCUITS SYSTEMS AND COMPUTERS"},
      {"name":"COMPLEX ANALYSIS AND OPERATOR THEORY"},
      {"name":"ZEITSCHRIFT FUR ANALYSIS UND IHRE ANWENDUNGEN"},
      {"name":"MAIN GROUP CHEMISTRY"},
      {"name":"AMERICAN ANNALS OF THE DEAF"},
      {"name":"INVERSE PROBLEMS AND IMAGING"},
      {"name":"PARASITOLOGY RESEARCH"},
      {"name":"UNDERSEA AND HYPERBARIC MEDICINE"},
      {"name":"JOURNAL OF ADDICTIONS NURSING"},
      {"name":"JOURNAL OF EARTHQUAKE AND TSUNAMI"},
      {"name":"CHROMATOGRAPHIA"},
      {"name":"JOURNAL OF CARDIOVASCULAR MEDICINE"},
      {"name":"CONTRIBUTIONS TO NEPHROLOGY"},
      {"name":"MATHEMATISCHE NACHRICHTEN"},
      {"name":"JOURNAL OF NEUROSCIENCE NURSING"},
      {"name":"PHARMACOGNOSY MAGAZINE"},
      {"name":"ZOOLOGISCHER ANZEIGER"},
      {"name":"NORDIC STUDIES ON ALCOHOL AND DRUGS"},
      {"name":"ANALYTICAL LETTERS"},
      {"name":"INTERNATIONAL REVIEW OF ECONOMICS EDUCATION"},
      {"name":"REVISTA MATEMATICA COMPLUTENSE"},
      {"name":"CRITICAL INQUIRY"},
      {"name":"JOURNAL OF FOOD PROCESSING AND PRESERVATION"},
      {"name":"JOURNAL OF INDUSTRIAL AND MANAGEMENT OPTIMIZATION"},
      {"name":"IEEE PULSE"},
      {"name":"MICROBIOLOGY AND IMMUNOLOGY"},
      {"name":"NUCLEAR ENGINEERING AND DESIGN"},
      {"name":"CLINICS IN PODIATRIC MEDICINE AND SURGERY"},
      {"name":"OPHTHALMOLOGE"},
      {"name":"REVIEW OF RELIGIOUS RESEARCH"},
      {"name":"EASTERN EUROPEAN ECONOMICS"},
      {"name":"EUROPEAN JOURNAL OF TRANSPORT AND INFRASTRUCTURE RESEARCH"},
      {"name":"ULTRASTRUCTURAL PATHOLOGY"},
      {"name":"JOURNAL OF ADOLESCENT AND YOUNG ADULT ONCOLOGY"},
      {"name":"NEUROENDOCRINOLOGY LETTERS"},
      {"name":"ETHOS"},
      {"name":"JOURNAL OF CORRECTIONAL HEALTH CARE"},
      {"name":"INTERNATIONAL BRAZ J UROL"},
      {"name":"INTERNATIONAL JOURNAL OF REHABILITATION RESEARCH"},
      {"name":"AMEGHINIANA"},
      {"name":"PSYCHIATRIA DANUBINA"},
      {"name":"MATHEMATICAL MODELLING OF NATURAL PHENOMENA"},
      {"name":"INTERNATIONAL POLYMER PROCESSING"},
      {"name":"INDUSTRIAL LAW JOURNAL"},
      {"name":"JOURNAL OF THE AUDIO ENGINEERING SOCIETY"},
      {"name":"LIBYAN JOURNAL OF MEDICINE"},
      {"name":"JOURNAL OF BUILDING PHYSICS"},
      {"name":"JOURNAL OF MATHEMATICAL FLUID MECHANICS"},
      {"name":"POLITICAL RESEARCH QUARTERLY"},
      {"name":"ADVANCES IN APPLIED CLIFFORD ALGEBRAS"},
      {"name":"SALAMANDRA"},
      {"name":"THEORY OF COMPUTING"},
      {"name":"JOURNAL OF AGRICULTURAL SCIENCE AND TECHNOLOGY"},
      {"name":"LABORATORY PHONOLOGY"},
      {"name":"JOURNAL OF MATHEMATICAL NEUROSCIENCE"},
      {"name":"BIOLOGICAL BULLETIN"},
      {"name":"ASIA EUROPE JOURNAL"},
      {"name":"JOURNAL OF CLINICAL APHERESIS"},
      {"name":"BRITISH JOURNAL OF OCCUPATIONAL THERAPY"},
      {"name":"ORNIS FENNICA"},
      {"name":"REVISTA DA SOCIEDADE BRASILEIRA DE MEDICINA TROPICAL"},
      {"name":"MAMMAL RESEARCH"},
      {"name":"ROMANIAN BIOTECHNOLOGICAL LETTERS"},
      {"name":"ANIMAL SCIENCE PAPERS AND REPORTS"},
      {"name":"JOURNAL OF SHIP RESEARCH"},
      {"name":"NORWEGIAN JOURNAL OF GEOLOGY"},
      {"name":"ANNALS OF OTOLOGY RHINOLOGY AND LARYNGOLOGY"},
      {"name":"SOMATOSENSORY AND MOTOR RESEARCH"},
      {"name":"THEORY INTO PRACTICE"},
      {"name":"ARCHIVES OF RHEUMATOLOGY"},
      {"name":"SOCIOLOGICAL RESEARCH ONLINE"},
      {"name":"JOURNAL OF BIOLOGICAL SYSTEMS"},
      {"name":"VIDEOSURGERY AND OTHER MINIINVASIVE TECHNIQUES"},
      {"name":"HISPANIC JOURNAL OF BEHAVIORAL SCIENCES"},
      {"name":"JOURNAL OF TOURISM AND CULTURAL CHANGE"},
      {"name":"PROBLEMY EKOROZWOJU"},
      {"name":"REVIEW OF DEVELOPMENT ECONOMICS"},
      {"name":"PHYSICS OF WAVE PHENOMENA"},
      {"name":"COMPUTATIONAL AND THEORETICAL CHEMISTRY"},
      {"name":"ADVANCED STEEL CONSTRUCTION"},
      {"name":"SOUTHEAST EUROPEAN AND BLACK SEA STUDIES"},
      {"name":"EMERGENCY MEDICINE CLINICS OF NORTH AMERICA"},
      {"name":"PAIN MANAGEMENT NURSING"},
      {"name":"PSICOLOGIA EDUCATIVA"},
      {"name":"JOURNAL OF ENHANCED HEAT TRANSFER"},
      {"name":"TECHNOLOGY AND CULTURE"},
      {"name":"SYSTEMS RESEARCH AND BEHAVIORAL SCIENCE"},
      {"name":"PACIFIC JOURNAL OF OPTIMIZATION"},
      {"name":"OSIRIS"},
      {"name":"JOURNAL OF MECHANICS IN MEDICINE AND BIOLOGY"},
      {"name":"TRANSPORT"},
      {"name":"MATERIALS EVALUATION"},
      {"name":"TRANSLATIONAL NEUROSCIENCE"},
      {"name":"ADAPTIVE BEHAVIOR"},
      {"name":"LABORATORY MEDICINE"},
      {"name":"TURKISH STUDIES"},
      {"name":"EUROPEAN JOURNAL OF DENTAL EDUCATION"},
      {"name":"CLINICAL NEUROLOGY AND NEUROSURGERY"},
      {"name":"SOCIAL BEHAVIOR AND PERSONALITY"},
      {"name":"ECONOMIC AND SOCIAL REVIEW"},
      {"name":"JOURNAL OF CARDIOVASCULAR SURGERY"},
      {"name":"ADVANCES IN CHILD DEVELOPMENT AND BEHAVIOR"},
      {"name":"CURRICULUM INQUIRY"},
      {"name":"INTERNATIONAL REVIEW OF HYDROBIOLOGY"},
      {"name":"CHILD INDICATORS RESEARCH"},
      {"name":"MEDICAL LAW REVIEW"},
      {"name":"PREHOSPITAL AND DISASTER MEDICINE"},
      {"name":"SOLID STATE COMMUNICATIONS"},
      {"name":"VETERINARY RESEARCH FORUM"},
      {"name":"ANNALES SOCIETATIS GEOLOGORUM POLONIAE"},
      {"name":"NATURAL LANGUAGE SEMANTICS"},
      {"name":"MODERN PHYSICS LETTERS A"},
      {"name":"SHIPS AND OFFSHORE STRUCTURES"},
      {"name":"ACM TRANSACTIONS ON MODELING AND COMPUTER SIMULATION"},
      {"name":"CHINESE JOURNAL OF STRUCTURAL CHEMISTRY"},
      {"name":"JOURNAL OF BIOSCIENCES"},
      {"name":"POSITIVITY"},
      {"name":"POLITICAL SCIENCE"},
      {"name":"JOURNAL OF LASER MICRO NANOENGINEERING"},
      {"name":"AUSTRALIAN ACCOUNTING REVIEW"},
      {"name":"HEC FORUM"},
      {"name":"JOURNAL OF MEDICAL IMAGING AND RADIATION ONCOLOGY"},
      {"name":"ARCHAEOMETRY"},
      {"name":"PROCEEDINGS OF THE ACADEMY OF NATURAL SCIENCES OF PHILADELPHIA"},
      {"name":"JOURNAL OF SCIENCE EDUCATION AND TECHNOLOGY"},
      {"name":"JAPANESE PSYCHOLOGICAL RESEARCH"},
      {"name":"HORTSCIENCE"},
      {"name":"JOURNAL OF DIVERSITY IN HIGHER EDUCATION"},
      {"name":"JOURNAL OF PEDIATRIC ONCOLOGY NURSING"},
      {"name":"ASSEMBLY AUTOMATION"},
      {"name":"JOURNAL OF POVERTY AND SOCIAL JUSTICE"},
      {"name":"JAPANESE JOURNAL OF MATHEMATICS"},
      {"name":"JOURNAL OF THE PHYSICAL SOCIETY OF JAPAN"},
      {"name":"JOURNAL OF AGING STUDIES"},
      {"name":"JOURNAL OF REFUGEE STUDIES"},
      {"name":"ACTA AMAZONICA"},
      {"name":"ADVANCES IN CIVIL ENGINEERING"},
      {"name":"GENDER IN MANAGEMENT"},
      {"name":"JOURNAL OF THE CERAMIC SOCIETY OF JAPAN"},
      {"name":"CHINESE JOURNAL OF PHYSIOLOGY"},
      {"name":"TRANSACTIONS OF THE INDIAN CERAMIC SOCIETY"},
      {"name":"RACE AND SOCIAL PROBLEMS"},
      {"name":"OPERATIONS RESEARCH LETTERS"},
      {"name":"SWISS JOURNAL OF PALAEONTOLOGY"},
      {"name":"INTERNATIONAL OPHTHALMOLOGY"},
      {"name":"AGRICULTURAL AND FOOD SCIENCE"},
      {"name":"EUROPEAN JOURNAL OF CONTROL"},
      {"name":"MULTISENSORY RESEARCH"},
      {"name":"MANAGEMENT COMMUNICATION QUARTERLY"},
      {"name":"JOURNAL OF OIL PALM RESEARCH"},
      {"name":"INDIANA LAW JOURNAL"},
      {"name":"MATHEMATICS OF CONTROL SIGNALS AND SYSTEMS"},
      {"name":"JOURNAL OF ADULT DEVELOPMENT"},
      {"name":"RUSSIAN JOURNAL OF ELECTROCHEMISTRY"},
      {"name":"THEORETICAL AND EXPERIMENTAL PLANT PHYSIOLOGY"},
      {"name":"MATHEMATICAL THINKING AND LEARNING"},
      {"name":"INTERNATIONAL JOURNAL OF FORENSIC MENTAL HEALTH"},
      {"name":"JOURNAL OF DATABASE MANAGEMENT"},
      {"name":"SCANDINAVIAN JOURNAL OF PSYCHOLOGY"},
      {"name":"JOURNAL OF HOUSING AND THE BUILT ENVIRONMENT"},
      {"name":"BIOSCIENCE BIOTECHNOLOGY AND BIOCHEMISTRY"},
      {"name":"JOURNAL FUR DIE REINE UND ANGEWANDTE MATHEMATIK"},
      {"name":"CHEMICAL AND BIOCHEMICAL ENGINEERING QUARTERLY"},
      {"name":"FOLIA NEUROPATHOLOGICA"},
      {"name":"INTERNATIONAL JOURNAL OF ARTIFICIAL ORGANS"},
      {"name":"ECOSCIENCE"},
      {"name":"THEORY AND DECISION"},
      {"name":"REVIEWS IN MATHEMATICAL PHYSICS"},
      {"name":"CORONARY ARTERY DISEASE"},
      {"name":"COMPUTATIONAL METHODS AND FUNCTION THEORY"},
      {"name":"INTERNATIONAL JOURNAL OF BUSINESS COMMUNICATION"},
      {"name":"IEEE COMPUTER GRAPHICS AND APPLICATIONS"},
      {"name":"JOURNAL OF CONTINUING EDUCATION IN NURSING"},
      {"name":"INDIAN JOURNAL OF HEMATOLOGY AND BLOOD TRANSFUSION"},
      {"name":"ENGINEERING MANAGEMENT JOURNAL"},
      {"name":"KNOWLEDGE ENGINEERING REVIEW"},
      {"name":"JOURNAL OF MEDIA ETHICS"},
      {"name":"ANTHROPOLOGISCHER ANZEIGER"},
      {"name":"PHASE TRANSITIONS"},
      {"name":"DENTAL MATERIALS JOURNAL"},
      {"name":"PHYTOPARASITICA"},
      {"name":"IMA JOURNAL OF MANAGEMENT MATHEMATICS"},
      {"name":"JOURNAL OF MOLLUSCAN STUDIES"},
      {"name":"INTERNATIONAL JOURNAL FOR VITAMIN AND NUTRITION RESEARCH"},
      {"name":"JOURNAL OF VETERINARY PHARMACOLOGY AND THERAPEUTICS"},
      {"name":"AUTOMATIKA"},
      {"name":"HERPETOLOGICAL CONSERVATION AND BIOLOGY"},
      {"name":"AUSTRALIAN GEOGRAPHER"},
      {"name":"ITALIAN JOURNAL OF FOOD SCIENCE"},
      {"name":"PEDIATRIC DERMATOLOGY"},
      {"name":"JOURNAL OF SUPERCONDUCTIVITY AND NOVEL MAGNETISM"},
      {"name":"EURASIAN GEOGRAPHY AND ECONOMICS"},
      {"name":"INTERNATIONAL JOURNAL OF NUMERICAL ANALYSIS AND MODELING"},
      {"name":"POWDER DIFFRACTION"},
      {"name":"JOURNAL OF RADIOLOGICAL PROTECTION"},
      {"name":"TRANSFORMATION GROUPS"},
      {"name":"BULLETIN OF INDONESIAN ECONOMIC STUDIES"},
      {"name":"ANNALS OF THE ROYAL COLLEGE OF SURGEONS OF ENGLAND"},
      {"name":"JOURNAL OF INTERNATIONAL CRIMINAL JUSTICE"},
      {"name":"JOURNAL OF EDUCATION FOR TEACHING"},
      {"name":"JOURNAL OF THE IRANIAN CHEMICAL SOCIETY"},
      {"name":"NATURAL RESOURCE MODELING"},
      {"name":"JOURNAL OF INTEGRAL EQUATIONS AND APPLICATIONS"},
      {"name":"GEOBIOS"},
      {"name":"AUSTRALIAN JOURNAL OF PRIMARY HEALTH"},
      {"name":"INTERNATIONAL JOURNAL OF SOCIAL WELFARE"},
      {"name":"DYNAMICS OF PARTIAL DIFFERENTIAL EQUATIONS"},
      {"name":"APPLIED MEASUREMENT IN EDUCATION"},
      {"name":"TURKISH JOURNAL OF EARTH SCIENCES"},
      {"name":"JOURNAL OF MOLECULAR MODELING"},
      {"name":"EUROPEAN JOURNAL OF CONTRACEPTION AND REPRODUCTIVE HEALTH CARE"},
      {"name":"AFRICAN JOURNAL OF AQUATIC SCIENCE"},
      {"name":"JOURNAL OF CONTEMPORARY EUROPEAN STUDIES"},
      {"name":"BULLETIN OF GEOSCIENCES"},
      {"name":"JOURNAL OF LAW AND SOCIETY"},
      {"name":"JOURNAL OF PALLIATIVE CARE"},
      {"name":"AMERICAN JOURNAL OF DERMATOPATHOLOGY"},
      {"name":"ENVIRONMENTAL FLUID MECHANICS"},
      {"name":"READING TEACHER"},
      {"name":"ASIA PACIFIC VIEWPOINT"},
      {"name":"DISEASES OF AQUATIC ORGANISMS"},
      {"name":"INTERNATIONAL JOURNAL OF CULTURAL STUDIES"},
      {"name":"LATIN AMERICAN RESEARCH REVIEW"},
      {"name":"INTERPRETER AND TRANSLATOR TRAINER"},
      {"name":"TOPICS IN EARLY CHILDHOOD SPECIAL EDUCATION"},
      {"name":"MEDICAL ANTHROPOLOGY QUARTERLY"},
      {"name":"JOURNAL OF THE CANADIAN DENTAL ASSOCIATION"},
      {"name":"ZEITSCHRIFT FUR ORTHOPADIE UND UNFALLCHIRURGIE"},
      {"name":"TURKISH JOURNAL OF ELECTRICAL ENGINEERING AND COMPUTER SCIENCES"},
      {"name":"ENTERTAINMENT COMPUTING"},
      {"name":"EUROPEAN MANAGEMENT REVIEW"},
      {"name":"INTERNATIONAL AGROPHYSICS"},
      {"name":"EXPERT OPINION ON ORPHAN DRUGS"},
      {"name":"AMERICAN JOURNAL OF OTOLARYNGOLOGY"},
      {"name":"CANADIAN JOURNAL OF SURGERY"},
      {"name":"PALZ"},
      {"name":"JOURNAL OF APPLIED COMMUNICATION RESEARCH"},
      {"name":"JOURNAL OF INTERNET TECHNOLOGY"},
      {"name":"EUROPEAN JOURNAL OF FINANCE"},
      {"name":"JOURNAL OF SOUTHERN AFRICAN STUDIES"},
      {"name":"CURRENT LEGAL PROBLEMS"},
      {"name":"ACTA CHIROPTEROLOGICA"},
      {"name":"INTERNATIONAL JOURNAL OF CONSUMER STUDIES"},
      {"name":"FOLIA HISTOCHEMICA ET CYTOBIOLOGICA"},
      {"name":"ANIMAL BIODIVERSITY AND CONSERVATION"},
      {"name":"REVISTA INTERNACIONAL DE SOCIOLOGIA"},
      {"name":"AUSTRAL ENTOMOLOGY"},
      {"name":"MACEDONIAN JOURNAL OF CHEMISTRY AND CHEMICAL ENGINEERING"},
      {"name":"SIAM JOURNAL ON FINANCIAL MATHEMATICS"},
      {"name":"APPLIED GEOPHYSICS"},
      {"name":"CURRENT NEUROVASCULAR RESEARCH"},
      {"name":"CANADIAN JOURNAL OF PHYSICS"},
      {"name":"JOURNAL OF POLYMER ENGINEERING"},
      {"name":"ACTA CLINICA BELGICA"},
      {"name":"DATA BASE FOR ADVANCES IN INFORMATION SYSTEMS"},
      {"name":"PROCEEDINGS OF THE AMERICAN MATHEMATICAL SOCIETY"},
      {"name":"OPHTHALMIC EPIDEMIOLOGY"},
      {"name":"JOURNAL OF ECONOMIC INTERACTION AND COORDINATION"},
      {"name":"AMERICAN CERAMIC SOCIETY BULLETIN"},
      {"name":"EUROPEAN JOURNAL OF SOCIAL WORK"},
      {"name":"REVISTA INTERNACIONAL DE MEDICINA Y CIENCIAS DE LA ACTIVIDAD FISICA Y DEL DEPORTE"},
      {"name":"JOURNAL OF EARTH SYSTEM SCIENCE"},
      {"name":"CESIFO ECONOMIC STUDIES"},
      {"name":"IEEE INDUSTRY APPLICATIONS MAGAZINE"},
      {"name":"MICROBIOLOGY"},
      {"name":"MEDYCYNA PRACY"},
      {"name":"JOURNAL OF VETERINARY EMERGENCY AND CRITICAL CARE"},
      {"name":"CAMBRIDGE LAW JOURNAL"},
      {"name":"JOURNAL OF THE AMERICAN MOSQUITO CONTROL ASSOCIATION"},
      {"name":"JOURNAL OF INDUSTRIAL ECONOMICS"},
      {"name":"ACI STRUCTURAL JOURNAL"},
      {"name":"GENETIC TESTING AND MOLECULAR BIOMARKERS"},
      {"name":"MATHEMATICAL COMMUNICATIONS"},
      {"name":"FOOD AND DRUG LAW JOURNAL"},
      {"name":"AUSTRALIAN ECONOMIC REVIEW"},
      {"name":"CIENCIA E AGROTECNOLOGIA"},
      {"name":"BAUINGENIEUR"},
      {"name":"APPLIED PSYCHOLOGICAL MEASUREMENT"},
      {"name":"BANGLADESH JOURNAL OF PHARMACOLOGY"},
      {"name":"FUNDAMENTAL AND APPLIED LIMNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF HUMANOID ROBOTICS"},
      {"name":"NETHERLANDS JOURNAL OF MEDICINE"},
      {"name":"ZOO BIOLOGY"},
      {"name":"WASHINGTON LAW REVIEW"},
      {"name":"JOURNAL OF PERIANESTHESIA NURSING"},
      {"name":"ETHNICITIES"},
      {"name":"ECONOMIC COMPUTATION AND ECONOMIC CYBERNETICS STUDIES AND RESEARCH"},
      {"name":"JOURNAL OF SMALL ANIMAL PRACTICE"},
      {"name":"CONTEMPORARY POLITICAL THEORY"},
      {"name":"BRITISH JOURNAL OF RELIGIOUS EDUCATION"},
      {"name":"EUROPEAN JOURNAL OF PSYCHOLOGY OF EDUCATION"},
      {"name":"CANADIAN METALLURGICAL QUARTERLY"},
      {"name":"FOLIA MORPHOLOGICA"},
      {"name":"POTATO RESEARCH"},
      {"name":"ZEITSCHRIFT FUR ERZIEHUNGSWISSENSCHAFT"},
      {"name":"JOURNAL OF DRUGS IN DERMATOLOGY"},
      {"name":"MEDICAL HISTORY"},
      {"name":"PHYSICAL MESOMECHANICS"},
      {"name":"SOCIAL COMPASS"},
      {"name":"SOUTH AFRICAN JOURNAL OF PSYCHOLOGY"},
      {"name":"PEDIATRICS INTERNATIONAL"},
      {"name":"INTEGRAL EQUATIONS AND OPERATOR THEORY"},
      {"name":"INTERNATIONAL JOURNAL OF THERMOPHYSICS"},
      {"name":"CURRENT OPTICS AND PHOTONICS"},
      {"name":"PERIODICA MATHEMATICA HUNGARICA"},
      {"name":"COMPOSITIO MATHEMATICA"},
      {"name":"AFRICA"},
      {"name":"INTERNATIONAL JOURNAL OF TECHNOLOGY AND DESIGN EDUCATION"},
      {"name":"JOURNAL OF MOUNTAIN SCIENCE"},
      {"name":"APPLIED ECONOMICS"},
      {"name":"CENTRAL EUROPEAN JOURNAL OF ENERGETIC MATERIALS"},
      {"name":"LATIN AMERICAN ECONOMIC REVIEW"},
      {"name":"AUSTRALIAN JOURNAL OF BOTANY"},
      {"name":"STRUCTURAL ENGINEERING INTERNATIONAL"},
      {"name":"JOURNAL OF THE NATIONAL MEDICAL ASSOCIATION"},
      {"name":"INTERNATIONAL JOURNAL OF SENSOR NETWORKS"},
      {"name":"JOURNAL OF PORTFOLIO MANAGEMENT"},
      {"name":"COMPUTATIONAL AND MATHEMATICAL ORGANIZATION THEORY"},
      {"name":"JOURNAL OF NURSING CARE QUALITY"},
      {"name":"TOMOGRAPHY"},
      {"name":"JOURNAL OF NIPPON MEDICAL SCHOOL"},
      {"name":"SOCIAL EPISTEMOLOGY"},
      {"name":"JOURNAL OF THE AMERICAN SOCIETY OF BREWING CHEMISTS"},
      {"name":"BIOMETRICAL JOURNAL"},
      {"name":"INTERACTING WITH COMPUTERS"},
      {"name":"JOURNAL OF PERSONNEL PSYCHOLOGY"},
      {"name":"EUROPEAN JOURNAL OF WOOD AND WOOD PRODUCTS"},
      {"name":"LINGUISTICS AND EDUCATION"},
      {"name":"LABOUR ECONOMICS"},
      {"name":"PHOTOGRAMMETRIC ENGINEERING AND REMOTE SENSING"},
      {"name":"THEORETICAL CHEMISTRY ACCOUNTS"},
      {"name":"ACTA ZOOLOGICA"},
      {"name":"AFRICA SPECTRUM"},
      {"name":"COMMUNITY ECOLOGY"},
      {"name":"JOURNAL OF RADIOANALYTICAL AND NUCLEAR CHEMISTRY"},
      {"name":"JOURNAL OF EQUINE VETERINARY SCIENCE"},
      {"name":"INTERNATIONAL JOURNAL OF PLANT SCIENCES"},
      {"name":"INTERNATIONAL JOURNAL OF CLINICAL AND EXPERIMENTAL HYPNOSIS"},
      {"name":"CANCER RADIOTHERAPIE"},
      {"name":"REVUE SUISSE DE ZOOLOGIE"},
      {"name":"JOURNAL OF FELINE MEDICINE AND SURGERY"},
      {"name":"BOLETIN DE LA ASOCIACION DE GEOGRAFOS ESPANOLES"},
      {"name":"BOIS ET FORETS DES TROPIQUES"},
      {"name":"PALEONTOLOGICAL RESEARCH"},
      {"name":"BULLETIN OF THE MALAYSIAN MATHEMATICAL SCIENCES SOCIETY"},
      {"name":"JOURNAL OF WOOD SCIENCE"},
      {"name":"LC GC NORTH AMERICA"},
      {"name":"FISHERIES SCIENCE"},
      {"name":"JOURNAL OF MODERN OPTICS"},
      {"name":"JOURNAL OF DIFFERENCE EQUATIONS AND APPLICATIONS"},
      {"name":"KOREAN JOURNAL OF METALS AND MATERIALS"},
      {"name":"ACCOUNTING HORIZONS"},
      {"name":"JOURNAL OF ENVIRONMENTAL HEALTH"},
      {"name":"CURRENT SPORTS MEDICINE REPORTS"},
      {"name":"GIORNALE ITALIANO DI DERMATOLOGIA E VENEREOLOGIA"},
      {"name":"COMPUTER APPLICATIONS IN ENGINEERING EDUCATION"},
      {"name":"PETROLEUM GEOSCIENCE"},
      {"name":"ACTA CHROMATOGRAPHICA"},
      {"name":"POLISH POLAR RESEARCH"},
      {"name":"ANNALES ZOOLOGICI FENNICI"},
      {"name":"GLOBAL NEST JOURNAL"},
      {"name":"ARCHIVES OF INSECT BIOCHEMISTRY AND PHYSIOLOGY"},
      {"name":"MIRES AND PEAT"},
      {"name":"PEDIATRIC ANNALS"},
      {"name":"RICERCHE DI MATEMATICA"},
      {"name":"APPLIED RADIATION AND ISOTOPES"},
      {"name":"JOURNAL OF ASTRONOMICAL TELESCOPES INSTRUMENTS AND SYSTEMS"},
      {"name":"POLISH JOURNAL OF MICROBIOLOGY"},
      {"name":"RUSSIAN JOURNAL OF MATHEMATICAL PHYSICS"},
      {"name":"REVISTA MATEMATICA IBEROAMERICANA"},
      {"name":"JOURNAL FOR THE SCIENTIFIC STUDY OF RELIGION"},
      {"name":"RANGELAND JOURNAL"},
      {"name":"RADIATION AND ENVIRONMENTAL BIOPHYSICS"},
      {"name":"MYCOSCIENCE"},
      {"name":"JOURNAL OF MATERIALS ENGINEERING AND PERFORMANCE"},
      {"name":"ELECTRIC POWER COMPONENTS AND SYSTEMS"},
      {"name":"JOURNAL OF CONTEMPORARY CRIMINAL JUSTICE"},
      {"name":"INDIAN JOURNAL OF EXPERIMENTAL BIOLOGY"},
      {"name":"IEEE AEROSPACE AND ELECTRONIC SYSTEMS MAGAZINE"},
      {"name":"JOURNAL OF PSYCHOSOCIAL ONCOLOGY"},
      {"name":"APPLICABLE ANALYSIS"},
      {"name":"ECONOMICS OF TRANSPORTATION"},
      {"name":"HORTICULTURAL SCIENCE"},
      {"name":"NAUTILUS"},
      {"name":"ORTHOPEDICS"},
      {"name":"TURKISH JOURNAL OF FISHERIES AND AQUATIC SCIENCES"},
      {"name":"JOURNAL OF PHYTOPATHOLOGY"},
      {"name":"SAGE OPEN"},
      {"name":"INTERNATIONAL JOURNAL OF AEROSPACE PSYCHOLOGY"},
      {"name":"HIGH ENERGY DENSITY PHYSICS"},
      {"name":"BRITISH DENTAL JOURNAL"},
      {"name":"COMPUTATIONAL INTELLIGENCE"},
      {"name":"EUROPEAN JOURNAL OF MASS SPECTROMETRY"},
      {"name":"PROCEEDINGS OF THE ESTONIAN ACADEMY OF SCIENCES"},
      {"name":"INDIAN JOURNAL OF MEDICAL RESEARCH"},
      {"name":"PERSPECTIVES IN PSYCHIATRIC CARE"},
      {"name":"LEGAL MEDICINE"},
      {"name":"JOURNAL OF MARINE ENGINEERING AND TECHNOLOGY"},
      {"name":"CELEBRITY STUDIES"},
      {"name":"JOURNAL OF THE SOUTH AFRICAN VETERINARY ASSOCIATION"},
      {"name":"GEORGE WASHINGTON LAW REVIEW"},
      {"name":"TEACHERS COLLEGE RECORD"},
      {"name":"INTERNATIONAL JOURNAL OF SOFTWARE ENGINEERING AND KNOWLEDGE ENGINEERING"},
      {"name":"ORTHOPAEDIC NURSING"},
      {"name":"JOURNAL OF GRAPH THEORY"},
      {"name":"JOURNAL OF PSYCHOPHYSIOLOGY"},
      {"name":"THEORETICAL AND MATHEMATICAL PHYSICS"},
      {"name":"TRANSPORTATION PLANNING AND TECHNOLOGY"},
      {"name":"JOURNAL OF POLICY MODELING"},
      {"name":"BULLETIN OF THE GEOLOGICAL SOCIETY OF FINLAND"},
      {"name":"ANIMAL PRODUCTION SCIENCE"},
      {"name":"VERHALTENSTHERAPIE"},
      {"name":"SPACE POLICY"},
      {"name":"HNO"},
      {"name":"ARCHIVES ANIMAL BREEDING"},
      {"name":"CHINESE JOURNAL OF ANALYTICAL CHEMISTRY"},
      {"name":"NANOSCIENCE AND NANOTECHNOLOGY LETTERS"},
      {"name":"JETP LETTERS"},
      {"name":"APPLIED COMPUTATIONAL ELECTROMAGNETICS SOCIETY JOURNAL"},
      {"name":"ANNALES GEOPHYSICAE"},
      {"name":"JOURNAL OF MORPHOLOGY"},
      {"name":"WOOD RESEARCH"},
      {"name":"INTERNATIONAL INTERACTIONS"},
      {"name":"SEXUAL AND RELATIONSHIP THERAPY"},
      {"name":"LASER PHYSICS"},
      {"name":"EDUCATIONAL ASSESSMENT EVALUATION AND ACCOUNTABILITY"},
      {"name":"SOCIAL SCIENCE JAPAN JOURNAL"},
      {"name":"SPANISH JOURNAL OF AGRICULTURAL RESEARCH"},
      {"name":"INTERNATIONAL JOURNAL OF SATELLITE COMMUNICATIONS AND NETWORKING"},
      {"name":"QUANTITATIVE FINANCE"},
      {"name":"MONATSHEFTE FUR MATHEMATIK"},
      {"name":"EXPERIMENTAL PSYCHOLOGY"},
      {"name":"FACIAL PLASTIC SURGERY CLINICS OF NORTH AMERICA"},
      {"name":"UROLOGY JOURNAL"},
      {"name":"JOURNAL OF EMERGENCY NURSING"},
      {"name":"DEVELOPING ECONOMIES"},
      {"name":"ACM TRANSACTIONS ON PROGRAMMING LANGUAGES AND SYSTEMS"},
      {"name":"HAUTARZT"},
      {"name":"JOURNAL OF PLANT PATHOLOGY"},
      {"name":"ANNALS OF NONINVASIVE ELECTROCARDIOLOGY"},
      {"name":"ORNITHOLOGICAL SCIENCE"},
      {"name":"EUROPEAN JOURNAL OF ENTOMOLOGY"},
      {"name":"ANNALS OF PLASTIC SURGERY"},
      {"name":"CIRUGIA ESPANOLA"},
      {"name":"INTERNATIONAL SOCIAL WORK"},
      {"name":"JOURNAL OF BIOETHICAL INQUIRY"},
      {"name":"JOURNAL OF POLITICAL PHILOSOPHY"},
      {"name":"SCANDINAVIAN JOURNAL OF UROLOGY"},
      {"name":"COMBUSTION EXPLOSION AND SHOCK WAVES"},
      {"name":"LETTERS IN ORGANIC CHEMISTRY"},
      {"name":"LOGICA UNIVERSALIS"},
      {"name":"SCIENTIA AGRICOLA"},
      {"name":"SCIENCE AND TECHNOLOGY STUDIES"},
      {"name":"INDIAN JOURNAL OF ORTHOPAEDICS"},
      {"name":"CENTRAL EUROPEAN JOURNAL OF IMMUNOLOGY"},
      {"name":"MEDIA AND COMMUNICATION"},
      {"name":"INTERNATIONAL JOURNAL OF APPLIED ELECTROMAGNETICS AND MECHANICS"},
      {"name":"CHILEAN JOURNAL OF AGRICULTURAL RESEARCH"},
      {"name":"PHYSICAL GEOGRAPHY"},
      {"name":"ADVANCES IN DIFFERENTIAL EQUATIONS"},
      {"name":"CHINESE JOURNAL OF INORGANIC CHEMISTRY"},
      {"name":"JOURNAL OF PSYCHIATRIC PRACTICE"},
      {"name":"JOURNAL OF VETERINARY MEDICAL EDUCATION"},
      {"name":"AMERICAN JOURNAL OF HUMAN BIOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF CRASHWORTHINESS"},
      {"name":"JOURNAL OF BRYOLOGY"},
      {"name":"COMMUNITY DENTAL HEALTH"},
      {"name":"JOURNAL OF ENERGY ENGINEERING"},
      {"name":"JOURNAL OF GEOGRAPHY"},
      {"name":"AGREKON"},
      {"name":"JOURNAL OF THE PHILOSOPHY OF SPORT"},
      {"name":"REPRESENTATION THEORY"},
      {"name":"JOURNAL OF THERMAL SCIENCE AND ENGINEERING APPLICATIONS"},
      {"name":"CANADIAN ENTOMOLOGIST"},
      {"name":"HELGOLAND MARINE RESEARCH"},
      {"name":"JOURNAL OF APICULTURAL SCIENCE"},
      {"name":"CYTOGENETIC AND GENOME RESEARCH"},
      {"name":"NOTORNIS"},
      {"name":"INTERNATIONAL JOURNAL OF PHARMACOLOGY"},
      {"name":"ACTA OECONOMICA"},
      {"name":"CHILD ABUSE REVIEW"},
      {"name":"INDIAN PEDIATRICS"},
      {"name":"ACTA HISTOCHEMICA ET CYTOCHEMICA"},
      {"name":"BULLETIN OF THE AMERICAN MATHEMATICAL SOCIETY"},
      {"name":"JOURNAL OF COMMUNITY HEALTH"},
      {"name":"SAUDI MEDICAL JOURNAL"},
      {"name":"JOURNAL OF THE WORLD AQUACULTURE SOCIETY"},
      {"name":"ADVANCES IN DATA ANALYSIS AND CLASSIFICATION"},
      {"name":"GYNECOLOGIC AND OBSTETRIC INVESTIGATION"},
      {"name":"JOURNAL OF PARASITOLOGY"},
      {"name":"AUSTRALIAN JOURNAL OF INTERNATIONAL AFFAIRS"},
      {"name":"LICHENOLOGIST"},
      {"name":"SPORTS MEDICINE AND ARTHROSCOPY REVIEW"},
      {"name":"MOBILIZATION"},
      {"name":"THEORY AND PRACTICE OF LOGIC PROGRAMMING"},
      {"name":"JOURNAL OF FOOD PROTECTION"},
      {"name":"JOURNAL OF APPLIED ANIMAL RESEARCH"},
      {"name":"FIXED POINT THEORY"},
      {"name":"JOURNAL OF SEXUAL AGGRESSION"},
      {"name":"JOURNAL OF THE ROYAL ANTHROPOLOGICAL INSTITUTE"},
      {"name":"BIORHEOLOGY"},
      {"name":"JOURNAL OF CHILDREN AND MEDIA"},
      {"name":"JOURNAL OF DYNAMICS AND DIFFERENTIAL EQUATIONS"},
      {"name":"CHIMIA"},
      {"name":"ENVIRONMENT AND DEVELOPMENT ECONOMICS"},
      {"name":"JOURNAL OF PHYSICAL ORGANIC CHEMISTRY"},
      {"name":"BIOTECHNOLOGY AND APPLIED BIOCHEMISTRY"},
      {"name":"ACTA BIOTHEORETICA"},
      {"name":"DESALINATION AND WATER TREATMENT"},
      {"name":"JOURNAL OF CHILD AND FAMILY STUDIES"},
      {"name":"CYTOJOURNAL"},
      {"name":"JOURNAL OF AQUATIC FOOD PRODUCT TECHNOLOGY"},
      {"name":"DREAMING"},
      {"name":"JOURNAL OF APPLIED SOCIAL PSYCHOLOGY"},
      {"name":"JOURNAL OF THE INSTITUTE OF MATHEMATICS OF JUSSIEU"},
      {"name":"CRITICAL ASIAN STUDIES"},
      {"name":"BIOCHEMISTRY AND MOLECULAR BIOLOGY EDUCATION"},
      {"name":"HEART AND VESSELS"},
      {"name":"ECONOMICS OF EDUCATION REVIEW"},
      {"name":"JOURNAL OF COLD REGIONS ENGINEERING"},
      {"name":"TOHOKU JOURNAL OF EXPERIMENTAL MEDICINE"},
      {"name":"STATISTICAL MODELLING"},
      {"name":"FLUID DYNAMICS RESEARCH"},
      {"name":"JOURNAL OF FORENSIC AND LEGAL MEDICINE"},
      {"name":"JOURNAL OF INCLUSION PHENOMENA AND MACROCYCLIC CHEMISTRY"},
      {"name":"PLASMA PHYSICS REPORTS"},
      {"name":"ETHOLOGY"},
      {"name":"APPLIED PSYCHOPHYSIOLOGY AND BIOFEEDBACK"},
      {"name":"FOOD SCIENCE AND BIOTECHNOLOGY"},
      {"name":"AUSTRALASIAN PLANT PATHOLOGY"},
      {"name":"JOURNAL OF ADVANCED CONCRETE TECHNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF HERITAGE STUDIES"},
      {"name":"TROPICAL CONSERVATION SCIENCE"},
      {"name":"MACHINE VISION AND APPLICATIONS"},
      {"name":"INTERNATIONAL JOURNAL OF HEALTH SERVICES"},
      {"name":"ADVANCED NONLINEAR STUDIES"},
      {"name":"JOURNAL OF INSECT CONSERVATION"},
      {"name":"GEOLOGY OF ORE DEPOSITS"},
      {"name":"CHINA OCEAN ENGINEERING"},
      {"name":"LOW TEMPERATURE PHYSICS"},
      {"name":"JOURNAL OF PACIFIC RIM PSYCHOLOGY"},
      {"name":"JOURNAL OF ENGLISH LINGUISTICS"},
      {"name":"JOURNAL OF INTERNATIONAL DEVELOPMENT"},
      {"name":"ERGODIC THEORY AND DYNAMICAL SYSTEMS"},
      {"name":"CEREAL FOODS WORLD"},
      {"name":"PHYSICS OF METALS AND METALLOGRAPHY"},
      {"name":"JAPAN AND THE WORLD ECONOMY"},
      {"name":"JOURNAL OF VETERINARY SCIENCE"},
      {"name":"CAREER DEVELOPMENT QUARTERLY"},
      {"name":"JOURNAL OF THE HISTORY OF BIOLOGY"},
      {"name":"JOURNAL OF APPLIED BOTANY AND FOOD QUALITY"},
      {"name":"JOURNAL OF THE MARINE BIOLOGICAL ASSOCIATION OF THE UNITED KINGDOM"},
      {"name":"VETERINARY ANAESTHESIA AND ANALGESIA"},
      {"name":"NEUROCHIRURGIE"},
      {"name":"INTERNATIONAL JOURNAL OF SELECTION AND ASSESSMENT"},
      {"name":"INTERNATIONAL JOURNAL OF INCLUSIVE EDUCATION"},
      {"name":"SCANDINAVIAN JOURNAL OF ECONOMICS"},
      {"name":"MATHEMATIKA"},
      {"name":"ASTRONOMISCHE NACHRICHTEN"},
      {"name":"OPTICAL REVIEW"},
      {"name":"JOURNAL OF HIGHER EDUCATION POLICY AND MANAGEMENT"},
      {"name":"WASHINGTON QUARTERLY"},
      {"name":"NEW ZEALAND JOURNAL OF BOTANY"},
      {"name":"ZEITSCHRIFT FUR PSYCHOSOMATISCHE MEDIZIN UND PSYCHOTHERAPIE"},
      {"name":"RADIATION MEASUREMENTS"},
      {"name":"JOURNAL OF CHILD SEXUAL ABUSE"},
      {"name":"NORTH AMERICAN JOURNAL OF FISHERIES MANAGEMENT"},
      {"name":"LOGOPEDICS PHONIATRICS VOCOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF INFORMATION SECURITY"},
      {"name":"ACTA NEUROBIOLOGIAE EXPERIMENTALIS"},
      {"name":"GROWTH FACTORS"},
      {"name":"JOURNAL OF CHILD LANGUAGE"},
      {"name":"EXPERIMENTAL BRAIN RESEARCH"},
      {"name":"JOURNAL OF CHEMICAL SCIENCES"},
      {"name":"MEN AND MASCULINITIES"},
      {"name":"CURRENT MEDICAL IMAGING"},
      {"name":"FILOMAT"},
      {"name":"CELLULAR AND MOLECULAR BIOLOGY"},
      {"name":"HEALTH PROMOTION JOURNAL OF AUSTRALIA"},
      {"name":"ENVIRONMENTAL ENTOMOLOGY"},
      {"name":"GEOSCIENCE CANADA"},
      {"name":"BRITISH JOURNAL OF SOCIAL WORK"},
      {"name":"MEDICINA DEL LAVORO"},
      {"name":"LIBRARY HI TECH"},
      {"name":"SOIL AND WATER RESEARCH"},
      {"name":"TRANSPORTATION RESEARCH RECORD"},
      {"name":"IRRIGATION AND DRAINAGE"},
      {"name":"STUDIA GEOPHYSICA ET GEODAETICA"},
      {"name":"EUROPEAN JOURNAL OF OPHTHALMOLOGY"},
      {"name":"THEORETICAL MEDICINE AND BIOETHICS"},
      {"name":"SOCIAL HISTORY OF MEDICINE"},
      {"name":"TURKISH JOURNAL OF CHEMISTRY"},
      {"name":"THORACIC AND CARDIOVASCULAR SURGEON"},
      {"name":"ADVANCES IN NEONATAL CARE"},
      {"name":"INTERNATIONAL JOURNAL OF DATA MINING AND BIOINFORMATICS"},
      {"name":"JOURNAL OF OCCUPATIONAL AND ENVIRONMENTAL MEDICINE"},
      {"name":"VITIS"},
      {"name":"SLEEP AND BIOLOGICAL RHYTHMS"},
      {"name":"PSYCHOLOGY OF LEARNING AND MOTIVATION"},
      {"name":"MAMMALIAN BIOLOGY"},
      {"name":"NORDIC JOURNAL OF BOTANY"},
      {"name":"JOURNAL OF MECHANICAL SCIENCE AND TECHNOLOGY"},
      {"name":"JOURNAL OF SOLUTION CHEMISTRY"},
      {"name":"ACTA CHIRURGICA BELGICA"},
      {"name":"METROECONOMICA"},
      {"name":"ADVANCES IN CONDENSED MATTER PHYSICS"},
      {"name":"LATIN AMERICAN PERSPECTIVES"},
      {"name":"ROCZNIK OCHRONA SRODOWISKA"},
      {"name":"JOURNAL OF ELEMENTOLOGY"},
      {"name":"EUROPEAN JOURNAL OF CULTURAL STUDIES"},
      {"name":"LATIN AMERICAN POLITICS AND SOCIETY"},
      {"name":"JOURNAL OF REAL ESTATE RESEARCH"},
      {"name":"COMPUTER ASSISTED SURGERY"},
      {"name":"METHODOLOGY AND COMPUTING IN APPLIED PROBABILITY"},
      {"name":"JOURNAL OF OCCUPATIONAL AND ENVIRONMENTAL HYGIENE"},
      {"name":"PHILOSOPHY OF THE SOCIAL SCIENCES"},
      {"name":"HIGH ENERGY CHEMISTRY"},
      {"name":"PHYSICAL COMMUNICATION"},
      {"name":"SOUTHERN CALIFORNIA LAW REVIEW"},
      {"name":"ANNALS OF SURGICAL TREATMENT AND RESEARCH"},
      {"name":"NONDESTRUCTIVE TESTING AND EVALUATION"},
      {"name":"EUROPEAN FINANCIAL MANAGEMENT"},
      {"name":"PACIFIC JOURNAL OF MATHEMATICS"},
      {"name":"FEMINIST MEDIA STUDIES"},
      {"name":"JOURNAL OF CLINICAL PSYCHOLOGY IN MEDICAL SETTINGS"},
      {"name":"JOURNAL OF DENTAL SCIENCES"},
      {"name":"CYTOLOGIA"},
      {"name":"JOURNAL OF COMPARATIVE POLICY ANALYSIS"},
      {"name":"ISLAND ARC"},
      {"name":"ANNALS OF GLOBAL ANALYSIS AND GEOMETRY"},
      {"name":"JOURNAL OF INSECT SCIENCE"},
      {"name":"JOURNAL OF ELASTOMERS AND PLASTICS"},
      {"name":"MARINE AND FRESHWATER RESEARCH"},
      {"name":"ASIAN JOURNAL OF TECHNOLOGY INNOVATION"},
      {"name":"POLISH JOURNAL OF ENVIRONMENTAL STUDIES"},
      {"name":"SOUTH AFRICAN JOURNAL OF ANIMAL SCIENCE"},
      {"name":"PEDIATRIC TRANSPLANTATION"},
      {"name":"ACTA GEOLOGICA POLONICA"},
      {"name":"JOURNAL OF ELECTROCHEMICAL SCIENCE AND TECHNOLOGY"},
      {"name":"FULLERENES NANOTUBES AND CARBON NANOSTRUCTURES"},
      {"name":"ECONOMICS AND PHILOSOPHY"},
      {"name":"INTERNATIONAL JOURNAL OF GREEN ENERGY"},
      {"name":"JOURNAL OF INTERDISCIPLINARY HISTORY"},
      {"name":"CRITICAL SOCIOLOGY"},
      {"name":"ACM JOURNAL ON EMERGING TECHNOLOGIES IN COMPUTING SYSTEMS"},
      {"name":"CHIRURG"},
      {"name":"SOCIAL SCIENCE JOURNAL"},
      {"name":"JOURNAL OF GENDER STUDIES"},
      {"name":"ECONOMICS OF TRANSITION"},
      {"name":"STUDIES IN HISTORY AND PHILOSOPHY OF MODERN PHYSICS"},
      {"name":"CLIOMETRICA"},
      {"name":"JOURNAL OF SPECTROSCOPY"},
      {"name":"SWISS JOURNAL OF GEOSCIENCES"},
      {"name":"ACM TRANSACTIONS ON STORAGE"},
      {"name":"YONAGO ACTA MEDICA"},
      {"name":"INDIAN JOURNAL OF CANCER"},
      {"name":"ELECTRONIC JOURNAL OF STATISTICS"},
      {"name":"PHYTOTAXA"},
      {"name":"BIOTECHNIQUES"},
      {"name":"BULLETIN OF MARINE SCIENCE"},
      {"name":"ALTERNATIVE THERAPIES IN HEALTH AND MEDICINE"},
      {"name":"BULLETIN DU CANCER"},
      {"name":"ANNALS OF SAUDI MEDICINE"},
      {"name":"INTERNATIONAL JOURNAL OF COMPUTATIONAL FLUID DYNAMICS"},
      {"name":"ADVANCES IN CALCULUS OF VARIATIONS"},
      {"name":"INORGANIC MATERIALS"},
      {"name":"REVIEW OF WORLD ECONOMICS"},
      {"name":"EDUCATION AND TRAINING IN AUTISM AND DEVELOPMENTAL DISABILITIES"},
      {"name":"REGIONAL STUDIES IN MARINE SCIENCE"},
      {"name":"JOURNAL OF INORGANIC MATERIALS"},
      {"name":"ARTS IN PSYCHOTHERAPY"},
      {"name":"MICROPROCESSORS AND MICROSYSTEMS"},
      {"name":"CARDIOLOGY RESEARCH AND PRACTICE"},
      {"name":"APPLIED ENGINEERING IN AGRICULTURE"},
      {"name":"INTERNATIONAL JOURNAL OF ENVIRONMENTAL ANALYTICAL CHEMISTRY"},
      {"name":"LANGUAGE AND INTERCULTURAL COMMUNICATION"},
      {"name":"LINGUISTIC REVIEW"},
      {"name":"EARLY CHILD DEVELOPMENT AND CARE"},
      {"name":"HUMAN PERFORMANCE"},
      {"name":"INTERNATIONAL JOURNAL OF WAVELETS MULTIRESOLUTION AND INFORMATION PROCESSING"},
      {"name":"MATHEMATISCHE ANNALEN"},
      {"name":"ANNALS OF GEOPHYSICS"},
      {"name":"QUARTERLY JOURNAL OF MECHANICS AND APPLIED MATHEMATICS"},
      {"name":"BRITISH JOURNAL FOR THE HISTORY OF SCIENCE"},
      {"name":"RESEARCH IN ASTRONOMY AND ASTROPHYSICS"},
      {"name":"JOURNAL OF OBSTETRICS AND GYNAECOLOGY RESEARCH"},
      {"name":"SCIENCE AND ENGINEERING OF COMPOSITE MATERIALS"},
      {"name":"QUEUEING SYSTEMS"},
      {"name":"INTERNATIONAL JOURNAL OF QUANTUM INFORMATION"},
      {"name":"JOURNAL OF THE SOCIETY FOR SOCIAL WORK AND RESEARCH"},
      {"name":"JOURNAL OF DEVELOPMENT STUDIES"},
      {"name":"JOURNAL OF CLINICAL LABORATORY ANALYSIS"},
      {"name":"ACTAS UROLOGICAS ESPANOLAS"},
      {"name":"INTERNATIONAL JOURNAL OF MICRO AIR VEHICLES"},
      {"name":"JOURNAL OF THE BRAZILIAN CHEMICAL SOCIETY"},
      {"name":"JOURNAL OF FISH AND WILDLIFE MANAGEMENT"},
      {"name":"TRANSLATIONAL CANCER RESEARCH"},
      {"name":"PHYSICS OF THE SOLID STATE"},
      {"name":"JOURNAL OF OPERATOR THEORY"},
      {"name":"IEEE MAGNETICS LETTERS"},
      {"name":"INTERNATIONAL JOURNAL OF ELECTRONICS"},
      {"name":"INTERCULTURAL PRAGMATICS"},
      {"name":"DEVELOPMENT SOUTHERN AFRICA"},
      {"name":"PERCEPTUAL AND MOTOR SKILLS"},
      {"name":"GEOMETRIC AND FUNCTIONAL ANALYSIS"},
      {"name":"JOURNAL OF FAMILY THERAPY"},
      {"name":"WETLANDS ECOLOGY AND MANAGEMENT"},
      {"name":"SYSTEMATIC PARASITOLOGY"},
      {"name":"PRISON JOURNAL"},
      {"name":"JOURNAL OF MOLECULAR MICROBIOLOGY AND BIOTECHNOLOGY"},
      {"name":"TERRESTRIAL ATMOSPHERIC AND OCEANIC SCIENCES"},
      {"name":"JOURNAL OF HISTORICAL GEOGRAPHY"},
      {"name":"JOURNAL OF DEMOGRAPHIC ECONOMICS"},
      {"name":"MATHEMATICA SLOVACA"},
      {"name":"AMME IDARESI DERGISI"},
      {"name":"ECON JOURNAL WATCH"},
      {"name":"RUSSIAN JOURNAL OF GENETICS"},
      {"name":"ENGLISH IN AUSTRALIA"},
      {"name":"EUROPEAN REVIEW"},
      {"name":"DOKLADY PHYSICS"},
      {"name":"MIDDLE EASTERN STUDIES"},
      {"name":"LASER FOCUS WORLD"},
      {"name":"CURRENT HERPETOLOGY"},
      {"name":"ASSISTENZA INFERMIERISTICA E RICERCA"},
      {"name":"CIRCULO DE LINGUISTICA APLICADA A LA COMUNICACION"},
      {"name":"NUTRITION CLINIQUE ET METABOLISME"},
      {"name":"FOLIA LINGUISTICA"},
      {"name":"REVIEW OF INTERNATIONAL ECONOMICS"},
      {"name":"ZEITSCHRIFT FUR DIALEKTOLOGIE UND LINGUISTIK"},
      {"name":"ACTA BIOLOGICA COLOMBIANA"},
      {"name":"EUROPEAN JOURNAL OF INFLAMMATION"},
      {"name":"JOURNAL OF THE LEPIDOPTERISTS SOCIETY"},
      {"name":"SAUDE E SOCIEDADE"},
      {"name":"JOURNAL OF THE HELLENIC VETERINARY MEDICAL SOCIETY"},
      {"name":"COSMIC RESEARCH"},
      {"name":"INDIAN JOURNAL OF GENETICS AND PLANT BREEDING"},
      {"name":"HISTORICAL STUDIES IN THE NATURAL SCIENCES"},
      {"name":"VLAAMS DIERGENEESKUNDIG TIJDSCHRIFT"},
      {"name":"JOURNAL OF ENGINEERING RESEARCH"},
      {"name":"OBSERVATORY"},
      {"name":"ISRAEL JOURNAL OF VETERINARY MEDICINE"},
      {"name":"JOURNAL OF NONPARAMETRIC STATISTICS"},
      {"name":"CHEMICKE LISTY"},
      {"name":"EARTH MOON AND PLANETS"},
      {"name":"ACTA CHIRURGIAE ORTHOPAEDICAE ET TRAUMATOLOGIAE CECHOSLOVACA"},
      {"name":"JOURNAL OF NEPAL MEDICAL ASSOCIATION"},
      {"name":"LIBRARY AND INFORMATION SCIENCE"},
      {"name":"LABOR HISTORY"},
      {"name":"CYBIUM"},
      {"name":"EUROPEAN BUSINESS ORGANIZATION LAW REVIEW"},
      {"name":"BOLETIM DO INSTITUTO DE PESCA"},
      {"name":"BUNSEKI KAGAKU"},
      {"name":"JOURNAL OF KOREAN ACADEMY OF NURSING"},
      {"name":"AATCC REVIEW"},
      {"name":"ANZIAM JOURNAL"},
      {"name":"MITTEILUNGEN KLOSTERNEUBURG"},
      {"name":"DIGITAL SCHOLARSHIP IN THE HUMANITIES"},
      {"name":"STUDIES IN SYMBOLIC INTERACTION"},
      {"name":"SOULS"},
      {"name":"OCEANIA"},
      {"name":"JOURNAL OF MENTAL HEALTH POLICY AND ECONOMICS"},
      {"name":"INTERNATIONAL JOURNAL OF SPORT FINANCE"},
      {"name":"ARQUIVOS BRASILEIROS DE OFTALMOLOGIA"},
      {"name":"ANNALES DE DERMATOLOGIE ET DE VENEREOLOGIE"},
      {"name":"ACTA PHYSICA POLONICA B"},
      {"name":"PROCEEDINGS OF THE EDINBURGH MATHEMATICAL SOCIETY"},
      {"name":"SCANDIA"},
      {"name":"FISH PATHOLOGY"},
      {"name":"SOUTH AFRICAN JOURNAL OF BUSINESS MANAGEMENT"},
      {"name":"ACTA BIOLOGICA CRACOVIENSIA SERIES BOTANICA"},
      {"name":"ROMANI STUDIES"},
      {"name":"PHYSICS TEACHER"},
      {"name":"PROGRAMMING AND COMPUTER SOFTWARE"},
      {"name":"JOURNAL OF THE PALAEONTOLOGICAL SOCIETY OF INDIA"},
      {"name":"JOURNAL OF CHEMICAL RESEARCH"},
      {"name":"OCEAN DEVELOPMENT AND INTERNATIONAL LAW"},
      {"name":"RANGE MANAGEMENT AND AGROFORESTRY"},
      {"name":"JOURNAL OF ECONOMIC ISSUES"},
      {"name":"SOUTH AFRICAN JOURNAL ON HUMAN RIGHTS"},
      {"name":"REVISTA CHILENA DE INFECTOLOGIA"},
      {"name":"GEORGIAN MATHEMATICAL JOURNAL"},
      {"name":"JOURNAL OF HOMOTOPY AND RELATED STRUCTURES"},
      {"name":"TERAPIA PSICOLOGICA"},
      {"name":"JOURNAL OF AFRICAN CULTURAL STUDIES"},
      {"name":"INDIAN JOURNAL OF CHEMICAL TECHNOLOGY"},
      {"name":"JOURNAL OF EMPLOYMENT COUNSELING"},
      {"name":"JAPANESE JOURNAL OF POLITICAL SCIENCE"},
      {"name":"JOURNAL OF SOCIAL WORK PRACTICE"},
      {"name":"NATIONAL TAX JOURNAL"},
      {"name":"RUSSIAN JOURNAL OF NEMATOLOGY"},
      {"name":"FRUITS"},
      {"name":"AUSTRIAN JOURNAL OF FOREST SCIENCE"},
      {"name":"REVIEW OF ECONOMIC DESIGN"},
      {"name":"ANALES DEL JARDIN BOTANICO DE MADRID"},
      {"name":"JOURNAL OF CERAMIC PROCESSING RESEARCH"},
      {"name":"JOURNAL OF AQUATIC PLANT MANAGEMENT"},
      {"name":"FLEISCHWIRTSCHAFT"},
      {"name":"SCANDINAVIAN JOURNAL OF LABORATORY ANIMAL SCIENCE"},
      {"name":"RENDICONTI DEL SEMINARIO MATEMATICO DELLA UNIVERSITA DI PADOVA"},
      {"name":"HOKKAIDO MATHEMATICAL JOURNAL"},
      {"name":"CIENCIA E INVESTIGACION AGRARIA"},
      {"name":"CESKOSLOVENSKA PSYCHOLOGIE"},
      {"name":"DYNAMIC SYSTEMS AND APPLICATIONS"},
      {"name":"ARCHIVE FOR MATHEMATICAL LOGIC"},
      {"name":"LANGAGES"},
      {"name":"BRITAIN AND THE WORLD"},
      {"name":"UNIVERSITY OF PENNSYLVANIA JOURNAL OF INTERNATIONAL LAW"},
      {"name":"ORIENTAL INSECTS"},
      {"name":"INDIAN JOURNAL OF ANIMAL RESEARCH"},
      {"name":"VIROLOGIE"},
      {"name":"MILITARY OPERATIONS RESEARCH"},
      {"name":"JOURNAL OF SOUTH ASIAN DEVELOPMENT"},
      {"name":"MATERIALS PERFORMANCE"},
      {"name":"HISTORY OF PSYCHIATRY"},
      {"name":"ASIA PACIFIC JOURNAL OF SOCIAL WORK AND DEVELOPMENT"},
      {"name":"TEKSTIL VE KONFEKSIYON"},
      {"name":"UKRAINIAN MATHEMATICAL JOURNAL"},
      {"name":"ZOOLOGY IN THE MIDDLE EAST"},
      {"name":"ETHNOHISTORY"},
      {"name":"AYER"},
      {"name":"BIOSCIENCE JOURNAL"},
      {"name":"REVIEW OF INDUSTRIAL ORGANIZATION"},
      {"name":"NATURAL PRODUCT COMMUNICATIONS"},
      {"name":"CULTURAL CRITIQUE"},
      {"name":"CARYOLOGIA"},
      {"name":"APPLICATIONS OF MATHEMATICS"},
      {"name":"JOURNAL OF POLICY HISTORY"},
      {"name":"ANNALES DE BIOLOGIE CLINIQUE"},
      {"name":"HUMAN HEREDITY"},
      {"name":"EUROPEAN JOURNAL OF THE HISTORY OF ECONOMIC THOUGHT"},
      {"name":"ESTUDIOS FILOLOGICOS"},
      {"name":"PRAGMATICS"},
      {"name":"JOURNAL OF BIOPHARMACEUTICAL STATISTICS"},
      {"name":"BULLETIN OF THE MENNINGER CLINIC"},
      {"name":"POLITICKA EKONOMIE"},
      {"name":"MOVIMENTO"},
      {"name":"GAYANA"},
      {"name":"KINEMATICS AND PHYSICS OF CELESTIAL BODIES"},
      {"name":"GACETA MEDICA DE MEXICO"},
      {"name":"TRAVAIL GENRE ET SOCIETES"},
      {"name":"ARCHIVES DES MALADIES PROFESSIONNELLES ET DE L ENVIRONNEMENT"},
      {"name":"BERICHTE UBER LANDWIRTSCHAFT"},
      {"name":"UNIVERSITY OF CINCINNATI LAW REVIEW"},
      {"name":"TRANSYLVANIAN REVIEW OF ADMINISTRATIVE SCIENCES"},
      {"name":"REVISTA MEXICANA DE FISICA"},
      {"name":"CONTRIBUTIONS OF THE ASTRONOMICAL OBSERVATORY SKALNATE PLESO"},
      {"name":"PSYCHIATRIC ANNALS"},
      {"name":"THEORETICAL BIOLOGY FORUM"},
      {"name":"CIENCIAS MARINAS"},
      {"name":"HYDROLOGIE UND WASSERBEWIRTSCHAFTUNG"},
      {"name":"SCHWEIZER ARCHIV FUR TIERHEILKUNDE"},
      {"name":"INTERNATIONAL JOURNAL OF SPEECH LANGUAGE AND THE LAW"},
      {"name":"COMPTABILITE CONTROLE AUDIT"},
      {"name":"JOURNAL OF PLANT REGISTRATIONS"},
      {"name":"ASTROPHYSICS"},
      {"name":"HISTORICAL RECORDS OF AUSTRALIAN SCIENCE"},
      {"name":"MODERN ITALY"},
      {"name":"SARCOIDOSIS VASCULITIS AND DIFFUSE LUNG DISEASES"},
      {"name":"EARLY SCIENCE AND MEDICINE"},
      {"name":"JOURNAL OF URBAN HISTORY"},
      {"name":"CUSTOS E AGRONEGOCIO ON LINE"},
      {"name":"INTERCIENCIA"},
      {"name":"JOURNAL OF THE KOREAN PHYSICAL SOCIETY"},
      {"name":"ELECTRONIC RESEARCH ARCHIVE"},
      {"name":"REVISTA DE LA UNION MATEMATICA ARGENTINA"},
      {"name":"JOURNAL OF FRENCH LANGUAGE STUDIES"},
      {"name":"AMERICAN FERN JOURNAL"},
      {"name":"LITHUANIAN MATHEMATICAL JOURNAL"},
      {"name":"THAI JOURNAL OF VETERINARY MEDICINE"},
      {"name":"SOCIAL RESEARCH"},
      {"name":"INTERNATIONAL JOURNAL OF GROUP PSYCHOTHERAPY"},
      {"name":"GRAPHS AND COMBINATORICS"},
      {"name":"JOURNAL OF RUBBER RESEARCH"},
      {"name":"ENGLISH IN EDUCATION"},
      {"name":"KLEINTIERPRAXIS"},
      {"name":"EUROPEAN JOURNAL OF PSYCHIATRY"},
      {"name":"EXPERIMENTAL MATHEMATICS"},
      {"name":"DRUGS OF THE FUTURE"},
      {"name":"REVISTA MEXICANA DE PSICOLOGIA"},
      {"name":"WORLD REVIEW OF NUTRITION AND DIETETICS"},
      {"name":"JOURNAL OF LATIN AMERICAN CULTURAL STUDIES"},
      {"name":"LINGUA"},
      {"name":"SERBIAN ASTRONOMICAL JOURNAL"},
      {"name":"JOURNAL OF THE GILDED AGE AND PROGRESSIVE ERA"},
      {"name":"TROPICAL JOURNAL OF PHARMACEUTICAL RESEARCH"},
      {"name":"MOSCOW MATHEMATICAL JOURNAL"},
      {"name":"JOURNAL OF ENTOMOLOGICAL SCIENCE"},
      {"name":"HUMAN ECOLOGY REVIEW"},
      {"name":"SEXUAL AND REPRODUCTIVE HEALTH MATTERS"},
      {"name":"MATHEMATICAL SOCIAL SCIENCES"},
      {"name":"VETERINARNI MEDICINA"},
      {"name":"DIAGNOSTICA"},
      {"name":"INTELLIGENT DATA ANALYSIS"},
      {"name":"ELECTRICAL ENGINEERING IN JAPAN"},
      {"name":"AFRICAN JOURNALISM STUDIES"},
      {"name":"ANTHROPOS"},
      {"name":"JOURNAL OF ASIAN AND AFRICAN STUDIES"},
      {"name":"BIOLOGY BULLETIN"},
      {"name":"ACTA POLONIAE PHARMACEUTICA"},
      {"name":"POLISH JOURNAL OF VETERINARY SCIENCES"},
      {"name":"JOURNAL OF COLD WAR STUDIES"},
      {"name":"IEICE TRANSACTIONS ON ELECTRONICS"},
      {"name":"ACTA MEDICA PORTUGUESA"},
      {"name":"JOURNAL OF THE PROFESSIONAL ASSOCIATION FOR CACTUS DEVELOPMENT"},
      {"name":"ELECTRONIC JOURNAL OF COMBINATORICS"},
      {"name":"OSTEUROPA"},
      {"name":"REVISTA MEDICA DE CHILE"},
      {"name":"ACTA GEOTECHNICA SLOVENICA"},
      {"name":"PHOTOBIOMODULATION PHOTOMEDICINE AND LASER SURGERY"},
      {"name":"BRAZILIAN JOURNAL OF PSYCHIATRY"},
      {"name":"FEMINISTISCHE STUDIEN"},
      {"name":"ACTA MATHEMATICA HUNGARICA"},
      {"name":"ANNALES DE LA SOCIETE ENTOMOLOGIQUE DE FRANCE"},
      {"name":"METALLURGIST"},
      {"name":"DIALECTOLOGIA ET GEOLINGUISTICA"},
      {"name":"PACIFIC ECONOMIC REVIEW"},
      {"name":"BALKAN JOURNAL OF MEDICAL GENETICS"},
      {"name":"HISTORIA AGRARIA"},
      {"name":"BOLLETTINO DI STORIA DELLE SCIENZE MATEMATICHE"},
      {"name":"MYCOTAXON"},
      {"name":"JOURNAL OF KOREA TRADE"},
      {"name":"HORTTECHNOLOGY"},
      {"name":"NORDIC JOURNAL OF LINGUISTICS"},
      {"name":"NEUROCIRUGIA"},
      {"name":"PRAEHISTORISCHE ZEITSCHRIFT"},
      {"name":"JOURNAL OF WEB ENGINEERING"},
      {"name":"JOURNAL OF CAMEL PRACTICE AND RESEARCH"},
      {"name":"INTERPRETING"},
      {"name":"LANDBAUFORSCHUNG"},
      {"name":"ANTHROPOZOOLOGICA"},
      {"name":"INDIAN JOURNAL OF PHARMACEUTICAL EDUCATION AND RESEARCH"},
      {"name":"KYUSHU JOURNAL OF MATHEMATICS"},
      {"name":"TAIWANESE JOURNAL OF MATHEMATICS"},
      {"name":"DISSENT"},
      {"name":"BERLINER JOURNAL FUR SOZIOLOGIE"},
      {"name":"STUDIES IN AMERICAN POLITICAL DEVELOPMENT"},
      {"name":"COMPUTATIONAL MATHEMATICS AND MATHEMATICAL PHYSICS"},
      {"name":"MARINE TECHNOLOGY SOCIETY JOURNAL"},
      {"name":"PROCEEDINGS OF THE YORKSHIRE GEOLOGICAL SOCIETY"},
      {"name":"INDEPENDENT REVIEW"},
      {"name":"KYBERNETIKA"},
      {"name":"JOURNAL OF VICTORIAN CULTURE"},
      {"name":"TRAVAIL HUMAIN"},
      {"name":"JOURNAL OF COMMUNITY HEALTH NURSING"},
      {"name":"JOURNAL OF MEDICAL IMAGING AND HEALTH INFORMATICS"},
      {"name":"GLASS AND CERAMICS"},
      {"name":"INFORMACIOS TARSADALOM"},
      {"name":"JOURNAL OF CAVE AND KARST STUDIES"},
      {"name":"MADERA Y BOSQUES"},
      {"name":"TOPICS IN CLINICAL NUTRITION"},
      {"name":"ROMANIAN JOURNAL OF LEGAL MEDICINE"},
      {"name":"AUSTRIAN HISTORY YEARBOOK"},
      {"name":"CIVIL ENGINEERING"},
      {"name":"BOTANICA SERBICA"},
      {"name":"B E JOURNAL OF MACROECONOMICS"},
      {"name":"NATIONAL MEDICAL JOURNAL OF INDIA"},
      {"name":"NEMATROPICA"},
      {"name":"REVISTA BRASILEIRA DE MEDICINA DO ESPORTE"},
      {"name":"NEURAL NETWORK WORLD"},
      {"name":"SIGNA VITAE"},
      {"name":"JOURNAL OF THE CHINESE INSTITUTE OF ENGINEERS"},
      {"name":"JOURNAL OF MATHEMATICS AND MUSIC"},
      {"name":"CATHOLIC UNIVERSITY LAW REVIEW"},
      {"name":"JOURNAL OF THE AMERICAN PODIATRIC MEDICAL ASSOCIATION"},
      {"name":"INSTRUMENTS AND EXPERIMENTAL TECHNIQUES"},
      {"name":"CLINICA Y SALUD"},
      {"name":"SOUTHWESTERN ENTOMOLOGIST"},
      {"name":"AMERICAN MATHEMATICAL MONTHLY"},
      {"name":"JOURNAL OF MENS HEALTH"},
      {"name":"JOURNAL OF LEGAL MEDICINE"},
      {"name":"SOCIAL WORK IN PUBLIC HEALTH"},
      {"name":"ENTOMOLOGICAL NEWS"},
      {"name":"EUROPEAN HISTORY QUARTERLY"},
      {"name":"CULTURE AND ORGANIZATION"},
      {"name":"PESQUISA VETERINARIA BRASILEIRA"},
      {"name":"JOURNAL OF MATERIAL CULTURE"},
      {"name":"ACTA PHYSICA SLOVACA"},
      {"name":"JOURNAL OF THE HISTORY OF THE BEHAVIORAL SCIENCES"},
      {"name":"ESTUDIOS DE ECONOMIA"},
      {"name":"ZUCHTUNGSKUNDE"},
      {"name":"PROGRESS IN NUTRITION"},
      {"name":"CIENCIA FLORESTAL"},
      {"name":"ANIMAL NUTRITION AND FEED TECHNOLOGY"},
      {"name":"JOURNAL OF MATERIALS EDUCATION"},
      {"name":"REVISTA BRASILEIRA DE POLITICA INTERNACIONAL"},
      {"name":"STRENGTH OF MATERIALS"},
      {"name":"AMERICAN SPEECH"},
      {"name":"MALAYSIAN JOURNAL OF PATHOLOGY"},
      {"name":"INTERNASJONAL POLITIKK"},
      {"name":"PSYCHOLOGICA BELGICA"},
      {"name":"JOURNAL OF LEGAL STUDIES"},
      {"name":"SPECTROSCOPY AND SPECTRAL ANALYSIS"},
      {"name":"ZEITSCHRIFT FUR PADAGOGIK"},
      {"name":"NORTHERN HISTORY"},
      {"name":"COMMUNICATIONS IN ALGEBRA"},
      {"name":"PHYTOPROTECTION"},
      {"name":"PERFILES LATINOAMERICANOS"},
      {"name":"JOURNAL OF OCEANOLOGY AND LIMNOLOGY"},
      {"name":"TRANSLATOR"},
      {"name":"HERALD OF THE RUSSIAN ACADEMY OF SCIENCES"},
      {"name":"CONTINUITY AND CHANGE"},
      {"name":"GLASGOW MATHEMATICAL JOURNAL"},
      {"name":"TURKISH JOURNAL OF PEDIATRICS"},
      {"name":"MANUSCRIPTA MATHEMATICA"},
      {"name":"ANTHROPOLOGIE"},
      {"name":"INTERNATIONAL ARAB JOURNAL OF INFORMATION TECHNOLOGY"},
      {"name":"PHYSIKALISCHE MEDIZIN REHABILITATIONSMEDIZIN KURORTMEDIZIN"},
      {"name":"TROPICAL DOCTOR"},
      {"name":"JOURNAL OF FUSION ENERGY"},
      {"name":"FUNCTIONS OF LANGUAGE"},
      {"name":"INDIAN JOURNAL OF ENGINEERING AND MATERIALS SCIENCES"},
      {"name":"SOIL MECHANICS AND FOUNDATION ENGINEERING"},
      {"name":"REVISTA COLOMBIANA DE ENTOMOLOGIA"},
      {"name":"EMERGING MATERIALS RESEARCH"},
      {"name":"DOKLADY PHYSICAL CHEMISTRY"},
      {"name":"CESKA A SLOVENSKA NEUROLOGIE A NEUROCHIRURGIE"},
      {"name":"FUNDAMENTA MATHEMATICAE"},
      {"name":"PORTUGALIAE MATHEMATICA"},
      {"name":"HARVARD JOURNAL OF LAW AND PUBLIC POLICY"},
      {"name":"CURRENT TOPICS IN NUTRACEUTICAL RESEARCH"},
      {"name":"ASIAN BIOMEDICINE"},
      {"name":"JOURNAL OF THE JAPAN INSTITUTE OF METALS AND MATERIALS"},
      {"name":"COLLOQUIUM MATHEMATICUM"},
      {"name":"REVISTA ESPANOLA DE LINGUISTICA APLICADA"},
      {"name":"SINTAGMA"},
      {"name":"JOURNAL OF AMERICAN HISTORY"},
      {"name":"SAMPE JOURNAL"},
      {"name":"BRAZILIAN ARCHIVES OF BIOLOGY AND TECHNOLOGY"},
      {"name":"LEGAL STUDIES"},
      {"name":"JOURNAL OF AFRICAN LAW"},
      {"name":"ENGENHARIA AGRICOLA"},
      {"name":"AUSTRALIAN FEMINIST STUDIES"},
      {"name":"SOCIAL INFLUENCE"},
      {"name":"PROGRESS IN REACTION KINETICS AND MECHANISM"},
      {"name":"GERMAN HISTORY"},
      {"name":"JOURNAL OF IMAGING SCIENCE AND TECHNOLOGY"},
      {"name":"ANNALI DI BOTANICA"},
      {"name":"CIENCIA RURAL"},
      {"name":"JOURNAL OF COMPUTER LANGUAGES"},
      {"name":"GESTION Y POLITICA PUBLICA"},
      {"name":"JOURNAL OF MAGNETICS"},
      {"name":"INDIAN JOURNAL OF HETEROCYCLIC CHEMISTRY"},
      {"name":"GRUPPENPSYCHOTHERAPIE UND GRUPPENDYNAMIK"},
      {"name":"THERMOPHYSICS AND AEROMECHANICS"},
      {"name":"IRANIAN STUDIES"},
      {"name":"ARCHIVES DE PEDIATRIE"},
      {"name":"CENTRAL ASIAN SURVEY"},
      {"name":"BULLETIN OF THE AUSTRALIAN MATHEMATICAL SOCIETY"},
      {"name":"ATOMIC ENERGY"},
      {"name":"OPEN HOUSE INTERNATIONAL"},
      {"name":"PHYLLOMEDUSA"},
      {"name":"JOURNAL OF LEGAL EDUCATION"},
      {"name":"INTERNATIONAL JOURNAL OF LEXICOGRAPHY"},
      {"name":"STUDIES IN NONLINEAR DYNAMICS AND ECONOMETRICS"},
      {"name":"WORLD ENGLISHES"},
      {"name":"GORTERIA"},
      {"name":"MAUSAM"},
      {"name":"NOTRE DAME JOURNAL OF FORMAL LOGIC"},
      {"name":"JOURNAL OF LEISURE RESEARCH"},
      {"name":"AUSTRIAN JOURNAL OF POLITICAL SCIENCE"},
      {"name":"ASIAN JOURNAL OF WOMENS STUDIES"},
      {"name":"ENTOMOLOGICA FENNICA"},
      {"name":"ZEITGESCHICHTE"},
      {"name":"MAGALLANIA"},
      {"name":"ZEITSCHRIFT FUR SPORTPSYCHOLOGIE"},
      {"name":"AUSTRALIAN JOURNAL OF ZOOLOGY"},
      {"name":"INTERNIST"},
      {"name":"JOURNAL OF GEOMETRIC MECHANICS"},
      {"name":"DENVER LAW REVIEW"},
      {"name":"CONTEMPORARY PACIFIC"},
      {"name":"ASIAN PERSPECTIVE"},
      {"name":"BRAZILIAN JOURNAL OF PROBABILITY AND STATISTICS"},
      {"name":"SEMINARS IN ROENTGENOLOGY"},
      {"name":"FERROELECTRICS"},
      {"name":"MONATSSCHRIFT FUR KRIMINOLOGIE UND STRAFRECHTSREFORM"},
      {"name":"KERNTECHNIK"},
      {"name":"METALLURGIA ITALIANA"},
      {"name":"DYNAMIS"},
      {"name":"ASIA PACIFIC JOURNAL OF ANTHROPOLOGY"},
      {"name":"SLOVO A SLOVESNOST"},
      {"name":"JOURNAL OF MATHEMATICAL SOCIOLOGY"},
      {"name":"SOLID FUEL CHEMISTRY"},
      {"name":"INTELLIGENCE AND NATIONAL SECURITY"},
      {"name":"MAGNETOHYDRODYNAMICS"},
      {"name":"DOKLADY MATHEMATICS"},
      {"name":"REVISTA INTERNACIONAL DE ANDROLOGIA"},
      {"name":"ANNALS OF SCIENCE"},
      {"name":"REVISTA DE LA CONSTRUCCION"},
      {"name":"BULLETIN OF THE KOREAN CHEMICAL SOCIETY"},
      {"name":"LIBRARY JOURNAL"},
      {"name":"KOREAN JOURNAL OF DEFENSE ANALYSIS"},
      {"name":"EUROPEAN JOURNAL OF GYNAECOLOGICAL ONCOLOGY"},
      {"name":"STUDIES IN LANGUAGE"},
      {"name":"JOURNAL OF OPTOELECTRONICS AND ADVANCED MATERIALS"},
      {"name":"RHODORA"},
      {"name":"CURRENT PERSPECTIVES IN SOCIAL THEORY"},
      {"name":"STUDIES IN CONSERVATION"},
      {"name":"ARS MATHEMATICA CONTEMPORANEA"},
      {"name":"ENDEAVOUR"},
      {"name":"PATTERNS OF PREJUDICE"},
      {"name":"BILIG"},
      {"name":"DIABETES STOFFWECHSEL UND HERZ"},
      {"name":"FOOD TECHNOLOGY"},
      {"name":"ORNITOLOGIA NEOTROPICAL"},
      {"name":"JOURNAL OF OPERATIONAL RISK"},
      {"name":"GRADEVINAR"},
      {"name":"BERLINER UND MUNCHENER TIERARZTLICHE WOCHENSCHRIFT"},
      {"name":"INTERNATIONAL REVIEW OF SOCIAL HISTORY"},
      {"name":"STUDIES IN EAST EUROPEAN THOUGHT"},
      {"name":"FORUM DER PSYCHOANALYSE"},
      {"name":"REVISTA CHAPINGO SERIE CIENCIAS FORESTALES Y DEL AMBIENTE"},
      {"name":"TOPOLOGY AND ITS APPLICATIONS"},
      {"name":"KEDI JOURNAL OF EDUCATIONAL POLICY"},
      {"name":"AATCC JOURNAL OF RESEARCH"},
      {"name":"INTERNATIONAL JOURNAL OF MATERIALS RESEARCH"},
      {"name":"TECNOLOGIA Y CIENCIAS DEL AGUA"},
      {"name":"LEXIKOS"},
      {"name":"JOURNAL OF THE SOCIETY OF LEATHER TECHNOLOGISTS AND CHEMISTS"},
      {"name":"EDUCATIONAL LEADERSHIP"},
      {"name":"REVISTA DE HISTORIA ECONOMICA"},
      {"name":"CULTURAL STUDIES REVIEW"},
      {"name":"CYTOLOGY AND GENETICS"},
      {"name":"REFRACTORIES AND INDUSTRIAL CERAMICS"},
      {"name":"JOURNAL OF WATER CHEMISTRY AND TECHNOLOGY"},
      {"name":"ANNALES BOTANICI FENNICI"},
      {"name":"TIERAERZTLICHE UMSCHAU"},
      {"name":"THEOLOGY AND SCIENCE"},
      {"name":"INTERNATIONAL JOURNAL OF MATHEMATICS"},
      {"name":"GASTROENTEROLOGY NURSING"},
      {"name":"CENTRAL EUROPEAN JOURNAL OF PUBLIC HEALTH"},
      {"name":"JOURNAL OF HISTOTECHNOLOGY"},
      {"name":"FOUNDATIONS OF SCIENCE"},
      {"name":"LANGUAGE AND LINGUISTICS"},
      {"name":"JOURNAL OF AFRICAN MEDIA STUDIES"},
      {"name":"ALGEBRA UNIVERSALIS"},
      {"name":"TRANSACTIONS OF FAMENA"},
      {"name":"ASIA PACIFIC LAW REVIEW"},
      {"name":"AUSTRIAN JOURNAL OF EARTH SCIENCES"},
      {"name":"VOJNOSANITETSKI PREGLED"},
      {"name":"HASELTONIA"},
      {"name":"PSIKHOLOGICHESKII ZHURNAL"},
      {"name":"REVIJA ZA KRIMINALISTIKO IN KRIMINOLOGIJO"},
      {"name":"INVERTEBRATE NEUROSCIENCE"},
      {"name":"ACTA ARITHMETICA"},
      {"name":"JOURNAL OF THE POLYNESIAN SOCIETY"},
      {"name":"JOURNAL OF COGNITIVE PSYCHOTHERAPY"},
      {"name":"KOREAN ECONOMIC REVIEW"},
      {"name":"PSYCHOANALYTIC DIALOGUES"},
      {"name":"PTERIDINES"},
      {"name":"JOURNAL OF APPLIED PROBABILITY"},
      {"name":"NOVON"},
      {"name":"KLINISCHE MONATSBLATTER FUR AUGENHEILKUNDE"},
      {"name":"JOURNAL OF THE ANATOMICAL SOCIETY OF INDIA"},
      {"name":"SPANISH IN CONTEXT"},
      {"name":"PROCEEDINGS OF THE ENTOMOLOGICAL SOCIETY OF WASHINGTON"},
      {"name":"WAR IN HISTORY"},
      {"name":"SCOTTISH MEDICAL JOURNAL"},
      {"name":"INTERNATIONAL JOURNAL OF ENVIRONMENT AND POLLUTION"},
      {"name":"NOISE CONTROL ENGINEERING JOURNAL"},
      {"name":"ETIKK I PRAKSIS"},
      {"name":"MIKROBIYOLOJI BULTENI"},
      {"name":"ACTA MEDICA OKAYAMA"},
      {"name":"NORTHWEST SCIENCE"},
      {"name":"KUWAIT MEDICAL JOURNAL"},
      {"name":"BULLETIN OF ECONOMIC RESEARCH"},
      {"name":"LEGUME RESEARCH"},
      {"name":"PFLEGE"},
      {"name":"ARQUIVO BRASILEIRO DE MEDICINA VETERINARIA E ZOOTECNIA"},
      {"name":"WILSON JOURNAL OF ORNITHOLOGY"},
      {"name":"NATURAL HISTORY"},
      {"name":"POLITY"},
      {"name":"BOSQUE"},
      {"name":"JOURNAL OF VISUAL CULTURE"},
      {"name":"OPERATIVE TECHNIQUES IN SPORTS MEDICINE"},
      {"name":"ISOKINETICS AND EXERCISE SCIENCE"},
      {"name":"CEMENT WAPNO BETON"},
      {"name":"KSII TRANSACTIONS ON INTERNET AND INFORMATION SYSTEMS"},
      {"name":"HONG KONG JOURNAL OF OCCUPATIONAL THERAPY"},
      {"name":"AQUATIC INSECTS"},
      {"name":"REVISTA DE NEFROLOGIA DIALISIS Y TRASPLANTE"},
      {"name":"NEUROPHYSIOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF ENGINEERING EDUCATION"},
      {"name":"LABOUR HISTORY"},
      {"name":"COMPTES RENDUS DE L ACADEMIE BULGARE DES SCIENCES"},
      {"name":"ETHICAL PERSPECTIVES"},
      {"name":"IRANIAN JOURNAL OF RADIOLOGY"},
      {"name":"PROBUS"},
      {"name":"TIJDSCHRIFT VOOR DIERGENEESKUNDE"},
      {"name":"SCOTTISH JOURNAL OF POLITICAL ECONOMY"},
      {"name":"GEOCHEMISTRY"},
      {"name":"WEST INDIAN MEDICAL JOURNAL"},
      {"name":"ZEITSCHRIFT FUR BIBLIOTHEKSWESEN UND BIBLIOGRAPHIE"},
      {"name":"ACTA ORTOPEDICA BRASILEIRA"},
      {"name":"NOTARZT"},
      {"name":"HEART SURGERY FORUM"},
      {"name":"ICHTHYOLOGICAL RESEARCH"},
      {"name":"JOURNAL OF COSMETIC SCIENCE"},
      {"name":"SEED SCIENCE AND TECHNOLOGY"},
      {"name":"ISRAEL AFFAIRS"},
      {"name":"WOCHENBLATT FUR PAPIERFABRIKATION"},
      {"name":"FIBER AND INTEGRATED OPTICS"},
      {"name":"ALGORITHMICA"},
      {"name":"HERPETOZOA"},
      {"name":"JOURNAL OF KNOT THEORY AND ITS RAMIFICATIONS"},
      {"name":"THALASSAS"},
      {"name":"KAGAKU KOGAKU RONBUNSHU"},
      {"name":"JOURNAL FRANCAIS D OPHTALMOLOGIE"},
      {"name":"ONOMAZEIN"},
      {"name":"NEPHROLOGY NURSING JOURNAL"},
      {"name":"JOURNAL OF ASIAN STUDIES"},
      {"name":"TROPICAL BIOMEDICINE"},
      {"name":"ANTHROPOLOGICAL QUARTERLY"},
      {"name":"JOURNAL OF VOLCANOLOGY AND SEISMOLOGY"},
      {"name":"ARTERY RESEARCH"},
      {"name":"AUSTRALIAN ABORIGINAL STUDIES"},
      {"name":"ANKARA UNIVERSITESI VETERINER FAKULTESI DERGISI"},
      {"name":"BIOLOGIA FUTURA"},
      {"name":"ACTES DE LA RECHERCHE EN SCIENCES SOCIALES"},
      {"name":"PSYCHOANALYTIC QUARTERLY"},
      {"name":"MAYDICA"},
      {"name":"FORTSCHRITTE DER NEUROLOGIE PSYCHIATRIE"},
      {"name":"ABHANDLUNGEN AUS DEM MATHEMATISCHEN SEMINAR DER UNIVERSITAT HAMBURG"},
      {"name":"ACTA MEDICA MEDITERRANEA"},
      {"name":"POLISH SOCIOLOGICAL REVIEW"},
      {"name":"JAPANESE ECONOMIC REVIEW"},
      {"name":"CALIFORNIA FISH AND GAME"},
      {"name":"BULLETIN DE LA SOCIETE LINNEENNE DE LYON"},
      {"name":"MATHEMATICAL REPORTS"},
      {"name":"WATERBIRDS"},
      {"name":"JOURNAL OF COMPARATIVE GERMANIC LINGUISTICS"},
      {"name":"METHODS IN MICROBIOLOGY"},
      {"name":"ASIAN WOMEN"},
      {"name":"INTERNATIONAL JOURNAL OF MARINE AND COASTAL LAW"},
      {"name":"ROMANIAN JOURNAL OF POLITICAL SCIENCE"},
      {"name":"ACCREDITATION AND QUALITY ASSURANCE"},
      {"name":"CONTEMPORARY PSYCHOANALYSIS"},
      {"name":"CHEMICAL ENGINEERING PROGRESS"},
      {"name":"GERIATRIE ET PSYCHOLOGIE NEUROPSYCHIATRIE DE VIEILLISSEMENT"},
      {"name":"CALDASIA"},
      {"name":"GLASNIK MATEMATICKI"},
      {"name":"INTERNATIONAL JOURNAL OF AMERICAN LINGUISTICS"},
      {"name":"INTERNATIONAL JOURNAL OF NUMBER THEORY"},
      {"name":"IHERINGIA SERIE BOTANICA"},
      {"name":"AGRICULTURAL HISTORY"},
      {"name":"PROCEEDINGS OF THE LINNEAN SOCIETY OF NEW SOUTH WALES"},
      {"name":"ASIAN ECONOMIC PAPERS"},
      {"name":"RADIOPHYSICS AND QUANTUM ELECTRONICS"},
      {"name":"JOURNAL OF PIDGIN AND CREOLE LANGUAGES"},
      {"name":"SCOTTISH HISTORICAL REVIEW"},
      {"name":"HERPETOLOGICAL JOURNAL"},
      {"name":"JOURNAL OF MODERN DYNAMICS"},
      {"name":"MATHEMATICAL STRUCTURES IN COMPUTER SCIENCE"},
      {"name":"MANUFACTURING ENGINEERING"},
      {"name":"REVUE D ECONOMIE POLITIQUE"},
      {"name":"TURKISH JOURNAL OF PHYSICAL MEDICINE AND REHABILITATION"},
      {"name":"CEPAL REVIEW"},
      {"name":"KODAI MATHEMATICAL JOURNAL"},
      {"name":"COMMUNICATIONS IN ANALYSIS AND GEOMETRY"},
      {"name":"ALLERGOLOGIE"},
      {"name":"POZNAN STUDIES IN CONTEMPORARY LINGUISTICS"},
      {"name":"AGROCIENCIA"},
      {"name":"CATTLE PRACTICE"},
      {"name":"CURRENT HISTORY"},
      {"name":"ANASTHESIOLOGIE INTENSIVMEDIZIN NOTFALLMEDIZIN SCHMERZTHERAPIE"},
      {"name":"COMPUTER ANIMATION AND VIRTUAL WORLDS"},
      {"name":"JOURNAL OF THE KOREAN STATISTICAL SOCIETY"},
      {"name":"AUSTRALASIAN ORTHODONTIC JOURNAL"},
      {"name":"JOURNAL OF PSYCHOLOGY IN AFRICA"},
      {"name":"PROPAGATION OF ORNAMENTAL PLANTS"},
      {"name":"KLINISCHE NEUROPHYSIOLOGIE"},
      {"name":"EDUCATION AS CHANGE"},
      {"name":"TRANSLATION AND INTERPRETING STUDIES"},
      {"name":"ENVIRONMENTAL ETHICS"},
      {"name":"AMERICAN JOURNAL OF FAMILY THERAPY"},
      {"name":"LJETOPIS SOCIJALNOG RADA"},
      {"name":"BOLETIN DE LA SOCIEDAD ARGENTINA DE BOTANICA"},
      {"name":"ALGEBRAS AND REPRESENTATION THEORY"},
      {"name":"JOURNAL OF THE AMERICAN POMOLOGICAL SOCIETY"},
      {"name":"GEOMETRIAE DEDICATA"},
      {"name":"ONKOLOGE"},
      {"name":"JOURNAL OF COMPUTATIONAL AND THEORETICAL TRANSPORT"},
      {"name":"JOURNAL OF HARD TISSUE BIOLOGY"},
      {"name":"ELECTROMAGNETICS"},
      {"name":"JOURNAL OF SUPERHARD MATERIALS"},
      {"name":"AKTUELLE RHEUMATOLOGIE"},
      {"name":"CENTRAL EUROPEAN HISTORY"},
      {"name":"MEDITERRANEAN HISTORICAL REVIEW"},
      {"name":"INTERNATIONAL JOURNAL OF APPLIED RESEARCH IN VETERINARY MEDICINE"},
      {"name":"JOURNAL OF SOCIAL HISTORY"},
      {"name":"ACTA BIOETHICA"},
      {"name":"MOLECULAR CRYSTALS AND LIQUID CRYSTALS"},
      {"name":"INLAND WATER BIOLOGY"},
      {"name":"JOURNAL OF FAMILY HISTORY"},
      {"name":"MARKOV PROCESSES AND RELATED FIELDS"},
      {"name":"IEEJ TRANSACTIONS ON ELECTRICAL AND ELECTRONIC ENGINEERING"},
      {"name":"INTERNATIONAL JOURNAL OF HUMAN GENETICS"},
      {"name":"RADIOPROTECTION"},
      {"name":"DIPLOMATIC HISTORY"},
      {"name":"MAIN GROUP METAL CHEMISTRY"},
      {"name":"EDUCATIONAL GERONTOLOGY"},
      {"name":"DIFFERENTIAL GEOMETRY AND ITS APPLICATIONS"},
      {"name":"LEPROSY REVIEW"},
      {"name":"JOURNAL OF BLACK STUDIES"},
      {"name":"KARDIOLOGIYA"},
      {"name":"SYLWAN"},
      {"name":"JOURNAL OF ALGEBRA AND ITS APPLICATIONS"},
      {"name":"NEXUS NETWORK JOURNAL"},
      {"name":"JOURNAL OF THE CHINESE SOCIETY OF MECHANICAL ENGINEERS"},
      {"name":"JOURNAL OF HISTORICAL SOCIOLOGY"},
      {"name":"EPIDEMIOLOGIE MIKROBIOLOGIE IMUNOLOGIE"},
      {"name":"ENTOMOLOGICA AMERICANA"},
      {"name":"KUWAIT JOURNAL OF SCIENCE"},
      {"name":"CORRESPONDANCES EN METABOLISMES HORMONES DIABETES ET NUTRITION"},
      {"name":"INTERNATIONAL JOURNAL OF MUSIC EDUCATION"},
      {"name":"QUIMICA NOVA"},
      {"name":"JOURNAL OF RISK"},
      {"name":"BIOAGRO"},
      {"name":"REVIEW OF CENTRAL AND EAST EUROPEAN LAW"},
      {"name":"EUROPEAN JOURNAL OF MIGRATION AND LAW"},
      {"name":"JOURNAL OF VETERINARY DENTISTRY"},
      {"name":"LONDON JOURNAL"},
      {"name":"BAUPHYSIK"},
      {"name":"ARGUMENTA OECONOMICA"},
      {"name":"MOSCOW UNIVERSITY PHYSICS BULLETIN"},
      {"name":"RADIOLOGE"},
      {"name":"SEMICONDUCTORS"},
      {"name":"MATHEMATICAL NOTES"},
      {"name":"RIVISTA ITALIANA DELLE SOSTANZE GRASSE"},
      {"name":"JOURNAL OF EVOLUTIONARY BIOCHEMISTRY AND PHYSIOLOGY"},
      {"name":"COMPUTER MUSIC JOURNAL"},
      {"name":"AKTUELLE UROLOGIE"},
      {"name":"INVESTIGACION ECONOMICA"},
      {"name":"JOURNAL OF EARLY MODERN HISTORY"},
      {"name":"WULFENIA"},
      {"name":"TROPICAL ECOLOGY"},
      {"name":"TRANSACTIONS OF THE AMERICAN ENTOMOLOGICAL SOCIETY"},
      {"name":"LARGE ANIMAL REVIEW"},
      {"name":"JOURNAL OF LOGIC LANGUAGE AND INFORMATION"},
      {"name":"NIPPON SUISAN GAKKAISHI"},
      {"name":"AUSTRALIAN ECONOMIC HISTORY REVIEW"},
      {"name":"DIABETOLOGE"},
      {"name":"KOREA OBSERVER"},
      {"name":"JOURNAL OF CYTOLOGY"},
      {"name":"ANALYSIS MATHEMATICA"},
      {"name":"HONG KONG LAW JOURNAL"},
      {"name":"INTERNATIONAL JOURNAL OF TROPICAL INSECT SCIENCE"},
      {"name":"ARS COMBINATORIA"},
      {"name":"EVOLUTION PSYCHIATRIQUE"},
      {"name":"JOURNAL OF GROUP THEORY"},
      {"name":"INDIAN JOURNAL OF PATHOLOGY AND MICROBIOLOGY"},
      {"name":"SOUTH AFRICAN JOURNAL OF SURGERY"},
      {"name":"ELECTRONIC COMMUNICATIONS IN PROBABILITY"},
      {"name":"JOURNAL OF THE HISTORY OF THE NEUROSCIENCES"},
      {"name":"JOURNAL OF ARCHITECTURAL AND PLANNING RESEARCH"},
      {"name":"ZEITSCHRIFT FUR GEBURTSHILFE UND NEONATOLOGIE"},
      {"name":"SOCIETY"},
      {"name":"BETRIEBSWIRTSCHAFTLICHE FORSCHUNG UND PRAXIS"},
      {"name":"JOURNAL OF CHEMICAL CRYSTALLOGRAPHY"},
      {"name":"EUROPEAN JOURNAL OF ENGLISH STUDIES"},
      {"name":"FRENCH HISTORY"},
      {"name":"ZEITSCHRIFT FUR SEXUALFORSCHUNG"},
      {"name":"ANALYSIS AND GEOMETRY IN METRIC SPACES"},
      {"name":"SUMARSKI LIST"},
      {"name":"JOURNAL OF PALESTINE STUDIES"},
      {"name":"JOURNAL OF ADVANCED MECHANICAL DESIGN SYSTEMS AND MANUFACTURING"},
      {"name":"ZEITSCHRIFT FUR SOZIOLOGIE DER ERZIEHUNG UND SOZIALISATION"},
      {"name":"REVUE DES MALADIES RESPIRATOIRES"},
      {"name":"FIBRE CHEMISTRY"},
      {"name":"INDIAN JOURNAL OF AGRICULTURAL SCIENCES"},
      {"name":"REVISTA DE BIOLOGIA MARINA Y OCEANOGRAFIA"},
      {"name":"AUSTRALIAN JOURNAL OF ADULT LEARNING"},
      {"name":"INDIAN JOURNAL OF GENDER STUDIES"},
      {"name":"PROBLEMS OF INFORMATION TRANSMISSION"},
      {"name":"MALIMBUS"},
      {"name":"CHEMISTRY OF NATURAL COMPOUNDS"},
      {"name":"HOMOLOGY HOMOTOPY AND APPLICATIONS"},
      {"name":"INTERNATIONAL JOURNAL OF FEMINIST APPROACHES TO BIOETHICS"},
      {"name":"ISRAEL JOURNAL OF PSYCHIATRY AND RELATED SCIENCES"},
      {"name":"ARCHIVOS ESPANOLES DE UROLOGIA"},
      {"name":"DEUTSCHE MEDIZINISCHE WOCHENSCHRIFT"},
      {"name":"JOURNAL OF AFRICAN LANGUAGES AND LINGUISTICS"},
      {"name":"IRISH EDUCATIONAL STUDIES"},
      {"name":"SCIENCE AND TECHNOLOGY OF ENERGETIC MATERIALS"},
      {"name":"MALAWI MEDICAL JOURNAL"},
      {"name":"HONG KONG JOURNAL OF PAEDIATRICS"},
      {"name":"VIOLENCE AND VICTIMS"},
      {"name":"REVISTA INTERNACIONAL DE CONTAMINACION AMBIENTAL"},
      {"name":"MEDICC REVIEW"},
      {"name":"ANNALI ITALIANI DI CHIRURGIA"},
      {"name":"SUCHTTHERAPIE"},
      {"name":"CONTRIBUTIONS TO DISCRETE MATHEMATICS"},
      {"name":"STOCHASTIC MODELS"},
      {"name":"JOURNAL OF EAST ASIAN LINGUISTICS"},
      {"name":"ELECTRONICS AND COMMUNICATIONS IN JAPAN"},
      {"name":"ENVIRONMENTAL MICROBIOME"},
      {"name":"JOURNAL OF ANTHROPOLOGICAL RESEARCH"},
      {"name":"SOUTH AFRICAN JOURNAL OF INDUSTRIAL ENGINEERING"},
      {"name":"BIOTECHNOLOGY LAW REPORT"},
      {"name":"ANDAMIOS"},
      {"name":"INTERNATIONAL JOURNAL OF FOUNDATIONS OF COMPUTER SCIENCE"},
      {"name":"ACTA PHYSICA POLONICA A"},
      {"name":"BRITISH JOURNAL OF HOSPITAL MEDICINE"},
      {"name":"CRYPTOGAMIE BRYOLOGIE"},
      {"name":"JOURNAL OF ENVIRONMENTAL SCIENCE AND MANAGEMENT"},
      {"name":"UNIVERSITY OF PITTSBURGH LAW REVIEW"},
      {"name":"JOURNAL OF THE ENTOMOLOGICAL RESEARCH SOCIETY"},
      {"name":"JOURNAL OF THE NATIONAL SCIENCE FOUNDATION OF SRI LANKA"},
      {"name":"TOPICS IN APPLIED PHYSICS"},
      {"name":"ENGLISH HISTORICAL REVIEW"},
      {"name":"SCIENTIA FORESTALIS"},
      {"name":"TOPICS IN GERIATRIC REHABILITATION"},
      {"name":"HIROSHIMA MATHEMATICAL JOURNAL"},
      {"name":"ASIAN JOURNAL OF SOCIAL SCIENCE"},
      {"name":"PHILIPPINE JOURNAL OF CROP SCIENCE"},
      {"name":"JOURNAL OF MODERN ITALIAN STUDIES"},
      {"name":"AUTOMATION AND REMOTE CONTROL"},
      {"name":"CULTURAL STUDIES OF SCIENCE EDUCATION"},
      {"name":"WILDFOWL"},
      {"name":"PRAGMATICS AND SOCIETY"},
      {"name":"JOURNAL OF LABORATORY MEDICINE"},
      {"name":"JOURNAL OF LATIN AMERICAN AND CARIBBEAN ANTHROPOLOGY"},
      {"name":"DRVNA INDUSTRIJA"},
      {"name":"BRITISH JOURNAL OF MUSIC EDUCATION"},
      {"name":"ASHRAE JOURNAL"},
      {"name":"ICGA JOURNAL"},
      {"name":"JOURNAL OF BRITISH STUDIES"},
      {"name":"INTERNATIONAL JOURNAL OF AERONAUTICAL AND SPACE SCIENCES"},
      {"name":"ESTUDIOS DE PSICOLOGIA"},
      {"name":"PANOECONOMICUS"},
      {"name":"JOURNAL OF AUSTRALIAN STUDIES"},
      {"name":"JUNDISHAPUR JOURNAL OF MICROBIOLOGY"},
      {"name":"JOURNAL OF MODERN EUROPEAN HISTORY"},
      {"name":"GENDER AND LANGUAGE"},
      {"name":"FERROELECTRICS LETTERS SECTION"},
      {"name":"HIMALAYAN GEOLOGY"},
      {"name":"RECORDS OF THE AUSTRALIAN MUSEUM"},
      {"name":"JOURNAL OF BIOMATERIALS AND TISSUE ENGINEERING"},
      {"name":"PRZEMYSL CHEMICZNY"},
      {"name":"INTERNATIONAL JOURNAL OF RADIATION RESEARCH"},
      {"name":"PRESIDENTIAL STUDIES QUARTERLY"},
      {"name":"MEDITERRANEAN BOTANY"},
      {"name":"JOURNAL OF MINING SCIENCE"},
      {"name":"LAW LIBRARY JOURNAL"},
      {"name":"ZOOSYSTEMA"},
      {"name":"ROCKY MOUNTAIN JOURNAL OF MATHEMATICS"},
      {"name":"CRYPTOLOGIA"},
      {"name":"REVISTA DE LA SOCIEDAD ENTOMOLOGICA ARGENTINA"},
      {"name":"INVESTIGACION BIBLIOTECOLOGICA"},
      {"name":"ADANSONIA"},
      {"name":"ANTHROPOLOGICAL FORUM"},
      {"name":"MOLECULAR GENETICS MICROBIOLOGY AND VIROLOGY"},
      {"name":"JOURNAL OF THE INDIAN CHEMICAL SOCIETY"},
      {"name":"EPE JOURNAL"},
      {"name":"COMPUTER SYSTEMS SCIENCE AND ENGINEERING"},
      {"name":"INTERSECCIONES EN ANTROPOLOGIA"},
      {"name":"VETERINARIA MEXICO"},
      {"name":"INTERNATIONAL JOURNAL OF DIABETES IN DEVELOPING COUNTRIES"},
      {"name":"MECHANICAL ENGINEERING"},
      {"name":"MECHANICS OF SOLIDS"},
      {"name":"REVISTA DE NEUROLOGIA"},
      {"name":"AUSTRAL JOURNAL OF VETERINARY SCIENCES"},
      {"name":"REVISTA DE ECONOMIA MUNDIAL"},
      {"name":"POLISH JOURNAL OF ECOLOGY"},
      {"name":"ANALYTICAL AND QUANTITATIVE CYTOPATHOLOGY AND HISTOPATHOLOGY"},
      {"name":"REVISTA DE ESTUDIOS POLITICOS"},
      {"name":"JOURNAL OF CELLULAR AUTOMATA"},
      {"name":"REVIJA ZA SOCIJALNU POLITIKU"},
      {"name":"IMPLANTOLOGIE"},
      {"name":"JOURNAL OF AVIAN MEDICINE AND SURGERY"},
      {"name":"COMPARATIVE PARASITOLOGY"},
      {"name":"HISTORIA MATHEMATICA"},
      {"name":"REVIEW OF RADICAL POLITICAL ECONOMICS"},
      {"name":"BANGLADESH JOURNAL OF PLANT TAXONOMY"},
      {"name":"ACTA ENTOMOLOGICA MUSEI NATIONALIS PRAGAE"},
      {"name":"CHINESE ANNALS OF MATHEMATICS SERIES B"},
      {"name":"JOURNAL DE THEORIE DES NOMBRES DE BORDEAUX"},
      {"name":"HORTICULTURA BRASILEIRA"},
      {"name":"INDOGERMANISCHE FORSCHUNGEN"},
      {"name":"PACIFIC FOCUS"},
      {"name":"RUSSIAN PHYSICS JOURNAL"},
      {"name":"SPIXIANA"},
      {"name":"MONATSSCHRIFT KINDERHEILKUNDE"},
      {"name":"PSYCHOLOGIE IN ERZIEHUNG UND UNTERRICHT"},
      {"name":"PAKISTAN JOURNAL OF AGRICULTURAL SCIENCES"},
      {"name":"QUALITATIVE SOCIOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF NANOTECHNOLOGY"},
      {"name":"RARE METAL MATERIALS AND ENGINEERING"},
      {"name":"PROGRES EN UROLOGIE"},
      {"name":"PSYCHOLOGIE FRANCAISE"},
      {"name":"CZECHOSLOVAK MATHEMATICAL JOURNAL"},
      {"name":"VOPROSY PSIKHOLOGII"},
      {"name":"AUSTRALASIAN JOURNAL OF EARLY CHILDHOOD"},
      {"name":"MEDIA INTERNATIONAL AUSTRALIA"},
      {"name":"HEROIN ADDICTION AND RELATED CLINICAL PROBLEMS"},
      {"name":"OPERATORS AND MATRICES"},
      {"name":"WATER RESOURCES"},
      {"name":"TURK PSIKOLOJI DERGISI"},
      {"name":"GEODETSKI VESTNIK"},
      {"name":"ACTA VETERINARIA BRNO"},
      {"name":"JOURNAL OF APPLIED MECHANICS AND TECHNICAL PHYSICS"},
      {"name":"AUSTRALIAN JOURNAL OF ANTHROPOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF CLOTHING SCIENCE AND TECHNOLOGY"},
      {"name":"PHYSICS OF PARTICLES AND NUCLEI"},
      {"name":"LAEKNABLADID"},
      {"name":"ALGEBRA COLLOQUIUM"},
      {"name":"PHYSICS WORLD"},
      {"name":"TERAPEVTICHESKII ARKHIV"},
      {"name":"NEW ZEALAND ENTOMOLOGIST"},
      {"name":"AMERICAN INDIAN AND ALASKA NATIVE MENTAL HEALTH RESEARCH"},
      {"name":"PALEONTOLOGICAL JOURNAL"},
      {"name":"JOKULL"},
      {"name":"HIPPOKRATIA"},
      {"name":"INTERNATIONAL JOURNAL OF POWDER METALLURGY"},
      {"name":"MODERN ASIAN STUDIES"},
      {"name":"SCIENCEASIA"},
      {"name":"FOOD SCIENCE AND TECHNOLOGY RESEARCH"},
      {"name":"LATIN AMERICAN APPLIED RESEARCH"},
      {"name":"ETHIOPIAN JOURNAL OF HEALTH DEVELOPMENT"},
      {"name":"DOKLADY CHEMISTRY"},
      {"name":"INFORMES DE LA CONSTRUCCION"},
      {"name":"ASIAN MYRMECOLOGY"},
      {"name":"PORTA LINGUARUM"},
      {"name":"TELOS"},
      {"name":"JUVENILE AND FAMILY COURT JOURNAL"},
      {"name":"FOOD SCIENCE OF ANIMAL RESOURCES"},
      {"name":"POLITISCHE VIERTELJAHRESSCHRIFT"},
      {"name":"JOURNAL FOR THE HISTORY OF ASTRONOMY"},
      {"name":"STUDIES IN PHILOSOPHY AND EDUCATION"},
      {"name":"PLANNING PERSPECTIVES"},
      {"name":"ZEITSCHRIFT DER DEUTSCHEN GESELLSCHAFT FUR GEOWISSENSCHAFTEN"},
      {"name":"VETERINARSKI ARHIV"},
      {"name":"HISTORIOGRAPHIA LINGUISTICA"},
      {"name":"IRANIAN JOURNAL OF PEDIATRICS"},
      {"name":"BRITTONIA"},
      {"name":"CRUSTACEANA"},
      {"name":"INTERNATIONAL JOURNAL OF THE HISTORY OF SPORT"},
      {"name":"JOURNAL OF ANIMAL AND PLANT SCIENCES"},
      {"name":"NEUROCHEMICAL JOURNAL"},
      {"name":"RUSSIAN JOURNAL OF DEVELOPMENTAL BIOLOGY"},
      {"name":"REVISTA DE PSICOLOGIA SOCIAL"},
      {"name":"HERZOGIA"},
      {"name":"BUFFALO LAW REVIEW"},
      {"name":"TAIWANIA"},
      {"name":"TRACE ELEMENTS AND ELECTROLYTES"},
      {"name":"NEUROLOGICAL SCIENCES AND NEUROPHYSIOLOGY"},
      {"name":"WIENER TIERARZTLICHE MONATSSCHRIFT"},
      {"name":"MATHEMATICAL INTELLIGENCER"},
      {"name":"BULLETIN OF THE LEBEDEV PHYSICS INSTITUTE"},
      {"name":"CONTEMPORARY PROBLEMS OF ECOLOGY"},
      {"name":"HISTORIA Y POLITICA"},
      {"name":"CONCEPTS IN MAGNETIC RESONANCE PART A"},
      {"name":"HEMOGLOBIN"},
      {"name":"IEICE TRANSACTIONS ON INFORMATION AND SYSTEMS"},
      {"name":"BULLETIN OF THE BRAZILIAN MATHEMATICAL SOCIETY"},
      {"name":"CLINICAL DYSMORPHOLOGY"},
      {"name":"MICROWAVE JOURNAL"},
      {"name":"JOURNAL OF SEMICONDUCTOR TECHNOLOGY AND SCIENCE"},
      {"name":"ACTA BIOQUIMICA CLINICA LATINOAMERICANA"},
      {"name":"B E JOURNAL OF THEORETICAL ECONOMICS"},
      {"name":"TEKNIK DERGI"},
      {"name":"PHYSICS IN PERSPECTIVE"},
      {"name":"JOURNAL OF EXOTIC PET MEDICINE"},
      {"name":"PHI DELTA KAPPAN"},
      {"name":"JOURNAL OF INFORMATION SCIENCE AND ENGINEERING"},
      {"name":"ONCOLOGIE"},
      {"name":"INTERNATIONAL JOURNAL OF MENTAL HEALTH PROMOTION"},
      {"name":"STUDIA PSYCHOLOGICA"},
      {"name":"CORNELL INTERNATIONAL LAW JOURNAL"},
      {"name":"JAPANESE JOURNAL OF VETERINARY RESEARCH"},
      {"name":"DISCRETE MATHEMATICS AND THEORETICAL COMPUTER SCIENCE"},
      {"name":"CUADERNOS DE DESARROLLO RURAL"},
      {"name":"REVUE FRANCAISE DE SOCIOLOGIE"},
      {"name":"PSYCHIATRY PSYCHOLOGY AND LAW"},
      {"name":"JOURNAL OF HISTORICAL PRAGMATICS"},
      {"name":"ZEITSCHRIFT FUR NEUROPSYCHOLOGIE"},
      {"name":"JOURNAL OF BALTIC STUDIES"},
      {"name":"CANADIAN JOURNAL OF CRIMINOLOGY AND CRIMINAL JUSTICE"},
      {"name":"SOUTH AFRICAN HISTORICAL JOURNAL"},
      {"name":"ECONOMIA POLITICA"},
      {"name":"INTERNATIONAL SURGERY"},
      {"name":"INTERNATIONAL JOURNAL OF COGNITIVE THERAPY"},
      {"name":"AUSTRALIAN JOURNAL OF POLITICS AND HISTORY"},
      {"name":"REVISTA DE HISTORIA INDUSTRIAL"},
      {"name":"BMC MOLECULAR AND CELL BIOLOGY"},
      {"name":"JAPAN JOURNAL OF INDUSTRIAL AND APPLIED MATHEMATICS"},
      {"name":"REVISTA INTERNACIONAL DE METODOS NUMERICOS PARA CALCULO Y DISENO EN INGENIERIA"},
      {"name":"FETAL AND PEDIATRIC PATHOLOGY"},
      {"name":"ARCHIVES OF NATURAL HISTORY"},
      {"name":"JOURNAL OF THE KANSAS ENTOMOLOGICAL SOCIETY"},
      {"name":"PSYCHIATRIE DE L ENFANT"},
      {"name":"JOURNAL OF SOCIAL SERVICE RESEARCH"},
      {"name":"ACTA PAULISTA DE ENFERMAGEM"},
      {"name":"ACTA CLINICA CROATICA"},
      {"name":"INTERNATIONAL JOURNAL OF PSYCHOANALYSIS"},
      {"name":"BULLETIN OF THE KOREAN MATHEMATICAL SOCIETY"},
      {"name":"GEOMAGNETISM AND AERONOMY"},
      {"name":"BIOMEDICA"},
      {"name":"CADMO"},
      {"name":"JOURNAL OF ISRAELI HISTORY"},
      {"name":"JOURNAL OF THE AUSTRALIAN LIBRARY AND INFORMATION ASSOCIATION"},
      {"name":"PSYCHOTHERAPEUT"},
      {"name":"IBERICA"},
      {"name":"REPORTS ON MATHEMATICAL LOGIC"},
      {"name":"JOURNAL OF POLYMER MATERIALS"},
      {"name":"JOURNAL OF LIE THEORY"},
      {"name":"JOURNAL OF INFRARED AND MILLIMETER WAVES"},
      {"name":"JOURNAL OF THE COPYRIGHT SOCIETY OF THE USA"},
      {"name":"JOURNAL OF FUNCTIONAL PROGRAMMING"},
      {"name":"ATLANTIC GEOLOGY"},
      {"name":"REVUE FRANCAISE D ALLERGOLOGIE"},
      {"name":"UNIVERSITAS PSYCHOLOGICA"},
      {"name":"BARIATRIC SURGICAL PRACTICE AND PATIENT CARE"},
      {"name":"PAKISTAN JOURNAL OF PHARMACEUTICAL SCIENCES"},
      {"name":"MAMMAL STUDY"},
      {"name":"COLUMBIA JOURNAL OF LAW AND SOCIAL PROBLEMS"},
      {"name":"ZEITSCHRIFT FUR ETHNOLOGIE"},
      {"name":"HYLE"},
      {"name":"INTERNATIONAL REVIEW OF THE RED CROSS"},
      {"name":"ARTHROPODA SELECTA"},
      {"name":"JOURNAL OF AGROMETEOROLOGY"},
      {"name":"REVISTA FITOTECNIA MEXICANA"},
      {"name":"HEMIJSKA INDUSTRIJA"},
      {"name":"INTERNATIONAL JOURNAL OF CLINICAL AND EXPERIMENTAL MEDICINE"},
      {"name":"RUTGERS UNIVERSITY LAW REVIEW"},
      {"name":"AMERICAN JOURNAL OF ECONOMICS AND SOCIOLOGY"},
      {"name":"NEW SCIENTIST"},
      {"name":"AERONAUTICAL JOURNAL"},
      {"name":"ANNALS OF COMBINATORICS"},
      {"name":"MAEJO INTERNATIONAL JOURNAL OF SCIENCE AND TECHNOLOGY"},
      {"name":"GENDER AND HISTORY"},
      {"name":"AGRARFORSCHUNG SCHWEIZ"},
      {"name":"JOURNAL OF WOMENS HISTORY"},
      {"name":"PSYCHOANALYSIS AND HISTORY"},
      {"name":"GLOBAL ECONOMIC REVIEW"},
      {"name":"AFRICAN AND ASIAN STUDIES"},
      {"name":"CONDENSED MATTER PHYSICS"},
      {"name":"ODONATOLOGICA"},
      {"name":"SERIALS REVIEW"},
      {"name":"REVUE FRANCAISE DE LINGUISTIQUE APPLIQUEE"},
      {"name":"FINANZARCHIV"},
      {"name":"WESTERN NORTH AMERICAN NATURALIST"},
      {"name":"BELL LABS TECHNICAL JOURNAL"},
      {"name":"AMERICAN MALACOLOGICAL BULLETIN"},
      {"name":"HITOTSUBASHI JOURNAL OF ECONOMICS"},
      {"name":"REVIEW OF DERIVATIVES RESEARCH"},
      {"name":"JOURNAL OF COMPARATIVE FAMILY STUDIES"},
      {"name":"OSTRICH"},
      {"name":"LATIN AMERICAN JOURNAL OF PHARMACY"},
      {"name":"REPRESENTATIONS"},
      {"name":"JOURNAL OF SOUTHEAST ASIAN STUDIES"},
      {"name":"ZHURNAL OBSHCHEI BIOLOGII"},
      {"name":"QUEEN MARY JOURNAL OF INTELLECTUAL PROPERTY"},
      {"name":"NAVAL ENGINEERS JOURNAL"},
      {"name":"JOURNAL OF FRICTION AND WEAR"},
      {"name":"MANAGERIAL AND DECISION ECONOMICS"},
      {"name":"JOURNAL OF THE RAMANUJAN MATHEMATICAL SOCIETY"},
      {"name":"JOURNAL OF APPLIED ICHTHYOLOGY"},
      {"name":"MODERN CHINESE LITERATURE AND CULTURE"},
      {"name":"AMERICAN LABORATORY"},
      {"name":"TURKISH JOURNAL OF ZOOLOGY"},
      {"name":"THEORY AND APPLICATIONS OF CATEGORIES"},
      {"name":"JOURNAL OF THE SOUTH AFRICAN INSTITUTION OF CIVIL ENGINEERING"},
      {"name":"JOURNAL OF LATIN AMERICAN STUDIES"},
      {"name":"BULLETIN OF LATIN AMERICAN RESEARCH"},
      {"name":"RUSSIAN JOURNAL OF HERPETOLOGY"},
      {"name":"IEICE TRANSACTIONS ON FUNDAMENTALS OF ELECTRONICS COMMUNICATIONS AND COMPUTER SCIENCES"},
      {"name":"ORVOSI HETILAP"},
      {"name":"PSYCHOLOGIST"},
      {"name":"SOCIAL SCIENCE HISTORY"},
      {"name":"RECHTSMEDIZIN"},
      {"name":"THEORETICAL AND EXPERIMENTAL CHEMISTRY"},
      {"name":"AFRICAN ENTOMOLOGY"},
      {"name":"ARCHIV DER MATHEMATIK"},
      {"name":"SCIENCE IN CONTEXT"},
      {"name":"MATHEMATICA SCANDINAVICA"},
      {"name":"ACTA ORTHOPAEDICA BELGICA"},
      {"name":"ARCHIVE FOR HISTORY OF EXACT SCIENCES"},
      {"name":"MARINE ORNITHOLOGY"},
      {"name":"REVUE D HISTOIRE DES MATHEMATIQUES"},
      {"name":"PSICOLOGICA"},
      {"name":"PLANTA DANINHA"},
      {"name":"THEORY OF COMPUTING SYSTEMS"},
      {"name":"GLASS PHYSICS AND CHEMISTRY"},
      {"name":"AMBIX"},
      {"name":"FEMINIST STUDIES"},
      {"name":"ARCHIVES OF METALLURGY AND MATERIALS"},
      {"name":"STATISTICS"},
      {"name":"TURKISH JOURNAL OF MATHEMATICS"},
      {"name":"ALTERNATIVES"},
      {"name":"AMERICAN BIOLOGY TEACHER"},
      {"name":"CHILDRENS HEALTH CARE"},
      {"name":"REVISTA DEL CLAD REFORMA Y DEMOCRACIA"},
      {"name":"QUATERNAIRE"},
      {"name":"BULLETIN DE LA SOCIETE MATHEMATIQUE DE FRANCE"},
      {"name":"THEORY OF PROBABILITY AND ITS APPLICATIONS"},
      {"name":"BAUTECHNIK"},
      {"name":"CHEMIJA"},
      {"name":"JOURNAL OF SYMBOLIC LOGIC"},
      {"name":"RESEARCH AND THEORY FOR NURSING PRACTICE"},
      {"name":"TEMPO SOCIAL"},
      {"name":"CARTOGRAPHIC JOURNAL"},
      {"name":"AMERICAN SURGEON"},
      {"name":"JOURNAL OF THE ECONOMIC AND SOCIAL HISTORY OF THE ORIENT"},
      {"name":"INTERNATIONAL JOURNAL OF GAME THEORY"},
      {"name":"KAFKAS UNIVERSITESI VETERINER FAKULTESI DERGISI"},
      {"name":"ORAL RADIOLOGY"},
      {"name":"ZEITSCHRIFT FUR EVALUATION"},
      {"name":"JOURNAL OF MODERN HISTORY"},
      {"name":"JOURNAL OF POST KEYNESIAN ECONOMICS"},
      {"name":"SOUTH AFRICAN JOURNAL OF ECONOMIC AND MANAGEMENT SCIENCES"},
      {"name":"MOKUZAI GAKKAISHI"},
      {"name":"CHIANG MAI JOURNAL OF SCIENCE"},
      {"name":"JOURNAL OF AFRICAN HISTORY"},
      {"name":"ANNEE PSYCHOLOGIQUE"},
      {"name":"ZEITSCHRIFT FUR SPRACHWISSENSCHAFT"},
      {"name":"INTERNATIONAL JOURNAL OF ALGEBRA AND COMPUTATION"},
      {"name":"JOURNAL OF GENERAL PSYCHOLOGY"},
      {"name":"AUSTRALIAN AND NEW ZEALAND JOURNAL OF FAMILY THERAPY"},
      {"name":"RUSSIAN JOURNAL OF PACIFIC GEOLOGY"},
      {"name":"MATHEMATICAL LOGIC QUARTERLY"},
      {"name":"MITTEILUNGEN DER OSTERREICHISCHEN GEOGRAPHISCHEN GESELLSCHAFT"},
      {"name":"TOKYO JOURNAL OF MATHEMATICS"},
      {"name":"COLUMBIA JOURNAL OF TRANSNATIONAL LAW"},
      {"name":"ALGEBRA AND LOGIC"},
      {"name":"FOOD HYGIENE AND SAFETY SCIENCE"},
      {"name":"ISIS"},
      {"name":"JAPANESE JOURNAL OF APPLIED ENTOMOLOGY AND ZOOLOGY"},
      {"name":"JOURNAL OF THE CHEMICAL SOCIETY OF PAKISTAN"},
      {"name":"SOCIOLOGIE DU TRAVAIL"},
      {"name":"RADIATION EFFECTS AND DEFECTS IN SOLIDS"},
      {"name":"ELECTRONIC RESEARCH ANNOUNCEMENTS IN MATHEMATICAL SCIENCES"},
      {"name":"POSTEPY BIOLOGII KOMORKI"},
      {"name":"HISTORIA CRITICA"},
      {"name":"NEUROLOGY ASIA"},
      {"name":"JOURNAL OF THE MEXICAN CHEMICAL SOCIETY"},
      {"name":"PAPELES DE POBLACION"},
      {"name":"ADVANCED COMPOSITES LETTERS"},
      {"name":"ZEITSCHRIFT FUR ENTWICKLUNGSPSYCHOLOGIE UND PADAGOGISCHE PSYCHOLOGIE"},
      {"name":"GEMATOLOGIYA I TRANSFUZIOLOGIYA"},
      {"name":"TAPPI JOURNAL"},
      {"name":"PFERDEHEILKUNDE"},
      {"name":"ZKG INTERNATIONAL"},
      {"name":"JOURNAL OF THE TORREY BOTANICAL SOCIETY"},
      {"name":"RUSSIAN JOURNAL OF PHYSICAL CHEMISTRY B"},
      {"name":"ETHIK IN DER MEDIZIN"},
      {"name":"LISTY CUKROVARNICKE A REPARSKE"},
      {"name":"SOCIOLOGISK FORSKNING"},
      {"name":"POLITIX"},
      {"name":"CASTANEA"},
      {"name":"ELECTRONIC JOURNAL OF LINEAR ALGEBRA"},
      {"name":"REVISTA MEXICANA DE BIODIVERSIDAD"},
      {"name":"NEGOTIATION JOURNAL"},
      {"name":"JOURNAL OF HEMATOPATHOLOGY"},
      {"name":"JOURNAL OF INTERNATIONAL DISPUTE SETTLEMENT"},
      {"name":"DREWNO"},
      {"name":"PHYSICS OF ATOMIC NUCLEI"},
      {"name":"REVISTA DE ESTUDIOS SOCIALES"},
      {"name":"INTEGRATIVE PSYCHOLOGICAL AND BEHAVIORAL SCIENCE"},
      {"name":"ROMANIAN AGRICULTURAL RESEARCH"},
      {"name":"BALTIC JOURNAL OF ROAD AND BRIDGE ENGINEERING"},
      {"name":"DEVIANCE ET SOCIETE"},
      {"name":"NEW YORK JOURNAL OF MATHEMATICS"},
      {"name":"JOURNAL OF THE PAKISTAN MEDICAL ASSOCIATION"},
      {"name":"AFRICAN STUDIES"},
      {"name":"SOUTH AFRICAN JOURNAL FOR RESEARCH IN SPORT PHYSICAL EDUCATION AND RECREATION"},
      {"name":"BULLETIN OF THE EUROPEAN ASSOCIATION OF FISH PATHOLOGISTS"},
      {"name":"DOCUMENTA MATHEMATICA"},
      {"name":"PATHOLOGE"},
      {"name":"JOURNAL OF POPULAR CULTURE"},
      {"name":"OCHRONA SRODOWISKA"},
      {"name":"HUMAN STUDIES"},
      {"name":"AFRICANA LINGUISTICA"},
      {"name":"COLEOPTERISTS BULLETIN"},
      {"name":"FORMAL ASPECTS OF COMPUTING"},
      {"name":"JOURNAL OF NEW MATERIALS FOR ELECTROCHEMICAL SYSTEMS"},
      {"name":"FOURRAGES"},
      {"name":"REVISTA BRASILEIRA DE ENGENHARIA AGRICOLA E AMBIENTAL"},
      {"name":"JOURNAL OF CHINESE LINGUISTICS"},
      {"name":"JOURNAL OF MORAL PHILOSOPHY"},
      {"name":"JOURNAL OF SOCIAL PHILOSOPHY"},
      {"name":"INTERNATIONAL JOURNAL OF ECONOMIC THEORY"},
      {"name":"ACTA PROTOZOOLOGICA"},
      {"name":"ZOOLOGICHESKY ZHURNAL"},
      {"name":"SANTE PUBLIQUE"},
      {"name":"REVUE DE MEDECINE VETERINAIRE"},
      {"name":"PRATIQUES PSYCHOLOGIQUES"},
      {"name":"JOURNAL OF RISK MODEL VALIDATION"},
      {"name":"JOURNAL OF THE CHILEAN CHEMICAL SOCIETY"},
      {"name":"RUSSIAN JOURNAL OF NONDESTRUCTIVE TESTING"},
      {"name":"ENGENHARIA SANITARIA E AMBIENTAL"},
      {"name":"LASERS IN ENGINEERING"},
      {"name":"ZEITSCHRIFT FUR PSYCHIATRIE PSYCHOLOGIE UND PSYCHOTHERAPIE"},
      {"name":"ZEITSCHRIFT FUR PADAGOGISCHE PSYCHOLOGIE"},
      {"name":"TRANSACTIONS OF THE CANADIAN SOCIETY FOR MECHANICAL ENGINEERING"},
      {"name":"IHERINGIA SERIE ZOOLOGIA"},
      {"name":"IN PRACTICE"},
      {"name":"SECURITIES REGULATION LAW JOURNAL"},
      {"name":"STUDIA SCIENTIARUM MATHEMATICARUM HUNGARICA"},
      {"name":"HONG KONG JOURNAL OF EMERGENCY MEDICINE"},
      {"name":"MECHANIKA"},
      {"name":"BIOPHARM INTERNATIONAL"},
      {"name":"INDIAN JOURNAL OF FISHERIES"},
      {"name":"ADVANCEMENTS OF MICROBIOLOGY"},
      {"name":"TURK PSIKIYATRI DERGISI"},
      {"name":"SURFACE COATINGS INTERNATIONAL"},
      {"name":"JOURNAL OF DERIVATIVES"},
      {"name":"JOURNAL OF COMMUTATIVE ALGEBRA"},
      {"name":"REVISTA MVZ CORDOBA"},
      {"name":"INDIAN ECONOMIC AND SOCIAL HISTORY REVIEW"},
      {"name":"HISTORY OF EDUCATION"},
      {"name":"JOURNAL OF GERMANIC LINGUISTICS"},
      {"name":"ANNALS OF ECONOMICS AND FINANCE"},
      {"name":"HISTORY AND PHILOSOPHY OF LOGIC"},
      {"name":"INDIAN JOURNAL OF ANIMAL SCIENCES"},
      {"name":"MAGYAR ALLATORVOSOK LAPJA"},
      {"name":"PSYCHOANALYTIC INQUIRY"},
      {"name":"ACROSS LANGUAGES AND CULTURES"},
      {"name":"ACTA LINGUISTICA HUNGARICA"},
      {"name":"BERICHTE ZUR WISSENSCHAFTSGESCHICHTE"},
      {"name":"EARTH SCIENCES HISTORY"},
      {"name":"PACHYDERM"},
      {"name":"ARCTIC ANTHROPOLOGY"},
      {"name":"JOURNAL OF SYMPLECTIC GEOMETRY"},
      {"name":"SEMIGROUP FORUM"},
      {"name":"JOURNAL OF REHABILITATION"},
      {"name":"MOUVEMENT SOCIAL"},
      {"name":"JOURNAL OF HOMELAND SECURITY AND EMERGENCY MANAGEMENT"},
      {"name":"VISUAL COMMUNICATION"},
      {"name":"SEMIOTICA"},
      {"name":"ACTA ZOOLOGICA BULGARICA"},
      {"name":"NEUROSCIENCES"},
      {"name":"SOUTHEASTERN NATURALIST"},
      {"name":"CANADIAN STUDIES IN POPULATION"},
      {"name":"BULLETIN MATHEMATIQUE DE LA SOCIETE DES SCIENCES MATHEMATIQUES DE ROUMANIE"},
      {"name":"JOURNAL OF REPRODUCTIVE MEDICINE"},
      {"name":"ENVIRONMENTAL POLLUTANTS AND BIOAVAILABILITY"},
      {"name":"POWDER METALLURGY AND METAL CERAMICS"},
      {"name":"JOURNAL OF SHIP PRODUCTION AND DESIGN"},
      {"name":"ACTA ALIMENTARIA"},
      {"name":"BIOLOGICHESKIE MEMBRANY"},
      {"name":"SOUTHEAST ASIAN JOURNAL OF TROPICAL MEDICINE AND PUBLIC HEALTH"},
      {"name":"STANFORD JOURNAL OF INTERNATIONAL LAW"},
      {"name":"HISTORY AND ANTHROPOLOGY"},
      {"name":"PROGRESS IN BIOCHEMISTRY AND BIOPHYSICS"},
      {"name":"METAL SCIENCE AND HEAT TREATMENT"},
      {"name":"CHEMIE IN UNSERER ZEIT"},
      {"name":"INTERNATIONAL JOURNAL OF MARITIME ENGINEERING"},
      {"name":"TECHNICAL PHYSICS"},
      {"name":"INDIAN JOURNAL OF HORTICULTURE"},
      {"name":"JOURNAL OF ASIAN ARCHITECTURE AND BUILDING ENGINEERING"},
      {"name":"REVISTA BRASILEIRA DE ORNITOLOGIA"},
      {"name":"CENTAURUS"},
      {"name":"GEFAHRSTOFFE REINHALTUNG DER LUFT"},
      {"name":"IRANIAN RED CRESCENT MEDICAL JOURNAL"},
      {"name":"CAHIERS DE BIOLOGIE MARINE"},
      {"name":"ANTHROPOLOGICAL SCIENCE"},
      {"name":"MEDICAL PROBLEMS OF PERFORMING ARTISTS"},
      {"name":"APPITA"},
      {"name":"WATER QUALITY RESEARCH JOURNAL OF CANADA"},
      {"name":"AMERICAN BANKRUPTCY LAW JOURNAL"},
      {"name":"INDIAN JOURNAL OF BIOTECHNOLOGY"},
      {"name":"AFINIDAD"},
      {"name":"LANGUAGE MATTERS"},
      {"name":"MATHEMATICAL POPULATION STUDIES"},
      {"name":"SAINS MALAYSIANA"},
      {"name":"TRANSINFORMACAO"},
      {"name":"ZHURNAL VYSSHEI NERVNOI DEYATELNOSTI IMENI I P PAVLOVA"},
      {"name":"EARTH SCIENCES RESEARCH JOURNAL"},
      {"name":"IDOJARAS"},
      {"name":"ANNALES DE PATHOLOGIE"},
      {"name":"PARALLAX"},
      {"name":"CONTRIBUTIONS TO INDIAN SOCIOLOGY"},
      {"name":"TIDSSKRIFT FOR SAMFUNNSFORSKNING"},
      {"name":"ACTA HISTRIAE"},
      {"name":"APPLIED CATEGORICAL STRUCTURES"},
      {"name":"JOURNAL OF CHEMICAL ENGINEERING OF JAPAN"},
      {"name":"REVISTA DE BIOLOGIA TROPICAL"},
      {"name":"ASIAN JOURNAL OF MATHEMATICS"},
      {"name":"AEROSPACE AMERICA"},
      {"name":"ECOLOGY LAW QUARTERLY"},
      {"name":"SENSORS AND MATERIALS"},
      {"name":"SOTSIOLOGICHESKIE ISSLEDOVANIYA"},
      {"name":"JOURNAL OF COMMUNICATIONS TECHNOLOGY AND ELECTRONICS"},
      {"name":"SEA TECHNOLOGY"},
      {"name":"AUSTRALASIAN EMERGENCY CARE"},
      {"name":"JOURNAL OF OPTICAL TECHNOLOGY"},
      {"name":"STATISTICAL METHODS AND APPLICATIONS"},
      {"name":"INTERNATIONAL SUGAR JOURNAL"},
      {"name":"INTERNATIONAL JOURNAL OF CLINICAL AND EXPERIMENTAL PATHOLOGY"},
      {"name":"PRAXIS DER KINDERPSYCHOLOGIE UND KINDERPSYCHIATRIE"},
      {"name":"REVISTA ARGENTINA DE CLINICA PSICOLOGICA"},
      {"name":"JAHRBUCHER FUR NATIONALOKONOMIE UND STATISTIK"},
      {"name":"IEICE TRANSACTIONS ON COMMUNICATIONS"},
      {"name":"HUMAN BIOLOGY"},
      {"name":"COMPUTING AND INFORMATICS"},
      {"name":"JOURNAL OF THE SOUTHERN AFRICAN INSTITUTE OF MINING AND METALLURGY"},
      {"name":"INTERNATIONAL POLITICS"},
      {"name":"ANTHROPOLOGICAL NOTEBOOKS"},
      {"name":"NIGERIAN JOURNAL OF CLINICAL PRACTICE"},
      {"name":"GRUNDWASSER"},
      {"name":"CIVIL SZEMLE"},
      {"name":"TYDSKRIF VIR GEESTESWETENSKAPPE"},
      {"name":"MEDICINA DELLO SPORT"},
      {"name":"JOURNAL OF THE INTERNATIONAL PHONETIC ASSOCIATION"},
      {"name":"INTERNATIONAL JOURNAL OF SURFACE SCIENCE AND ENGINEERING"},
      {"name":"REVIEW OF NETWORK ECONOMICS"},
      {"name":"RUSSIAN JOURNAL OF ORGANIC CHEMISTRY"},
      {"name":"WASSERWIRTSCHAFT"},
      {"name":"SCIENCES SOCIALES ET SANTE"},
      {"name":"COLD WAR HISTORY"},
      {"name":"RUSSIAN JOURNAL OF ECOLOGY"},
      {"name":"CIRUGIA Y CIRUJANOS"},
      {"name":"CONTEMPORARY EUROPEAN HISTORY"},
      {"name":"ENGLISH TODAY"},
      {"name":"BRADLEYA"},
      {"name":"JOURNAL OF SPANISH CULTURAL STUDIES"},
      {"name":"PEDAGOGISCHE STUDIEN"},
      {"name":"ARCHIVES OF ACOUSTICS"},
      {"name":"PAEDAGOGICA HISTORICA"},
      {"name":"JOURNAL OF MATHEMATICAL PHYSICS ANALYSIS GEOMETRY"},
      {"name":"TELOPEA"},
      {"name":"JOURNAL OF CONVEX ANALYSIS"},
      {"name":"GERMAN JOURNAL OF AGRICULTURAL ECONOMICS"},
      {"name":"ICELANDIC AGRICULTURAL SCIENCES"},
      {"name":"ENVIRONMENTAL HISTORY"},
      {"name":"CRYSTALLOGRAPHY REPORTS"},
      {"name":"PROGRESS IN COMPUTATIONAL FLUID DYNAMICS"},
      {"name":"SLAVIC REVIEW"},
      {"name":"JOURNAL OF THE FACULTY OF AGRICULTURE KYUSHU UNIVERSITY"},
      {"name":"ACTA ICHTHYOLOGICA ET PISCATORIA"},
      {"name":"HETEROCYCLES"},
      {"name":"INTERNATIONAL JOURNAL OF DATA WAREHOUSING AND MINING"},
      {"name":"LITHOLOGY AND MINERAL RESOURCES"},
      {"name":"JOURNAL OF PSYCHOLOGY AND THEOLOGY"},
      {"name":"LECTURE NOTES IN MATHEMATICS"},
      {"name":"BUFFALO BULLETIN"},
      {"name":"ASIAN SURVEY"},
      {"name":"ACTA SCIENTIAE VETERINARIAE"},
      {"name":"RETHINKING HISTORY"},
      {"name":"FOLIA ZOOLOGICA"},
      {"name":"PSYCHOANALYTIC STUDY OF THE CHILD"},
      {"name":"PROCEEDINGS OF THE STEKLOV INSTITUTE OF MATHEMATICS"},
      {"name":"JOURNAL OF FIBER SCIENCE AND TECHNOLOGY"},
      {"name":"RUSSIAN JOURNAL OF THERIOLOGY"},
      {"name":"ANNALES POLONICI MATHEMATICI"},
      {"name":"EASTERN EUROPEAN COUNTRYSIDE"},
      {"name":"ROMANIAN JOURNAL OF INFORMATION SCIENCE AND TECHNOLOGY"},
      {"name":"GERMAN STUDIES REVIEW"},
      {"name":"STATISTICS AND ITS INTERFACE"},
      {"name":"HOUSTON JOURNAL OF MATHEMATICS"},
      {"name":"BULLETIN OF THE IRANIAN MATHEMATICAL SOCIETY"},
      {"name":"INDIAN JOURNAL OF SURGERY"},
      {"name":"REVISTA COLOMBIANA DE CIENCIAS PECUARIAS"},
      {"name":"NORTHEASTERN NATURALIST"},
      {"name":"JOURNAL OF ZOO AND WILDLIFE MEDICINE"},
      {"name":"EKONOMICKY CASOPIS"},
      {"name":"REVISTA SIGNOS"},
      {"name":"SOCIOLOGUS"},
      {"name":"REVUE ROUMAINE DE CHIMIE"},
      {"name":"SEMINARS IN ORTHODONTICS"},
      {"name":"TRIMESTRE ECONOMICO"},
      {"name":"LYMPHOLOGY"},
      {"name":"HISTORY WORKSHOP JOURNAL"},
      {"name":"TIJDSCHRIFT VOOR COMMUNICATIEWETENSCHAP"},
      {"name":"COATINGSTECH"},
      {"name":"UTILITAS MATHEMATICA"},
      {"name":"FOLIA PHONIATRICA ET LOGOPAEDICA"},
      {"name":"THEORETICAL FOUNDATIONS OF CHEMICAL ENGINEERING"},
      {"name":"INTERNATIONAL FOOD RESEARCH JOURNAL"},
      {"name":"DOKLADY EARTH SCIENCES"},
      {"name":"INGENIERIA E INVESTIGACION"},
      {"name":"INTERDISCIPLINARY SCIENCE REVIEWS"},
      {"name":"MANCHESTER SCHOOL"},
      {"name":"FLUID DYNAMICS"},
      {"name":"ECONTENT"},
      {"name":"ARCHIVOS ARGENTINOS DE PEDIATRIA"},
      {"name":"BANGLADESH JOURNAL OF BOTANY"},
      {"name":"FREQUENZ"},
      {"name":"RUSSIAN JOURNAL OF NUMERICAL ANALYSIS AND MATHEMATICAL MODELLING"},
      {"name":"PSIHOLOGIJA"},
      {"name":"RUSSIAN JOURNAL OF MARINE BIOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF MORPHOLOGY"},
      {"name":"ARCHIVES OF CLINICAL PSYCHIATRY"},
      {"name":"FUNCTIONAL ANALYSIS AND ITS APPLICATIONS"},
      {"name":"INTERNATIONAL JOURNAL"},
      {"name":"BULLETIN OF SYMBOLIC LOGIC"},
      {"name":"AFRICAN JOURNAL OF LIBRARY ARCHIVES AND INFORMATION SCIENCE"},
      {"name":"HIDROBIOLOGICA"},
      {"name":"SRPSKI ARHIV ZA CELOKUPNO LEKARSTVO"},
      {"name":"JOURNAL OF THE KOREAN MATHEMATICAL SOCIETY"},
      {"name":"BMC CHEMISTRY"},
      {"name":"PHARMACEUTICAL CHEMISTRY JOURNAL"},
      {"name":"INFINITE DIMENSIONAL ANALYSIS QUANTUM PROBABILITY AND RELATED TOPICS"},
      {"name":"REVISTA CHILENA DE DERECHO"},
      {"name":"OSAKA JOURNAL OF MATHEMATICS"},
      {"name":"AGROCHIMICA"},
      {"name":"PESQUISA AGROPECUARIA BRASILEIRA"},
      {"name":"BULLETIN DE L ACADEMIE NATIONALE DE MEDECINE"},
      {"name":"APPLICABLE ALGEBRA IN ENGINEERING COMMUNICATION AND COMPUTING"},
      {"name":"GEOGRAPHY"},
      {"name":"FRESENIUS ENVIRONMENTAL BULLETIN"},
      {"name":"SALUD COLECTIVA"},
      {"name":"NIHON REOROJI GAKKAISHI"},
      {"name":"JOURNAL OF PEDIATRIC INFECTIOUS DISEASES"},
      {"name":"BRAZILIAN JOURNAL OF OCEANOGRAPHY"},
      {"name":"CANDOLLEA"},
      {"name":"SOUTHERN AFRICAN LINGUISTICS AND APPLIED LANGUAGE STUDIES"},
      {"name":"EAST EUROPEAN POLITICS AND SOCIETIES"},
      {"name":"INTERNATIONAL JOURNAL OF TRANSPORT ECONOMICS"},
      {"name":"FEDERAL RESERVE BANK OF ST LOUIS REVIEW"},
      {"name":"JOURNAL OF APPLIED ECONOMICS"},
      {"name":"FRENCH CULTURAL STUDIES"},
      {"name":"REVISTA BRASILEIRA DE FRUTICULTURA"},
      {"name":"SOCIOLOGICAL SPECTRUM"},
      {"name":"CHEMISTRY AND TECHNOLOGY OF FUELS AND OILS"},
      {"name":"JUSTICE SYSTEM JOURNAL"},
      {"name":"INTEGRATED FERROELECTRICS"},
      {"name":"MATHEMATICAL RESEARCH LETTERS"},
      {"name":"JOURNAL OF VALUE INQUIRY"},
      {"name":"REVISTA ARVORE"},
      {"name":"ACTA ZOOLOGICA ACADEMIAE SCIENTIARUM HUNGARICAE"},
      {"name":"NAGOYA MATHEMATICAL JOURNAL"},
      {"name":"REVIEWS IN CARDIOVASCULAR MEDICINE"},
      {"name":"JOURNAL OF SEED SCIENCE"},
      {"name":"SLOVENIAN VETERINARY RESEARCH"},
      {"name":"KOBUNSHI RONBUNSHU"},
      {"name":"UROLOGE"},
      {"name":"JOURNAL OF COMPUTER AND SYSTEMS SCIENCES INTERNATIONAL"},
      {"name":"JOURNAL OF MEDICAL PRIMATOLOGY"},
      {"name":"DRUSTVENA ISTRAZIVANJA"},
      {"name":"SOUTHWESTERN NATURALIST"},
      {"name":"JOURNAL OF SYNTHETIC ORGANIC CHEMISTRY JAPAN"},
      {"name":"ARKIV FOR MATEMATIK"},
      {"name":"ZYGON"},
      {"name":"INTERNATIONAL JOURNAL OF OFFSHORE AND POLAR ENGINEERING"},
      {"name":"PHILIPPINE AGRICULTURAL SCIENTIST"},
      {"name":"NUCLEAR ENGINEERING INTERNATIONAL"},
      {"name":"JOURNAL OF TROPICAL FOREST SCIENCE"},
      {"name":"CA-A CANCER JOURNAL FOR CLINICIANS"},
      {"name":"JAMA-JOURNAL OF THE AMERICAN MEDICAL ASSOCIATION"},
      {"name":"BMJ-BRITISH MEDICAL JOURNAL"},
      {"name":"ENERGY & ENVIRONMENTAL SCIENCE"},
      {"name":"NATURE REVIEWS GASTROENTEROLOGY & HEPATOLOGY"},
      {"name":"MATERIALS SCIENCE & ENGINEERING R-REPORTS"},
      {"name":"PHYSICS REPORTS-REVIEW SECTION OF PHYSICS LETTERS"},
      {"name":"LANCET DIABETES & ENDOCRINOLOGY"},
      {"name":"ALZHEIMERS & DEMENTIA"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-COMPUTATIONAL MOLECULAR SCIENCE"},
      {"name":"ANNUAL REVIEW OF PATHOLOGY-MECHANISMS OF DISEASE"},
      {"name":"APPLIED CATALYSIS B-ENVIRONMENTAL"},
      {"name":"CELL HOST & MICROBE"},
      {"name":"LANCET GASTROENTEROLOGY & HEPATOLOGY"},
      {"name":"TRENDS IN ECOLOGY & EVOLUTION"},
      {"name":"LIGHT-SCIENCE & APPLICATIONS"},
      {"name":"MMWR-MORBIDITY AND MORTALITY WEEKLY REPORT"},
      {"name":"ANGEWANDTE CHEMIE-INTERNATIONAL EDITION"},
      {"name":"JACC-CARDIOVASCULAR IMAGING"},
      {"name":"NATURE ECOLOGY & EVOLUTION"},
      {"name":"NANO-MICRO LETTERS"},
      {"name":"RENEWABLE & SUSTAINABLE ENERGY REVIEWS"},
      {"name":"NATURE STRUCTURAL & MOLECULAR BIOLOGY"},
      {"name":"JOURNAL OF PHOTOCHEMISTRY AND PHOTOBIOLOGY C-PHOTOCHEMISTRY REVIEWS"},
      {"name":"JNCI-JOURNAL OF THE NATIONAL CANCER INSTITUTE"},
      {"name":"CATALYSIS REVIEWS-SCIENCE AND ENGINEERING"},
      {"name":"TRENDS IN FOOD SCIENCE & TECHNOLOGY"},
      {"name":"JOURNAL OF HEMATOLOGY & ONCOLOGY"},
      {"name":"LASER & PHOTONICS REVIEWS"},
      {"name":"PHARMACOLOGY & THERAPEUTICS"},
      {"name":"GLOBAL ENVIRONMENTAL CHANGE-HUMAN AND POLICY DIMENSIONS"},
      {"name":"REVIEWS IN MINERALOGY & GEOCHEMISTRY"},
      {"name":"BIOSENSORS & BIOELECTRONICS"},
      {"name":"NEURO-ONCOLOGY"},
      {"name":"PROTEIN & CELL"},
      {"name":"TRAC-TRENDS IN ANALYTICAL CHEMISTRY"},
      {"name":"EARTH-SCIENCE REVIEWS"},
      {"name":"ARTHRITIS & RHEUMATOLOGY"},
      {"name":"CURRENT OPINION IN SOLID STATE & MATERIALS SCIENCE"},
      {"name":"GENES & DEVELOPMENT"},
      {"name":"IEEE TRANSACTIONS ON SYSTEMS MAN CYBERNETICS-SYSTEMS"},
      {"name":"JACC-HEART FAILURE"},
      {"name":"ACS APPLIED MATERIALS & INTERFACES"},
      {"name":"JOURNAL OF CROHNS & COLITIS"},
      {"name":"COMPUTER-AIDED CIVIL AND INFRASTRUCTURE ENGINEERING"},
      {"name":"LANCET CHILD & ADOLESCENT HEALTH"},
      {"name":"CELLULAR & MOLECULAR IMMUNOLOGY"},
      {"name":"JACC-CARDIOVASCULAR INTERVENTIONS"},
      {"name":"INTERNATIONAL JOURNAL OF MACHINE TOOLS & MANUFACTURE"},
      {"name":"ENVIRONMENTAL SCIENCE & TECHNOLOGY"},
      {"name":"NEUROLOGY-NEUROIMMUNOLOGY & NEUROINFLAMMATION"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-NANOMEDICINE AND NANOBIOTECHNOLOGY"},
      {"name":"ENVIRONMENTAL SCIENCE-NANO"},
      {"name":"ENVIRONMENTAL SCIENCE & TECHNOLOGY LETTERS"},
      {"name":"COMPOSITES PART B-ENGINEERING"},
      {"name":"ACS SUSTAINABLE CHEMISTRY & ENGINEERING"},
      {"name":"JOURNAL OF ALLERGY AND CLINICAL IMMUNOLOGY-IN PRACTICE"},
      {"name":"ALIMENTARY PHARMACOLOGY & THERAPEUTICS"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-REVIEWS ON CANCER"},
      {"name":"AMERICAN JOURNAL OF MEDICAL GENETICS PART C-SEMINARS IN MEDICAL GENETICS"},
      {"name":"SENSORS AND ACTUATORS B-CHEMICAL"},
      {"name":"JOURNAL OF EXPERIMENTAL & CLINICAL CANCER RESEARCH"},
      {"name":"GENOMICS PROTEOMICS & BIOINFORMATICS"},
      {"name":"ANTIOXIDANTS & REDOX SIGNALING"},
      {"name":"MOLECULAR THERAPY-NUCLEIC ACIDS"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-RNA"},
      {"name":"ADVANCES IN PHYSICS-X"},
      {"name":"CURRENT OPINION IN COLLOID & INTERFACE SCIENCE"},
      {"name":"EUROPEAN HEART JOURNAL-CARDIOVASCULAR PHARMACOTHERAPY"},
      {"name":"SEMINARS IN CELL & DEVELOPMENTAL BIOLOGY"},
      {"name":"CLINICAL PHARMACOLOGY & THERAPEUTICS"},
      {"name":"CLINICAL & TRANSLATIONAL IMMUNOLOGY"},
      {"name":"COMPOSITES PART A-APPLIED SCIENCE AND MANUFACTURING"},
      {"name":"CLINICAL REVIEWS IN ALLERGY & IMMUNOLOGY"},
      {"name":"GREEN ENERGY & ENVIRONMENT"},
      {"name":"SCIENCE CHINA-CHEMISTRY"},
      {"name":"TRAUMA VIOLENCE & ABUSE"},
      {"name":"CELL DEATH & DISEASE"},
      {"name":"MATERIALS & DESIGN"},
      {"name":"CEMENT & CONCRETE COMPOSITES"},
      {"name":"REVIEWS IN ENDOCRINE & METABOLIC DISORDERS"},
      {"name":"METABOLISM-CLINICAL AND EXPERIMENTAL"},
      {"name":"JOURNAL OF MATERIALS SCIENCE & TECHNOLOGY"},
      {"name":"FUTURE GENERATION COMPUTER SYSTEMS-THE INTERNATIONAL JOURNAL OF ESCIENCE"},
      {"name":"ALZHEIMERS RESEARCH & THERAPY"},
      {"name":"JOURNAL OF TOXICOLOGY AND ENVIRONMENTAL HEALTH-PART B-CRITICAL REVIEWS"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-CLIMATE CHANGE"},
      {"name":"SCIENCE CHINA-MATERIALS"},
      {"name":"BIOENGINEERING & TRANSLATIONAL MEDICINE"},
      {"name":"TISSUE ENGINEERING PART B-REVIEWS"},
      {"name":"BMJ QUALITY & SAFETY"},
      {"name":"TRANSPORTATION RESEARCH PART C-EMERGING TECHNOLOGIES"},
      {"name":"GLOBAL FOOD SECURITY-AGRICULTURE POLICY ECONOMICS AND ENVIRONMENT"},
      {"name":"CIRCULATION-HEART FAILURE"},
      {"name":"JOURNAL OF INNOVATION & KNOWLEDGE"},
      {"name":"CYTOKINE & GROWTH FACTOR REVIEWS"},
      {"name":"GISCIENCE & REMOTE SENSING"},
      {"name":"KNOWLEDGE-BASED SYSTEMS"},
      {"name":"DIABETES OBESITY & METABOLISM"},
      {"name":"NONLINEAR ANALYSIS-HYBRID SYSTEMS"},
      {"name":"MATERIALS SCIENCE & ENGINEERING C-MATERIALS FOR BIOLOGICAL APPLICATIONS"},
      {"name":"ADVANCED SYNTHESIS & CATALYSIS"},
      {"name":"BUSINESS & INFORMATION SYSTEMS ENGINEERING"},
      {"name":"MUTATION RESEARCH-REVIEWS IN MUTATION RESEARCH"},
      {"name":"SOIL BIOLOGY & BIOCHEMISTRY"},
      {"name":"ALTEX-ALTERNATIVES TO ANIMAL EXPERIMENTATION"},
      {"name":"EMERGING MICROBES & INFECTIONS"},
      {"name":"CHINESE JOURNAL OF CANCER"},
      {"name":"CATALYSIS SCIENCE & TECHNOLOGY"},
      {"name":"CIRCULATION-CARDIOVASCULAR IMAGING"},
      {"name":"PHILOSOPHICAL TRANSACTIONS OF THE ROYAL SOCIETY B-BIOLOGICAL SCIENCES"},
      {"name":"IEEE-ASME TRANSACTIONS ON MECHATRONICS"},
      {"name":"ASTRONOMY & ASTROPHYSICS"},
      {"name":"JOURNAL OF SANDWICH STRUCTURES & MATERIALS"},
      {"name":"ULTRASOUND IN OBSTETRICS & GYNECOLOGY"},
      {"name":"CLINICAL PSYCHOLOGY-SCIENCE AND PRACTICE"},
      {"name":"CURRENT OPINION IN GENETICS & DEVELOPMENT"},
      {"name":"SUSTAINABLE ENERGY & FUELS"},
      {"name":"CIRCULATION-CARDIOVASCULAR INTERVENTIONS"},
      {"name":"ARCHIVES OF DISEASE IN CHILDHOOD-FETAL AND NEONATAL EDITION"},
      {"name":"CANCER BIOLOGY & MEDICINE"},
      {"name":"ANNUAL REVIEW OF CANCER BIOLOGY-SERIES"},
      {"name":"JOURNAL OF CLINICAL ENDOCRINOLOGY & METABOLISM"},
      {"name":"JOURNAL OF COMPUTER-MEDIATED COMMUNICATION"},
      {"name":"BIOLOGICAL PSYCHIATRY-COGNITIVE NEUROSCIENCE AND NEUROIMAGING"},
      {"name":"OMEGA-INTERNATIONAL JOURNAL OF MANAGEMENT SCIENCE"},
      {"name":"MOLECULAR NUTRITION & FOOD RESEARCH"},
      {"name":"COMPUTERS & EDUCATION"},
      {"name":"JOURNAL OF MATERIALS RESEARCH AND TECHNOLOGY-JMR&T"},
      {"name":"ACTA CRYSTALLOGRAPHICA SECTION D-STRUCTURAL BIOLOGY"},
      {"name":"JOURNALS OF GERONTOLOGY SERIES A-BIOLOGICAL SCIENCES AND MEDICAL SCIENCES"},
      {"name":"NANOMEDICINE-NANOTECHNOLOGY BIOLOGY AND MEDICINE"},
      {"name":"INFORMATION & MANAGEMENT"},
      {"name":"IEEE-CAA JOURNAL OF AUTOMATICA SINICA"},
      {"name":"STEM CELL RESEARCH & THERAPY"},
      {"name":"CIRCULATION-CARDIOVASCULAR QUALITY AND OUTCOMES"},
      {"name":"PUBLICATIONS OF THE ASTRONOMICAL SOCIETY OF AUSTRALIA"},
      {"name":"ROBOTICS AND COMPUTER-INTEGRATED MANUFACTURING"},
      {"name":"MICROSYSTEMS & NANOENGINEERING"},
      {"name":"RELIABILITY ENGINEERING & SYSTEM SAFETY"},
      {"name":"AMERICAN ECONOMIC JOURNAL-APPLIED ECONOMICS"},
      {"name":"CANCER & METABOLISM"},
      {"name":"PUBLICATIONS OF THE ASTRONOMICAL SOCIETY OF JAPAN"},
      {"name":"APPLIED CATALYSIS A-GENERAL"},
      {"name":"ANNALS OF ALLERGY ASTHMA & IMMUNOLOGY"},
      {"name":"REVIEWS IN ENVIRONMENTAL SCIENCE AND BIO-TECHNOLOGY"},
      {"name":"IEEE JOURNAL OF SOLID-STATE CIRCUITS"},
      {"name":"CMC-COMPUTERS MATERIALS & CONTINUA"},
      {"name":"MOLECULAR & CELLULAR PROTEOMICS"},
      {"name":"STRUCTURAL HEALTH MONITORING-AN INTERNATIONAL JOURNAL"},
      {"name":"CHEMISTRY-A EUROPEAN JOURNAL"},
      {"name":"EUROPEAN HEART JOURNAL-CARDIOVASCULAR IMAGING"},
      {"name":"AGING-US"},
      {"name":"ENVIRONMENTAL MODELLING & SOFTWARE"},
      {"name":"GENES & DISEASES"},
      {"name":"ENDOCRINE-RELATED CANCER"},
      {"name":"TRANSPORTATION RESEARCH PART B-METHODOLOGICAL"},
      {"name":"INFORMATION PROCESSING & MANAGEMENT"},
      {"name":"NEURAL COMPUTING & APPLICATIONS"},
      {"name":"ENERGY RESEARCH & SOCIAL SCIENCE"},
      {"name":"ENVIRONMENTAL SCIENCE & POLICY"},
      {"name":"INTERNATIONAL JOURNAL OF PHYSICAL DISTRIBUTION & LOGISTICS MANAGEMENT"},
      {"name":"DIABETES & METABOLISM"},
      {"name":"SUPPLY CHAIN MANAGEMENT-AN INTERNATIONAL JOURNAL"},
      {"name":"INTEGRATED COMPUTER-AIDED ENGINEERING"},
      {"name":"MATERIALS SCIENCE AND ENGINEERING B-ADVANCED FUNCTIONAL SOLID-STATE MATERIALS"},
      {"name":"TRANSPORTATION RESEARCH PART E-LOGISTICS AND TRANSPORTATION REVIEW"},
      {"name":"USER MODELING AND USER-ADAPTED INTERACTION"},
      {"name":"BJOG-AN INTERNATIONAL JOURNAL OF OBSTETRICS AND GYNAECOLOGY"},
      {"name":"MATERIALS SCIENCE AND ENGINEERING A-STRUCTURAL MATERIALS PROPERTIES MICROSTRUCTURE AND PROCESSING"},
      {"name":"DISEASE MODELS & MECHANISMS"},
      {"name":"PROCEEDINGS OF THE ROYAL SOCIETY B-BIOLOGICAL SCIENCES"},
      {"name":"INTERNATIONAL JOURNAL OF OPERATIONS & PRODUCTION MANAGEMENT"},
      {"name":"SCIENCE CHINA-LIFE SCIENCES"},
      {"name":"SOIL & TILLAGE RESEARCH"},
      {"name":"JOURNAL OF BONE AND JOINT SURGERY-AMERICAN VOLUME"},
      {"name":"BIG DATA & SOCIETY"},
      {"name":"NEW MEDIA & SOCIETY"},
      {"name":"TRANSPORTATION RESEARCH PART D-TRANSPORT AND ENVIRONMENT"},
      {"name":"INFORMATION COMMUNICATION & SOCIETY"},
      {"name":"JOURNAL OF PHYSIOLOGY-LONDON"},
      {"name":"BIOMEDICINE & PHARMACOTHERAPY"},
      {"name":"FRACTALS-COMPLEX GEOMETRY PATTERNS AND SCALING IN NATURE AND SOCIETY"},
      {"name":"CIRCULATION-CARDIOVASCULAR GENETICS"},
      {"name":"MOLECULAR THERAPY-METHODS & CLINICAL DEVELOPMENT"},
      {"name":"BIOFUELS BIOPRODUCTS & BIOREFINING-BIOFPR"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-MOLECULAR AND CELL BIOLOGY OF LIPIDS"},
      {"name":"SOCIOLOGICAL METHODS & RESEARCH"},
      {"name":"JOURNAL OF HOSPITALITY MARKETING & MANAGEMENT"},
      {"name":"INNOVATIVE FOOD SCIENCE & EMERGING TECHNOLOGIES"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-DATA MINING AND KNOWLEDGE DISCOVERY"},
      {"name":"JOURNAL OF MOLECULAR MEDICINE-JMM"},
      {"name":"AMERICAN JOURNAL OF PHYSIOLOGY-LUNG CELLULAR AND MOLECULAR PHYSIOLOGY"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-WATER"},
      {"name":"DIABETES TECHNOLOGY & THERAPEUTICS"},
      {"name":"CIRCULATION-ARRHYTHMIA AND ELECTROPHYSIOLOGY"},
      {"name":"COLLOIDS AND SURFACES B-BIOINTERFACES"},
      {"name":"BEST PRACTICE & RESEARCH CLINICAL OBSTETRICS & GYNAECOLOGY"},
      {"name":"JOURNAL OF PHOTOCHEMISTRY AND PHOTOBIOLOGY B-BIOLOGY"},
      {"name":"JOURNAL OF PSYCHIATRY & NEUROSCIENCE"},
      {"name":"BIOTECHNOLOGY & GENETIC ENGINEERING REVIEWS"},
      {"name":"PROGRESS IN NEURO-PSYCHOPHARMACOLOGY & BIOLOGICAL PSYCHIATRY"},
      {"name":"NUTRITION & DIABETES"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-MOLECULAR BASIS OF DISEASE"},
      {"name":"NEUROIMAGE-CLINICAL"},
      {"name":"CURRENT HIV/AIDS REPORTS"},
      {"name":"CANCER EPIDEMIOLOGY BIOMARKERS & PREVENTION"},
      {"name":"ARTHROSCOPY-THE JOURNAL OF ARTHROSCOPIC AND RELATED SURGERY"},
      {"name":"AMYLOID-JOURNAL OF PROTEIN FOLDING DISORDERS"},
      {"name":"BONE & JOINT JOURNAL"},
      {"name":"M&SOM-MANUFACTURING & SERVICE OPERATIONS MANAGEMENT"},
      {"name":"JOURNAL OF DESTINATION MARKETING & MANAGEMENT"},
      {"name":"PUBLICATIONS MATHEMATIQUES DE L IHES"},
      {"name":"AGRICULTURE ECOSYSTEMS & ENVIRONMENT"},
      {"name":"EPIGENETICS & CHROMATIN"},
      {"name":"SCIENCE CHINA-PHYSICS MECHANICS & ASTRONOMY"},
      {"name":"HUMAN-COMPUTER INTERACTION"},
      {"name":"PUBLIC MANAGEMENT REVIEW"},
      {"name":"ALCOHOL RESEARCH-CURRENT REVIEWS"},
      {"name":"ELEMENTA-SCIENCE OF THE ANTHROPOCENE"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-SYSTEMS BIOLOGY AND MEDICINE"},
      {"name":"FOOD & FUNCTION"},
      {"name":"INTERNATIONAL JOURNAL OF PRECISION ENGINEERING AND MANUFACTURING-GREEN TECHNOLOGY"},
      {"name":"JOURNAL OF HEPATO-BILIARY-PANCREATIC SCIENCES"},
      {"name":"ALLERGY ASTHMA & IMMUNOLOGY RESEARCH"},
      {"name":"ACS BIOMATERIALS SCIENCE & ENGINEERING"},
      {"name":"SOCIO-ECONOMIC PLANNING SCIENCES"},
      {"name":"COMPUTERS & INDUSTRIAL ENGINEERING"},
      {"name":"SCANDINAVIAN JOURNAL OF WORK ENVIRONMENT & HEALTH"},
      {"name":"MOLECULAR THERAPY-ONCOLYTICS"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-MOLECULAR CELL RESEARCH"},
      {"name":"ARTHRITIS RESEARCH & THERAPY"},
      {"name":"JOURNAL OF TRAVEL & TOURISM MARKETING"},
      {"name":"BIO-DESIGN AND MANUFACTURING"},
      {"name":"ARCHIVES OF PATHOLOGY & LABORATORY MEDICINE"},
      {"name":"IEEE POWER & ENERGY MAGAZINE"},
      {"name":"CRYSTAL GROWTH & DESIGN"},
      {"name":"NICOTINE & TOBACCO RESEARCH"},
      {"name":"BUSINESS & SOCIETY"},
      {"name":"CNS NEUROSCIENCE & THERAPEUTICS"},
      {"name":"INTERNATIONAL BIODETERIORATION & BIODEGRADATION"},
      {"name":"CIRCULATION-GENOMIC AND PRECISION MEDICINE"},
      {"name":"ACADEMY OF MANAGEMENT LEARNING & EDUCATION"},
      {"name":"ARTHRITIS CARE & RESEARCH"},
      {"name":"CHEMISTRY-AN ASIAN JOURNAL"},
      {"name":"FRONTIERS OF ENVIRONMENTAL SCIENCE & ENGINEERING"},
      {"name":"JOURNAL OF THE FRANKLIN INSTITUTE-ENGINEERING AND APPLIED MATHEMATICS"},
      {"name":"THIN-WALLED STRUCTURES"},
      {"name":"INTERNATIONAL JOURNAL OF COMPUTER-SUPPORTED COLLABORATIVE LEARNING"},
      {"name":"URBAN FORESTRY & URBAN GREENING"},
      {"name":"ACTA DERMATO-VENEREOLOGICA"},
      {"name":"ALGAL RESEARCH-BIOMASS BIOFUELS AND BIOPRODUCTS"},
      {"name":"LWT-FOOD SCIENCE AND TECHNOLOGY"},
      {"name":"COMPUTERS & CHEMICAL ENGINEERING"},
      {"name":"PROGRESS IN NATURAL SCIENCE-MATERIALS INTERNATIONAL"},
      {"name":"TRANSPORTATION RESEARCH PART A-POLICY AND PRACTICE"},
      {"name":"BEST PRACTICE & RESEARCH CLINICAL GASTROENTEROLOGY"},
      {"name":"DISEASES OF THE COLON & RECTUM"},
      {"name":"COLLOIDS AND SURFACES A-PHYSICOCHEMICAL AND ENGINEERING ASPECTS"},
      {"name":"PUBLICATIONS OF THE ASTRONOMICAL SOCIETY OF THE PACIFIC"},
      {"name":"JOURNAL OF THE ROYAL STATISTICAL SOCIETY SERIES B-STATISTICAL METHODOLOGY"},
      {"name":"AMERICAN ECONOMIC JOURNAL-ECONOMIC POLICY"},
      {"name":"EUROPEAN CHILD & ADOLESCENT PSYCHIATRY"},
      {"name":"PARKINSONISM & RELATED DISORDERS"},
      {"name":"FORTSCHRITTE DER PHYSIK-PROGRESS OF PHYSICS"},
      {"name":"PSYCHONOMIC BULLETIN & REVIEW"},
      {"name":"ANTIBIOTICS-BASEL"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-DEVELOPMENTAL BIOLOGY"},
      {"name":"SUICIDE AND LIFE-THREATENING BEHAVIOR"},
      {"name":"AMERICAN JOURNAL OF PHYSIOLOGY-HEART AND CIRCULATORY PHYSIOLOGY"},
      {"name":"JAMA OTOLARYNGOLOGY-HEAD & NECK SURGERY"},
      {"name":"JOURNAL OF ORTHOPAEDIC & SPORTS PHYSICAL THERAPY"},
      {"name":"JOURNAL OF GEOPHYSICAL RESEARCH-ATMOSPHERES"},
      {"name":"JOURNAL OF HOSPITALITY & TOURISM RESEARCH"},
      {"name":"VIRUSES-BASEL"},
      {"name":"EUROPEAN HEART JOURNAL-ACUTE CARDIOVASCULAR CARE"},
      {"name":"BIOLOGY-BASEL"},
      {"name":"COMPLEX & INTELLIGENT SYSTEMS"},
      {"name":"EUROPEAN JOURNAL OF MECHANICS A-SOLIDS"},
      {"name":"SURFACE & COATINGS TECHNOLOGY"},
      {"name":"JOURNAL OF SCIENCE-ADVANCED MATERIALS AND DEVICES"},
      {"name":"CANADIAN JOURNAL OF ANESTHESIA-JOURNAL CANADIEN D ANESTHESIE"},
      {"name":"LAND DEGRADATION & DEVELOPMENT"},
      {"name":"SOCIO-ECONOMIC REVIEW"},
      {"name":"EXPERT REVIEW OF ANTI-INFECTIVE THERAPY"},
      {"name":"CHAOS SOLITONS & FRACTALS"},
      {"name":"INTERNATIONAL SMALL BUSINESS JOURNAL-RESEARCHING ENTREPRENEURSHIP"},
      {"name":"REVIEWS IN FISHERIES SCIENCE & AQUACULTURE"},
      {"name":"EUROPEAN CELLS & MATERIALS"},
      {"name":"CHEMICAL ENGINEERING AND PROCESSING-PROCESS INTENSIFICATION"},
      {"name":"AMERICAN JOURNAL OF PHYSIOLOGY-GASTROINTESTINAL AND LIVER PHYSIOLOGY"},
      {"name":"CHEMICO-BIOLOGICAL INTERACTIONS"},
      {"name":"JOURNAL OF ACCOUNTING & ECONOMICS"},
      {"name":"JOURNAL OF GEOPHYSICAL RESEARCH-PLANETS"},
      {"name":"BEST PRACTICE & RESEARCH CLINICAL ENDOCRINOLOGY & METABOLISM"},
      {"name":"HUMAN-CENTRIC COMPUTING AND INFORMATION SCIENCES"},
      {"name":"MOLECULAR PLANT-MICROBE INTERACTIONS"},
      {"name":"CELL ADHESION & MIGRATION"},
      {"name":"PIGMENT CELL & MELANOMA RESEARCH"},
      {"name":"ENVIRONMENT AND PLANNING D-SOCIETY & SPACE"},
      {"name":"INTERNATIONAL JOURNAL OF BIOCHEMISTRY & CELL BIOLOGY"},
      {"name":"COMPUTERS & STRUCTURES"},
      {"name":"CANCER BIOLOGY & THERAPY"},
      {"name":"RETINA-THE JOURNAL OF RETINAL AND VITREOUS DISEASES"},
      {"name":"FOOD & NUTRITION RESEARCH"},
      {"name":"JOURNAL OF HYDROLOGY-REGIONAL STUDIES"},
      {"name":"CIRP ANNALS-MANUFACTURING TECHNOLOGY"},
      {"name":"JOURNAL OF GEOPHYSICAL RESEARCH-SOLID EARTH"},
      {"name":"STRUCTURAL EQUATION MODELING-A MULTIDISCIPLINARY JOURNAL"},
      {"name":"EATING AND WEIGHT DISORDERS-STUDIES ON ANOREXIA BULIMIA AND OBESITY"},
      {"name":"SOCIAL SCIENCE & MEDICINE"},
      {"name":"PRODUCTION PLANNING & CONTROL"},
      {"name":"IEEE ROBOTICS & AUTOMATION MAGAZINE"},
      {"name":"INTERNATIONAL JOURNAL OF ELECTRICAL POWER & ENERGY SYSTEMS"},
      {"name":"COMPUTERS & SECURITY"},
      {"name":"SPACE WEATHER-THE INTERNATIONAL JOURNAL OF RESEARCH AND APPLICATIONS"},
      {"name":"INDUSTRIAL & ENGINEERING CHEMISTRY RESEARCH"},
      {"name":"PHYSICA E-LOW-DIMENSIONAL SYSTEMS & NANOSTRUCTURES"},
      {"name":"JOURNAL OF GEOPHYSICAL RESEARCH-OCEANS"},
      {"name":"JOURNAL OF GEOPHYSICAL RESEARCH-EARTH SURFACE"},
      {"name":"BIOMASS & BIOENERGY"},
      {"name":"SEMINARS IN FETAL & NEONATAL MEDICINE"},
      {"name":"BONE & JOINT RESEARCH"},
      {"name":"INTERNATIONAL JOURNAL OF ENTREPRENEURIAL BEHAVIOR & RESEARCH"},
      {"name":"EXPERT REVIEW OF GASTROENTEROLOGY & HEPATOLOGY"},
      {"name":"INTERNET INTERVENTIONS-THE APPLICATION OF INFORMATION TECHNOLOGY IN MENTAL AND BEHAVIOURAL HEALTH"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-GENE REGULATORY MECHANISMS"},
      {"name":"NEUROLOGY-GENETICS"},
      {"name":"JOURNALS OF GERONTOLOGY SERIES B-PSYCHOLOGICAL SCIENCES AND SOCIAL SCIENCES"},
      {"name":"STRUCTURAL CONTROL & HEALTH MONITORING"},
      {"name":"ACCOUNTING AUDITING & ACCOUNTABILITY JOURNAL"},
      {"name":"EXPERIMENTAL HEMATOLOGY & ONCOLOGY"},
      {"name":"PROGRESS IN PHYSICAL GEOGRAPHY-EARTH AND ENVIRONMENT"},
      {"name":"EUROPEAN JOURNAL OF CARDIO-THORACIC SURGERY"},
      {"name":"AMERICAN JOURNAL OF PHYSIOLOGY-CELL PHYSIOLOGY"},
      {"name":"JAIDS-JOURNAL OF ACQUIRED IMMUNE DEFICIENCY SYNDROMES"},
      {"name":"BIOMOLECULES & THERAPEUTICS"},
      {"name":"EXPERT OPINION ON DRUG METABOLISM & TOXICOLOGY"},
      {"name":"INVESTIGATIVE OPHTHALMOLOGY & VISUAL SCIENCE"},
      {"name":"AMERICAN JOURNAL OF PHYSIOLOGY-ENDOCRINOLOGY AND METABOLISM"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-BIOENERGETICS"},
      {"name":"INTERNATIONAL JOURNAL OF REFRIGERATION-REVUE INTERNATIONALE DU FROID"},
      {"name":"NDT & E INTERNATIONAL"},
      {"name":"CLUSTER COMPUTING-THE JOURNAL OF NETWORKS SOFTWARE TOOLS AND APPLICATIONS"},
      {"name":"CELLULAR & MOLECULAR BIOLOGY LETTERS"},
      {"name":"ENVIRONMENTAL SCIENCE-WATER RESEARCH & TECHNOLOGY"},
      {"name":"GENES CHROMOSOMES & CANCER"},
      {"name":"GM CROPS & FOOD-BIOTECHNOLOGY IN AGRICULTURE AND THE FOOD CHAIN"},
      {"name":"REACTION CHEMISTRY & ENGINEERING"},
      {"name":"AMERICAN ECONOMIC JOURNAL-MACROECONOMICS"},
      {"name":"COMPUTERS & OPERATIONS RESEARCH"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-GENERAL SUBJECTS"},
      {"name":"ENERGY & FUELS"},
      {"name":"PERSONALITY DISORDERS-THEORY RESEARCH AND TREATMENT"},
      {"name":"EARTHQUAKE ENGINEERING & STRUCTURAL DYNAMICS"},
      {"name":"ORGANIC & BIOMOLECULAR CHEMISTRY"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-BIOMEMBRANES"},
      {"name":"JOURNAL OF GEOPHYSICAL RESEARCH-BIOGEOSCIENCES"},
      {"name":"INTERNATIONAL JOURNAL OF REFRACTORY METALS & HARD MATERIALS"},
      {"name":"IEEE-ACM TRANSACTIONS ON AUDIO SPEECH AND LANGUAGE PROCESSING"},
      {"name":"AMERICAN JOURNAL OF MEDICAL GENETICS PART B-NEUROPSYCHIATRIC GENETICS"},
      {"name":"MOLECULAR DIAGNOSIS & THERAPY"},
      {"name":"ARCHAEA-AN INTERNATIONAL MICROBIOLOGICAL JOURNAL"},
      {"name":"REGULATION & GOVERNANCE"},
      {"name":"IEEE TRANSACTIONS ON HUMAN-MACHINE SYSTEMS"},
      {"name":"CTS-CLINICAL AND TRANSLATIONAL SCIENCE"},
      {"name":"COMPUTERS & MATHEMATICS WITH APPLICATIONS"},
      {"name":"ENVIRONMENTAL TECHNOLOGY & INNOVATION"},
      {"name":"CHEMICAL ENGINEERING RESEARCH & DESIGN"},
      {"name":"MOLECULAR BIOSYSTEMS"},
      {"name":"GEOMATICS NATURAL HAZARDS & RISK"},
      {"name":"ORGANIZATION & ENVIRONMENT"},
      {"name":"REACTIVE & FUNCTIONAL POLYMERS"},
      {"name":"INDUSTRIAL MANAGEMENT & DATA SYSTEMS"},
      {"name":"MOLECULAR SYSTEMS DESIGN & ENGINEERING"},
      {"name":"IEEE TRANSACTIONS ON CIRCUITS AND SYSTEMS I-REGULAR PAPERS"},
      {"name":"IEEE-ACM TRANSACTIONS ON NETWORKING"},
      {"name":"DIABETES-METABOLISM RESEARCH AND REVIEWS"},
      {"name":"CANADIAN JOURNAL OF PSYCHIATRY-REVUE CANADIENNE DE PSYCHIATRIE"},
      {"name":"PALEOCEANOGRAPHY"},
      {"name":"NITRIC OXIDE-BIOLOGY AND CHEMISTRY"},
      {"name":"JOURNAL OF PHOTOCHEMISTRY AND PHOTOBIOLOGY A-CHEMISTRY"},
      {"name":"MENOPAUSE-THE JOURNAL OF THE NORTH AMERICAN MENOPAUSE SOCIETY"},
      {"name":"SCIENCE CHINA-INFORMATION SCIENCES"},
      {"name":"BRAIN STRUCTURE & FUNCTION"},
      {"name":"HEALTH & PLACE"},
      {"name":"CANCER GENOMICS & PROTEOMICS"},
      {"name":"PHILOSOPHICAL TRANSACTIONS OF THE ROYAL SOCIETY A-MATHEMATICAL PHYSICAL AND ENGINEERING SCIENCES"},
      {"name":"JOURNAL OF NEURO-ONCOLOGY"},
      {"name":"PHARMACOGENOMICS & PERSONALIZED MEDICINE"},
      {"name":"SCANDINAVIAN JOURNAL OF MEDICINE & SCIENCE IN SPORTS"},
      {"name":"BEHAVIOR ANALYST"},
      {"name":"SCIENCE CHINA-EARTH SCIENCES"},
      {"name":"BIOSENSORS-BASEL"},
      {"name":"ENVIRONMENTAL SCIENCE-PROCESSES & IMPACTS"},
      {"name":"SPECTROCHIMICA ACTA PART A-MOLECULAR AND BIOMOLECULAR SPECTROSCOPY"},
      {"name":"PATIENT-PATIENT CENTERED OUTCOMES RESEARCH"},
      {"name":"ENGINEERING SCIENCE AND TECHNOLOGY-AN INTERNATIONAL JOURNAL-JESTECH"},
      {"name":"ECONOMICS OF ENERGY & ENVIRONMENTAL POLICY"},
      {"name":"STRUCTURAL DYNAMICS-US"},
      {"name":"NUTRITION & METABOLISM"},
      {"name":"PLASMA SOURCES SCIENCE & TECHNOLOGY"},
      {"name":"AMERICAN JOURNAL OF PHYSIOLOGY-RENAL PHYSIOLOGY"},
      {"name":"BMJ OPEN DIABETES RESEARCH & CARE"},
      {"name":"JOURNAL OF ENERGY RESOURCES TECHNOLOGY-TRANSACTIONS OF THE ASME"},
      {"name":"PUBLIC HEALTH NUTRITION"},
      {"name":"INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY-PERSPECTIVES ON SCIENCE AND PRACTICE"},
      {"name":"JOURNAL OF EXPERIMENTAL PSYCHOLOGY-GENERAL"},
      {"name":"JOURNAL OF PHYSICS D-APPLIED PHYSICS"},
      {"name":"INTERNATIONAL JOURNAL OF HUMAN-COMPUTER STUDIES"},
      {"name":"PFLUGERS ARCHIV-EUROPEAN JOURNAL OF PHYSIOLOGY"},
      {"name":"COMPUTER-AIDED DESIGN"},
      {"name":"MARITIME POLICY & MANAGEMENT"},
      {"name":"JPAD-JOURNAL OF PREVENTION OF ALZHEIMERS DISEASE"},
      {"name":"ACM TRANSACTIONS ON COMPUTER-HUMAN INTERACTION"},
      {"name":"JOURNAL OF VASCULAR SURGERY-VENOUS AND LYMPHATIC DISORDERS"},
      {"name":"PRECISION ENGINEERING-JOURNAL OF THE INTERNATIONAL SOCIETIES FOR PRECISION ENGINEERING AND NANOTECHNOLOGY"},
      {"name":"HEMATOLOGY-ONCOLOGY CLINICS OF NORTH AMERICA"},
      {"name":"STRESS-THE INTERNATIONAL JOURNAL ON THE BIOLOGY OF STRESS"},
      {"name":"DIABETES & METABOLISM JOURNAL"},
      {"name":"PARTICLE & PARTICLE SYSTEMS CHARACTERIZATION"},
      {"name":"ADDICTION SCIENCE & CLINICAL PRACTICE"},
      {"name":"BURNS & TRAUMA"},
      {"name":"SPECTROCHIMICA ACTA PART B-ATOMIC SPECTROSCOPY"},
      {"name":"BIOORGANIC & MEDICINAL CHEMISTRY"},
      {"name":"SOCIOLOGY-THE JOURNAL OF THE BRITISH SOCIOLOGICAL ASSOCIATION"},
      {"name":"SUPERCONDUCTOR SCIENCE & TECHNOLOGY"},
      {"name":"BMC CELL BIOLOGY"},
      {"name":"PURINERGIC SIGNALLING"},
      {"name":"FOOD STRUCTURE-NETHERLANDS"},
      {"name":"BIOINSPIRATION & BIOMIMETICS"},
      {"name":"FUNCTIONAL & INTEGRATIVE GENOMICS"},
      {"name":"JOURNAL OF QUANTITATIVE SPECTROSCOPY & RADIATIVE TRANSFER"},
      {"name":"MATHEMATICAL MODELS & METHODS IN APPLIED SCIENCES"},
      {"name":"JOURNAL OF ENVIRONMENTAL POLICY & PLANNING"},
      {"name":"ALCOHOLISM-CLINICAL AND EXPERIMENTAL RESEARCH"},
      {"name":"ENVIRONMENT AND PLANNING A-ECONOMY AND SPACE"},
      {"name":"FATIGUE & FRACTURE OF ENGINEERING MATERIALS & STRUCTURES"},
      {"name":"CLINICAL & EXPERIMENTAL METASTASIS"},
      {"name":"AMERICAN JOURNAL OF PHYSIOLOGY-REGULATORY INTEGRATIVE AND COMPARATIVE PHYSIOLOGY"},
      {"name":"ORGANIC PROCESS RESEARCH & DEVELOPMENT"},
      {"name":"JOURNAL OF MANAGED CARE & SPECIALTY PHARMACY"},
      {"name":"IEEE-ACM TRANSACTIONS ON COMPUTATIONAL BIOLOGY AND BIOINFORMATICS"},
      {"name":"BOUNDARY-LAYER METEOROLOGY"},
      {"name":"COMPARATIVE BIOCHEMISTRY AND PHYSIOLOGY D-GENOMICS & PROTEOMICS"},
      {"name":"JOURNAL OF NEUROSURGERY-SPINE"},
      {"name":"INTERNATIONAL JOURNAL FOR PARASITOLOGY-DRUGS AND DRUG RESISTANCE"},
      {"name":"POLISH ARCHIVES OF INTERNAL MEDICINE-POLSKIE ARCHIWUM MEDYCYNY WEWNETRZNEJ"},
      {"name":"PSYCHO-ONCOLOGY"},
      {"name":"JOURNAL OF CHROMATOGRAPHY B-ANALYTICAL TECHNOLOGIES IN THE BIOMEDICAL AND LIFE SCIENCES"},
      {"name":"HEMATOLOGY-AMERICAN SOCIETY OF HEMATOLOGY EDUCATION PROGRAM"},
      {"name":"PROCEEDINGS OF THE JAPAN ACADEMY SERIES B-PHYSICAL AND BIOLOGICAL SCIENCES"},
      {"name":"COMPUTERS & GEOSCIENCES"},
      {"name":"LIFE-BASEL"},
      {"name":"LEUKEMIA & LYMPHOMA"},
      {"name":"INNOVATION-ORGANIZATION & MANAGEMENT"},
      {"name":"NUMERICAL HEAT TRANSFER PART A-APPLICATIONS"},
      {"name":"JOURNAL OF BUSINESS & ECONOMIC STATISTICS"},
      {"name":"JOURNAL OF POLYMER SCIENCE PART A-POLYMER CHEMISTRY"},
      {"name":"JOURNAL OF NON-CRYSTALLINE SOLIDS"},
      {"name":"AEU-INTERNATIONAL JOURNAL OF ELECTRONICS AND COMMUNICATIONS"},
      {"name":"PHYSICA A-STATISTICAL MECHANICS AND ITS APPLICATIONS"},
      {"name":"TOTAL QUALITY MANAGEMENT & BUSINESS EXCELLENCE"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-ENERGY AND ENVIRONMENT"},
      {"name":"R & D MANAGEMENT"},
      {"name":"SENSORS AND ACTUATORS A-PHYSICAL"},
      {"name":"GOVERNANCE-AN INTERNATIONAL JOURNAL OF POLICY ADMINISTRATION AND INSTITUTIONS"},
      {"name":"COMPARATIVE BIOCHEMISTRY AND PHYSIOLOGY C-TOXICOLOGY & PHARMACOLOGY"},
      {"name":"CELL STRESS & CHAPERONES"},
      {"name":"WORLD WIDE WEB-INTERNET AND WEB INFORMATION SYSTEMS"},
      {"name":"UROLOGIC ONCOLOGY-SEMINARS AND ORIGINAL INVESTIGATIONS"},
      {"name":"JOURNAL OF MANUFACTURING SCIENCE AND ENGINEERING-TRANSACTIONS OF THE ASME"},
      {"name":"CHEMIE DER ERDE-GEOCHEMISTRY"},
      {"name":"INTERNATIONAL JOURNAL OF NUMERICAL METHODS FOR HEAT & FLUID FLOW"},
      {"name":"SPORTS HEALTH-A MULTIDISCIPLINARY APPROACH"},
      {"name":"METEORITICS & PLANETARY SCIENCE"},
      {"name":"IET GENERATION TRANSMISSION & DISTRIBUTION"},
      {"name":"RESEARCH IN SOCIAL & ADMINISTRATIVE PHARMACY"},
      {"name":"DIABETES METABOLIC SYNDROME AND OBESITY-TARGETS AND THERAPY"},
      {"name":"EUROPEAN JOURNAL OF CLINICAL MICROBIOLOGY & INFECTIOUS DISEASES"},
      {"name":"JOURNAL OF EXPERIMENTAL PSYCHOLOGY-LEARNING MEMORY AND COGNITION"},
      {"name":"JOURNAL OF BIOMEDICAL MATERIALS RESEARCH PART B-APPLIED BIOMATERIALS"},
      {"name":"PHOTOCHEMICAL & PHOTOBIOLOGICAL SCIENCES"},
      {"name":"PROTEINS-STRUCTURE FUNCTION AND BIOINFORMATICS"},
      {"name":"PATHOLOGY & ONCOLOGY RESEARCH"},
      {"name":"PHYSIOLOGY & BEHAVIOR"},
      {"name":"DRUGS & AGING"},
      {"name":"JOURNAL OF INDUSTRIAL MICROBIOLOGY & BIOTECHNOLOGY"},
      {"name":"PARASITES & VECTORS"},
      {"name":"WILEY INTERDISCIPLINARY REVIEWS-COGNITIVE SCIENCE"},
      {"name":"ENVIRONMENT AND PLANNING B-URBAN ANALYTICS AND CITY SCIENCE"},
      {"name":"PHYSICS-USPEKHI"},
      {"name":"JOURNAL OF VESTIBULAR RESEARCH-EQUILIBRIUM & ORIENTATION"},
      {"name":"IEEE TRANSACTIONS ON CIRCUITS AND SYSTEMS II-EXPRESS BRIEFS"},
      {"name":"THEORY CULTURE & SOCIETY"},
      {"name":"COMPUTER STANDARDS & INTERFACES"},
      {"name":"APPLIED PSYCHOLOGY-AN INTERNATIONAL REVIEW-PSYCHOLOGIE APPLIQUEE-REVUE INTERNATIONALE"},
      {"name":"SOCIAL MEDIA + SOCIETY"},
      {"name":"IMMUNITY & AGEING"},
      {"name":"JOURNAL OF INFLAMMATION-LONDON"},
      {"name":"JOURNAL OF GEOPHYSICAL RESEARCH-SPACE PHYSICS"},
      {"name":"SUSTAINABLE COMPUTING-INFORMATICS & SYSTEMS"},
      {"name":"PEER-TO-PEER NETWORKING AND APPLICATIONS"},
      {"name":"BEST PRACTICE & RESEARCH CLINICAL HAEMATOLOGY"},
      {"name":"JOURNAL OF NUTRITION HEALTH & AGING"},
      {"name":"JOURNAL OF CLOUD COMPUTING-ADVANCES SYSTEMS AND APPLICATIONS"},
      {"name":"G3-GENES GENOMES GENETICS"},
      {"name":"NONLINEAR ANALYSIS-MODELLING AND CONTROL"},
      {"name":"SIGNAL PROCESSING-IMAGE COMMUNICATION"},
      {"name":"PULMONOLOGY"},
      {"name":"WASTE MANAGEMENT & RESEARCH"},
      {"name":"PLANTS-BASEL"},
      {"name":"SYSTEMS & CONTROL LETTERS"},
      {"name":"JARO-JOURNAL OF THE ASSOCIATION FOR RESEARCH IN OTOLARYNGOLOGY"},
      {"name":"FUNDAMENTAL & CLINICAL PHARMACOLOGY"},
      {"name":"TICKS AND TICK-BORNE DISEASES"},
      {"name":"FRONTIERS IN BIOSCIENCE-LANDMARK"},
      {"name":"GENDER & SOCIETY"},
      {"name":"PROCEEDINGS OF THE ROYAL SOCIETY A-MATHEMATICAL PHYSICAL AND ENGINEERING SCIENCES"},
      {"name":"DARU-JOURNAL OF PHARMACEUTICAL SCIENCES"},
      {"name":"CLINICAL & TRANSLATIONAL ONCOLOGY"},
      {"name":"MINI-REVIEWS IN MEDICINAL CHEMISTRY"},
      {"name":"BEST PRACTICE & RESEARCH IN CLINICAL RHEUMATOLOGY"},
      {"name":"JOURNAL OF TURBOMACHINERY-TRANSACTIONS OF THE ASME"},
      {"name":"INDIAN JOURNAL OF DERMATOLOGY VENEREOLOGY & LEPROLOGY"},
      {"name":"DIABETOLOGY & METABOLIC SYNDROME"},
      {"name":"ANAESTHESIA CRITICAL CARE & PAIN MEDICINE"},
      {"name":"DIABETES & VASCULAR DISEASE RESEARCH"},
      {"name":"BIRTH-ISSUES IN PERINATAL CARE"},
      {"name":"JOURNAL OF PHYSICS-CONDENSED MATTER"},
      {"name":"DEEP-SEA RESEARCH PART II-TOPICAL STUDIES IN OCEANOGRAPHY"},
      {"name":"JOURNAL OF BIOMATERIALS SCIENCE-POLYMER EDITION"},
      {"name":"BMJ SUPPORTIVE & PALLIATIVE CARE"},
      {"name":"PULMONARY PHARMACOLOGY & THERAPEUTICS"},
      {"name":"SYNTHESIS-STUTTGART"},
      {"name":"JOURNAL OF APPLIED MECHANICS-TRANSACTIONS OF THE ASME"},
      {"name":"COMPUTERS & ELECTRICAL ENGINEERING"},
      {"name":"ATTACHMENT & HUMAN DEVELOPMENT"},
      {"name":"PLANNING THEORY & PRACTICE"},
      {"name":"JOURNAL OF TOXICOLOGY AND ENVIRONMENTAL HEALTH-PART A-CURRENT ISSUES"},
      {"name":"BASIC & CLINICAL PHARMACOLOGY & TOXICOLOGY"},
      {"name":"PUNISHMENT & SOCIETY-INTERNATIONAL JOURNAL OF PENOLOGY"},
      {"name":"AQUACULTURE ECONOMICS & MANAGEMENT"},
      {"name":"PSYCHOLOGY AND PSYCHOTHERAPY-THEORY RESEARCH AND PRACTICE"},
      {"name":"SYMMETRY-BASEL"},
      {"name":"ENERGY SCIENCE & ENGINEERING"},
      {"name":"EUROPEAN JOURNAL OF ORAL IMPLANTOLOGY"},
      {"name":"HUMAN VACCINES & IMMUNOTHERAPEUTICS"},
      {"name":"CURRENT STEM CELL RESEARCH & THERAPY"},
      {"name":"INTERNATIONAL JOURNAL OF PRESS-POLITICS"},
      {"name":"INTERNATIONAL FORUM OF ALLERGY & RHINOLOGY"},
      {"name":"RECENT PATENTS ON ANTI-CANCER DRUG DISCOVERY"},
      {"name":"TISSUE ENGINEERING PART C-METHODS"},
      {"name":"DEEP-SEA RESEARCH PART I-OCEANOGRAPHIC RESEARCH PAPERS"},
      {"name":"AGRONOMY-BASEL"},
      {"name":"FOOD ADDITIVES & CONTAMINANTS PART B-SURVEILLANCE"},
      {"name":"MOBILE NETWORKS & APPLICATIONS"},
      {"name":"ENVIRONMENT AND PLANNING C-POLITICS AND SPACE"},
      {"name":"PSYCHOLOGICAL TRAUMA-THEORY RESEARCH PRACTICE AND POLICY"},
      {"name":"DATABASE-THE JOURNAL OF BIOLOGICAL DATABASES AND CURATION"},
      {"name":"PALAEONTOGRAPHICA ABTEILUNG A-PALAOZOOLOGIE-STRATIGRAPHIE"},
      {"name":"CONTACT LENS & ANTERIOR EYE"},
      {"name":"AQUATIC CONSERVATION-MARINE AND FRESHWATER ECOSYSTEMS"},
      {"name":"BIOORGANIC & MEDICINAL CHEMISTRY LETTERS"},
      {"name":"JOURNAL OF HEALTH SERVICES RESEARCH & POLICY"},
      {"name":"CHILD ABUSE & NEGLECT"},
      {"name":"ETHNICITY & HEALTH"},
      {"name":"JOURNAL OF INTERNATIONAL FINANCIAL MARKETS INSTITUTIONS & MONEY"},
      {"name":"NEUROPHYSIOLOGIE CLINIQUE-CLINICAL NEUROPHYSIOLOGY"},
      {"name":"JOURNAL OF ENVIRONMENTAL SCIENCE AND HEALTH PART C-ENVIRONMENTAL CARCINOGENESIS & ECOTOXICOLOGY REVIEWS"},
      {"name":"CHEMICAL BIOLOGY & DRUG DESIGN"},
      {"name":"JOURNAL OF COMPUTER-AIDED MOLECULAR DESIGN"},
      {"name":"MOBILE MEDIA & COMMUNICATION"},
      {"name":"JCMS-JOURNAL OF COMMON MARKET STUDIES"},
      {"name":"BRITISH JOURNAL OF POLITICS & INTERNATIONAL RELATIONS"},
      {"name":"HEAD AND NECK-JOURNAL FOR THE SCIENCES AND SPECIALTIES OF THE HEAD AND NECK"},
      {"name":"JOURNAL OF NON-NEWTONIAN FLUID MECHANICS"},
      {"name":"GROUP PROCESSES & INTERGROUP RELATIONS"},
      {"name":"SUSTAINABLE ENERGY GRIDS & NETWORKS"},
      {"name":"IEEE JOURNAL OF TRANSLATIONAL ENGINEERING IN HEALTH AND MEDICINE-JTEHM"},
      {"name":"OMICS-A JOURNAL OF INTEGRATIVE BIOLOGY"},
      {"name":"QJM-AN INTERNATIONAL JOURNAL OF MEDICINE"},
      {"name":"EXTRACTIVE INDUSTRIES AND SOCIETY-AN INTERNATIONAL JOURNAL"},
      {"name":"PSYCHOLOGY & HEALTH"},
      {"name":"GROUP & ORGANIZATION MANAGEMENT"},
      {"name":"BRQ-BUSINESS RESEARCH QUARTERLY"},
      {"name":"SEIZURE-EUROPEAN JOURNAL OF EPILEPSY"},
      {"name":"SURGICAL ONCOLOGY-OXFORD"},
      {"name":"CURRENT PROTEIN & PEPTIDE SCIENCE"},
      {"name":"TRANSPORTATION RESEARCH PART F-TRAFFIC PSYCHOLOGY AND BEHAVIOUR"},
      {"name":"SOCIAL & CULTURAL GEOGRAPHY"},
      {"name":"JOURNAL OF NEURO-OPHTHALMOLOGY"},
      {"name":"INTERNATIONAL ENVIRONMENTAL AGREEMENTS-POLITICS LAW AND ECONOMICS"},
      {"name":"EPILEPSY & BEHAVIOR"},
      {"name":"MUTATION RESEARCH-GENETIC TOXICOLOGY AND ENVIRONMENTAL MUTAGENESIS"},
      {"name":"MUSCLE & NERVE"},
      {"name":"COPD-JOURNAL OF CHRONIC OBSTRUCTIVE PULMONARY DISEASE"},
      {"name":"JOURNAL OF BUSINESS & INDUSTRIAL MARKETING"},
      {"name":"INFORMATION TECHNOLOGY & PEOPLE"},
      {"name":"JOURNAL OF ORAL PATHOLOGY & MEDICINE"},
      {"name":"PUBLIC OPINION QUARTERLY"},
      {"name":"CHEMISTRY CENTRAL JOURNAL"},
      {"name":"JOURNAL OF MATERIALS SCIENCE-MATERIALS IN MEDICINE"},
      {"name":"JOURNAL OF POLYMER SCIENCE PART B-POLYMER PHYSICS"},
      {"name":"PHYSICA MEDICA-EUROPEAN JOURNAL OF MEDICAL PHYSICS"},
      {"name":"OCEAN & COASTAL MANAGEMENT"},
      {"name":"AGING & MENTAL HEALTH"},
      {"name":"JOURNAL OF PUBLIC POLICY & MARKETING"},
      {"name":"CLINICAL PSYCHOLOGY & PSYCHOTHERAPY"},
      {"name":"WORLD JOURNAL OF MICROBIOLOGY & BIOTECHNOLOGY"},
      {"name":"APPLIED SCIENCES-BASEL"},
      {"name":"COGNITION & EMOTION"},
      {"name":"LANGUAGE LEARNING & TECHNOLOGY"},
      {"name":"MUTATION RESEARCH-FUNDAMENTAL AND MOLECULAR MECHANISMS OF MUTAGENESIS"},
      {"name":"LIMNOLOGY AND OCEANOGRAPHY-METHODS"},
      {"name":"INTERNATIONAL JOURNAL OF RETAIL & DISTRIBUTION MANAGEMENT"},
      {"name":"PHOTONICS AND NANOSTRUCTURES-FUNDAMENTALS AND APPLICATIONS"},
      {"name":"JOURNAL OF EXPERIMENTAL PSYCHOLOGY-HUMAN PERCEPTION AND PERFORMANCE"},
      {"name":"RESEARCH-TECHNOLOGY MANAGEMENT"},
      {"name":"EDUCATIONAL MANAGEMENT ADMINISTRATION & LEADERSHIP"},
      {"name":"JOURNAL OF INTEGRATIVE MEDICINE-JIM"},
      {"name":"DOSE-RESPONSE"},
      {"name":"HORMONES & CANCER"},
      {"name":"JOURNAL OF EXPERIMENTAL PSYCHOLOGY-APPLIED"},
      {"name":"HEPATOBILIARY & PANCREATIC DISEASES INTERNATIONAL"},
      {"name":"JOURNAL OF EVIDENCE-BASED DENTAL PRACTICE"},
      {"name":"ADSORPTION SCIENCE & TECHNOLOGY"},
      {"name":"TRANSPORTMETRICA A-TRANSPORT SCIENCE"},
      {"name":"PSYCHOLOGICAL RESEARCH-PSYCHOLOGISCHE FORSCHUNG"},
      {"name":"JOURNAL OF TRANSPORT & HEALTH"},
      {"name":"JOURNAL OF PHYSICS G-NUCLEAR AND PARTICLE PHYSICS"},
      {"name":"IEEE DESIGN & TEST"},
      {"name":"ONCOLOGY-NEW YORK"},
      {"name":"COMPUTERS & FLUIDS"},
      {"name":"ANNALS OF ANATOMY-ANATOMISCHER ANZEIGER"},
      {"name":"BRITISH JOURNAL OF MATHEMATICAL & STATISTICAL PSYCHOLOGY"},
      {"name":"PHOTODERMATOLOGY PHOTOIMMUNOLOGY & PHOTOMEDICINE"},
      {"name":"TELEMEDICINE AND E-HEALTH"},
      {"name":"PACIFIC-BASIN FINANCE JOURNAL"},
      {"name":"INFRARED PHYSICS & TECHNOLOGY"},
      {"name":"NETWORKS & SPATIAL ECONOMICS"},
      {"name":"CANCER CAUSES & CONTROL"},
      {"name":"BIOCHIMICA ET BIOPHYSICA ACTA-PROTEINS AND PROTEOMICS"},
      {"name":"PSYCHOLOGY & MARKETING"},
      {"name":"SCANDINAVIAN JOURNAL OF TRAUMA RESUSCITATION & EMERGENCY MEDICINE"},
      {"name":"TRANSFER-EUROPEAN REVIEW OF LABOUR AND RESEARCH"},
      {"name":"NUTRITION AND CANCER-AN INTERNATIONAL JOURNAL"},
      {"name":"JCR-JOURNAL OF CLINICAL RHEUMATOLOGY"},
      {"name":"LEARNING & MEMORY"},
      {"name":"APPLIED PSYCHOLOGY-HEALTH AND WELL BEING"},
      {"name":"CRISIS-THE JOURNAL OF CRISIS INTERVENTION AND SUICIDE PREVENTION"},
      {"name":"JOURNAL OF URBAN HEALTH-BULLETIN OF THE NEW YORK ACADEMY OF MEDICINE"},
      {"name":"PEDIATRIC BLOOD & CANCER"},
      {"name":"GLOBAL HEALTH-SCIENCE AND PRACTICE"},
      {"name":"GAIT & POSTURE"},
      {"name":"ADVANCES IN PRODUCTION ENGINEERING & MANAGEMENT"},
      {"name":"JOURNAL OF VIBRATION AND ACOUSTICS-TRANSACTIONS OF THE ASME"},
      {"name":"OTOLARYNGOLOGY-HEAD AND NECK SURGERY"},
      {"name":"FOOD ADDITIVES AND CONTAMINANTS PART A-CHEMISTRY ANALYSIS CONTROL EXPOSURE & RISK ASSESSMENT"},
      {"name":"PUBLIC UNDERSTANDING OF SCIENCE"},
      {"name":"SCIENCE TECHNOLOGY & HUMAN VALUES"},
      {"name":"JOURNAL OF FAMILY THEORY & REVIEW"},
      {"name":"UHOD-ULUSLARARASI HEMATOLOJI-ONKOLOJI DERGISI"},
      {"name":"PUBLIC RELATIONS REVIEW"},
      {"name":"ASSESSMENT & EVALUATION IN HIGHER EDUCATION"},
      {"name":"INTERNATIONAL JOURNAL OF ORAL & MAXILLOFACIAL IMPLANTS"},
      {"name":"HOUSING THEORY & SOCIETY"},
      {"name":"SOCIOLOGY OF HEALTH & ILLNESS"},
      {"name":"INTERNATIONAL JOURNAL OF NON-LINEAR MECHANICS"},
      {"name":"SURGEON-JOURNAL OF THE ROYAL COLLEGES OF SURGEONS OF EDINBURGH AND IRELAND"},
      {"name":"TROPICAL MEDICINE & INTERNATIONAL HEALTH"},
      {"name":"ETR&D-EDUCATIONAL TECHNOLOGY RESEARCH AND DEVELOPMENT"},
      {"name":"SCIENCE CHINA-TECHNOLOGICAL SCIENCES"},
      {"name":"CLINICAL LYMPHOMA MYELOMA & LEUKEMIA"},
      {"name":"JOURNAL OF IMMIGRANT & REFUGEE STUDIES"},
      {"name":"CORPORATE GOVERNANCE-AN INTERNATIONAL REVIEW"},
      {"name":"FOOT & ANKLE INTERNATIONAL"},
      {"name":"PHYSICA STATUS SOLIDI-RAPID RESEARCH LETTERS"},
      {"name":"JOURNAL OF HAND SURGERY-EUROPEAN VOLUME"},
      {"name":"ADVANCES IN BIOCHEMICAL ENGINEERING-BIOTECHNOLOGY"},
      {"name":"ENVIRONMENTAL & RESOURCE ECONOMICS"},
      {"name":"PROSTAGLANDINS & OTHER LIPID MEDIATORS"},
      {"name":"JOURNAL OF INTERNATIONAL FINANCIAL MANAGEMENT & ACCOUNTING"},
      {"name":"TELLUS SERIES B-CHEMICAL AND PHYSICAL METEOROLOGY"},
      {"name":"DRUGS IN R&D"},
      {"name":"EUROPEAN JOURNAL OF POPULATION-REVUE EUROPEENNE DE DEMOGRAPHIE"},
      {"name":"JOURNAL OF BANKING & FINANCE"},
      {"name":"GREY SYSTEMS-THEORY AND APPLICATION"},
      {"name":"ADVANCES IN CHEMICAL PHYSICS"},
      {"name":"ASSESSMENT IN EDUCATION-PRINCIPLES POLICY & PRACTICE"},
      {"name":"ANTI-CANCER DRUGS"},
      {"name":"JOURNAL OF INTELLIGENT & ROBOTIC SYSTEMS"},
      {"name":"EUROPEAN JOURNAL OF GASTROENTEROLOGY & HEPATOLOGY"},
      {"name":"VECTOR-BORNE AND ZOONOTIC DISEASES"},
      {"name":"JOURNAL OF THE AIR & WASTE MANAGEMENT ASSOCIATION"},
      {"name":"CBE-LIFE SCIENCES EDUCATION"},
      {"name":"ISPRS INTERNATIONAL JOURNAL OF GEO-INFORMATION"},
      {"name":"JOURNAL OF SPORT & EXERCISE PSYCHOLOGY"},
      {"name":"ECONOMIC RESEARCH-EKONOMSKA ISTRAZIVANJA"},
      {"name":"CURRENT OPINION IN OBSTETRICS & GYNECOLOGY"},
      {"name":"LIGHTING RESEARCH & TECHNOLOGY"},
      {"name":"STARCH-STARKE"},
      {"name":"GEOMECHANICS AND GEOPHYSICS FOR GEO-ENERGY AND GEO-RESOURCES"},
      {"name":"ADDICTION RESEARCH & THEORY"},
      {"name":"BIOACOUSTICS-THE INTERNATIONAL JOURNAL OF ANIMAL SOUND AND ITS RECORDING"},
      {"name":"JASSS-THE JOURNAL OF ARTIFICIAL SOCIETIES AND SOCIAL SIMULATION"},
      {"name":"HUMAN NATURE-AN INTERDISCIPLINARY BIOSOCIAL PERSPECTIVE"},
      {"name":"JOURNAL OF MATERIALS SCIENCE-MATERIALS IN ELECTRONICS"},
      {"name":"COMPARATIVE BIOCHEMISTRY AND PHYSIOLOGY B-BIOCHEMISTRY & MOLECULAR BIOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF GYNECOLOGY & OBSTETRICS"},
      {"name":"JOURNAL OF STATISTICAL MECHANICS-THEORY AND EXPERIMENT"},
      {"name":"TRANSPORTMETRICA B-TRANSPORT DYNAMICS"},
      {"name":"BILINGUALISM-LANGUAGE AND COGNITION"},
      {"name":"JOURNAL OF THE ROYAL STATISTICAL SOCIETY SERIES A-STATISTICS IN SOCIETY"},
      {"name":"COGNITIVE AFFECTIVE & BEHAVIORAL NEUROSCIENCE"},
      {"name":"PULMONARY CIRCULATION"},
      {"name":"AUTONOMIC NEUROSCIENCE-BASIC & CLINICAL"},
      {"name":"JOURNAL OF LEADERSHIP & ORGANIZATIONAL STUDIES"},
      {"name":"ASIAN BUSINESS & MANAGEMENT"},
      {"name":"CRIME & DELINQUENCY"},
      {"name":"COMPETITION & CHANGE"},
      {"name":"JOURNAL OF HISTOCHEMISTRY & CYTOCHEMISTRY"},
      {"name":"JOURNAL OF PROSTHODONTICS-IMPLANT ESTHETIC AND RECONSTRUCTIVE DENTISTRY"},
      {"name":"HYDROLOGICAL SCIENCES JOURNAL-JOURNAL DES SCIENCES HYDROLOGIQUES"},
      {"name":"JOURNAL OF THE OPTICAL SOCIETY OF AMERICA B-OPTICAL PHYSICS"},
      {"name":"PROGRESS IN BIOPHYSICS & MOLECULAR BIOLOGY"},
      {"name":"IEEE TRANSACTIONS ON COMPUTER-AIDED DESIGN OF INTEGRATED CIRCUITS AND SYSTEMS"},
      {"name":"POST-SOVIET AFFAIRS"},
      {"name":"JOURNAL OF VACUUM SCIENCE & TECHNOLOGY A"},
      {"name":"MARKETING INTELLIGENCE & PLANNING"},
      {"name":"SOLDERING & SURFACE MOUNT TECHNOLOGY"},
      {"name":"RESEARCH IN NURSING & HEALTH"},
      {"name":"SCHOOL PSYCHOLOGY QUARTERLY"},
      {"name":"JOURNAL OF NON-EQUILIBRIUM THERMODYNAMICS"},
      {"name":"ORGANISMS DIVERSITY & EVOLUTION"},
      {"name":"PAIN RESEARCH & MANAGEMENT"},
      {"name":"INTERNATIONAL JOURNAL OF LOGISTICS-RESEARCH AND APPLICATIONS"},
      {"name":"CULTURAL DIVERSITY & ETHNIC MINORITY PSYCHOLOGY"},
      {"name":"MICROBIOLOGY-SGM"},
      {"name":"AMERICAN JOURNAL OF HEALTH-SYSTEM PHARMACY"},
      {"name":"CLINICAL MEDICINE INSIGHTS-ONCOLOGY"},
      {"name":"EUROPEAN JOURNAL OF MECHANICS B-FLUIDS"},
      {"name":"SEXUAL ABUSE-A JOURNAL OF RESEARCH AND TREATMENT"},
      {"name":"HIGHER EDUCATION RESEARCH & DEVELOPMENT"},
      {"name":"BMJ SEXUAL & REPRODUCTIVE HEALTH"},
      {"name":"JOURNAL OF NUTRIGENETICS AND NUTRIGENOMICS"},
      {"name":"THINKING & REASONING"},
      {"name":"TREES-STRUCTURE AND FUNCTION"},
      {"name":"JOURNAL OF HAND SURGERY-AMERICAN VOLUME"},
      {"name":"JOURNALISM & MASS COMMUNICATION QUARTERLY"},
      {"name":"JOURNAL OF BIOLOGICAL RESEARCH-THESSALONIKI"},
      {"name":"JOURNAL OF NEUROSURGERY-PEDIATRICS"},
      {"name":"HUMAN PSYCHOPHARMACOLOGY-CLINICAL AND EXPERIMENTAL"},
      {"name":"TRANSLATIONAL VISION SCIENCE & TECHNOLOGY"},
      {"name":"AUDITING-A JOURNAL OF PRACTICE & THEORY"},
      {"name":"INJURY-INTERNATIONAL JOURNAL OF THE CARE OF THE INJURED"},
      {"name":"RESEARCH IN ORGANIZATIONAL BEHAVIOR"},
      {"name":"JOURNAL OF HYDRO-ENVIRONMENT RESEARCH"},
      {"name":"ALZHEIMER DISEASE & ASSOCIATED DISORDERS"},
      {"name":"PREGNANCY HYPERTENSION-AN INTERNATIONAL JOURNAL OF WOMENS CARDIOVASCULAR HEALTH"},
      {"name":"RANGELAND ECOLOGY & MANAGEMENT"},
      {"name":"INTERNATIONAL JOURNAL OF COMPUTERS COMMUNICATIONS & CONTROL"},
      {"name":"ACTA METALLURGICA SINICA-ENGLISH LETTERS"},
      {"name":"EDUCATIONAL TECHNOLOGY & SOCIETY"},
      {"name":"JOURNAL OF ZHEJIANG UNIVERSITY-SCIENCE B"},
      {"name":"OZONE-SCIENCE & ENGINEERING"},
      {"name":"TREE GENETICS & GENOMES"},
      {"name":"JOURNAL OF MOLECULAR GRAPHICS & MODELLING"},
      {"name":"CHILDREN-BASEL"},
      {"name":"CHEMICAL SPECIATION AND BIOAVAILABILITY"},
      {"name":"SCIENCE & JUSTICE"},
      {"name":"TECHNOLOGY IN CANCER RESEARCH & TREATMENT"},
      {"name":"AGRICULTURE-BASEL"},
      {"name":"NONLINEAR ANALYSIS-REAL WORLD APPLICATIONS"},
      {"name":"CYTOMETRY PART B-CLINICAL CYTOMETRY"},
      {"name":"CANADIAN PSYCHOLOGY-PSYCHOLOGIE CANADIENNE"},
      {"name":"HUMAN & EXPERIMENTAL TOXICOLOGY"},
      {"name":"PSYCHIATRY RESEARCH-NEUROIMAGING"},
      {"name":"OBESITY RESEARCH & CLINICAL PRACTICE"},
      {"name":"EXPERIMENTAL AND CLINICAL ENDOCRINOLOGY & DIABETES"},
      {"name":"JOURNAL OF FLUIDS ENGINEERING-TRANSACTIONS OF THE ASME"},
      {"name":"COLLEGE & RESEARCH LIBRARIES"},
      {"name":"PHARMACOLOGY RESEARCH & PERSPECTIVES"},
      {"name":"HEALTH & SOCIAL CARE IN THE COMMUNITY"},
      {"name":"METALLURGICAL AND MATERIALS TRANSACTIONS A-PHYSICAL METALLURGY AND MATERIALS SCIENCE"},
      {"name":"NAUNYN-SCHMIEDEBERGS ARCHIVES OF PHARMACOLOGY"},
      {"name":"ANTI-CANCER AGENTS IN MEDICINAL CHEMISTRY"},
      {"name":"ACTA CRYSTALLOGRAPHICA SECTION B-STRUCTURAL SCIENCE CRYSTAL ENGINEERING AND MATERIALS"},
      {"name":"OPTO-ELECTRONICS REVIEW"},
      {"name":"URBAN RESEARCH & PRACTICE"},
      {"name":"JOURNAL OF COMPARATIVE PHYSIOLOGY B-BIOCHEMICAL SYSTEMS AND ENVIRONMENTAL PHYSIOLOGY"},
      {"name":"CRIMINOLOGY & PUBLIC POLICY"},
      {"name":"IEEE SECURITY & PRIVACY"},
      {"name":"CHEMISTRY & BIODIVERSITY"},
      {"name":"JOURNAL OF EXPERIMENTAL & THEORETICAL ARTIFICIAL INTELLIGENCE"},
      {"name":"IEEE TRANSACTIONS ON VERY LARGE SCALE INTEGRATION (VLSI) SYSTEMS"},
      {"name":"METALLURGICAL AND MATERIALS TRANSACTIONS B-PROCESS METALLURGY AND MATERIALS PROCESSING SCIENCE"},
      {"name":"EXPERT REVIEW OF PHARMACOECONOMICS & OUTCOMES RESEARCH"},
      {"name":"ZEITSCHRIFT FUR PHYSIKALISCHE CHEMIE-INTERNATIONAL JOURNAL OF RESEARCH IN PHYSICAL CHEMISTRY & CHEMICAL PHYSICS"},
      {"name":"GERIATRICS & GERONTOLOGY INTERNATIONAL"},
      {"name":"MEDICAL & BIOLOGICAL ENGINEERING & COMPUTING"},
      {"name":"JOURNAL OF PALAEOGEOGRAPHY-ENGLISH"},
      {"name":"APPLIED MATHEMATICS AND MECHANICS-ENGLISH EDITION"},
      {"name":"JOURNAL OF OUTDOOR RECREATION AND TOURISM-RESEARCH PLANNING AND MANAGEMENT"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART L-JOURNAL OF MATERIALS-DESIGN AND APPLICATIONS"},
      {"name":"SPE RESERVOIR EVALUATION & ENGINEERING"},
      {"name":"ASIA-PACIFIC JOURNAL OF CLINICAL ONCOLOGY"},
      {"name":"JOURNAL OF CROSS-CULTURAL PSYCHOLOGY"},
      {"name":"JOURNAL OF SOL-GEL SCIENCE AND TECHNOLOGY"},
      {"name":"CRIME AND JUSTICE-A REVIEW OF RESEARCH"},
      {"name":"JOURNAL OF APPLIED BIOMATERIALS & FUNCTIONAL MATERIALS"},
      {"name":"JOURNAL OF PSYCHOSOMATIC OBSTETRICS & GYNECOLOGY"},
      {"name":"MEDIA CULTURE & SOCIETY"},
      {"name":"JOURNAL OF PHYSICS A-MATHEMATICAL AND THEORETICAL"},
      {"name":"MOLECULAR GENETICS & GENOMIC MEDICINE"},
      {"name":"JOURNAL OF PHYSICAL ACTIVITY & HEALTH"},
      {"name":"WORLDVIEWS ON EVIDENCE-BASED NURSING"},
      {"name":"ENVIRONMENTAL PROGRESS & SUSTAINABLE ENERGY"},
      {"name":"INFORMATICS FOR HEALTH & SOCIAL CARE"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART B-JOURNAL OF ENGINEERING MANUFACTURE"},
      {"name":"HEALTH SYSTEMS & REFORM"},
      {"name":"GREENHOUSE GASES-SCIENCE AND TECHNOLOGY"},
      {"name":"WATER ALTERNATIVES-AN INTERDISCIPLINARY JOURNAL ON WATER POLITICS AND DEVELOPMENT"},
      {"name":"BIOCHEMISTRY-MOSCOW"},
      {"name":"LEADERSHIP & ORGANIZATION DEVELOPMENT JOURNAL"},
      {"name":"ABACUS-A JOURNAL OF ACCOUNTING FINANCE AND BUSINESS STUDIES"},
      {"name":"IET SCIENCE MEASUREMENT & TECHNOLOGY"},
      {"name":"ACTA GEOLOGICA SINICA-ENGLISH EDITION"},
      {"name":"ENDOCRINE METABOLIC & IMMUNE DISORDERS-DRUG TARGETS"},
      {"name":"POLYMER-PLASTICS TECHNOLOGY AND ENGINEERING"},
      {"name":"REVISTA PORTUGUESA DE PNEUMOLOGIA"},
      {"name":"IET MICROWAVES ANTENNAS & PROPAGATION"},
      {"name":"CULTURE HEALTH & SEXUALITY"},
      {"name":"INFORMATION SYSTEMS AND E-BUSINESS MANAGEMENT"},
      {"name":"JOURNAL OF REAL-TIME IMAGE PROCESSING"},
      {"name":"PALLIATIVE & SUPPORTIVE CARE"},
      {"name":"COMPARATIVE BIOCHEMISTRY AND PHYSIOLOGY A-MOLECULAR & INTEGRATIVE PHYSIOLOGY"},
      {"name":"SIAM-ASA JOURNAL ON UNCERTAINTY QUANTIFICATION"},
      {"name":"HORMONES-INTERNATIONAL JOURNAL OF ENDOCRINOLOGY AND METABOLISM"},
      {"name":"ACTA CRYSTALLOGRAPHICA A-FOUNDATION AND ADVANCES"},
      {"name":"OPEN SYSTEMS & INFORMATION DYNAMICS"},
      {"name":"ADSORPTION-JOURNAL OF THE INTERNATIONAL ADSORPTION SOCIETY"},
      {"name":"MATCH-COMMUNICATIONS IN MATHEMATICAL AND IN COMPUTER CHEMISTRY"},
      {"name":"CALPHAD-COMPUTER COUPLING OF PHASE DIAGRAMS AND THERMOCHEMISTRY"},
      {"name":"JOURNAL OF BIOMECHANICAL ENGINEERING-TRANSACTIONS OF THE ASME"},
      {"name":"JOURNAL OF FOOD SCIENCE AND TECHNOLOGY-MYSORE"},
      {"name":"AMERICAN JOURNAL OF RHINOLOGY & ALLERGY"},
      {"name":"PSYCHOLOGY OF MEN & MASCULINITY"},
      {"name":"JOURNAL OF SPORT & SOCIAL ISSUES"},
      {"name":"JOURNAL OF VETERINARY BEHAVIOR-CLINICAL APPLICATIONS AND RESEARCH"},
      {"name":"COMPUTING IN SCIENCE & ENGINEERING"},
      {"name":"JOURNAL OF MANAGEMENT & ORGANIZATION"},
      {"name":"TELLUS SERIES A-DYNAMIC METEOROLOGY AND OCEANOGRAPHY"},
      {"name":"INTERNATIONAL JOURNAL OF EVIDENCE-BASED HEALTHCARE"},
      {"name":"GLOBAL NETWORKS-A JOURNAL OF TRANSNATIONAL AFFAIRS"},
      {"name":"HEALTH EDUCATION & BEHAVIOR"},
      {"name":"EVOLUTION & DEVELOPMENT"},
      {"name":"JOURNAL OF MECHANISMS AND ROBOTICS-TRANSACTIONS OF THE ASME"},
      {"name":"INTERNATIONAL JOURNAL FOR PARASITOLOGY-PARASITES AND WILDLIFE"},
      {"name":"PURE AND APPLIED CHEMISTRY"},
      {"name":"PHOTOMEDICINE AND LASER SURGERY"},
      {"name":"JOURNAL OF EXPERIMENTAL ZOOLOGY PART A-ECOLOGICAL AND INTEGRATIVE PHYSIOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF BIO-INSPIRED COMPUTATION"},
      {"name":"SOCIAL POLICY & ADMINISTRATION"},
      {"name":"AMERICAN JOURNAL OF CLINICAL ONCOLOGY-CANCER CLINICAL TRIALS"},
      {"name":"GEOARCHAEOLOGY-AN INTERNATIONAL JOURNAL"},
      {"name":"CONSERVATION & SOCIETY"},
      {"name":"REVISTA BRASILEIRA DE PSIQUIATRIA"},
      {"name":"PHYSICA B-CONDENSED MATTER"},
      {"name":"KIDNEY & BLOOD PRESSURE RESEARCH"},
      {"name":"PROGRESS IN ELECTROMAGNETICS RESEARCH-PIER"},
      {"name":"JOURNAL OF EXPERIMENTAL ZOOLOGY PART B-MOLECULAR AND DEVELOPMENTAL EVOLUTION"},
      {"name":"AIDS CARE-PSYCHOLOGICAL AND SOCIO-MEDICAL ASPECTS OF AIDS/HIV"},
      {"name":"INTERNATIONAL JOURNAL OF INFORMATION TECHNOLOGY & DECISION MAKING"},
      {"name":"ATTENTION PERCEPTION & PSYCHOPHYSICS"},
      {"name":"NETHERLANDS JOURNAL OF GEOSCIENCES-GEOLOGIE EN MIJNBOUW"},
      {"name":"CHILD PSYCHIATRY & HUMAN DEVELOPMENT"},
      {"name":"FEMINISM & PSYCHOLOGY"},
      {"name":"HEAD & FACE MEDICINE"},
      {"name":"ECOHYDROLOGY & HYDROBIOLOGY"},
      {"name":"ECONOMICS & HUMAN BIOLOGY"},
      {"name":"SPE PRODUCTION & OPERATIONS"},
      {"name":"DEMENTIA-INTERNATIONAL JOURNAL OF SOCIAL RESEARCH AND PRACTICE"},
      {"name":"CONTRAST MEDIA & MOLECULAR IMAGING"},
      {"name":"GAIA-ECOLOGICAL PERSPECTIVES FOR SCIENCE AND SOCIETY"},
      {"name":"HEALTH PROMOTION AND CHRONIC DISEASE PREVENTION IN CANADA-RESEARCH POLICY AND PRACTICE"},
      {"name":"EUROPEAN JOURNAL OF OBSTETRICS & GYNECOLOGY AND REPRODUCTIVE BIOLOGY"},
      {"name":"ROFO-FORTSCHRITTE AUF DEM GEBIET DER RONTGENSTRAHLEN UND DER BILDGEBENDEN VERFAHREN"},
      {"name":"TECHNOLOGY ANALYSIS & STRATEGIC MANAGEMENT"},
      {"name":"CHINA & WORLD ECONOMY"},
      {"name":"BIOLOGICAL & PHARMACEUTICAL BULLETIN"},
      {"name":"JOURNAL OF TRAUMA & DISSOCIATION"},
      {"name":"JOURNAL OF COMMUNITY & APPLIED SOCIAL PSYCHOLOGY"},
      {"name":"MULTISCALE MODELING & SIMULATION"},
      {"name":"DISABILITY & SOCIETY"},
      {"name":"JOURNAL OF OTOLARYNGOLOGY-HEAD & NECK SURGERY"},
      {"name":"JOURNAL OF INTELLIGENT & FUZZY SYSTEMS"},
      {"name":"ANNALES DE L INSTITUT HENRI POINCARE-ANALYSE NON LINEAIRE"},
      {"name":"COMPUTER LAW & SECURITY REVIEW"},
      {"name":"JOURNAL OF ENVIRONMENT & DEVELOPMENT"},
      {"name":"TRANSPORTATION LETTERS-THE INTERNATIONAL JOURNAL OF TRANSPORTATION RESEARCH"},
      {"name":"AMERICAN JOURNAL OF PHYSICAL MEDICINE & REHABILITATION"},
      {"name":"CROSS CULTURAL & STRATEGIC MANAGEMENT"},
      {"name":"TISSUE & CELL"},
      {"name":"ARTHROPOD STRUCTURE & DEVELOPMENT"},
      {"name":"NEUROLOGIA MEDICO-CHIRURGICA"},
      {"name":"ASIA-PACIFIC JOURNAL OF ATMOSPHERIC SCIENCES"},
      {"name":"VASA-EUROPEAN JOURNAL OF VASCULAR MEDICINE"},
      {"name":"JOURNAL OF TRIBOLOGY-TRANSACTIONS OF THE ASME"},
      {"name":"ECONOMICS & POLITICS"},
      {"name":"MINI-REVIEWS IN ORGANIC CHEMISTRY"},
      {"name":"HIV CLINICAL TRIALS"},
      {"name":"PM&R"},
      {"name":"POPULATION STUDIES-A JOURNAL OF DEMOGRAPHY"},
      {"name":"WIRELESS COMMUNICATIONS & MOBILE COMPUTING"},
      {"name":"INTERNATIONAL REVIEW OF ECONOMICS & FINANCE"},
      {"name":"APPLIED PHYSICS B-LASERS AND OPTICS"},
      {"name":"IN VITRO CELLULAR & DEVELOPMENTAL BIOLOGY-PLANT"},
      {"name":"EVIDENCE-BASED COMPLEMENTARY AND ALTERNATIVE MEDICINE"},
      {"name":"SOCIETY & NATURAL RESOURCES"},
      {"name":"PUBLIC POLICY AND ADMINISTRATION"},
      {"name":"APPLIED PHYSICS A-MATERIALS SCIENCE & PROCESSING"},
      {"name":"DM DISEASE-A-MONTH"},
      {"name":"REVISTA BRASILEIRA DE REUMATOLOGIA"},
      {"name":"CRIMINOLOGY & CRIMINAL JUSTICE"},
      {"name":"EUROPEAN ARCHIVES OF OTO-RHINO-LARYNGOLOGY"},
      {"name":"JOURNAL OF LABELLED COMPOUNDS & RADIOPHARMACEUTICALS"},
      {"name":"ORTHOPAEDICS & TRAUMATOLOGY-SURGERY & RESEARCH"},
      {"name":"PHYSICA D-NONLINEAR PHENOMENA"},
      {"name":"KOREAN JOURNAL OF PHYSIOLOGY & PHARMACOLOGY"},
      {"name":"JOURNAL OF ENGINEERING FOR GAS TURBINES AND POWER-TRANSACTIONS OF THE ASME"},
      {"name":"FOOD SCIENCE & NUTRITION"},
      {"name":"YOUTH & SOCIETY"},
      {"name":"JOURNAL OF THE OPTICAL SOCIETY OF AMERICA A-OPTICS IMAGE SCIENCE AND VISION"},
      {"name":"POLICING-A JOURNAL OF POLICY AND PRACTICE"},
      {"name":"ENVIRONMENTAL COMMUNICATION-A JOURNAL OF NATURE AND CULTURE"},
      {"name":"JOURNAL OF HEAT TRANSFER-TRANSACTIONS OF THE ASME"},
      {"name":"JOURNAL OF STROKE & CEREBROVASCULAR DISEASES"},
      {"name":"SOFTWARE-PRACTICE & EXPERIENCE"},
      {"name":"HEALTHCARE-THE JOURNAL OF DELIVERY SCIENCE AND INNOVATION"},
      {"name":"BEHAVIOUR & INFORMATION TECHNOLOGY"},
      {"name":"RACE & CLASS"},
      {"name":"JOURNAL OF EXPERIMENTAL PSYCHOLOGY-ANIMAL LEARNING AND COGNITION"},
      {"name":"AJIDD-AMERICAN JOURNAL ON INTELLECTUAL AND DEVELOPMENTAL DISABILITIES"},
      {"name":"CHILDHOOD-A GLOBAL JOURNAL OF CHILD RESEARCH"},
      {"name":"ENERGY & ENVIRONMENT"},
      {"name":"JOURNAL OF SEX & MARITAL THERAPY"},
      {"name":"PROTEIN ENGINEERING DESIGN & SELECTION"},
      {"name":"PUBLIC HEALTH"},
      {"name":"APPLIED IMMUNOHISTOCHEMISTRY & MOLECULAR MORPHOLOGY"},
      {"name":"GEOGRAFISKA ANNALER SERIES A-PHYSICAL GEOGRAPHY"},
      {"name":"BMC PHARMACOLOGY & TOXICOLOGY"},
      {"name":"JOURNAL OF INFORMATION TECHNOLOGY & POLITICS"},
      {"name":"AGEING & SOCIETY"},
      {"name":"JOURNAL OF CRANIO-MAXILLOFACIAL SURGERY"},
      {"name":"PUBLIC HEALTH REPORTS"},
      {"name":"COLLABRA-PSYCHOLOGY"},
      {"name":"SIMULATION IN HEALTHCARE-JOURNAL OF THE SOCIETY FOR SIMULATION IN HEALTHCARE"},
      {"name":"PHYSICA STATUS SOLIDI A-APPLICATIONS AND MATERIALS SCIENCE"},
      {"name":"ENERGY SOURCES PART B-ECONOMICS PLANNING AND POLICY"},
      {"name":"MILLENNIUM-JOURNAL OF INTERNATIONAL STUDIES"},
      {"name":"JOURNAL OF MIDWIFERY & WOMENS HEALTH"},
      {"name":"NUTRITION & DIETETICS"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-MARITIME ENGINEERING"},
      {"name":"INTERNATIONAL JOURNAL OF LANGUAGE & COMMUNICATION DISORDERS"},
      {"name":"JOURNAL OF MATERNAL-FETAL & NEONATAL MEDICINE"},
      {"name":"MEDICAL ENGINEERING & PHYSICS"},
      {"name":"MICROSYSTEM TECHNOLOGIES-MICRO-AND NANOSYSTEMS-INFORMATION STORAGE AND PROCESSING SYSTEMS"},
      {"name":"INTERNATIONAL JOURNAL OF SPEECH-LANGUAGE PATHOLOGY"},
      {"name":"PSYCHIATRY-INTERPERSONAL AND BIOLOGICAL PROCESSES"},
      {"name":"DISCOURSE-STUDIES IN THE CULTURAL POLITICS OF EDUCATION"},
      {"name":"ARHIV ZA HIGIJENU RADA I TOKSIKOLOGIJU-ARCHIVES OF INDUSTRIAL HYGIENE AND TOXICOLOGY"},
      {"name":"CANADIAN ASSOCIATION OF RADIOLOGISTS JOURNAL-JOURNAL DE L ASSOCIATION CANADIENNE DES RADIOLOGISTES"},
      {"name":"HERD-HEALTH ENVIRONMENTS RESEARCH & DESIGN JOURNAL"},
      {"name":"JOURNAL OF ENVIRONMENTAL SCIENCE AND HEALTH PART A-TOXIC/HAZARDOUS SUBSTANCES & ENVIRONMENTAL ENGINEERING"},
      {"name":"DEVELOPMENT GROWTH & DIFFERENTIATION"},
      {"name":"CROSS-CULTURAL RESEARCH"},
      {"name":"TREE-RING RESEARCH"},
      {"name":"MARINE GEORESOURCES & GEOTECHNOLOGY"},
      {"name":"CONVERGENCE-THE INTERNATIONAL JOURNAL OF RESEARCH INTO NEW MEDIA TECHNOLOGIES"},
      {"name":"INTERNATIONAL JOURNAL OF HUMAN-COMPUTER INTERACTION"},
      {"name":"ANALYSIS & PDE"},
      {"name":"OTOLOGY & NEUROTOLOGY"},
      {"name":"CULTURE & PSYCHOLOGY"},
      {"name":"PSYCHOLOGY HEALTH & MEDICINE"},
      {"name":"JOURNAL OF PHYSICS B-ATOMIC MOLECULAR AND OPTICAL PHYSICS"},
      {"name":"MARITIME ECONOMICS & LOGISTICS"},
      {"name":"NAVIGATION-JOURNAL OF THE INSTITUTE OF NAVIGATION"},
      {"name":"JOURNAL OF ENVIRONMENTAL SCIENCE AND HEALTH PART B-PESTICIDES FOOD CONTAMINANTS AND AGRICULTURAL WASTES"},
      {"name":"MEMORY & COGNITION"},
      {"name":"JOURNAL OF CONTEMPORARY ACCOUNTING & ECONOMICS"},
      {"name":"PRAMANA-JOURNAL OF PHYSICS"},
      {"name":"IFOREST-BIOGEOSCIENCES AND FORESTRY"},
      {"name":"INFANT BEHAVIOR & DEVELOPMENT"},
      {"name":"E-POLYMERS"},
      {"name":"BIOLOGICAL AGRICULTURE & HORTICULTURE"},
      {"name":"COMPUTER SUPPORTED COOPERATIVE WORK-THE JOURNAL OF COLLABORATIVE COMPUTING AND WORK PRACTICES"},
      {"name":"NONPROFIT MANAGEMENT & LEADERSHIP"},
      {"name":"PLANT SIGNALING & BEHAVIOR"},
      {"name":"EUROPEAN PHYSICAL JOURNAL-SPECIAL TOPICS"},
      {"name":"BODY & SOCIETY"},
      {"name":"INSECT SYSTEMATICS & EVOLUTION"},
      {"name":"IN VITRO CELLULAR & DEVELOPMENTAL BIOLOGY-ANIMAL"},
      {"name":"ASIAN-AUSTRALASIAN JOURNAL OF ANIMAL SCIENCES"},
      {"name":"BIOPHARMACEUTICS & DRUG DISPOSITION"},
      {"name":"REPRODUCTIVE HEALTH MATTERS"},
      {"name":"JOURNAL OF MUSCULOSKELETAL & NEURONAL INTERACTIONS"},
      {"name":"MOLECULAR & CELLULAR TOXICOLOGY"},
      {"name":"REAL-TIME SYSTEMS"},
      {"name":"NUMERICAL MATHEMATICS-THEORY METHODS AND APPLICATIONS"},
      {"name":"POLICING & SOCIETY"},
      {"name":"CYTA-JOURNAL OF FOOD"},
      {"name":"POLITICS & GENDER"},
      {"name":"AUSTRALIAN & NEW ZEALAND JOURNAL OF OBSTETRICS & GYNAECOLOGY"},
      {"name":"JOURNAL OF TRANSPORTATION SAFETY & SECURITY"},
      {"name":"JOURNAL OF SOLAR ENERGY ENGINEERING-TRANSACTIONS OF THE ASME"},
      {"name":"AMERICAN JOURNAL OF HOSPICE & PALLIATIVE MEDICINE"},
      {"name":"CANADIAN JOURNAL OF PUBLIC HEALTH-REVUE CANADIENNE DE SANTE PUBLIQUE"},
      {"name":"ANATOMICAL RECORD-ADVANCES IN INTEGRATIVE ANATOMY AND EVOLUTIONARY BIOLOGY"},
      {"name":"ENVIRONMENTAL MODELING & ASSESSMENT"},
      {"name":"VETERINARY CLINICS OF NORTH AMERICA-FOOD ANIMAL PRACTICE"},
      {"name":"HEART & LUNG"},
      {"name":"NATURAL LANGUAGE & LINGUISTIC THEORY"},
      {"name":"INTERNATIONAL JOURNAL OF LOW-CARBON TECHNOLOGIES"},
      {"name":"JOURNAL OF PLASTIC FILM & SHEETING"},
      {"name":"TRANSFORMATIONS IN BUSINESS & ECONOMICS"},
      {"name":"GROWTH HORMONE & IGF RESEARCH"},
      {"name":"JOURNAL OF BROADCASTING & ELECTRONIC MEDIA"},
      {"name":"CELESTIAL MECHANICS & DYNAMICAL ASTRONOMY"},
      {"name":"SURFACE TOPOGRAPHY-METROLOGY AND PROPERTIES"},
      {"name":"NJAS-WAGENINGEN JOURNAL OF LIFE SCIENCES"},
      {"name":"COMPARE-A JOURNAL OF COMPARATIVE AND INTERNATIONAL EDUCATION"},
      {"name":"CURRENT OPINION IN OTOLARYNGOLOGY & HEAD AND NECK SURGERY"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART E-JOURNAL OF PROCESS MECHANICAL ENGINEERING"},
      {"name":"FRONTIERS OF INFORMATION TECHNOLOGY & ELECTRONIC ENGINEERING"},
      {"name":"CLEAN-SOIL AIR WATER"},
      {"name":"RENDICONTI LINCEI-SCIENZE FISICHE E NATURALI"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART O-JOURNAL OF RISK AND RELIABILITY"},
      {"name":"NUMERICAL HEAT TRANSFER PART B-FUNDAMENTALS"},
      {"name":"JOURNAL OF ECONOMIC BEHAVIOR & ORGANIZATION"},
      {"name":"RESPIRATORY PHYSIOLOGY & NEUROBIOLOGY"},
      {"name":"JOURNAL OF BEHAVIORAL HEALTH SERVICES & RESEARCH"},
      {"name":"JOURNAL OF THE ROYAL STATISTICAL SOCIETY SERIES C-APPLIED STATISTICS"},
      {"name":"IEEE TRANSACTIONS ON COMPUTATIONAL INTELLIGENCE AND AI IN GAMES"},
      {"name":"NONLINEAR ANALYSIS-THEORY METHODS & APPLICATIONS"},
      {"name":"PURE AND APPLIED GEOPHYSICS"},
      {"name":"KNOWLEDGE MANAGEMENT RESEARCH & PRACTICE"},
      {"name":"NATURE CONSERVATION-BULGARIA"},
      {"name":"EVALUATION & THE HEALTH PROFESSIONS"},
      {"name":"GEOGRAFISK TIDSSKRIFT-DANISH JOURNAL OF GEOGRAPHY"},
      {"name":"MECHANICS OF TIME-DEPENDENT MATERIALS"},
      {"name":"CROP & PASTURE SCIENCE"},
      {"name":"OCCUPATIONAL MEDICINE-OXFORD"},
      {"name":"INTERNATIONAL & COMPARATIVE LAW QUARTERLY"},
      {"name":"JOURNAL OF WORK AND ORGANIZATIONAL PSYCHOLOGY-REVISTA DE PSICOLOGIA DEL TRABAJO Y DE LAS ORGANIZACIONES"},
      {"name":"ADMINISTRATION & SOCIETY"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART A-JOURNAL OF POWER AND ENERGY"},
      {"name":"JOURNAL OF MICRO-NANOLITHOGRAPHY MEMS AND MOEMS"},
      {"name":"NUCLEOSIDES NUCLEOTIDES & NUCLEIC ACIDS"},
      {"name":"JOURNAL OF VINYL & ADDITIVE TECHNOLOGY"},
      {"name":"MALAYSIAN JOURNAL OF LIBRARY & INFORMATION SCIENCE"},
      {"name":"AUDIOLOGY AND NEURO-OTOLOGY"},
      {"name":"IEEE INSTRUMENTATION & MEASUREMENT MAGAZINE"},
      {"name":"POLITICS & SOCIETY"},
      {"name":"CHEMICAL ENGINEERING & TECHNOLOGY"},
      {"name":"ADVANCES IN SKIN & WOUND CARE"},
      {"name":"I-PERCEPTION"},
      {"name":"JOURNAL OF BURN CARE & RESEARCH"},
      {"name":"MATERIALS AND CORROSION-WERKSTOFFE UND KORROSION"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART K-JOURNAL OF MULTI-BODY DYNAMICS"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART M-JOURNAL OF ENGINEERING FOR THE MARITIME ENVIRONMENT"},
      {"name":"INTERNATIONAL JOURNAL OF RF AND MICROWAVE COMPUTER-AIDED ENGINEERING"},
      {"name":"ZEITSCHRIFT FUR PSYCHOLOGIE-JOURNAL OF PSYCHOLOGY"},
      {"name":"EKSPLOATACJA I NIEZAWODNOSC-MAINTENANCE AND RELIABILITY"},
      {"name":"EYE & CONTACT LENS-SCIENCE AND CLINICAL PRACTICE"},
      {"name":"CANADIAN JOURNAL OF INFECTIOUS DISEASES & MEDICAL MICROBIOLOGY"},
      {"name":"JOURNAL OF AGRICULTURAL EDUCATION & EXTENSION"},
      {"name":"PUBLIC HEALTH GENOMICS"},
      {"name":"CONSUMPTION MARKETS & CULTURE"},
      {"name":"JOURNAL OF COMPARATIVE PHYSIOLOGY A-NEUROETHOLOGY SENSORY NEURAL AND BEHAVIORAL PHYSIOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF PERIODONTICS & RESTORATIVE DENTISTRY"},
      {"name":"TAIWANESE JOURNAL OF OBSTETRICS & GYNECOLOGY"},
      {"name":"CHIROPRACTIC & MANUAL THERAPIES"},
      {"name":"EDUCATIONAL MEASUREMENT-ISSUES AND PRACTICE"},
      {"name":"INTERDISCIPLINARY SCIENCES-COMPUTATIONAL LIFE SCIENCES"},
      {"name":"JOURNAL OF VACUUM SCIENCE & TECHNOLOGY B"},
      {"name":"ARTHROPOD SYSTEMATICS & PHYLOGENY"},
      {"name":"ICON-INTERNATIONAL JOURNAL OF CONSTITUTIONAL LAW"},
      {"name":"MATHEMATICAL INEQUALITIES & APPLICATIONS"},
      {"name":"PUBLIC PERFORMANCE & MANAGEMENT REVIEW"},
      {"name":"JANAC-JOURNAL OF THE ASSOCIATION OF NURSES IN AIDS CARE"},
      {"name":"PAEDIATRICS & CHILD HEALTH"},
      {"name":"PS-POLITICAL SCIENCE & POLITICS"},
      {"name":"BRAIN & DEVELOPMENT"},
      {"name":"JOURNAL OF ATMOSPHERIC AND SOLAR-TERRESTRIAL PHYSICS"},
      {"name":"BULLETIN DE LA SOCIETE GEOLOGIQUE DE FRANCE"},
      {"name":"JOURNAL OF WOMEN POLITICS & POLICY"},
      {"name":"PALAEONTOGRAPHICA ABTEILUNG B-PALAEOPHYTOLOGIE PALAEOBOTANY-PALAEOPHYTOLOGY"},
      {"name":"SUBSTANCE USE & MISUSE"},
      {"name":"BIOFUELS-UK"},
      {"name":"HIGH ALTITUDE MEDICINE & BIOLOGY"},
      {"name":"JOURNAL OF PEDIATRIC NURSING-NURSING CARE OF CHILDREN & FAMILIES"},
      {"name":"EMU-AUSTRAL ORNITHOLOGY"},
      {"name":"MEASUREMENT & CONTROL"},
      {"name":"JOURNAL OF ZHEJIANG UNIVERSITY-SCIENCE A"},
      {"name":"SOUTH AFRICAN JOURNAL OF CHEMISTRY-SUID-AFRIKAANSE TYDSKRIF VIR CHEMIE"},
      {"name":"ASTRONOMY LETTERS-A JOURNAL OF ASTRONOMY AND SPACE ASTROPHYSICS"},
      {"name":"APPLIED NEUROPSYCHOLOGY-ADULT"},
      {"name":"ECOLOGICAL CHEMISTRY AND ENGINEERING S-CHEMIA I INZYNIERIA EKOLOGICZNA S"},
      {"name":"PHYSICAL & OCCUPATIONAL THERAPY IN PEDIATRICS"},
      {"name":"AMERICAN JOURNAL OF SPEECH-LANGUAGE PATHOLOGY"},
      {"name":"EXPLORE-THE JOURNAL OF SCIENCE AND HEALING"},
      {"name":"LIBRARY & INFORMATION SCIENCE RESEARCH"},
      {"name":"OSTOMY WOUND MANAGEMENT"},
      {"name":"PHYSICA STATUS SOLIDI B-BASIC SOLID STATE PHYSICS"},
      {"name":"SEX EDUCATION-SEXUALITY SOCIETY AND LEARNING"},
      {"name":"GEOMETRY & TOPOLOGY"},
      {"name":"DATA & KNOWLEDGE ENGINEERING"},
      {"name":"SET-VALUED AND VARIATIONAL ANALYSIS"},
      {"name":"DISCOURSE & COMMUNICATION"},
      {"name":"SCANDINAVIAN JOURNAL OF CLINICAL & LABORATORY INVESTIGATION"},
      {"name":"JOURNAL OF BUSINESS FINANCE & ACCOUNTING"},
      {"name":"JOURNAL OF COMPUTATIONAL ACOUSTICS"},
      {"name":"ICHNOS-AN INTERNATIONAL JOURNAL FOR PLANT AND ANIMAL TRACES"},
      {"name":"MATERIALS RESEARCH-IBERO-AMERICAN JOURNAL OF MATERIALS"},
      {"name":"ARTHROPOD-PLANT INTERACTIONS"},
      {"name":"JOURNAL OF AGRICULTURAL & ENVIRONMENTAL ETHICS"},
      {"name":"JSLS-JOURNAL OF THE SOCIETY OF LAPAROENDOSCOPIC SURGEONS"},
      {"name":"PROFESSIONAL PSYCHOLOGY-RESEARCH AND PRACTICE"},
      {"name":"ACCOUNTABILITY IN RESEARCH-POLICIES AND QUALITY ASSURANCE"},
      {"name":"ORTHODONTICS & CRANIOFACIAL RESEARCH"},
      {"name":"EVIDENCE & POLICY"},
      {"name":"SMALL-SCALE FORESTRY"},
      {"name":"HYSTRIX-ITALIAN JOURNAL OF MAMMALOGY"},
      {"name":"CONCURRENCY AND COMPUTATION-PRACTICE & EXPERIENCE"},
      {"name":"HAU-JOURNAL OF ETHNOGRAPHIC THEORY"},
      {"name":"KOREAN JOURNAL FOR FOOD SCIENCE OF ANIMAL RESOURCES"},
      {"name":"JOURNAL OF AGING & SOCIAL POLICY"},
      {"name":"CANADIAN JOURNAL OF EXPERIMENTAL PSYCHOLOGY-REVUE CANADIENNE DE PSYCHOLOGIE EXPERIMENTALE"},
      {"name":"JOURNAL OF HOSPITALITY LEISURE SPORT & TOURISM EDUCATION"},
      {"name":"SOLID-STATE ELECTRONICS"},
      {"name":"JOURNAL OF ECONOMICS & MANAGEMENT STRATEGY"},
      {"name":"LAW & SOCIETY REVIEW"},
      {"name":"OPTIMIZATION METHODS & SOFTWARE"},
      {"name":"BIOMEDICAL RESEARCH-TOKYO"},
      {"name":"GEO-MARINE LETTERS"},
      {"name":"ATMOSPHERE-OCEAN"},
      {"name":"WILDERNESS & ENVIRONMENTAL MEDICINE"},
      {"name":"ACM TRANSACTIONS ON ASIAN AND LOW-RESOURCE LANGUAGE INFORMATION PROCESSING"},
      {"name":"INSTRUMENTATION SCIENCE & TECHNOLOGY"},
      {"name":"JOURNAL OF OBSESSIVE-COMPULSIVE AND RELATED DISORDERS"},
      {"name":"JOURNAL OF THE RENIN-ANGIOTENSIN-ALDOSTERONE SYSTEM"},
      {"name":"CHEMICAL & PHARMACEUTICAL BULLETIN"},
      {"name":"PREPARATIVE BIOCHEMISTRY & BIOTECHNOLOGY"},
      {"name":"AMPHIBIA-REPTILIA"},
      {"name":"ZEITSCHRIFT FUR KRISTALLOGRAPHIE-CRYSTALLINE MATERIALS"},
      {"name":"ARCHIVES OF DISEASE IN CHILDHOOD-EDUCATION AND PRACTICE EDITION"},
      {"name":"REVISTA BRASILEIRA DE FARMACOGNOSIA-BRAZILIAN JOURNAL OF PHARMACOGNOSY"},
      {"name":"INTERNATIONAL JOURNAL OF STD & AIDS"},
      {"name":"INTERNATIONAL RELATIONS OF THE ASIA-PACIFIC"},
      {"name":"REVISTA DE LA REAL ACADEMIA DE CIENCIAS EXACTAS FISICAS Y NATURALES SERIE A-MATEMATICAS"},
      {"name":"JOURNAL OF LAPAROENDOSCOPIC & ADVANCED SURGICAL TECHNIQUES"},
      {"name":"DIVERSITY-BASEL"},
      {"name":"BUILDING SERVICES ENGINEERING RESEARCH & TECHNOLOGY"},
      {"name":"CANADIAN JOURNAL OF ELECTRICAL AND COMPUTER ENGINEERING-REVUE CANADIENNE DE GENIE ELECTRIQUE ET INFORMATIQUE"},
      {"name":"JOURNAL OF NEUROLOGICAL SURGERY PART B-SKULL BASE"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART J-JOURNAL OF ENGINEERING TRIBOLOGY"},
      {"name":"OIL & GAS SCIENCE AND TECHNOLOGY-REVUE D IFP ENERGIES NOUVELLES"},
      {"name":"PFG-JOURNAL OF PHOTOGRAMMETRY REMOTE SENSING AND GEOINFORMATION SCIENCE"},
      {"name":"INTERPRETATION-A JOURNAL OF SUBSURFACE CHARACTERIZATION"},
      {"name":"ECHOCARDIOGRAPHY-A JOURNAL OF CARDIOVASCULAR ULTRASOUND AND ALLIED TECHNIQUES"},
      {"name":"MATHEMATICAL MEDICINE AND BIOLOGY-A JOURNAL OF THE IMA"},
      {"name":"DISCOURSE & SOCIETY"},
      {"name":"KOREA-AUSTRALIA RHEOLOGY JOURNAL"},
      {"name":"STOCHASTICS AND PARTIAL DIFFERENTIAL EQUATIONS-ANALYSIS AND COMPUTATIONS"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART C-JOURNAL OF MECHANICAL ENGINEERING SCIENCE"},
      {"name":"BULLETIN OF THE POLISH ACADEMY OF SCIENCES-TECHNICAL SCIENCES"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART D-JOURNAL OF AUTOMOBILE ENGINEERING"},
      {"name":"GEOGRAFISKA ANNALER SERIES B-HUMAN GEOGRAPHY"},
      {"name":"ENERGY EXPLORATION & EXPLOITATION"},
      {"name":"SURGICAL LAPAROSCOPY ENDOSCOPY & PERCUTANEOUS TECHNIQUES"},
      {"name":"DISCOURSE CONTEXT & MEDIA"},
      {"name":"ACTA PHYSICO-CHIMICA SINICA"},
      {"name":"PROBLEMS OF POST-COMMUNISM"},
      {"name":"PUBLIC MONEY & MANAGEMENT"},
      {"name":"STROJNISKI VESTNIK-JOURNAL OF MECHANICAL ENGINEERING"},
      {"name":"INTERNATIONAL JOURNAL OF UNCERTAINTY FUZZINESS AND KNOWLEDGE-BASED SYSTEMS"},
      {"name":"CLINICAL AND APPLIED THROMBOSIS-HEMOSTASIS"},
      {"name":"CUAJ-CANADIAN UROLOGICAL ASSOCIATION JOURNAL"},
      {"name":"STUDIES IN CONFLICT & TERRORISM"},
      {"name":"CANADIAN JOURNAL OF OPHTHALMOLOGY-JOURNAL CANADIEN D OPHTALMOLOGIE"},
      {"name":"INTERNATIONAL JOURNAL OF QUALITATIVE STUDIES ON HEALTH AND WELL-BEING"},
      {"name":"REVISTA DE CONTABILIDAD-SPANISH ACCOUNTING REVIEW"},
      {"name":"COMPUTATIONAL & APPLIED MATHEMATICS"},
      {"name":"INSURANCE MATHEMATICS & ECONOMICS"},
      {"name":"JOURNAL OF ELDER ABUSE & NEGLECT"},
      {"name":"MARINE ECOLOGY-AN EVOLUTIONARY PERSPECTIVE"},
      {"name":"PLASMA SCIENCE & TECHNOLOGY"},
      {"name":"CHILD & YOUTH CARE FORUM"},
      {"name":"ZEITSCHRIFT FUR NATURFORSCHUNG SECTION A-A JOURNAL OF PHYSICAL SCIENCES"},
      {"name":"CYBERPSYCHOLOGY-JOURNAL OF PSYCHOSOCIAL RESEARCH ON CYBERSPACE"},
      {"name":"ESAIM-MATHEMATICAL MODELLING AND NUMERICAL ANALYSIS-MODELISATION MATHEMATIQUE ET ANALYSE NUMERIQUE"},
      {"name":"IRONMAKING & STEELMAKING"},
      {"name":"COMPUTERS & GRAPHICS-UK"},
      {"name":"JOURNAL OF MEDIA PSYCHOLOGY-THEORIES METHODS AND APPLICATIONS"},
      {"name":"JOURNAL OF MACROMOLECULAR SCIENCE PART A-PURE AND APPLIED CHEMISTRY"},
      {"name":"CLEFT PALATE-CRANIOFACIAL JOURNAL"},
      {"name":"OMEGA-JOURNAL OF DEATH AND DYING"},
      {"name":"GERIATRIC ORTHOPAEDIC SURGERY & REHABILITATION"},
      {"name":"AUTONOMOUS AGENTS AND MULTI-AGENT SYSTEMS"},
      {"name":"JOURNAL OF X-RAY SCIENCE AND TECHNOLOGY"},
      {"name":"LEARNING & BEHAVIOR"},
      {"name":"ACTA GEOGRAPHICA SLOVENICA-GEOGRAFSKI ZBORNIK"},
      {"name":"LEARNING DISABILITIES RESEARCH & PRACTICE"},
      {"name":"SEXUAL & REPRODUCTIVE HEALTHCARE"},
      {"name":"STANDARDS IN GENOMIC SCIENCES"},
      {"name":"EKLEM HASTALIKLARI VE CERRAHISI-JOINT DISEASES AND RELATED SURGERY"},
      {"name":"CHILD & FAMILY SOCIAL WORK"},
      {"name":"JOURNAL OF CRIMINAL LAW & CRIMINOLOGY"},
      {"name":"LIBRARY COLLECTIONS ACQUISITIONS & TECHNICAL SERVICES"},
      {"name":"ASCE-ASME JOURNAL OF RISK AND UNCERTAINTY IN ENGINEERING SYSTEMS PART A-CIVIL ENGINEERING"},
      {"name":"INQUIRY-THE JOURNAL OF HEALTH CARE ORGANIZATION PROVISION AND FINANCING"},
      {"name":"WOUNDS-A COMPENDIUM OF CLINICAL RESEARCH AND PRACTICE"},
      {"name":"AMERICAN ECONOMIC JOURNAL-MICROECONOMICS"},
      {"name":"CIN-COMPUTERS INFORMATICS NURSING"},
      {"name":"JOURNAL OF WATER SUPPLY RESEARCH AND TECHNOLOGY-AQUA"},
      {"name":"AFRICAN JOURNAL OF RANGE & FORAGE SCIENCE"},
      {"name":"THEORY & PSYCHOLOGY"},
      {"name":"JOURNAL OF DYNAMIC SYSTEMS MEASUREMENT AND CONTROL-TRANSACTIONS OF THE ASME"},
      {"name":"PACE-PACING AND CLINICAL ELECTROPHYSIOLOGY"},
      {"name":"COMMUNICATIONS-EUROPEAN JOURNAL OF COMMUNICATION RESEARCH"},
      {"name":"SAHARA J-JOURNAL OF SOCIAL ASPECTS OF HIV-AIDS"},
      {"name":"CANADIAN JOURNAL ON AGING-REVUE CANADIENNE DU VIEILLISSEMENT"},
      {"name":"REVISTA LATINO-AMERICANA DE ENFERMAGEM"},
      {"name":"PROCEEDINGS OF THE ROMANIAN ACADEMY SERIES A-MATHEMATICS PHYSICS TECHNICAL SCIENCES INFORMATION SCIENCE"},
      {"name":"APPLIED NEUROPSYCHOLOGY-CHILD"},
      {"name":"COMMUNICATION & SPORT"},
      {"name":"PSYCHOLOGY & SEXUALITY"},
      {"name":"CRYPTOGRAPHY AND COMMUNICATIONS-DISCRETE-STRUCTURES BOOLEAN FUNCTIONS AND SEQUENCES"},
      {"name":"IET CIRCUITS DEVICES & SYSTEMS"},
      {"name":"JAVMA-JOURNAL OF THE AMERICAN VETERINARY MEDICAL ASSOCIATION"},
      {"name":"FAMILIES SYSTEMS & HEALTH"},
      {"name":"JOURNAL OF OROFACIAL ORTHOPEDICS-FORTSCHRITTE DER KIEFERORTHOPADIE"},
      {"name":"REGULAR & CHAOTIC DYNAMICS"},
      {"name":"OBSTETRICAL & GYNECOLOGICAL SURVEY"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART H-JOURNAL OF ENGINEERING IN MEDICINE"},
      {"name":"QUANTUM INFORMATION & COMPUTATION"},
      {"name":"INNOVATION-THE EUROPEAN JOURNAL OF SOCIAL SCIENCE RESEARCH"},
      {"name":"JOURNAL OF PEDIATRIC ENDOCRINOLOGY & METABOLISM"},
      {"name":"LAW & POLICY"},
      {"name":"MIND & LANGUAGE"},
      {"name":"SPANISH JOURNAL OF FINANCE AND ACCOUNTING-REVISTA ESPANOLA DE FINANCIACION Y CONTABILIDA"},
      {"name":"HUMAN FACTORS AND ERGONOMICS IN MANUFACTURING & SERVICE INDUSTRIES"},
      {"name":"INTERNATIONAL JOURNAL OF OCCUPATIONAL AND ENVIRONMENTAL HEALTH"},
      {"name":"DISCRETE AND CONTINUOUS DYNAMICAL SYSTEMS-SERIES B"},
      {"name":"NUCLEAR INSTRUMENTS & METHODS IN PHYSICS RESEARCH SECTION B-BEAM INTERACTIONS WITH MATERIALS AND ATOMS"},
      {"name":"NURSING & HEALTH SCIENCES"},
      {"name":"GEOCHEMISTRY-EXPLORATION ENVIRONMENT ANALYSIS"},
      {"name":"PUBLIUS-THE JOURNAL OF FEDERALISM"},
      {"name":"SCIENCE & EDUCATION"},
      {"name":"NUCLEAR INSTRUMENTS & METHODS IN PHYSICS RESEARCH SECTION A-ACCELERATORS SPECTROMETERS DETECTORS AND ASSOCIATED EQUIPMENT"},
      {"name":"WOOD MATERIAL SCIENCE & ENGINEERING"},
      {"name":"JOURNAL OF EXERCISE SCIENCE & FITNESS"},
      {"name":"JOURNAL OF PENSION ECONOMICS & FINANCE"},
      {"name":"JOURNAL OF ORAL & FACIAL PAIN AND HEADACHE"},
      {"name":"MADERAS-CIENCIA Y TECNOLOGIA"},
      {"name":"PERIODICA POLYTECHNICA-CHEMICAL ENGINEERING"},
      {"name":"VETERINARY CLINICS OF NORTH AMERICA-SMALL ANIMAL PRACTICE"},
      {"name":"ZDM-MATHEMATICS EDUCATION"},
      {"name":"ASIA-PACIFIC JOURNAL OF PUBLIC HEALTH"},
      {"name":"ANNALES DE L INSTITUT HENRI POINCARE-PROBABILITES ET STATISTIQUES"},
      {"name":"X-RAY SPECTROMETRY"},
      {"name":"OPTIMAL CONTROL APPLICATIONS & METHODS"},
      {"name":"AFRICAN DEVELOPMENT REVIEW-REVUE AFRICAINE DE DEVELOPPEMENT"},
      {"name":"JOGNN-JOURNAL OF OBSTETRIC GYNECOLOGIC AND NEONATAL NURSING"},
      {"name":"JOURNAL OF VISUAL LANGUAGES AND COMPUTING"},
      {"name":"SOIL & SEDIMENT CONTAMINATION"},
      {"name":"COMMUNICATION CULTURE & CRITIQUE"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART G-JOURNAL OF AEROSPACE ENGINEERING"},
      {"name":"BIO-MEDICAL MATERIALS AND ENGINEERING"},
      {"name":"LANGUAGE & COMMUNICATION"},
      {"name":"METHODOLOGY-EUROPEAN JOURNAL OF RESEARCH METHODS FOR THE BEHAVIORAL AND SOCIAL SCIENCES"},
      {"name":"PHYSICA C-SUPERCONDUCTIVITY AND ITS APPLICATIONS"},
      {"name":"PROGRAM-ELECTRONIC LIBRARY AND INFORMATION SYSTEMS"},
      {"name":"JOURNAL OF THE EUROPEAN OPTICAL SOCIETY-RAPID PUBLICATIONS"},
      {"name":"PUBLICACIONS MATEMATIQUES"},
      {"name":"ZEITSCHRIFT FUR NATURFORSCHUNG SECTION C-A JOURNAL OF BIOSCIENCES"},
      {"name":"INTERNATIONAL JOURNAL OF SPORTS SCIENCE & COACHING"},
      {"name":"PERFUSION-UK"},
      {"name":"DISCRETE AND CONTINUOUS DYNAMICAL SYSTEMS-SERIES S"},
      {"name":"YOUTH JUSTICE-AN INTERNATIONAL JOURNAL"},
      {"name":"ETHNICITY & DISEASE"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART F-JOURNAL OF RAIL AND RAPID TRANSIT"},
      {"name":"WORKPLACE HEALTH & SAFETY"},
      {"name":"HEALTH & SOCIAL WORK"},
      {"name":"SOFTWARE TESTING VERIFICATION & RELIABILITY"},
      {"name":"VETERINARY RADIOLOGY & ULTRASOUND"},
      {"name":"SOCIAL & LEGAL STUDIES"},
      {"name":"INFORMATION TECHNOLOGY & MANAGEMENT"},
      {"name":"ACTA OECOLOGICA-INTERNATIONAL JOURNAL OF ECOLOGY"},
      {"name":"AUSTRALIAN FAMILY PHYSICIAN"},
      {"name":"OTJR-OCCUPATION PARTICIPATION AND HEALTH"},
      {"name":"INTEGRATION-THE VLSI JOURNAL"},
      {"name":"COGNITION TECHNOLOGY & WORK"},
      {"name":"RANDOM MATRICES-THEORY AND APPLICATIONS"},
      {"name":"LUTS-LOWER URINARY TRACT SYMPTOMS"},
      {"name":"MEDICINA-LITHUANIA"},
      {"name":"JOURNAL OF ECONOMIC DYNAMICS & CONTROL"},
      {"name":"BIOTECHNIC & HISTOCHEMISTRY"},
      {"name":"BLOOD COAGULATION & FIBRINOLYSIS"},
      {"name":"BRATISLAVA MEDICAL JOURNAL-BRATISLAVSKE LEKARSKE LISTY"},
      {"name":"ANTI-CORROSION METHODS AND MATERIALS"},
      {"name":"PLANT ECOLOGY & DIVERSITY"},
      {"name":"COMBINATORIAL CHEMISTRY & HIGH THROUGHPUT SCREENING"},
      {"name":"E & M EKONOMIE A MANAGEMENT"},
      {"name":"PSYCHOLOGY CRIME & LAW"},
      {"name":"REVISTA DE INVESTIGACION CLINICA-CLINICAL AND TRANSLATIONAL INVESTIGATION"},
      {"name":"GENES & GENOMICS"},
      {"name":"BIOTECHNOLOGY & BIOTECHNOLOGICAL EQUIPMENT"},
      {"name":"COMPUTATIONAL STATISTICS & DATA ANALYSIS"},
      {"name":"JOURNAL OF OFFSHORE MECHANICS AND ARCTIC ENGINEERING-TRANSACTIONS OF THE ASME"},
      {"name":"CANADIAN JOURNAL OF VETERINARY RESEARCH-REVUE CANADIENNE DE RECHERCHE VETERINAIRE"},
      {"name":"POLICING-AN INTERNATIONAL JOURNAL OF POLICE STRATEGIES & MANAGEMENT"},
      {"name":"ENERGY SOURCES PART A-RECOVERY UTILIZATION AND ENVIRONMENTAL EFFECTS"},
      {"name":"ITALIAN JOURNAL OF AGROMETEOROLOGY-RIVISTA ITALIANA DI AGROMETEOROLOGIA"},
      {"name":"ESAIM-CONTROL OPTIMISATION AND CALCULUS OF VARIATIONS"},
      {"name":"ARCHIVES OF ENVIRONMENTAL & OCCUPATIONAL HEALTH"},
      {"name":"SOLVENT EXTRACTION RESEARCH AND DEVELOPMENT-JAPAN"},
      {"name":"JOURNAL OF SOFTWARE-EVOLUTION AND PROCESS"},
      {"name":"PUBLIC HEALTH ETHICS"},
      {"name":"CRANIO-THE JOURNAL OF CRANIOMANDIBULAR & SLEEP PRACTICE"},
      {"name":"ACSMS HEALTH & FITNESS JOURNAL"},
      {"name":"RISK MANAGEMENT-AN INTERNATIONAL JOURNAL"},
      {"name":"DRUGS-EDUCATION PREVENTION AND POLICY"},
      {"name":"LETTERS IN DRUG DESIGN & DISCOVERY"},
      {"name":"MCN-THE AMERICAN JOURNAL OF MATERNAL-CHILD NURSING"},
      {"name":"NOTULAE BOTANICAE HORTI AGROBOTANICI CLUJ-NAPOCA"},
      {"name":"JOVE-JOURNAL OF VISUALIZED EXPERIMENTS"},
      {"name":"JOURNAL OF RELIGION & HEALTH"},
      {"name":"AUSTRALASIAN PHYSICAL & ENGINEERING SCIENCES IN MEDICINE"},
      {"name":"JOURNAL OF HORTICULTURAL SCIENCE & BIOTECHNOLOGY"},
      {"name":"JOURNAL OF CRIME & JUSTICE"},
      {"name":"ACTA OTO-LARYNGOLOGICA"},
      {"name":"PARENTING-SCIENCE AND PRACTICE"},
      {"name":"JOURNAL OF HUAZHONG UNIVERSITY OF SCIENCE AND TECHNOLOGY-MEDICAL SCIENCES"},
      {"name":"ARTS & HEALTH"},
      {"name":"JOURNAL OF INTELLECTUAL & DEVELOPMENTAL DISABILITY"},
      {"name":"HUMAN SERVICE ORGANIZATIONS MANAGEMENT LEADERSHIP & GOVERNANCE"},
      {"name":"JOURNAL OF ENGINEERING MATERIALS AND TECHNOLOGY-TRANSACTIONS OF THE ASME"},
      {"name":"4OR-A QUARTERLY JOURNAL OF OPERATIONS RESEARCH"},
      {"name":"SOCIAL THEORY & HEALTH"},
      {"name":"JOURNAL OF PRESSURE VESSEL TECHNOLOGY-TRANSACTIONS OF THE ASME"},
      {"name":"JOURNAL OF WOMEN & AGING"},
      {"name":"PERIODICA POLYTECHNICA-CIVIL ENGINEERING"},
      {"name":"JOURNAL OF MINING AND METALLURGY SECTION B-METALLURGY"},
      {"name":"AMPHIBIAN & REPTILE CONSERVATION"},
      {"name":"CITY & COMMUNITY"},
      {"name":"ENVIRONMENTAL HAZARDS-HUMAN AND POLICY DIMENSIONS"},
      {"name":"JOURNAL OF DEVELOPMENTAL AND LIFE-COURSE CRIMINOLOGY"},
      {"name":"NODEA-NONLINEAR DIFFERENTIAL EQUATIONS AND APPLICATIONS"},
      {"name":"WORK-A JOURNAL OF PREVENTION ASSESSMENT & REHABILITATION"},
      {"name":"ASIA-PACIFIC JOURNAL OF TEACHER EDUCATION"},
      {"name":"JOURNAL OF FAMILY PLANNING AND REPRODUCTIVE HEALTH CARE"},
      {"name":"PUBLIC HEALTH NURSING"},
      {"name":"JOURNAL OF ADOLESCENT & ADULT LITERACY"},
      {"name":"ANTHROPOLOGY & MEDICINE"},
      {"name":"BIOLOGY & PHILOSOPHY"},
      {"name":"INDUSTRIAL ROBOT-THE INTERNATIONAL JOURNAL OF ROBOTICS RESEARCH AND APPLICATION"},
      {"name":"JOURNAL OF THE LONDON MATHEMATICAL SOCIETY-SECOND SERIES"},
      {"name":"ETHICS & BEHAVIOR"},
      {"name":"AI EDAM-ARTIFICIAL INTELLIGENCE FOR ENGINEERING DESIGN ANALYSIS AND MANUFACTURING"},
      {"name":"VICTIMS & OFFENDERS"},
      {"name":"PHILOSOPHY & PUBLIC AFFAIRS"},
      {"name":"LINEAR & MULTILINEAR ALGEBRA"},
      {"name":"RECHT & PSYCHIATRIE"},
      {"name":"RESEARCH IN SCIENCE & TECHNOLOGICAL EDUCATION"},
      {"name":"JOURNAL OF ARTHROPOD-BORNE DISEASES"},
      {"name":"TIME & SOCIETY"},
      {"name":"AGRICULTURAL ECONOMICS-ZEMEDELSKA EKONOMIKA"},
      {"name":"EUROPEAN ANNALS OF OTORHINOLARYNGOLOGY-HEAD AND NECK DISEASES"},
      {"name":"ZAMM-ZEITSCHRIFT FUR ANGEWANDTE MATHEMATIK UND MECHANIK"},
      {"name":"BEHAVIORAL SCIENCES & THE LAW"},
      {"name":"JOURNAL OF ASIA-PACIFIC ENTOMOLOGY"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART I-JOURNAL OF SYSTEMS AND CONTROL ENGINEERING"},
      {"name":"JOURNAL OF PEDIATRIC OPHTHALMOLOGY & STRABISMUS"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-TRANSPORT"},
      {"name":"ECONOMICS-THE OPEN ACCESS OPEN-ASSESSMENT E-JOURNAL"},
      {"name":"JOURNAL OF WORLD ENERGY LAW & BUSINESS"},
      {"name":"WOMEN & HEALTH"},
      {"name":"ASIA-PACIFIC PSYCHIATRY"},
      {"name":"ACTA AGRICULTURAE SCANDINAVICA SECTION B-SOIL AND PLANT SCIENCE"},
      {"name":"ACTA CRYSTALLOGRAPHICA SECTION C-STRUCTURAL CHEMISTRY"},
      {"name":"SIMULATION-TRANSACTIONS OF THE SOCIETY FOR MODELING AND SIMULATION INTERNATIONAL"},
      {"name":"ECOLOGICAL MANAGEMENT & RESTORATION"},
      {"name":"AFFILIA-JOURNAL OF WOMEN AND SOCIAL WORK"},
      {"name":"JOURNAL OF LAW MEDICINE & ETHICS"},
      {"name":"JOURNAL OF TRANSPORTATION ENGINEERING PART B-PAVEMENTS"},
      {"name":"NUKLEARMEDIZIN-NUCLEAR MEDICINE"},
      {"name":"PSYCHOSIS-PSYCHOLOGICAL SOCIAL AND INTEGRATIVE APPROACHES"},
      {"name":"ORL-JOURNAL FOR OTO-RHINO-LARYNGOLOGY HEAD AND NECK SURGERY"},
      {"name":"INTERNATIONAL JOURNAL OF SPORTS MARKETING & SPONSORSHIP"},
      {"name":"NORTHWESTERN JOURNAL OF INTERNATIONAL LAW & BUSINESS"},
      {"name":"JOURNAL OF INTERNATIONAL TRADE & ECONOMIC DEVELOPMENT"},
      {"name":"EPIDEMIOLOGIA & PREVENZIONE"},
      {"name":"INTERNATIONAL JOURNAL OF AGING & HUMAN DEVELOPMENT"},
      {"name":"ANTHROPOLOGY & EDUCATION QUARTERLY"},
      {"name":"CHINESE JOURNAL OF OCEANOLOGY AND LIMNOLOGY"},
      {"name":"HAHR-HISPANIC AMERICAN HISTORICAL REVIEW"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-ENGINEERING SUSTAINABILITY"},
      {"name":"BRITISH JOURNAL OF ORAL & MAXILLOFACIAL SURGERY"},
      {"name":"JOURNAL OF FORENSIC PSYCHIATRY & PSYCHOLOGY"},
      {"name":"ASIA-PACIFIC JOURNAL OF CHEMICAL ENGINEERING"},
      {"name":"BUNDESGESUNDHEITSBLATT-GESUNDHEITSFORSCHUNG-GESUNDHEITSSCHUTZ"},
      {"name":"CANADIAN JOURNAL OF DEVELOPMENT STUDIES-REVUE CANADIENNE D ETUDES DU DEVELOPPEMENT"},
      {"name":"COMMUNICATION AND CRITICAL-CULTURAL STUDIES"},
      {"name":"NUCLEAR TECHNOLOGY & RADIATION PROTECTION"},
      {"name":"GEMS & GEMOLOGY"},
      {"name":"BIOMEDICAL ENGINEERING-BIOMEDIZINISCHE TECHNIK"},
      {"name":"REVIEW OF EUROPEAN COMPARATIVE & INTERNATIONAL ENVIRONMENTAL LAW"},
      {"name":"SPE DRILLING & COMPLETION"},
      {"name":"SELECTA MATHEMATICA-NEW SERIES"},
      {"name":"MEDIZINISCHE KLINIK-INTENSIVMEDIZIN UND NOTFALLMEDIZIN"},
      {"name":"HEALTH RISK & SOCIETY"},
      {"name":"RANDOM STRUCTURES & ALGORITHMS"},
      {"name":"ERWERBS-OBSTBAU"},
      {"name":"CANADIAN JOURNAL OF SOCIOLOGY-CAHIERS CANADIENS DE SOCIOLOGIE"},
      {"name":"JOURNAL OF FOOT & ANKLE SURGERY"},
      {"name":"ACTA SCIENTIARUM-AGRONOMY"},
      {"name":"MAPAN-JOURNAL OF METROLOGY SOCIETY OF INDIA"},
      {"name":"CANADIAN GEOGRAPHER-GEOGRAPHE CANADIEN"},
      {"name":"ANNALI DELLA SCUOLA NORMALE SUPERIORE DI PISA-CLASSE DI SCIENZE"},
      {"name":"JOURNAL OF AGGRESSION MALTREATMENT & TRAUMA"},
      {"name":"IRANIAN JOURNAL OF SCIENCE AND TECHNOLOGY-TRANSACTIONS OF MECHANICAL ENGINEERING"},
      {"name":"MINIMALLY INVASIVE THERAPY & ALLIED TECHNOLOGIES"},
      {"name":"RAIRO-OPERATIONS RESEARCH"},
      {"name":"SCIENCE CHINA-MATHEMATICS"},
      {"name":"JAVNOST-THE PUBLIC"},
      {"name":"POLYMERS & POLYMER COMPOSITES"},
      {"name":"ETHOLOGY ECOLOGY & EVOLUTION"},
      {"name":"MINERALS & METALLURGICAL PROCESSING"},
      {"name":"CIENCIA & SAUDE COLETIVA"},
      {"name":"BEHAVIORAL PSYCHOLOGY-PSICOLOGIA CONDUCTUAL"},
      {"name":"PROCEEDINGS OF THE ROYAL SOCIETY OF EDINBURGH SECTION A-MATHEMATICS"},
      {"name":"ARQUIVOS DE NEURO-PSIQUIATRIA"},
      {"name":"ADVANCES IN CHROMATOGRAPHY"},
      {"name":"BIOMEDICAL PAPERS-OLOMOUC"},
      {"name":"INZINERINE EKONOMIKA-ENGINEERING ECONOMICS"},
      {"name":"NEUES JAHRBUCH FUR MINERALOGIE-ABHANDLUNGEN"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-GEOTECHNICAL ENGINEERING"},
      {"name":"PHYSICS AND CHEMISTRY OF GLASSES-EUROPEAN JOURNAL OF GLASS SCIENCE AND TECHNOLOGY PART B"},
      {"name":"POLIMEROS-CIENCIA E TECNOLOGIA"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF MECHANICAL ENGINEERS PART P-JOURNAL OF SPORTS ENGINEERING AND TECHNOLOGY"},
      {"name":"SYNTAX-A JOURNAL OF THEORETICAL EXPERIMENTAL AND INTERDISCIPLINARY RESEARCH"},
      {"name":"VIAL-VIGO INTERNATIONAL JOURNAL OF APPLIED LINGUISTICS"},
      {"name":"JOURNAL OF LIQUID CHROMATOGRAPHY & RELATED TECHNOLOGIES"},
      {"name":"JOURNAL OF TRANSPORTATION ENGINEERING PART A-SYSTEMS"},
      {"name":"RENDICONTI LINCEI-MATEMATICA E APPLICAZIONI"},
      {"name":"INTERNATIONAL JOURNAL OF ROBOTICS & AUTOMATION"},
      {"name":"DYNAMICAL SYSTEMS-AN INTERNATIONAL JOURNAL"},
      {"name":"OPHTHALMIC SURGERY LASERS & IMAGING RETINA"},
      {"name":"NEUES JAHRBUCH FUR GEOLOGIE UND PALAONTOLOGIE-ABHANDLUNGEN"},
      {"name":"ASTA-ADVANCES IN STATISTICAL ANALYSIS"},
      {"name":"JOURNAL OF LATINA-O PSYCHOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF HEALTH  ECONOMICS AND MANAGEMENT"},
      {"name":"CLINICAL LINGUISTICS & PHONETICS"},
      {"name":"IRANIAN JOURNAL OF SCIENCE AND TECHNOLOGY-TRANSACTIONS OF CIVIL ENGINEERING"},
      {"name":"MICRO & NANO LETTERS"},
      {"name":"BETON- UND STAHLBETONBAU"},
      {"name":"LARYNGO-RHINO-OTOLOGIE"},
      {"name":"COMPOST SCIENCE & UTILIZATION"},
      {"name":"NOISE & HEALTH"},
      {"name":"ACTA CRYSTALLOGRAPHICA SECTION F-STRUCTURAL BIOLOGY COMMUNICATIONS"},
      {"name":"CANADIAN JOURNAL OF OCCUPATIONAL THERAPY-REVUE CANADIENNE D ERGOTHERAPIE"},
      {"name":"ENGINEERING JOURNAL-AMERICAN INSTITUTE OF STEEL CONSTRUCTION"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-STRUCTURES AND BUILDINGS"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-WATER MANAGEMENT"},
      {"name":"CHILDREN & SOCIETY"},
      {"name":"DU BOIS REVIEW-SOCIAL SCIENCE RESEARCH ON RACE"},
      {"name":"HAND SURGERY & REHABILITATION"},
      {"name":"JOURNAL OF LAW & ECONOMICS"},
      {"name":"ASIA & THE PACIFIC POLICY STUDIES"},
      {"name":"JOURNAL OF LAW ECONOMICS & ORGANIZATION"},
      {"name":"COMMUNIST AND POST-COMMUNIST STUDIES"},
      {"name":"INTERNATIONAL JOURNAL OF FINANCE & ECONOMICS"},
      {"name":"JOURNAL OF NONLINEAR OPTICAL PHYSICS & MATERIALS"},
      {"name":"INQUIRY-AN INTERDISCIPLINARY JOURNAL OF PHILOSOPHY"},
      {"name":"JOURNAL OF PERINATAL & NEONATAL NURSING"},
      {"name":"CHINA REVIEW-AN INTERDISCIPLINARY JOURNAL ON GREATER CHINA"},
      {"name":"CURRENT COMPUTER-AIDED DRUG DESIGN"},
      {"name":"DISCRETE EVENT DYNAMIC SYSTEMS-THEORY AND APPLICATIONS"},
      {"name":"LAW AND SOCIAL INQUIRY-JOURNAL OF THE AMERICAN BAR FOUNDATION"},
      {"name":"JOURNAL OF INVERSE AND ILL-POSED PROBLEMS"},
      {"name":"JOURNAL OF SYSTEMS SCIENCE & COMPLEXITY"},
      {"name":"AMERICAN JOURNAL OF LAW & MEDICINE"},
      {"name":"SPORTVERLETZUNG-SPORTSCHADEN"},
      {"name":"JOURNAL OF FORENSIC PSYCHOLOGY PRACTICE"},
      {"name":"PROCEEDINGS OF THE NATIONAL ACADEMY OF SCIENCES INDIA SECTION A-PHYSICAL SCIENCES"},
      {"name":"ALGEBRA & NUMBER THEORY"},
      {"name":"JNP-JOURNAL FOR NURSE PRACTITIONERS"},
      {"name":"ORAL HEALTH & PREVENTIVE DENTISTRY"},
      {"name":"THERAPEUTIC INNOVATION & REGULATORY SCIENCE"},
      {"name":"ARMED FORCES & SOCIETY"},
      {"name":"GENES & GENETIC SYSTEMS"},
      {"name":"ZEITSCHRIFT FUR KINDER-UND JUGENDPSYCHIATRIE UND PSYCHOTHERAPIE"},
      {"name":"COMPUTER LANGUAGES SYSTEMS & STRUCTURES"},
      {"name":"ARDEOLA-INTERNATIONAL JOURNAL OF ORNITHOLOGY"},
      {"name":"JOURNAL OF MACROMOLECULAR SCIENCE PART B-PHYSICS"},
      {"name":"MATERIALS SCIENCE-POLAND"},
      {"name":"PSICOLOGIA-REFLEXAO E CRITICA"},
      {"name":"ETHICS & INTERNATIONAL AFFAIRS"},
      {"name":"EURE-REVISTA LATINOAMERICANA DE ESTUDIOS URBANO REGIONALES"},
      {"name":"JOURNAL OF NEUROLOGICAL SURGERY PART A-CENTRAL EUROPEAN NEUROSURGERY"},
      {"name":"OXFORD ECONOMIC PAPERS-NEW SERIES"},
      {"name":"CANADIAN JOURNAL OF BEHAVIOURAL SCIENCE-REVUE CANADIENNE DES SCIENCES DU COMPORTEMENT"},
      {"name":"CHILD LANGUAGE TEACHING & THERAPY"},
      {"name":"WATER SCIENCE AND TECHNOLOGY-WATER SUPPLY"},
      {"name":"FAMILY & COMMUNITY HEALTH"},
      {"name":"HUMAN-WILDLIFE INTERACTIONS"},
      {"name":"PUBLIC PERSONNEL MANAGEMENT"},
      {"name":"PIGMENT & RESIN TECHNOLOGY"},
      {"name":"EUROPEAN JOURNAL OF HOSPITAL PHARMACY-SCIENCE AND PRACTICE"},
      {"name":"EAST ASIAN SCIENCE TECHNOLOGY AND SOCIETY-AN INTERNATIONAL JOURNAL"},
      {"name":"AJAR-AFRICAN JOURNAL OF AIDS RESEARCH"},
      {"name":"ANNALES DE LIMNOLOGIE-INTERNATIONAL JOURNAL OF LIMNOLOGY"},
      {"name":"MITOCHONDRIAL DNA PART B-RESOURCES"},
      {"name":"REDIA-GIORNALE DI ZOOLOGIA"},
      {"name":"JOURNAL OF CHILD & ADOLESCENT SUBSTANCE ABUSE"},
      {"name":"EUROPE-ASIA STUDIES"},
      {"name":"INDAGATIONES MATHEMATICAE-NEW SERIES"},
      {"name":"CANADIAN JOURNAL OF MATHEMATICS-JOURNAL CANADIEN DE MATHEMATIQUES"},
      {"name":"GRAVITATION & COSMOLOGY"},
      {"name":"JOURNAL OF RATIONAL-EMOTIVE AND COGNITIVE-BEHAVIOR THERAPY"},
      {"name":"COMBINATORICS PROBABILITY & COMPUTING"},
      {"name":"JOURNAL OF HOSPICE & PALLIATIVE NURSING"},
      {"name":"IRANIAN JOURNAL OF SCIENCE AND TECHNOLOGY TRANSACTION A-SCIENCE"},
      {"name":"JOURNAL OF POLITENESS RESEARCH-LANGUAGE BEHAVIOUR CULTURE"},
      {"name":"POST-COMMUNIST ECONOMIES"},
      {"name":"RBGN-REVISTA BRASILEIRA DE GESTAO DE NEGOCIOS"},
      {"name":"WOMEN & CRIMINAL JUSTICE"},
      {"name":"MECHANICS & INDUSTRY"},
      {"name":"ENCEPHALE-REVUE DE PSYCHIATRIE CLINIQUE BIOLOGIQUE ET THERAPEUTIQUE"},
      {"name":"GYNECOLOGIE OBSTETRIQUE FERTILITE & SENOLOGIE"},
      {"name":"PLANT GENETIC RESOURCES-CHARACTERIZATION AND UTILIZATION"},
      {"name":"GENEVA PAPERS ON RISK AND INSURANCE-ISSUES AND PRACTICE"},
      {"name":"NORSK GEOGRAFISK TIDSSKRIFT-NORWEGIAN JOURNAL OF GEOGRAPHY"},
      {"name":"ENT-EAR NOSE & THROAT JOURNAL"},
      {"name":"CANADIAN JOURNAL OF AGRICULTURAL ECONOMICS-REVUE CANADIENNE D AGROECONOMIE"},
      {"name":"REVISTA BRASILEIRA DE ZOOTECNIA-BRAZILIAN JOURNAL OF ANIMAL SCIENCE"},
      {"name":"AD HOC & SENSOR WIRELESS NETWORKS"},
      {"name":"CONCURRENT ENGINEERING-RESEARCH AND APPLICATIONS"},
      {"name":"SADHANA-ACADEMY PROCEEDINGS IN ENGINEERING SCIENCES"},
      {"name":"JOURNAL OF THEORETICAL & COMPUTATIONAL CHEMISTRY"},
      {"name":"BRITISH JOURNAL OF GUIDANCE & COUNSELLING"},
      {"name":"ANALELE STIINTIFICE ALE UNIVERSITATII OVIDIUS CONSTANTA-SERIA MATEMATICA"},
      {"name":"PROGRESS IN COMMUNITY HEALTH PARTNERSHIPS-RESEARCH EDUCATION AND ACTION"},
      {"name":"ANASTHESIOLOGIE & INTENSIVMEDIZIN"},
      {"name":"ZEITSCHRIFT FUR NATURFORSCHUNG SECTION B-A JOURNAL OF CHEMICAL SCIENCES"},
      {"name":"READING & WRITING QUARTERLY"},
      {"name":"TELEVISION & NEW MEDIA"},
      {"name":"INTERNATIONAL JOURNAL OF NUMERICAL MODELLING-ELECTRONIC NETWORKS DEVICES AND FIELDS"},
      {"name":"ZEMDIRBYSTE-AGRICULTURE"},
      {"name":"JOURNAL OF PEDIATRIC ORTHOPAEDICS-PART B"},
      {"name":"PAST & PRESENT"},
      {"name":"INORGANIC AND NANO-METAL CHEMISTRY"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-CIVIL ENGINEERING"},
      {"name":"FEW-BODY SYSTEMS"},
      {"name":"PUBLICATIONS OF THE RESEARCH INSTITUTE FOR MATHEMATICAL SCIENCES"},
      {"name":"CERAMICS-SILIKATY"},
      {"name":"JPC-JOURNAL OF PLANAR CHROMATOGRAPHY-MODERN TLC"},
      {"name":"MULTILINGUA-JOURNAL OF CROSS-CULTURAL AND INTERLANGUAGE COMMUNICATION"},
      {"name":"CHINESE JOURNAL OF GEOPHYSICS-CHINESE EDITION"},
      {"name":"INFANTS & YOUNG CHILDREN"},
      {"name":"FOOD CULTURE & SOCIETY"},
      {"name":"GLASS TECHNOLOGY-EUROPEAN JOURNAL OF GLASS SCIENCE AND TECHNOLOGY PART A"},
      {"name":"CANADIAN REVIEW OF SOCIOLOGY-REVUE CANADIENNE DE SOCIOLOGIE"},
      {"name":"CMES-COMPUTER MODELING IN ENGINEERING & SCIENCES"},
      {"name":"JOURNAL OF MEDICAL DEVICES-TRANSACTIONS OF THE ASME"},
      {"name":"APPLIED MATHEMATICS-A JOURNAL OF CHINESE UNIVERSITIES SERIES B"},
      {"name":"INTERNATIONAL JOURNAL OF AVIATION PSYCHOLOGY"},
      {"name":"EUROPEAN SURGERY-ACTA CHIRURGICA AUSTRIACA"},
      {"name":"IZVESTIYA-PHYSICS OF THE SOLID EARTH"},
      {"name":"ACTA MATHEMATICAE APPLICATAE SINICA-ENGLISH SERIES"},
      {"name":"ANNALES ACADEMIAE SCIENTIARUM FENNICAE-MATHEMATICA"},
      {"name":"GROUP DYNAMICS-THEORY RESEARCH AND PRACTICE"},
      {"name":"PORTAL-LIBRARIES AND THE ACADEMY"},
      {"name":"HYPATIA-A JOURNAL OF FEMINIST PHILOSOPHY"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-ENERGY"},
      {"name":"ATLA-ALTERNATIVES TO LABORATORY ANIMALS"},
      {"name":"ISJ-INVERTEBRATE SURVIVAL JOURNAL"},
      {"name":"SORT-STATISTICS AND OPERATIONS RESEARCH TRANSACTIONS"},
      {"name":"CANADIAN PUBLIC POLICY-ANALYSE DE POLITIQUES"},
      {"name":"FIBRES & TEXTILES IN EASTERN EUROPE"},
      {"name":"GEOMORPHOLOGIE-RELIEF PROCESSUS ENVIRONNEMENT"},
      {"name":"MERRILL-PALMER QUARTERLY-JOURNAL OF DEVELOPMENTAL PSYCHOLOGY"},
      {"name":"NORDIC PULP & PAPER RESEARCH JOURNAL"},
      {"name":"CONTEMPORARY SOCIOLOGY-A JOURNAL OF REVIEWS"},
      {"name":"VETERINARY CLINICS OF NORTH AMERICA-EQUINE PRACTICE"},
      {"name":"JOURNAL OF LOSS & TRAUMA"},
      {"name":"STATE POLITICS & POLICY QUARTERLY"},
      {"name":"EUROPEAN REVIEW OF APPLIED PSYCHOLOGY-REVUE EUROPEENNE DE PSYCHOLOGIE APPLIQUEE"},
      {"name":"FORSCHUNG IM INGENIEURWESEN-ENGINEERING RESEARCH"},
      {"name":"CANADIAN PUBLIC ADMINISTRATION-ADMINISTRATION PUBLIQUE DU CANADA"},
      {"name":"KOVOVE MATERIALY-METALLIC MATERIALS"},
      {"name":"PLANKTON & BENTHOS RESEARCH"},
      {"name":"INFORMATION RESEARCH-AN INTERNATIONAL ELECTRONIC JOURNAL"},
      {"name":"AQUATIC ECOSYSTEM HEALTH & MANAGEMENT"},
      {"name":"PROCEEDINGS OF THE INSTITUTION OF CIVIL ENGINEERS-MUNICIPAL ENGINEER"},
      {"name":"STOCHASTICS-AN INTERNATIONAL JOURNAL OF PROBABILITY AND STOCHASTIC PROCESSES"},
      {"name":"REVUE ROUMAINE DES SCIENCES TECHNIQUES-SERIE ELECTROTECHNIQUE ET ENERGETIQUE"},
      {"name":"ASIA-PACIFIC JOURNAL OF OPERATIONAL RESEARCH"},
      {"name":"CANADIAN JOURNAL OF ADMINISTRATIVE SCIENCES-REVUE CANADIENNE DES SCIENCES DE L ADMINISTRATION"},
      {"name":"ENVIRONMENTAL & ENGINEERING GEOSCIENCE"},
      {"name":"FILOSOFIJA-SOCIOLOGIJA"},
      {"name":"NORTH-WESTERN JOURNAL OF ZOOLOGY"},
      {"name":"ASIA-PACIFIC EDUCATION RESEARCHER"},
      {"name":"ACADEMIA-REVISTA LATINOAMERICANA DE ADMINISTRACION"},
      {"name":"JOURNAL OF ELECTRICAL ENGINEERING & TECHNOLOGY"},
      {"name":"INTERNATIONAL JOURNAL OF TURBO & JET-ENGINES"},
      {"name":"SYMMETRY INTEGRABILITY AND GEOMETRY-METHODS AND APPLICATIONS"},
      {"name":"JOURNAL OF SCIENTIFIC & INDUSTRIAL RESEARCH"},
      {"name":"NATIONALITIES PAPERS-THE JOURNAL OF NATIONALISM AND ETHNICITY"},
      {"name":"PAN-PACIFIC ENTOMOLOGIST"},
      {"name":"CHEMICAL INDUSTRY & CHEMICAL ENGINEERING QUARTERLY"},
      {"name":"SERIES-JOURNAL OF THE SPANISH ECONOMIC ASSOCIATION"},
      {"name":"INTERNATIONAL JOURNAL OF MATERIALS & PRODUCT TECHNOLOGY"},
      {"name":"SCRIPTA NOVA-REVISTA ELECTRONICA DE GEOGRAFIA Y CIENCIAS SOCIALES"},
      {"name":"HUMOR-INTERNATIONAL JOURNAL OF HUMOR RESEARCH"},
      {"name":"CANADIAN JOURNAL OF ECONOMICS-REVUE CANADIENNE D ECONOMIQUE"},
      {"name":"REFERENCE & USER SERVICES QUARTERLY"},
      {"name":"NOROPSIKIYATRI ARSIVI-ARCHIVES OF NEUROPSYCHIATRY"},
      {"name":"ASIA-PACIFIC JOURNAL OF ACCOUNTING & ECONOMICS"},
      {"name":"CONVERGENCIA-REVISTA DE CIENCIAS SOCIALES"},
      {"name":"JOURNAL OF MULTIPLE-VALUED LOGIC AND SOFT COMPUTING"},
      {"name":"REVISTA PANAMERICANA DE SALUD PUBLICA-PAN AMERICAN JOURNAL OF PUBLIC HEALTH"},
      {"name":"TROPICAL GRASSLANDS-FORRAJES TROPICALES"},
      {"name":"HORTICULTURAL SCIENCE & TECHNOLOGY"},
      {"name":"M S-MEDECINE SCIENCES"},
      {"name":"ACTA VETERINARIA-BEOGRAD"},
      {"name":"FAMILIES IN SOCIETY-THE JOURNAL OF CONTEMPORARY SOCIAL SERVICES"},
      {"name":"CONCEPTS IN MAGNETIC RESONANCE PART B-MAGNETIC RESONANCE ENGINEERING"},
      {"name":"LEX LOCALIS-JOURNAL OF LOCAL SELF-GOVERNMENT"},
      {"name":"JOURNAL OF ELECTRICAL ENGINEERING-ELEKTROTECHNICKY CASOPIS"},
      {"name":"STUDIES IN HISTORY AND PHILOSOPHY OF SCIENCE PART C-STUDIES IN HISTORY AND PHILOSOPHY OF BIOLOGICAL AND BIOMEDICAL SCIENCES"},
      {"name":"STATISTICS & PROBABILITY LETTERS"},
      {"name":"IDENTITIES-GLOBAL STUDIES IN CULTURE AND POWER"},
      {"name":"SCIENCE & SPORTS"},
      {"name":"SOCIETY & ANIMALS"},
      {"name":"LAW PROBABILITY & RISK"},
      {"name":"POLITICS PHILOSOPHY & ECONOMICS"},
      {"name":"MONTHLY REVIEW-AN INDEPENDENT SOCIALIST MAGAZINE"},
      {"name":"PUBLICATIONES MATHEMATICAE-DEBRECEN"},
      {"name":"CULTURAL STUDIES-CRITICAL METHODOLOGIES"},
      {"name":"QUALITY ASSURANCE AND SAFETY OF CROPS & FOODS"},
      {"name":"TEHNICKI VJESNIK-TECHNICAL GAZETTE"},
      {"name":"ECO MONT-JOURNAL ON PROTECTED MOUNTAIN AREAS RESEARCH"},
      {"name":"GLQ-A JOURNAL OF LESBIAN AND GAY STUDIES"},
      {"name":"REVSTAT-STATISTICAL JOURNAL"},
      {"name":"PROMET-TRAFFIC & TRANSPORTATION"},
      {"name":"CHEMICAL AND PROCESS ENGINEERING-INZYNIERIA CHEMICZNA I PROCESOWA"},
      {"name":"JAAPA-JOURNAL OF THE AMERICAN ACADEMY OF PHYSICIAN ASSISTANTS"},
      {"name":"ACTA GASTRO-ENTEROLOGICA BELGICA"},
      {"name":"IRANIAN JOURNAL OF SCIENCE AND TECHNOLOGY-TRANSACTIONS OF ELECTRICAL ENGINEERING"},
      {"name":"CANADIAN JOURNAL OF STATISTICS-REVUE CANADIENNE DE STATISTIQUE"},
      {"name":"CANADIAN MATHEMATICAL BULLETIN-BULLETIN CANADIEN DE MATHEMATIQUES"},
      {"name":"BIOLOGY AND ENVIRONMENT-PROCEEDINGS OF THE ROYAL IRISH ACADEMY"},
      {"name":"CHUNGARA-REVISTA DE ANTROPOLOGIA CHILENA"},
      {"name":"PHYTON-ANNALES REI BOTANICAE"},
      {"name":"INDIAN JOURNAL OF PURE & APPLIED PHYSICS"},
      {"name":"COMMUNICATIONS IN STATISTICS-SIMULATION AND COMPUTATION"},
      {"name":"ENGLISH TEACHING-PRACTICE AND CRITIQUE"},
      {"name":"IRAL-INTERNATIONAL REVIEW OF APPLIED LINGUISTICS IN LANGUAGE TEACHING"},
      {"name":"METALLURGICAL RESEARCH & TECHNOLOGY"},
      {"name":"ULUSAL TRAVMA VE ACIL CERRAHI DERGISI-TURKISH JOURNAL OF TRAUMA & EMERGENCY SURGERY"},
      {"name":"JOURNAL OF WUHAN UNIVERSITY OF TECHNOLOGY-MATERIALS SCIENCE EDITION"},
      {"name":"NEPHROLOGIE & THERAPEUTIQUE"},
      {"name":"EUROPEAN PHYSICAL JOURNAL-APPLIED PHYSICS"},
      {"name":"CANADIAN VETERINARY JOURNAL-REVUE VETERINAIRE CANADIENNE"},
      {"name":"FINANCE A UVER-CZECH JOURNAL OF ECONOMICS AND FINANCE"},
      {"name":"MATERIALS SCIENCE-MEDZIAGOTYRA"},
      {"name":"ALEA-LATIN AMERICAN JOURNAL OF PROBABILITY AND MATHEMATICAL STATISTICS"},
      {"name":"HOMO-JOURNAL OF COMPARATIVE HUMAN BIOLOGY"},
      {"name":"DISCRETE & COMPUTATIONAL GEOMETRY"},
      {"name":"GERMAN JOURNAL OF HUMAN RESOURCE MANAGEMENT-ZEITSCHRIFT FUR PERSONALFORSCHUNG"},
      {"name":"UNIVERSITY POLITEHNICA OF BUCHAREST SCIENTIFIC BULLETIN-SERIES A-APPLIED MATHEMATICS AND PHYSICS"},
      {"name":"ACTA MATHEMATICA SINICA-ENGLISH SERIES"},
      {"name":"PROBABILITY AND MATHEMATICAL STATISTICS-POLAND"},
      {"name":"ACTA SCIENTIARUM POLONORUM-HORTORUM CULTUS"},
      {"name":"BIOLOGICALLY INSPIRED COGNITIVE ARCHITECTURES"},
      {"name":"NATURE + CULTURE"},
      {"name":"COMMUNICATIONS IN STATISTICS-THEORY AND METHODS"},
      {"name":"IDS BULLETIN-INSTITUTE OF DEVELOPMENT STUDIES"},
      {"name":"IRANIAN JOURNAL OF CHEMISTRY & CHEMICAL ENGINEERING-INTERNATIONAL ENGLISH EDITION"},
      {"name":"JOURNAL OF ELECTRONIC TESTING-THEORY AND APPLICATIONS"},
      {"name":"CHINA PETROLEUM PROCESSING & PETROCHEMICAL TECHNOLOGY"},
      {"name":"COMPEL-THE INTERNATIONAL JOURNAL FOR COMPUTATION AND MATHEMATICS IN ELECTRICAL AND ELECTRONIC ENGINEERING"},
      {"name":"GOSPODARKA SUROWCAMI MINERALNYMI-MINERAL RESOURCES MANAGEMENT"},
      {"name":"CANADIAN JOURNAL OF POLITICAL SCIENCE-REVUE CANADIENNE DE SCIENCE POLITIQUE"},
      {"name":"ACTA BIOLOGICA HUNGARICA"},
      {"name":"INVERTEBRATE REPRODUCTION & DEVELOPMENT"},
      {"name":"ENGLISH WORLD-WIDE"},
      {"name":"LIBRI"},
      {"name":"QME-QUANTITATIVE MARKETING AND ECONOMICS"},
      {"name":"TM-TECHNISCHES MESSEN"},
      {"name":"BSGF-EARTH SCIENCES BULLETIN"},
      {"name":"ASIA-PACIFIC JOURNAL OF FINANCIAL STUDIES"},
      {"name":"ESTUDIOS GEOLOGICOS-MADRID"},
      {"name":"CHEMICAL JOURNAL OF CHINESE UNIVERSITIES-CHINESE"},
      {"name":"ICSID REVIEW-FOREIGN INVESTMENT LAW JOURNAL"},
      {"name":"ORDER-A JOURNAL ON THE THEORY OF ORDERED SETS AND ITS APPLICATIONS"},
      {"name":"REVISTA IBEROAMERICANA DE DIAGNOSTICO Y EVALUACION-E AVALIACAO PSICOLOGICA"},
      {"name":"RUSSIAN JOURNAL OF NON-FERROUS METALS"},
      {"name":"TURKIYE ENTOMOLOJI DERGISI-TURKISH JOURNAL OF ENTOMOLOGY"},
      {"name":"SEQUENTIAL ANALYSIS-DESIGN METHODS AND APPLICATIONS"},
      {"name":"JOURNAL OF COMPETITION LAW & ECONOMICS"},
      {"name":"ITEA-INFORMACION TECNICA ECONOMICA AGRARIA"},
      {"name":"REVUE SCIENTIFIQUE ET TECHNIQUE-OFFICE INTERNATIONAL DES EPIZOOTIES"},
      {"name":"PERSPECTIVES-STUDIES IN TRANSLATION THEORY AND PRACTICE"},
      {"name":"ZEITSCHRIFT FUR ARBEITS-UND ORGANISATIONSPSYCHOLOGIE"},
      {"name":"HISPANIA-A JOURNAL DEVOTED TO THE TEACHING OF SPANISH AND PORTUGUESE"},
      {"name":"TURKISH JOURNAL OF VETERINARY & ANIMAL SCIENCES"},
      {"name":"ACTA ENDOCRINOLOGICA-BUCHAREST"},
      {"name":"ECONOMIST-NETHERLANDS"},
      {"name":"HACIENDA PUBLICA ESPANOLA-REVIEW OF PUBLIC ECONOMICS"},
      {"name":"IMAGE ANALYSIS & STEREOLOGY"},
      {"name":"JOURNAL OF BUSINESS-TO-BUSINESS MARKETING"},
      {"name":"AUSTRALIAN & NEW ZEALAND JOURNAL OF STATISTICS"},
      {"name":"REVISTA ROMANA DE MATERIALE-ROMANIAN JOURNAL OF MATERIALS"},
      {"name":"INDIAN JOURNAL OF BIOCHEMISTRY & BIOPHYSICS"},
      {"name":"JOURNAL OF VIBRATION ENGINEERING & TECHNOLOGIES"},
      {"name":"FOLIA BIOLOGICA-KRAKOW"},
      {"name":"JOURNAL OF PSEUDO-DIFFERENTIAL OPERATORS AND APPLICATIONS"},
      {"name":"ADVANCES IN STRATEGIC MANAGEMENT-A RESEARCH ANNUAL"},
      {"name":"POLYMER-KOREA"},
      {"name":"CHILD & FAMILY BEHAVIOR THERAPY"},
      {"name":"ARCHIVE FOR THE PSYCHOLOGY OF RELIGION-ARCHIV FUR RELIGIONSPSYCHOLOGIE"},
      {"name":"INDIAN JOURNAL OF PURE & APPLIED MATHEMATICS"},
      {"name":"NOTFALL & RETTUNGSMEDIZIN"},
      {"name":"BULLETIN OF THE BELGIAN MATHEMATICAL SOCIETY-SIMON STEVIN"},
      {"name":"ESAIM-PROBABILITY AND STATISTICS"},
      {"name":"ASTRONOMY & GEOPHYSICS"},
      {"name":"ENGLISH LANGUAGE & LINGUISTICS"},
      {"name":"LINGUISTICA ANTVERPIENSIA NEW SERIES-THEMES IN TRANSLATION STUDIES"},
      {"name":"NETWORK-COMPUTATION IN NEURAL SYSTEMS"},
      {"name":"JOURNAL OF CONTEMPORARY PHYSICS-ARMENIAN ACADEMY OF SCIENCES"},
      {"name":"STUDIA UNIVERSITATIS BABES-BOLYAI CHEMIA"},
      {"name":"EGITIM VE BILIM-EDUCATION AND SCIENCE"},
      {"name":"JOURNAL OF BELIEFS & VALUES-STUDIES IN RELIGION & EDUCATION"},
      {"name":"SHILAP-REVISTA DE LEPIDOPTEROLOGIA"},
      {"name":"INDIAN JOURNAL OF CHEMISTRY SECTION A-INORGANIC BIO-INORGANIC PHYSICAL THEORETICAL & ANALYTICAL CHEMISTRY"},
      {"name":"AT-AUTOMATISIERUNGSTECHNIK"},
      {"name":"ASIAN-PACIFIC ECONOMIC LITERATURE"},
      {"name":"JOURNAL OF VISUAL IMPAIRMENT & BLINDNESS"},
      {"name":"LIBRARY RESOURCES & TECHNICAL SERVICES"},
      {"name":"TARGET-INTERNATIONAL JOURNAL OF TRANSLATION STUDIES"},
      {"name":"INFORMATION & CULTURE"},
      {"name":"ENTERPRISE & SOCIETY"},
      {"name":"REVISTA DE ECONOMIA APLICADA"},
      {"name":"PROCEEDINGS OF THE JAPAN ACADEMY SERIES A-MATHEMATICAL SCIENCES"},
      {"name":"COMPUTATIONAL GEOMETRY-THEORY AND APPLICATIONS"},
      {"name":"INTERNATIONAL JOURNAL OF ART & DESIGN EDUCATION"},
      {"name":"RAE-REVISTA DE ADMINISTRACAO DE EMPRESAS"},
      {"name":"HISTORICAL SOCIAL RESEARCH-HISTORISCHE SOZIALFORSCHUNG"},
      {"name":"FORUM-A JOURNAL OF APPLIED RESEARCH IN CONTEMPORARY POLITICS"},
      {"name":"CHEMICAL & ENGINEERING NEWS"},
      {"name":"INTERNATIONAL JOURNAL OF INDUSTRIAL ENGINEERING-THEORY APPLICATIONS AND PRACTICE"},
      {"name":"MEDICINA-BUENOS AIRES"},
      {"name":"INDIAN JOURNAL OF FIBRE & TEXTILE RESEARCH"},
      {"name":"FUNKCIALAJ EKVACIOJ-SERIO INTERNACIA"},
      {"name":"OPTOELECTRONICS AND ADVANCED MATERIALS-RAPID COMMUNICATIONS"},
      {"name":"JOURNAL OF MARINE SCIENCE AND TECHNOLOGY-TAIWAN"},
      {"name":"POLITICS RELIGION & IDEOLOGY"},
      {"name":"RELATIONS INDUSTRIELLES-INDUSTRIAL RELATIONS"},
      {"name":"JARQ-JAPAN AGRICULTURAL RESEARCH QUARTERLY"},
      {"name":"POLAR-POLITICAL AND LEGAL ANTHROPOLOGY REVIEW"},
      {"name":"SOCIAL PHILOSOPHY & POLICY"},
      {"name":"TETSU TO HAGANE-JOURNAL OF THE IRON AND STEEL INSTITUTE OF JAPAN"},
      {"name":"PUERTO RICO HEALTH SCIENCES JOURNAL"},
      {"name":"VIE ET MILIEU-LIFE AND ENVIRONMENT"},
      {"name":"LANGUAGE PROBLEMS & LANGUAGE PLANNING"},
      {"name":"BIJDRAGEN TOT DE TAAL- LAND- EN VOLKENKUNDE"},
      {"name":"JCPSP-JOURNAL OF THE COLLEGE OF PHYSICIANS AND SURGEONS PAKISTAN"},
      {"name":"HIGH TEMPERATURES-HIGH PRESSURES"},
      {"name":"ACTA SCIENTIARUM-TECHNOLOGY"},
      {"name":"HISTORICAL MATERIALISM-RESEARCH IN CRITICAL MARXIST THEORY"},
      {"name":"JOURNAL OF AGRICULTURAL SCIENCES-TARIM BILIMLERI DERGISI"},
      {"name":"REVISTA LATINOAMERICANA DE INVESTIGACION EN MATEMATICA EDUCATIVA-RELIME"},
      {"name":"NATIONAL ACADEMY SCIENCE LETTERS-INDIA"},
      {"name":"ALLGEMEINE FORST UND JAGDZEITUNG"},
      {"name":"SOCIOLOGICKY CASOPIS-CZECH SOCIOLOGICAL REVIEW"},
      {"name":"SEMINA-CIENCIAS AGRARIAS"},
      {"name":"JOURNAL OF EVIDENCE-BASED PSYCHOTHERAPIES"},
      {"name":"GENETIKA-BELGRADE"},
      {"name":"SOUTH ASIA-JOURNAL OF SOUTH ASIAN STUDIES"},
      {"name":"SUGAR INDUSTRY-ZUCKERINDUSTRIE"},
      {"name":"SOLDAGEM & INSPECAO"},
      {"name":"INDIAN JOURNAL OF CHEMISTRY SECTION B-ORGANIC CHEMISTRY INCLUDING MEDICINAL CHEMISTRY"},
      {"name":"TIERARZTLICHE PRAXIS"},
      {"name":"WOMEN & THERAPY"},
      {"name":"REVISTA DE NUTRICAO-BRAZILIAN JOURNAL OF NUTRITION"},
      {"name":"INTERVENTIONS-INTERNATIONAL JOURNAL OF POSTCOLONIAL STUDIES"},
      {"name":"CONTINUUM-JOURNAL OF MEDIA & CULTURAL STUDIES"},
      {"name":"TURKISH JOURNAL OF BIOCHEMISTRY-TURK BIYOKIMYA DERGISI"},
      {"name":"NOTES AND RECORDS-THE ROYAL SOCIETY JOURNAL OF THE HISTORY OF SCIENCE"},
      {"name":"PURE AND APPLIED MATHEMATICS QUARTERLY"},
      {"name":"LABOUR-LE TRAVAIL"},
      {"name":"MANAGEMENT & ORGANIZATIONAL HISTORY"},
      {"name":"SOCIAL DYNAMICS-A JOURNAL OF AFRICAN STUDIES"},
      {"name":"INTER-ASIA CULTURAL STUDIES"},
      {"name":"INTERNATIONAL LABOR AND WORKING-CLASS HISTORY"},
      {"name":"SCIENCE & SOCIETY"},
      {"name":"ADVANCED MATERIALS & PROCESSES"},
      {"name":"ISRAEL JOURNAL OF ECOLOGY & EVOLUTION"},
      {"name":"RURAL HISTORY-ECONOMY SOCIETY CULTURE"},
      {"name":"CANADIAN MODERN LANGUAGE REVIEW-REVUE CANADIENNE DES LANGUES VIVANTES"},
      {"name":"INFORMACIJE MIDEM-JOURNAL OF MICROELECTRONICS ELECTRONIC COMPONENTS AND MATERIALS"},
      {"name":"TRAMES-JOURNAL OF THE HUMANITIES AND SOCIAL SCIENCES"},
      {"name":"IDEGGYOGYASZATI SZEMLE-CLINICAL NEUROSCIENCE"},
      {"name":"PSYCHE-ZEITSCHRIFT FUR PSYCHOANALYSE UND IHRE ANWENDUNGEN"},
      {"name":"ASIAN JOURNAL OF WTO & INTERNATIONAL HEALTH LAW AND POLICY"},
      {"name":"COMPETITION POLICY INTERNATIONAL"},
      {"name":"HISTORY OF GEO- AND SPACE SCIENCES"},
      {"name":"REVUE D ECOLOGIE-LA TERRE ET LA VIE"},
      {"name":"YAKUGAKU ZASSHI-JOURNAL OF THE PHARMACEUTICAL SOCIETY OF JAPAN"},
      {"name":"B E JOURNAL OF ECONOMIC ANALYSIS & POLICY"},
      {"name":"ANADOLU PSIKIYATRI DERGISI-ANATOLIAN JOURNAL OF PSYCHIATRY"},
      {"name":"PHYTON-INTERNATIONAL JOURNAL OF EXPERIMENTAL BOTANY"},
      {"name":"CCAMLR SCIENCE"},
      {"name":"INDIAN JOURNAL OF GEO-MARINE SCIENCES"},
      {"name":"JOURNAL OF INSTITUTIONAL AND THEORETICAL ECONOMICS-ZEITSCHRIFT FUR DIE GESAMTE STAATSWISSENSCHAFT"},
      {"name":"TEXT & TALK"},
      {"name":"REVUE DE PNEUMOLOGIE CLINIQUE"},
      {"name":"ACTA AGRICULTURAE SCANDINAVICA SECTION A-ANIMAL SCIENCE"},
      {"name":"CHINA-AN INTERNATIONAL JOURNAL"},
      {"name":"PRAKTISCHE METALLOGRAPHIE-PRACTICAL METALLOGRAPHY"},
      {"name":"AIBR-REVISTA DE ANTROPOLOGIA IBEROAMERICANA"},
      {"name":"LIGHT & ENGINEERING"},
      {"name":"CROATIAN JOURNAL OF EDUCATION-HRVATSKI CASOPIS ZA ODGOJ I OBRAZOVANJE"},
      {"name":"JOURNAL OF FOOD SAFETY AND FOOD QUALITY-ARCHIV FUR LEBENSMITTELHYGIENE"},
      {"name":"NWIG-NEW WEST INDIAN GUIDE-NIEUWE WEST-INDISCHE GIDS"},
      {"name":"BMGN-THE LOW COUNTRIES HISTORICAL REVIEW"},
      {"name":"ZEITSCHRIFTE FUR FAMILIENFORSCHUNG-JOURNAL OF FAMILY RESEARCH"},
      {"name":"FRONTIERS-A JOURNAL OF WOMEN STUDIES"},
      {"name":"OIL GAS-EUROPEAN MAGAZINE"},
      {"name":"ARCHEOSCIENCES-REVUE D ARCHEOMETRIE"},
      {"name":"LAZAROA"},
      {"name":"ZEITSCHRIFT FUR KRISTALLOGRAPHIE-NEW CRYSTAL STRUCTURES"},
      {"name":"CT&F-CIENCIA TECNOLOGIA Y FUTURO"},
      {"name":"DIPLOMACY & STATECRAFT"},
      {"name":"RIDE-THE JOURNAL OF APPLIED THEATRE AND PERFORMANCE"},
      {"name":"DIFFERENCES-A JOURNAL OF FEMINIST CULTURAL STUDIES"},
      {"name":"FORKTAIL"},
      {"name":"DADOS-REVISTA DE CIENCIAS SOCIAIS"},
      {"name":"BOUNDARY 2-AN INTERNATIONAL JOURNAL OF LITERATURE AND CULTURE"},
      {"name":"MEDYCYNA WETERYNARYJNA-VETERINARY MEDICINE-SCIENCE AND PRACTICE"},
      {"name":"ANNALES MEDICO-PSYCHOLOGIQUES"},
      {"name":"ISRAELI JOURNAL OF AQUACULTURE-BAMIDGEH"},
      {"name":"ITINERARIO-INTERNATIONAL JOURNAL ON THE HISTORY OF EUROPEAN EXPANSION AND GLOBAL INTERACTION"},
      {"name":"PROCEEDINGS OF THE INDIAN ACADEMY OF SCIENCES-MATHEMATICAL SCIENCES"},
      {"name":"TURKISH JOURNAL OF GERIATRICS-TURK GERIATRI DERGISI"},
      {"name":"GENERATIONS-JOURNAL OF THE AMERICAN SOCIETY ON AGING"},
      {"name":"HOUILLE BLANCHE-REVUE INTERNATIONALE DE L EAU"},
      {"name":"ETHICS & GLOBAL POLITICS"},
      {"name":"POSTEPY MIKROBIOLOGII"},
      {"name":"ATLANTIS-JOURNAL OF THE SPANISH ASSOCIATION OF ANGLO-AMERICAN STUDIES"},
      {"name":"CURRICULUM MATTERS"},
      {"name":"NUNCIUS-JOURNAL OF THE HISTORY OF SCIENCE"},
      {"name":"REVUE ROUMAINE DE LINGUISTIQUE-ROMANIAN REVIEW OF LINGUISTICS"},
      {"name":"ITE JOURNAL-INSTITUTE OF TRANSPORTATION ENGINEERS"},
      {"name":"ISI BILIMI VE TEKNIGI DERGISI-JOURNAL OF THERMAL SCIENCE AND TECHNOLOGY"},
      {"name":"J-FOR-JOURNAL OF SCIENCE & TECHNOLOGY FOR FOREST PRODUCTS AND PROCESSES"},
      {"name":"INFORMACAO & SOCIEDADE-ESTUDOS"},
      {"name":"REVUE D ETUDES COMPARATIVES EST-OUEST"},
      {"name":"ULUSLARARASI ILISKILER-INTERNATIONAL RELATIONS"},
      {"name":"B-ENT"},
      {"name":"MEDECINE NUCLEAIRE-IMAGERIE FONCTIONNELLE ET METABOLIQUE"},
      {"name":"KGK-KAUTSCHUK GUMMI KUNSTSTOFFE"},
      {"name":"REVUE DE GEOGRAPHIE ALPINE-JOURNAL OF ALPINE RESEARCH"},
      {"name":"FUJITSU SCIENTIFIC & TECHNICAL JOURNAL"},
      {"name":"IMAGO MUNDI-THE INTERNATIONAL JOURNAL FOR THE HISTORY OF CARTOGRAPHY"},
      {"name":"WAR & SOCIETY"},
      {"name":"POSTMEDIEVAL-A JOURNAL OF MEDIEVAL CULTURAL STUDIES"},
      {"name":"TOPIA-CANADIAN JOURNAL OF CULTURAL STUDIES"},
      {"name":"TIJDSCHRIFT VOOR RECHTSGESCHIEDENIS-REVUE D HISTOIRE DU DROIT-THE LEGAL HISTORY REVIEW"},
      {"name":"CRITICAL ARTS-SOUTH-NORTH CULTURAL AND MEDIA STUDIES"},
      {"name":"ACUPUNCTURE & ELECTRO-THERAPEUTICS RESEARCH"},
      {"name":"JOURNAL OF CONTEMPORARY MATHEMATICAL ANALYSIS-ARMENIAN ACADEMY OF SCIENCES"},
      {"name":"TURK GOGUS KALP DAMAR CERRAHISI DERGISI-TURKISH JOURNAL OF THORACIC AND CARDIOVASCULAR SURGERY"},
      {"name":"PRAGMATICS & COGNITION"},
      {"name":"JOURNAL OF THE JAPANESE SOCIETY FOR FOOD SCIENCE AND TECHNOLOGY-NIPPON SHOKUHIN KAGAKU KOGAKU KAISHI"},
      {"name":"CANADIAN JOURNAL OF INFORMATION AND LIBRARY SCIENCE-REVUE CANADIENNE DES SCIENCES DE L INFORMATION ET DE BIBLIOTHECONOMIE"},
      {"name":"RLA-REVISTA DE LINGUISTICA TEORICA Y APLICADA"},
      {"name":"RILCE-REVISTA DE FILOLOGIA HISPANICA"},
      {"name":"MATERIA-RIO DE JANEIRO"},
      {"name":"RESTAURATOR-INTERNATIONAL JOURNAL FOR THE PRESERVATION OF LIBRARY AND ARCHIVAL MATERIAL"},
      {"name":"AMA-AGRICULTURAL MECHANIZATION IN ASIA AFRICA AND LATIN AMERICA"},
      {"name":"CHEMISTRY & INDUSTRY"},
      {"name":"NAMES-A JOURNAL OF ONOMASTICS"},
      {"name":"CLINICAL AND EXPERIMENTAL OBSTETRICS & GYNECOLOGY"},
      {"name":"SOZIALE WELT-ZEITSCHRIFT FUR SOZIALWISSENSCHAFTLICHE FORSCHUNG UND PRAXIS"},
      {"name":"RAIRO-THEORETICAL INFORMATICS AND APPLICATIONS"},
      {"name":"ISSUES IN LAW & MEDICINE"},
      {"name":"CULTURE & HISTORY DIGITAL JOURNAL"},
      {"name":"BABEL-REVUE INTERNATIONALE DE LA TRADUCTION-INTERNATIONAL JOURNAL OF TRANSLATION"},
      {"name":"GEDRAG & ORGANISATIE"},
      {"name":"FILTRATION & SEPARATION"},
      {"name":"PSYCHO-ONCOLOGIE"},
      {"name":"PROGRESS IN TUMOR RESEARCH"},
      {"name":"ATW-INTERNATIONAL JOURNAL FOR NUCLEAR POWER"},
      {"name":"DUTCH CROSSING-JOURNAL OF LOW COUNTRIES STUDIES"},
      {"name":"ZEITSCHRIFT FUR ARZNEI- & GEWURZPFLANZEN"},
      {"name":"MINNESOTA SYMPOSIA ON CHILD PSYCHOLOGY"},
      {"name":"HONG KONG JOURNAL OF DERMATOLOGY & VENEREOLOGY"},
      {"name":"LANGUAGE & HISTORY"},
      {"name":"OIL & GAS JOURNAL"},
      {"name":"DEUTSCHE LEBENSMITTEL-RUNDSCHAU"},
      {"name":"JOURNAL OF NEUROLOGICAL SCIENCES-TURKISH"},
      {"name":"PULP & PAPER-CANADA"},
      {"name":"SEN-I GAKKAISHI"},
      {"name":"MINING METALLURGY & EXPLORATION"},
      {"name":"POLYMER-PLASTICS TECHNOLOGY AND MATERIALS"},
      {"name":"WOUND MANAGEMENT & PREVENTION"}
 ]
}

// getters
const getters = {

}

// actions
const actions = {



}

// mutations
const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
