//let serverBase='http://127.0.0.1:18888/api'

import commonConfig from "../../../common/commonConfig";
import config from "../../../config/config"
let debug=commonConfig.debug;

let ENCRYPT_RESTRESULT=commonConfig.ENCRYPT_RESTRESULT;
let autoRemFontSize=true


let serverBase=config.baseUrl;


let regx={
  phone:/^1\d{10}$/,
  password : /^[A-Za-z0-9]{6,30}$/,
  piccode : /^[A-Za-z0-9]{4,10}$/,
  email : /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
}



// if (!debug){
//   serverBase=''
// }

export default {
  serverBase,debug,regx,autoRemFontSize,ENCRYPT_RESTRESULT
}
