<template>
  <div id="global_tip"  class="global_tip">


    <div class="tip-center-warp">

        <div class="top">
          <div class="tip-text">{{topTip}}</div>
          <div v-show="canClose" @click="onCloseClick" class="close">+</div>
        </div>
        <div class="text-warp">

          <div class="text">
            <span v-show="tipType=='e'" class="error">!</span>
            <span v-show="tipType=='s'" class="success">&radic;</span>
            <span  @click="textClick" style="color: black" v-html="tipText"></span>
            <div style="margin: 0 auto;display: flex;justify-content: center" v-show="getRes(configData,'strConfig')&& wxOpen">
              <img style="display: inline-block; margin: 0 auto; width: 200px;height: 200px;" :src="getRes(configData,'strConfig')" alt="">
            </div>
          </div>
          <div class="bn-warp">
            <div  v-show="canClose"  @click="onOkClick" class="bn" >
              <div v-text="okBnText"></div>
            </div>
          </div>

        </div>

        </div>


  </div>
</template>

<script>
  import $ from 'jquery'

  import utils from "../api/common/utils";
  import commonUtils from "../../common/commonUtils";
  import configApi from "../api/configApi";

  export default {
    name: 'globalTip',
    data () {
      return {
        wxOpen:false,
        configData:''
      }
    },
    methods:{
      textClick(e){
        try {
          let set=e.target.dataset
          let d=set.d
          console.log(e.target,set)
          if(d=='wx'){
            this.wxOpen=!this.wxOpen
          }
        }catch (e){

        }
      },
      getClientHeight(){
        //获取窗口可视范围的高度
        var clientHeight=0;
        if(document.body.clientHeight&&document.documentElement.clientHeight){
          var clientHeight=(document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
        }else{
          var clientHeight=(document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
        }
        return clientHeight;
      },
      getScrollTop(){
        var scrollTop=0;
        if(document.documentElement&&document.documentElement.scrollTop){
          scrollTop=document.documentElement.scrollTop;
        }else if(document.body){
          scrollTop=document.body.scrollTop;
        }
        return scrollTop;
      }

      ,
      getSelfWidth(){

       return utils.getSelfWidth()

      }

      ,
      onOkClick(){

        this.$emit('okClick', 'ok');
      }
      ,
      onCloseClick(){
          this.$emit('okClose', 'ok');
      },
      changeGlobalTipShadowSize(){
        $('#global_tip').height((commonUtils.getSelfHeight()));
        $('#global_tip').width((this.getSelfWidth()));
      },
      getQrConfig(){
        // configApi.getPayQrData().
        // then((data)=>{
        //
        // }).catch(()=>{
        //   window.globalMethods.swichTip(true,'e','网络错误,获取二维码信息出错')
        // })
        // this.configData={
        //   "strConfig":'https://static.home-for-researchers.com/service/wx-research.jpg'
        // }
      }

    },
    mounted() {



      this.getQrConfig();
      setTimeout(()=>{
        this.changeGlobalTipShadowSize();
      },500)


      // $('.tip-center-warp').css('margin-top',that.getClientHeight()*.4);

      $(window).resize(() => {

        this.changeGlobalTipShadowSize()


      })

    }
    ,
    props: {
      canClose:{
        type:Boolean,
        default(){
          return true
        }
      },
      open:{
        type:Boolean,
        default(){
          return false
        }
      },
      tipType: {
        type: String,
        default: ''
      },
      tipText: {
        type: String,
        default: '操作成功'
      },
      okBnText:{
          type: String,
          default: '确定'
      },
      topTip:{
        type: String,
        default: '提示'
      }
    },
    computed:{
      getRes(obj,path){
        return (obj,path)=>{
          return commonUtils.getValFromObj(obj,path)
        }
      }
    },
     watch:{
       open:{
         handler(newVal, oldVal) {


           if(!newVal){
             this.wxOpen=false
           }

           this.changeGlobalTipShadowSize()


           setTimeout(()=>{
             this.changeGlobalTipShadowSize();
           },800)


         },
         deep: true,
         immediate: true
       },
         tipText: {
             handler(newVal, oldVal) {


                 this.changeGlobalTipShadowSize()


               setTimeout(()=>{
                 this.changeGlobalTipShadowSize();
               },800)


             },
             deep: true,
             immediate: true
         }
    }
  }
</script>


<style  lang="less">

  #global_tip{
    z-index: 20000;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &:after{
      content: "";
      display: block;
      clear: both;
      zoom: 1;
    }


    .tip-center-warp{
      position: fixed;
      background: white;
      width: 580px;
      /*transform: translateY(-50%) translateX(-50%);*/
      top: 50%;
      left: 50%;
      margin-left: -580px/2;
      margin-top: -180px/1.2;
      font-size: 15px;
      z-index: 4099;


      .top{
        box-sizing: border-box;
        color: rgba(0,0,0,1);
        font-weight: 400;
        height: 50px;
        line-height: 50px;
        /*background-color: #2B3758;*/
        background-color: rgba(52,123,198,1);;
        display: flex;
        justify-content: space-between;
        padding: 0 6px 0 14px;
        .tip-text{
          color: white;
        }
        .close{
          cursor: pointer;
          font-size: 40px;
          color: white;
          transform: rotate(45deg);
        }
      }

      .text-warp{
        box-sizing: border-box;
        width: 100%;
        .text{
          background: white;
          text-align: left;
          line-height: 26px;
          font-weight: 600;
          font-size: 15px;
          padding: 0px 31px;
          margin-top: 48px;
          /*border-top: 1px solid rgba(240,240,241,1);*/
          /*border-left: none;*/
          /*border-right: none;*/


          .success,.error{
            margin-right: 10px;
            font-size: 15px;
            background: rgba(80,191,68,1);
            color:white ;
            border-radius: 150px;
            width: 24px;
            text-align: center;
            height: 24px;
            display: inline-block;
          }
          .error{
            background: rgba(246,166,35,1);
          }
        }

        .bn-warp{
          background: white;
          height: 55px;
          .bn{
            color: white;
            text-align: center;
            font-size: 14px;
            margin-left: 499px;
            height: 100%;
            box-sizing: border-box;
            padding-top: 16px;
            div{
              cursor: pointer;
              width:66px;
              height:29px;
              border-radius:5px;
              /*background: #2B3758;*/
              background-color: rgba(52,123,198,1);
              font-size:14px;
              color:rgba(255,255,255,1);
              line-height:29px;
              &:hover{
                /*-moz-box-shadow:0px 2px 5px 1px #333333;*/
                /*-webkit-box-shadow:0px 2px 5px 1px #333333;*/
                /*box-shadow:0px 2px 5px 1px #333333;*/
              }
            }
          }
        }
      }




    }

  }

</style>
