export const lang = {
    //公共
    document_title:'RefLinker',
    confirm:'Confirm',
    confirm_pay:'Confirm',
    key_point: "FigCheck is free!",
    no_data:'no data',
    unknown:'Unknown',
    timeout_dialog_title:'Tips',
    timeout_dialog_conetent_html:'Timeout! Contact admin@figcheck.com for help.',

    btn_cancer:'Cancer',
    btn_update:'Update',
    btn_upgrade:'Upgrade',

    global_tip_title:'Tips',
    global_tip_confirm_known:'Noticed',
    global_tip_confirm_ok:'ok',
    global_tip_success:'Success',
    global_tip_check_num_zero:'Free times are running out! Upgrade your permission!',

    home_checkout: 'Please register with your email address to keep using Mcar {1} times per month.',
    contact:"Contact Us",

    //顶部
    header_lang: "中文",
    header_login: "Login",
    header_login_prompt: "Login first for better function use",
    header_quit: "Logout",
    header_ret_home: "Click to back home",
    header_click_copy_user_nickname: "Click to copy user nickname",

    header_menu_api_access:"API Access",
    header_menu_down_word_plugin:"Download Word Plugin",

    header_user_id:"User ID",
    header_username:"Username",
    header_user_nick_name:"User’s Nickname",
    header_user_remain_num:"Remaining Times",
    header_user_pay:"Donate",
    header_user_click_copy_userID:"Click to copy User ID",
    header_user_click_update_nickName:"Click to change User’s Nickname",
    header_user_update_nickName:"Change User’s Nickname",
    header_user_input_new_nickName:"New Nickname",
    header_copy_success:"Copied Successful",
    loginbox_msg_logout_succ:'Logout Successful',

    //首页
    upload_box_drag_here:"Drag files here or Click to",
    upload_box_upload:"upload",
    upload_box_upload_support_format:"File Format: docx",
    checking:"Checking...",
    start_check:"Start running",
    check_time_prefix:"The check will complete in about",
    check_time_postfix:"seconds",
    check_waiting:"A lot of inquires currently! Queuing, please wait patiently!",
    upload_box_msg_size:"Please upload a docx file with a size no more than {0}MB.",
    upload_box_msg_not_docx:"Only docx file is allowed.",
    check_limit_prompt:"Kind reminder: This system is adapted to the original post layout of the sample. Other layouts may cause recognition omissions. ",

    check_option_title:"Testing Contents",
    check_option_ref_sim:"Duplicate references",
    check_option_org:"Institution identified",
    check_option_author_article:"Author information",
    check_option_year_stat:"Reference year of publication",
    check_option_suspect_ref:"Suspicious references",
    check_option_ref_risk:"Academic integrity risk of references",
    check_option_author_risk:"Academic integrity risk of authors",
    check_option_email_risk:"Email address of the corresponding author",
    check_option_email_sim:"Alert for similar Email addresses of corresponding authors",
    check_option_figcheck:"FigCheck image duplication detection",

    check_result_down_report:"Download your report",
    check_result_msg_no_report:"No report available",

    check_result_ref_sim_title:"Duplicate references",
    check_result_ref_sim_prompt:"We will push alert if the similarity between the references in the list exceeds 80%.",
    check_result_sim_prompt:"Suspicious duplicates",
    check_result_sim_percent:"Similarity",
    check_result_ref_sim_zero_result_prompt:"Congratulate! No duplicates are found.",

    check_result_org_title:"Author institution",
    check_result_no:"Order",
    check_result_org_name:"Institution name",
    check_result_org_zero_result_prompt:"No author institution found in the manuscript.",

    check_result_author_article_title:"Corresponding author information",
    check_result_author:"Authors",
    check_result_org:"Institution",
    check_result_pub_article_count:"Number of papers published",
    check_result_max_if:"Max IF",
    check_result_avg_if:"Avg IF",
    check_result_author_article:"Related literatures",
    check_result_view:"View",
    check_result_author_aritcle_zero_result_prompt:"No other literatures published by the corresponding author.",

    check_result_ref_year_stat_title:"Reference year of publication",
    check_result_year:"Year of publication",
    check_result_cur_year_num:"Reference number of the year",
    check_result_count_num:"Cumulative number",
    check_result_cur_year_percent:"Proportion of the year",
    check_result_count_percent:"Cumulative proportion",
    check_result_ref_zero_result_prompt:"No references found.",

    check_result_ref_detail_stat_title:"Reference details (including academic integrity risk)",
    check_result_ref_detail_stat_prompt:"Academic integrity issues found in references",
    check_result_fullscreen_view:"Full-screen mode",
    check_result_journal:"Journal",
    check_result_ifs:"IF",
    check_result_pub_year:"Year of publication",
    check_result_warning:"Academic integrity risk",
    check_result_click_view:"More details",

    check_result_ref_risk_title:"Suspicious references",
    check_result_ref_risk_prompt:"We will push alert when the frequency of one author is up to or over 3",
    check_result_show_num:"Frequency",
    check_result_ref_no:"Citation number",
    check_result_ref_risk_zero_result_prompt:"Congratulate! No suspicious references are found!",

    check_result_author_risk_title:"Academic integrity risk of author",
    check_result_author_risk_prompt:"We will push alert if there is an academic integrity risk involved in other papers of the authors of the manuscript",
    check_result_article:"Paper",
    check_result_link:"Link",
    check_result_author_risk_zero_result_prompt:"Congratulate! None of the authors of the manuscript are involved in academic integrity risk.",

    check_result_email_risk_title:"Email address of the corresponding author",
    check_result_email_risk_prompt:"The system is automatically checking other Emails with academic integrity issues",
    check_result_email:"Email address",
    check_result_pub_date:"Year of publication",
    check_result_warning_link:"Link",
    check_result_email_risk_zero_result_prompt:"Congratulate! There is no risk involved in the email address of the corresponding author. ",

    check_result_email_sim_title:"Alert for similar Email addresses of corresponding authors",
    check_result_email_sim_prompt:"This system automatically matches identical or similar email addresses that are involved in academic integrity issues, such as the similar email addresses used by paper mills, and the testing result is only for reference",
    check_result_email_sim_zero_result_prompt:"Congratulate! No any other Emails of corresponding authors found with academic integrity issues",

    check_result_title_figcheck:"FigCheck image duplication detection",

    check_result_dialog_title_postfix_year_pub:"Annual Publications",
    check_result_dialog_title_postfix_ifs:"IF Distribution",
    check_result_dialog_title_postfix_pub_article:"Publications",
    check_result_pub_count:"Number of Publications",


    case_title:'Examples of image plagiarism',
    links_title:'Links',
    links_link_3:'国家卫健委',
    links_link_4:'国务院',
    links_link_5:'中华医学会',
    links_link_6:'国自然基金委',


    //登录注册
    //公共
    loginbox_input_email:'Email address',
    loginbox_input_pass:'Password',
    loginbox_link_login_wechat:'微信登录',
    loginbox_link_reg:'Sign up',
    loginbox_link_find_pass:'Forgot password?',
    loginbox_link_login:'Log in',
    //登录
    loginbox_login_title:'Log in',
    loginbox_login:'Log in',
    //注册
    loginbox_reg_title:'Sign up by Email',
    loginbox_send_code:'Get code',
    loginbox_input_code:'Security code',
    loginbox_reg:'Sign up',
    //找回密码
    loginbox_reset_title:'Reset password',
    loginbox_input_new_pass:'New password',
    //错误提示
    loginbox_err_email:'Invalid Email address! Please try again.',
    loginbox_err_pass:'Invalid password! Your password must contain at least 6 characters.',
    loginbox_err_code:'Invalid code. The security code must contain 4 characters.',
    //消息提示
    loginbox_msg_login_succ:'Login successful',
    loginbox_msg_login_fail:'Login error. Try again.',
    loginbox_msg_send_limit:'Too many operations. Try again later.',
    loginbox_msg_reg_succ:'Successful registration. You may log in using your email address and password.',
    loginbox_msg_reset_succ:'Password reset successful.',
    loginbox_msg_reset_succ_login_fail:'Password reset successfully.  Auto-login failed, please try again manually.',
    loginbox_msg_reset_fail:'Password reset failed.',
    loginbox_msg_send_email_fail:'Sending failed.',
    loginbox_msg_send_email_succ:'Sent.',
    loginbox_msg_code_err:'Invalid security code. Four failed attempts, one attempt left.',
    loginbox_msg_code_err_no_num:'Incorrect security code.',
    loginbox_msg_login_gt_limit:'You have exceeded the maximum number of attempts for this action. Try again later.',
    loginbox_msg_login_user_in_black_list:'Your account has been locked.',
    loginbox_msg_login_ip_in_black_list:'Your IP has been locked.',
    loginbox_msg_user_pass_err:'Username/password is incorrect. Three more attempts left.',
    loginbox_msg_user_not_exist:'There is no user registered with that Email address. Check your Email address and try again later.',
    loginbox_msg_user_has_exist:'The account already exists. Please log in.',
    loginbox_msg_reg_fail:'Registration failed. Try again.',
    //邮箱验证码
    email_titile:'Security code sent by FigCheck',
    email_content:'Your security code is {1}. The code will expire in 5 mins, please enter the code quickly for successful registration',
    //头部菜单
    menu_self_check:'Academic self-examination',
    menu_readme:'Instructions for use',
    menu_pub_sub:'Pubpeer subscription',
    menu_peer_review:'Peer review',
    figcheck_menu_api_access:'Institutional API access',

    // mcarcheck
    figcheck_pay_id_account:"Account ID",
    figcheck_pay_remain_num:"Remaining Count",
    figcheck_pay_remain_num_unit:" Times",
    figcheck_pay_not_login:"Not logged in yet",
    figcheck_pay_action:"Donation",
    figcheck_pay_money_unit_char:"￥",
    figcheck_pay_money_unit_str:"Yuan",
    figcheck_pay_order_num:"Order Number",
    figcheck_pay_type:"Payment Method",
    figcheck_pay_type_wx:"WeChat Scan",
    figcheck_pay_type_ali:"Alipay Scan",
    figcheck_pay_msg:"Message",
    // figcheck_pay_prompt_1:"1. Overtime is not counted.",
    figcheck_pay_prompt_2:"1.This payment is for special virtual goods and does not support refunds. Please fully confirm its usefulness before donating. Thank you.",
    figcheck_pay_prompt_3:"2.Please do not pay repeatedly; after the payment is completed, if the count does not increase, please log out and log in again; for other issues, please contact customer service in time.",
    figcheck_pay_prompt_4_1:"3.For invoice issuance, please contact",
    figcheck_pay_prompt_4_2:"Staff",
    figcheck_pay_prompt_4_3:".",
    figcheck_pay_qcode_title:"Scan to add customer service WeChat",
    figcheck_pay_qcode_prompt:"Customer Service WeChat",

    figcheck_pay_msg_success:'Payment Completed',
    figcheck_pay_msg_nopay:'Unpaid',

    check_result_toggle_to_list_view:"View List",
    check_result_toggle_to_chart_view:"View Bar Chart",

    //Reflinker
    step_name_1:"Journal selection",
    step_name_2:"Email filtering",
    step_name_3:"Email content preview",
    step_name_4:"Bulk sending progress",

    step_btn_prev:"Previous step",
    step_btn_next:"Next step",
    step_btn_next_start_send:"Start sending",

    step_1_input_prompt:"Please select the journal to be promoted",

    journal_name:"Journal name",
    ifs:"Impact factor",
    part:"Partition",
    part_postfix:"",
    journal_url:"Address link",

    step_2_left_title:"Literature overview",
    step_2_left_stat_1:"Total found",
    step_2_left_stat_2:"References",
    step_2_left_stat_3:"Emails available for sending",
    step_2_left_stat_4:"",

    step_2_left_filter_time:"Publication time of the literature",
    step_2_left_filter_time_0:"To Now",
    step_2_left_filter_time_1:"Current year",
    step_2_left_filter_time_2:"Year 23",
    step_2_left_filter_time_3:"Year 22",
    step_2_left_filter_time_year:"Year",
    step_2_left_filter_time_4:"Last half year",
    step_2_left_filter_time_5:"Last 3 months",

    step_2_right_title:"Email filtering",
    step_2_right_stat_1:"Target emails set",
    step_2_right_stat_2:"",
    step_2_right_confirm_btn:"Confirm settings",

    step_2_right_filter_range_separator:"To",
    step_2_right_filter_start_placeholder:"Start date",
    step_2_right_filter_end_placeholder:"End date",

    step_2_right_filter_ifs:"Impact factor",
    step_2_right_filter_org:"Institution filtering",
    step_2_right_filter_journal:"Journal filtering",

    step_2_right_result_email_title:"Emails available for recommendation",

    step_4_progress_prompt:"Sending progress",
    step_4_history_title:"Push history",
    step_4_history_col_1:"No",
    step_4_history_col_11:"Journal",
    step_4_history_col_2:"Emails to send",
    step_4_history_col_3:"Emails sent",
    step_4_history_col_4:"Link openings",
    step_4_history_col_41:"Link open Rate",
    step_4_history_col_42:"Open count",
    step_4_history_col_43:"Open rate",
    step_4_history_col_44:"Progress",
    step_4_history_col_5:"Send situation",
    step_4_history_col_6:"Send time",
    step_4_history_col_7:"Operation",
    step_4_history_col_7_1:"View Report",

    step_4_stat_dialog_title:"Author situation",
    step_4_stat_dialog_col_1:"Open email",
    step_4_stat_dialog_col_2:"Name",
    step_4_stat_dialog_col_3:"Institution",
    step_4_stat_dialog_col_4:"Personal keywords",
    step_4_stat_dialog_col_5:"Total articles",
    step_4_stat_dialog_col_6:"Average IF",
    step_4_stat_dialog_col_7:"Highest IF",
    step_4_stat_dialog_col_8:"Related articles",

    step_4_stat_dialog_col_view:"View",

    step_4_stat_dialog_year_title:"Annual publication volume",
    step_4_stat_dialog_ifs_title:"Impact factor distribution chart",
    step_4_stat_dialog_article_title:"Published literature",

    month_picker_1:"This month",
    month_picker_2:"This year",
    month_picker_3:"Last 6 months",

    canvas_article_count:"Publication volume",
    pagination: {
        goto: 'Go to',
        pagesize: '/page',
        total: 'Total {total}',
        pageClassifier: ''
    },
    //element ui
    datepicker: {
        now: 'Now',
        today: 'Today',
        cancel: 'Cancel',
        clear: 'Clear',
        confirm: 'OK',
        selectDate: 'Select date',
        selectTime: 'Select time',
        startDate: 'Start Date',
        startTime: 'Start Time',
        endDate: 'End Date',
        endTime: 'End Time',
        prevYear: 'Previous Year',
        nextYear: 'Next Year',
        prevMonth: 'Previous Month',
        nextMonth: 'Next Month',
        year: '',
        month1: 'January',
        month2: 'February',
        month3: 'March',
        month4: 'April',
        month5: 'May',
        month6: 'June',
        month7: 'July',
        month8: 'August',
        month9: 'September',
        month10: 'October',
        month11: 'November',
        month12: 'December',
        week: 'week',
        weeks: {
            sun: 'Sun',
            mon: 'Mon',
            tue: 'Tue',
            wed: 'Wed',
            thu: 'Thu',
            fri: 'Fri',
            sat: 'Sat'
        },
        months: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec'
        }
    },

    report_title:"Push Statistics Report",
    report_total_state_title:"Journal Data Total",
    report_total_state_label_1:"Total Number of Pushes",
    report_total_state_label_2:"Number of Articles",
    report_total_state_label_3:"Number of Authors",
    report_total_state_label_4:"Number of References",

    report_cur_state_label_pre:"Current Push Statistics (Push Time:",
    report_cur_state_label_after:")",
    report_cur_state_progress_label:"Current Push Progress",
    report_cur_state_label_1:"Number of Authors",
    report_cur_state_label_2:"Number of Successfully Sent Emails",
    report_cur_state_label_3:"Number of Email Opens",
    report_cur_state_label_4:"Email Open Rate",
    report_cur_state_label_5:"Number of Website Opens",
    report_cur_state_label_6:"Total Exposure",
    report_cur_state_label_7:"Total Exposure Rate",

    report_chart_state_label_1:"Push Trend",
    report_chart_state_label_2:"Website Visit Statistics",

    report_chart_state_label_11:"Literature Citations",
    report_chart_state_label_21:"Email Open Num",

    report_chart_state_label_date_1:"Last Day",
    report_chart_state_label_date_2:"Last Three Days",
    report_chart_state_label_date_3:"Last Week",
    report_chart_state_label_date_4:"Last Month",

    report_list_filter_label_1:"Recipient",
    report_list_filter_label_2:"To",
    report_list_filter_label_3:"Start Date",
    report_list_filter_label_4:"End Date",
    report_list_filter_label_btn_1:"Query",

    report_list_filter_label_11:"All",
    report_list_filter_label_12:"Sent",
    report_list_filter_label_13:"Not Sent",

    report_list_filter_label_21:"All",
    report_list_filter_label_22:"Read",
    report_list_filter_label_23:"Unread",

    report_list_filter_label_31:"All",
    report_list_filter_label_32:"Link Clicked",
    report_list_filter_label_33:"Link Not Clicked",

    report_list_table_label_1:"Title",
    report_list_table_label_2:"Recipient",
    report_list_table_label_3:"Outbox",

    report_list_table_label_col_1:"No",
    report_list_table_label_col_2:"Method",
    report_list_table_label_col_21:"Email",
    report_list_table_label_col_3:"Recipient",
    report_list_table_label_col_4:"Email",
    report_list_table_label_col_41:"Organization",
    report_list_table_label_col_42:"Visit Region",
    report_list_table_label_col_5:"Push Time",
    report_list_table_label_col_6:"Result",
    report_list_table_label_col_7:"Read",
    report_list_table_label_col_8:"Website Visit",
}
